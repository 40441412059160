import { Alert, AlertIcon, Box, Button, Stack, Text, useBoolean } from "@chakra-ui/react"
import { MainHeader } from "../../components"
import { PaymentMethod } from "./PaymentMethod"
import { Subscriptions } from "./Subscriptions"
import { useGetClaims, useGetSubscription, useIsOrganizationAdmin } from "../../../../hooks"
import { Link } from "react-router-dom"
import { UnderlineLink } from "../../../Auth/SignupLogin/Components/UnderlineLink"
import { wait } from "../../../../../shared/helpers"
import { useFirebase } from "react-redux-firebase"

const statuses = ["active", "trialing"]

export const Billing = () => {
    const [isLoading, setIsLoading] = useBoolean()
    const firebase = useFirebase()
    const isAdmin = useIsOrganizationAdmin()
    const { status } = useGetClaims()
    const subscription = useGetSubscription()
    const { customer_id } = subscription || {};
    return (
        <Stack spacing={6}>
            {!statuses?.includes(status) && (
                <Alert status="warning" p={5} px={7} rounded="md" maxW="6xl">
                    <AlertIcon />
                    <Stack ml={3} fontWeight="normal">
                        <Box>We were unable to process your latest payment, so your account has been temporarily suspended.</Box>
                        <Box>All of your account data is still being maintained - nothing has been deleted.</Box>
                        <Box display="inline">
                            {`To restore access to this account, `}
                            {isAdmin && (
                                <Button variant="link" color="rarchy-link" fontSize="15px" fontWeight="medium" size="md" isLoading={isLoading} onClick={async () => {
                                    setIsLoading.toggle()
                                    try {
                                        const data = { customerId: customer_id, flow_data: { type: "payment_method_update" } };
                                        const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')(data)
                                        if (sessionURL?.data) window.location = sessionURL.data;
                                    } catch (e) {
                                        console.error(e);
                                    }
                                    await wait(1000)
                                    setIsLoading.toggle()
                                }}>
                                    {` update your payment method `}
                                </Button>
                            )}
                            <Box display="inline">
                                {!isAdmin ? "ask an account admin to update the payment method" : " to pay your latest invoice"}, or <UnderlineLink><Link to="/help/ticket"><Text fontSize="15px" fontWeight="semibold">contact us</Text></Link></UnderlineLink> if you need further assistance.</Box></Box>
                    </Stack>

                </Alert>
            )}
            <Stack maxW="lg" spacing={7} mb={20}>
                <MainHeader title="Billing & plans" />
                <Stack spacing={10}>
                    <PaymentMethod />
                    <Subscriptions />
                </Stack>
            </Stack>
        </Stack>
    )
}