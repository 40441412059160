import { createContext, useState } from "react"
import { useBoolean } from "@chakra-ui/react"
import { useGetFileFromFiles, useGetFilteredPeopleData } from "../../../../hooks"

export const ShareFileContext = createContext()

export const ShareFileHOC = ({ fileId, onClose, children }) => {
    const file = useGetFileFromFiles(fileId)
    const people = useGetFilteredPeopleData({ file, forShareFile: true })
    const [isSelectFocused, setIsSelectFocused] = useBoolean()
    const [inputValue, setInputValue] = useState('')
    const [peopleToInvite, setPeopleToInvite] = useState([])
    return (
        <ShareFileContext.Provider
            value={{
                file,
                people,
                onClose,
                isSelectFocused,
                setIsSelectFocused,
                inputValue,
                setInputValue,
                peopleToInvite,
                setPeopleToInvite
            }}>
            {children({ file, inputValue, setInputValue, setPeopleToInvite, peopleToInvite })}
        </ShareFileContext.Provider>
    )
}