import { applyYWithCavasOffset, getPageColor, getRandomColor } from '../../../app/canvas/utils/helpers'
import { isEmpty, sortBy } from 'lodash'
import { renderCover, renderHeader, renderNodeRect, renderPageSections, renderText } from '../../../app/canvas/components';

import canvasTxt from 'canvas-txt';
import { dragging } from '../../../app/canvas/utils/drag';
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers';
import { getCanvasTextEditor } from '../../../../../helpers';
import { getPalletteColors } from '../../../../../store/actions/editor-actions';
import { mouseover } from '../../../app/canvas/utils/listeners';
import { renameUserFlowSymbol } from '../../../app/canvas/components/text/utils';
import { showOptionsOnClick } from '../../helpers';
import { store } from '../../../../../store';
import { transform } from '../../../app/canvas/utils/zoom';

// import { setSectionAttrs } from '../../../app/canvas/components/page-sections';

export const colors = {};
const colorByNodeIds = {};

export const renderNodes = (nodes, context, opts = {}) => {
    const sortedNodesByIndex = sortBy(nodes, [n => n.index ? n.index : 0]);
    if (isEmpty(sortedNodesByIndex)) return;
    // ensure mouseovered node is always rendered last (so it's on top)
    sortedNodesByIndex.push(sortedNodesByIndex.splice(sortedNodesByIndex.indexOf(mouseover.userFlowNode), 1)[0]);
    sortedNodesByIndex.map(d => { // has to be map to fix dragging issue
        if (dragging && dragging.started && (d.id === dragging.id)) return // dragging started
        if (dragging && !dragging.started && (d.id === dragging.id)) { // click/long click - IMPORTANT FOR DRAGGING
            d.x = dragging.x0; d.y = dragging.y0;
        }
        d?.type?.includes('page') ? renderUserFlowPage({ context, opts, d }) : renderUserFlowSymbol({ context, opts, d, x: d.x, y: d.y })
    })
    return sortedNodesByIndex;
}

export const renderUserFlowPage = ({ context, opts, d }) => {
    const { hidden, pdf } = opts;
    // DIFF FROM PAGES AS WE DONT NEED CHILD RECTS / PAGES WAY DOESNT WORK WITH 'ARRANGE'
    renderNodeRect(context, d, hidden)
    if (!hidden) {
        if (d.type === 'page') renderCover(context, d, /* visible */ true, pdf) // only if not hidden so it doesn't interfer with picker 
    }
    /*** sections ***/
    if (d.type === 'page-sections') {
        renderPageSections(context, d, hidden);
    }
    /*** sections ***/
    renderHeader(context, d, hidden)
    renderText(context, d, hidden, pdf);
}

export const SYMBOL_WIDTH = 225;
export const SYMBOL_HEIGHT = 90;
export const SYMBOL_LINEHEIGHT = 19;
const PICKER_OVERLAP_MARGIN = 35;

export const renderUserFlowSymbol = ({ context, opts, d, x, y }) => {

    if (!x) x = d.x;
    if (!y) y = d.y;
    y = y - 19.25 // need -19.25 to line up original svg y (all symbols apart from page)

    const { editor, ui } = store.getState();
    const { hidden } = opts;
    /*** assign event colors if hidden ***/
    var randomColor;
    if (hidden) {
        if (!colorByNodeIds[d.id]) {
            /*** assign new random color to node id ***/
            randomColor = getRandomColor();
            colorByNodeIds[d.id] = randomColor;
            colors[randomColor] = d;
            /*** assign new random color to node id ***/
        } else {
            randomColor = colorByNodeIds[d.id]; // use existing assigned color
        }
    };
    /*** assign event colors ***/
    // set styles 
    context.lineWidth = 2;
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.fillStyle = hidden ? randomColor : ui.colorMode === 'light' ? 'white' : '#1A202C';
    context.strokeStyle = d.color ? d.color : editor?.pallette?.header;
    if (d._selected) context.setLineDash([9, 6]);

    const newSymbolOffsetX = d.id !== 'new-symbol' ? 0 : (SYMBOL_HEIGHT / 2);
    const newSymbolOffsetY = d.id !== 'new-symbol' ? 0 : 18.25;

    /*** multi-select dragging ***/
    if (dragging && (d?.id !== dragging?.id && d._selected)) {
        x = x + (dragging.x - dragging.x0);
        y = y + (dragging.y - dragging.y0);
    }
    /*** multi-select dragging ***/

    const movex = (d?.id === dragging?.id || d._selected) ? Math.round(x / (225 / 12)) * (225 / 12) : x;
    const movey = (d?.id === dragging?.id || d._selected) ? Math.round(y / (265 / 12)) * (265 / 12) : y;

    const xwithOffset = movex - newSymbolOffsetX;
    const ywithOffset = movey - newSymbolOffsetY;

    // switch
    switch (d.type) {
        case 'startEnd':
            roundRect(context, d, xwithOffset, ywithOffset, 50, hidden);
            break;
        case 'decision':
            drawDiamond(context, d, xwithOffset, ywithOffset, hidden)
            break;
        case 'action':
            roundRect(context, d, xwithOffset, ywithOffset, 5, hidden);
            break;
        case 'document':
            drawDocument(context, d, xwithOffset, ywithOffset, hidden)
            break;
        case 'inputOutput':
            drawInputOutput(context, d, xwithOffset, ywithOffset, hidden)
            break;
    }

    drawText(context, d, movex, movey, opts)
    context.setLineDash([]); // reset line dash

}

export const getUserFlowSymbolTextWidthAndHeight = (type) => {
    let width = SYMBOL_WIDTH;
    let height = SYMBOL_HEIGHT * 0.65;
    switch (type) {
        case 'startEnd':
        case 'action':
            width = width * 0.9
            break;
        case 'decision':
            width = width * 0.5
            break;
        case 'inputOutput':
            width = width * 0.8
            break;
        case 'document':
            width = width * 0.8
            break;
    }
    return { width, height };
}

function fittingString(c, str, maxWidth) {
    var width = c.measureText(str).width;
    var ellipsis = '…';
    var ellipsisWidth = c.measureText(ellipsis).width;
    if (width <= maxWidth || width <= ellipsisWidth) {
        return str;
    } else {
        var len = str.length;
        while (width >= maxWidth - ellipsisWidth && len-- > 0) {
            str = str.substring(0, len);
            width = c.measureText(str).width;
        }
        return str + ellipsis;
    }
}

const actualHeights = {};
const fittingStrings = {}

export function drawText(context, d, x, y, opts) {

    const { hidden, pdf } = opts;

    const CanvasTextEditor = getCanvasTextEditor()
    const isRenaming = CanvasTextEditor?.node?.id === d.id;

    if (!isRenaming && hidden) return;

    // reset cached node attrs
    if (isRenaming) actualHeights[d.id] = null; fittingStrings[d.id] = null;

    const { width, height } = getUserFlowSymbolTextWidthAndHeight(d.type);

    let fontsize = 15; // fontSizes[d.id] ? fontSizes[d.id] : getFontSizeToFit(d.text, 'Inter,sans-serif', width * 5);
    // if (!fontSizes[d.id]) fontSizes[d.id] = fontsize
    canvasTxt.fontSize = fontsize; //  fontsize < 15 ? fontsize : 15;
    canvasTxt.lineHeight = SYMBOL_LINEHEIGHT;
    var font = `${pdf ? 500 : 600} ${fontsize}px Inter,sans-serif`
    canvasTxt.align = 'top' // truncated ? 'left' : 'middle';
    canvasTxt.vAlign = 'top';
    canvasTxt.fontWeight = pdf ? 500 : 600;
    canvasTxt.font = 'Inter,sans-serif';
    // canvasTxt.lineHeight = fontsize * 1.25;
    context.font = font;

    const pallette = getPalletteColors(getPageColor(d))
    const fillStyle = pallette[7];

    context.fillStyle = 'transparent';
    x = x + ((SYMBOL_WIDTH - width) / 2)
    y = y - (d.type === 'document' ? 5 : 1);
    const str = fittingStrings[d.id] || fittingString(context, d.text || '', width * 2.75);
    // set fitting strings
    if (!fittingStrings[d.id]) fittingStrings[d.id] = str;
    // heights   
    const { height: actualHeight } = actualHeights[d.id] || canvasTxt.drawText(context, str, x, y, width, height);
    // set height
    if (!actualHeights[d.id]) actualHeights[d.id] = { height: actualHeight }
    // redraw text
    context.fillStyle = isRenaming ? 'transparent' : fillStyle;
    canvasTxt.drawText(context, str, x, y + ((SYMBOL_HEIGHT - actualHeight) / 2), width, height);
    return { actualHeight };
}

function roundRect(ctx, d, x, y, radius, hidden) {
    const pickerOverlap = (hidden && mouseover?.userFlowNode?.id === d.id) ? PICKER_OVERLAP_MARGIN : 0;
    if (SYMBOL_WIDTH < 2 * radius) radius = SYMBOL_WIDTH / 2;
    if (SYMBOL_HEIGHT < 2 * radius) radius = SYMBOL_HEIGHT / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius - pickerOverlap, y - pickerOverlap);
    ctx.arcTo(x + SYMBOL_WIDTH + pickerOverlap, y - pickerOverlap, x + SYMBOL_WIDTH + pickerOverlap, y + SYMBOL_HEIGHT - pickerOverlap, radius);
    ctx.arcTo(x + SYMBOL_WIDTH + pickerOverlap, y + SYMBOL_HEIGHT + pickerOverlap, x + pickerOverlap, y + SYMBOL_HEIGHT + pickerOverlap, radius);
    ctx.arcTo(x - pickerOverlap, y + SYMBOL_HEIGHT + pickerOverlap, x - pickerOverlap, y + pickerOverlap, radius);
    ctx.arcTo(x - pickerOverlap, y - pickerOverlap, x + SYMBOL_WIDTH - pickerOverlap, y - pickerOverlap, radius);
    ctx.fill()
    if (!hidden) ctx.stroke();
};

function drawDiamond(ctx, d, x, y, hidden) {
    const pickerOverlap = (hidden && mouseover?.userFlowNode?.id === d.id) ? PICKER_OVERLAP_MARGIN : 0;
    const yOffset = 3;
    x = x + (SYMBOL_WIDTH / 2); // because drawing is from middle to left, to bottom to right
    y = y - yOffset; // to match left/right connectors
    ctx.beginPath();
    ctx.moveTo(x, y + yOffset - pickerOverlap);
    ctx.lineTo(x - (SYMBOL_WIDTH / 2) - pickerOverlap, y + SYMBOL_HEIGHT / 2);
    ctx.lineTo(x, y + yOffset + SYMBOL_HEIGHT + pickerOverlap);
    ctx.lineTo(x + (SYMBOL_WIDTH / 2) + pickerOverlap, y + SYMBOL_HEIGHT / 2);
    ctx.closePath();
    ctx.fill()
    if (!hidden) ctx.stroke();
}

function drawInputOutput(ctx, d, x, y, hidden) {
    const pickerOverlap = (hidden && mouseover?.userFlowNode?.id === d.id) ? PICKER_OVERLAP_MARGIN : 0;
    y = y + SYMBOL_HEIGHT;
    ctx.beginPath();
    ctx.moveTo(x - pickerOverlap, y + pickerOverlap);
    ctx.lineTo(x + (SYMBOL_WIDTH * 0.075) - pickerOverlap, y - SYMBOL_HEIGHT - pickerOverlap);
    ctx.lineTo(x + SYMBOL_WIDTH + pickerOverlap, y - SYMBOL_HEIGHT - pickerOverlap);
    ctx.lineTo(x + (SYMBOL_WIDTH * 0.925) + pickerOverlap, y + pickerOverlap);
    ctx.closePath();
    ctx.fill()
    if (!hidden) ctx.stroke();
}

function drawDocument(ctx, d, x, y, hidden) {
    const pickerOverlap = (hidden && mouseover?.userFlowNode?.id === d.id) ? PICKER_OVERLAP_MARGIN : 0;
    y = y + SYMBOL_HEIGHT // start at bottom left
    ctx.beginPath();
    ctx.moveTo(x - pickerOverlap, y - pickerOverlap);
    ctx.lineTo(x - pickerOverlap, y - SYMBOL_HEIGHT - pickerOverlap);
    ctx.lineTo(x + SYMBOL_WIDTH + pickerOverlap, y - SYMBOL_HEIGHT - pickerOverlap);
    ctx.lineTo(x + SYMBOL_WIDTH + pickerOverlap, y + pickerOverlap);
    ctx.bezierCurveTo(x + (SYMBOL_WIDTH * 0.5) - pickerOverlap, y - (SYMBOL_HEIGHT * 0.4) + pickerOverlap, x + (SYMBOL_WIDTH * 0.5) - pickerOverlap, y + (SYMBOL_HEIGHT * 0.4) + pickerOverlap, x - pickerOverlap, y + pickerOverlap);
    ctx.fill()
    if (!hidden) ctx.stroke();
}

export const mouseoverUserFlowNode = (colKey) => {
    var node = colors[colKey];
    if (node) {
        const { flow } = store.getState();
        node = flow.data.nodes.find(d => d.id === node.id) // get most up to date node data
        return node;
    };
}

export const clickUserFlowNode = (node) => {
    const { flow } = store.getState()
    const { OptionsPopover } = flow.ui;
    // show options menu
    const canEdit = getCanEditInEditor();
    if (!canEdit) return;
    // continue
    if (OptionsPopover.showing) {
        renameUserFlowSymbol(node);
        // store.dispatch(hideFlowsOptionsPopover()); // not sure about having this functionality
    } else {
        showOptionsOnClick({ nodes: [node], symbolOptions: true, x: transform.applyX(node.x) + ((225 / 2) * transform.k), y:   applyYWithCavasOffset(node.topOfNode) - (54) });
    }
};