import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Avatar,
    Badge,
    Box,
    HStack,
    Skeleton,
    SkeletonCircle,
    Stack,
    Text,
    VStack,
    useColorModeValue as mode
} from "@chakra-ui/react"
import React, { useEffect, useState } from 'react'
import { groupBy, isEmpty, keys } from 'lodash'
import { patch, reverse } from 'jsondiffpatch-rc';
import { useGetEditor, useGetRevisionHistoryDrawer, useGetSitemap, useGetUserFlow, useInUserFlow, useIsRetrievingRevisionHistoryDrawerChanges } from '../../../../../../../../../hooks';

import { FixedSizeList } from 'react-window';
import { chain } from '../../../../../../../../../helpers/chain';
import copy from "fast-copy";
import { createRoot } from '../../../../../../../../Sitemap/utils/app';
import dayjs from 'dayjs';
import { getAvatarSrc } from '../../../../../../../../../helpers';
import { hideCovers } from '../../../../../../../../../store/actions/sitemap-actions';
import { setRevisionHistoryDrawerSelectedIndex } from '../../../../../../../../../store/actions/editor-actions';
import { useDispatch } from 'react-redux'

const MARGIN_TOP = 57;

export const List = () => {
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    const [groupedByDay, setGroupedByDay] = useState(null)
    useEffect(() => {
        if (!isEmpty(RevisionHistoryDrawer.changes) && !groupedByDay) {
            // add in original indexes
            RevisionHistoryDrawer.changes.map((c, i) => { c.index = i; return c; });
            // set grouped by day
            setGroupedByDay(groupByTime(RevisionHistoryDrawer.changes, 'updatedAt', 'day'));
        }
    }, [RevisionHistoryDrawer.changes]);

    return (
        <>
            <Box
                pos="absolute"
                top={`${MARGIN_TOP}px`}
                right={0} w={300}
                h={`calc(100vh - ${MARGIN_TOP}px)`}
                bgColor="rarchy-bg-sitemap-editor"
                borderLeftWidth="1px"
                borderColor="rarchy-border-sitemap-editor"
            >
                {!RevisionHistoryDrawer.error && <ChangesList RevisionHistoryDrawer={RevisionHistoryDrawer} groupedByDay={groupedByDay} />}
                {RevisionHistoryDrawer.error && Error(RevisionHistoryDrawer.error)}
            </Box>
        </>
    )
}

const ITEM_HEIGHT = 65

const ChangesList = ({ groupedByDay }) => {
    const isRetrieving = useIsRetrievingRevisionHistoryDrawerChanges();
    if (!groupedByDay && !isRetrieving) return <Text p={5} fontSize="sm" color="fg.subtle">No history to display, yet.</Text>
    // group by day;
    const groupedByDayKeys = keys(groupedByDay);
    return (
        <Accordion allowMultiple h="calc(100vh - 64px)" overflow="scroll" defaultIndex={[0]}>
            {[...groupedByDayKeys.map((groupedByDayKey, i) => {
                const updatedAt = parseInt(groupedByDayKey)
                const itemCount = groupedByDay[groupedByDayKey].length
                return (
                    <AccordionItem w="full" key={groupedByDayKey} borderTopWidth={i === 0 ? 0 : 1}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton w="full">
                                    <HStack justifyContent="space-between" w="full" py={1}>
                                        <Text pointerEvents="none" fontSize="sm" fontWeight={500} mb={-1} _hover={{ cursor: 'default' }}>{dayjs(updatedAt).format('MMMM D, YYYY')}
                                        </Text>
                                        <HStack>
                                            <Badge px={1.5}>{itemCount}</Badge>
                                            <AccordionIcon />
                                        </HStack>
                                    </HStack>
                                </AccordionButton>
                                <AccordionPanel p={0}>
                                    {isExpanded && (
                                        <FixedSizeList
                                            height={itemCount * ITEM_HEIGHT}
                                            itemCount={itemCount}
                                            itemSize={ITEM_HEIGHT}
                                            width={300}
                                            itemData={groupedByDay[groupedByDayKey]}
                                        >
                                            {Change}
                                        </FixedSizeList>
                                    )}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                )
            })]}
        </Accordion>
    )
};

const Change = ({ index: i, style, data }) => {
    const dispatch = useDispatch()
    const editor = useGetEditor()
    const sitemap = useGetSitemap()
    const flow = useGetUserFlow()
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    const inUserFlow = useInUserFlow()
    const isRetrieving = useIsRetrievingRevisionHistoryDrawerChanges()
    const change = data[i];
    const selected = change.index === RevisionHistoryDrawer.selectedIndex
    return (
        <Box
            key={i}
            style={style}
            onClick={async () => {
                const pages = copy(sitemap?.docs?.pages) || {}; // starting base
                const website_sections = copy(sitemap?.docs?.website_sections) || {}; // starting base
                const sections = copy(sitemap?.docs?.sections) || {}; // starting base
                const elements = copy(flow?.docs?.elements) || {}; // starting base
                // reverse all changes up to and including the selected change
                if (change.index > 0) {
                    for (let j = 0; j < change.index; j++) {
                        const element = copy(RevisionHistoryDrawer.changes[j]);
                        if (element.delta) {
                            var reverseDelta = reverse(element.delta);
                            if (!inUserFlow) {
                                try { if (element.doc === 'website_sections') patch(website_sections, reverseDelta); } catch (e) { console.error(element.doc, e) }
                                try { if (element.doc === 'pages' && !element.key) patch(pages, reverseDelta); } catch (e) {
                                    // try to fix website_section issue - THIS IS THE BEST CODE I'VE EVER WRITTEN
                                    if (e.message.startsWith("Cannot read properties of undefined (reading")) {
                                        console.error(e, element, j, i);
                                        keys(reverseDelta).forEach(pageId => {
                                            for (let k = j + 1; k < j + data.length; k++) {
                                                const change = RevisionHistoryDrawer.changes[k]
                                                if (change && change.doc === 'pages') {
                                                    if (change.delta[pageId]) {
                                                        if (change.delta[pageId].length === 1) { // find last time the page was added so we can use it's details
                                                            pages[pageId] = change.delta[pageId][0];
                                                            try { patch(pages, reverseDelta); return; } catch (e) { }
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    } else {
                                        // if not fixed, console.error
                                        console.error(e, element, j, i);
                                    }
                                }
                                try { if (element.doc === 'sections') patch(sections, reverseDelta); } catch (e) { console.error(element.doc, e) } 
                            } else {
                                try { patch(elements, reverseDelta); } catch (e) { console.error(e) }
                            }
                        }
                    }
                }
                // set selected index + pages
                if (!inUserFlow) {
                    let { root } = editor?.ui.RevisionHistoryDrawer?.data || {}
                    if (!isEmpty(pages)) {
                        const pagesForRoot = chain(pages).map((obj, key) => { return { ...obj, id: key } }).value();
                        root = await createRoot(pagesForRoot);
                    }
                    dispatch(setRevisionHistoryDrawerSelectedIndex({ selectedIndex: change.index, pages, website_sections, sections, root }))
                } else {
                    // ensure all x/y are floats (diffpatch turns them into string)
                    Object.keys(elements).forEach(fid => { const d = elements[fid]; d.x = +d.x, d.y = +d.y })
                    // user-flow only
                    dispatch(setRevisionHistoryDrawerSelectedIndex({ selectedIndex: change.index, elements }));
                    // dispatch for rendering
                    // dispatch(overrideFlowData(flow));
                }
                // don't show covers if doc = sections
                if (change.doc === 'sections' && sitemap?.showCovers) dispatch(hideCovers());
            }}>
            <Stack direction="row" px={4} py={2} borderTopWidth="1px" borderColor="rarchy-border" bgColor={selected ? "rarchy-bg-subtle" : mode("white", "gray.800")} minH={`${ITEM_HEIGHT}px`} _hover={{ cursor: 'pointer', bgColor: "rarchy-bg-subtle" }}>
                <HStack alignItems="start" spacing={4}>
                    {isRetrieving && <SkeletonCircle w="24px" h="24px" mt={1} />}
                    {!isRetrieving && change && (
                        <Avatar size='xs' mt={1} src={getAvatarSrc({ user: change.editedBy })} />
                    )}
                    <VStack alignItems="start">
                        {isRetrieving &&
                            <>
                                <Skeleton isLoaded={isRetrieving} w="200px" h="14px" mt={1} mb={1} />
                                <Skeleton isLoaded={isRetrieving} w="150px" h="12px" />
                            </>
                        }
                        {!isRetrieving && change && <Text fontSize="sm" fontWeight={selected ? 600 : 500} mb={-1}>{dayjs(change.updatedAt).format('h:mm A'/* 'MMMM D, h:mm A'*/)}</Text>}
                        {!isRetrieving && change && <Text fontSize="sm" color={mode("gray.600", "whiteAlpha.800")}>{`${change.editedBy.firstName} ${change.editedBy.lastName}`}</Text>}
                    </VStack>
                </HStack>
            </Stack>
        </Box>
    )
}

const Error = (error) => {
    return 'Error';
}

function getMonday(d) {
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
    return new Date(d.setDate(diff))
}

function groupByTime(arr, key, group) {

    var groupings = {
        day: function (obj) {
            var date = new Date(obj[key])
            date.setHours(0, 0, 0, 0)
            return date.valueOf()
        }
        , week: function (obj) {
            var date = new Date(obj[key])
            date.setHours(0, 0, 0, 0)
            return getMonday(date).valueOf()
        }
        , month: function (obj) {
            var date = new Date(obj[key])
            return new Date(date.getFullYear(), date.getMonth(), 1).valueOf()
        }
    }

    if (!group) group == 'day'

    return groupBy(arr, groupings[group])

}

groupByTime.byDay = function (arr, key) { groupByTime(arr, key, 'day') }
groupByTime.byWeek = function (arr, key) { groupByTime(arr, key, 'week') }
groupByTime.byMonth = function (arr, key) { groupByTime(arr, key, 'month') }