import { AddIcon, ArrowBackIcon, CloseIcon, RepeatClockIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, HStack, Icon, IconButton, MenuDivider, MenuGroup, MenuItem, Text, VStack } from "@chakra-ui/react"
import { FaRegFileCode, FaRegFileExcel, FaSpider } from "react-icons/fa"
import { toggleNewSitemapModal } from "../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"

export const NewSitemapMenu = ({ hideHeader, setScreen }) => {
    const dispatch = useDispatch()
    return (
        <Box>
            {/* <Flex w="full" justifyContent="end" pr={3}>
                <IconButton mt={0} variant="ghost" size="sm" icon={<CloseIcon fontSize="xs" />} onClick={() => { setScreen('default') }} />
            </Flex> */}
            {!hideHeader && (
                <>
                    <MenuGroup as={Box} title={<Text mt={-1.5} fontSize="lg"><IconButton ml={-1} mr={2} variant="ghost" size="xs" icon={<ArrowBackIcon fontSize="lg" />} onClick={() => { setScreen('default') }} />Visual Sitemap</Text>} fontSize="md" />
                    <MenuDivider />
                </>
            )}
            <MenuItem px={4} onClick={() => dispatch(toggleNewSitemapModal({ showing: true, createType: 'crawl' }))} icon={<Icon as={FaSpider} fontSize="lg" />} iconSpacing={4}>
                <VStack align="start" spacing={1}>
                    <HStack spacing={3}>
                        <Text>Crawl Website</Text>
                    </HStack>
                    <Box fontSize="xs" color="fg.subtle" textTransform="initial">
                        Get the freshest pages using our crawler
                    </Box>
                </VStack>
            </MenuItem>
            <MenuItem px={4} onClick={() => dispatch(toggleNewSitemapModal({ showing: true, createType: 'xml' }))} icon={<Icon as={FaRegFileCode} fontSize="lg" />} iconSpacing={4}>
                <VStack align="start" spacing={1}>
                    <HStack spacing={3}>
                        <Text>Import from XML</Text>
                    </HStack>
                    <Box fontSize="xs" color="fg.subtle" textTransform="initial">
                        Import an existing sitemap from XML
                    </Box>
                </VStack>
            </MenuItem>
            {/* <MenuItem px={4} onClick={() => dispatch(toggleNewSitemapModal({ showing: true, createType: 'csv' }))} icon={<Icon as={FaRegFileExcel} fontSize="lg" />} iconSpacing={4}>
                <VStack align="start" spacing={1}>
                    <HStack spacing={3}>
                        <Text>Import from CSV</Text>
                    </HStack>
                    <Box fontSize="xs" color="fg.subtle" textTransform="initial">
                        Upload URLs to start visualizing your site
                    </Box>
                </VStack>
            </MenuItem> */}
            <MenuItem px={4} onClick={() => dispatch(toggleNewSitemapModal({ showing: true, createType: 'history' }))} icon={<Icon as={RepeatClockIcon} fontSize="lg" />} iconSpacing={4}>
                <VStack align="start" spacing={1}>
                    <HStack spacing={3}>
                        <Text>Import from History</Text>
                    </HStack>
                    <Box fontSize="xs" color="fg.subtle" textTransform="initial">
                        View your previous sitemap imports
                    </Box>
                </VStack>
            </MenuItem>
            <MenuDivider />
            <MenuItem px={4} onClick={() => dispatch(toggleNewSitemapModal({ showing: true, createType: 'new' }))} icon={<Icon as={AddIcon} fontSize="sm" />} iconSpacing={4}>
                <VStack align="start" spacing={1}>
                    <HStack spacing={3}>
                        <Text>Build Sitemap</Text>
                    </HStack>
                    <Box fontSize="xs" color="fg.subtle" textTransform="initial">
                        Start planning your pages from scratch
                    </Box>
                </VStack>
            </MenuItem>
        </Box>
    )
}