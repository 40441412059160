import {
    Box,
    Grid,
    Heading,
    Img,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from "@chakra-ui/react"
import React, { useEffect, useMemo, useState } from 'react'
import { addCoversChange, togglePageDrawer } from '../../../../../../store/actions/sitemap-actions'
import { compact, isEmpty } from 'lodash'
import { getSitemap, sendHubspotCustomEvent } from '../../../../../../helpers'
import { useGetPageDrawer, useGetSitemap, useInUserFlow } from '../../../../../../hooks'

import { Popular } from './Popular'
import { Search } from './Search'
import { addSymbolChange } from '../../../../user-flows/helpers'
import { chain } from '../../../../../../helpers/chain'
import { getPageColor } from "../../../canvas/utils/helpers"
import { getTemplateWithColor } from '../../../covers/collections'
import { showEditorPermissionsToast } from '../../../../../Editor/App/Components/PermissionsToast'
import { useDispatch } from 'react-redux'

export const Wireframes = () => {

    const sitemap = useGetSitemap()
    const { collections } = sitemap?.covers;
    const PageDrawer = useGetPageDrawer()

    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [searched, setSearched] = useState('')

    const handleSearch = (event) => {
        if (!imagesLoaded) setImagesLoaded(true)
        setSearched(event.target.value)
    }

    useEffect(() => {
        setTimeout(() => {
            if (!imagesLoaded) setImagesLoaded(true)
        }, 250);
    }, [])

    const data = useMemo(() => {
        return compact(Object.keys(collections || []).map((key, i) => {
            if (key === 'header') return null
            const results = chain(collections[key]).map(obj => Object.assign({}, obj)).value()
            const wireframes = results.filter(cover => { if (isEmpty(searched)) return cover; return cover.name.toLocaleLowerCase().indexOf(searched) !== -1 })
            return { key, wireframes }
        }))
    }, [collections, searched])

    return (
        <>
            <Search searched={searched} handleChange={handleSearch} />
            <>
                <Popular searched={searched} />
                {data?.map((d, i) => {
                    if (isEmpty(data)) return null
                    if (i > 1 && !imagesLoaded) return null
                    if (d?.wireframes?.length === 0) return null
                    // passing page ensures color is updated when switching between wireframes, without uncessary renders
                    return (
                        <WireframeGrid key={d?.key} name={d?.key} data={d?.wireframes} page={PageDrawer?.page} />
                    )
                })}
            </>
        </>
    )
}

export const WireframeGrid = React.memo(({ name, data, page }) => {

    const dispatch = useDispatch()

    const sitemap = getSitemap() // has to be get sitemap (useSitemap causes inefficient re-renders)
    const inUserFlow = useInUserFlow()

    const { pages } = sitemap?.covers || {}

    const handleCollectionSave = (selected) => {
        // continue
        if (selected) {
            // close
            dispatch(togglePageDrawer({ node: page }))
            /*** save changes ***/
            if (!inUserFlow || (inUserFlow && page?.page)) {

                // can edit
                const canEdit = showEditorPermissionsToast({ doc: sitemap }); if (!canEdit) return;
                const pageId = page?.[!inUserFlow ? 'id' : 'page'];
                const covers = { [pageId]: { collection: selected }, default: /* setDefault ? selected : */ pages.default }
                const change = { id: new Date().getTime(), data: [covers] }
                dispatch(addCoversChange({ change, covers }))

                sendHubspotCustomEvent('selected_page_wireframe', {
                    wireframe_key: selected
                })

            } else {
                // can edit
                const canEdit = showEditorPermissionsToast(); if (!canEdit) return;
                const data = { [page.id]: { wireframe: selected } }
                const oldData = { [page.id]: { wireframe: page.wireframe } }
                addSymbolChange({ key: 'wireframe', data, oldData });
            }
            /*** save changes ***/
        }
    }

    return (
        <Box textTransform="capitalize" mt={0} borderBottom="1px solid" borderColor="rarchy-border" pb={4}>
            <Heading size="sm" textTransform="capitalize" fontWeight="semibold" mt={6} mb={4}>{name?.replace(/-/g, ' ')}</Heading>
            <Wrap gap={1}>
                {data?.map((cover) => {
                    return (
                        <WrapItem key={cover.key}>
                            <VStack key={cover.key} w="109px" _hover={{ cursor: 'pointer', fontWeight: 'semibold', textDecor: 'underline' }} onClick={() => handleCollectionSave(cover.key)}>
                                <Img
                                    w="full"
                                    boxShadow="sm"
                                    _hover={{ boxShadow: "base" }}
                                    alt={cover.name}
                                    src={"data:image/svg+xml;base64," + btoa(getTemplateWithColor(cover.key, { d: page }))}
                                />
                                <Text w="full" fontSize="xs" fontWeight="semibold" textAlign="center" noOfLines={1}>
                                    {cover.name}
                                </Text>
                            </VStack>
                        </WrapItem>
                    )
                })}
            </Wrap>
        </Box>
    )
})