import { Box, Button, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, VStack } from "@chakra-ui/react"

export const EstimatePopover = ({ data }) => {
    const googleDomain = 'https://google.com/search?hl=en-US&rls=en&q=';
    const requestUrl = `${googleDomain}site:${data?.url}+-.pdf+-.doc+-.jpg+-.png+-.xml+-.txt+-.xslx+-.csv+-.wav+-.mp3`;
    return (
        <Popover trigger="hover" openDelay={250}>
            <PopoverTrigger>
                <Button
                    variant="link"
                    fontWeight="normal"
                    color="fg.muted"
                    fontSize="15px"
                    textDecor="underline"
                    textDecorationColor="rarchy-border-400"
                    textUnderlineOffset={3}
                >
                    estimated
                </Button>
            </PopoverTrigger>
            <Portal>
                <PopoverContent mt={1}>
                    <PopoverArrow />
                    <PopoverBody py={3} fontSize="sm" color="fg.muted">
                        <VStack align="start" spacing={1}>
                            <Box>We use Google's <Link target="_blank" href={requestUrl} color="rarchy-link" textDecor="underline" fontWeight="medium" textUnderlineOffset={3}>site: command</Link> to estimate the number of pages we could crawl.</Box>
                            <Box fontSize="13px" color="fg.subtle">We exclude file extensions such as '.pdf', '.xml'.</Box>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}