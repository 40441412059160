import React, { useEffect, useState } from 'react';
import {
    PopoverHeader,
    InputGroup,
    InputLeftElement,
    Input,
} from "@chakra-ui/react"
import { SearchIcon } from '@chakra-ui/icons'

import { mapFilteredSubfolders } from '../helpers'

export const Search = ({ isOpen, subfolders, setFilteredSubfolders, setExpanded, expandNodesToLevel }) => {
    const [value, setValue] = useState('')
    useEffect(() => { if (!isOpen && value !== '') setValue('') }, [isOpen, value])
    return (
        <PopoverHeader>
            <InputGroup size="sm">
                <InputLeftElement w={5} pointerEvents="none" children={<SearchIcon color="gray.400" />} />
                <Input
                    type="text"
                    value={value}
                    placeholder="Search..."
                    border="none"
                    pl="30px"
                    onChange={(e) => {
                        try {
                            setValue(e.target.value)
                            const filtered = mapFilteredSubfolders(subfolders, e.target.value.toLowerCase())
                            setFilteredSubfolders(filtered)
                            setExpanded(expandNodesToLevel(filtered, Infinity)) 
                        } catch (e) {
                            console.error(e)
                        }
                    }}
                />
            </InputGroup>
        </PopoverHeader>
    )
}