import { useCanEditFile, useGetSitemap, useInUserFlow } from '../../../../hooks';

import { AddIcon } from '@chakra-ui/icons';
import {
    IconButton
} from "@chakra-ui/react"
import { addFromButton } from '../../utils/edit';
import { getPageColor } from '../canvas/utils/helpers';
import { getPalletteColors } from '../../../../store/actions/editor-actions';
import { mouseover } from "../canvas/utils/listeners";
import { renderInteractionsCanvas } from '../canvas/canvases';
import { showEditorPermissionsToast } from '../../../Editor/App/Components/PermissionsToast';
import { togglePageButtons } from '../../../../store/actions/sitemap-actions';
import { transform } from '../canvas/utils/zoom';
import { useDispatch } from 'react-redux';

export const NewPageButton = ({ d, x, y, side }) => {

    const dispatch = useDispatch()
    const sitemap = useGetSitemap()

    const inUserFlow = useInUserFlow()

    const canEditFile = useCanEditFile()
    if (!canEditFile) return null

    const color = "rarchy-bg-sitemap-editor"
    
    const willRender = shouldRender({ d, side, sitemap })
    if (!willRender) return null; // don't render

    const showCovers = inUserFlow ? true : sitemap?.showCovers
    const targetButtonWidth = Math.ceil((showCovers ? 28 : 24) * transform.k)
    const buttonWidth = (targetButtonWidth % 2 === 0) ? targetButtonWidth : targetButtonWidth + 1
    const left = getLeft({ sitemap, d, x, side, buttonWidth }), top = getTop({ sitemap, d, y, side, buttonWidth })

    const header = getPageColor(d)
    const pallette = getPalletteColors(header)

    return (d.parent || inUserFlow) ? (
        <IconButton
            color={color}
            icon={renderIcon(buttonWidth)}
            h={`${buttonWidth}px`}
            minW={`${buttonWidth}px`}
            left={left}
            top={top}
            bgColor={header}
            outline={`${2 * transform.k}px solid`}
            outlineColor={color}
            _hover={{ bgColor: pallette[5] }}
            _active={{ bgColor: pallette[5] }}
            rounded="full"  
            pos="absolute"
            onClick={(e) => {
                e.stopPropagation()
                clickAddPage({ node: d, side })
                dispatch(togglePageButtons({ showing: false }))
            }}
            paddingInlineStart={0}
            paddingInlineEnd={0}
        />
    ) : null
}

const getLeft = ({ sitemap, d, x, side, buttonWidth }) => {
    if (side === 'left') return x + (d.leftOfNode * transform.k) - (buttonWidth / 0.85) // return x + ((45) * transform.k) - (58.5 * transform.k)
    if (side === 'right') {
        if (sitemap?.format === 'tree-vertical-matrix') {
            if (d.depth > 1) return x - ((sitemap?.showCovers ? 3 : 0) * transform.k) + (buttonWidth / 2)
        }
        return x + ((d.rightOfNode + (d._children ? 5 : 0)) * transform.k) + (buttonWidth * 0.15)// (212 * transform.k) 
    }
    if (side === 'below' || side === 'above') return x + ((sitemap?.showCovers ? 99.25 : 105) * transform.k)
}

const getTop = ({ sitemap, d, y, side, buttonWidth }) => {
    if (side === 'left') return (y + ((sitemap?.showCovers ? 112.5 : d.nodeHeight / 2) * transform.k) - (1 * transform.k))
    if (side === 'right') {
        if (sitemap?.format === 'tree-vertical-matrix') {
            if (d.depth > 1) return (y + (d.nodeHeight * transform.k)) - (7.5 * transform.k);
        }
        return (y + ((sitemap?.showCovers ? 112.5 : d.nodeHeight / 2) * transform.k) - (1 * transform.k))
    }
    if (side === 'below') {
        return (y + ((d.nodeHeight + (d._children ? 5 : 0)) * transform.k)) + (buttonWidth * 0.175)
    }
    if (side === 'above') return y - (buttonWidth * 1.15);
}

const renderIcon = (buttonWidth) => {
    const targetFontSize = Math.floor(buttonWidth * 0.5)
    const fontSize = (targetFontSize % 2 === 0) ? targetFontSize : targetFontSize + 1;
    return <AddIcon fontSize={fontSize} />
}

const shouldRender = ({ side, d, sitemap }) => {

    if (side === 'left') {
        if (!d.parent) return;
        if (sitemap?.format === 'tree' || sitemap?.format === 'indent') return;
        if (sitemap?.format === 'tree-vertical-matrix' && d.depth > 1) return;
        return true;
    }

    if (side === 'right') {
        if (sitemap?.format === 'tree' || sitemap?.format === 'indent') {
            if (d.children || d._children) return;
        }
        if (sitemap?.format === 'tree-vertical') {
            if (!d.parent) return;
        }
        if (sitemap?.format === 'tree-vertical-matrix') {
            if (d.depth <= 1) {
                if (!d.parent) return;
            } else if (d.depth > 1) {
                if (d.children || d._children) return;
            }
        }
        return true;
    }

    if (side === 'above') {
        if (!d.parent) return;
        if (sitemap?.format === 'tree-vertical') return;
        if (sitemap?.format === 'tree-vertical-matrix') {
            if (d.depth <= 1) return;
        }
        return true;
    }

    if (side === 'below') {
        if (sitemap?.format === 'tree') {
            if (!d.parent) return;
        }
        if (sitemap?.format === 'tree-vertical') {
            if (d.children || d._children) return;
        }
        if (sitemap?.format === 'tree-vertical-matrix') {
            if (!d.parent) {
                if (d.children || d._children) return;
            }
            if (d.depth <= 1) {
                if (d._children) return;
            }
        }
        return true;
    }

}

const clickAddPage = (add) => {

    // can edit
    const canEdit = showEditorPermissionsToast(); if (!canEdit) return null;
    // continue

    const { node, side } = add;
    if (node) addFromButton(node, side);

    /*** reset canvas ***/
    mouseover.node = null;
    mouseover.options = {};
    renderInteractionsCanvas();
    /*** reset canvas ***/

}