import { store } from "../../../../../store";
import { getEditor, getUI, getUserFlow } from "../../../../../helpers";
import { toggleUserFlowSymbolButtons } from "../../../../../store/actions/flow-actions";
import { getCanEditInEditor } from "../../../../Editor/Navbar/helpers";
import { renderDrag, renderHeader, renderOptions } from "../../../app/canvas/components";
import { dragging } from "../../../app/canvas/utils/drag";
import { mousePos, mouseover } from "../../../app/canvas/utils/listeners";
import { keepScale, transform } from "../../../app/canvas/utils/zoom";
import { setSelectedUserFlowSymbols } from "../../helpers";
import { drawArrowhead, drawLinks } from "../connectors";
import { getConnectorX, getConnectorY } from "../connectors/helpers";
import { renderUserFlowPage, renderUserFlowSymbol } from "../nodes";
import copy from 'fast-copy';
import { compact } from 'lodash'

export const renderMultiSelectBox = (interactionsCanvas, e) => {

    const ui = getUI()
    
    const editor = getEditor()
    const flow = getUserFlow()

    const { MultiSelectBox } = flow.utils;
    // render
    interactionsCanvas.strokeStyle = editor?.pallette?.header;
    interactionsCanvas.fillStyle = ui.colorMode === 'light' ? editor?.pallette.colors[0] : "#ffffffeb";
    interactionsCanvas.globalAlpha = ui.colorMode === 'light' ? 0.25 : 0.05;
    interactionsCanvas.lineWidth = keepScale(1);
    // calculate the rectangle width/height based
    // on starting vs current mouse position
    var width = mousePos.x - MultiSelectBox.x;
    var height = mousePos.y - MultiSelectBox.y;
    // draw a new rect from the start position 
    // to the current mouse position
    interactionsCanvas.beginPath();
    interactionsCanvas.roundRect(MultiSelectBox.x, MultiSelectBox.y, width, height, 5);
    interactionsCanvas.fill();
    interactionsCanvas.stroke();
    // restore
    interactionsCanvas.globalAlpha = 1;
    interactionsCanvas.restore();
    //
    const left = MultiSelectBox.x < mousePos.x ? MultiSelectBox.x : mousePos.x;
    const top = MultiSelectBox.y < mousePos.y ? MultiSelectBox.y : mousePos.y;
    const right = MultiSelectBox.x > mousePos.x ? MultiSelectBox.x : mousePos.x;
    const bottom = MultiSelectBox.y > mousePos.y ? MultiSelectBox.y : mousePos.y;

    const nodes = compact(copy(flow?.data?.nodes).map((d, i) => {
        const inBox = (d.leftOfNode >= left) && ((d.rightOfNode) <= (right)) && (d.topOfNode >= top) && ((d.bottomOfNode) <= bottom)
        if (inBox) return d.id;
    }))

    setSelectedUserFlowSymbols(nodes);

}

export const mouseoverUserFlowSymbol = (interactionsCanvas, e) => {

    const editor = getEditor()
    const flow = getUserFlow()

    const { SymbolButtons } = flow.ui;
    const { NewConnectorLine, NewSymbol, MultiSelectBox } = flow.utils

    const canEdit = getCanEditInEditor()

    if (editor?.ui?.RevisionHistoryDrawer?.showing) return;

    if (NewSymbol?.dragging) {
        if (e) {
            if (NewSymbol?.symbol?.includes('page')) {
                const nodeHeight = NewSymbol?.symbol === 'page' ? 267 : 117;
                renderUserFlowPage({ context: interactionsCanvas, opts: {}, d: { id: 'new-symbol', type: NewSymbol?.symbol, x: transform.invertX(e.offsetX) - (225 / 2), y: transform.invertY(e.offsetY) - (nodeHeight / 2), nodeHeight } });
            } else {
                renderUserFlowSymbol({ context: interactionsCanvas, opts: {}, d: { id: 'new-symbol', type: NewSymbol?.symbol }, x: transform.invertX(e.offsetX) - 67.5, y: transform.invertY(e.offsetY) });
            }
        }
        return interactionsCanvas.restore();
    }

    if (dragging && dragging.started) {
        renderDrag(dragging);
        return interactionsCanvas.restore();
    };

    // render node
    const node = mouseover?.node || mouseover.userFlowNode // page or symbol
    if (node) {
        // don't show options if multi-selecting or selected
        if (!MultiSelectBox.showing && !node._selected) {
            // page only
            if (mouseover.node) {
                renderOptions(mouseover.node)
                renderHeader(interactionsCanvas, mouseover.node);
            }
            // symbols + pages
            const opts = mouseover.section ? { ...mouseover.section } : { node };
            if (!SymbolButtons.showing || (SymbolButtons.showing && (SymbolButtons.node.id !== node.id)
                || (!SymbolButtons.section && opts.section || (SymbolButtons.section && opts.section && ((SymbolButtons.section.id !== opts.section.id)) || SymbolButtons.section && !opts.section))
            )) {
                // don't show for dragging new connector node
                if (!NewConnectorLine.showing || (NewConnectorLine.showing && NewConnectorLine.node.id !== node.id)) {
                    if (canEdit) {
                        store.dispatch(toggleUserFlowSymbolButtons({ showing: true, ...opts }))
                    }
                }
            }
        }
    }

    // set line type
    var type = localStorage.getItem(`userFlowConnectorType`); type === type ? type : 'solid';

    // render new connector line
    if (NewConnectorLine.showing) {
        if (!NewConnectorLine.docked) { // currently drawing
            if (e) {
                const sx = getConnectorX(NewConnectorLine.node, NewConnectorLine.section, NewConnectorLine.startPos);
                const sy = getConnectorY(NewConnectorLine.node, NewConnectorLine.section, NewConnectorLine.startPos);
                interactionsCanvas.strokeStyle = editor?.pallette?.header
                interactionsCanvas.lineWidth = 2;
                interactionsCanvas.beginPath();
                if (type === 'dash') interactionsCanvas.setLineDash([12, 6]);
                interactionsCanvas.moveTo(sx, sy);
                interactionsCanvas.lineTo(transform.invertX(e.offsetX), transform.invertY(e.offsetY));
                interactionsCanvas.stroke();
                interactionsCanvas.setLineDash([]); // reset line dash
                drawArrowhead({ context: interactionsCanvas, from: { x: sx, y: sy }, to: { x: transform.invertX(e.offsetX), y: transform.invertY(e.offsetY) }, radius: 7.5, fillStyle: editor?.pallette?.header })
            }
        }
        else {
            drawLinks({ context: interactionsCanvas, link: {}, startNode: NewConnectorLine.node, startSection: NewConnectorLine.section, startPos: NewConnectorLine.startPos, endNode: NewConnectorLine.docked.node, endSection: NewConnectorLine.docked.section, endPos: NewConnectorLine.docked.endPos, type, hidden: false })
        }
    }
    interactionsCanvas.restore();
}