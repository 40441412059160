import { Box, FormControl, FormLabel, Input, InputGroup, InputRightElement, Stack, Text, Textarea, useColorModeValue as mode } from '@chakra-ui/react'
import React, { Component } from 'react';
import { getInUserFlow, getSitemap, getUser } from '../../../../../helpers';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers';
import { getPageId } from '../../canvas/utils/helpers';
import { saveSeoChange } from '../../../../../store/actions/sitemap-actions';
import { showEditorPermissionsToast } from '../../../../Editor/App/Components/PermissionsToast';
import { store } from '../../../../../store';

class SEOContainer extends Component {

    state = {
        title: { value: '', length: 0, extracting: false },
        description: { value: '', length: 0, extracting: false },
        h1: { value: '', extracting: false },
        keywords: { value: '' },
        extracted: ''
    }

    componentDidMount() {

        const { sitemap } = this.props;
        const { seo } = sitemap?.data;
        const { PageDrawer } = sitemap?.ui;
        const pageId = getPageId(PageDrawer.page);
        const pageData = seo[pageId] ? seo[pageId] : {}

        const title = pageData.title ? decodeURIComponent(pageData.title) : '';
        const description = pageData.description ? decodeURIComponent(pageData.description) : '';
        const h1 = pageData.h1 ? decodeURIComponent(pageData.h1) : '';
        const keywords = pageData.keywords ? decodeURIComponent(pageData.keywords) : '';

        if (pageData) {
            this.setState({
                title: { ...this.state.title, value: title, length: title ? title.length : 0 },
                description: { ...this.state.description, value: description, length: description ? description.length : 0 },
                h1: { ...this.state.h1, value: h1, length: h1 ? h1.length : 0 },
                keywords: { ...this.state.keywords, value: keywords }
            })
        }
    }

    handleChange = async (text, key) => {
        // can edit
        if (!showEditorPermissionsToast()) return;
        // continue
        this.props.saveSeoChange(text, key);
    }

    handleExtract = async (key) => {

        const { sitemap, canEdit, inUserSitemap } = this.props;
        const { url } = sitemap?.ui.PageDrawer;
        if (!canEdit || inUserSitemap || this.state[key].extracting || this.state[key].saving) return;
        this.setState({ [key]: { ...this.state[key], extracting: true } })
        // set tags obj
        var tags = {}
        // already have data from previous extract
        if (this.state.extracted) {
            await sleep(1000);
            tags.data = this.state.extracted;
            if (tags.data[key]) this.handleChange(tags.data[key], key);
        } else {
            // get tags data
            tags = await store.firebase.functions().httpsCallable('sitemaps-seo-getPageTags')({ url, key });
            if (tags.data) {
                this.setState({ extracted: tags.data });
                if (tags.data[key]) this.handleChange(tags.data[key], key);
            }
        }
        // set state and update value for user
        this.setState({
            [key]: {
                ...this.state[key],
                value: tags.data[key] ? tags.data[key] : this.state[key].value,
                length: tags.data[key] ? tags.data[key].length : this.state[key].value ? this.state[key].value.length : 0,
                extracting: false
            }
        })
        // send to GA
        const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `SEO: Extracted ${key} ` } };
        window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
    }

    render() {
        const { canEdit } = this.props;
        return (
            <Box pl={1} pb={3} px={0}>
                <Stack spacing={7} w="full" mt={3}>
                    <FormControl id="title" w="full">
                        <FormLabel fontSize="sm" fontWeight="semibold" mb={1.5}>Page Title</FormLabel>
                        <InputGroup>
                            <Input
                                variant="flushed"
                                paddingEnd={20}
                                fontSize="sm"
                                value={this.state.title.value}
                                onChange={(e) => { if (canEdit) this.setState({ title: { ...this.state.title, value: e.target.value, length: e.target.value.length } }) }}
                                onBlur={(e) => this.handleChange(e.target.value, 'title')}
                                placeholder="A unique title that describes the content of this page"
                            />
                            <InputRightElement
                                mb={1.5}
                                mr={3}
                                w="fit-content"
                                children={
                                    <Text fontSize="sm" fontWeight="semibold" color={GetLengthColor('title', this.state.title.length, mode)}>
                                        {`${this.state.title.length} / 55`}
                                    </Text>
                                } />
                        </InputGroup>
                    </FormControl>
                    <FormControl id="h1" w="full">
                        <FormLabel fontSize="sm" fontWeight="semibold" mb={1.5}>H1 Tag</FormLabel>
                        <Input
                            variant="flushed"
                            fontSize="sm"
                            value={this.state.h1.value}
                            onBlur={(e) => this.handleChange(e.target.value, 'h1')}
                            placeholder="The most important heading, usually the name/title of this page"
                            onChange={(e) => { if (canEdit) this.setState({ h1: { ...this.state.h1, value: e.target.value } }) }}
                        />
                        {/* </Stack> */}
                    </FormControl>
                    <FormControl id="meta" w="full">
                        <FormLabel fontSize="sm" fontWeight="semibold" mb={1.5}>Meta Description</FormLabel>
                        <InputGroup>
                            <Textarea
                                fontSize="sm"
                                pr="84px"
                                value={this.state.description.value}
                                onChange={(e) => { if (canEdit) this.setState({ description: { ...this.state.description, value: e.target.value, length: e.target.value.length } }) }}
                                onBlur={(e) => this.handleChange(e.target.value, 'description')}
                                placeholder="A short piece of descriptive text that summarizes the content of this page for search engines"
                            />
                            <InputRightElement
                                mb={1.5}
                                mr={3}
                                w="fit-content"
                                children={
                                    <Text fontSize="sm" fontWeight="semibold" color={GetLengthColor('description', this.state.description.length, mode)}>
                                        {`${this.state.description.length} / 155`}
                                    </Text>
                                } />
                        </InputGroup>
                    </FormControl>
                    <FormControl id="keywords" w="full">
                        <FormLabel fontSize="sm" fontWeight="semibold" mb={1.5}>Focus Keywords</FormLabel>
                        <Textarea
                            color="fg.subtle"
                            fontSize="sm"
                            value={this.state.keywords.value}
                            onChange={(e) => { if (canEdit) this.setState({ keywords: { ...this.state.keywords, value: e.target.value } }) }}
                            onBlur={(e) => this.handleChange(e.target.value, 'keywords')}
                            placeholder="The keywords you want this page to rank for"
                        />
                        {/* </Stack> */}
                    </FormControl>
                </Stack>
            </Box>
        )
    }
}

const getDefaultValue = (value) => {
    if (value) return decodeURIComponent(value);
    return '';
}

const getExtractText = (state, key) => {
    if (state[key].extracting) return 'Extracting...';
    return 'Extract';
}

const GetLengthColor = (key, length, mode) => {
    if (key === 'title' && length > 55) return 'red.500';
    if (key === 'description' && length > 155) return 'red.500';
    if (length > 0) return 'green.500';
    return 'gray.500'; // mode('gray.600', 'whiteAlpha.200');
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const mapStateToProps = state => {
    const sitemap = getSitemap()
    const inUserFlow = getInUserFlow()
    const canEdit = getCanEditInEditor(inUserFlow ? sitemap : null)
    const user = getUser()
    const inUserSitemap = sitemap?.id === user.id;
    return {
        ...state,
        canEdit,
        inUserSitemap
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ saveSeoChange }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SEOContainer);