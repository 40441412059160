import { Badge, Box, Center, Icon, Image, Stack, Text, Tooltip, WrapItem, useColorMode } from "@chakra-ui/react"
import { BsFileRichtext, BsFileText } from "react-icons/bs"
import { SkeletonWrapper } from "../../Components/Shared/SkeletonWrapper"
import { useDispatch } from "react-redux"
import { toggleTemplateDrawer } from "../../../store/actions/ui-actions"
import { TEMPLATE_INDUSTRY_ICONS } from "./Topbar/Menus/Industry"

export const TemplateCard = ({ template }) => {
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const isSections = template?.type === 'sections'
    return (
        <WrapItem
            mt={3}
            _hover={{ cursor: 'pointer' }}
        >
            <Stack w="full" overflow="hidden" mt={0} spacing={0}>
                <Stack onClick={() => dispatch(toggleTemplateDrawer({ showing: true, template: { ...template, sitemap: { format: 'tree-vertical', docs: template.docs, showCovers: !isSections } } }))}>
                    <Box rounded="md" bg="rarchy-color-file" p={3}>
                        <SkeletonWrapper w="fit-content">
                            <Center
                                bg="rarchy-bg-sitemap-editor"
                                p={2}
                                border="1px solid"
                                borderColor="rarchy-border"
                                rounded="md"
                            >
                                <Center w={`240px`} h={`160px`} overflow="hidden" alignItems="baseline">
                                    {/*** this is where the image goes ***/}
                                    <Image
                                        src={`https://help.rarchy.com/hubfs/Templates/Thumbnails/${template?.id}-${template?.type}-${colorMode}.jpeg`}
                                        height="inherit"
                                        objectFit="contain"
                                    />
                                </Center>
                            </Center>
                        </SkeletonWrapper>
                    </Box>
                    <SkeletonWrapper w="fit-content">
                        <Tooltip label={template?.label} variant="rounded-sm" openDelay={500} hasArrow>
                            <Box fontSize="sm">{template?.label}</Box>
                        </Tooltip>
                    </SkeletonWrapper>
                </Stack>
                <Stack direction="row" align="center" mt={1} spacing={3} color="fg.muted">
                    <SkeletonWrapper w="fit-content">
                        <Badge fontWeight="normal" textTransform="initial" px={1.5} color="fg.muted" lineHeight="19px">
                            <Stack direction="row" align="center" spacing={1}>
                                <Icon as={TEMPLATE_INDUSTRY_ICONS[template?.industry]} fontSize="xs" />
                                <Text>{template?.industry}</Text>
                            </Stack>
                        </Badge>
                    </SkeletonWrapper>
                    <SkeletonWrapper w="fit-content">
                        <Badge fontWeight="normal" textTransform="initial" px={1.5} colorScheme={isSections ? "cyan" : "twitter"} lineHeight="19px">
                            <Stack direction="row" align="center" spacing={1.5}>
                                <Icon as={isSections ? BsFileText : BsFileRichtext} fontSize="xs" />
                                <Text fontSize="xs">{isSections ? "Page Sections" : "Covers"}</Text>
                            </Stack>
                        </Badge>
                    </SkeletonWrapper>
                </Stack>
            </Stack>
        </WrapItem>
    )
}