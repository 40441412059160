import { dragging } from '..';
import { createRoot, setIndexesAndGetNodesLinks } from '../../../../../utils/app';
import { updateNodesHeights, updateTreeLayout, } from '../../../../views/index';
import { render as renderPages } from '../../../render';
import { getSitemap } from '../../../../../../../helpers';

export { insertPlaceholderForVerticalTree } from './tree-vertical';
export { insertPlaceholderForVerticalTreeMatrix } from './tree-vertical-matrix';
export { insertPlaceholderForIndent } from './indent';
export { insertPlaceholderForTree } from './tree';

export const getNewPositionAndRenderPlaceholder = async (nodesWithPlaceholder) => {

    const sitemap = getSitemap();

    var nodesIntoPages = nodesWithPlaceholder.map(d => { return { ...d, parent: d.parent ? d.parent.id : null }; }).filter(d => {
        return d.id !== dragging.id && !d.hideWhileDragging; // remove dragged node and any children from nodes
    });

    const root = await createRoot(nodesIntoPages);
    
    let { nodes: nodesForDrag, links } = setIndexesAndGetNodesLinks(root, sitemap);

    nodesForDrag = updateNodesHeights(nodesForDrag);

    // tree
    if (sitemap?.format.includes('tree')) updateTreeLayout(nodesForDrag, root);

    var updatedNodesWithNewLayout = nodesWithPlaceholder.map(d => {
        var updatedNode = nodesForDrag.find(node => node.id === d.id);
        if (updatedNode) {
            d.x = updatedNode.x;
            d.y = updatedNode.y;
        }
        return d;
    });

    renderPages(updatedNodesWithNewLayout, links);

    return updatedNodesWithNewLayout;

}