import { getBase64Image, getInUserFlow, getSitemap, getUI } from '../../../../../helpers';
import { getCustomCover, getDeviceByPage, getPageColor, getPageId, getX, getY, shouldShowCovers } from '../utils/helpers';
import { renderChildRect, renderNodeRect } from './node';

import { barWidth } from '../..';
import { dragging } from '../utils/drag';
import { getTemplateWithColor } from '../../covers/collections';
import { transform, } from '../utils/zoom';

export const loaded_images = {};
var loaded_wireframes = {};

export const renderCover = async (context, d, visible, exp) => {

    const sitemap = getSitemap()
    const { covers } = sitemap

    const ui = getUI()

    const inUserFlow = getInUserFlow()
    const nodeId = getPageId(d)

    const showCover = shouldShowCovers(d);
    if (!showCover) return;

    if ((dragging && (nodeId === dragging.id || nodeId === 'dragging-placeholder')) && context.canvas.id !== 'interactions-canvas') return // don't show cover when dragging that page

    // render rects here (if not in user flows)
    if (!inUserFlow) {
        if (!dragging || (dragging && (nodeId !== dragging.id))) {
            renderChildRect(context, sitemap, ui, d, false) // ensure this is first so the filled node rect covers it
            renderNodeRect(context, d, false) // this is filled and then the cover is drawn over the top (so it's not transparent when dragging)
        }
    }

    const device = getDeviceByPage(d);

    const customCover = getCustomCover(d);
    var x = getX(d), y = getY(d) + (112.5 - (device !== 'mobile' ? 94.5 : 112.5));

    const pageColor = getPageColor(d);
    const wireframeKey = getWireframeKey(d, sitemap);

    // if (inUserFlow) return; // for testing

    // background
    context.fillStyle = ui.colorMode === 'light' ? 'white' : '#1A202C';
    roundRect(context, getX(d) + 1.1, getY(d) + 1, barWidth - 2, (d.nodeHeight - d.textRectHeight), device === 'desktop' ? 4 : 14) //device === 'desktop' ? 5 : 15) 

    // account for refreshed sitemap pallette
    if (loaded_wireframes?.[wireframeKey + pageColor] && (loaded_wireframes?.[wireframeKey + pageColor].pallette !== pageColor)) delete loaded_wireframes[wireframeKey + pageColor];

    /*** already loaded wireframe / image ***/
    if (customCover) {
        if (loaded_images?.[`${nodeId}#${customCover.generation}`]) {
            const image = loaded_images?.[`${nodeId}#${customCover.generation}`];
            if (!image || image?.loading || image?.error) return;
            if (visible) {
                if (device === 'mobile') {
                    context.save();
                    roundedImage(context, x + 1, y + 1, barWidth - 2, barWidth, 16);
                    context.clip();
                }
                context.drawImage(image.base_image, x + 1, y, barWidth - 2, barWidth);
                if (device === 'mobile') context.restore();
            }
            if (exp) {
                const base64 = await getBase64Image(image.base_image.src)
                if (base64) return { [image.base_image.src]: base64 };
            }
            return;
        }
    } else {
        if (loaded_wireframes[wireframeKey + pageColor]) {
            y = getY(d);
            if (visible) {
                context.drawImage(loaded_wireframes[wireframeKey + pageColor].base_image, x, y, barWidth, barWidth);
            }
            return;
        }
    }
    /*** already loaded wireframe / image ***/

    /*** load wireframe / image ***/
    if (!loaded_wireframes?.[wireframeKey + pageColor] || !loaded_images?.[`${nodeId}#${customCover.generation}`]) {

        var image;
        const base_image = new Image();

        if (customCover) {
            image = { type: 'image', src: customCover.thumbDownloadURL }
        } else {
            // get the collection
            let wireframeSrc = getTemplateWithColor(wireframeKey, { d })
            if (wireframeSrc) {
                try {
                    image = { key: wireframeKey, type: 'wireframe', src: "data:image/svg+xml;base64," + btoa(wireframeSrc) }
                } catch (e) {
                    image = { key: covers?.pages?.default, type: 'wireframe', src: "data:image/svg+xml;base64," + btoa(getTemplateWithColor(covers?.pages?.default, { d })) }
                }
            }
        };

        if (!image) return;

        if (image?.src) {
            base_image.src = image.src;
            base_image.setAttribute('crossOrigin', '');
        }

        // load wireframe
        if (image?.type === 'wireframe') await base_image.decode();

        // try and load image
        try {
            if (image?.type === 'image') {
                loaded_images[`${nodeId}#${customCover.generation}`] = { loading: true };
                await base_image.decode();
            }
        } catch (e) {
            if (image?.type === 'image') {
                loaded_images[`${nodeId}#${customCover.generation}`] = { error: e };
            }
            return;
        }

        if (d.parent && d.parent._children) return; // node is no longer on screen

        if (image?.type === 'wireframe') {
            loaded_wireframes[image.key + pageColor] = { key: image.key, type: image.type, base_image, pallette: pageColor };
            // eslint-disable-next-line
            x = transform.x + getX(d) * transform.k, y = transform.y + getY(d) * transform.k;
            if (visible) context.drawImage(base_image, x, y, barWidth * transform.k, barWidth * transform.k);
        } else {
            loaded_images[`${nodeId}#${customCover.generation}`] = { type: image?.type, base_image };
            // eslint-disable-next-line
            x = transform.x + x * transform.k, y = transform.y + y * transform.k;
            if (visible) {
                if (!exp) {
                    if (device === 'mobile') {
                        context.save();
                        roundedImage(context, x, y + 1, barWidth * transform.k, barWidth * transform.k, 16);
                        context.clip()
                    };
                    context.drawImage(base_image, x, y, barWidth * transform.k, barWidth * transform.k);
                    if (device === 'mobile') context.restore();
                }
            }
            if (exp) {
                const base64 = await getBase64Image(base_image.src);
                if (base64) return { [base_image.src]: base64 };
            }
        }

    }

    /*** load wireframe / image ***/

}

const getWireframeKey = (node, { covers }) => {
    var key;
    if (node.wireframe) key = node.wireframe // non-linked pages in user flows - has to be first
    const nodeId = getPageId(node)
    if (covers.pages[nodeId]) {
        if (covers?.pages?.[nodeId]?.collection) {
            key = covers.pages[nodeId].collection;
        }
    }
    // use default key if no collection selected
    if (!key) key = covers.pages.default ? covers.pages.default : 'landing-pages/content-4';
    return key;
}

const drawPendingCoverCapture = (context, d) => {

    context.fillStyle = getPageColor(d)
    context.textAlign = 'center';

    context.font = `42px FontAwesome`;
    context.fillText('\uf030', getX(d) + 112, getY(d) + 112.5);

    context.font = '500 30px Inter,sans-serif';
    context.fillText(`Capturing`, getX(d) + 112, getY(d) + 112.5 + 50);
    context.textAlign = 'start'; // reset context text-align
}

function roundedImage(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - 0, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - 0);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
}

const roundRect = (ctx, x, y, width, height, radius) => {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, 0); // radius for full
    ctx.arcTo(x, y + height, x, y, 0); // radius for full
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.fill();
};