import {
    Button,
    IconButton,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import { connect, useDispatch, useSelector } from 'react-redux';

import React from 'react';
import { WEBSITE_SECTIONS_MARGIN } from '../../../../views/index';
import { add } from '../../../../../utils/edit/add';
import { handleAddWebsiteSection } from '../options/Add';
import { toggleWebsiteSectionNewButton } from '../../../../../../../store/actions/sitemap-actions';
import { transform } from '../../../utils/zoom';
import { useGetSitemap } from '../../../../../../../hooks';

/*** popover ****/
export const WebsiteSectionNewButton = () => {
    const dispatch = useDispatch();
    const sitemap = useGetSitemap()
    const { WebsiteSectionNewButton } = sitemap?.ui;
    const { section, offset } = WebsiteSectionNewButton;
    const width = 225;
    const height = sitemap?.showCovers ? 265 : 104;
    const newSection = section?.newSectionPlaceholder;
    const newPage = section?.newPagePlaceholder;
    const color = newSection ? sitemap?.pallette?.header : sitemap?.pallette.colors[3]
    const isTree = sitemap?.format === 'tree';
    return (
        <Button
            pos="absolute"
            p={0}
            fontSize={`${17 * transform.k}px`}
            minW={`${width * transform.k}`}
            h={`${(newSection ? 60 : height) * transform.k}px`}
            left={`${offset?.[0] - (!isTree ? ((width / 2) * transform.k) : 0)}px`}
            top={`${offset?.[1] + ((newSection ? -27 : WEBSITE_SECTIONS_MARGIN) * transform.k)}px`}
            onClick={() => {
                dispatch(toggleWebsiteSectionNewButton({ showing: false }));
                if (newSection) handleAddWebsiteSection({ newSectionIndex: 0, website_sections: {}, dispatch });
                if (newPage) add({ website_section: section.id });
            }}
            border={`${2 * transform.k}px solid`}
            color={color}
            _hover={{ color: sitemap?.pallette?.header, bgColor: "rarchy-bg-subtle" }}
            borderColor={color}
            borderRadius={`${5 * transform.k}px`}
            bgColor="transparent"
            variant="ghost"
        >
            {newSection ? 'Add Website Section' : 'Add Page'}
        </Button>
    )
};

{/* <IconButton
            size="sm"
            variant="ghost"
            icon={<AddIcon fontSize="xs" />}
            left={offset[0]}
            top={offset[1]}
    /> */}