import { useEffect } from "react";
import { Options } from "./Options"
import { Pages } from "./Pages"
import { Preview } from "./Preview"

export const Steps = ({ activeStep, setActiveStep, checked, setChecked, options, setOptions }) => {
    // component will unmount
    useEffect(() => { return () => { setActiveStep(0); } }, []);
    return (
        <>
            <Pages activeStep={activeStep} checked={checked} setChecked={setChecked} />
            <Options activeStep={activeStep} options={options} setOptions={setOptions} />
            {activeStep === 2 && <Preview activeStep={activeStep} options={options} setOptions={setOptions} checked={checked} />}
        </>
    )
}