import { Box, Center, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { useGetEditorSubfolderFromDoc, useGetFilesDataByFolder, useGetFoldersData, useGetItemNameFromDoc, useGetPeople, useInTrash, useIsViewingFolder } from "../../../../../hooks";

import { FileMenu } from "../../../../Files/Components/Options/Menu";
import { FileName } from "../../../../Files/Components/Name";
import { FileThumbnail } from "../../../../Files/Components/Thumbnail";
import { FolderBadge } from "../../../../Files/Components/FolderBadge";
import { FolderComponent } from "../../../Folders/Components/Folder";
import { FolderMenu } from "../../../Folders/Components/Options/Menu";
import { Folders } from "../../../Folders";
import { Link } from "react-router-dom"
import { ProjectsView } from "../../ProjectsView";
import { SharedAvatarGroup } from "../../../../Components/Shared/AvatarGroup";
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper";
import dayjs from 'dayjs';
import { getUsersFullName } from "../../../../../helpers";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useHistory } from "react-router-dom";
import { useMemo } from "react";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

const locale = { name: 'en-long', relativeTime: { future: "in %s", past: "%s", s: 'just now', m: "a few minutes ago", mm: "%d minutes ago", h: "a few hours ago", hh: "%d hours ago", d: "a day ago", dd: "%d days ago", M: "a month ago", MM: "%d months ago", y: "a year ago", yy: "%d years ago" } } // Your Day.js locale Object.

dayjs.locale(locale, null, true) // load locale for later use

export const SectionsList = ({ tab }) => {
    const files = useGetFilesDataByFolder()
    const isViewingFolder = useIsViewingFolder()
    const filteredFiles = useMemo(() => {
        const isAll = tab?.id === 'all'
        return files?.filter(f => {
            if (!isAll && f?.collection !== tab?.id) return;
            return f;
        })
    }, [files, tab?.id])
    return (
        <TableContainer mt={7}>
            <Table size='sm' display="table">
                <Thead>
                    <Tr>
                        <Th>
                            <SkeletonWrapper w="fit-content">
                                Name
                            </SkeletonWrapper>
                        </Th>
                        {!isViewingFolder && (
                            <Th>
                                <SkeletonWrapper w="fit-content">
                                    People
                                </SkeletonWrapper>
                            </Th>
                        )}
                        <Th>
                            <SkeletonWrapper w="fit-content">
                                Type
                            </SkeletonWrapper>
                        </Th>
                        <Th>
                            <SkeletonWrapper w="fit-content">
                                Edited
                            </SkeletonWrapper>
                        </Th>
                        {isViewingFolder && (
                            <Th>
                                <SkeletonWrapper w="fit-content">
                                    Owner
                                </SkeletonWrapper>
                            </Th>
                        )}
                        <Th pr={0} textAlign="right">
                            <ProjectsView showInSections />
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Folders tab={tab} />
                    {filteredFiles?.map((f, i) => <ListRow key={i} row={f} tab={tab} />)}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export const ListRow = ({ row }) => {

    const history = useHistory()
    const folders = useGetFoldersData()
    const inTrash = useInTrash()
    const isViewingFolder = useIsViewingFolder()

    const people = useGetPeople()?.byId
    const owner = people?.[row?.createdBy]
    const ownerName = getUsersFullName(owner)

    const { type } = row;
    const isFolder = type === "folder"

    const updatedAt = row?.updatedAt || row?.createdAt
    const edited = `${dayjs(updatedAt?.toString())?.locale('en-long')?.fromNow()}`

    const itemNameFromDoc = useGetItemNameFromDoc(row)

    return (
        <Tr display="table-row" verticalAlign="middle" w="full" _hover={{ bg: 'rarchy-bg-subtle', cursor: 'pointer' }} h="75px" onClick={() => {
            if (!inTrash) history.push(row?.link)
        }}>
            <Td display="table-cell" py={0}>
                {isFolder && <FolderComponent folder={row} thumbnail={row.thumbnail} count={row.count} />}
                {!isFolder && (
                    <Stack direction="row" align="center" spacing={3.5}>
                        <SkeletonWrapper w="fit-content">
                            <Center rounded="md" w="54px" h="54px" bg="rarchy-bg-sitemap-editor" px={0.5} borderWidth="1px">
                                <FileThumbnail file={row} collection={row.collection} />
                            </Center>
                        </SkeletonWrapper>
                        <Stack>
                            <SkeletonWrapper w="fit-content">
                                <FileName file={row} />
                            </SkeletonWrapper>
                            <SkeletonWrapper w="fit-content">
                                <Box>
                                    <FolderBadge file={row} folders={folders} />
                                </Box>
                            </SkeletonWrapper>
                        </Stack>
                    </Stack>
                )}
            </Td>
            {!isViewingFolder && (
                <Td display="table-cell">
                    <SkeletonWrapper w="max-content">
                        <SharedAvatarGroup file={!isFolder ? row : null} folder={isFolder ? row : null} />
                    </SkeletonWrapper>
                </Td>
            )}
            <Td display="table-cell" textTransform="capitalize">
                <SkeletonWrapper w="fit-content">
                    {isFolder ? type : itemNameFromDoc}
                </SkeletonWrapper>
            </Td>
            <Td display="table-cell">
                <SkeletonWrapper w="fit-content">
                    {edited}
                </SkeletonWrapper>
            </Td>
            {isViewingFolder && (
                <Td>
                    <SkeletonWrapper w="fit-content">
                        {ownerName}
                    </SkeletonWrapper>
                </Td>
            )}
            <Td display="table-cell" textAlign="right">
                {isFolder && <FolderMenu folder={row} />}
                {!isFolder && <FileMenu file={row} />}
            </Td>
        </Tr>
    )
}