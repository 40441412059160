import {
    Drawer,
    DrawerBody,
    DrawerContent,
    useColorModeValue as mode
} from "@chakra-ui/react"
import React, {
useEffect,
useState
} from 'react';
import { useCheckHasAutocaptured, useCheckIfAutocapturing, useGetAutoCapturedDocFromFirestore, useGetAutoCapturingDocFromFirestore } from './hooks';
import { useInUserFlow, usePrevious } from '../../../../../hooks';

// import { Scheduled } from './Components/Scheduled';
import { BulkCapture } from './Components/Bulk';
import { Header } from './Components/Header';
import { isEmpty } from 'lodash'
import { render } from '../../canvas/render';
import { toggleCaptureDrawer } from '../../../../../store/actions/sitemap-actions';
import { useDispatch } from 'react-redux';

export const CaptureDrawer = ({ sitemap }) => {

    // autocapturing
    const isAutocapturing = useCheckIfAutocapturing()
    const autocapturing = useGetAutoCapturingDocFromFirestore()
    const prevAutocapturing = usePrevious(autocapturing)
    // autocaptured
    const hasAutocaptured = useCheckHasAutocaptured()
    const autocaptured = useGetAutoCapturedDocFromFirestore()

    // re-render on batch change
    ReRenderOnBatchChange({ isAutocapturing, autocapturing, prevAutocapturing })

    const dispatch = useDispatch()

    const { CaptureDrawer } = sitemap?.ui
    // const [showRecurring, setShowRecurring] = useState(false)

    return (
        <Drawer
            isOpen={CaptureDrawer.showing}
            placement="right"
            size="md"
            returnFocusOnClose={false}
            blockScrollOnMount={false}
            onClose={() => dispatch(toggleCaptureDrawer())}
        >
            <DrawerContent overflowY="scroll">
                <Header isAutocapturing={isAutocapturing} hasAutocaptured={hasAutocaptured} autocaptured={autocaptured} />
                <DrawerBody pt={0.5} w="full">
                    {/* !showRecurring && ( */ <BulkCapture
                        isAutocapturing={isAutocapturing}
                        autocapturing={autocapturing}
                        hasAutocaptured={hasAutocaptured}
                        autocaptured={autocaptured}
                    /> /*) */}
                    {/* showRecurring && (
                        <Tabs size="sm" defaultIndex={0} mt={2}>
                            <TabList>
                                <Tab fontWeight="semibold" fontSize="0.95em" px={1.5} pb={1} mr={5}>Capture Once</Tab>
                                <Tab fontWeight="semibold" fontSize="0.95em" px={1.5} pb={1} mr={5}>Scheduled Screenshots</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p={0} pt={2}>
                                    <BulkCapture />
                                </TabPanel>
                                <TabPanel p={0} pt={2}>
                                    <Scheduled />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    )*/}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
};

const ReRenderOnBatchChange = ({ isAutocapturing, autocapturing, prevAutocapturing }) => {
    // re-render here as capture icon in toolbar will always be visible (unlink drawer)
    useEffect(() => {
        // re-render once batch has changed
        if (isAutocapturing) {
            if (autocapturing?.batch?.id !== prevAutocapturing?.batch?.id) render()
        }
        // re-render once finished  
        if (isAutocapturing && !isEmpty(prevAutocapturing)) render();
    })
}