import { Box } from "@chakra-ui/react"
import { toTitleCase } from "../../../../helpers"
import { useGetUpgradeModal } from "../../../../hooks"

export const WhatYoullGet = () => {
    const { planId } = useGetUpgradeModal()
    return (
        <Box fontWeight="semibold">
            {`Here's what you'll get with Rarchy `}
            <Box display="inline">{toTitleCase(planId)}</Box>
            :
        </Box>
    )
}