import { getAccountId, getNewFirestoreDocId, getUserId } from "../../../../../helpers"
import { mergeFolderChange } from "../../../../../store/actions/folders-actions"

import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { wait } from "../../../../../../shared/helpers"
import { getMembersToSave } from "../Share/helpers"

export const saveNewFolderInFirestore = async ({ name, parentFolderId, membersToAdd, membersToRemove, dispatch }) => {

    const userId = getUserId()
    const accountId = getAccountId()

    const members = getMembersToSave({ folder: { createdBy: userId }, membersToAdd, membersToRemove })

    const docData = {
        archived: false,
        createdAt: new Date(),
        createdBy: userId,
        members,
        name,
        organization: accountId,
        team: parentFolderId || null,
        updatedAt: new Date(),
        updatedBy: userId,
    }

    const folderId = getNewFirestoreDocId("teams")
    // wait a bit
    await wait(1000)
    // save to firestore
    const folderRef = doc(getFirestore(), 'teams', folderId);
    await setDoc(folderRef, docData, { merge: true });
    // update in redux
    dispatch(mergeFolderChange({ [folderId]: docData }))
    // return folderId
    return { folderId }

}
