import { CheckIcon } from "@chakra-ui/icons"
import { Box, Stack } from "@chakra-ui/react"
import { TRIAL_DAYS, useCanStartNewTrial, useGetNumberOfDaysLeftInTrial, useIsTrialing, useIsTrialingOrCanStartNewTrial } from "../../../../shared/hooks/pricing"
TRIAL_DAYS
import { useGetUpgradeModal } from "../../../hooks"
import { toTitleCase } from "../../../helpers"

export const Checklist = () => {
    const canStartNewTrial = useCanStartNewTrial()
    const isTrialing = useIsTrialing()
    const daysLeftInTrial = useGetNumberOfDaysLeftInTrial()
    const isTrialingOrCanStartNewTrial = useIsTrialingOrCanStartNewTrial()
    const { planId } = useGetUpgradeModal()
    return (
        <Stack w="full" mt={3}>
            {canStartNewTrial && (
                <>
                    <ChecklistItem>{`Free ${TRIAL_DAYS} day trial, cancel anytime`}</ChecklistItem>
                    <ChecklistItem>{`We'll remind you 7 days before your trial ends`}</ChecklistItem>
                </>)}
            {isTrialing && (<ChecklistItem>{`Your free trial will continue (${daysLeftInTrial} days left)`}</ChecklistItem>)}
            {!isTrialingOrCanStartNewTrial && (
                <>
                    <ChecklistItem>{`Upgrade to Rarchy ${toTitleCase(planId)} in a couple of clicks`}</ChecklistItem>
                    <ChecklistItem>{`We'll remind you 7 days before your plan renews`}</ChecklistItem>
                </>
            )}
        </Stack>
    )
}

const ChecklistItem = ({ children }) => <Box fontSize="sm"><CheckIcon fontSize="md" mr={4} color="rarchy-green" />{children}</Box>