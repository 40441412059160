import { getPageName } from '../../../../utils/app';
import { dragging } from '../../../views/nodes';
import { keepScale } from '../../utils/zoom';

export const renderNodesTooltip = (context, node) => {
    
    if (dragging) return;

    var hasChildren = node.children || node._children;

    context.font = `500 ${keepScale(12)}px Inter,sans-serif`;

    var textWidth = context.measureText(getPageName(node.name)).width + keepScale(20);

    context.fillStyle = '#323e48';
    roundRect(context, node.x - (textWidth / 2), node.y - keepScale((hasChildren ? 42 : 39)), textWidth, keepScale(30), keepScale(3), true);

    context.textAlign = 'start';
    context.baseLine = 'middle';
    context.fillStyle = 'white';
    context.fillText(getPageName(node.name), node.x - (textWidth / 2) + keepScale(10), node.y - keepScale((hasChildren ? 23 : 20)));

    context.baseLine = 'alphabetical';

}

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof radius === 'undefined') {
        radius = 5;
    }
    if (typeof radius === 'number') {
        radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
        var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side];
        }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
}