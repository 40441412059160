import React, { useEffect, useState } from 'react'
import {
    HStack,
    useColorModeValue as mode,
    Button,
    Box,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Portal,
    Input,
    InputGroup,
    InputLeftElement,
    PopoverArrow,
    Flex,
} from "@chakra-ui/react"
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { FilesTable } from './Table'
import { SearchIcon } from '@chakra-ui/icons'
import { DropzoneFiles } from './Dropzone'

import { useFirestore } from 'react-redux-firebase'
import { useCanEditFile, useGetSitemap, useGetUser, useInDemoProject, useInUserFlow, useInUserEditorDoc, useInOrganization } from '../../../../../hooks'

export const Files = () => {

    const [files, setFiles] = useState([])
    const [filteredFiles, setFilteredFiles] = useState([])

    const firestore = useFirestore()

    const sitemap = useGetSitemap()
    const user = useGetUser()
    const inUserSitemap = useInUserEditorDoc()
    const inUserFlow = useInUserFlow()

    const inDemoProject = useInDemoProject()
    const canEdit = useCanEditFile(inUserFlow ? sitemap : null)

    const { PageDrawer } = sitemap?.ui

    useEffect(() => {

        async function fetchFiles() {

            if (!user.id && !inDemoProject) return;
            if (inUserSitemap) return;

            var files = await firestore.doc(`sitemaps/${sitemap?.id}/data/pages/files/${PageDrawer.page.id}`).get();

            if (files.exists) {
                var arr = Object.entries(files.data().files).map(([id, val]) => ({ id, ...val })).sort((a, b) => new Date(b.uploaded).getTime() - new Date(a.uploaded).getTime()); // sorted by date (desc)
                setFiles(arr);
            }
        }
        fetchFiles();
    }, [])

    return (
        <Box mt={2}>
            {!inUserSitemap && (
                <HStack mb={-1} direction="row" justifyContent="space-between" zIndex="popover">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                        <Input variant="flushed" fontSize="sm" placeholder={"Search files..."} onChange={e => handleSearchChange(e, files, setFilteredFiles)} />
                    </InputGroup>
                    {canEdit && (
                        <Flex>
                            <Popover>
                                {({ onClose }) => (
                                    <>
                                        <PopoverTrigger>
                                            <Button
                                                colorScheme="blue"
                                                fontSize="sm"
                                                paddingInline={3}
                                                leftIcon={<AiOutlineCloudUpload fontSize="16px" />}>
                                                Upload
                                            </Button>
                                        </PopoverTrigger>
                                        <Portal>
                                            <Box>
                                                <PopoverContent right="24px" p={2}>
                                                    <PopoverArrow />
                                                    <DropzoneFiles files={files} setFiles={setFiles} onClose={onClose} />
                                                </PopoverContent>
                                            </Box>
                                        </Portal>
                                    </>
                                )}
                            </Popover>
                        </Flex>
                    )}
                </HStack>
            )}
            <FilesTable files={files} filteredFiles={filteredFiles} setFiles={setFiles} />
        </Box>
    )
}


const handleSearchChange = (e, files, setFilteredFiles) => {
    const searched = e.target.value.toLowerCase();
    setFilteredFiles(files.filter(file => {
        if (file.name.toLowerCase().includes(searched)) return file;
    }))
}