import React from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Tooltip,
} from "@chakra-ui/react"
import { AiOutlineLine, AiOutlineDash } from 'react-icons/ai'
import { addConnectorChange } from '../../helpers'

const FONT_SIZE = "20px"

export const Type = ({ OptionsPopover }) => {
    const type = OptionsPopover.links[0]?.type || 'solid'
    return (
        <Menu autoSelect={false} placement="bottom" isLazy>
            <Tooltip variant="rounded" placement='top' label='Type' openDelay={1000}>
                <MenuButton as={IconButton} size="sm" variant="ghost" icon={<Icon type={type} />} onClick={e => e.stopPropagation()} />
            </Tooltip>
            <MenuList minW={-1} ml="0px" p={0}>
                <MenuItem
                    px="6px"
                    rounded="5px"
                    onClick={() => {
                        handleChangeConnectorType({ newType: type !== 'solid' ? 'solid' : 'dash', OptionsPopover })
                    }}
                >
                    {type !== 'solid' ? <AiOutlineLine fontSize={FONT_SIZE} /> : <AiOutlineDash fontSize={FONT_SIZE} />}
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

const Icon = ({ type }) => {
    return type === 'solid' ? <AiOutlineLine fontSize={FONT_SIZE} /> : <AiOutlineDash fontSize={FONT_SIZE} />
}

const handleChangeConnectorType = ({ newType, OptionsPopover }) => {
    
    const link = OptionsPopover.links[0];
    const oldType = link.type

    /*** save ***/
    const data = { [link.start.id]: { connectors: { [link.start.position]: { [link.end.id]: { type: newType } } } }}
    const oldData = { [link.start.id]: { connectors: { [link.start.position]: { [link.end.id]: { type: oldType } } } }}
    addConnectorChange({ data, oldData })
    /*** save ***/
}

