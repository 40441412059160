import React, { useEffect, useState } from 'react';
import {
    Box,
    Tabs,
    TabList,
    Tab,
    useColorModeValue as mode,
    HStack,
    Text,
    IconButton
} from "@chakra-ui/react"
import { SmallCloseIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux';
import { Subfolders } from '../../../Editor/Toolbar/Subfolders';
import { addSubfolderToTabs, initSection, removeSubfoldersFromTabs, unsetSection } from '../../../../store/actions/sitemap-actions';
import { createRoot, getPageDataAsObjFromNode } from '../../utils/app';
import { searchTree } from '../../../Editor/Navbar/Search/helpers';
import queryString from 'query-string';
import { isEmpty, findIndex, compact, split, find } from 'lodash'
import { chain } from '../../../../helpers/chain';

export const SubfolderTabs = () => {
    const { sitemap } = useSelector(state => state)
    const dispatch = useDispatch()
    const borderColor = mode("gray.200", "whiteAlpha.300")
    const styles = {
        fontWeight: "semibold",
        fontSize: "sm",
        color: mode("gray.600", "whiteAlpha.800"),
        bgColor: mode("white", "gray.800"),
        borderBottom: 'none',
        paddingInlineStart: 3,
        paddingInlineEnd: 3,
        _selected: { bgColor: mode("gray.100", "gray.700"), /* border: "1px solid", borderBottom: 'none', borderColor */ }
    }
    const closeIconStyles = { color: mode("gray.500", "whiteAlpha.700"), _hover: { bgColor: mode("gray.200", "gray.600") } }

    // on mount
    LoadSubfoldersFromURL(sitemap)

    if (isEmpty(sitemap?.ui.SubfoldersTabs)) return null;

    const subfolders = Object.keys(sitemap?.ui.SubfoldersTabs).map(id => ({ id, ...sitemap?.ui.SubfoldersTabs[id] })).filter(s => s.id !== 'home');
    const subfolderIndex = !sitemap?.data.section ? 0 : findIndex(subfolders, (s) => s.id == sitemap?.data.section.id) + 1; // because 'All' is 1st

    return (
        (subfolders.length > 0)
            ? <HStack direction="row" spacing={2} pos="fixed" left="30px" bottom="30px" borderBottom="1px solid" borderColor={borderColor} bgColor={styles.bgColor} borderTopLeftRadius="md" borderTopRightRadius="md" pr={2} maxW="85%">
                <Tabs isManual variant="enclosed" index={subfolderIndex ? subfolderIndex : 0} size="sm">
                    <TabList mb={0}>
                        <Tab {...styles} onClick={() => dispatch(initSection(sitemap?.data.root))}>All Pages</Tab>
                        {[...subfolders].map((subfolder, i) => {
                            const subfolderId = subfolder.id;
                            return (
                                <Tab
                                    key={subfolderId}
                                    {...styles}
                                    onClick={() => addOrGoToSubfolderTab({ sitemap, subfolderId, dispatch, action: 'go' })}
                                >
                                    <Text noOfLines={1} maxW="xs">{subfolder ? subfolder.name : ' '}</Text>
                                    {(sitemap?.data.section && sitemap?.data.section.id) === subfolderId && (
                                        <Box onClick={(e) => {
                                            e.stopPropagation();
                                            // can jump to another section in the tabs (try left first)
                                            if (subfolders.length > 1) {
                                                const newSubfolderId = subfolders[i - 1] ? subfolders[i - 1].id : subfolders[i + 1].id
                                                addOrGoToSubfolderTab({ sitemap, subfolders, i, subfolderId: newSubfolderId, dispatch, action: 'go' })
                                                dispatch(removeSubfoldersFromTabs([subfolderId]))
                                            } else {
                                                dispatch(unsetSection()) // close subfolder tab
                                            }
                                        }}>
                                            <SmallCloseIcon
                                                fontSize="16px"
                                                rounded="sm"
                                                ml={2}
                                                {...closeIconStyles}
                                            />
                                        </Box>
                                    )}
                                </Tab>
                            )
                        })}
                    </TabList>
                </Tabs>
                <Subfolders location="canvas" />
            </HStack >
            : null
    )
}

const LoadSubfoldersFromURL = (sitemap) => {
    const dispatch = useDispatch()
    useEffect(() => {
        const { sf } = queryString.parse(window.location.search);
        const subfolderIds = compact(split(sf, ',').map(id => id.length === 10 ? id : null));
        if (isEmpty(subfolderIds)) return;
        // loop through tabs
        subfolderIds.forEach((subfolderId, i) => addOrGoToSubfolderTab({ sitemap, subfolders: [], i, subfolderId, dispatch, action: 'add' }));
        // ensure first tab is home
        addOrGoToSubfolderTab({ sitemap, subfolderId: 'home', dispatch, action: 'add' });
    }, [])
}

export const addOrGoToSubfolderTab = async ({ sitemap, subfolderId, dispatch, action }) => {
    // const subfolders = [], i = 0;
    // remove subfolder tab if page no longer exists
    const { pages } = sitemap?.docs;
    if (!pages[subfolderId]) dispatch(removeSubfoldersFromTabs([subfolderId]));
    // continue
    const { root: sitemapRoot } = sitemap?.data;
    var node = subfolderId === 'home' ? sitemapRoot : find(sitemapRoot.children, ['id', subfolderId]);
    /*** node isn't visible on page (i.e collapsed) ***/
    if (!node) {
        var paths = searchTree(sitemapRoot, subfolderId, []); // don't know what this only get object refers to
        if (isEmpty(paths)) {
            // can jump to another section in the tabs (try left first, if no to the left, go to home)
            /* if (subfolders.length > 1) {
                const newSubfolderId = subfolders[i - 1] ? subfolders[i - 1].id : 'home'
                addOrGoToSubfolderTab({ sitemap, subfolders, i, subfolderId: newSubfolderId, dispatch, action })
                dispatch(removeSubfoldersFromTabs([subfolderId]))
            } */
        } else {
            node = paths.slice(-1).pop()
        }
    }
    /*** node isn't visible on page (i.e collapsed) ***/
    if (node) {
        // add tab
        if (action === 'add') dispatch(addSubfolderToTabs(node));
        // go to tab
        if (action === 'go') goToSection({ node, sitemap, subfolderId, dispatch });
    };
}

export const goToSection = async ({ node, sitemap, subfolderId, dispatch }) => {
    if (sitemap?.data.section && sitemap?.data.section.id === node.id) return; // already in subfolder
    const pageDataFromNode = chain(getPageDataAsObjFromNode(node)).map((obj, id) => Object.assign({}, obj, { id })).value();
    const root = await createRoot(pageDataFromNode);
    dispatch(initSection(subfolderId !== 'home' ? root : sitemap?.data.root));
}