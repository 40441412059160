import {
    Box,
    CloseButton,
    DarkMode,
    HStack,
    Icon,
    LightMode,
    Stack,
    Text,
    Tooltip
} from "@chakra-ui/react"

import { Kbd } from '@chakra-ui/react'
import { LuKeyboard } from "react-icons/lu"
import { Name } from './Name'
import React from 'react'
import { URL } from './URL'
import { togglePageDrawer } from "../../../../../store/actions/sitemap-actions"
import { useDispatch } from "react-redux"
import { useInUserFlow } from '../../../../../hooks'

export const Header = ({ PageDrawer }) => {
    const dispatch = useDispatch()
    const inUserFlow = useInUserFlow()
    const showURL = !inUserFlow || (inUserFlow && (PageDrawer.page.page))
    return (
        <Box w="full">
            <Stack py={1.5} pt={2.5} w="full" spacing={0} /* borderBottom="1px solid" borderColor="rarchy-border" */ /* h="80px" minH="80px" maxH="80px"*/>
                <HStack>
                    <Name />
                    <Tooltip
                        variant="rounded-sm"
                        hasArrow
                        label={(
                            <HStack spacing={1.5}>
                                <Icon as={LuKeyboard} />
                                <Text>Esc</Text>
                            </HStack>
                        )}
                        openDelay={750}
                    >
                        <CloseButton fontSize="sm" onClick={() => dispatch(togglePageDrawer())} />
                    </Tooltip>
                </HStack>
                {showURL && <URL />}
            </Stack>
        </Box>
    )
}