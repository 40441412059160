
import { getX, getY, getRandomColor } from '../../../canvas/utils/helpers';
import { colors, colorByNodeIds } from '../../../canvas/utils/drag';
import { transform } from '../../../canvas/utils/zoom';
import { NODE_WIDTH } from '../../../canvas/components/drag';
import { getSitemap } from '../../../../../../helpers';

export const spacing = (context, nodes) => {

    nodes.forEach((d, i) => {
        if (!d) return;
        if (d.parent) {
            const x = getX(d), y = getY(d);
            addLeftPlaceholder(context, d, x, y, nodes, i);
            addRightPlaceholder(context, d, x, y, nodes, i);
        }
    });

}

const addLeftPlaceholder = (context, d, x, y, nodes, nodeIndex) => {

    const sitemap = getSitemap();

    var width = sitemap?.showCovers ? 30 : 20;

    const nodeOnLeft = nodes[nodeIndex - 1] && nodes[nodeIndex - 1].depth === d.depth ? nodes[nodeIndex - 1] : null;

    if (nodeOnLeft) {
        if ((nodeOnLeft.id === 'dragging-placeholder') && (nodeOnLeft.parent.id === d.parent.id)) return;
        const distanceToNodeOnLeft = d.x - (nodeOnLeft.x + NODE_WIDTH);
        if (distanceToNodeOnLeft <= (sitemap?.showCovers ? 41 : 23)) width = sitemap?.showCovers ? 20.5 : 11.5;
    }

    var randomColorForLeft;
    if (!colorByNodeIds[d.id] || !colorByNodeIds[d.id]['left']) {
        /*** assign new random color to node id ***/
        randomColorForLeft = getRandomColor();
        colorByNodeIds[d.id] = { ...colorByNodeIds[d.id], 'left': randomColorForLeft };
        colors[randomColorForLeft] = { node: d, side: 'left' };
        /*** assign new random color to node id ***/
    } else {
        randomColorForLeft = colorByNodeIds[d.id]['left']; // use existing assigned color
    }
    context.fillStyle = randomColorForLeft;
    if (context.canvas.id === 'picker-canvas') {
        context.fillRect(transform.applyX(x - width), transform.applyY(y), width * transform.k, (d.nodeHeight) * transform.k);
    } else {
        context.fillRect(x - width, y, width, d.nodeHeight);
    }

}

const addRightPlaceholder = (context, d, x, y, nodes, nodeIndex) => {

    const sitemap = getSitemap();

    var width = sitemap?.showCovers ? 30 : 20;

    const nodeOnRight = nodes[nodeIndex + 1] && nodes[nodeIndex + 1].depth === d.depth ? nodes[nodeIndex + 1] : null;
    width = sitemap?.showCovers ? 30 : 20;
    if (nodeOnRight) {
        if ((nodeOnRight.id === 'dragging-placeholder') && (nodeOnRight.parent.id === d.parent.id)) return;
        const distanceToNodeOnRight = nodeOnRight.x - (d.x + NODE_WIDTH);
        if (distanceToNodeOnRight <= (sitemap?.showCovers ? 41 : 23)) width = sitemap?.showCovers ? 20.5 : 11.5;
    }

    var randomColorForRight;
    if (!colorByNodeIds[d.id] || !colorByNodeIds[d.id]['right']) {
        /*** assign new random color to node id ***/
        randomColorForRight = getRandomColor();
        colorByNodeIds[d.id] = { ...colorByNodeIds[d.id], 'right': randomColorForRight };
        colors[randomColorForRight] = { node: d, side: 'right' };
        /*** assign new random color to node id ***/
    } else {
        randomColorForRight = colorByNodeIds[d.id]['right']; // use existing assigned color
    }
    context.fillStyle = randomColorForRight;
    if (context.canvas.id === 'picker-canvas') {
        context.fillRect(transform.applyX(x + NODE_WIDTH), transform.applyY(y), width * transform.k, (d.nodeHeight) * transform.k);
    } else {
        context.fillRect(x + NODE_WIDTH, y, width, d.nodeHeight);
    }

}