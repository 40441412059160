import {
    Box,
    Divider,
    HStack,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { CopyIcon, DeleteIcon } from '@chakra-ui/icons';

import { Add } from './Add';
import { Color } from './Color';
import React from 'react';
import { Wireframes } from './Wireframes';
import { clonePageSection } from '../../../utils/page-sections/clone';
import { deletePageSection } from '../../../utils/page-sections/delete';
import { end as endRename } from '../../../utils/page-sections/rename';
import { getPageId } from '../../../utils/helpers';
import { useSelector } from 'react-redux';

/*** popover ****/
export const PageSectionsOptionsPopover = () => {
    const { sitemap, user } = useSelector(state => state);
    /* componentDidUpdate(prevProps) {
        const { showUpgradeMessaging } = this.props?.sitemap?.ui.PageSectionsOptionsPopover;
        const { showUpgradeMessaging: prevShowUpgradeMessaging } = prevProps.sitemap?.ui.PageSectionsOptionsPopover;
        // just shown upgrade messaging
        if (showUpgradeMessaging && !prevShowUpgradeMessaging) sendDataLayerEventForPageSections('Upgrade');
    } */
    const { PageSectionsOptionsPopover } = sitemap?.ui;
    const { offset, node, section } = PageSectionsOptionsPopover;
    const nodeId = getPageId(node);
    const sectionsCount = getSectionsCount(sitemap, nodeId);
    if (!PageSectionsOptionsPopover?.showing) return null;
    return (
        <Popover
            isOpen
            offset={offset}
        >
            <PopoverTrigger><Box display="none"/></PopoverTrigger>
            <PopoverContent
                w="200px"
                h="36px"
                onClick={(e) => e.stopPropagation()}
                borderColor={mode("gray.300", "whiteAlpha.400")}
            >
                <HStack h="34px" px={1} spacing="6px" onClick={e => { endRename(); e.preventDefault(); e.stopPropagation(); }}>
                    <Add />
                    <Color node={node} section={section} />
                    <Tooltip variant="rounded-sm" placement='top' openDelay={500} label='Clone'>
                        <IconButton size="sm" icon={<CopyIcon fontSize="16px" />} color={mode("gray.600", "whiteAlpha.900")} variant="ghost" _hover={{ bgColor: "transparent" }}
                            onClick={e => {
                                clonePageSection({ node, section });
                                sendDataLayerEventForPageSections('Clone', sectionsCount + 1);
                            }} />
                    </Tooltip>
                    <Wireframes />
                    <Divider h="17px" orientation="vertical" />
                    <Tooltip variant="rounded-sm" placement='top' openDelay={500} label='Delete'>
                        <IconButton size="sm" icon={<DeleteIcon />} color="red.400" variant="ghost" _hover={{ bgColor: "transparent", color: "red.500" }}
                            onClick={e => {
                                deletePageSection({ node, section });
                                sendDataLayerEventForPageSections('Delete', sectionsCount - 1);
                            }} />
                    </Tooltip>
                </HStack>
            </PopoverContent>
        </Popover>
    )
};

export const sendDataLayerEventForPageSections = (action, label) => {
    const obj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Page Section: ${action}`, label } };
    window.dataLayer.push({
        event: 'generic_ga_event',
        ...obj
    });
};

export const getSectionsCount = (sitemap, nodeId) => {
    const sections = sitemap?.data.page_sections ;
    if (!sections[nodeId]) return 0;
    return Object.keys(sections[nodeId]).length;
}
/*** popover ****/