import {
    Box,
    Center,
    Divider,
    DrawerBody,
    HStack,
    IconButton,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react"
import { ChatIcon, CheckIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { areCommentsMarkedAsResolved, commentMentions, commentTime, commentUser, resolveComments } from '../../../../Sitemap/comments/helpers';
import { find, isEmpty, keys, orderBy, sortBy, uniq } from 'lodash'
import { getPeopleData, getSitemap, getUser } from '../../../../../helpers';
import { useCanCommentOnFile, useGetSitemap } from '../../../../../hooks';

import { BsLink } from 'react-icons/bs';
import { Card } from './Card';
import { PageComments } from '../../../../Sitemap/comments/Comments';
import { SearchWithFilter } from '../../../../Sitemap/app/components/SearchWithFilter';
import { chain } from '../../../../../helpers/chain';
import copy from 'copy-to-clipboard';
import { getPageName } from '../../../../Sitemap/utils/app';
import { handleSelectedPage } from '../utils';

export const Pages = ({ filter, setFilter }) => {

    const sitemap = useGetSitemap()
    const [filteredSearchData, setFilteredSearchData] = useState([])

    const canCommentInSitemap = useCanCommentOnFile()

    const comments = renderComments({ filter })
    const hasComments = !isEmpty(comments)

    useEffect(() => {
        return () => {
            // reset once closed
            if (!isEmpty(comments)) {
                setFilteredSearchData([])
            }
        }
    }, []);

    const data = !isEmpty(filteredSearchData) ? filteredSearchData : comments;

    return (
        <Box pb={6} w="full">
            {hasComments && (
                <SearchWithFilter onChange={(e) => {
                    const inputVal = e.target.value.toLowerCase();
                    setFilteredSearchData(filter(comments, (c) => {
                        return JSON.stringify(c.page.name).toLowerCase().includes(inputVal) || c.comments?.find((com) => {
                            const fullName = JSON.stringify(`${com.user.firstName} ${com.user.lastName}`).toLowerCase();
                            const comment = JSON.stringify(com?.comment?.string)?.toLowerCase();
                            return fullName?.includes(inputVal) || comment?.includes(inputVal)
                        })
                    }));
                }} />
            )}
            {hasComments && data.map((comment) => {
                const isResolved = comment?.comments[comment?.comments?.length - 1]?.status === 'resolved';
                const sharingURL = `${window.location.origin}${window.location.pathname}?pc=${comment.page.id}`
                return <Card
                    boxShadow="none"
                    _hover={{ boxShadow: "none" }}
                    key={comment.page.id}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleSelectedPage(sitemap, comment.page.id)
                    }}
                    bgColor={isResolved ? "rarchy-bg-subtle" : "unset"}
                >
                    <Box
                        mt={-1}
                        px="4"
                        pt="3"
                        pb={3}
                    >
                        <HStack mb={2} w="full" justifyContent="space-between" pr={0}>
                            <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>
                                {getPageName(comment.page.name)}
                            </Text>
                            <HStack spacing={2}>
                                <IconButton
                                    size="sm"
                                    variant="ghost"
                                    icon={<BsLink fontSize="18px" />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        copy(sharingURL);
                                    }}
                                />
                                {canCommentInSitemap && !isResolved && (
                                    <Tooltip placement="end" label="Mark as resolved and hide discussion" openDelay={500} variant="rounded">
                                        <IconButton size="sm" rounded="full" variant="ghost" icon={<CheckIcon />} onClick={(e) => { e.stopPropagation(); resolveComments(comment.page.id) }} />
                                    </Tooltip>
                                )}
                            </HStack>
                        </HStack>
                        <Divider />
                    </Box>
                    <Box mt={-3} mx="1px" mb="1px" bgColor="transparent" px={5} py={3} borderBottomRadius="md">
                        <PageComments sitemap={sitemap} page={comment.page.id} />
                    </Box>
                </Card>
            })}
            {!hasComments && (
                <Center>
                    <VStack spacing={8} color="fg.muted" mt={12}>
                        <ChatIcon fontSize="36px" />
                        <Text fontSize="lg">No comments yet</Text>
                    </VStack>
                </Center>
            )}
        </Box>
    )
}

const renderComments = props => {

    const sitemap = getSitemap()
    const user = getUser()
    const people = getPeopleData()

    const { filter } = props;
    //
    const { comments } = sitemap;
    if (!comments) return [];
    //
    const commentsArray = [];
    const pageIds = uniq(keys(comments));

    const { pages } = sitemap?.docs;

    pageIds.forEach(pageId => {

        const pageComments = comments[pageId];
        if (!pageComments) return;

        if (!pages[pageId]) return; // don't continue if page doesn't exist anymore

        /*** return if page doesn't contain any comments referencing the user ***/
        if (filter === 'for you' && !pageCommentsContainsUser(pageComments, user.id)) return
        /*** return if page doesn't contain any comments referencing the user ***/

        /*** return if open ***/
        if (filter === 'open' && areCommentsMarkedAsResolved(pageComments)) return;
        /*** return if open ***/

        /*** return if not resolved ***/
        if (filter === 'resolved' && !areCommentsMarkedAsResolved(pageComments)) return;
        /*** return if not resolved ***/

        var latestTimestamp;

        const timestamps = sortBy(Object.keys(pageComments));
        const parentId = timestamps[0];

        const commentsData = chain(pageComments)
            .map((obj, id) => {
                const data = Object.assign({}, obj, { id });
                // set latest timestamp so we can sort comments array
                latestTimestamp = (!latestTimestamp || id > latestTimestamp) ? id : latestTimestamp;
                data.time = commentTime(id);
                const user = commentUser(people, data.user);
                if (user) data.user = user;
                if (id !== parentId) data.parent = parentId;
                data.comment = commentMentions(people, data.comment);
                return data;
            }).value();
        const pageName = sitemap?.docs.pages[pageId] && sitemap?.docs.pages[pageId].name;
        const sortedComments = orderBy(commentsData, ['id'], ['asc']);
        commentsArray.push({ latestTimestamp, page: { id: pageId, name: pageName }, comments: sortedComments });
    });
    // sort by descending latest timestamp
    var sortedComments = orderBy(commentsArray, ['latestTimestamp'], ['desc']);
    //
    if (sortedComments.length > 0) return sortedComments;
    //
    return [];
};

const pageCommentsContainsUser = (comments, userId) => {

    var userHasCommented = find(keys(comments), (commentId => {
        var comment = comments[commentId];

        // user has commented on this page
        if (comment.user === userId) return commentId;

        /* check if user has been tagged on this page */
        var mentionsRegex = /\b[a-zA-Z0-9]{28}\b/ig, match;
        /* eslint-disable-next-line */
        while (match = mentionsRegex.exec(comment.comment)) {
            if (match[0] === userId) return commentId;
        }
        /* check if user has been tagged on this page */

    }));

    if (userHasCommented) return true;
}