import './app.scss';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { changeSitemapPallette, getCollaboratorsData, hideCovers, mergeSitemapDocEdits, setFormat, showCovers, switchCoversDevice } from './store/actions/sitemap-actions';
import { difference, find, isEmpty, isEqual, isUndefined } from 'lodash';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { getOrganization, getUser, getUserId } from './helpers';
import { initAccount, logOut, switchAccount, switchTeam } from './store/actions/auth-actions';

import Screens from './screens'
import { connect } from 'react-redux';
import { mergeOrganizationDocChanges } from './store/actions/organization-actions';
import queryString from 'query-string';
import { removeOrganization } from './store/actions/organizations-actions';
import { setUpgradeModalScreen } from './store/actions/ui-actions';
import { store } from "../src/store/"
import { withRouter } from 'react-router-dom';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshingToken: null
    }
  }
  
  async componentDidMount() {
    // read defaults from shared URL
    readDefaultsFromSharedURL(this.props.sitemap);
  }

  async componentDidUpdate(prevProps) {
    /*** check for refresh of user token ***/
    checkForClaimRefresh(this, prevProps);
    /*** check for refresh of user token ***/
    /*** merge organization doc changes ***/
    checkForOrgDocChanges(this, prevProps);
    /*** merge organization doc changes ***/
  }

  render() {
    return <Screens />;
  }
}

const checkForClaimRefresh = async ($this, prevProps) => {

  const { props } = $this;
  const { switchAccount, auth, user } = props;

  const userId = getUserId()
  
  const currentClaims = user?.token?.claims;

  /*** user has been removed from organization ***/
  const prevAuth = prevProps.auth;
  if ((!auth.switching?.account && !prevAuth.switching?.account)) {
    // have to include extra check for oldClaims.organization
    const oldClaims = prevProps.user?.token?.claims;
    // ensure user is logged in
    if (currentClaims) {
      if (oldClaims?.organization && (oldClaims?.organization !== currentClaims?.organization)) {
        // removeOrganization(oldClaims?.organization);
        switchAccount(currentClaims?.organization || userId);
      }
    }
  }
  /*** user has been removed from organization ***/

  /*** org role doesn't match token ***/
  if (!auth?.initing && !auth?.error) {
    const organization = getOrganization()
    const orgRoleDiffersToToken = (organization?.users?.[userId] && (currentClaims?.organization === organization?.id) && (currentClaims?.role !== organization?.users?.[userId]?.role))
    if (orgRoleDiffersToToken) store.dispatch(initAccount({ user, forTokenRefresh: true })) // completely refreshes token
  }
  /*** org role doesn't match token ***/

  /*** check for refresh of user token ***/
  const prevUser = prevProps.use
  const refreshTokenIsSetOnUser = (/* user profile has just loaded */(user?.email && !prevUser?.email) && user?.refreshToken) || (user?.refreshToken && !prevProps?.user?.refreshToken)
  const shouldRefreshToken = !$this.state.refreshingToken && refreshTokenIsSetOnUser

  if (shouldRefreshToken) {

    // ensures the below code doesn't run multiple times
    $this.setState({ refreshingToken: true });

    // try to refresh token
    const { currentUser } = props.firebase.auth();
    //
    const oldToken = await currentUser.getIdTokenResult();
    // force refresh of token
    await currentUser.getIdToken(true);
    // unset refresh on user profile
    try {

      if (user.email) await props.firestore.doc(`users/${userId}`).set({ refreshToken: false }, { merge: true });
      const newToken = await currentUser.getIdTokenResult();

      /*** force user to switch account or team back to personal account if they have changed with the refresh ***/
      if (oldToken.claims.organization !== newToken.claims.organization) {
        switchAccount(newToken.claims.organization || userId)
      }
      /*** force user to switch account or team if they have changed with the refresh ***/

      store.dispatch({ type: 'REFRESHED_TOKEN', payload: newToken });

      // ensure this is wrapped in try block, otherwise there will be an infinite loop if can't set refreshToken to false on user
      $this.setState({ refreshingToken: false });

    } catch (e) {
      console.error(e)
    }
  }
  /*** check for refresh of user token ***/
}

const checkForOrgDocChanges = ($this, prevProps) => {

  const { props } = $this;
  // use org id from token OR listen for newly created organization post stripe redirect
  const { screen: UpgradeModalScreen, stripeRedirectAttrs } = props.ui.UpgradeModal || {}
  const newlyCreatedOrgId = stripeRedirectAttrs?.orgId;
  const orgId = props.organization.id || newlyCreatedOrgId;

  const organization = props?.firestoreListeners?.data?.organizations?.[orgId];
  const prevOrganization = prevProps?.firestoreListeners?.data?.organizations?.[orgId];
  //
  /*** merge doc changes in redux ***/

  if (!isEqual(organization, prevOrganization)) {

    if (newlyCreatedOrgId) {
      if (!UpgradeModalScreen.startsWith('confirmed')) {
        if (!organization?.archived) {
          store.dispatch(setUpgradeModalScreen({ screen: 'confirmed' }))
        }
      }
      return;
    }

    /*** merge organization doc ***/
    props.mergeOrganizationDocChanges(organization);
    /*** merge organization doc ***/

    /*** user added ***/
    const addedUsers = difference(Object.keys(organization?.users || []), Object.keys(prevOrganization?.users || []));
    if (!isEmpty(addedUsers)) {
      // addedUsers.forEach(user => console.log(user))
    }
    /*** user added ***/
    /*** user removed ***/
    const removedUsers = difference(Object.keys(prevOrganization?.users || []), Object.keys(organization?.users || []));
    if (!isEmpty(removedUsers)) {
      // removedUsers.forEach(user => console.log(user))
    }
    /*** user removed ***/

  }
  /*** merge doc changes in redux ***/

}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    firebaseListeners: state.firebase,
    firestoreListeners: state.firestore
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ initAccount, removeOrganization, switchAccount, switchTeam, logOut, mergeOrganizationDocChanges, mergeSitemapDocEdits, changeSitemapPallette }, dispatch)
};

const connector = withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps), firebaseConnect((props) => {
    return [];
  }), firestoreConnect((props) => {
    const { user } = props;
    const { email, organization, team } = user.token?.claims || {};
    // listen to stripe checkout redirect
    const newlyCreatedOrgId = props.ui.UpgradeModal?.stripeRedirectAttrs?.orgId;
    const orgId = newlyCreatedOrgId || organization
    const listenTo = [];
    const { isLoaded } = props.firebaseListeners.auth;
    if (isLoaded && user.token) {
      const teamId = team || user?.id; // undefined team is personal account
      // if (teamId) listenTo.push({ collection: "teams", doc: teamId }); // listen to current team  
      // if (email) listenTo.push({ collection: "invitations", where: [['email', '==', email]] }); // listen to user invitations  
      if (orgId) listenTo.push({ collection: "organizations", doc: orgId }); // listen to organization
    }
    return listenTo;
  }))(App));

export default connector;

/* export class AppLoader extends Component {
  render() {
    return (
      <div style={{ width: '100%', height: '100vh', background: 'white', position: 'fixed', top: 0, left: 0, zIndex: 999999 }}>
        <div
          style={{
            height: '100vh',
            maxWidth: 260,
            top: '50%',
            left: '50%',
            position: 'absolute',
            margin: '-70px 0 0 -130px',
          }}
        >
          <StyledLogo />
        </div>
      </div>
    );
  }
}; */

const readDefaultsFromSharedURL = (sitemap) => {
  const { v: view, sc: covers, d: device } = queryString.parse(window.location.search);
  // view
  if (view) {
    switch (view) {
      case 'tv':
        store.dispatch(setFormat('tree-vertical'));
        break;
      case 'tvm':
        store.dispatch(setFormat('tree-vertical-matrix'));
        break;
      case 't':
        store.dispatch(setFormat('tree'));
        break;
      case 'i':
        store.dispatch(setFormat('indent'));
        break;
      case 'n':
        store.dispatch(setFormat('nodes'));
        break;
      default:
        break;
    }
  };
  // show covers
  if (covers) {
    if (covers === '1') {
      store.dispatch(showCovers(sitemap?.id));
    } else if (covers === '0') {
      store.dispatch(hideCovers(sitemap?.id));
    }
  };
  // device
  if (device) {
    const devices = ['desktop', 'mobile'];
    devices.forEach(d => {
      if (device === d[0]) {
        store.dispatch(switchCoversDevice(d));
      }
    })
  };
}