import { BiLock, BiMailSend, BiUser, BiUserCircle } from "react-icons/bi"
import { UserAvatarDetail } from "../../../../Components/Shared/UserAvatarDetail"
import { NavItem } from "../../../Components/NavItem"
import { Stack } from "@chakra-ui/react"
import { TbUser, TbUserCircle } from "react-icons/tb"

export const UserSettings = () => {
    return (
        <Stack mt={1.5}>
            <UserAvatarDetail avatarSize="smd" nameSize="sm" />
            <Stack spacing={1.5} my={2.5}>
                <NavItem
                    pathname={"/app/settings/your-account"}
                    label="Your account"
                    icon={<TbUserCircle />}
                />
                <NavItem
                    pathname={"/app/settings/login-and-security"}
                    label="Login & security"
                    icon={<BiLock />}
                />
                {/* <NavItem
                    pathname={"/settings/message-preferences"}
                    label="Message preferences"
                    icon={<BiMailSend />}
                    /> */}
            </Stack>
        </Stack>
    )
}