import { ARC_TO_RADIUS } from "../helpers";
// test config by dragging end node - NOT start node
export const drawBottomStarts = (context, values) => {
    const { endPos, startNode, endNode, startConnectorX, endConnectorX, startConnectorY, endConnectorY, minPadding, startConnectorIsToTheLeftOfEndConnector, startConnectorIsBelowEndConnector, midXBetweenNodes, midYBetweenNodes } = values;
    // always start drawing here
    context.moveTo(startConnectorX, startConnectorY);
    // bottom
    if (endPos === 'bottom') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom
            if (startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(startConnectorX, startConnectorY + minPadding, endConnectorX, startConnectorY + minPadding, ARC_TO_RADIUS);
                if ((startNode.rightOfNode) < endConnectorX) {
                    context.arcTo(endConnectorX, startConnectorY + minPadding, endConnectorX, startConnectorY, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY + minPadding, startNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS);
                    context.arcTo(startNode.rightOfNode + minPadding, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS);
                    context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(startConnectorX, startConnectorY + minPadding, endConnectorX, startConnectorY + minPadding, ARC_TO_RADIUS);
                if (startNode.x > (endConnectorX)) {
                    context.arcTo(endConnectorX, startConnectorY + minPadding, endConnectorX, startConnectorY, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startNode.x - minPadding, startConnectorY + minPadding, startNode.x - minPadding, midYBetweenNodes, ARC_TO_RADIUS);
                    context.arcTo(startNode.x - minPadding, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS);
                    context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.x > (endConnectorX)) {
                    context.arcTo(startConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startConnectorX, midYBetweenNodes, endNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS);
                    context.arcTo(endNode.rightOfNode + minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, endConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(endNode.rightOfNode + minPadding, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS);
                }
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startConnectorX < (endNode.x)) {
                    context.arcTo(startConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startConnectorX, midYBetweenNodes, endNode.x - minPadding, midYBetweenNodes, ARC_TO_RADIUS);
                    context.arcTo(endNode.x - minPadding, midYBetweenNodes, endNode.x - minPadding, endConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(endNode.x - minPadding, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS);
                }
            }
            context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS);
        }
    }
    // top
    if (endPos === 'top') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if ((startNode.rightOfNode + minPadding) < endNode.leftOfNode) {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, midXBetweenNodes, startConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, startConnectorY + minPadding, midXBetweenNodes, endConnectorY - minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.rightOfNode + minPadding, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY + minPadding, startNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, midYBetweenNodes, endNode.leftOfNode - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endNode.leftOfNode - minPadding, midYBetweenNodes, endNode.leftOfNode - minPadding, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.leftOfNode - minPadding, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if ((startNode.leftOfNode - minPadding) < endNode.rightOfNode) {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.leftOfNode - minPadding, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, startConnectorY + minPadding, startNode.leftOfNode - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, midXBetweenNodes, startConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, startConnectorY + minPadding, midXBetweenNodes, endConnectorY - minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS);
                }
            }
            context.arcTo(endConnectorX, endConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS);
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            context.arcTo(startConnectorX, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS);
            context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS);
        }
    }
    // left
    if (endPos === "left") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.rightOfNode + minPadding < endConnectorX) {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, midXBetweenNodes, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY + minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.rightOfNode + minPadding, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY + minPadding, startNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, midYBetweenNodes, endConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, midYBetweenNodes, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                const bottomOfStartIsAboveBottomOfEnd = startNode.bottomOfNode + minPadding < endNode.bottomOfNode
                if (startNode.leftOfNode >= endNode.leftOfNode) {
                    if (bottomOfStartIsAboveBottomOfEnd) {
                        context.arcTo(startConnectorX, startConnectorY + minPadding, startConnectorX, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                        context.arcTo(startConnectorX, endNode.bottomOfNode + minPadding, endConnectorX - minPadding, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX - minPadding, endNode.bottomOfNode + minPadding, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                    } else {
                        context.arcTo(startConnectorX, startConnectorY + minPadding, endConnectorX - minPadding, startConnectorY + minPadding, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX - minPadding, startConnectorY + minPadding, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                    }
                    context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.leftOfNode - minPadding, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, startConnectorY + minPadding, startNode.leftOfNode - minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if ((startNode.bottomOfNode + minPadding) >= endNode.topOfNode) {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, midXBetweenNodes, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY + minPadding, midXBetweenNodes, endNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endNode.topOfNode - minPadding, endConnectorX - minPadding, endNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, endNode.topOfNode - minPadding, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, midYBetweenNodes, endConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, midYBetweenNodes, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) context.arcTo(startConnectorX, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
        }
    }
    if (endPos === "right") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.rightOfNode < endNode.rightOfNode) {
                    if (startNode.bottomOfNode < endNode.bottomOfNode) {
                        context.arcTo(startConnectorX, endNode.bottomOfNode + minPadding, endConnectorX + minPadding, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS);
                        context.arcTo(endConnectorX + minPadding, endNode.bottomOfNode + minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS);
                        context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                    } else {
                        context.arcTo(startConnectorX, startConnectorY + minPadding, endConnectorX + minPadding, startConnectorY + minPadding, ARC_TO_RADIUS);
                        context.arcTo(endConnectorX + minPadding, startConnectorY + minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS);
                        context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                    }
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.rightOfNode + minPadding, startConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY + minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS);
                    context.arcTo(startNode.rightOfNode + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.leftOfNode > endNode.rightOfNode + minPadding) {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, midXBetweenNodes, startConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, startConnectorY + minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.leftOfNode - minPadding, startConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, startConnectorY + minPadding, startNode.leftOfNode - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, midYBetweenNodes, endConnectorX + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, midYBetweenNodes, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                   /* context.arcTo(startConnectorX, startConnectorY + minPadding, startNode.rightOfNode + minPadding, startConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY + minPadding, startNode.rightOfNode + minPadding, endConnectorY, ARC_TO_RADIUS);
                    context.arcTo(startNode.rightOfNode + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS); */
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) context.arcTo(startConnectorX, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if ((startNode.bottomOfNode + minPadding) < endNode.topOfNode) {
                    context.arcTo(startConnectorX, midYBetweenNodes, endConnectorX + minPadding, midYBetweenNodes, ARC_TO_RADIUS);
                    context.lineTo(endConnectorX, midYBetweenNodes)
                    context.arcTo(endConnectorX + minPadding, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS);
                } else {
                    context.arcTo(startConnectorX, startConnectorY + minPadding, midXBetweenNodes, startConnectorY + minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, startConnectorY + minPadding, midXBetweenNodes, endNode.topOfNode - minPadding, ARC_TO_RADIUS);
                    context.arcTo(midXBetweenNodes, endNode.topOfNode - minPadding, endConnectorX + minPadding, endNode.topOfNode - minPadding, ARC_TO_RADIUS);
                    context.arcTo(endConnectorX + minPadding, endNode.topOfNode - minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS);
                }
                context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            }
        }
    }
    context.lineTo(endConnectorX, endConnectorY); // always end here
}