import { Editable, EditableInput, EditablePreview } from "@chakra-ui/react"
import React, { useEffect, useRef } from 'react';
import { useCanEditFile, useGetSitemap, useInSitemap, useIsLinkedUserFlowPage } from '../../../../../hooks';

import { getPageName } from '../../../utils/app';
import { handleRenamePage } from '../../canvas/components/text/utils';
import { showEditorPermissionsToast } from '../../../../Editor/App/Components/PermissionsToast';

export const Name = () => {
    const sitemap = useGetSitemap()
    const inSitemap = useInSitemap()
    const inputRef = useRef()
    useEffect(() => {
        // stop Chakra from focusing on this everytime page drawer is opened
        setTimeout(() => inputRef.current.blur(), 5);
    }, [])
    const { PageDrawer } = sitemap?.ui
    const isLinkedPage = useIsLinkedUserFlowPage(PageDrawer.page) ? true : false
    const canEdit = useCanEditFile((inSitemap || isLinkedPage) ? sitemap : null)
    const name = getPageName((inSitemap || isLinkedPage) ? PageDrawer?.page?.name : PageDrawer?.page?.text);
    return (
        <Editable
            key={PageDrawer?.page?.id}
            variant="flushed"
            w="full"
            defaultValue={name}
            isPreviewFocusable={canEdit}
            // fontSize="lg"
            fontWeight="semibold"
            onSubmit={(newName) => { const node = PageDrawer?.page; handleRenamePage(node, newName); }}
        >
            <EditablePreview
                w="full"
                onClick={(e) => {
                    showEditorPermissionsToast({ doc: (inSitemap || isLinkedPage) ? sitemap : null }); if (!canEdit) return e.stopPropagation();
                }}
                pb={0}
            />
            <EditableInput
                ref={inputRef}
                w="full"
                pb={0}
                onChange={null}
            />
        </Editable>
    )
}