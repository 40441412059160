import { Button, Heading, Skeleton, Stack } from "@chakra-ui/react"
import { ConfirmedList } from "./Confirmed/List"
import { useIsTrialEnabled } from "../../../../shared/hooks/pricing"

export const Confirming = () => {
    const isTrialEnabled = useIsTrialEnabled()
    return (
        <Stack spacing={9}>
            <Heading fontSize="2xl" color="fg.default">{`Confirming ${isTrialEnabled ? "free trial" : "account"}...`}</Heading>
            <ConfirmedList isConfirming />
            <Skeleton rounded="md"><Button /></Skeleton>
        </Stack>
    )
}