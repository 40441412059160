import React from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Tooltip
} from "@chakra-ui/react"
import { useDispatch, useSelector } from 'react-redux'
import { addFlowChange, changedSymbol } from '../../../../../store/actions/flow-actions'
import { ActionSVG, DecisionSVG, DocumentSVG, GridIcon, InputOutputSVG, PageSectionSVG, PageSVG, StartEndSVG } from '../../../../Editor/Toolbar/User-Flows/Grid/icons'
import { addSymbolChange } from '../../helpers'

const FONT_SIZE = "20px"
const HEIGHT = "33px"

export const Type = ({ OptionsPopover, multiple }) => {
    const ui = useSelector(state => state.ui)
    const handleClick = (type) => {
        const { nodes } = OptionsPopover;
        const data = {}; [...nodes].forEach(d => data[d.id] = { type })
        const oldData = {}; nodes.forEach(({ id, type }) => oldData[id] = { ...oldData[id], type });
        addSymbolChange({ data, oldData });
    }
    const node = OptionsPopover.nodes && OptionsPopover.nodes[0];
    const selectedType = node?.type;
    return (
        <Menu autoSelect={false} isLazy>
            <Tooltip variant="rounded" placement='top' label='Type' openDelay={1000}>
                <MenuButton as={IconButton} size="sm" variant="ghost" icon={<GridIcon icon={types[!multiple ? selectedType : 'page'].icon} colorMode={ui.colorMode} />} onClick={e => e.stopPropagation()} />
            </Tooltip>
            <MenuList fontSize="sm" minW={0}>
                {Object.keys(types).map((type, i) => (
                    <MenuItem
                        key={i}
                        h={HEIGHT}
                        value={type}
                        icon={<GridIcon icon={types[type].icon} colorMode={ui.colorMode} />}
                        onClick={() => handleClick(type)}
                    >
                        {types[type].text}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

const types = {
    'page': {
        text: 'Page',
        icon: PageSVG,
    },
    'page-sections': {
        text: 'Sections',
        icon: PageSectionSVG,
    },
    'action': {
        text: 'Action',
        icon: ActionSVG,
    },
    'startEnd': {
        text: 'Start / End',
        icon: StartEndSVG,
    },
    'decision': {
        text: 'Decision',
        icon: DecisionSVG,
    },
    'document': {
        text: 'Document',
        icon: DocumentSVG,
    },
    'inputOutput': {
        text: 'Input / Output',
        icon: InputOutputSVG,
    }
}