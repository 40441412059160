import { Badge, Icon, Stack, Text, Tooltip } from "@chakra-ui/react"
import { useGetFolderIdFromPath } from "../../../hooks"
import { BsFolderFill } from "react-icons/bs"
import { useHistory } from "react-router-dom"

export const FolderBadge = ({ file, folders }) => {
    const history = useHistory()
    const folderId = useGetFolderIdFromPath()
    const inFolder = folderId === file?.team;
    const folder = folders.find(f => f.id === file?.team)
    if (!folder || inFolder) return null;
    return (
        <Tooltip label={folder?.name} variant="rounded-sm" openDelay={500} hasArrow>
            <Badge fontWeight="normal" textTransform="initial" px={1.5} color="fg.muted" lineHeight="19px" onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push(`/app/folder/${folder.id}`) }}>
                <Stack direction="row" align="center">
                    <Icon as={BsFolderFill} fontSize="sm" color="rarchy-badge-icon" />
                    <Text>{folder?.name}</Text></Stack>
            </Badge>
        </Tooltip>
    )
}