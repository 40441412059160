import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetOrganization, useGetSitemap, useGetUser, useInOrganization } from "../../../../../hooks";
import { getPageName } from "../../../utils/app";
import { getURL } from "../../../../../store/actions/sitemap-actions";
import { getFirestore } from "redux-firestore";
import { getSitemap } from "../../../../../helpers";
import { isEmpty, keys, includes } from 'lodash'

export const useCheckIfAutocapturing = () => {
    // return true // for testing
    return !isEmpty(useGetAutoCapturingDocFromFirestore())
}

export const useGetAutoCapturingDocFromFirestore = () => {
    // return testAutocapturingData // for testing
    const sitemap = useGetSitemap();
    const firestore = useSelector(state => state.firestore);
    const sitemaps = firestore?.data?.sitemaps || {}
    return sitemaps[sitemap?.id]?.data?.autocapturing || {};
}

export const useCheckHasAutocaptured = () => {
    // return true // for testing
    const autocapturing = useCheckIfAutocapturing();
    const autocaptured = useGetAutoCapturedDocFromFirestore();
    return !autocapturing && !isEmpty(autocaptured) || autocapturing?.total === keys(autocaptured.pages)
}

export const useGetAutoCapturedDocFromFirestore = () => {
    // return testAutocapturedData // for testing
    const sitemap = useGetSitemap();
    const firestore = useSelector(state => state.firestore);
    const sitemaps = firestore?.data?.sitemaps || {}
    return sitemaps[sitemap?.id]?.data?.autocaptured || {};
}

export const useGetMonthlyScreenshotsRemaining = () => {
    const organization = useGetOrganization()
    const inOrganization = useInOrganization()
    const user = useGetUser()
    return inOrganization ? organization?.subscription?.autocaptures_remaining : (user?.limits?.screenshots?.remaining || 50);
}

export const useWillHitMonthlyScreenshotsRemaining = (screenshots) => {
    return screenshots > useGetMonthlyScreenshotsRemaining();
}

export const useGetPagesForCapture = (checkedPages) => {
    const sitemap = useGetSitemap();
    return useMemo(() => {
        const { root, section } = sitemap?.data;
        // hierarchical data to flat data for force layout
        var pages = {};
        var index = 0;
        //
        function getPageData(node) {
            /*** push url to array ***/
            if (includes(checkedPages, node.id)) pages[node.id] = { name: getPageName(node.name), url: getURL(node.id), index: index++ };
            /*** push url to array ***/
            // recurse
            const children = node?.children || node?._children;
            children?.forEach(getPageData);
        }
        const rootToUse = section ? section : root; getPageData(rootToUse); // main pages
        rootToUse?.website_sections?.forEach(ws => { (ws?.children || ws?._children)?.forEach(getPageData) }); // website sections
        return pages;
    }, [checkedPages])
};

/* autocapturing */
const testAutocapturingData = {
    "httpAuth": {
        "password": "",
        "username": ""
    },
    "device": "tablet",
    "hidden": "",
    "organization": "ZTkw5K3lZK9TnkX631Ln",
    "batch": {
        "started": {
            "seconds": 1631684842,
            "nanoseconds": 101000000
        },
        "id": "2f387187-fca3-44fe-be67-de6430e44ada-0"
    },
    "createdAt": {
        "seconds": 1631684795,
        "nanoseconds": 484000000
    },
    "fullpage": true,
    "pages": {
        "10lb20ygxi": {
            "url": "https://au.koala.com/products/straight-back-dining-chair",
            "index": 31,
            "name": "Straight Back Dining Chair!!!"
        },
        "teg453z6pw": {
            "index": 29,
            "name": "Paddington Bed Base",
            "url": "https://au.koala.com/products/paddington-bed-base"
        },
        "s1h9w038q9": {
            "index": 28,
            "url": "https://au.koala.com/products/koala-wfh-desk",
            "name": "Koala Wfh Desk"
        },
        "u5zbi6fas8": {
            "name": "Serenity Dining Table",
            "index": 30,
            "url": "https://au.koala.com/products/serenity-dining-table"
        },
        "1avmevlai2": {
            "url": "https://au.koala.com/products/koala-timber-bed-base",
            "index": 25,
            "name": "Koala Timber Bed Base"
        },
        "gpw0pp5xml": {
            "index": 26,
            "name": "Koala Timber Bookshelf",
            "url": "https://au.koala.com/products/koala-timber-bookshelf"
        },
        "w6gdb1vnib": {
            "url": "https://au.koala.com/products/koala-tv-unit",
            "name": "Koala Tv Unit",
            "index": 27
        }
    },
    "total": 32,
    "email": true,
    "createdBy": "6RTDY8XwGTgXUwr2p1anSMMxfbC2",
    "waitForFiveSeconds": false,
    "lazyload": false
}
/* autocapturing */

/* autocaptured */
const testAutocapturedData = {
    "pages": {
        "migf0ati4h": {
            "url": "https://au.koala.com/products/koala-sofa-bed",
            "error": true,
            "index": 23,
            "name": "Koala Sofa Bed"
        },
        "lyol23mosh": {
            "name": "Everyday Armchair",
            "url": "https://au.koala.com/products/everyday-armchair",
            "captured": true,
            "index": 11
        },
        "2cet7nkw5f": {
            "index": 22,
            "error": true,
            "name": "Koala Sofa",
            "url": "https://au.koala.com/products/koala-sofa"
        },
        "r736qgndv5": {
            "url": "https://au.koala.com/products/coastal-sofa",
            "index": 7,
            "name": "Coastal Sofa",
            "error": true
        },
        "ybynkmbhqd": {
            "captured": true,
            "name": "Koala High Back Armchair Footstool",
            "url": "https://au.koala.com/products/koala-high-back-armchair-footstool",
            "index": 17
        },
        "d0sl6t7wdk": {
            "url": "https://au.koala.com/products/koala-timber-armchair",
            "index": 24,
            "name": "Koala Timber Armchair",
            "captured": true
        },
        "e53kyuhog4": {
            "index": 8,
            "url": "https://au.koala.com/products/coaster-coffee-table",
            "error": true,
            "name": "Coaster Coffee Table"
        },
        "aoyn1pq937": {
            "index": 14,
            "name": "Koala Compact Sofa",
            "error": true,
            "url": "https://au.koala.com/products/koala-compact-sofa"
        },
        "532jilx35r": {
            "error": true,
            "name": "Koala Modern Sofa",
            "index": 19,
            "url": "https://au.koala.com/products/koala-modern-sofa"
        },
        "87nvbcbikw": {
            "name": "Gift Card",
            "url": "https://au.koala.com/products/gift-card",
            "index": 12,
            "error": true
        },
        "nyn8h51f3d": {
            "name": "All Nighter Bedside Table",
            "url": "https://au.koala.com/products/all-nighter-bedside-table",
            "index": 6,
            "error": true
        },
        "8nig4s6u1j": {
            "name": "Koala Everyday Armchair Footstool",
            "captured": true,
            "index": 16,
            "url": "https://au.koala.com/products/koala-everyday-armchair-footstool"
        },
        "x3vz00lzgv": {
            "index": 1,
            "captured": true,
            "name": "Elissa 94",
            "url": "https://au.koala.com/living-room/elissa-94"
        },
        "h4z2lz2y5a": {
            "captured": true,
            "index": 3,
            "url": "https://au.koala.com/living-room/owen-25",
            "name": "Owen 25"
        },
        "24hyohpxwx": {
            "error": true,
            "url": "https://au.koala.com/products/curved-dining-chair",
            "name": "Curved Dining Chair",
            "index": 10
        },
        "hxkaru88ge": {
            "index": 2,
            "captured": true,
            "url": "https://au.koala.com/living-room/elissa-94",
            "name": "Elissa 94"
        },
        "v6a6r0lhu2": {
            "index": 0,
            "name": "Living Room!",
            "error": true,
            "url": "https://au.koala.com/living-room"
        },
        "oyviaan54f": {
            "index": 21,
            "captured": true,
            "name": "Koala Sheets",
            "url": "https://au.koala.com/products/koala-sheets"
        },
        "cnyxslpk8z": {
            "captured": true,
            "name": "Owen 90",
            "index": 4,
            "url": "https://au.koala.com/living-room/owen-25/owen-90"
        },
        "jjj2g8jcmf": {
            "captured": true,
            "url": "https://au.koala.com/products",
            "name": "Products",
            "index": 5
        },
        "sjkq9hr28s": {
            "name": "Koala Pillow",
            "url": "https://au.koala.com/products/koala-pillow",
            "error": true,
            "index": 20
        },
        "3gyalfpxih": {
            "index": 9,
            "url": "https://au.koala.com/products/cork-sofa",
            "name": "Cork Sofa",
            "error": true
        },
        "wlel4wd4cj": {
            "name": "Koala Mattress",
            "url": "https://au.koala.com/products/koala-mattress",
            "index": 18,
            "error": true
        },
        "tlqn8xksr9": {
            "index": 15,
            "error": true,
            "url": "https://au.koala.com/products/koala-duvet",
            "name": "Koala Duvet"
        },
        "fg7r5y49v8": {
            "index": 13,
            "url": "https://au.koala.com/products/high-back-armchair",
            "name": "High Back Armchair",
            "captured": true
        }
    }
}
/* autocaptured */

// not a hook
export const clearBulkCaptureDoc = (setActiveStep) => {
    const firestore = getFirestore();
    const sitemap = getSitemap();
    firestore.doc(`sitemaps/${sitemap?.id}/data/autocaptured`).delete()
    setActiveStep(0);
}