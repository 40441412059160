import { chain } from 'lodash'
import { getPageColor } from '../../canvas/utils/helpers';
import { getPalletteColors } from '../../../../../store/actions/editor-actions';
import { getSitemap } from '../../../../../helpers';
import model from '../../../../../store/reducers/model';

export const getTemplateWithColor = (key, opts = {}) => {

  if (!key) key = "grids/2-columns"; // FAIl-SAFE, for when covers.default doesn't exist

  const sitemap = getSitemap();
  const { collections } = sitemap?.covers;
  
  if (!collections) return;

  var { d } = opts;

  if (!d) d = {};

  const { baseColors } = model.sitemap?.pallette;

  let pageColor = getPageColor(d, { forWireframe: true });

  const colors = getPalletteColors(pageColor)

  const folder = key.split('/')[0];
  var svg = JSON.parse(JSON.stringify(key === 'header' ? header : collections[folder][key].svg))

  baseColors.forEach((b, i) => svg = svg.replaceAll(b, i === 0 ? 'transparent' : colors[i])); // transparent background for wireframes

  return svg;
}

const header = '<svg width="130" height="10" viewBox="0 0 130 10" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M3.477 0A2.98 2.98 0 00.5 2.982V9.94h129.01V2.982A2.98 2.98 0 00126.533 0H3.477z" fill="#66BBFF"/><path d="M18.692 3.484c-.912 0-1.65.74-1.65 1.654a1.652 1.652 0 103.302 0c0-.914-.74-1.654-1.652-1.654m-5.59 0c-.91 0-1.65.74-1.65 1.654 0 .913.74 1.654 1.65 1.654.912 0 1.652-.74 1.652-1.654 0-.914-.74-1.654-1.652-1.654m-5.616 0c-.912 0-1.65.74-1.65 1.654a1.652 1.652 0 103.302 0c0-.914-.74-1.654-1.652-1.654" fill="#F9F9F9"/></g></svg>';

/*

// used to modify svg

var newCollections = {};

Object.keys(collections).map(key => {
  if (key === 'header') return null;
  const results = chain(collections[key]).map(obj => Object.assign({}, obj)).value();
  console.log(results);
  results.forEach(result => {
    var newSVG = result.svg.replaceAll('id="', `id="${result.key}-`).replaceAll('xlink:href="#', `xlink:href="#${result.key}-`).replaceAll('url(#', `url(#${result.key}-`);
    if (!newCollections[key]) newCollections[key] = {};
    newCollections[key][result.key] = { ...result, svg: newSVG }
  });
});
var jsonString = JSON.stringify(newCollections).replace(/\\/g, '').replaceAll(`"<svg`, `'<svg`).replaceAll(`</svg>"`, `</svg>'`)
console.log(jsonString);

*/