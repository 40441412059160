import { createContext, useState } from "react"
import { useBoolean } from "@chakra-ui/react"

export const ShareFolderContext = createContext()

export const ShareFolderHOC = ({ folder, forNew, onClose, children }) => {
    const [isSelectFocused, setIsSelectFocused] = useBoolean()
    // if new folder, use parent folder members as starting point
    // if sharing folder, set to null if folder is public, (so we can ensure that the save button is disabled on default, and enables when switching from public/private and vv)
    const [membersToAdd, setMembersToAdd] = useState(forNew ? (folder?.members || null) : (!folder?.members ? null : [])) 
    // continue
    const [membersToRemove, setMembersToRemove] = useState([])
    return (
        <ShareFolderContext.Provider
            value={{
                folder,
                forNew,
                onClose,
                isSelectFocused,
                setIsSelectFocused,
                membersToAdd,
                setMembersToAdd,
                membersToRemove,
                setMembersToRemove
            }}>
            {children({ membersToAdd, membersToRemove })}
        </ShareFolderContext.Provider>
    )
}