import { Stack, useColorMode } from "@chakra-ui/react"
import { useGetEditor, useInEditor } from "../../../hooks"

import { Left } from "./Sides/Left"
import { Right } from "./Sides/Right"
import { getIsNavbarHidden } from "../../../helpers"
import { useIsPublicPage } from "../../../PrivateRoute"

export const Navbar = () => {

    const inEditor = useInEditor()
    const { colorMode } = useColorMode()
    const isNavbarHidden = getIsNavbarHidden()
    const isPublicPage = useIsPublicPage()
    const editor = useGetEditor()


    if (isNavbarHidden) return null
    if (isPublicPage) return null
    if (editor?.error) return null

    const isDark = colorMode === 'dark'
    const boxShadow = (isDark || inEditor) ? null : "xs"

    return (
        <Stack
            zIndex={3}
            w="full"
            direction="row"
            justify="space-between"
            boxShadow={boxShadow}
            bgColor="rarchy-bg-sitemap-editor"
            borderBottom={!inEditor && isDark ? "1px" : null}
            borderColor="rarchy-border-sitemap-editor"
            align="center"
            p={5}
            py="9.5px"
        >
            <Left />
            <Right />
        </Stack>
    )
}