import { getDecodedEmail } from '../../helpers';
import deepmerge from 'deepmerge';
import { flatten, compact, pickBy, identity } from 'lodash';
import { chain } from '../../helpers/chain';

// get users gets all organization users and file collaborators

export const getPeople = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const { user, organization, files, firebase, people } = state;
    dispatch({
      type: 'GET_PEOPLE',
      async payload() {

        // users
        const users = [{ id: user.id, data: firebase.profile }];
        await Promise.all((Object.keys(organization?.users || {}))?.map(async userId => {
          const existing = people?.byId?.[userId]
          const user = existing ? { data: () => existing } : await firestore.collection('users').doc(userId).get();
          users.push({ id: userId, data: (user?.data() || organization?.users[userId]) })
        }));
        const usersData = getDataFromDoc(users, organization)

        // collaborators (plus whoever created the file)
        const collaborators = [];
        const collaboratorIds = flatten(compact(files?.data?.map(d => {
          return [d.createdBy, ...Object.keys(d?.collaborators || {})].filter(cId => !usersData[getDecodedEmail(cId)]);
        })));
        await Promise.all(collaboratorIds.map(async userId => {
          const existing = people?.byId?.[userId]
          const collaborator = existing ? { data: () => existing } : await firestore.collection('users').doc(userId).get();
          collaborators.push({ id: userId, data: collaborator?.data() || {} })
        }));
        const collaboratorsData = getDataFromDoc(collaborators, organization)

        // return data
        const byId = { ...usersData, ...collaboratorsData };

        return { byId };

      },
    }).catch(e => {
      console.error(e)
      return e
    });
  };
};

export const getDataFromDoc = (users, organization) => {
  // loop through query results
  const usersToReturn = users.map(doc => {
    const { id } = doc;
    const decodedId = getDecodedEmail(id);
    const { firstName, lastName, email, photoURL, invitedAt } = doc.data || {};
    return pickBy({
      id: decodedId,
      firstName,
      lastName,
      email: email || decodedId,
      photoURL,
      invited: invitedAt ? true : false,
      invitedAt: invitedAt ? typeof invitedAt?.toDate === 'function' ? invitedAt.toDate() : invitedAt : null,
      owner: id === organization?.createdBy ? true : false,
      role: organization?.users?.[id]?.role,
    }, identity);
  });
  // return data
  return chain(usersToReturn).keyBy('id').value();
};

export const mergePeopleChange = (data) => {
  return (dispatch, getState) => {
    const { people } = getState()
    const byId = deepmerge(people.byId, data)
    dispatch({
      type: 'MERGE_PEOPLE_CHANGE', byId
    })
  }
}
