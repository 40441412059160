import { Button, FormControl, Input, Stack, Text, useBoolean } from "@chakra-ui/react"
import { getUsersFullName } from "../../../../helpers"
import { useGetUser } from "../../../../hooks"
import { SectionHeader } from "../../Components"
import { Formik } from "formik"
import { wait } from "../../../../../shared/helpers"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const Name = () => {
    const [isEditing, setIsEditing] = useBoolean()
    const user = useGetUser()
    const fullName = getUsersFullName(user)
    return (
        <Stack spacing={3} mt={1.5}>
            <SectionHeader title="Name" />
            <Stack direction="row" w="full" justify="space-between">
                {!isEditing && (
                    <>
                        <SkeletonWrapper w="fit-content">
                            <Text fontSize="sm">{fullName}</Text>
                        </SkeletonWrapper>
                        <SkeletonWrapper w="fit-content">
                            <Button fontSize="sm" px={6} onClick={setIsEditing.toggle}>Edit</Button>
                        </SkeletonWrapper>
                    </>
                )}
                {isEditing && (
                    <Formik
                        initialValues={{ firstName: user?.firstName, lastName: user?.lastName }}
                        onSubmit={async (values, actions) => {
                            try {
                                let { firstName, lastName } = values;
                                // can't be empty
                                if (!firstName) firstName = user?.firstName;
                                if (!lastName) lastName = user?.lastName;
                                // no changes
                                if (`${user?.firstName}${user?.lastName}` === `${firstName}${lastName}`) return;
                                // wait a bit
                                await wait(500)
                                // save
                                const userRef = doc(getFirestore(), 'users', user?.id);
                                await setDoc(userRef, { firstName, lastName }, { merge: true });
                            } catch (e) {

                            } finally {
                                setIsEditing.toggle()
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            return (
                                <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                                    <Stack direction="row" w="full" spacing={3}>
                                        <FormControl id="firstName">
                                            <Input
                                                defaultValue={values?.firstName}
                                                fontSize="sm"
                                                name="firstName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isRequired
                                                placeholder="Enter your first name"
                                            />
                                        </FormControl>
                                        <FormControl id="lastName">
                                            <Input
                                                defaultValue={values?.lastName}
                                                fontSize="sm"
                                                name="lastName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isRequired
                                                placeholder="Enter your last name"
                                            />
                                        </FormControl>
                                        <Stack direction="row" spacing={3}>
                                            <Button fontSize="sm" px={6} onClick={setIsEditing.toggle}>Cancel</Button>
                                            <Button fontSize="sm" colorScheme="blue" px={6} onClick={(e) => { handleSubmit(e); }} isLoading={isSubmitting}>Save</Button>
                                        </Stack>
                                    </Stack>
                                </form>
                            )
                        }}
                    </Formik>
                )}
            </Stack>
        </Stack>
    )
}