import { getFirestore, query, collection, where, and, or, getDocs, doc, setDoc } from "firebase/firestore"
import { convertObjToArrayWithKey, getNewFirestoreDocId } from '../../helpers'
import { getFiles } from "./files-actions";
import { wait } from "../../../shared/helpers";
import deepmerge from 'deepmerge';
import { isEmpty, map } from 'lodash'
import { chain } from '../../helpers/chain';

export const getFolders = ({ claims, archived }) => {

  return (dispatch, getState) => {

    const { folders } = getState()
    if (folders?.loaded) return;

    const firestore = getFirestore()

    const { organization, user_id } = claims;

    dispatch({
      type: 'GET_FOLDERS',
      async payload() {

        if (!isEmpty(claims)) {

          if (organization) {

            const folders = (await getDocs(query(collection(firestore, "teams"),
              and(
                or(
                  and( // organization folders
                    where('archived', '==', false),
                    where('organization', '==', organization),
                    and(
                      // only public folders or folders the user has access to
                      or(
                        where('members', '==', null),
                        where("members", "array-contains", user_id)
                      ))
                  )
                ))
            ),))?.docs?.map(doc => { return { doc, type: 'folder' } })

            const byId = await getDocsData({ organization, folders, user_id });
            const data = convertObjToArrayWithKey(byId);

            return { byId, data };

          } else {

            // free user
            return { data: [{ id: user_id }] }

          }

        }

      },
    }).then(({ value }) => {
      const { data } = value || {};
      if (data) dispatch(getFiles({ folderIds: map([...data], 'id'), archived }))
    }).catch(e => {
      console.error(e);
      return { data: [{ id: user_id }] }
    });
  };
};

export const toggleNewFolderModal = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { NewFolderModal } = state?.folders?.ui || {};
    dispatch({
      type: 'TOGGLE_NEW_FOLDER_MODAL',
      showing: !NewFolderModal.showing,
    });
  };
};

export const toggleShareFolderModal = (folder) => {
  return (dispatch, getState) => {
    const state = getState();
    const { ShareFolderModal } = state?.folders?.ui || {};
    dispatch({
      type: 'TOGGLE_SHARE_FOLDER_MODAL',
      showing: !ShareFolderModal?.showing,
      folder
    });
  }
}

export const toggleDeleteFolderModal = (folder) => {
  return (dispatch, getState) => {
    const state = getState();
    const { DeleteFolderModal } = state?.folders?.ui || {};
    dispatch({
      type: 'TOGGLE_DELETE_FOLDER_MODAL',
      showing: !DeleteFolderModal?.showing,
      folder
    });
  }
}

export const mergeFolderChange = (data, opts) => {
  return (dispatch, getState) => {
    const { folders } = getState()
    const byId = deepmerge(folders.byId, data, opts)
    dispatch({
      type: 'MERGE_FOLDER_CHANGE', byId
    })
  }
}

export const createNewFolder = ({ history, docData }) => {

  const firestore = getFirestore()

  return (dispatch, getState) => {

    const folderId = getNewFirestoreDocId("teams")

    dispatch({
      type: 'CREATE_NEW_FOLDER',
      async payload() {
        // wait a bit
        await wait(1000)
        // save to firestore
        const folderRef = doc(firestore, 'teams', folderId);
        await setDoc(folderRef, docData, { merge: true });
        // return to redux
        const byId = { [folderId]: docData }
        return { byId }
      }
    }).then(() => {
      dispatch(toggleNewFolderModal())
      history.push(`/app/folder/${folderId}`)
    }).catch(e => {
      console.error(e)
    })
  }
}

const getDocsData = async ({ organization, folders }) => {
  let arr = await Promise.all(folders.map(async ({ doc }) => {
    const { id } = doc;
    if (!doc.data()) return;
    const { lastEdit, sitemaps, ...rest } = doc.data()
    return {
      id,
      ...rest,
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate(),
      updatedBy: doc.data().updatedBy || lastEdit,
      team: doc.data().team || null,
      link: `/app/folder/${id}`
      // member
    };
  })
  );
  arr = arr.filter(folder => folder.id !== organization) // don't include default team
  return await chain(arr)?.keyBy('id')?.value();
};