import { createDraggingPlaceholderNodeData } from '../helpers';
import { sortBy, find } from 'lodash'

export const insertPlaceholderForVerticalTree = (nodesWithPlaceholder, { node, side }) => {

    var newPlaceholderNode = createDraggingPlaceholderNodeData(node, side);
    newPlaceholderNode.index = node.index;

    // eslint-disable-next-line
    var parentsChildNodes = nodesWithPlaceholder.filter(d => {
        if (d.parent && (d.parent.id === node.parent.id)) {
            return d;
        }
    });

    if (side === 'left') {
        var index = newPlaceholderNode.index - 1;
        newPlaceholderNode.index = index;
        parentsChildNodes.push(newPlaceholderNode);
    }
    if (side === 'right') parentsChildNodes.push(newPlaceholderNode);

    parentsChildNodes = sortBy(parentsChildNodes, ['index']);

    parentsChildNodes.forEach((d, i) => { d.index = i }); // sort nodes by index

    nodesWithPlaceholder.push(newPlaceholderNode);

    /* update indexes in nodes with placeholder to return */
    nodesWithPlaceholder.forEach(d => {
        const nodeFromParentsChildNodes = find(parentsChildNodes, c => c.id === d.id);
        if (nodeFromParentsChildNodes) { d.index = nodeFromParentsChildNodes.index; }
    });
    /* update indexes in nodes with placeholder to return */

    nodesWithPlaceholder = sortBy(nodesWithPlaceholder, ['depth', 'index']);

    return nodesWithPlaceholder;
    
};
