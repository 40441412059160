import { createContext, useEffect, useState } from "react"
import { getFirestore, collection, doc, getDoc } from "firebase/firestore"
import { isEmpty } from 'lodash'

export const TemplatesContext = createContext()

export const templates = [
    { id: "2qT9m8WnGJ17pwozl0eO", industry: "Software", collection: 'sitemaps' },
    { id: "Lu3eVoKCX8LwwyYRAQcG", industry: "Education", collection: 'sitemaps' },
    { id: "B6yE2q6yPmCxHocFI0vj", industry: "E-commerce", collection: 'sitemaps' },
    { id: "a80BFSt5XsoB9DcTDzKS", industry: "Education", collection: 'sitemaps' },
    { id: "FhnTqVZP9YVdgcr6pcXd", industry: "Non-profit", collection: 'sitemaps' },
    { id: "ylpbN6HKSqk3H5i9BTuQ", industry: "Healthcare", collection: 'sitemaps' },
    { id: "ol9ZfEsRkPusMUqn4WNb", industry: "Digital Marketing", collection: 'sitemaps' },
    { id: "KvkHW4uiteoRUGpRLZNF", industry: "Financial Services", name: "Banking", collection: 'sitemaps' },
    { id: "TPeFifNkBJ59pItqDF6i", industry: "Telecommunications", collection: 'sitemaps' },
]

export const TemplatesHOC = ({ children }) => {
    const [templatesJSON, setTemplatesJSON] = useState([])
    useEffect(() => {
        async function fetchTemplatesJSON() {
            const response = await fetch("https://help.rarchy.com/hubfs/Templates/data.json");
            const data = await response.json()
            setTemplatesJSON(data);
            // fetch data if doesn't exist (prints to console)
            if (!data) {
                if (import.meta.env.DEV) {
                    if (isEmpty(data)) {
                        let arr = []
                        await Promise.all(templates?.map(async (t, i) => {
                            // if (i !== 0) return;
                            const docRef = doc(collection(getFirestore(), t?.collection), t?.id)
                            const docSnap = await getDoc(docRef)
                            if (docSnap.exists()) {

                                const { name, ...rest } = docSnap?.data()

                                // obj data
                                const isSitemap = t?.collection === 'sitemaps'
                                const obj = { name, ...templates?.find(d => d?.id === docSnap?.id) || {} }

                                const dataDocIds = isSitemap ?
                                    [
                                        { docId: 'pages', dataKey: 'pages' },
                                        { docId: 'covers', dataKey: 'pages' },
                                        { docId: 'sections', dataKey: 'pages' },
                                        { docId: 'website_sections', dataKey: 'data' }
                                    ] :
                                    [
                                        { docId: 'elements', dataKey: 'data' }
                                    ]

                                await Promise.all(dataDocIds?.map(async ({ docId, dataKey }) => {
                                    const dataDocRef = doc(collection(getFirestore(), `${t?.collection}/${t?.id}/data`), docId)
                                    const dataDocSnap = await getDoc(dataDocRef)
                                    if (dataDocSnap.exists()) {
                                        obj.docs = { ...obj?.docs || {}, [docId]: { ...dataDocSnap?.data()?.[dataKey] } }
                                    }
                                }))

                                arr.push(obj)
                            }
                        }))
                        console.log(JSON.stringify(arr))
                    }
                }
            }
        }
        if (isEmpty(templatesJSON)) fetchTemplatesJSON()
    }, [])

    return (
        <TemplatesContext.Provider
            value={{ templatesJSON }}>
            {children}
        </TemplatesContext.Provider>
    )

}