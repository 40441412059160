import { BsStar, BsStarFill, BsTrash } from "react-icons/bs"
import { useIsFolderStarred } from "../../../../../../hooks"
import { ICON_SIZE, PY } from "../Menu"
import { Icon, MenuItem } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { setStarred } from "../../../../../../store/actions/user-actions"

export const StarredFolder = ({ folderId }) => {
    const dispatch = useDispatch()
    const isStarred = useIsFolderStarred(folderId)
    return (
        <MenuItem 
        py={PY} 
        icon={<Icon as={isStarred ? BsStarFill : BsStar} fontSize={ICON_SIZE} color={isStarred ? "rarchy-yellow" : "unset"} />} 
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setStarred({ id: folderId, isStarred: !isStarred, type: 'folders' }))
        }}>
            {`${isStarred ? "Uns" : "S"}tar folder`} 
        </MenuItem>
    )
}