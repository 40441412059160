import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transform } from '../../../canvas/utils/zoom';

import { ImAttachment } from 'react-icons/im';
import { togglePageDrawer } from '../../../../../../store/actions/sitemap-actions';
import { PageButton } from '../../components/PageButton';

export const Files = ({ d, showCovers }) => {
/* 
    const dispatch = useDispatch()
    const fontSize = `${(showCovers ? 18 : 15) * transform.k}px`
    return (
        <PageButton
            title="View Files"
            icon={<ImAttachment />}
            fontSize={fontSize}
            d={d}
            onClick={(e) => {
                e.stopPropagation()
                dispatch(togglePageDrawer({ node: d, defaultIndex: "files" }));
            }}
        />
    ) */
}