import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    IconButton,
    Tooltip,
    useColorModeValue as mode,
    useDisclosure,
    Image,
    VStack,
    HStack,
    Button,
} from "@chakra-ui/react"
import { AiOutlinePicLeft } from 'react-icons/ai';
import modules from './modules'
import { changePageSectionWireframe, getWireframeWithColor } from '../../../../utils/page-sections/wireframe';
import Masonry from "react-responsive-masonry"
import { sendDataLayerEventForPageSections } from '..';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { getSitemap } from '../../../../../../../../helpers';
import { usePrevious } from '../../../../../../../../hooks';

export const Wireframes = () => {

    const sitemap = getSitemap()

    const { isOpen, onToggle, onClose } = useDisclosure()
    const { section } = sitemap?.ui?.PageSectionsOptionsPopover
    const [wireframes, setWireframes] = useState(getWireframeImages(section))

    const handleClick = (wireframe) => {
        changePageSectionWireframe(wireframe);
        sendDataLayerEventForPageSections(`Wireframe`, wireframe);
        onClose();
    }

    /** ensures wireframes are set to right color in options when switching between sections **/
    const prevSection = usePrevious(section)
    useEffect(() => {
        if (section?.id !== prevSection?.id) setWireframes(getWireframeImages(section))
    }, [section, prevSection])
    /** ensures wireframes are set to right color in options when switching between sections **/

    return (
        <Popover
            offset={[82, 46]}
            isOpen={isOpen}
            onClose={onClose}
            closeOnBlur
        >
            <PopoverTrigger>
                <Tooltip variant="rounded-sm" placement='top' openDelay={500} label='Wireframes'>
                    <IconButton size="sm" icon={<AiOutlinePicLeft fontSize="18px" />} color="rarchy-color-sitemap-editor" variant="ghost" _hover={{ bgColor: "transparent" }} onClick={onToggle} />
                </Tooltip>
            </PopoverTrigger>
            <PopoverContent w="250px" h="250px" overflow="scroll" onClick={(e) => e.stopPropagation()}>
                <VStack p={1} px={2}>
                    <HStack justifyContent="space-between" mt={1} mb={-1} w="full">
                        <IconButton variant="ghost" size="xs" icon={<ArrowBackIcon fontSize="lg" />} onClick={() => { /* savePageSectionChanges(); */ onClose(); }} />
                        {section.wireframe && <Button
                            variant="ghost"
                            size="sm"
                            // w="full"
                            justifyContent="end"
                            fontWeight="400"
                            fontSize="sm"
                            h={7}
                            onClick={() => handleClick(undefined)}
                        >
                            Reset
                        </Button>}
                    </HStack>
                    <Masonry columnsCount={3}>
                        {useMemo(() => {
                            return wireframes.map((w, i) => (
                                <Image
                                    key={i}
                                    src={w.svg}
                                    p={1}
                                    rounded="sm"
                                    _hover={{ cursor: 'pointer', bgColor: 'gray.100' }}
                                    onClick={() => handleClick(w.id)}
                                />
                            ))
                        }, [wireframes])}
                    </Masonry>
                </VStack>
            </PopoverContent>
        </Popover >
    )
};

const getWireframeImages = (section) => {
    const wireframes = Object.keys(modules).map(id => {
        return ({ id, ...modules[id], svg: "data:image/svg+xml;base64," + btoa(getWireframeWithColor({ wireframe: id, color: section.color }, { forWireframe: true })) });
    });
    return wireframes;
}