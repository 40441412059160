import { Box, Button, Center, Divider, Flex, HStack, Heading, Skeleton, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import { AuthContainer } from ".."
import { useGetClaims, useGetFiles, useGetOrganizations, useGetRedirectUserQueryParams, useGetUser, useIsLoggedIn, usePrevious } from "../../../../hooks"
import { useHistory, Redirect } from "react-router-dom"
import { getItemNameFromDoc } from "../../../../helpers"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { switchAccount } from "../../../../store/actions/auth-actions"
import { UnderlineLink } from "../Components/UnderlineLink"
import { Link } from "react-router-dom"
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons"
import { getFirebase } from "react-redux-firebase"

export const ConfirmInvitation = () => {

    const isLoggedIn = useIsLoggedIn()

    const user = useGetUser()
    const { email, name, id, key, invitedBy } = useGetRedirectUserQueryParams()

    const { organization } = useGetClaims()

    const [status, setStatus] = useState(null)

    const forOrganization = key === 'organizations'
    const itemDocName = getItemNameFromDoc({ collection: key })?.toLowerCase()

    LoadedOrg({ forOrganization, status, setStatus })
    LoadedFile({ forOrganization, status, setStatus })

    const emailDoesntMatch = (user?.email && (user?.email !== email))

    const files = useGetFiles()
    const file = !forOrganization && files?.data?.find(file => file?.id === id)

    if (emailDoesntMatch) return <Redirect to="/app/" />

    const isSuccess = status && status === 'success'
    const isError = status && status !== 'success'

    return (

        <AuthContainer type="confirm-invitation">

            {!isLoggedIn && (
                <VStack mt={-8} w="full" align="start" spacing={8}>
                    <Skeleton w="50%" h="30px" />
                    <VStack w="full" align="start" spacing={4}>
                        <Skeleton w="50%" h="25px" />
                        <Skeleton w="75%" h="25px" />
                        <Skeleton w="50%" h="25px" />
                    </VStack>
                </VStack>
            )}

            {isLoggedIn && status && (
                <Heading fontSize="2xl" mt={-9}>
                    <HStack spacing={6} mb={7}>
                        {isSuccess && (
                            <>
                                <CheckCircleIcon color="rarchy-green" />
                                <Text>Confirmed invitation</Text>
                            </>
                        )}
                        {isError && (
                            <HStack color="rarchy-red" spacing={6}>
                                <WarningIcon />
                                <Text>Unable to join</Text>
                            </HStack>
                        )}
                    </HStack>
                </Heading>
            )}

            {isLoggedIn && !status && (
                <>
                    <Heading fontSize="2xl" mt={-9}>Confirming invite...</Heading>
                    <Center pt="75px" pb="150px">
                        <Spinner size="xl" speed="0.7s" />
                    </Center>
                </>
            )}

            {isLoggedIn && isSuccess && (
                <Stack spacing={9}>
                    <VStack w="full" align="start" spacing={3} fontWeight="medium">
                        <Box>
                            {forOrganization && (
                                `You are now a member of the ${name} team, along with ${invitedBy} and others...`
                            )}
                            {!forOrganization && (
                                <>
                                    {`You can now collaborate with ${invitedBy} and others on the ${itemDocName}: `}
                                    <Link to={file?.link}><UnderlineLink>{name}</UnderlineLink></Link>
                                </>
                            )}
                        </Box>
                    </VStack>
                    <Flex justify="end" w="full" gap={3}>
                        <Link to="/"><Button colorScheme={forOrganization ? "blue" : "unset"} variant={forOrganization ? "solid" : "ghost"}>Go to Account</Button></Link>
                        {file && <Link to={file?.link}><Button px={7} colorScheme="blue">Open</Button></Link>}
                    </Flex>
                    <Divider p={0} m={-1} />
                    <Text fontSize="sm" color="fg.subtle">
                        {forOrganization && (
                            <>
                                You can easily switch between your accounts using the dropdowns in the side menu or the navbar.
                            </>
                        )}
                        {!forOrganization && (
                            <>
                                {`This ${itemDocName} will also be listed under the 'Shared with you' screen within your Rarchy projects.`}
                            </>
                        )}
                    </Text>
                </Stack>
            )}

            {isLoggedIn && isError && (
                <Stack spacing={6}>
                    <VStack w="full" align="start" spacing={3} fontWeight="medium">
                        <Box>{status}</Box>
                    </VStack>
                    <Text fontSize="sm" color="fg.subtle">{`Please contact ${invitedBy} (${email}) if you think this is an error.`}
                        <Divider mt={6} />
                    </Text>
                    <Flex justify="end" w="full" gap={6}>
                        <Link to={`/help/ticket`}><Button variant="ghost">Contact Us</Button></Link>
                        <Link to={`/sitemaps`}><Button colorScheme="blue">Go to Account</Button></Link>
                    </Flex>
                </Stack>
            )}

        </AuthContainer>
    )
}

export const LoadedOrg = ({ forOrganization, status, setStatus }) => {

    const dispatch = useDispatch()
    const firebase = getFirebase()

    const { email, name, id } = useGetRedirectUserQueryParams()

    const organizations = useGetOrganizations()
    const loadedOrganizations = organizations?.loaded
    const gotOrganization = forOrganization && organizations?.data?.find(org => org?.id === id)
    const prevGotOrganization = usePrevious(gotOrganization)

    useEffect(() => {

        if (!status) {

            if (forOrganization && loadedOrganizations) {
                if (!gotOrganization) {
                    confirmInvite();
                }
                // already in organization
                else if (gotOrganization && prevGotOrganization) {
                    setStatus('success');
                    dispatch(switchAccount(gotOrganization?.id))
                }
            }
        }

        async function confirmInvite() {
            try {
                await firebase.functions().httpsCallable('organizations-confirmUserInvite')({ email, organization: id, name })
                setStatus('success')
                dispatch(switchAccount(id))
            } catch (e) {
                setStatus(e?.message)
            }
        }

    }, [status, loadedOrganizations, gotOrganization, prevGotOrganization])

}

export const LoadedFile = ({ forOrganization, status, setStatus }) => {

    const files = useGetFiles()
    const loadedFiles = files?.loaded

    const { id } = useGetRedirectUserQueryParams()

    const gotFile = !forOrganization && files?.data?.find(file => file?.id === id)
    const prevGotFile = usePrevious(gotFile)

    useEffect(() => {

        if (!status) {

            if (!forOrganization && loadedFiles) {
                if (!gotFile) {
                    // console.log("file isn't loaded yet - call function")
                } else if (gotFile && prevGotFile) {
                    // console.log('got file!')
                    setStatus('success');
                    // history.push(gotFile?.link)
                }
            }

        }

    }, [status, loadedFiles, gotFile, prevGotFile])

}