import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
    Input,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import { addWebsiteSectionChange, mergeWebsiteSectionsEdits, toggleWebsiteSectionOptionsPopover } from '../../../../../../../store/actions/sitemap-actions';
import { isEmpty } from 'lodash'

/*** popover ****/
export const Rename = ({ WebsiteSectionOptionsPopover, website_sections, sitemap, user }) => {
    const dispatch = useDispatch();
    const inputValRef = useRef();
    const { section, offset } = WebsiteSectionOptionsPopover;
    const [inputVal, setInputVal] = useState(section.title);
    const websiteSectionsCount = Object.keys(website_sections).length;
    // update inputVal in ref
    useEffect(() => { inputValRef.current = inputVal }, [inputVal]);
    // component will unmount
    useEffect(() => {
        return () => {
            const val = inputValRef.current;
            if (isEmpty(val)) return;

            const oldTitle = section.title;

            if (oldTitle === val) return; // not changed

            // change
            website_sections[section.id].title = val;

            /*** merge page edits ***/
            dispatch(mergeWebsiteSectionsEdits({ website_sections }));
            /*** merge page edits ***/

            const changedWebsiteSection = { id: section.id, title: val }

            // save
            const change = {
                id: new Date().getTime(),
                data: [{ action: 'website-section-rename', ...changedWebsiteSection }],
            };
            const history = {
                action: 'website-section-rename',
                data: { section: changedWebsiteSection, oldTitle },
            };

            // add page change in redux
            dispatch(addWebsiteSectionChange({ change, history }));
        }
    }, []);
    return (
        <Input
            size="sm"
            autoFocus
            textAlign="center"
            defaultValue={section.title}
            variant="flushed"
            onChange={(e) => setInputVal(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') dispatch(toggleWebsiteSectionOptionsPopover({ showing: false }))
            }} />
    )
}; 