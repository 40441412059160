import { useEffect, useReducer, useState } from "react"
import { useDispatch } from "react-redux"
import { useMediaQuery } from "@chakra-ui/react"
import { useGetOnboardingModal, useGetUser } from "../../../hooks/"
import { toggleOnboardingModal } from "../../../store/actions/ui-actions"
import { SplitModal } from "../../Components/Modals/SplitModal"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { SitemapEditorOnboarding } from "./SitemapEditor"

export const OnboardingModalContainer = () => {

    const [stepIndex, setStepIndex] = useState(0)

    const dispatch = useDispatch()
    const [hideRight] = useMediaQuery('(max-width: 1100px)')
    const NewSitemapModal = useGetOnboardingModal()
    const { key } = NewSitemapModal || {}

    const user = useGetUser()

    useEffect(() => {
        if (!user?.onboarding?.[key]?.shown) setDoc(doc(getFirestore(), 'users', user?.id), { onboarding: { [key]: { shown: new Date() } } }, { merge: true })
    }, [])

    return (
        <SplitModal
            isOpen
            isCentered
            onClose={() => dispatch(toggleOnboardingModal())}
            size="4xl"
            left={
                <>
                    {key === 'sitemap_editor' && <SitemapEditorOnboarding side="left" stepIndex={stepIndex} setStepIndex={setStepIndex} />}
                </>
            }
            right={
                <>
                    {key === 'sitemap_editor' && <SitemapEditorOnboarding side="right" stepIndex={stepIndex} setStepIndex={setStepIndex} />}
                </>
            }
        />
    )

}