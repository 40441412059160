import { getInUserFlow, getUserFlow } from '../../../../../helpers';
import { getX, getY } from '../../../../Sitemap/app/canvas/utils/helpers';

import { keys } from 'lodash';
import { render as renderSitemap } from '../../../../Sitemap/app/canvas/render';
import { render as renderUserFlow } from '../../../../Sitemap/user-flows/render';
import { store } from '../../../../../store';
import { toggleExportCoversProgressModal } from '../../../../../store/actions/sitemap-actions';
import { wait } from '../../../../../../shared/helpers';
import { zoomIdentity } from 'd3';

export const exportSVG = async (name) => {

    const state = store.getState();
    const { sitemap } = state;
    const inUserFlow = getInUserFlow()

    const { width, height, left, top } = getWidthAndHeight(sitemap);

    const render = !inUserFlow ? renderSitemap : renderUserFlow;

    var ctx = window.C2S ? new window.C2S(width, height) : null;
    if (!ctx) return;

    const { base64CoversForExport } = await render(null, null, ctx);

    const { ExportCoversProgressModal } = store.getState().sitemap?.ui;

    if (!inUserFlow && (sitemap?.showCovers && !ExportCoversProgressModal.showing)) return;

    var cloned = ctx.getSvg();

    if (inUserFlow || (!inUserFlow && sitemap?.format !== 'nodes')) {
        // don't think this is needed any more
        var all = cloned.getElementsByTagName("*");
        for (var i = 0, max = all.length; i < max; i++) {
            const el = all[i];
            if (el) {
                // get and set xy for images
                if (el.tagName === 'image') {
                    if (base64CoversForExport[el.href.baseVal]) { el.setAttributeNS(null, 'href', base64CoversForExport[el.href.baseVal]); };
                    if (el.href.baseVal.includes('/svg')) el.setAttributeNS(null, 'href', el.href.baseVal);
                    el.removeAttribute('xlink:href'); // this has to be after base64 
                }
                // remove transparent rect
                if (el.tagName === 'rect' && el.getAttribute('fill') === 'transparent') {
                    el.remove();
                }
            }
        }
    };

    var fitted = zoomIdentity.translate(Math.abs(left), Math.abs(top));
    var g = cloned.getElementsByTagName("g")[1];
    if (g) {
        const fittedX = left > 0 ? -fitted.x : fitted.x
        const fittedY = top > 0 ? -fitted.y : fitted.y
        g.setAttribute('transform', `translate(${fittedX},${fittedY}) scale(${fitted.k})`);
    };

    // replace all 'transparents' to none (otherwise sketch messes up)
    var svgData = cloned.outerHTML.replaceAll("transparent", "none");

    if (ExportCoversProgressModal.showing) {
        await wait(500);
        store.dispatch(toggleExportCoversProgressModal({ showing: false }));
    }

    /*** export to svg ***/
    var svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    var svgUrl = URL.createObjectURL(svgBlob);

    if (import.meta.env.DEV) {
        window.open(svgUrl, "_blank");
    } else {
        // download link
        var downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = `${name}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    /*** export to svg ***/

};

export const getWidthAndHeight = (sitemap, opts = {}) => {

    const inUserFlow = getInUserFlow()
    const flow = getUserFlow()

    const { forThumbnail } = opts;

    const { nodes, website_sections } = sitemap?.data;

    const nodesToUse = [...(forThumbnail || (inUserFlow && flow.data.nodes) || nodes)];

    let left = Math.min.apply(Math, nodesToUse.map(d => getX(d) - 1));
    let right = Math.max.apply(Math, nodesToUse.map(d => getX(d) + 226));

    let top = Math.min.apply(Math, nodesToUse.map(d => getY(d) - ((inUserFlow && !d?.type?.includes('page')) ? 25 : 0))); // not sure why we need '25' but it works
    let bottom = Math.max.apply(Math, nodesToUse.map(d => (getY(d) + d.nodeHeight) + 1));

    // account for website sections
    if (!inUserFlow) {
        if (!forThumbnail) {
            keys(website_sections)?.forEach(ws => {
                const { y } = website_sections[ws];
                if (y > bottom) bottom = y + 25;
            })
        }
    }

    const box = {
        width: (right - left),
        height: (bottom - top),
        left,
        top
    }

    return box;
};
