import { zoom as d3Zoom, event, select, zoomIdentity } from 'd3';
import { getCanvasTextEditor, getEditor, getInUserFlow, getIsNavbarHidden, getIsTemplateDrawerOpen, getSitemap, getUI, getUserFlow } from '../../../../../helpers/index.js';
import { setZoomLevel, toggleCanvasTextEditor } from '../../../../../store/actions/editor-actions';
import { togglePageButtons, togglePageSectionsOptionsPopover, toggleWebsiteSectionNewButton, toggleWebsiteSectionOptionsPopover } from '../../../../../store/actions/sitemap-actions';

import { drag } from './drag';
import { render as renderPages } from '../render';
import { render as renderUserFlow } from '../../../user-flows/render';
import { showCommentsPopover } from '../../../comments/helpers.jsx';
import { store } from '../../../../../store';
import { toggleUserFlowSymbolButtons } from '../../../../../store/actions/flow-actions';

export var transform = zoomIdentity;

var disablePan = null;

const toolbarHeight = 60;

var isZooming;

export const keepScale = (num) => num / transform.k;

// zooming
const zoom = d3Zoom().scaleExtent([0.05, 1.5]).on('zoom', zoomed)
    .on('end', () => {

        isZooming = false;

        const sitemap = getSitemap()
        const editor = getEditor()

        /*** reset cursor ***/
        const intCanvas = select('#interactions-canvas');
        if (intCanvas && intCanvas.classed('grab')) intCanvas.classed('grab', false);
        /*** reset cursor ***/

        /*** re-align comment popover on zoom end */
        const { PageCommentsPopover } = sitemap?.ui || {};
        if (PageCommentsPopover.showing) showCommentsPopover(PageCommentsPopover.node)
        /*** re-align comment popover on zoom end */

        /* setTimeout(() => {
            if (sitemap?.ui.PageButtons.zooming) {
                store.dispatch(togglePageButtons({ ...sitemap?.ui.PageButtons, zooming: false }))
            }
            if (flow.ui.SymbolButtons.zooming) store.dispatch(toggleUserFlowSymbolButtons({ ...flow.ui.SymbolButtons, zooming: false }))
        }, 250); */

        // so canvas buttons are updated
        // var initialZoom = sitemap?.showCovers ? 0.7 : 0.9;
        var scale = Math.ceil((transform.k) / 0.1) * 0.1
        const zoomLevel = Math.round((scale/* + (1 - initialZoom)*/) * 100);
        if (zoomLevel !== editor?.utils.zoomLevel) store.dispatch(setZoomLevel(zoomLevel));

        render();

        // re-enable zoom
        select(select('#interactions-canvas').node()).call(drag().on("start.render drag.render end.render", () => { })).call(zoom).on('dblclick.zoom', null);
        // re-enable pan if disabled
        if (disablePan) {
            var coords = { ...disablePan } // set transform to initial state before attempted drag over text edit
            setTimeout(() => { zoomToCoords(coords); disablePan = null; }, 10) // needs timeout otherwise max stack exceeded
        }

    });

function zoomed() {

    if (disablePan) return;

    if (!isZooming) {

        isZooming = true;

        const sitemap = getSitemap()
        const flow = getUserFlow()

        const { PageSectionsOptionsPopover, WebsiteSectionOptionsPopover, WebsiteSectionNewButton, PageButtons } = sitemap?.ui;

        const CanvasTextEditor = getCanvasTextEditor()

        const { SymbolButtons } = flow?.ui;

        /*** hide canvas text editor ***/
        if (CanvasTextEditor.showing) store.dispatch(toggleCanvasTextEditor({ showing: false }));
        /*** hide canvas text editor ***/

        /*** hide page sections options popover ***/
        if (PageSectionsOptionsPopover.showing) store.dispatch(togglePageSectionsOptionsPopover({ showing: false }));
        /*** hide page sections options popover ***/

        /*** hide website section tings ***/
        if (WebsiteSectionOptionsPopover.showing) store.dispatch(toggleWebsiteSectionOptionsPopover({ showing: false }));
        if (WebsiteSectionNewButton.showing) store.dispatch(toggleWebsiteSectionNewButton({ showing: false }));
        /*** hide website section tings ***/

        if (PageButtons?.showing && !PageButtons.zooming) {
            store.dispatch(togglePageButtons({ ...PageButtons, zooming: true, renaming: false }))
        }

        if (SymbolButtons.showing && !SymbolButtons.zooming) {
            store.dispatch(toggleUserFlowSymbolButtons({ ...SymbolButtons, zooming: true, renaming: false }))
        }
    }

    /*** disable pan if highlighting canvas editor input ***/
    /* const { editor, sitemap } = store.getState()
    if (editor?.ui.CanvasTextEditor.showing && (mouseover.text && mouseover.text.id === editor?.ui.CanvasTextEditor.node.id)) {
        if (!disablePan) {
            select(select('#interactions-canvas').node()).on(".zoom", null)
            disablePan = { x: transform.x, y: transform.y, k: transform.k };
        }
        return;
    } */
    /*** disable pan if highlighting canvas editor input ***/

    /*** grabbing cursor */
    if (event.sourceEvent) {
        if (typeof event.sourceEvent.preventDefault === 'function') event.sourceEvent.preventDefault();
        /*** set cursor ***/
        if (event.sourceEvent.type === 'mousemove') {
            const intCanvas = select('#interactions-canvas');
            if (intCanvas && !intCanvas.classed('grab')) intCanvas.classed('grab', true);
        }
    }
    /*** grabbing cursor */

    transform = event.transform;

    render();

}

const render = (opts) => {
    if (!opts) opts = {}
    const inUserFlow = getInUserFlow()
    !inUserFlow ? renderPages(null, null, null, opts) : renderUserFlow()
}

const zoomToCoords = (newTransform) => {
    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(newTransform.x, newTransform.y).scale(newTransform.k));
}

export const zoomForSectionRename = (newTransform) => {
    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(transform.x, transform.y).scale(newTransform));
}

export const center = () => {

    const sitemap = getSitemap()
    const flow = getUserFlow()
    const isTemplateDrawerOpen = getIsTemplateDrawerOpen()

    var sw, sh;
    var z = (sitemap?.showCovers ? 0.66 : 0.5) * (isTemplateDrawerOpen ? 0.8 : 1);

    var { width, height } = select('#canvases-container').node()?.getBoundingClientRect() || {};

    const inUserFlow = getInUserFlow();

    if (!inUserFlow) {
        switch (sitemap?.format) {
            case 'tree':
                sw = width / 10;
                sh = height / 2 - (sitemap?.data.nodes[0].height / 2.5);
                break;
            case 'tree-vertical':
            case 'tree-vertical-matrix':
                sw = width / 2.25;
                sh = height / 6
                break;
            case 'nodes':
                sw = (width / 2);
                sh = ((height - toolbarHeight) / 2)
                z = 1.2;
                break;
            default:
                break;
        }
    }

    if (inUserFlow) {

        select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(width / 2, height / 2).scale(z));

        const arr = flow?.data?.nodes;

        let xes = arr?.map(o => o.x) || [0];
        let yes = arr?.map(o => o.y) || [0];

        const left = Math.min(...xes);
        const right = Math.max(...xes) + 225;
        const top = Math.min(...yes) - 132.5;
        const bottom = Math.max(...yes) + (132.5 / 2);

        const box = { x: left, y: top, width: right - left, height: bottom - top };

        let scale = 0.8 / Math.max(box.width / width, box.height / height);
        if (!scale || (scale > 1)) scale = 1
        // Reset transform.
        let newTransform = zoomIdentity;
        // Center [0, 0].
        newTransform = newTransform.translate(width / 2, height / 2);
        // Apply scale.
        newTransform = newTransform.scale(scale);
        // Center elements.
        newTransform = newTransform.translate(((-box.x - box.width / 2) || 0), ((-box.y - box.height / 2) || 0));
        //
        return select(select('#interactions-canvas').node()).call(zoom.transform, newTransform);
    }

    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(sw, sh).scale(z));

}

export const centerNode = source => {

    const sitemap = getSitemap();

    const inUserFlow = getInUserFlow();

    const { width, height } = select('#container').node().getBoundingClientRect()

    let x, y;
    let scale = 0.9;

    if (!inUserFlow) {
        switch (sitemap?.format) {
            case 'tree':
                x = -source.y + width / 2.4;
                y = -source.x + height / (sitemap?.showCovers ? 2.55 : 2.9);
                break;
            case 'tree-vertical':
            case 'tree-vertical-matrix':
                x = -source.x + width / (sitemap?.showCovers ? 2.45 : 2.4);
                y = -source.y + height / (sitemap?.showCovers ? 2.6 : 3);
                break;
            case 'indent':
                x = -source.y + width / 2.5;
                y = -source.x + height / 2.9;
                break;
            case 'nodes':
                x = width / 2;
                y = height / 2.66;
                break;
            default:
                break;
        }
    } else {
        x = -source.x + (width - (225 / 4)) / 2;
        y = (-source.y + height /* - ((source.height || 1) / 2))) */ / 2.25); // not perfect, but good enough
    }
    //
    if (source.searched) delete source.searched;
    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(x, y).scale(scale));
};

export const centerToComments = source => {

    const sitemap = getSitemap()

    const { PageCommentsDrawer } = sitemap?.ui;

    var x, y;

    switch (sitemap?.format) {
        case 'tree':
            x = -source.y + 70
            y = -source.x + (sitemap?.showCovers ? 200 : 100)
            break;
        case 'tree-vertical':
        case 'tree-vertical-matrix':
            x = -source.x + 70
            y = -source.y + (sitemap?.showCovers ? 200 : 100)
            break;
        case 'indent':
            x = -source.y + 70
            y = -source.x + (sitemap?.showCovers ? 200 : 100)
            break;
        case 'nodes':
            // x = width / 2;
            // y = height / 2.66;
            break;
        default:
            break;
    };
    //
    if (source.goToComments) delete source.goToComments;
    //
    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(x, y).scale(1));
    /*** show page comments popover ***/
    if (!PageCommentsDrawer.showing) setTimeout(() => showCommentsPopover(source), 250);
    /*** show page comments popover ***/
};

export const zoomIn = () => {
    if (transform.k >= 1.5) return;
    var scale = Math.round((Math.ceil(transform.k * 1.1 / 0.1) * 0.1) * 10) / 10
    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(transform.x, transform.y).scale(scale));
};

export const zoomOut = () => {
    if (transform.k <= 0.1) return;
    var scale = Math.round((Math.floor(transform.k * 0.9 / 0.1) * 0.1) * 10) / 10
    select(select('#interactions-canvas').node()).call(zoom.transform, zoomIdentity.translate(transform.x, transform.y).scale(scale));
};

export const initZoom = () => {
    select(select('#interactions-canvas').node()).on(".zoom", null)
    select(select('#interactions-canvas').node()).call(drag().on("start.render drag.render end.render", () => { })).call(zoom).on('dblclick.zoom', null);
}