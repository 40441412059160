
import { getX, getY, getRandomColor } from '../../canvas/utils/helpers';
import { colors, colorByNodeIds } from '../../canvas/utils/drag';
import { transform } from '../../canvas/utils/zoom';
import { NODE_WIDTH } from '../../canvas/components/drag';
import { getSitemap } from '../../../../../helpers';

let indentedBarMargin;

export const spacing = (context, nodes) => {

    const sitemap = getSitemap();

    indentedBarMargin = sitemap?.showCovers ? 35 : 20;

    nodes.forEach((d, i) => {
        if (!d) return;
        const x = getX(d), y = getY(d);
        addBelowPlaceholder(context, d, x, y, nodes, i);
    });

}


const addBelowPlaceholder = (context, d, x, y, nodes, nodeIndex) => {

    if (!d.overflow) {

        const nodeBelow = nodes[nodeIndex + 1];
        if (nodeBelow && nodeBelow.id === 'dragging-placeholder') return;

        var randomColorForBelow;
        if (!colorByNodeIds[d.id] || !colorByNodeIds[d.id]['below']) {
            /*** assign new random color to node id ***/
            randomColorForBelow = getRandomColor();
            colorByNodeIds[d.id] = { ...colorByNodeIds[d.id], 'below': randomColorForBelow };
            colors[randomColorForBelow] = { node: d, side: 'below' };
            /*** assign new random color to node id ***/
        } else {
            randomColorForBelow = colorByNodeIds[d.id]['below']; // use existing assigned color
        }
        if (d.children) x = x + 50;
        context.fillStyle = randomColorForBelow;

        if (context.canvas.id === 'picker-canvas') {
            context.fillRect(transform.applyX(x), transform.applyY(y + d.nodeHeight), NODE_WIDTH * transform.k, indentedBarMargin * transform.k);
        } else {
            context.fillRect(x, y + (d.nodeHeight), NODE_WIDTH, indentedBarMargin);
        }
    }

};