import React, { useEffect } from 'react'
import { useGetRevisionHistoryDrawer, useGetSitemap, useInSitemap, useInUserFlow, usePrevious } from '../../../../../../../../../hooks'

import {
    ModalBody
} from "@chakra-ui/react"
import PagesCanvas from '../../../../../../../../Sitemap/app/canvas/'
import { Toolbar } from '../../../../../../../Toolbar'
import { Canvas as UserFlowsCanvas } from '../../../../../../../../Sitemap/user-flows'
import { init } from '../../../../../../../../Sitemap/app/canvas/shared'

export const App = () => {

    const inSitemap = useInSitemap()

    const inUserFlow = useInUserFlow()
    
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    const prevRevisionHistoryDrawer = usePrevious(RevisionHistoryDrawer)

    useEffect(() => {
        if (RevisionHistoryDrawer.retrieved) {
            if (!prevRevisionHistoryDrawer?.retrieved) { init() }
            if (RevisionHistoryDrawer?.selectedIndex !== prevRevisionHistoryDrawer?.selectedIndex) { init() }
        }
    }, [RevisionHistoryDrawer, prevRevisionHistoryDrawer])
    return (
        <ModalBody p={0} w="calc(100% - 300px)">
            <Toolbar />
            {inSitemap && <PagesCanvas />}
            {inUserFlow && <UserFlowsCanvas />}
        </ModalBody>
    )
}