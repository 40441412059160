import {
    Editable,
    EditableInput,
    EditablePreview,
    HStack,
    Link,
    Tooltip
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from 'react'
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCanEditFile, useInEditor, useInTrash } from '../../../hooks'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import { getUserId } from '../../../helpers'
import { mergeFileChange } from '../../../store/actions/files-actions'
import { useDispatch } from 'react-redux'

export const SitemapDomain = ({ file, opts = {} }) => {

    const dispatch = useDispatch()

    const { domain } = file || {}
    
    const [val, setVal] = useState(domain)
    
    const inTrash = useInTrash()
    
    const preview = useRef()
    
    const { w } = opts

    const inEditor = useInEditor()
    const canEditFile = useCanEditFile()

    const canEdit = !inEditor || inEditor && canEditFile

    return (
        <HStack spacing={2}>
            <Tooltip label="Visit domain" variant="rounded-sm" hasArrow openDelay={500}>
                <Link mt={-0.5} href={domain} isExternal><ExternalLinkIcon fontSize="xs" color={val ? "rarchy-link" : "fg.subtle"} /></Link>
            </Tooltip>
            <Tooltip label={val || "Enter Website URL"} variant="rounded-sm" hasArrow openDelay={500}>
                <Editable
                    color={val ? "rarchy-link" : "fg.subtle"}
                    value={val}
                    fontWeight="medium"
                    placeholder="--"
                    fontSize="sm"
                    mb={0}
                    w={w || "full"}
                    isPreviewFocusable={!inTrash || canEdit}
                    isDisabled={inTrash || !canEdit}
                    variant="flushed"
                    onSubmit={async () => {
                        if (val) {
                            if (canEdit) { // fail-safe
                                try {
                                    const docRef = doc(getFirestore(), 'sitemaps', file?.id);
                                    await setDoc(docRef, { domain: val, updatedBy: getUserId(), updatedAt: new Date() }, { merge: true });
                                    dispatch(mergeFileChange(file?.id, file?.collection, { domain: val }))
                                } catch (e) {
                                    console.error(e)
                                }
                            } // fail-safe
                        }
                    }}
                >
                    <EditablePreview
                        ref={preview}
                        w={w || "full"}
                        noOfLines={1}
                        py={0}
                    />
                    <EditableInput
                        py={0}
                        w={w || "full"}
                        onBlur={(e) => setVal(e.target.value)}
                        onChange={(e) => setVal(e.target.value)}
                    />
                </Editable>
            </Tooltip>
        </HStack>
    )
}