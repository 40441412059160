import { Icon, MenuItem, Stack, Text } from "@chakra-ui/react"
import { ICON_SIZE, PY } from "../../../../Projects/Folders/Components/Options/Menu"
import { HiOutlineFolder } from "react-icons/hi"
import { IoChevronForward } from "react-icons/io5"
import { getIsPublicFolderOrSharedWithUser } from "../../../../../helpers"
import { useMoveToFolderContext } from "../../../../Projects/Folders/hooks"

export const MoveToFolderMenuItem = () => {
    const { setShowMoveToFolderScreen, folder } = useMoveToFolderContext()
    // don't show if folder and not public/shared with user
    const isPublicOrSharedFolder = getIsPublicFolderOrSharedWithUser(folder)
    if (folder && !isPublicOrSharedFolder) return null
    // continue
    return (
        <MenuItem py={PY} icon={<Icon as={HiOutlineFolder} fontSize={ICON_SIZE} />} onClick={setShowMoveToFolderScreen.on}>
            <Stack direction="row" justify="space-between">
                <Text>Move to folder</Text>
                <Icon as={IoChevronForward} opacity={1} fontSize={ICON_SIZE} />
            </Stack>
        </MenuItem>
    )
}