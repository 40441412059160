import { Button, DarkMode, HStack, MenuButton, Stack, Text } from "@chakra-ui/react"

import { AddIcon } from "@chakra-ui/icons"
import { CreateDropdown } from "../../Components/Navbar/Create"
import { Home } from "./Home"
import { SkeletonWrapper } from "../../Components/Shared/SkeletonWrapper"
import { Upgrade } from "./Upgrade"

export const Header = () => {
    return (
        <Stack spacing={5} py={1}>
            <Stack align="start" w="full" spacing={4}>
                <Upgrade />
                <CreateDropdown
                    placement="bottom-start"
                    matchWidth
                    hideDefaultHeader
                    ignoreSkeleton
                    button={(
                        <DarkMode>
                            <SkeletonWrapper isLoaded>
                                <MenuButton
                                    as={Button}
                                    borderRadius="lg"
                                    size="md"
                                    w="full"
                                    fontSize="sm"
                                    iconSpacing={0}
                                    color="white"
                                    bgColor="blue.600"
                                    _hover={{ bgColor: "blue.500" }}
                                    _active={{ bgColor: "blue.500" }}
                                >
                                    <HStack justify="center" spacing={4} ml={-2}>
                                        <AddIcon />
                                        <Text>Create new</Text>
                                    </HStack>
                                </MenuButton>
                            </SkeletonWrapper>
                        </DarkMode>
                    )}
                />
                {/* <AccountSwitcher inSideMenu /> */}
            </Stack>
            <Home />
        </Stack>
    )
}