import React from 'react';
import {
    Box
} from "@chakra-ui/react"

import { Settings as SettingsFromPageDrawer } from '../../../../../page-drawer/Covers/Screenshot/Settings';

export const Options = ({ activeStep, options, setOptions, inPreview, isCapturing }) => {
    if (activeStep !== 1) return null;
    return (
        <Box px={3} w="full" mb={7} mt={-3}>
            <SettingsFromPageDrawer settings={options} setSettings={setOptions} isDisabled={isCapturing} showTitle />
        </Box>
    )
}