import { Box, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const Card = (props) => (
    <Box
        mt={4}
        bgColor={props.bgColor}
        _hover={{ boxShadow: "base" }}
        shadow="sm"
        maxW="2xl"
        mx="auto"
        borderWidth="1px"
        rounded="md"
        {...props}
    >
        
    </Box>
)