import { ARC_TO_RADIUS } from "../helpers";

/* THESE ARE THE SAME */ 
export const drawBottomRight = (context, startX, startY, endX, endY) => {
    context.moveTo(startX, startY);
    context.arcTo(startX, endY, endX, endY, ARC_TO_RADIUS);
    context.lineTo(endX, endY);
}

export const drawTopLeft = (context, startX, startY, endX, endY) => {
    context.moveTo(startX, startY);
    context.arcTo(startX, endY, endX, endY, ARC_TO_RADIUS);
    context.lineTo(endX, endY);
}
/* THESE ARE THE SAME */

/* THESE ARE THE SAME */
export const drawBottomLeft = (context, startX, startY, endX, endY) => {
    context.moveTo(startX, startY);
    context.arcTo(endX, startY, endX, endY, ARC_TO_RADIUS);
    context.lineTo(endX, endY);
}

export const drawTopRight = (context, startX, startY, endX, endY) => {
    context.moveTo(startX, startY);
    context.arcTo(endX, startY, endX, endY, ARC_TO_RADIUS);
    context.lineTo(endX, endY);
}
/* THESE ARE THE SAME */