import {
    CloseButton,
    HStack,
    Heading,
} from "@chakra-ui/react"

import { togglePageCommentsDrawer } from "../../../../../store/actions/sitemap-actions"
import { useDispatch } from "react-redux"

export const Header = () => {
    const dispatch = useDispatch()
    return (
        <HStack w="full" justifyContent="space-between">
            <Heading size="sm" fontWeight="semibold">
                Comments
            </Heading>
            <CloseButton fontSize="sm" onClick={() => dispatch(togglePageCommentsDrawer())} />
        </HStack>
    )
}