import * as React from 'react'
import {
    Button,
} from "@chakra-ui/react"
import { Link } from 'react-router-dom'
import { useHasEditorLoaded, useIsLoggedIn } from '../../../../hooks';


export const SignUp = () => {
        
    const loadedEditor = useHasEditorLoaded()

    const isLoggedIn = useIsLoggedIn()

    if (loadedEditor && !isLoggedIn) return (
        <Link to="/signup">
            <Button colorScheme="blue" fontSize="sm" h={9} minW={9}>
                Sign up for free
            </Button>
        </Link>
    )
    return null
};