import {
    Popover,
    PopoverBody,
    PopoverContent,
} from "@chakra-ui/react"
import { getDefaultPalletteHeader, getIsDefaultPalletteHeader, motionPropsZeroAnimation } from '../../../../helpers';

import { ColorsLabels } from './Components/ColorsLabels';
import { EditorSkeletonWrapper } from '..';
import { TriggerButton } from './Components/TriggerButton';
import { changeEditorPallette } from '../../../../store/actions/editor-actions';
import { getCanEditInEditor } from '../../Navbar/helpers';
import { updateSitemapThumbnail } from '../../../../store/actions/sitemap-actions';
import { useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useGetSitemap } from '../../../../hooks';

export const Colors = () => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const firestore = useFirestore()
    
    // pallette change - not using editor pallette, as specifically for sitemap
    const handlePalletteChange = (pallette) => {
        const isDefaultPallette = getIsDefaultPalletteHeader(pallette.header)
        const header = isDefaultPallette ? getDefaultPalletteHeader() : pallette.header
        dispatch(changeEditorPallette({ header }))
    };

    return (
        <EditorSkeletonWrapper>
            <Popover isLazy placement="bottom-start" onClose={() => saveChanges({ sitemap, firestore, dispatch })}>
                <TriggerButton />
                <PopoverContent w="210px" px={0} motionProps={motionPropsZeroAnimation}>
                    <PopoverBody px={0}>
                        <ColorsLabels
                            selectedColor={sitemap?.pallette?.header}
                            handlePalletteChange={handlePalletteChange}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </EditorSkeletonWrapper>
    )
}

const saveChanges = async ({ sitemap, firestore, dispatch }) => {

    const { originalHeader } = sitemap?.ui.PalletteDropdown;

    if (!getCanEditInEditor()) return; // don't save if user can't edit

    /*** only save to firestore if the color has changed ***/
    if (sitemap?.pallette?.header !== originalHeader) {
        if (sitemap?.createdBy) {
            // amplitude.getInstance().logEvent('CHANGED_SITEMAP_PALLETTE', { id: sitemap?.id, pallette: sitemap?.pallette?.header }); // amplitude tracking
            var pallette = {};
            if (getIsDefaultPalletteHeader(sitemap?.pallette?.header)) {
                pallette = firestore.FieldValue.delete()
            } else {
                pallette = { header: sitemap?.pallette?.header }
            }
            await firestore.doc(`sitemaps/${sitemap?.id}`).set({ pallette }, { merge: true });
            dispatch(updateSitemapThumbnail())
        }
    }
    /*** only save to firestore if the color has changed ***/

}