import { ShareFolderHOC } from "./ShareFolderHOC"
import { ShareFolder } from "."
import { Stack, Button, useBoolean } from "@chakra-ui/react"
import { getMembersToSave } from "./helpers"
import { useDispatch } from "react-redux"
import { useGetUserId } from "../../../../../hooks"
import { mergeFolderChange } from "../../../../../store/actions/folders-actions"
import { getFirestore, setDoc, doc } from "firebase/firestore"
import { useShareFolderContext } from "../../hooks"
import { isEmpty, isEqual } from 'lodash'

export const ShareFolderDropdownModalWrapper = ({ folder, onClose }) => {
    return (
        <ShareFolderHOC folder={folder} onClose={onClose}>
            {({ membersToAdd, membersToRemove }) => (
                <Stack align="center">
                    <ShareFolder />
                    <SaveButton />
                </Stack>
            )}
        </ShareFolderHOC>
    )
}

const SaveButton = () => {

    const dispatch = useDispatch()

    const userId = useGetUserId()

    const [isSaving, setIsSaving] = useBoolean()

    const { folder, membersToAdd, membersToRemove, onClose } = useShareFolderContext()

    const checkedForPublicPrivateChange = ((folder?.members === null && membersToAdd === null) || (membersToAdd === null && !isEmpty(folder?.members)))

    const members = getMembersToSave({
        folder,
        membersToAdd: checkedForPublicPrivateChange ? null : [...(membersToAdd || []), ...(folder?.members || [])],
        membersToRemove
    });

    const isDisabled = isEqual(folder?.members, members)

    return (
        <Button
            my={4}
            fontSize="sm"
            w="max-content"
            px={6}
            isLoading={isSaving}
            isDisabled={isDisabled}
            onClick={async () => {
                setIsSaving.toggle()
                const firestoreData = { members, updatedAt: new Date(), updatedBy: userId }
                await setDoc(doc(getFirestore(), "teams", folder?.id), firestoreData, { merge: true })
                dispatch(mergeFolderChange({ [folder?.id]: { members } }, { arrayMerge: (destinationArray, sourceArray) => sourceArray })) // this is merging arrays instead of overwriting!
                setIsSaving.toggle()
                if (onClose) onClose()
            }}
        >
            Share folder
        </Button>
    )
}