import React, { useEffect } from 'react';
import { SharedCanvas, init } from './shared';
import { useGetSitemap, usePrevious } from '../../../../hooks';

// import { ScreenshotsDrawer } from '../page-drawer/Covers/Screenshot/Scheduled/Drawer';
import { CaptureDrawer } from '../covers/Capture/Drawer';
import { SubfolderTabs } from '../subfolders';
import { WebsiteSectionNewButton } from './components/website-sections/buttons/new';
import { WebsiteSectionOptionsPopover } from './components/website-sections/options';
import { toggleOnboardingModal } from '../../../../store/actions/ui-actions';
import { useDispatch } from 'react-redux';
import { useShouldShowSitemapEditorOnboarding } from '../../../../hooks/onboarding';

export default function Canvas() {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const prevSitemap = usePrevious(sitemap)

    const showOnboarding = useShouldShowSitemapEditorOnboarding()

    useEffect(() => {
        if (showOnboarding) {
            setTimeout(() => {
                dispatch(toggleOnboardingModal({ showing: true, key: 'sitemap_editor' }))
            }, 1000);
        }
    }, [])

    useEffect(() => {
        if (sitemap?.loaded && !prevSitemap?.loaded) init(sitemap)
    }, [sitemap, prevSitemap])

    return (
        <SharedCanvas>
            {sitemap?.ui.CaptureDrawer.showing && <CaptureDrawer sitemap={sitemap} />}
            {sitemap?.ui.WebsiteSectionOptionsPopover.showing && <WebsiteSectionOptionsPopover />}
            {sitemap?.ui.WebsiteSectionNewButton.showing && <WebsiteSectionNewButton />}
            <SubfolderTabs />
        </SharedCanvas>
    )
}