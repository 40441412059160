import { useDispatch } from "react-redux"
import { Box, Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup, Tooltip } from "@chakra-ui/react"
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons"

import { useGetProjectsSortFilter } from "../../../../../hooks"
import { changeProjectsModified } from "../../../../../store/actions/projects-actions"

import dayjs from 'dayjs'
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"
import { isEmpty } from 'lodash'

export const Modified = () => {
    const dispatch = useDispatch()
    const modified = useGetProjectsSortFilter('modified')
    const options = getOptions()
    return (
        <Menu placement="bottom-start" autoSelect>
            <SkeletonWrapper>
                <Tooltip label={modified?.title} variant="rounded-sm" placement="top" hasArrow openDelay={250}>
                    <MenuButton
                        as={Button}
                        onFocus={e => e.preventDefault()}
                        rightIcon={<ChevronDownIcon fontSize="xl" />}
                        pl={3.5}
                        pr={2.5}
                        variant="outline"
                        fontSize="sm"
                        fontWeight="normal"
                        _hover={{ bgColor: "rarchy-bg-white" }}
                        _expanded={{ borderColor: 'rarchy-blue', bgColor: "rarchy-bg-white" }}
                    >
                        {modified?.title}
                    </MenuButton>
                </Tooltip>
            </SkeletonWrapper>
            <MenuList fontSize="sm" w="300px">
                <MenuOptionGroup
                    as={Box}
                    pt={0}
                    mt={1}
                    pb={1}
                    title="Date modified"
                    type='radio'
                >
                </MenuOptionGroup>
                <MenuDivider />
                {options.map(o => {
                    return (
                        <MenuItem
                            key={o.key}
                            py={2}
                            pl={4}
                            command={modified.key === o.key ? <CheckIcon mr={2} fontSize="lg" /> : null}
                            onClick={() => {
                                setTimeout(() => {
                                    dispatch(changeProjectsModified(o))
                                }, 100);
                            }}
                        >
                            {o.label}
                        </MenuItem>
                    )
                })}
            </MenuList>
        </Menu>
    )
}

const getOptions = () => {
    return [
        { key: null, title: 'Date modified', label: 'Any time' },
        { key: 'today', timestamp: getTimestamp('today'), title: 'Today', label: 'Today' },
        { key: 'yesterday', timestamp: getTimestamp('yesterday'), title: 'Yesterday', label: 'Yesterday' },
        { key: '30d', timestamp: getTimestamp('30d'), title: 'Last 30 days', label: 'Last 30 days' },
        { key: '90d', timestamp: getTimestamp('90d'), title: 'Last 90 days', label: 'Last 90 days' },
        { key: 'year', timestamp: getTimestamp('year'), title: 'Last year', label: 'Last year' },
    ]
}

const getTimestamp = (key) => {
    let timestamp = []
    switch (key) {
        case 'today':
            timestamp.push(dayjs().startOf('day'))
            break;
        case 'yesterday':
            const today = dayjs().startOf('day')
            timestamp.push(today.subtract(1, 'day'))
            timestamp.push(today)
            break;
        case '30d':
            timestamp.push(dayjs().subtract(30, 'day'))
            break;
        case '90d':
            timestamp.push(dayjs().subtract(90, 'day'))
            break;
        case 'year':
            timestamp.push(dayjs().subtract(12, 'month'))
            break;
        default:
            break;
    }
    return !isEmpty(timestamp) ? timestamp.map(t => t.valueOf()) : null
}