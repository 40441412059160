import { getUsersFullName } from "../../../../../../helpers"
import { useGetPhotoURL, useGetUser } from "../../../../../../hooks"
import { Avatar, MenuButton } from "@chakra-ui/react"
import { SkeletonCircleWrapper } from "../../../../Shared/SkeletonWrapper"

export const ProfileAvatar = () => {
    const user = useGetUser()
    const name = getUsersFullName(user)
    const photoURL = useGetPhotoURL()
    return (
        <SkeletonCircleWrapper isLoaded={user?.id ? true : false}>
            <MenuButton>
                <Avatar
                    size="smd"
                    objectFit="cover"
                    name={name}
                    src={photoURL}
                >
                </Avatar>
            </MenuButton>
        </SkeletonCircleWrapper>
    )
}