import { Stack, Text } from "@chakra-ui/react"
import { useGetUser } from "../../../../hooks"
import { SectionHeader } from "../../Components"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const Email = () => {
    const user = useGetUser()
    return (
        <Stack spacing={3} mt={1.5}>
            <SectionHeader title="Email address" />
            <Stack direction="row" w="full" justify="space-between" mb={6}>
                <SkeletonWrapper w="fit-content">
                    <Text fontSize="sm">{user?.email}</Text>
                </SkeletonWrapper>
            </Stack>
        </Stack>
    )
}