import { CheckIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { Button, Flex, HStack, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { isEmpty } from 'lodash'

export const SearchWithFilter = ({ placeholder, minChar, onChange, filter, setFilter, }) => {
    return (
        <HStack spacing={3} mt={1} mb={5}>
            <InputGroup>
                <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                <Input
                    fontSize="sm"
                    variant="flushed"
                    placeholder={placeholder || (minChar && `Enter ${minChar} characters to start filtering...`) || "Search..."}
                    onChange={onChange}
                />
            </InputGroup>
            <Filter filter={filter} setFilter={setFilter} />
        </HStack>
    )
}

export const Filter = ({ filter, setFilter, keys }) => {
    if (isEmpty(keys)) return null;
    return (
        <Flex>
            <Menu autoSelect={false} placement="bottom-end">
                <MenuButton as={Button} variant="ghost" size="sm" textTransform="capitalize" rightIcon={<ChevronDownIcon />}>
                    {filter}
                </MenuButton>
                <MenuList minW="1xs" fontSize="sm">
                    {keys.map(key => {
                        return (
                            <MenuItem
                                key={key}
                                icon={filter === key ? <CheckIcon /> : null}
                                textTransform="capitalize"
                                onClick={() => { setFilter(key); }}
                            >
                                {key}
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>
        </Flex>
    )
}