import { Avatar, Circle, HStack, Icon, Skeleton, Stack, Text, Tooltip, VStack, DarkMode } from "@chakra-ui/react"
import { useRef } from "react"
import { BiUser } from "react-icons/bi"
import { useGetAccountId, useGetAuth, useGetFirebaseFromState, useGetOrganization, useGetOrganizations, useGetPhotoURL, useGetUser, useInOrganization, useIsTextOverflown } from "../../../../hooks"
import { ArrowUpDownIcon } from "@chakra-ui/icons"
import { AiOutlineCheck } from "react-icons/ai"
import { getUser, getUsersFullName } from "../../../../helpers"

export const AccountItem = ({ isCurrent, inSideMenu, inNav, account, shortPlanId, usersCount }) => {
    const user = useGetUser()
    const auth = useGetAuth()
    const { isLoaded } = useGetFirebaseFromState().profile
    const name = getAccountName({auth, isLoaded, isCurrent, inNav, account })
    const photoURL = useGetPhotoURL()
    const nameElementRef = useRef(null)
    const isNameOverflown = useIsTextOverflown(nameElementRef)
    const accountId = useGetAccountId()
    const organizations = useGetOrganizations()
    const organization = useGetOrganization()
    const inOrganization = useInOrganization()
    const AVATAR_SIZE = inNav ? "sm" : "smd"

    /*** loading ***/
    const isSwitchingAccount = auth?.switching?.account
    const isOrganizationLoaded = account?.id && ((!inOrganization && organizations?.loaded) || (inOrganization && organization?.id))
    const isLoading = (isCurrent && (isSwitchingAccount || !isOrganizationLoaded))
    // only show check next to current account in menu after everything has loaded
    const showCheck = inNav && (accountId === account?.id) && !isLoading
    /*** loading ***/

    const isUserAccount = user?.id === account?.id

    return (
        <DarkMode>
            <Stack direction="row" align="center" spacing={3} justifyContent="space-between" w="full">
                <Stack direction="row" align="center" spacing={3} w="full">
                    <Skeleton rounded="full" isLoaded={!isLoading}>
                        {/*** issues with org avatars not loading unless loaded user / org separately like this ***/}
                        {!isUserAccount && (
                            <Avatar
                                size={AVATAR_SIZE}
                                objectFit="cover"
                                name={name}
                            />
                        )}
                        {isUserAccount && (
                            <Avatar
                                size={AVATAR_SIZE}
                                objectFit="cover"
                                name={name}
                                src={photoURL}
                            />
                        )}
                    </Skeleton>
                    <Tooltip offset={[-5, 10]} isDisabled={!isNameOverflown} variant="rounded-sm" label={name} placement="bottom-start" openDelay={1000}>
                        <VStack textAlign="start" align="start" spacing={0}>
                            {isLoading && <Skeleton mt={1} mb={0.5} width="120px" height="15px" />}
                            {!inNav && isLoading && <Skeleton mt={0.5} mb={1} width="120px" height="12px" />}
                            {!isLoading && (
                                <Text color={inSideMenu ? "fg.default" : "auto"} noOfLines={1} fontWeight={inNav ? "normal" : "medium"} ref={nameElementRef}>{name}</Text>
                            )}
                            {!inNav && !isLoading && (
                                <HStack color="fg.default" spacing={1.5}>
                                    <Text fontSize="xs" textTransform="capitalize">{shortPlanId}</Text>
                                    <Circle size="3px" bgColor="fg.subtle" />
                                    <HStack spacing={1}>
                                        <BiUser fontSize="12px" />
                                        <Text fontSize="xs">{usersCount}</Text>
                                    </HStack>
                                </HStack>
                            )}
                        </VStack>
                    </Tooltip>
                </Stack>
                {showCheck && <Icon as={AiOutlineCheck} fontSize="lg" />}
                {!inNav && isCurrent && <ArrowUpDownIcon color="fg.muted" />}
            </Stack>
        </DarkMode>

    )
}

const getAccountName = ({ auth, isLoaded, isCurrent, inNav, account }) => {
    const user = getUser()
    if (isCurrent && !inNav && !isLoaded) return;
    if (isCurrent && auth.switching.account) return;
    if (account?.type === 'organization') return account?.name;
    if (account?.type === 'user') return getUsersFullName(user)
    return;
}