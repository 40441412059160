import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
    Button,
    IconButton,
    Tooltip,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    useColorModeValue as mode,
} from "@chakra-ui/react";

import { DeleteIcon } from '@chakra-ui/icons';
import { addWebsiteSectionChange, mergeWebsiteSectionsEdits, toggleWebsiteSectionOptionsPopover } from '../../../../../../../store/actions/sitemap-actions';
import { mapWebSectionsToArr } from '../index';
import { isEmpty} from 'lodash'

/*** popover ****/
export const Delete = ({ WebsiteSectionOptionsPopover, website_sections, sitemap, user }) => {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { root } = sitemap?.data;
    const { section } = WebsiteSectionOptionsPopover;
    const isWebsiteSectionEmpty = true;
    return (
        <>
            <IconButton size="sm" variant="ghost" icon={<DeleteIcon color="red.400" />} isDisabled={!isWebsiteSectionEmpty} onClick={() => {
                // website section has pages
                const sectionFromRoot = root.website_sections?.find(ws => ws.id === section.id);
                const hasPages = sectionFromRoot && !isEmpty(sectionFromRoot.children);
                if (hasPages) {
                    onOpen();
                } else {
                    handleDeleteWebsiteSection({ sectionId: section.id, website_sections, dispatch });
                }
            }} />
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Remove Pages
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {`All pages must be removed before we can delete "${section.title}" `}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onClose}>
                                Close
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
};

const handleDeleteWebsiteSection = ({ sectionId, website_sections, dispatch }) => {

    // map to arr
    const websiteSectionsArr = mapWebSectionsToArr(website_sections);
    // old indexes
    const oldSectionIndexesForHistory = {};
    websiteSectionsArr.forEach((s, i) => { oldSectionIndexesForHistory[s.id] = { index: i } });

    // needs to be inited BEFORE merging edits
    const section = { id: sectionId, ...website_sections[sectionId] };

    /*** merge page edits ***/
    website_sections[sectionId] = undefined;
    dispatch(mergeWebsiteSectionsEdits({ website_sections }));
    dispatch(toggleWebsiteSectionOptionsPopover({ showing: false }));
    /*** merge page edits ***/

    // map data for merge
    const newSectionIndexes = {};
    websiteSectionsArr.filter(s => s.id !== sectionId).forEach((s, i) => newSectionIndexes[s.id] = { index: i });

    // save
    var obj = [];
    var websiteSectionKeys = Object.keys(newSectionIndexes);
    websiteSectionKeys.forEach(sectionId => {
        obj.push({ action: 'website-section-reorder', id: sectionId, ...newSectionIndexes[sectionId] });
    });

    const action = 'website-section-remove';
    // new website section
    obj.push({ action, ...section });
    // save
    const change = {
        id: new Date().getTime(),
        data: obj,
    };
    const history = {
        action,
        data: { section, newSectionIndexes, oldSectionIndexes: oldSectionIndexesForHistory },
    };

    // add page change in redux
    dispatch(addWebsiteSectionChange({ change, history }));

};

/* export const sendDataLayerEventForPageSections = (action, label) => {
    const obj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Page Section: ${action}`, label } };
    window.dataLayer.push({
        event: 'generic_ga_event',
        ...obj
    });
}; */