import React from 'react'
import {
    IconButton,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import { transform } from '../../canvas/utils/zoom'
import { getPageColor, shouldShowCovers } from '../../canvas/utils/helpers'
import { getPalletteColors } from '../../../../../store/actions/editor-actions'

export const PageButton = React.forwardRef(({ children, ...rest }, ref) => {
    const { fontSize, icon, title, d, onClick} = rest
    const showCovers = shouldShowCovers(d)
    const color = getPageColor(d)
    const pallette = getPalletteColors(color)
    return (
        <IconButton
            ref={ref}
            {...rest}
            icon={icon}
            title={title}
            fontSize={`${fontSize}px`}
            variant="ghost"
            color={color}
            bgColor="rarchy-bg-sitemap-editor"
            _hover={{ bgColor: mode(pallette[0], "gray.700") }}
            onClick={onClick}
            h={`${(showCovers ? 35 : 25) * transform.k}px`}
            p={`${3 * transform.k}px`}
            minW={0}
            rounded={`${5 * transform.k}px`}
        >
            {children}
        </IconButton>
    )
})