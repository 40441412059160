import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
} from '@chakra-ui/react'
import { useState } from 'react'

export const Industry = ({onChange}) => {
    const [selectedOption, setSelectedOption] = useState({ label: 'Select the industry you work in...' })
    return (
        <Menu minW="full" matchWidth>
            <MenuButton as={Button} fontSize="sm" rightIcon={<ChevronDownIcon />} minW="full" fontWeight="normal" textAlign="left">
                {selectedOption?.label}
            </MenuButton>
            <MenuList>
                <MenuOptionGroup type='radio' value={selectedOption?.value}>
                    {data.map(option => {
                        return <MenuItemOption fontSize="sm" key={option.value} value={option.value} onClick={() => {
                            setSelectedOption(option)
                            onChange(option.value)
                        }}>{option.label}</MenuItemOption>
                    })}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    )
}

const data = [
    {
        label: "Agency / Consulting", value: "agency"
    },
    {
        label: "Ecommerce", value: "ecommerce"
    },
    {
        label: "Education", value: "education",
    },
    {
        label: "Financial Services", value: "financial-services"
    },
    {
        label: "Healthcare", value: "healthcare",
    },
    {
        label: "Non - profit", value: "non-profit"
    },
    {
        label: "Software / Technology", value: "software-technology"
    },
    {
        label: "Telecommunications", value: "telecommunications"
    },
    {
        label: "Other", value: "other"
    }
]