import { compact } from 'lodash';

export const setStarred = ({ id, isStarred, type }) => {
    // type will always be folder (for now anyway)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const state = getState();
        const { user } = state;
        const arr = user?.starred?.[type]
        const starred = {
            ...user?.starred,
            [type]: compact(isStarred ? arr?.concat(id) : arr.filter(a => a !== id))
        };
        dispatch({
            type: 'SET_STARRED',
            meta: { starred },
            async payload() {
                const firestore = getFirestore();
                const { arrayUnion, arrayRemove } = firestore.FieldValue;
                await firestore.doc(`users/${user.id}`).update({ [`starred.${type}`]: isStarred ? arrayUnion(id) : arrayRemove(id) });
            }
        })
    }
}

export const setPhotoURL = photoURL => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SET_PHOTO_URL',
            photoURL: photoURL,
        });
        // amplitude tracking
        // amplitude.getInstance().logEvent('CHANGED_USER_PHOTO');
    };
};