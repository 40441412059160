import {
    Box,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger
} from "@chakra-ui/react"
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserFlowsConnectorOptions } from './Connector';
import { UserFlowsSymbolOptions } from './Symbol';
import { useGetUserFlow } from '../../../../hooks';

export const UserFlowsOptionsPopover = () => {
    const flow = useGetUserFlow()
    const { OptionsPopover } = flow.ui;
    if (!OptionsPopover.showing) return null;
    const { symbolOptions, connectorOptions } = OptionsPopover;
    return (
        <Popover
            isOpen
            placement="top"
            offset={OptionsPopover.offset}
        >
            <PopoverTrigger><Box display="none" /></PopoverTrigger>
            <PopoverContent w="full">
                <PopoverBody p={1}>
                    <HStack spacing={1}>
                        {connectorOptions && (<UserFlowsConnectorOptions OptionsPopover={OptionsPopover} />)}
                        {symbolOptions && (<UserFlowsSymbolOptions OptionsPopover={OptionsPopover} />)}
                    </HStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}