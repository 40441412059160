import { Box, UnorderedList, VStack } from "@chakra-ui/react"
import { useInProPlan } from "../../../hooks"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { CollaborateWithOthersListItem, ScreenshotsPageColorsAndMoreListItem, UnlimitedFilesListItem, UnlimitedPagesPlusImportListItem } from "../Components/Messaging/ListItems"

export const Default = () => {
    const canStartNewTrial = useCanStartNewTrial()
    const userIsInProPlan = useInProPlan()
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                <>
                    {`Unlock premium features to supercharge your sitemaps and user flows. `}
                    {`${canStartNewTrial ? `Try for free for ${TRIAL_DAYS} days.` : 'Upgrade your plan today.'}`}
                </>
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                {!userIsInProPlan && <UnlimitedFilesListItem />}
                <UnlimitedPagesPlusImportListItem />
                <CollaborateWithOthersListItem />
                <ScreenshotsPageColorsAndMoreListItem />
            </UnorderedList>
        </VStack>
    )
}