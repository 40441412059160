import React from 'react';
import {
    Button
} from "@chakra-ui/react"
import { useDispatch } from 'react-redux';
import { transform } from '../../canvas/utils/zoom';
import { sectionWidth } from '../../canvas/components/page-sections';
import { insertNewPageSection } from '../../canvas/utils/page-sections/new';
import { getPageColor, getPageId, shouldShowCovers } from '../../canvas/utils/helpers';
import { togglePageDrawer } from '../../../../../store/actions/sitemap-actions';

import { useCanEditFile, useGetSitemap, useGetUserFlow } from '../../../../../hooks';
import { showEditorPermissionsToast } from '../../../../Editor/App/Components/PermissionsToast';
import { isEmpty } from 'lodash'

export const NewPageSection = ({ inUserFlow, d, x, y }) => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const showCovers = shouldShowCovers(d)

    const flow = useGetUserFlow()

    const canEditFile = useCanEditFile()
    if (!canEditFile) return null

    const { NewConnectorLine } = flow?.utils;
    if (NewConnectorLine.showing) return null;

    if (showCovers) return null;

    const nodeId = getPageId(d);
    const sectionData = !inUserFlow ? (sitemap?.data.page_sections?.[nodeId] || []) : !isEmpty(d.sections) ? d.sections : [];

    if (!isEmpty(sectionData)) return null;

    return (
        <Button
            rounded="4px"
            variant="ghost"
            _hover={{ textDecoration: "underline" }}
            _active={{ bgColor: "transparent" }}
            w={`${(sectionWidth - 36) * transform.k}px`}
            h={`${31 * transform.k}px`}
            pos="absolute"
            left={x + (39 * transform.k)}
            top={y + ((d.nodeHeight - d.pageSectionsHeight + 9) * transform.k)}
            onClick={(e) => {
                if (!inUserFlow || (inUserFlow && d.page)) {
                    // can edit
                    const canEdit = showEditorPermissionsToast({ doc: sitemap }); if (!canEdit) return;
                    // continue
                    insertNewPageSection({ nodeId, index: 0 })
                } else {
                    dispatch(togglePageDrawer({ node: d }))
                }
            }}
            fontWeight="semibold"
            color={getPageColor(d)}
            fontSize={`${14 * transform.k}px`}
        >
            {!inUserFlow || (inUserFlow && d.page) ? "+ Add Page Section" : "+ Set Linked Page"}
        </Button>
    )
}