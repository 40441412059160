import { Button } from "@chakra-ui/react"
import { FiDownloadCloud } from "react-icons/fi"
import { useCanFullyEditFile } from "../../../../../hooks"

export const Export = () => {
    const canFullyEdit = useCanFullyEditFile()
    if (!canFullyEdit) return null
    return (
        <Button size="md" py={4} leftIcon={<FiDownloadCloud />} iconSpacing={3} variant="ghost">Export</Button>
    )
}