import { ArrowBackIcon, ArrowDownIcon, ArrowForwardIcon, ArrowUpIcon } from "@chakra-ui/icons"
import {
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    useColorModeValue as mode
} from "@chakra-ui/react"
import React, { useLayoutEffect, useState } from "react"
import { getConnectorX, getConnectorY } from "../../../../connectors/helpers";
import { showingNewConnectorLine, toggleUserFlowSymbolButtons } from "../../../../../../../../store/actions/flow-actions";
import { useDispatch, useSelector } from 'react-redux';
import { useGetEditor, useGetUserFlow } from "../../../../../../../../hooks";

import { addConnectorChange } from "../../../../../helpers";
import { applyYWithCavasOffset } from "../../../../../../app/canvas/utils/helpers";
import { togglePageButtons } from "../../../../../../../../store/actions/sitemap-actions";
import { transform } from "../../../../../../app/canvas/utils/zoom";

export const NewConnectorLineButton = ({ d, startPos, section }) => {
    
    const dispatch = useDispatch()
    const editor = useGetEditor()
    const flow = useGetUserFlow()

    const [mouseover, setMouseover] = useState(false)
    const [transparent, setTransparent] = useState(false)

    useLayoutEffect(() => { return () => { if (mouseover) setMouseover(false); if (transparent) setTransparent(false) } }, [mouseover, transparent])
    const BUTTON_WIDTH = !section ? 22 : 18;
    var targetButtonWidth = Math.ceil((!mouseover ? BUTTON_WIDTH : (BUTTON_WIDTH * 1.1)) * transform.k)
    var maxButtonWidth = !mouseover ? (BUTTON_WIDTH / 3) : ((BUTTON_WIDTH / 3) * 1.2);
    targetButtonWidth = targetButtonWidth < maxButtonWidth ? maxButtonWidth : targetButtonWidth // ensure this is never under 7
    const buttonWidth = (targetButtonWidth % 2 == 0) ? targetButtonWidth : targetButtonWidth + 1

    const { NewConnectorLine } = flow?.utils;

    const left = getLeft({ section, d, startPos, buttonWidth }), top = getTop({ section, d, startPos, buttonWidth });

    return (
        <>
            <Popover placement="top">
                <PopoverTrigger>
                    <IconButton
                        h={`${buttonWidth}px`}
                        minW={`${buttonWidth}px`}
                        left={left}
                        top={top}
                        bgColor={editor?.pallette?.header}
                        icon={IconByPosition(startPos, NewConnectorLine)}
                        color="rarchy-bg-sitemap-editor"
                        rounded="50%"
                        pos="absolute"
                        paddingInlineStart={0}
                        paddingInlineEnd={0}
                        draggable
                        _hover={{ bgColor: transparent ? 'transparent' : editor?.pallette?.header, color: transparent ? 'transparent' : 'rarchy-bg-sitemap-editor' }}
                        onDragStart={(e) => {
                            dispatch(showingNewConnectorLine({ showing: true, node: d, section, startPos }))
                            dispatch(togglePageButtons({ showing: false }))
                            dispatch(toggleUserFlowSymbolButtons({ showing: false }))
                            e.preventDefault();
                        }}
                        onMouseEnter={() => {
                            setMouseover(true)
                            if (NewConnectorLine.showing) {
                                // dock new connector line
                                dispatch(showingNewConnectorLine({ ...NewConnectorLine, docked: { node: d, section, endPos: startPos } }))
                                setTransparent(true)
                            }
                        }}
                        onMouseLeave={() => {
                            setMouseover(false)
                            if (NewConnectorLine.showing) {
                                // undock new connector line
                                dispatch(showingNewConnectorLine({ ...NewConnectorLine, docked: undefined }))
                                setTransparent(false)
                            }
                        }}
                        onMouseUp={() => {
                            handleAddNewConnector({ dispatch, NewConnectorLine, flow })
                        }}
                    />
                </PopoverTrigger>
                <PopoverContent w="fit-content" rounded="md" borderWidth={0}> 
                    <PopoverArrow bg={editor?.pallette?.header} />
                    <PopoverBody py={1} rounded="md" fontSize="sm" align="center" color={mode("white", "gray.800")} bg={editor?.pallette?.header}>Drag from here to another element</PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}

const LEFT_TOP_OFFSET = 7.5;

const getLeft = ({ section, d, startPos, buttonWidth }) => {
    if (section) return transform.applyX(section.x + (startPos === "left" ? -5 : 195)) - buttonWidth / 2
    const offset = startPos === "left" ? -LEFT_TOP_OFFSET : startPos === "right" ? LEFT_TOP_OFFSET : 0;
    return transform.applyX(getConnectorX(d, null, startPos) + offset) - buttonWidth / 2
}

const getTop = ({ section, d, startPos, buttonWidth }) => {
    if (section) return applyYWithCavasOffset(section.y + (section.rectHeight / 2)) - buttonWidth / 2
    const offset = startPos === "top" ? -LEFT_TOP_OFFSET : startPos === "bottom" ? LEFT_TOP_OFFSET : 0;
    return applyYWithCavasOffset(getConnectorY(d, null, startPos) + offset) - buttonWidth / 2
}

const IconByPosition = (startPos, NewConnectorLine) => {
    const FONT_SIZE = `${transform.k * 15}px`;
    const orientation = `rotate(${NewConnectorLine.showing ? 180 : 0}deg)`
    if (startPos === 'top') return <ArrowUpIcon transform={orientation} fontSize={FONT_SIZE} />
    if (startPos === 'bottom') return <ArrowDownIcon transform={orientation} fontSize={FONT_SIZE} />
    if (startPos === 'left') return <ArrowBackIcon transform={orientation} fontSize={FONT_SIZE} />
    if (startPos === 'right') return <ArrowForwardIcon transform={orientation} fontSize={FONT_SIZE} />
}

const handleAddNewConnector = ({ dispatch, NewConnectorLine, flow }) => {

    if (!NewConnectorLine.showing) return;
    // get line type
    const lineType = localStorage.getItem(`userFlowConnectorType`);
    //
    const link = { start: { id: NewConnectorLine?.node?.id, position: NewConnectorLine?.startPos }, end: { id: NewConnectorLine?.docked?.node?.id, position: NewConnectorLine?.docked?.endPos }, type: lineType }
    // section links
    if (NewConnectorLine.section) link.start.section = NewConnectorLine?.section?.id;
    if (NewConnectorLine.docked.section) link.end.section = NewConnectorLine?.docked?.section?.id;
    // create line data
    const lineData = { [link.end.id]: { position: link.end.position } };
    if (link.start.section) lineData[link.end.id].startSection = link.start.section;
    if (link.end.section) lineData[link.end.id].endSection = link.end.section;
    // 
    if (lineType && lineType !== 'solid') lineData[link.end.id].type = lineType;
    /*** save ***/

    // no longer show new connector line
    if (NewConnectorLine.showing) dispatch(showingNewConnectorLine({ showing: false }))
    // no longer show symbol buttons
    if (flow.ui.SymbolButtons.showing) dispatch(toggleUserFlowSymbolButtons({ showing: false }))

    /*** save ***/
    const data = { [link.start.id]: { connectors: { [link.start.position]: lineData } } };
    const oldData = { [link.start.id]: { connectors: { [link.start.position]: { [link.end.id]: undefined } } } }
    addConnectorChange({ data, oldData })
    /*** save ***/

}