import { Button, FormControl, Input, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, UnorderedList } from "@chakra-ui/react"

import { useGetSubscription, useIsOrganizationAdmin } from "../../../../../hooks"
import { SectionHeader } from "../../../Components"

import dayjs from 'dayjs';
import { Formik } from "formik";
import { useFirebase } from "react-redux-firebase";
import { wait } from "../../../../../../shared/helpers";

const FONT_SIZE = "sm"
const MB = 3

export const DeleteModal = ({ organization, isOpen, onClose }) => {
    const firebase = useFirebase()
    const subscription = useGetSubscription()
    const canEdit = useIsOrganizationAdmin()
    const { customer_id, id: subscription_id } = subscription || {}
    const nextBillingPeriod = dayjs.unix(subscription?.current_period_end).format('ll')
    return (
        <Modal isOpen={isOpen} size="xl" isCentered onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody pb={9}>
                    <ModalHeader px={3}>
                        {`Confirm deletion of ${organization?.name}?`}
                    </ModalHeader>
                    <Stack mt={3} px={3} spacing={3}>
                        <Stack>
                            <SectionHeader title="What happens next" />
                            <UnorderedList mt={3} px={2}>
                                <ListItem fontSize={FONT_SIZE} mb={MB}>{`You'll be directed to our billing platform to cancel your subscription.`}</ListItem>
                                <ListItem fontSize={FONT_SIZE} mb={MB}>{`This organization account will remain active until ${nextBillingPeriod}.`}</ListItem>
                                <ListItem fontSize={FONT_SIZE} mb={MB}>{`On ${nextBillingPeriod}, we’ll archive all sitemaps, user flows, and other content created in this organization.`}</ListItem>
                                <ListItem fontSize={FONT_SIZE} mb={MB}>{`You can stop the deletion by pressing 'Renew plan' at any time before ${nextBillingPeriod}.`}</ListItem>
                                <ListItem fontSize={FONT_SIZE} mb={MB}>{`After ${nextBillingPeriod}, you can restore your organization with a new subscription by selecting your account under 'Manage accounts'.`}</ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack>
                            <SectionHeader title="How to save your data" />
                            <UnorderedList mt={3} px={3}>
                                <ListItem fontSize={FONT_SIZE} mb={MB}>{`Export your sitemaps / user flows before ${nextBillingPeriod}.`}</ListItem>
                            </UnorderedList>
                        </Stack>
                        <Stack mt={2}>
                            <SectionHeader title="Enter the organization name to confirm deletion" />
                            <Formik
                                validateOnMount
                                initialValues={{ name: '' }}
                                validate={(values) => {
                                    const errors = {};
                                    if (values?.name !== organization?.name) errors.name = 'error'
                                    return errors;
                                }}
                                onSubmit={async () => {
                                    try {
                                        const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')({
                                            customerId: customer_id,
                                            flow_data: {
                                                type: "subscription_cancel",
                                                subscription_cancel: {
                                                    subscription: subscription_id
                                                }
                                            },
                                            return_url: window.location.href
                                        })
                                        if (sessionURL?.data) window.location = sessionURL.data;
                                        await wait(1000)
                                    } catch (e) {
                                        console.error(e);
                                        // fallback - open billing portal
                                        const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')({ customerId: customer_id })
                                        if (sessionURL?.data) window.location = sessionURL.data;
                                        await wait(1000)
                                    }
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isValid,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Stack mt={2} spacing={4}>
                                            <FormControl id="name" isRequired>
                                                <Input name="name" fontSize="sm" placeholder="Enter organization name" onChange={handleChange} />
                                            </FormControl>
                                            <Stack direction="row" justify="end">
                                                <Button fontSize="sm" onClick={onClose}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    fontSize="sm"
                                                    colorScheme="red"
                                                    isLoading={isSubmitting}
                                                    isDisabled={!isValid}
                                                    loadingText="Redirecting..."
                                                    onClick={handleSubmit}
                                                >
                                                    Delete account
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </form>
                                )}
                            </Formik>
                        </Stack>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}