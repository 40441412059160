import { ArrowBackIcon, ArrowForwardIcon, CheckCircleIcon, TimeIcon, WarningIcon } from "@chakra-ui/icons"
import { Box, Button, Circle, Divider, Flex, HStack, Icon, IconButton, Link, Spinner, Stack, Text, Tooltip } from "@chakra-ui/react"
import { getTypeLabel } from "../../../New/Import/Detail"
import { BsQuestionCircle } from "react-icons/bs"
import { toggleNewSitemapModal } from "../../../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useGetImportingSitemap, useGetNewSitemapModal, useGetSitemapImportHistory, useHasProcessedSitemapImport, usePrevious } from "../../../../../hooks"

import { UnderlineLink } from "../../../../Auth/SignupLogin/Components/UnderlineLink"
import { useEffect } from "react"
import { getImportedSitemapPages, mergeImportedSitemapById } from "../../../../../store/actions/sitemaps-actions"
import { UpgradePopover } from "./UpgradePopover"
import { useIsSitemapImportCrawl, useIsSitemapImportIncomplete, useIsSitemapImportPausedByUser } from "../../../New/Import/helpers"
import { AiFillPauseCircle } from "react-icons/ai"

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { getImportingWebsiteLabel } from "../../../../../helpers"

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

export const ImportStatus = ({ inBanner }) => {

    const dispatch = useDispatch()
    // new sitemap modal
    const NewSitemapModal = useGetNewSitemapModal()
    const { fromHistory, detailId } = NewSitemapModal
    // importing
    const importing = useGetImportingSitemap()
    // imported
    const imported = useGetSitemapImportHistory()?.byId?.[detailId]
    const processed = useHasProcessedSitemapImport()
    // is paused
    const isPausedByUser = useIsSitemapImportPausedByUser(imported)
    // has processed
    const hasProcessed = (imported || processed)
    // data to use
    const data = imported || importing
    // is crawl
    const isCrawl = useIsSitemapImportCrawl(data)
    // circle
    const circle = <Circle mx={3} size="3px" bgColor="fg.subtle" />
    // created
    const created = `${dayjs(data?.completedAt?.toDate()?.toString())?.locale('en-long')?.fromNow()}`
    // fetched messaging
    const { count } = data?.retrieved || {};
    const finishedEarly = useIsSitemapImportIncomplete(data)
    const isErrored = data?.error;
    const fetchedMessaging = (!count && !isErrored) ? `Starting ${isCrawl ? "crawl" : "import"}...` : `${isCrawl ? "Fetched" : "Imported"} ${count?.toLocaleString()} pages${importing ? '...' : ''}`
    // website label
    const websiteLabel = getImportingWebsiteLabel(data)
    // processing has just completed
    CopyProcessingToProcessed({ importing, imported, processed })
    // render
    return (
        <Stack direction="row" align={fromHistory ? "center" : "center"} spacing={6} pl={2}>
            {!inBanner && (
                <>
                    <IconButton
                        variant="ghost"
                        icon={<ArrowBackIcon fontSize="3xl" />}
                        onClick={() => {
                            dispatch(toggleNewSitemapModal({ showing: true, createType: 'history' }))
                        }}
                    />
                    <Divider orientation="vertical" h="60px" opacity={1} />
                </>
            )}
            <>
                {hasProcessed && <Icon as={isErrored ? WarningIcon : isPausedByUser ? AiFillPauseCircle : CheckCircleIcon} color={isPausedByUser || finishedEarly ? 'fg.subtle' : `rarchy-${isErrored ? "red" : "green"}`} fontSize={inBanner ? "2xl" : isPausedByUser ? "5xl" : "4xl"} />}
                {!hasProcessed && <Spinner color="fg.muted" size="md" speed="0.7s" />}
            </>
            <Stack spacing={inBanner ? 0 : 3}>
                <Text
                    fontWeight={inBanner ? "medium" : "semibold"}
                    fontSize={inBanner ? "md" : "2xl"}
                >
                    {hasProcessed ? (isCrawl ? `${`${isErrored ? "Error crawling" : isPausedByUser ? "Paused crawl of" : "Crawled"} `}` : `${`${isErrored ? "Error importing from" : "Imported from "} `}`) : ""}
                    {!hasProcessed ? (isCrawl ? `Crawling ` : `Importing from `) : ""}
                    <Link isExternal href={withHttp(websiteLabel)} _hover={{ color: 'rarchy-link', textDecor: "underline" }}>{websiteLabel}</Link>
                </Text>
                <Flex align="center" fontSize="md" color="fg.muted" mb={-.5}>
                    {!inBanner && (
                        <>
                            {getTypeLabel(data?.type)}
                            {circle}
                        </>
                    )}
                    <UpgradePopover
                        finishedEarly={finishedEarly}
                        data={data}
                        isCrawl={isCrawl}
                        button={<Button
                            fontWeight="normal"
                            variant="link"
                            color="fg.muted"
                            _hover={{ cursor: finishedEarly ? "pointer" : "text" }}
                            fontSize={inBanner ? "sm" : "md"}
                            rightIcon={finishedEarly ? <Icon as={BsQuestionCircle} fontSize="sm" mt={0.5} /> : null}
                            textDecor={finishedEarly ? "underline" : "unset"}
                            textDecorationColor="rarchy-border-400"
                            textUnderlineOffset={7}
                        >
                            {fetchedMessaging}
                        </Button>} />
                    {/* inBanner && !hasProcessed && (
                        <>
                            {circle}
                            <Text fontSize={inBanner ? "sm" : "md"}>Estimated 2 hours remaining</Text>
                        </>
                    ) */}
                    {!inBanner && hasProcessed && (
                        <>
                            {circle}
                            <Tooltip
                                hasArrow
                                placement="bottom"
                                openDelay={500}
                                variant="rounded-sm"
                                label={`${isPausedByUser ? "Paused" : "Completed"} ${dayjs(data?.createdAt?.toDate()?.toString()).format('lll')}`}
                            >
                                <HStack>
                                    <TimeIcon fontSize="sm" /><Box>{created}</Box>
                                </HStack>
                            </Tooltip>
                        </>
                    )}
                    {inBanner && (
                        <>
                            {circle}
                            <UnderlineLink
                                // onMouseEnter={() => setIsMouseoverLink(true)}
                                // onMouseLeave={() => setIsMouseoverLink(false)}
                                onClick={() => {
                                    dispatch(toggleNewSitemapModal({ showing: true, showDetailScreen: true, detailId: importing?.id }))
                                }}>
                                <Stack direction="row" align="center">
                                    <Text fontSize="15px" fontWeight="semibold">
                                        {`View ${hasProcessed ? isCrawl ? "crawl" : "import" : "progress"}`}
                                    </Text>
                                    <ArrowForwardIcon fontSize="15px" />
                                </Stack>
                            </UnderlineLink>
                        </>
                    )}
                </Flex>
            </Stack>
        </Stack>
    )
}

// this is where we move processing to processed (can cause infinite loops)
const CopyProcessingToProcessed = ({ importing, imported, processed }) => {
    const dispatch = useDispatch()
    const prevProcessed = usePrevious(processed)
    const NewSitemapModal = useGetNewSitemapModal()
    useEffect(() => {
        async function run() {
            // just completed
            if ((processed && !prevProcessed)) {
                dispatch(mergeImportedSitemapById({ [importing.id]: { ...importing } }, importing, NewSitemapModal?.showDetailScreen))
                dispatch(getImportedSitemapPages({ detailId: [importing?.id] }))
            }
        }
        run()
    }, [importing, imported, processed, prevProcessed])
}

const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;