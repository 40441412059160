import { Box, Heading, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { useGetOrganization, useJustLoggedIn } from "../../../hooks";
import { useDispatch } from "react-redux";
import { switchAccount } from "../../../store/actions/auth-actions";
import { useHistory } from "react-router-dom";
import { addOrganization } from "../../../store/actions/organizations-actions";

// DIRECT USER TO SIGNUP WITH MESSAGE (SAME AS COLLABORATOR / USER) - handle redirection to organization account if successful there

export const JoinOrganization = ({ match }) => {

    const dispatch = useDispatch();

    const firebase = useFirebase();

    const justLoggedIn = useJustLoggedIn();

    const [error, setError] = useState(null);

    const { join_token } = match.params;

    const join = async () => await firebase.functions().httpsCallable('organizations-users-join-from-token')({ join_token });

    useEffect(() => {
        if (justLoggedIn) {
            (async function () {
                try {
                    const { status, organization } = (await join())?.data;
                    console.log(status, organization);
                    if (organization) {
                        // if (status === "joined") dispatch(addOrganization(organization));
                        // dispatch(switchAccount(organization?.id))
                    }
                } catch (e) {
                    const { message, details } = e;
                    setError(e);
                    console.log(message, details);
                }
            })();
        }
    })

    return (
        <Box as="section" maxH="full" overflowY="scroll">
            <VStack w="full" spacing={3} alignItems="flex-start">
                <Box px="16" pt={8} pb={12} maxWidth="6xl">
                    <Heading fontSize="2xl" as="h1" pb={8} mb={12} w="full" borderBottom="1px solid" borderColor="rarchy-border">
                        Join Organization
                    </Heading>
                    <Box w="lg">Hello</Box>
                    {error && (
                        <Box w="lg">{error?.message}</Box>
                    )}
                </Box>
            </VStack>
        </Box>
    )
}