import { Checkbox, FormControl } from "@chakra-ui/react"
import { useFormikContext } from "formik"

export const RespectRobots = () => {
    const { values, handleChange } = useFormikContext()
    return (
        <FormControl id="respectRobotsTxt">
            <Checkbox
                spacing={5}
                defaultChecked={values?.respectRobotsTxt === false ? false : true}
                onChange={handleChange}
            >
                Respect robots.txt
            </Checkbox>
        </FormControl>
    )
}