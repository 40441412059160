import { useDispatch } from "react-redux";
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, IconButton, Stack, Tooltip } from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useGetFilesData, useGetFolderIdFromPath, useGetFoldersByKeyOrPath, useGetFoldersData, useHasLoadedFiles, useIsFolderStarred } from "../../../../hooks"
import { BsStar, BsStarFill } from "react-icons/bs";
import { setStarred } from "../../../../store/actions/user-actions";
import { Sections } from "../../Components/Sections";
import { Topbar } from "./Topbar";
import { FolderName } from "./Name";
import { useHistory } from "react-router-dom"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper";

export const Folder = () => {

    const dispatch = useDispatch()

    const folderId = useGetFolderIdFromPath()
    const isStarred = useIsFolderStarred(folderId)
    const folder = (useGetFoldersByKeyOrPath({ key: 'id' }))?.[0]
    const hasLoadedFiles = useHasLoadedFiles()

    if (hasLoadedFiles && !folder?.name) return null

    return (
        <Box py={5}>
            <Box>
                <Topbar folder={folder} />
                <Stack direction="row" align="center" spacing={2} mt={2} mb={5}>
                    <Box mb={1.5}>
                        <SkeletonWrapper w="fit-content">
                            <FolderName folder={folder} />
                        </SkeletonWrapper>
                    </Box>
                    <SkeletonWrapper w="fit-content">
                        <Tooltip
                            label={`${isStarred ? "Uns" : "S"}tar folder`}
                            placement='right'
                            openDelay={1000}
                            variant="rounded"
                            fontSize="xs"
                            py={1.5}
                        >
                            <IconButton
                                icon={isStarred ? <BsStarFill fontSize="22px" /> : <BsStar fontSize="22px" />} bg="transparent" _hover={{ bg: 'transparent' }}
                                color={isStarred ? "rarchy-yellow" : "fg.subtle"}
                                mb={1.5}
                                onClick={() => {
                                    dispatch(setStarred({ id: folderId, isStarred: !isStarred, type: 'folders' }))
                                }}
                            />
                        </Tooltip>
                    </SkeletonWrapper>
                </Stack>
            </Box>
            <Sections />
        </Box>
    )
}

// used in both folder screen, and in search (should decouple if needing to add anywhere else)
// maybe should change opts to onlyFolders vs foldersAndFiles, rather than forSearch

export const Bread = ({ itemId, forSearch }) => {
    const history = useHistory()
    const folders = useGetFoldersData()
    const files = useGetFilesData()
    const folderIdFromPath = useGetFolderIdFromPath()
    const breadcrumbs = getBreadcrumbsArr({ itemId: itemId || folderIdFromPath, folders, files, forSearch })
    return (
        <SkeletonWrapper w="fit-content">
            <Breadcrumb size="sm" spacing={!forSearch ? 2 : 0.5} separator={<ChevronRightIcon fontSize={!forSearch ? `18px` : "xs"} color="rarchy-color-sitemap-editor" />} listProps={{ flexWrap: "wrap" }}>
                {breadcrumbs.map((f, i) => {
                    const isLast = i == breadcrumbs.length - 1;
                    // console.log(f?.type)
                    return (
                        <BreadcrumbItem key={i} color="fg.muted" fontSize={!forSearch ? "sm" : "xs"}>
                            {!isLast && (
                                <BreadcrumbLink
                                    as={Box}
                                    _hover={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        history.push(f?.link);
                                        e.preventDefault();
                                    }}
                                >
                                    {f?.name}
                                </BreadcrumbLink>
                            )}
                            {isLast && !forSearch && <>{f.name}</>}
                        </BreadcrumbItem>
                    )
                })}
            </Breadcrumb>
        </SkeletonWrapper>
    )
}

function getBreadcrumbsArr({ itemId, folders, files, forSearch }) {
    const arr = [];
    const recurse = (id) => {
        const folder = folders?.find(f => f.id === id)
        const file = !forSearch ? null : files?.find(f => f.id === id)
        const item = folder || file
        if (item) {
            arr.push({ ...item, type: item?.collection ? 'file' : 'folder' });
            if (folder) {
                recurse(folder.team);
            } else {
                if (forSearch) {
                    if (folders?.find(f => f.id === file?.team)) recurse(item?.team);
                }
            }
        };
    }
    recurse(itemId);
    return [{ name: 'Projects', link: `/app/projects` }, ...arr.reverse()];
}