import {
    Box,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuOptionGroup,
    Portal,
    Stack,
    Text
} from '@chakra-ui/react'
import { useCanCommentOnFile, useCanFullyEditFile, useInEditor, useInSitemap, useInTrash, useInUserEditorDoc, useIsUserFile } from '../../../../hooks'

import { CopyFile } from './Copy'
import { CopyLinkMenuItem } from '../../../Components/Shared/CopyLinkMenuItem'
import { DeleteFile } from './Delete'
import { FONT_SIZE } from '../../../Projects/Folders/Components/Options/Menu'
import { FileName } from '../Name'
import { FileShare } from './Share'
import { FolderFileIconButton } from '../../../Components/Shared/FolderFileIconButton'
import { MoveToFolderHOC } from '../../../Components/Shared/MoveToFolder/Components/MoveToFolderHOC'
import { MoveToFolderMenuItem } from '../../../Components/Shared/MoveToFolder/Components/MoveToFolderMenuItem'
import { MoveToFolderScreen } from '../../../Components/Shared/MoveToFolder'
import { RemoveFromFolderMenuItem } from '../../../Components/Shared/RemoveFromFolderMenuItem'
import { RestoreFile } from './Restore'
import { SitemapDomain } from '../Domain'
import { Subtext } from '../Subtext'
import { motionPropsZeroAnimation } from '../../../../helpers'
import { useState } from 'react'

export const FileMenu = ({ file, opts = {} }) => {
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const inTrash = useInTrash()
    const inEditor = useInEditor()
    const isUserFile = useIsUserFile(file)
    const userCanFullyEdit = useCanFullyEditFile(file)
    const canComment = useCanCommentOnFile(file)
    const inSitemap = useInSitemap()
    const isSitemap = inSitemap || file?.collection === 'sitemaps'
    const showSubtext = !inEditor && !inTrash
    const userCanComment = !inEditor || inEditor && canComment
    return (
        <Menu
            placement={!inEditor ? "right" : "bottom-start"}
            autoSelect={false}
            isLazy
            closeOnSelect={false}
            closeOnBlur={!showDeleteAlert}
        >
            {({ isOpen, onClose }) => (
                <>
                    <FolderFileIconButton as={MenuButton} opts={opts} />
                    {isOpen && (
                        <MoveToFolderHOC file={file} onClose={onClose}>
                            {({ showMoveToFolderScreen }) => (
                                <Portal>
                                    <MenuList w="300px" pb={showMoveToFolderScreen ? 0 : 2} boxShadow="xl" fontSize={FONT_SIZE} onClick={e => e.stopPropagation()} motionProps={motionPropsZeroAnimation}>
                                        {!showMoveToFolderScreen && (
                                            <>
                                                <Stack>
                                                    <MenuOptionGroup
                                                        as={Box}
                                                        title={
                                                            <Stack spacing={1.5} fontWeight="normal">
                                                                <Stack my={0} spacing={1}>
                                                                    <FileName file={file} opts={{ fontSize: "md", fontWeight: "semibold" }} />
                                                                    {isSitemap && <SitemapDomain file={file} />}
                                                                </Stack>
                                                                {showSubtext && <Subtext file={file} forMenu />}
                                                            </Stack>
                                                        }
                                                        type='radio'
                                                    >
                                                    </MenuOptionGroup>
                                                </Stack>
                                                <MenuDivider />
                                                {!inTrash && (
                                                    <>
                                                        {userCanFullyEdit && (
                                                            <>
                                                                <CopyFile file={file} onClose={onClose} />
                                                                {!isUserFile && <MoveToFolderMenuItem file={file} onClose={onClose} />}
                                                            </>
                                                        )}
                                                        {userCanComment && <FileShare file={file} inDropdown />}
                                                        <CopyLinkMenuItem file={file} onClose={onClose} />
                                                        {userCanFullyEdit && (
                                                            <>
                                                                <DeleteFile
                                                                    file={file}
                                                                    showDeleteAlert={showDeleteAlert}
                                                                    setShowDeleteAlert={setShowDeleteAlert}
                                                                    onClose={onClose}
                                                                />
                                                                <RemoveFromFolderMenuItem file={file} />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {inTrash && (
                                                    <>
                                                        <RestoreFile file={file} onClose={onClose} />
                                                        <DeleteFile
                                                            file={file}
                                                            showDeleteAlert={showDeleteAlert}
                                                            setShowDeleteAlert={setShowDeleteAlert}
                                                            onClose={onClose}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {showMoveToFolderScreen && <MoveToFolderScreen />}
                                    </MenuList>

                                </Portal>
                            )}
                        </MoveToFolderHOC>
                    )}
                </>
            )}
        </Menu>
    )
}