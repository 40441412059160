import * as React from 'react'

import {
    Box,
    Flex,
    Spacer,
    VStack,
    useMediaQuery,
} from "@chakra-ui/react"

import { useSelector } from 'react-redux';

import { Default } from './Default';
import { Footer } from './Footer'

import { Header } from './Header'
import { useGetUi, useInSettings } from '../../hooks'
import { SettingsMenu } from './Screens/Settings'
import { Left } from '../Components/Navbar/Sides/Left';
import { useIsPublicPage } from '../../PrivateRoute';


const SPACING = 1.5

export const SideMenu = () => {

    const ui = useGetUi()
    const inSettings = useInSettings()
    const isPublicPage = useIsPublicPage()
    const [isLessThan800] = useMediaQuery('(max-width: 800px)')

    if (isLessThan800) return null;
    if (ui?.sideMenu?.collapsed) return null;

    const shouldTakeover = inSettings ? true : false;

    return isPublicPage
        ? null
        : (
            <Box w="266px" minW="266px" h="100vh" fontSize="sm" py={3} overflowY={inSettings ? "scroll" : "hidden"} bgColor="rarchy-bg-dark" zIndex={1}>
                <Flex
                    w="full"
                    h="full"
                    direction="column"
                    borderColor="rarchy-border-sitemap-editor"
                    px="4"
                    py="0"
                >
                    <Box mt={0} mb={2} pl={1}><Left inSideMenu /></Box>
                    {shouldTakeover && (
                        <>
                            {inSettings && <SettingsMenu />}
                        </>
                    )}
                    {!shouldTakeover && (
                        <>
                            <Header />
                            <VStack mt={SPACING} spacing={SPACING} overflowY="scroll">
                                <Default />
                            </VStack>
                            <Spacer />
                            <Footer />
                        </>
                    )}
                </Flex>
            </Box>
        )
};