import {
    Box,
    Container,
    Divider,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack
} from "@chakra-ui/react"
import { useGetPageDrawer, useGetSitemap, useInUserFlow, useIsLinkedUserFlowPage } from '../../../../hooks';

import { Covers } from './Covers'
import { Files } from './Files';
import { Header } from './Header'
import { Pages } from './Pages';
import React from 'react';
import SEO from './SEO'
import { findIndex } from 'lodash'
import { getCanEditInEditor } from '../../../Editor/Navbar/helpers';

export const PageDrawer = ({ h }) => {
    const sitemap = useGetSitemap()
    const PageDrawer = useGetPageDrawer()
    const inUserFlow = useInUserFlow()
    const linkedPage = useIsLinkedUserFlowPage(PageDrawer.page)
    const canEditSitemap = getCanEditInEditor(sitemap)
    const tabs = getTabs({ inUserFlow, canEditSitemap, linkedPage });
    return (
        <Container
            maxW="unset"
            paddingInline={6}
            h={h}
        >
            <VStack spacing={1.5}>
                <Header PageDrawer={PageDrawer} />
                <Tabs size="sm" defaultIndex={findIndex(tabs, (t) => t.id === PageDrawer.defaultIndex)} w="full">
                    <TabList borderBottomWidth="1px">
                        {[...tabs.map(t => {
                            const tab = <Tab key={t.id} fontWeight="semibold" fontSize="0.9em" px={1.5} mr={t.mr} ml={t.ml}>{t.title}</Tab>
                            if (t.id === 'pages') {
                                return (
                                    <Flex key={t.id}>
                                        <Divider ml={4} orientation='vertical' h="20px" mt="5px" opacity={1} />
                                        {tab}
                                    </Flex>
                                )
                            }
                            return tab
                        })]}
                    </TabList>
                    <TabPanels>
                        {[...tabs.map(t => {
                            return (
                                <TabPanel key={t.id} px={0}>
                                    {t.id === 'covers' && <Covers />}
                                    {t.id === 'seo' && <SEO />}
                                    {t.id === 'files' && <Files />}
                                    {t.id === 'pages' && <Pages />}
                                </TabPanel>
                            )
                        })]}
                    </TabPanels>
                </Tabs>
            </VStack>
        </Container>
    )
}

const getTabs = ({ inUserFlow, canEditSitemap, linkedPage }) => {
    const arr = [];
    arr.push({ id: "covers", title: 'Covers' })
    if (!inUserFlow || (inUserFlow && linkedPage)) {
        arr.push({ id: "seo", title: 'SEO', ml: 4 })
        arr.push({ id: "files", title: 'Files', ml: 4 })
    }
    if (inUserFlow && canEditSitemap) arr.push({ id: "pages", title: 'Linked Pages', ml: 4 })
    return arr;
}