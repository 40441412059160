import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai"
import { Avatar, Box, Button, Circle, Icon, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup, Text, Tooltip, useColorModeValue as mode } from "@chakra-ui/react"
import { CheckIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons"
import { compact, map, uniq } from 'lodash'
import { getAvatarSrc, getPeopleData, getUsersFullName } from "../../../../../helpers"
import { useEffect, useMemo, useRef } from "react"
import { useGetItems, useGetLocation, useGetProjectsPathFilter, useGetProjectsSortFilter, useGetUser, usePrevious } from "../../../../../hooks"

import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"
import { changeProjectsOwner } from "../../../../../store/actions/projects-actions"
import { useDispatch } from "react-redux"

export const Owner = () => {

    const dispatch = useDispatch()

    const inputRef = useRef()
    const owner = useGetProjectsSortFilter('owner')

    const user = useGetUser()
    const items = useGetItems()
    const ownerIds = useMemo(() => compact(uniq(map(items, 'createdBy'))), [items])
    const people = useMemo(() => getPeopleData()?.filter(p => p?.id !== user?.id && ownerIds?.includes(p?.id), [ownerIds]))

    const options = getOptions({ user, people })
    const hasPathFilter = useGetProjectsPathFilter()
    const hasOwnFilter = hasPathFilter === 'your-projects'
    const hasSharedFilter = hasPathFilter === 'shared-with-you'

    UpdateOwnerBasedOnFilter({ owner, hasOwnFilter, hasSharedFilter })

    const selected = options.find(o => o.key === owner)
    const isDisabled = (hasOwnFilter || hasSharedFilter) ? true : false

    return (
        <Menu placement="bottom-start" autoSelect initialFocusRef={inputRef}>
            <SkeletonWrapper>
                <Tooltip label={selected?.title} variant="rounded-sm" placement="top" hasArrow openDelay={250}>
                    <MenuButton
                        as={Button}
                        isDisabled={isDisabled}
                        onFocus={e => e.preventDefault()}
                        rightIcon={<ChevronDownIcon fontSize="xl" />}
                        pl={3.5}
                        pr={2.5}
                        variant="outline"
                        fontSize="sm"
                        fontWeight="normal"
                        bgColor={isDisabled ? "rarchy-badge-icon" : "unset"}
                        _hover={{ bgColor: isDisabled ? "rarchy-badge-icon" : "rarchy-bg-white" }}
                        _expanded={{ borderColor: 'rarchy-blue', bgColor: "rarchy-bg-white" }}
                    >
                        {selected?.title}
                    </MenuButton>
                </Tooltip>
            </SkeletonWrapper>
            <MenuList fontSize="sm" w="325px">
                <MenuOptionGroup
                    as={Box}
                    pt={0}
                    mt={1}
                    pb={1}
                    title={
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<SearchIcon color="gray.400" />}
                            />
                            <Input
                                ref={inputRef}
                                fontSize="sm"
                                placeholder="Search people"
                                _placeholder={{ color: mode("gray.400", "whiteAlpha.700") }}
                            />
                        </InputGroup>
                    }
                    type='radio'
                >
                </MenuOptionGroup>
                <MenuDivider />
                <Box maxH="sm" overflow="scroll">
                    {options.map((o, i) => {
                        return (
                            <MenuItem
                                key={o.key || i}
                                py={2}
                                icon={
                                    !o.user ?
                                        <Circle
                                            w="40px"
                                            h="40px"
                                            bg="rarchy-bg-subtle-hover-200"
                                        >
                                            <Icon as={o.icon} fontSize={o.key === 'shared' ? "2xl" : "xl"} />
                                        </Circle> : <Avatar w="40px" h="40px" src={getAvatarSrc({ user: o.user })} />
                                }
                                command={owner === o.key ? <CheckIcon mr={2} fontSize="lg" /> : null}
                                onClick={() => {
                                    setTimeout(() => {
                                        dispatch(changeProjectsOwner(o.key))
                                    }, 100);
                                }}
                            >
                                {o.label}
                            </MenuItem>
                        )
                    })}
                </Box>
            </MenuList>
        </Menu>
    )
}

const UpdateOwnerBasedOnFilter = ({ owner, hasOwnFilter, hasSharedFilter }) => {
    const dispatch = useDispatch()
    const user = useGetUser()
    const { pathname } = useGetLocation()
    const prevPathname = usePrevious(pathname)
    useEffect(() => {
        let newOwner = undefined
        // shared
        if (hasSharedFilter && owner !== 'shared') newOwner = 'shared'
        // user
        if (hasOwnFilter && owner !== user?.id) newOwner = user?.id
        // back to projects
        if (pathname === "/app/projects" && prevPathname !== "/app/projects") {
            if (owner !== null) newOwner = null
        }
        // change in redux
        if (newOwner !== undefined) dispatch(changeProjectsOwner(newOwner))
    }, [hasOwnFilter, hasSharedFilter, owner])
}

const getOptions = ({ user, people }) => {
    const fullName = getUsersFullName(user)
    return [
        ...[
            { key: null, title: 'Owner', label: 'Any owner', icon: AiOutlineUser },
            { key: 'shared', title: 'Shared with you', label: 'Shared with you', icon: AiOutlineUsergroupAdd },
            { key: user.id, title: fullName, label: <Box noOfLines={2}>{fullName}<Text display="inline" ml={1} color="fg.subtle" fontSize="xs">(You)</Text></Box>, user }
        ],
        ...people?.map(p => {
            const name = getUsersFullName(p)
            return {
                key: p.id, title: name, label: <Box noOfLines={2}>{name}</Box>, user: p
            }
        })
    ]
}