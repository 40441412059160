import { Box } from "@chakra-ui/react"
import { useGetUpgradeModal, useInProPlan } from "../../../../../hooks"
import { Screenshots } from "./Screenshots"

export const ScreenshotsPageColorsAndMore = ({ forConfirmed, andMore }) => {
    const { planId } = useGetUpgradeModal()
    const inProPlan = useInProPlan(planId)
    return (
        <Screenshots forConfirmed={forConfirmed}>
            <Box display="inline" fontWeight="normal">
                {`, plus ${inProPlan ? 'individual page colors' : 'revision history'} `}
            </Box>
            <Box display="inline" fontWeight="normal">and much more</Box>
        </Screenshots>
    )
}