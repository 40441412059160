import { Box, Button, Stack, Text } from "@chakra-ui/react"
import { PayToday } from "./PayToday"
import { useFormikContext } from "formik"
import { useIsTrialEnabled } from "../../../../shared/hooks/pricing"
import { ArrowForwardIcon } from "@chakra-ui/icons"

export const GoToStripe = () => {
    const isTrialEnabled = useIsTrialEnabled()
    const { isValid, isSubmitting, handleSubmit } = useFormikContext()
    return (
        <Stack spacing={9}>
            <PayToday />
            <Stack w="full" spacing={4}>
                <Button
                    colorScheme="blue"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                    rightIcon={<ArrowForwardIcon />}
                    onClick={handleSubmit}
                >
                    {isTrialEnabled ? "Start your free trial" : "Continue to payment"}
                </Button>
                {isValid && (
                    <Text
                        fontSize="xs"
                        color="fg.muted"
                    >
                        {`We'll take you to our billing provider (Stripe), to securely input your card details and ${isTrialEnabled ? "start your free trial" : "confirm your account"}.`}
                    </Text>
                )}
            </Stack>
        </Stack>
    )
}