import { barWidth } from '../..';
import { dragging } from '../utils/drag';
import { getSitemap } from '../../../../../helpers';
import { isEmpty, map, cloneDeep } from 'lodash'

export const renderLinks = (context, links, opts = {}) => {

    const { forThumbnail } = opts
    const sitemap = getSitemap()

    const isTree = forThumbnail && sitemap?.format === 'tree';
    const x = isTree ? 'y' : 'x';
    const y = isTree ? 'x' : 'y'

    const forThumbnailNodeIds = map(forThumbnail, 'id');
    // Draw the links
    links.filter(l => { return !forThumbnail ? l : forThumbnailNodeIds.includes(l?.id) }).forEach((d) => {
        if (forThumbnail) {
            d = cloneDeep(d);
            const thumbnailNode = forThumbnail?.find(n => n.id === d.id)
            d[x] = thumbnailNode?.[x];
            d[y] = thumbnailNode?.[y];
        }
        if (d.parent && !d.parent.children) return; // don't render link if collapsed
        if (d.overflow && isEmpty(d.overflow)) return;
        if (dragging?.started) {
            if (d.id === dragging.id) return; // dragging
            if (d.parent.id === dragging.id) return; // dragging
            if (d.id === 'dragging-placeholder' || d.parent.id === 'dragging-placeholder') return; // dragging 
            if (d.hideWhileDragging) return;
        }
        var o;
        if (!forThumbnail && sitemap?.format === 'tree') {
            o = {
                x: d.x + 112.5 + (sitemap?.showCovers ? 58 / 2 : Math.round(((d.nodeHeight / 2) + 16.5))),
                y: d.y,
                parent: {
                    x: d.parent.x + 112.5 + (sitemap?.showCovers ? 58 / 2 : Math.round(((d.parent.nodeHeight / 2) + 16.5))),
                    y: d.parent.y + barWidth,
                },
            };
        }
        if (forThumbnail || sitemap?.format === 'tree-vertical') {
            o = {
                x: d[x] + barWidth / 2,
                y: d[y],
                parent: {
                    x: d.parent[x] + barWidth / 2,
                    y: d.parent[y] + (d.parent.nodeHeight)
                },
            };
        }
        if (!forThumbnail && sitemap?.format === 'tree-vertical-matrix') {
            o = {
                x: d.x + barWidth / 2,
                y: d.y,
                parent: {
                    x: d.parent.x + barWidth / 2,
                    y: d.parent.y + (d.parent.nodeHeight)
                },
            };
        }
        if (!forThumbnail && sitemap?.format === 'indent') {
            o = d;
        }
        drawLinks(context, o, d, { forThumbnail });
    });
}

const drawLinks = (context, d, node, opts = {}) => {
    const { forThumbnail } = opts;
    const sitemap = getSitemap();
    /*** draw lines ***/
    context.strokeStyle = sitemap?.pallette?.header;
    context.lineWidth = 1.75;// !sitemap?.showCovers ? 1.25 : 1.75;
    context.beginPath();
    if (!forThumbnail && sitemap?.format === 'tree') treeLinks(context, d, node);
    if (forThumbnail || sitemap?.format === 'tree-vertical') treeVerticalLinks(context, d, sitemap);
    if (!forThumbnail && sitemap?.format === 'tree-vertical-matrix') treeVerticalMatrixLinks(context, d, node, sitemap);
    if (!forThumbnail && sitemap?.format === 'indent') indentLinks(context, d, node);
    context.stroke();
    /*** draw lines ***/
}

const treeLinks = (context, d) => {
    const sitemap = getSitemap()
    var adjustX = sitemap?.showCovers ? -15.5 : -130.5;
    context.moveTo(d.y, d.x + adjustX);
    context.lineTo(d.y + (d.parent.y - d.y) / 2, d.x + adjustX);
    context.lineTo(d.y + (d.parent.y - d.y) / 2, d.parent.x + adjustX);
    context.lineTo(d.parent.y, d.parent.x + adjustX);
}

const treeVerticalLinks = (context, d) => {
    context.moveTo(d.x, d.y);
    context.lineTo(d.x, ((d.y + d.parent.y) / 2));
    context.lineTo(d.parent.x, ((d.y + d.parent.y) / 2));
    context.lineTo(d.parent.x, d.parent.y);
}

const treeVerticalMatrixLinks = (context, d, node, sitemap) => {
    if (node && node.depth > 1) {
        context.moveTo(d.x - 112.5, d.y + (sitemap?.showCovers ? 126 : 37));
        context.lineTo(d.x - 142.75, d.y + (sitemap?.showCovers ? 126 : 37));
        context.lineTo(d.x - 142.75, d.parent.y);
        return;
    };
    /*** top row ***/
    context.moveTo(d.x, d.y);
    context.lineTo(d.x, ((d.y + d.parent.y) / 2));
    context.lineTo(d.parent.x, ((d.y + d.parent.y) / 2));
    context.lineTo(d.parent.x, d.parent.y);
}

const indentLinks = (context, d) => {
    const sitemap = getSitemap()
    context.moveTo(d.y, d.x + (sitemap?.showCovers ? 58 : 58) / 2 + (sitemap?.showCovers ? -15.5 : (17 / 2)));
    context.lineTo(d.parent.y + ((sitemap?.showCovers ? 58 : 58) / 2) - 9, d.x + (sitemap?.showCovers ? 58 : 58) / 2 + (sitemap?.showCovers ? -15.5 : (17 / 2)));
    context.lineTo(d.parent.y + ((sitemap?.showCovers ? 58 : 58) / 2) - 9, d.parent.x + (sitemap?.showCovers ? 150 : 0));
}