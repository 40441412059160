import { Box, Flex, Stack, useColorMode, useColorModePreference } from "@chakra-ui/react"
import PrivateRoute, { useIsPublicPage } from '../PrivateRoute';
import React, { useLayoutEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { toggleNewSitemapModal, toggleUpgradeModal, updateColorMode } from '../store/actions/ui-actions';
import { useGetFiles, useGetFolders, useGetUi, useGetUserId, useHasLoadedFiles, useInEditor, useInHomeScreen, useIsLoggedIn, usePrevious } from "../hooks";

import { AddUsersModal } from './Users/Modal';
import { Banner } from "./Sitemaps/Import/Banner";
import { ConfirmInvitation } from './Auth/SignupLogin/Signup/ConfirmInvitation';
import { DeleteFolderModal } from './Projects/Folders/Components/Options/Handlers/Delete';
import { Editor } from './Editor';
import { FAQs } from "./Help/FAQs";
import { Folder } from "./Projects/Folders/Folder";
import { Home } from './Home';
import { JoinOrganization } from "./Auth/Join-Organization";
import { Login } from './Auth/SignupLogin/Login'
import { Navbar } from "./Components/Navbar";
import { NewFolderModalContainer } from "./Projects/Folders/Components/New";
import { NewSitemapModalContainer } from "./Sitemaps/New";
import { NewUserFlowModalContainer } from "./UserFlows/New";
import { OnboardingModalContainer } from "./Auth/Onboarding/"
import PricingWithTable from '../../marketing/src/-sections/Pricing/PricingWithTable';
import { Projects } from "./Projects";
import { ResetPassword } from './Auth/SignupLogin/login/reset';
import { Settings } from './Settings';
import { ShareFileModal } from './Files/Components/Share/Modal';
import { ShareFolderModal } from './Projects/Folders/Components/Share/Modal';
import { SideMenu } from './SideMenu/'
import { SignUpSegmentation } from "./Auth/SignupLogin/Signup/Segmentation";
/** router ***/
import { Signup } from './Auth/SignupLogin/Signup';
import { TemplateDrawer } from './Templates/Components/Drawer';
import { Templates } from './Templates';
import { Ticket } from "./Help/Ticket";
import { Trash } from "./Trash";
import { UpgradeModalContainer } from "./UpgradeAccount";
import { VerifyResetPassword } from './Auth/SignupLogin/Login/ResetVerified';
import queryString from 'query-string';
import { useDispatch } from 'react-redux'
import { useFirestoreConnect } from "react-redux-firebase"
import { useHistory } from 'react-router-dom'

/** router ***/




const App = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const ui = useGetUi()
    const folders = useGetFolders()
    const files = useGetFiles()
    const isPublicPage = useIsPublicPage()
    const loggedIn = useIsLoggedIn()
    /*** update color mode in redux ***/
    UpdateColorModeOnPreferenceChange()
    /*** update color mode in redux ***/
    // listen for importing sitemaps
    SetFirestoreListenerForImportingSitemap()
    // 
    const inEditor = useInEditor()
    const inHome = useInHomeScreen()
    return (
        <>
            <Route exact path="*" component={({ location }) => { if (!location?.pathname?.startsWith('/app')) return <Redirect to={{ ...location, pathname: `/app${location.pathname}` }} /> }} />
            <Box height="100vh" overflowY="hidden">
                <Stack direction="row" gap={0}>
                    <SideMenu />
                    <Stack w="full" spacing={0}>
                        {/* add here so they never get hidden on responsive */}
                        {/* ui?.EditSitemapModal?.showing && <EditSitemapModal /> */}
                        {ui?.NewSitemapModal?.showing && <NewSitemapModalContainer />}
                        {ui?.NewUserFlowModal?.showing && <NewUserFlowModalContainer />}
                        {ui?.OnboardingModal?.showing && <OnboardingModalContainer />}
                        {ui?.TemplateDrawer?.showing && <TemplateDrawer />}
                        {folders?.ui?.NewFolderModal?.showing && <NewFolderModalContainer />}
                        {folders?.ui?.ShareFolderModal?.showing && <ShareFolderModal />}
                        {folders?.ui?.DeleteFolderModal?.showing && <DeleteFolderModal />}
                        {files?.ui?.ShareFileModal?.showing && <ShareFileModal />}
                        {ui?.UpgradeModal?.showing && <UpgradeModalContainer />}
                        {ui?.AddUsersModal?.showing && <AddUsersModal />}
                        <Navbar />
                        <Switch>
                            <Route exact path="/app/flow/:flowId([0-9a-z]{20,28})" component={Editor} />
                            <Route exact path="/app/sitemap/:sitemapId([0-9a-z]{20,28})" component={Editor} />
                            <Redirect from="/s/:sitemapId([0-9a-z]{20,28})/flows/:flowId([0-9a-z]{20,28})" to="/app/flow/:flowId([0-9a-z]{20,28})" /> {/* has to be before sitemap redirect */}
                            <Redirect from="/s/:sitemapId([0-9a-z]{20,28})" to="/app/sitemap/:sitemapId([0-9a-z]{20,28})" />
                            {/* <Route exact path="/app/logout" render={() => { this.props.logOut(this.props.history); }} /> */}
                            {/* <Route path="/app/verify" component={Verify} /> */}
                        </Switch>
                        <Flex h={`calc(100vh - ${!isPublicPage ? 60 : 0}px)`}>
                            <Box overflow="scroll" w="full" flex="1" minW="xs" pos={inEditor ? "fixed" : "unset"} px={inHome || inEditor ? 0 : 6}>
                                {!isPublicPage && !inEditor && <Banner />}
                                <Box>
                                    <Switch>
                                        <Route exact path="/app/login" component={Login} />
                                        <Route exact path="/app/login/reset" component={ResetPassword} />
                                        <Route path="/app/verify" component={VerifyResetPassword} /> {/*** change to login/verify post-launch (kept the same to make it simpler) ***/}
                                        <Route exact path="/app/signup" component={Signup} />
                                        <Route exact path="/app/signup/2" component={SignUpSegmentation} />
                                        <Route path="/app/invitations" component={ConfirmInvitation} />
                                        {/* <Route exact path="/app/logout" render={() => { this.props.logOut(this.props.history); }} /> */}
                                        {/* <Route path="/app/verify" component={Verify} /> */}
                                    </Switch>
                                    <PrivateRoute inEditor={inEditor}>
                                        <Switch>
                                            <Route exact path="/app" component={Home} />
                                            <Route path="/app/projects/:hasFilter?" component={Projects} />
                                            <Route path="/app/folder/:folderId" component={Folder} />
                                            <Route exact path="/app/templates" component={Templates} />
                                            <Route exact path="/app/confirm-collaborator-invite" />
                                            <Route exact path="/app" component={Home} />
                                            <Route path="/app/upgrade-modal" render={({ location }) => {
                                                const { planId } = queryString.parse(location.search) || {}
                                                dispatch(toggleUpgradeModal({ showing: true, planId }))
                                                return <Redirect to="/app" />
                                            }} />
                                            <Route path="/app/projects/:hasFilter?" component={Projects} />
                                            <Route path="/app/folder/:folderId" component={Folder} />
                                            <Route exact path="/app/templates" component={Templates} />
                                            <Route exact path="/app/confirm-collaborator-invite"
                                                component={({ location }) => (<Redirect to={{ ...location, pathname: '/invitations' }} />)}
                                            />
                                            <Route exact path="/app/confirm-user-invite"
                                                component={({ location }) => (<Redirect to={{ ...location, pathname: '/invitations' }} />)}
                                            />
                                            <Route path="/app/joinorg/:join_token([0-9a-z]{20})" component={JoinOrganization} />
                                            <Route path="/app/pricing" component={PricingWithTable} />
                                            <Route path='/app/organizations/settings' render={({ location }) => {
                                                if (location.hash === "#billing") return <Redirect to="/app/settings/billing-and-plans" />
                                                return <Redirect to="/app/settings/org-details" />
                                            }} />
                                            <Route path='/app/sitemaps/crawl' component={() => {
                                                const { url } = queryString.parse(location.search) || {}
                                                if (loggedIn) {
                                                    // redirect here and open new sitemap modal
                                                    history.push("/app")
                                                    dispatch(toggleNewSitemapModal({ showing: true, createType: 'crawl', prefill: { url } }))
                                                }
                                            }} />
                                            <Route path="/app/settings/:subfolder" component={Settings} />
                                            <Redirect exact from="/app/help" to="/app/help/faqs" />
                                            <Route exact path="/app/help/faqs" component={FAQs} />
                                            <Route exact path="/app/help/ticket" component={Ticket} />
                                            <Route exact path="/app/trash" component={Trash} />
                                        </Switch>
                                    </PrivateRoute>
                                </Box>
                            </Box>
                        </Flex>
                    </Stack>
                </Stack>
            </Box>
        </>
    )
};

export const getIsSyncWithSystem = () => localStorage.getItem("chakra-ui-color-mode-system")

const UpdateColorModeOnPreferenceChange = () => {

    const dispatch = useDispatch()
    const { colorMode, toggleColorMode } = useColorMode()
    const preference = useColorModePreference()
    const prevPreference = usePrevious(preference)
    const isSyncWithSystem = getIsSyncWithSystem()
    const uiColorMode = useGetUi?.colorMode

    // update on change
    useLayoutEffect(() => {
        if (isSyncWithSystem) {
            if (colorMode !== preference && preference !== prevPreference) {
                toggleColorMode()
                dispatch(updateColorMode(colorMode))
            } else {
                if (colorMode !== uiColorMode) dispatch(updateColorMode(colorMode))
            }
        } else {
            dispatch(updateColorMode(colorMode))
        }
    }, [preference, prevPreference, colorMode, uiColorMode])

}

const SetFirestoreListenerForImportingSitemap = () => {
    const userId = useGetUserId()
    useFirestoreConnect(() => {
        if (!userId) return []
        return [{
            collection: 'sitemaps_processing',
            where: [
                ['createdBy', '==', userId],
                ['type', 'in', ['crawl', 'xml', 'csv']]
            ],
            limit: 1
        }]
    })
}

export default App;