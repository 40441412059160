import { Box, UnorderedList, VStack } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../hooks"
import { toTitleCase } from "../../../helpers"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { ScreenshotsPageColorsAndMoreListItem, UnlimitedFilesListItem, UnlimitedPagesPlusImportListItem } from "../Components/Messaging/ListItems"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"

export const PageSections = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                {`Your Rarchy Free account is limited to three page sections per page. To unlock `}<strong>unlimited page sections</strong> {`${canStartNewTrial ? `start your free ${TRIAL_DAYS}-day trial of` : 'upgrade to'} Rarchy ${toTitleCase(planId)}.`}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                <UnlimitedFilesListItem />
                <UnlimitedPagesPlusImportListItem />
                <ScreenshotsPageColorsAndMoreListItem />
            </UnorderedList>
        </VStack>
    )
}