import { FileMenu } from "./Menu"
import { FileShare } from "./Share"
import { HStack } from "@chakra-ui/react"
import { useInTrash } from "../../../../hooks"

export const FileOptions = ({ file, isMouseover, opts = {} }) => {
    const inTrash = useInTrash()
    return (
        <HStack maxH={0} w="full" justifyContent="end" top="25px" zIndex={0} right={2} pos="relative" visibility={isMouseover ? "visible" : "hidden"}>
            {!inTrash && <FileShare file={file} opts={opts} />}
            <FileMenu file={file} opts={opts} />
        </HStack>
    )
}