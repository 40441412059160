import { ARC_TO_RADIUS } from "../helpers";
import { drawBottomLeft, drawBottomRight, drawTopLeft, drawTopRight } from "./helpers";
// test config by dragging end node - NOT start node
export const drawRightStarts = (context, values) => {
    const { endPos, startNode, endNode, startConnectorX, endConnectorX, startConnectorY, endConnectorY, minPadding, startConnectorIsToTheLeftOfEndConnector, startConnectorIsBelowEndConnector, midXBetweenNodes, midYBetweenNodes } = values;
    // always start drawing here
    context.moveTo(startConnectorX, startConnectorY);
    // bottom
    if (endPos === 'bottom') { // THIS IS DONE FOR RADIUS
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) return drawBottomRight(context, endConnectorX, endConnectorY, startConnectorX, startConnectorY)
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                const startY = endNode.bottomOfNode + minPadding > startNode.topOfNode ? startNode.bottomOfNode + minPadding : midYBetweenNodes
                if (endNode.bottomOfNode + minPadding > startNode.topOfNode) {
                    drawTopRight(context, startConnectorX, startConnectorY, startConnectorX + minPadding, startY - ARC_TO_RADIUS)
                    drawBottomRight(context, startConnectorX + minPadding, startConnectorY + ARC_TO_RADIUS, endConnectorX + ARC_TO_RADIUS, startY)
                    drawBottomLeft(context, endConnectorX + ARC_TO_RADIUS, startY, endConnectorX, endConnectorY)
                } else {
                    drawBottomRight(context, startConnectorX + minPadding, startY + ARC_TO_RADIUS, startConnectorX, startConnectorY)
                    drawTopRight(context, startConnectorX, startY, startConnectorX + minPadding, startConnectorY - ARC_TO_RADIUS)
                    drawBottomLeft(context, startConnectorX, midYBetweenNodes, endConnectorX, endConnectorY)
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) { // THIS WORKS WITH RADIUS WITHOUT HAVING TO DRAW CORNERS
                const startX = endNode.leftOfNode > startNode.leftOfNode ? endNode.rightOfNode : startConnectorX;
                const endY = (endNode.bottomOfNode + minPadding) > startNode.bottomOfNode ? endConnectorY : startNode.bottomOfNode;
                context.arcTo(startX + minPadding, startConnectorY, startX + minPadding, endY + minPadding, ARC_TO_RADIUS);
                context.arcTo(startX + minPadding, endY + minPadding, endConnectorX, endY + minPadding, ARC_TO_RADIUS);
                context.arcTo(endConnectorX, endY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                const startX = (endNode.leftOfNode > (startNode.rightOfNode + minPadding)) ? midXBetweenNodes : endNode.rightOfNode + minPadding
                const startIsToTheLeft = (endNode.leftOfNode > (startNode.rightOfNode + minPadding))
                drawTopRight(context, startConnectorX, startConnectorY, startX, endConnectorY + minPadding - ARC_TO_RADIUS)
                drawBottomRight(context, endConnectorX, endConnectorY, startX + (startIsToTheLeft ? ARC_TO_RADIUS : -ARC_TO_RADIUS), endConnectorY + minPadding)
                return drawBottomLeft(context, startX + (startIsToTheLeft ? ARC_TO_RADIUS : -ARC_TO_RADIUS), endConnectorY + minPadding, startX, startConnectorY + ARC_TO_RADIUS)
            }
        }
    }
    // top
    if (endPos === 'top') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endNode.leftOfNode > startNode.rightOfNode) {
                    drawBottomRight(context, midXBetweenNodes, endConnectorY, startConnectorX, startConnectorY)
                    drawTopLeft(context, midXBetweenNodes, startConnectorY, endConnectorX, endConnectorY - minPadding)
                    drawTopRight(context, midXBetweenNodes + ARC_TO_RADIUS, endConnectorY - minPadding, endConnectorX, endConnectorY)
                } else {
                    drawBottomRight(context, startConnectorX + minPadding, midYBetweenNodes + ARC_TO_RADIUS, startConnectorX, startConnectorY)
                    drawTopRight(context, endNode.leftOfNode - minPadding + ARC_TO_RADIUS, midYBetweenNodes, startConnectorX + minPadding, startConnectorY - ARC_TO_RADIUS)
                    drawBottomLeft(context, endNode.leftOfNode - minPadding + ARC_TO_RADIUS, midYBetweenNodes, endNode.leftOfNode - minPadding, endConnectorY - minPadding + ARC_TO_RADIUS)
                    drawTopLeft(context, endNode.leftOfNode - minPadding, midYBetweenNodes - ARC_TO_RADIUS, endConnectorX - ARC_TO_RADIUS, endConnectorY - minPadding)
                    drawTopRight(context, endConnectorX - ARC_TO_RADIUS, endConnectorY - minPadding, endConnectorX, endConnectorY)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (endNode.rightOfNode > startConnectorX) {
                    // THIS IS THE SAME AS ABOVE
                    drawBottomRight(context, startConnectorX + minPadding, midYBetweenNodes + ARC_TO_RADIUS, startConnectorX, startConnectorY)
                    drawTopRight(context, endNode.leftOfNode - minPadding + ARC_TO_RADIUS, midYBetweenNodes, startConnectorX + minPadding, startConnectorY - ARC_TO_RADIUS)
                    drawBottomLeft(context, endNode.leftOfNode - minPadding + ARC_TO_RADIUS, midYBetweenNodes, endNode.leftOfNode - minPadding, endConnectorY - minPadding + ARC_TO_RADIUS)
                    drawTopLeft(context, endNode.leftOfNode - minPadding, midYBetweenNodes - ARC_TO_RADIUS, endConnectorX - ARC_TO_RADIUS, endConnectorY - minPadding)
                    drawTopRight(context, endConnectorX - ARC_TO_RADIUS, endConnectorY - minPadding, endConnectorX, endConnectorY)
                } else {
                    const endY = (endConnectorY > startNode.topOfNode) ? startNode.topOfNode : endConnectorY;
                    drawBottomRight(context, startConnectorX + minPadding, endY - minPadding + ARC_TO_RADIUS, startConnectorX, startConnectorY)
                    drawTopRight(context, endConnectorX + ARC_TO_RADIUS, endY - minPadding, startConnectorX + minPadding, startConnectorY - ARC_TO_RADIUS)
                    drawTopLeft(context, endConnectorX, endConnectorY, endConnectorX + ARC_TO_RADIUS, endY - minPadding)
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            if ((endConnectorX) > startNode.rightOfNode) {
                drawTopRight(context, startConnectorX, startConnectorY, endConnectorX, endConnectorY)
            } else {
                if (endConnectorY > (startNode.bottomOfNode + minPadding)) {
                    drawTopRight(context, startConnectorX, startConnectorY, startConnectorX + minPadding, midYBetweenNodes - ARC_TO_RADIUS)
                    drawBottomRight(context, startConnectorX + minPadding, midYBetweenNodes - ARC_TO_RADIUS, endConnectorX + ARC_TO_RADIUS, midYBetweenNodes)
                    drawTopLeft(context, endConnectorX, endConnectorY, endConnectorX + ARC_TO_RADIUS, midYBetweenNodes)
                } else {
                    drawTopRight(context, startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, startNode.bottomOfNode + minPadding);
                    drawBottomRight(context, startConnectorX + minPadding, startNode.bottomOfNode + minPadding, midXBetweenNodes, startNode.bottomOfNode + minPadding);
                    // context.arcTo(midXBetweenNodes, startNode.bottomOfNode + minPadding, midXBetweenNodes, endConnectorY - minPadding, ARC_TO_RADIUS);
                    drawTopRight(context, endConnectorX + ARC_TO_RADIUS, endConnectorY - minPadding, midXBetweenNodes, startNode.bottomOfNode + minPadding - ARC_TO_RADIUS)
                    drawTopLeft(context, endConnectorX, endConnectorY, midXBetweenNodes - ARC_TO_RADIUS, endConnectorY - minPadding)
                }
            }
        }
    }
    // left
    if (endPos === "left") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(midXBetweenNodes, startConnectorY, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS);
                context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                const topOfNode = (endNode.topOfNode > startNode.topOfNode) ? startNode.topOfNode : endNode.topOfNode;
                const startY = (endNode.bottomOfNode + minPadding) < startNode.topOfNode ? midYBetweenNodes : topOfNode - minPadding
                context.arcTo(startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, startY, ARC_TO_RADIUS);
                context.arcTo(startConnectorX + minPadding, startY, endConnectorX - minPadding, startY, ARC_TO_RADIUS);
                context.arcTo(endConnectorX - minPadding, startY, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS);
                context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                const bottomOfNode = (endNode.bottomOfNode < startNode.bottomOfNode) ? startNode.bottomOfNode : endNode.bottomOfNode;
                const startY = (startNode.bottomOfNode + minPadding) < endNode.topOfNode ? midYBetweenNodes : bottomOfNode + minPadding
                context.arcTo(startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, startY, ARC_TO_RADIUS);
                context.arcTo(startConnectorX + minPadding, startY, endConnectorX - minPadding, startY, ARC_TO_RADIUS);
                context.arcTo(endConnectorX - minPadding, startY, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS);
                context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(midXBetweenNodes, startConnectorY, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS);
                context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS);
            }
        }
    }
    if (endPos === "right") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(endConnectorX + minPadding, startConnectorY, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                const topOfStartIsBelowEndConnectorY = (endConnectorY + minPadding) < startNode.topOfNode
                if (topOfStartIsBelowEndConnectorY) {
                    context.arcTo(startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    const topOfNode = ((endConnectorY + minPadding) > startNode.topOfNode) ? startNode.topOfNode : endNode.topOfNode; // top of end node is below top of start node
                    context.arcTo(startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX + minPadding, topOfNode - minPadding, midXBetweenNodes, topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, topOfNode - minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY > startNode.bottomOfNode) {
                    context.arcTo(startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    const bottomOfNode = (endConnectorY < startNode.bottomOfNode) ? startNode.bottomOfNode : endNode.bottomOfNode;
                    context.arcTo(startConnectorX + minPadding, startConnectorY, startConnectorX + minPadding, bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX + minPadding, bottomOfNode + minPadding, midXBetweenNodes, bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, bottomOfNode + minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }

            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(endConnectorX + minPadding, startConnectorY, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
    }
    context.lineTo(endConnectorX, endConnectorY); // always end here
}