import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Skeleton } from "@chakra-ui/react"
import { ToggleButtonGroup } from '../Components/ToggleButton/ButtonGroup';
import { ToggleButton } from '../Components/ToggleButton/Button';
import { hideCommentsPopover } from '../../../Sitemap/comments/helpers.jsx';
import { switchCoversDevice } from '../../../../store/actions/sitemap-actions';
import { BsDisplay, BsPhone, BsTablet } from 'react-icons/bs'
import { useGetSitemap, useInUserFlow } from '../../../../hooks';
import { EditorSkeletonWrapper } from '..';

export const Devices = () => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()

    const inUserFlow = useInUserFlow()

    const showCovers = inUserFlow ? true : sitemap?.showCovers;
    if (!showCovers) return null;

    const handleDeviceChange = (device) => { hideCommentsPopover(); dispatch(switchCoversDevice(device)) };

    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                value={sitemap?.covers.device}
                isAttached
                variant="outline"
                isRadio
                onChange={handleDeviceChange}
            >
                <ToggleButton value="desktop" icon={<BsDisplay fontSize="17px" />} />
                {/* <ToggleButton value="tablet" icon={<BsTablet fontSize="17px" />} /> */}
                <ToggleButton value="mobile" icon={<BsPhone fontSize="17px" />} />
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}