import { IconButton } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../../hooks"
import { INITIAL_SCREENS } from "../.."
import { ChevronLeftIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { setUpgradeModalScreen } from "../../../../store/actions/ui-actions"
import { useState } from "react"

export const BackButton = () => {
    const dispatch = useDispatch()
    const { screen, lockPlan } = useGetUpgradeModal()
    const [initialScreen] = useState(screen)
    const isConfirming = screen === 'confirming'
    const showBackButton = !isConfirming && (screen && !INITIAL_SCREENS?.some(v => screen?.startsWith(v)))
    if (!showBackButton) return null
    return (
        <IconButton
            variant="unstyled"
            size="sm"
            icon={<ChevronLeftIcon fontSize="3xl" />}
            ml={-3}
            onClick={() => {
                const getScreenToReturnTo = () => {
                    if (screen === 'details') return 'plans-pricing'
                    if (screen === 'plans-pricing') return INITIAL_SCREENS?.includes(initialScreen) ? initialScreen : undefined
                }
                let goToScreen = getScreenToReturnTo()
                dispatch(setUpgradeModalScreen({ screen: goToScreen }))
            }}
        />
    )
}