import {
    Box,
    HStack,
    Stack,
    Step,
    StepDescription,
    StepIndicator,
    StepNumber,
    StepStatus,
    StepTitle,
    Stepper,
    Tooltip,
    useSteps
} from "@chakra-ui/react"
import { clearBulkCaptureDoc, useWillHitMonthlyScreenshotsRemaining } from "../../hooks"

import { Capture } from "./Capture"
import { HitLimitAlert } from "../HitLimitAlert"
import { RemainingScreenshots } from "../Remaining"
import { Steps } from "./Steps"
import { TopBar } from "./TopBar"
import { defaultScreenshotSettings } from "../../../../page-drawer/Covers/Screenshot"
import { isEmpty } from 'lodash'
import { useGetSitemap } from "../../../../../../../hooks"
import { useState } from "react"

export const BulkCapture = ({ isAutocapturing, autocapturing, hasAutocaptured, autocaptured }) => {
    const sitemap = useGetSitemap()
    const [checked, setChecked] = useState(Object.keys(sitemap?.docs?.pages || {}))
    const [options, setOptions] = useState(defaultScreenshotSettings)
    const beforeCaptureStarted = !isAutocapturing && !hasAutocaptured;
    const steps = getSteps({ beforeCaptureStarted, hasAutocaptured });
    const { activeStep, setActiveStep, goToPrevious, goToNext } = useSteps({ index: beforeCaptureStarted ? 0 : 3, count: steps.length })
    const willHitScreenshotsLimit = useWillHitMonthlyScreenshotsRemaining(checked?.length)
    const canProgress = !willHitScreenshotsLimit && (hasAutocaptured || canGoToNextStep({ activeStep, checked, beforeCaptureStarted }));
    return (
        <Stack w="full">
            <>
                <HStack w="full" justify="space-between" borderBottomWidth="1px" py={5} pt={3} mb={4}>
                    {beforeCaptureStarted && (
                        <Stepper w="90px" gap={2.5} index={hasAutocaptured ? 4 : activeStep} size="sm">
                            {steps.map((step, index) => {
                                const isActive = activeStep === index;
                                return (
                                    <Step key={index} onClick={() => {
                                        if (hasAutocaptured) clearBulkCaptureDoc(setActiveStep)
                                        if (canProgress) setActiveStep(index);
                                    }}>

                                        <StepIndicator _hover={{ cursor: 'pointer' }}>
                                            <StepStatus complete={<StepNumber />} incomplete={<StepNumber />} active={<StepNumber />} />
                                        </StepIndicator>
                                        <Tooltip variant="rounded" label={!canProgress ? beforeCaptureStarted ? "No pages selected yet..." : "Capturing..." : ""} placement="bottom" openDelay={500}>
                                            <Box flexShrink='0' _hover={{ cursor: canProgress ? 'pointer' : 'default' }} className={index > 0 && !canProgress ? "disabled" : ""}>
                                                {isActive && <StepTitle>{step.title}</StepTitle>}
                                                {isActive && <StepDescription>{step.description}</StepDescription>}
                                            </Box>
                                        </Tooltip>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    )}
                    <TopBar
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        goToPrevious={goToPrevious}
                        goToNext={goToNext}
                        canProgress={canProgress}
                        checked={checked}
                        setChecked={setChecked}
                        options={options}
                        isAutocapturing={isAutocapturing}
                        autocapturing={autocapturing}
                        hasAutocaptured={hasAutocaptured}
                        autocaptured={autocaptured}
                    />
                </HStack>
                {activeStep === 0 && !willHitScreenshotsLimit && (
                    <Box mt={-.5} pb={3.5}>
                        <RemainingScreenshots />
                    </Box>
                )}
                {willHitScreenshotsLimit && <HitLimitAlert checked={checked} />}
                {beforeCaptureStarted && (
                    <Steps activeStep={activeStep} setActiveStep={setActiveStep} checked={checked} setChecked={setChecked} options={options} setOptions={setOptions} />
                )}
            </>
            <Capture
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                checked={checked}
                isAutocapturing={isAutocapturing}
                autocapturing={autocapturing}
                hasAutocaptured={hasAutocaptured}
                autocaptured={autocaptured}
            />
        </Stack>
    )
}

const canGoToNextStep = ({ activeStep, checked, beforeCaptureStarted }) => {
    if (activeStep === 0) return !isEmpty(checked);
    if (!beforeCaptureStarted) return false;
    return true;
}

const getSteps = ({ beforeCaptureStarted, hasAutocaptured }) => {
    return [
        { title: 'Pages', description: '' },
        { title: 'Options', description: '' },
        { title: 'Preview', description: '' },
        { title: beforeCaptureStarted ? 'Capture' : !hasAutocaptured ? 'Capturing' : 'Captured', description: '' },
    ]
}