import { FormControl, HStack, Heading, Input, VStack } from "@chakra-ui/react"
import { useFormikContext } from "formik"

export const BasicAuth = () => {
    const { handleChange } = useFormikContext()
    return (
        <VStack w="xl" spacing={3} alignItems="start">
            <Heading fontSize="md" fontWeight="semibold">HTTP Basic Auth</Heading>
            <HStack w="full">
                <FormControl id="username">
                    <Input bgColor="rarchy-bg-white" placeholder="Username" size="md" fontSize="sm" type="text" maxLength={255} rounded="md" onChange={handleChange} />
                </FormControl>
                <FormControl id="password">
                    <Input bgColor="rarchy-bg-white" placeholder="Password" size="md" fontSize="sm" type="text" rounded="md" onChange={handleChange} />
                </FormControl>
            </HStack>
        </VStack>
    )
}