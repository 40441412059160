import { store } from '../../../../../../store';
import wireframes from '../../components/page-sections/options/Wireframes/modules';
import { updatePageSectionsData, resetNodeHeights, addPageSectionChange } from '../../../../../../store/actions/sitemap-actions';
import Values from 'values.js';
import { getPageColor, getPageId } from '../helpers';
import { getSectionColor } from '../../components/page-sections';
import { getSitemap } from '../../../../../../helpers';
import { cloneDeep } from 'lodash'

export const changePageSectionWireframe = (wireframe) => {

    const sitemap = getSitemap();
    const { node, section } = sitemap?.ui?.PageSectionsOptionsPopover;

    const inUserFlow = node.page && (node.type && node.type.includes('page-sections'));

    const nodeId = getPageId(node);

    var sections = cloneDeep(sitemap?.data?.page_sections);
    var sectionData = !inUserFlow ? (sections[nodeId] || []) : node.sections; // node.sections = in user flow
    /*** update wireframe ***/
    sectionData.map((s) => { if (s.id.toString() === section.id.toString()) s.wireframe = wireframe; return section }); // set wireframe
    /*** update wireframe ***/
    sections[nodeId] = sectionData;
    /*** dispatch so we can render immediately ***/
    store.dispatch(updatePageSectionsData(sections));
    /*** dispatch so we can render immediately ***/
    // reset node heights after immediate render
    store.dispatch(resetNodeHeights([nodeId], { update: true }));
    // save change 
    var changeData = { pageId: nodeId, section: { id: section.id, wireframe: wireframe ? wireframe : 'delete' } };
    const change = {
        id: new Date().getTime(),
        data: [{ action: 'page-section-wireframe', ...changeData }],
    };
    const history = {
        action: 'page-section-wireframe',
        data: {
            ...changeData,
            newWireframe: wireframe,
            oldWireframe: section.wireframe
        }
    };
    store.dispatch(addPageSectionChange({ change, history }));
};

export const getWireframeWithColor = (section, opts) => {
    const sitemap = getSitemap();
    const { baseColors } = sitemap?.pallette;
    const wireframeColors = getWireframePalletteColors(section, opts);
    var { svg } = { ...wireframes[section.wireframe] };
    baseColors.forEach((b, i) => svg = svg.replaceAll(b, wireframeColors[i]))
    return svg;
}

export const getWireframePalletteColors = (section, opts) => {
    const { forWireframe } = opts || {};
    const color = new Values((section.color || forWireframe) ? getSectionColor(section) : getPageColor(section.node));
    var colors = color.all(5).map(c => c.hex.toUpperCase());
    return [`#${colors[2]}`, `#${colors[5]}`, `#${colors[8]}`, `#${colors[12]}`, `#${colors[14]}`, `#${colors[17]}`, `#${colors[20]}`, `#${colors[23]}`];
};