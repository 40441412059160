import { Button, Icon } from "@chakra-ui/react"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { AiOutlinePlayCircle } from "react-icons/ai"
import { wait } from "../../../../../../../shared/helpers"
import { useDispatch } from "react-redux"
import { mergeImportedSitemapById } from "../../../../../../store/actions/sitemaps-actions"
import { getFolderIdFromPath } from "../../../../../../helpers"

export const Resume = ({ data, isResuming, setIsResuming }) => {
    const dispatch = useDispatch()
    const folderId = getFolderIdFromPath({ returnAccountId: true })
    const { id, paused, processed, pages, error, ...rest } = data;
    return (
        <Button
            variant="outline"
            fontSize="md"
            iconSpacing={3}
            leftIcon={<Icon as={AiOutlinePlayCircle} fontSize="lg" />}
            size="lg"
            isLoading={isResuming}
            onClick={async () => {
                setIsResuming(true)
                await setDoc(doc(getFirestore(), "sitemaps_processing", id), { ...rest, createdAt: new Date(), team: folderId, options: { ...rest.options, paused: false } })
                await wait(500)
                dispatch(mergeImportedSitemapById({ [id]: undefined })) // remove from imported
            }}>
            Resume
        </Button>
    )
}