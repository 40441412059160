import { Avatar, AvatarBadge, Box, Divider, Icon, Stack, Tooltip } from "@chakra-ui/react"
import { useGetFolderById, useGetOrganization, useInOrganization, useIsUserFile } from "../../../../hooks"
import { HiOutlineOfficeBuilding } from "react-icons/hi"
import { RiUserLine } from "react-icons/ri"
import { useContext } from "react"
import { ShareFileContext } from "./ShareFileHOC"
import { BsFolderFill } from "react-icons/bs"

export const AccountFolderAccess = () => {

    const { file } = useContext(ShareFileContext)

    const inOrganization = useInOrganization()
    const organization = useGetOrganization()

    const folder = useGetFolderById(file?.team)
    const isUserFile = useIsUserFile(file)

    if (isUserFile) return null

    const fileIsInFolder = file?.team !== organization?.id
    const accountOrFolderName = fileIsInFolder ? folder?.name : organization?.name

    return (
        <Box>
            <Stack px={7} py={1.5} direction="row" align="center" _hover={{ bgColor: 'rarchy-bg-subtle-hover' }}>
                {inOrganization && (
                    <>
                        {fileIsInFolder && (
                            <Icon fontSize="3xl" as={BsFolderFill} color="rarchy-badge-icon" />
                        )}
                        {!fileIsInFolder && (
                            <Avatar size="sm" name={accountOrFolderName}>
                                <AvatarBadge top="10px" left="10px" bgColor="rarchy-border-sitemap-editor" p={1} boxSize='1.75em' size="sm">
                                    <Icon as={inOrganization ? HiOutlineOfficeBuilding : RiUserLine} fontSize="sm" color="rarchy-color-sitemap-editor" />
                                </AvatarBadge>
                            </Avatar>
                        )}
                    </>
                )}
                <Stack px={2} spacing={.5}>
                    <Tooltip variant="rounded-sm" label={accountOrFolderName} openDelay={500} hasArrow>
                        <Box fontWeight="medium" _hover={{ cursor: 'default' }} noOfLines={1} fontSize="sm">{accountOrFolderName}</Box>
                    </Tooltip>
                    <Box fontSize="xs" color="fg.muted" _hover={{ cursor: 'default' }} noOfLines={1}>{`Everyone ${fileIsInFolder ? 'with access to this folder' : 'in this account'}`}</Box>
                </Stack>
            </Stack>
            <Box px={7}>
                <Divider my={2} />
            </Box>
        </Box>
    )
}