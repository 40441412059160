import React from 'react';
import {
    IconButton,
    Tooltip,
    PopoverTrigger,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { RiPaintFill } from 'react-icons/ri';

export const ColorButton = ({ onToggle }) => {
    return (
        <PopoverTrigger>
            <Tooltip variant="rounded" placement='top' label='Color'>
                <IconButton size="sm" icon={<RiPaintFill fontSize="16px" />} variant="ghost" color={mode("gray.600", "whiteAlpha.900")} onClick={() => { onToggle(); }} />
            </Tooltip>
        </PopoverTrigger>
    )
}