import { applyYWithCavasOffset, getRandomColor } from '../../utils/helpers';
import { getRevisionHistoryDrawer, getSitemap, getUI } from '../../../../../../helpers';
import { toggleWebsiteSectionNewButton, toggleWebsiteSectionOptionsPopover } from '../../../../../../store/actions/sitemap-actions';

import canvasTxt from '../text/canvasTxt';
import { dragging } from '../../utils/drag/index';
import { event } from 'd3';
import { getCanEditInEditor } from '../../../../../Editor/Navbar/helpers';
import { roundRect } from '../node';
import { showEditorPermissionsToast } from '../../../../../Editor/App/Components/PermissionsToast';
import { sortBy } from 'lodash'
import { store } from '../../../../../../store';
import { transform } from '../../utils/zoom';

export const colors = {};
const colorBySectionIds = {};

const PADDING = 35;

export const renderWebsiteSections = (context, hidden) => {

    const sitemap = getSitemap()

    const { website_sections } = sitemap?.data;
    if (!website_sections) return;

    const ui = getUI()

    const spacing = 25;

    var fontSize = 19;
    var font = `500 ${fontSize}px Inter,sans-serif`;

    canvasTxt.fontSize = fontSize;
    canvasTxt.align = 'left';
    canvasTxt.fontWeight = 500;
    canvasTxt.font = 'Inter,sans-serif';
    context.font = font;
    context.fillStyle = sitemap?.pallette?.header;

    // no website_sections (empty object in arr so we don't have to keep recalculating y pos
    if (hidden) {
        if (Object.keys(website_sections)[0] === 'empty') {
            var randomColorForNewSection;
            const section = { id: 'empty', ...website_sections['empty'] };
            if (!colorBySectionIds['empty']) {
                /*** assign new random color to new page ***/
                randomColorForNewSection = getRandomColor();
                colorBySectionIds[`empty`] = randomColorForNewSection;
                colors[randomColorForNewSection] = { newSectionPlaceholder: true, ...section };
                /*** assign new random color to new page ***/
            } else {
                randomColorForNewSection = colorBySectionIds[`empty`];
            }
            context.beginPath();
            context.rect(section.x, section.y - 60, section.width, section.height);
            context.fillStyle = randomColorForNewSection;
            context.fill();
        }
    };

    Object.keys(website_sections)?.filter(id => id !== 'empty').forEach(id => {

        const section = { id, ...website_sections[id] };

        /*** assign event colors ***/
        var randomColorForTitle;
        var randomColorForNewPage;
        var randomColorForDroppedPage;

        if (hidden) {
            if (!colorBySectionIds[section.id]) {
                /*** assign new random color to section title ***/
                randomColorForTitle = getRandomColor();
                colorBySectionIds[section.id] = randomColorForTitle;
                colors[randomColorForTitle] = section;
                /*** assign new random color to section title ***/
                /*** assign new random color to new page ***/
                randomColorForNewPage = getRandomColor();
                colorBySectionIds[`${section.id}#new-page`] = randomColorForNewPage;
                colors[randomColorForNewPage] = { newPagePlaceholder: true, ...section };
                /*** assign new random color to new page ***/
                /*** assign new random color to new page ***/
                randomColorForDroppedPage = getRandomColor();
                colorBySectionIds[`${section.id}#dropped-page`] = randomColorForDroppedPage;
                colors[randomColorForDroppedPage] = { droppedPagePlaceholder: true, ...section };
                /*** assign new random color to new page ***/
            } else {
                randomColorForTitle = colorBySectionIds[section.id]; // use existing assigned color
                if (section.isEmpty) {
                    if (!dragging) {
                        randomColorForNewPage = colorBySectionIds[`${section.id}#new-page`];
                    } else {
                        randomColorForDroppedPage = colorBySectionIds[`${section.id}#dropped-page`];
                    }
                }
            }
        };
        /*** assign event colors ***/

        const isTree = sitemap?.format === 'tree';
        const textWidth = context.measureText(section.title).width;
        const x = section.x + (!isTree ? ((section.width / 2) - (textWidth / 2)) : 0);

        if (!hidden) {
            // text
            canvasTxt.drawText(context, (section.title || ""), x + (!isTree ? 0 : 40), section.y - (fontSize / 1.5), textWidth * 1.2, fontSize)
            context.textAlign = 'start'; // reset context text-align
            // divider
            context.beginPath();
            context.rect(section.x, section.y, (!isTree ? ((section.width / 2) - (textWidth / 2) - spacing) : 20), 0.25);
            context.strokeStyle = ui.colorMode === "light" ? "#e2e8f0" : "#2d3748";
            context.stroke();
            context.rect(x + textWidth + spacing + (!isTree ? 0 : 40), section.y, (!isTree ? ((section.width / 2) - (textWidth / 2) - spacing) : section.width - (section.x + (x + textWidth + spacing + (!isTree ? 0 : 40)))), 0.25);
            context.stroke();
            // drop page rect
            if (section.isEmpty) {
                if (dragging) {
                    const isDraggedOver = dragging.website_section && dragging.website_section.id === section.id && dragging.website_section.droppedPagePlaceholder;
                    context.fillStyle = !isDraggedOver ? 'transparent' : (ui.colorMode === 'light' ? "#e2e8f045" : "#2d374845");
                    roundRect(context, section.x, section.y + (PADDING * 2), section.width, section.height - ((PADDING * 2) * 2), 5, true)
                    context.fillStyle = sitemap?.pallette?.header;
                }
            }
        };

        // selector
        if (hidden) {

            // title
            context.beginPath();
            context.rect(x - (textWidth * 0.1) + (!isTree ? 0 : 40), section.y - fontSize, textWidth * 1.2, fontSize * 2);
            context.fillStyle = randomColorForTitle;
            context.fill();

            // new node rect
            if (section.isEmpty) {
                if (!dragging) {
                    // new page
                    context.beginPath();
                    context.rect(section.x, section.y + PADDING, section.width, section.height - (PADDING * 2));
                    context.fillStyle = randomColorForNewPage;
                    context.fill();
                } else {
                    // drop page
                    context.beginPath();
                    context.fillStyle = randomColorForDroppedPage;
                    roundRect(context, section.x, section.y + (PADDING * 2), section.width, section.height - ((PADDING * 2) * 2), 5, false)
                    context.fill();
                }
            }

        }

    });
}

export const mouseoverWebsiteSection = (colKey) => {
    let section = colors[colKey];
    if (section) {

        const { newPagePlaceholder, newSectionPlaceholder } = section;
        const sitemap = getSitemap()
        const sections = sitemap?.data?.website_sections;
        section = { ...sections[section?.id] };

        const isTree = sitemap?.format === 'tree';

        if (newPagePlaceholder || newSectionPlaceholder) {
            // can edit
            const canEdit = getCanEditInEditor(); if (!canEdit) return;
            // isn't in history
            if (getRevisionHistoryDrawer()?.showing) return;
            // continue
            if (newPagePlaceholder) section.newPagePlaceholder = true; // NEED THIS
            if (newSectionPlaceholder) section.newSectionPlaceholder = true; // NEED THIS
            if (!sitemap?.ui.WebsiteSectionOptionsPopover.showing) {
                const x = transform.applyX(section.x);
                const y = applyYWithCavasOffset(section.y);
                const right = x + (!isTree && (section.width / 2) * transform.k);
                const top = y;
                store.dispatch(toggleWebsiteSectionNewButton({ showing: true, section, offset: [right, top] }));
            }
        }

        return section;
    };
}

export const clickWebsiteSection = (section, sitemap) => {
    // can edit
    const canEdit = showEditorPermissionsToast(); if (!canEdit) return;
    // continue
    if (section.newSectionPlaceholder || section.newPagePlaceholder) return;
    const { WebsiteSectionOptionsPopover } = sitemap?.ui;
    const x = transform.applyX(section.x);
    const y = applyYWithCavasOffset(section?.y);
    const right = event ? event.x : WebsiteSectionOptionsPopover ? WebsiteSectionOptionsPopover.offset[0] : x + (((section.width / 2) - 3) * transform.k);
    const top = (y - 55) - (12.5 * transform.k);
    // NEEDS TO BE IN SET TIMEOUT
    setTimeout(() => {
        store.dispatch(toggleWebsiteSectionOptionsPopover({ showing: true, section, offset: [right, top], renaming: WebsiteSectionOptionsPopover.showing ? true : false }));
    }, 5)
};

export const mapWebSectionsToArr = (website_sections) => sortBy(Array.from(new Map(Object.entries(website_sections)), ([id, value]) => ({ id, ...value })), 'index');