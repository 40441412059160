import { getInUserFlow } from "../../../../../../helpers";
import { initZoom } from "../zoom";
import { resetMetaKeyPress } from "./keydown";

export const handleKeyUp = (e) => {
    const inUserFlow = getInUserFlow();
    if (inUserFlow) {
        // multi-select (e.metaKey doesn't work for keyup) - re-init zoom
        if (e.key === "Meta") {
            resetMetaKeyPress();
            initZoom();
        }
    }
}