import { Box, Center } from "@chakra-ui/react"
import { useDispatch } from "react-redux"

export const RightConfirmedInviteTeam = () => {
    const dispatch = useDispatch()
    return (
        <Center key="confirmed-bg" bgColor="#2E3C4B" w="full">
            <Box
                as='video'
                w="330px"
                autoPlay
                muted
                loop
                preload="true"
                controls={false}
                src={"https://help.rarchy.com/hubfs/Invite%20People%20-%20Dark.mp4"}
            />
        </Center>
    )
}