import { useEffect } from "react"
import { useGetUserId } from "../../../../hooks"
import { Error } from "./Invite/Error"
import { getDecodedEmail, getEncodedEmail, getPeopleData } from "../../../../helpers"
import copy from "fast-copy";
import { mergePeopleChange } from "../../../../store/actions/people-actions";
import { store } from "../../../../store";
import { isEqual, uniqBy } from 'lodash'

export const getUsersKey = (forOrganization) => {
    return forOrganization ? 'user' : 'collaborator';
}

export const getDefaultPermission = (forOrganization) => {
    return forOrganization ? 'user' : 'comment';
}

export const getPermissionsKey = (forOrganization) => {
    return forOrganization ? 'role' : 'access';
}

export const getPermissionWithSuffix = (permission, permissions, isOwner) => {
    if (isOwner) return 'Owner'
    if (permissions === 'access') return `${permission}${permission === 'edit' ? 'o' : 'e'}r`;
    return permission;
}

export const PERMISSIONS = { role: ['user', 'admin'], access: ['comment', 'edit'] };

export const ValidateInvited = ({ forOrganization, organization, file, peopleToInvite, setPeopleToInvite }) => {

    const userId = useGetUserId()

    const users = organization?.users
    const collaborators = file?.collaborators

    // if a user is included, they should be in people
    const people = getPeopleData()

    useEffect(() => {

        const validated = copy(peopleToInvite).map((v) => {
            if (v.error) return v;
            const person = people?.find(p => p?.email?.toLowerCase() === v?.email?.toLowerCase());
            const encodedEmail = getEncodedEmail(person)
            // adding themselves
            const isSameUser = person?.id === userId
            if (isSameUser) { v.error = "same"; return v; }
            // already a team member
            const isMemberOfOrganization = users?.[person?.id] || users?.[encodedEmail]
            if (forOrganization && isMemberOfOrganization) { v.error = "user"; return v; }
            // already a collaborator
            const existing = collaborators?.[person?.id] || collaborators?.[encodedEmail]
            if (existing) { v.error = "existing"; return v; }
            return v;
        });

        if (!isEqual(peopleToInvite, validated)) {
            // add errors here otherwise will break
            validated.map(user => {
                user.renderError = <Error user={user} forOrganization={forOrganization} />
                return user;
            })
            setPeopleToInvite(uniqBy(validated, 'email'));
        }

    }, [peopleToInvite])
}

export const chakraStyles = (permission) => {
    return {
        control: (provided, state) => ({
            ...provided,
            minHeight: '47px',
            py: 1,
        }),
        container: (prev, { selectProps }) => {
            const isContainerEmpty = selectProps.value.length < 1
            const isUser = permission === 'user'
            const isAdmin = permission === 'admin'
            const isEditor = permission === 'edit'
            const isComment = permission === 'comment'
            return ({
                ...prev,
                cursor: "text",
                borderRadius: "md",
                borderColor: "rarchy-chakra-react-select-border",
                bgColor: "transparent",
                maxW: isContainerEmpty ? "full" : isUser ? "76%" : isEditor || isAdmin ? "73%" : isComment ? "65%" : "full",
                w: "full"
            })
        },
        input: (prev, { selectProps }) => ({
            ...prev,
            cursor: "text",
            fontSize: "sm",
            borderColor: "rarchy-red",
            mx: 1
        }),
        placeholder: (prev, { selectProps }) => ({
            ...prev,
            pl: 1,
            color: "rarchy-color-sitemap-editor",
            fontSize: "sm"
        }),
        option: (prev, { selectProps }) => ({
            ...prev,
            fontSize: "sm"
        }),
        valueContainer: (prev, { selectProps }) => ({
            ...prev,
            pl: 2
        }),
        multiValue: (prev, props) => {
            const isError = props?.data.error;
            return ({
                ...prev,
                // borderWidth: "1px", 
                // borderColor: isError ? "red.600" : null,
                color: isError ? "rarchy-red" : null,
                fontWeight: isError ? "semibold" : null,
            })
        },
        noOptionsMessage: (prev, { selectProps }) => ({
            ...prev,
            px: 4,
            py: 2,
            textAlign: 'left',
            fontSize: "sm",
            color: "gray.500"
        })
    }
};

export const addUsersToPeople = (users) => {
    try {
        const people = getPeopleData()
        const peopleToMerge = {}
        Object.keys(users || {}).forEach(encodedEmail => {
            const decodedEmail = getDecodedEmail(encodedEmail)
            const userExists = people.find(p => p?.email === decodedEmail)
            if (!userExists) {
                if (!peopleToMerge[decodedEmail]) {
                    peopleToMerge[decodedEmail] = { email: decodedEmail }
                }
            }
        })
        store.dispatch(mergePeopleChange(peopleToMerge))
    }
    catch (e) {
        console.error(e)
    }
}