import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuOptionGroup,
    Portal,
    Stack,
    Text
} from '@chakra-ui/react'

import { BsFolderPlus } from "react-icons/bs"
import { NewSitemapMenu } from "../../Sitemaps/New/Menu"
import { RiFlowChart } from "react-icons/ri"
import { SkeletonWrapper } from "../../Components/Shared/SkeletonWrapper"
import { TbSitemap } from "react-icons/tb"
import { toggleNewFolderModal } from "../../../store/actions/folders-actions"
import { toggleNewUserFlowModal } from "../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useState } from "react"

const FONT_SIZE = "sm"
const ICON_SIZE = "xl"
const PX = 4
const PY = 2.5

export const New = () => {

    const dispatch = useDispatch()

    const [screen, setScreen] = useState('default')
    const isDefault = screen === "default"
    const isSitemap = screen === "sitemap"

    return (
        <Menu placement="bottom-end" autoSelect={false} isLazy closeOnSelect={isSitemap ? true : false} onClose={() => {
            setTimeout(() => {
                setScreen('default')
            }, 250);
        }}>
            <SkeletonWrapper w="fit-content">
                <MenuButton as={Button} fontSize="sm" leftIcon={<AddIcon fontSize="sm" />} iconSpacing={3}>Add new</MenuButton>
            </SkeletonWrapper>
            <Portal>
                <MenuList boxShadow="xl" fontSize={FONT_SIZE} onClick={e => e.stopPropagation()}>
                    {isDefault && (
                        <>
                            <MenuOptionGroup
                                as={Box}
                                title={<Text fontSize="lg" mt={-1.5}>Add new</Text>}
                                type='radio'
                            >
                            </MenuOptionGroup>
                            <MenuItem
                                px={PX} py={PY}
                                icon={<Icon as={BsFolderPlus} fontSize={ICON_SIZE} />}
                                onClick={() => {
                                    setTimeout(() => {
                                        dispatch(toggleNewFolderModal())
                                    }, 100)
                                }}>
                                Folder
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                px={PX} py={PY}
                                icon={<Icon as={TbSitemap} fontSize={ICON_SIZE} />}
                                onClick={(e) => {
                                    setScreen('sitemap');
                                }}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Text>Visual Sitemap</Text>
                                    <ChevronRightIcon fontSize="xl" />
                                </Stack>
                            </MenuItem>
                            <MenuItem
                                px={PX} py={PY}
                                icon={<Icon as={RiFlowChart} fontSize={ICON_SIZE} />}
                                onClick={() => {
                                    dispatch(toggleNewUserFlowModal())
                                }}
                            >
                                User Flow
                            </MenuItem>
                        </>
                    )}
                    {isSitemap && <NewSitemapMenu setScreen={setScreen} />}
                </MenuList>
            </Portal>
        </Menu>
    )
}