import { compact, groupBy, sortBy, uniqBy } from "lodash";
import { convertObjToArrayWithKey, defaultPalletteColors } from "../../../../helpers";
import { useGetEditor, useGetSitemap, useGetUserFlow } from "../../../../hooks";

import { ColorLabel } from "./Components/Labels/class";
import { useMemo } from "react";

export const useGetColorsFromEditorDoc = () => {

    const editor = useGetEditor()

    const labels = useMemo(() => convertObjToArrayWithKey(editor?.colors, 'color') || [])?.map(o => { o.color = `#${o.color}`; return o }, [editor?.colors])
    const pages = useGetArrayOfColorsFromSitemapPages()
    const elements = useGetArrayOfColorsFromUserFlowElements()

    const merged = uniqBy([...labels, ...pages, ...elements], 'color')

    return sortBy(merged?.map(c => new ColorLabel(c)), 'label')
}

const useGetArrayOfColorsFromSitemapPages = () => {
    const sitemap = useGetSitemap()
    const pages = sitemap?.docs?.pages || {};
    const groupedByPage = useMemo(() => groupBy(pages, 'pallette.header'), [pages])
    return compact(Object.keys(groupedByPage)?.map(color => {
        if (color === 'undefined' || defaultPalletteColors?.includes(color)) return;
        return { color }
    }))
}

const useGetArrayOfColorsFromUserFlowElements = () => {
    const flow = useGetUserFlow()
    const elements = flow?.docs?.elements || {};
    const groupedByElement = useMemo(() => groupBy(elements, 'color'), [elements])
    return compact(Object.keys(groupedByElement)?.map(color => {
        if (color === 'undefined' || defaultPalletteColors?.includes(color)) return;
        return { color }
    }))
}
