import React from 'react';
import { getDefaultPalletteHeader } from '../../../../helpers';

export const FormatIcon = ({ scale, icon }) => {
  const stroke = getDefaultPalletteHeader()
  return <i style={{ transform: `scale(${scale})` }}>{icon({ stroke })}</i>
}

export const HorizontalSVG = ({ stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    version="1.1"
  >
    <g
      id="tree"
      stroke={stroke}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      transform="translate(12.000000, 12.000000) rotate(-90) translate(-12.000000, -12.000000)"
    >
      <path d="M11.5,5.501 L11.5,18.501" id="Shape" />
      <rect id="Rectangle-path" x="9" y="0.5" width="5" height="5" />
      <rect id="Rectangle-path-1" x="9.5" y="18.501" width="5" height="5" />
      <rect id="Rectangle-path-2" x="0.5" y="18.501" width="5" height="5" />
      <rect id="Rectangle-path-3" x="18.5" y="18.501" width="5" height="5" />
      <path d="M20.5,18.501 L20.5,12.501 L3.5,12.501 L3.5,18.501" id="Shape" />
    </g>
  </svg>
);

export const VerticalSVG = ({ stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    version="1.1"
  >
    <g
      id="tree-vertical"
      stroke={stroke}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      transform="translate(12.000000, 12.000000) rotate(0) translate(-12.000000, -12.000000)"
    >
      <path d="M11.5,5.501 L11.5,18.501" id="Shape" />
      <rect id="Rectangle-path" x="9" y="0.5" width="5" height="5" />
      <rect id="Rectangle-path-Copy" x="9.5" y="18.501" width="5" height="5" />
      <rect id="Rectangle-path" x="0.5" y="18.501" width="5" height="5" />
      <rect id="Rectangle-path" x="18.5" y="18.501" width="5" height="5" />
      <path d="M20.5,18.501 L20.5,12.501 L3.5,12.501 L3.5,18.501" id="Shape" />
    </g>
  </svg>
);

export const VerticalMatrixSVG = ({ stroke }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <g id="matrixIcon" stroke={stroke}>
        <path d="M2.5,5.501 L2.5,12.5" id="Shape"></path>
        <path d="M8.5,12 L2.5,11.999" id="Shape"></path>
        <path d="M10.5,14.501 L10.5,21" id="Shape"></path>
        <path d="M16.5,20.5 L10.5,20.5" id="Shape"></path>
        <rect id="Rectangle-path-Copy" x="9.5" y="0.501" width="5" height="5"></rect>
        <rect id="Rectangle-path-Copy" x="8.5" y="9.5" width="5" height="5"></rect>
        <rect id="Rectangle-path" x="0.5" y="0.501" width="5" height="5"></rect>
        <rect id="Rectangle-path" x="18.5" y="0.501" width="5" height="5"></rect>
        <rect id="Rectangle-path-Copy" x="16.5" y="18" width="5" height="5"></rect>
      </g>
    </g>
  </svg>
);

export const NodesSVG = ({ stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    version="1.1"
  >
    <g strokeWidth="1.5" stroke={stroke} fill="none" fillRule="evenodd">
      <circle id="Oval" cx="13.499" cy="10.501" r="4" />
      <circle id="Oval" cx="2" cy="5.501" r="1.5" />
      <circle id="Oval" cx="22" cy="2.001" r="1.5" />
      <circle id="Oval" cx="2" cy="22.001" r="1.5" />
      <circle id="Oval" cx="13.499" cy="22.001" r="1.5" />
      <path d="M3.06,20.94 L10.68,13.339" id="Shape" />
      <path d="M16.333,7.678 L20.938,3.062" id="Shape" />
      <path d="M3.35,6.151 L9.822,8.926" id="Shape" />
      <path d="M20.562,14.03 L16.944,12.535" id="Shape" />
      <path d="M13.499,20.501 L13.499,14.501" id="Shape" />
      <circle id="Oval" cx="22" cy="14.501" r="1.5" />
    </g>
  </svg>
);

const UserFlowsSVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <g id="Group">
        <rect id="Rectangle" fillOpacity="0" fill="#000000" x="8" y="0.5" width="8" height="4.5" rx="2"></rect>
        <path d="M16.1196907,12.5 L19.5,12.5" id="Line" strokeLinecap="square"></path>
        <path d="M5,12.5 L7.5,12.5" id="Line" strokeLinecap="square"></path>
        <path d="M20,12.5 L20,18.75" id="Line" strokeLinecap="square"></path>
        <polygon id="Path" fillOpacity="0" fill="#FFFFFF" points="12 9.5 7.5 12.5 12 15.5 16.5 12.5"></polygon>
        <polygon id="Path" fillOpacity="0" fill="#000000" points="15.3 23.5 16.3666667 19.5 23.3 19.5 22.2333333 23.5"></polygon>
        <path d="M0.5,19.5 L8.5,19.5 L8.5,23.1717205 C4.5,21.6710847 4.5,24.714853 0.5,22.9054907 L0.5,19.5 Z" id="Path" fillOpacity="0" fill="#FFFFFF"></path>
        <path d="M4.5,12.5 L4.5,18.75" id="Line" strokeLinecap="square"></path>
        <path d="M12,5 L12,9" id="Line" strokeLinecap="square"></path>
      </g>
    </g>
  </svg>
);

/* 
export const HorizontalTreeIcon = props => {
  return (
    <StyledIcon
      component={HorizontalSVG}
      style={{
        float: props.float,
        padding: props.padding,
        margin: props.margin,
        transform: `scale(${props.scale})`,
      }}
      className={props.className}
    />
  );
};
export const VerticalTreeIcon = props => (
  <StyledIcon
    component={VerticalSVG}
    style={{
      float: props.float,
      padding: props.padding,
      margin: props.margin,
      transform: `scale(${props.scale})`,
      verticalAlign: '-0.45em'
    }}
    className={props.className}
  />
);
export const VerticalTreeMatrixIcon = props => (
  <StyledIcon
    component={VerticalMatrixSVG}
    style={{
      float: props.float,
      padding: props.padding,
      margin: props.margin,
      transform: `scale(${props.scale})`,
      verticalAlign: '-0.35em'
    }}
    className={props.className}
  />
);
export const IndentedIcon = props => (
  <StyledIcon
    component={IndentedSVG}
    style={{
      float: props.float,
      padding: props.padding,
      margin: props.margin,
      transform: `scale(${props.scale})`,
    }}
    className={props.className}
  />
);
export const NodesIcon = props => (
  <StyledIcon
    component={NodesSVG}
    style={{
      float: props.float,
      padding: props.padding,
      margin: props.margin,
      transform: `scale(${props.scale})`,
    }}
    className={props.className}
  />
);

export const UserFlowsIcon = props => (
  <StyledIcon
    component={UserFlowsSVG}
    style={{
      float: props.float,
      padding: props.padding,
      margin: props.margin,
      transform: `scale(${props.scale})`,
    }}
  />
  ); */