import { store } from '../../../../../../store';
import { defaultPalletteColors, getSitemap } from '../../../../../../helpers';
import { updatePageSectionsData } from '../../../../../../store/actions/sitemap-actions';
import { cloneDeep } from 'lodash'

export const changePageSectionColor = ({ node, section, color }) => {
    const sitemap = getSitemap();
    var sections = cloneDeep(sitemap?.data.page_sections );
    // fail-safe for deleting color
    if (defaultPalletteColors.includes(color)) color = undefined;
    //
    var sectionData = sections[node.id] ? sections[node.id] : [];
    /*** update color ***/
    sectionData.map((s) => { if (s?.id?.toString() === section?.id?.toString()) s.color = color; return section }); // set new color
    /*** update color ***/
    sections[node.id] = sectionData;
    /*** dispatch so we can render immediately ***/
    store.dispatch(updatePageSectionsData(sections));
    /*** dispatch so we can render immediately ***/
}