import { Box, Stack, StackDivider, Text } from "@chakra-ui/react"
import { MainHeader, SectionHeader } from "../../components"
import { Password } from "./Password"
import { Security } from "./Security"
import { DeleteAccount } from "./Delete"

export const LoginAndSecurity = () => {
    return (
        <Stack maxW="3xl">
            <Box mb={7}>
                <Stack spacing={5} divider={<StackDivider />}>
                    <Password />
                    <Security />
                    <DeleteAccount />
                </Stack>
            </Box>
        </Stack>
    )
}