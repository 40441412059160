import {
} from "@chakra-ui/react"

import { FormatIcon, HorizontalSVG, VerticalMatrixSVG, VerticalSVG } from './icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { EditorSkeletonWrapper } from '..';
import React from 'react';
import { ToggleButton } from '../Components/ToggleButton/Button';
import { ToggleButtonGroup } from '../Components/ToggleButton/ButtonGroup';
import { center } from '../../../Sitemap/app/canvas/utils/zoom';
import { coversCanvas } from '../../../Sitemap/app/canvas/canvases';
// import { HorizontalTreeIcon, NodesIcon, VerticalTreeIcon, VerticalTreeMatrixIcon } from '../Views/icons';
import { hideCommentsPopover } from '../../../Sitemap/comments/helpers.jsx';
import { setFormat } from '../../../../store/actions/sitemap-actions';
import { simulation } from '../../../Sitemap/app/views/nodes';
import { update } from '../../../Sitemap/utils/app';

export const Formats = () => {
    const state = useSelector(state => state, shallowEqual)
    const { sitemap, ui } = state
    const dispatch = useDispatch()
    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                value={sitemap?.format}
                isAttached
                variant="outline"
                isRadio
                onChange={val => updateFormat({ props: { ...state, format: val }, dispatch })}
            >
                <ToggleButton
                    value="tree-vertical"
                    icon={
                        <FormatIcon
                            scale={0.65}
                            icon={VerticalSVG}
                        />
                    }
                />
                <ToggleButton
                    value="tree-vertical-matrix"
                    icon={
                        <FormatIcon
                            scale={0.6}
                            icon={VerticalMatrixSVG}
                        />
                    }
                />
                <ToggleButton
                    value="tree"
                    icon={
                        <FormatIcon
                            scale={0.6}
                            icon={HorizontalSVG}
                        />
                    }
                />
                {/* <ToggleButton
                value="nodes"
                icon={
                    <FormatIcon
                        scale={0.65}
                        icon={NodesSVG}
                        selected={sitemap?.format === 'nodes'}
                        colorMode={ui.colorMode} />
                }
            /> */}
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}

const updateFormat = async ({ props, dispatch }) => {

    const { sitemap, format } = props;

    if (format === sitemap?.format) return; // current format has been selected
    // stop simulation
    if (sitemap?.format === 'nodes' && simulation) simulation.stop();
    // persist
    dispatch(setFormat(format)); // react action
    localStorage.setItem(`${sitemap?.id}#format`, format);

    if (sitemap?.data.root) {
        if (sitemap?.format !== 'nodes' && coversCanvas) coversCanvas.clear();
        hideCommentsPopover(); // hide comment popover if showing
        center();
        update({ noTransition: true });
    }
};