import React, { useEffect } from 'react';
import { getDefaultPalletteHeader, getInUserFlow, getIsDefaultPalletteHeader, getRevisionHistoryDrawer, getSitemap, getUserFlow } from '../../../../../helpers';
import { handleKeyDown, handleKeyUp } from '../utils/events';
import { mouseoverUserFlowSymbol, renderMultiSelectBox } from '../../../user-flows/components/mouseover';
import { renderDrag, renderHeader } from '../components';
import { togglePageSectionsOptionsPopover, toggleSitemapNotificationsDropdown, toggleWebsiteSectionOptionsPopover } from '../../../../../store/actions/sitemap-actions';

import { changeEditorPallette } from '../../../../../store/actions/editor-actions';
import { dragging } from '../utils/drag';
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers';
import { hideFlowsOptionsPopover } from '../../../../../store/actions/flow-actions';
import { isEmpty } from 'lodash'
import { mouseover } from '../utils/listeners';
import { renderNodesTooltip } from '../components/options/nodes';
import { renderOptions } from '../components/options';
import { store } from '../../../../../store';
import { transform } from '../utils/zoom';
import { useDispatch } from 'react-redux';
import { useGetSitemap } from '../../../../../hooks';
import { useRef } from 'react';

export let interactionsCanvas;

export const InteractionsCanvas = React.memo(() => {

    const canvasRef = useRef(null);

    useEffect(() => {
        interactionsCanvas = canvasRef.current.getContext('2d', { alpha: true });
    }, [])

    HandleEventListeners()

    return (
        <canvas id="interactions-canvas" ref={canvasRef} onClick={() => hideAllShowing()} />
    )
})

const HandleEventListeners = () => {
    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp]);
}

const hideAllShowing = () => {

    const sitemap = getSitemap()
    const flow = getUserFlow()

    const { NotificationsDropdown, PageSectionsOptionsPopover, WebsiteSectionOptionsPopover } = sitemap?.ui || {};
    // hide notifications dropdown
    if (NotificationsDropdown?.showing) store.dispatch(toggleSitemapNotificationsDropdown(false))
    // hide page section options popover 
    if (PageSectionsOptionsPopover?.showing) store.dispatch(togglePageSectionsOptionsPopover({ showing: false }))
    // website section options popover
    if (WebsiteSectionOptionsPopover?.showing) store.dispatch(toggleWebsiteSectionOptionsPopover({ showing: false }))
    // hide flow options
    if (flow?.ui?.OptionsPopover?.showing) {
        if (isEmpty(flow?.data?.nodes?.filter(d => d._selected))) {
            store.dispatch(hideFlowsOptionsPopover())
        }
    }
};

export const renderInteractionsCanvas = (e, opts) => {

    if (!interactionsCanvas) return;

    opts = opts ? opts : {}

    interactionsCanvas.save();
    interactionsCanvas.clear();

    interactionsCanvas.translate(transform.x, transform.y);
    interactionsCanvas.scale(transform.k, transform.k);

    const sitemap = getSitemap()
    const flow = getUserFlow()

    const { PageButtons } = sitemap?.ui || {};
    const RevisionHistoryDrawer = getRevisionHistoryDrawer()

    const inUserFlow = getInUserFlow()

    if (inUserFlow) {
        const canEdit = getCanEditInEditor()
        const { MultiSelectBox } = flow.utils;
        if (MultiSelectBox.showing) {
            if (canEdit) return renderMultiSelectBox(interactionsCanvas, e)
        } else {
            return mouseoverUserFlowSymbol(interactionsCanvas, e);
        }
    }

    if (sitemap?.format !== 'nodes') {

        if (!RevisionHistoryDrawer.showing) {

            if (dragging && dragging.started) {
                renderDrag(dragging);
                return interactionsCanvas.restore();
            };

        }

        // render page buttons
        if (PageButtons?.showing || mouseover.node) {
            renderOptions(PageButtons?.node || mouseover?.node);
            renderHeader(interactionsCanvas, PageButtons?.node || mouseover?.node);
        }

    }

    if (sitemap?.format === 'nodes') {
        if (mouseover.node) renderNodesTooltip(interactionsCanvas, mouseover.node);
    }

    interactionsCanvas.restore();

};