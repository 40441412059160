import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { useHasLoadedFiles, useInHomeScreen } from "../../../../hooks"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"
import { TemplatesGrid } from "../Grid";

const PX = 2;
const PB = 2;
const MR = 5;

const tabs = [
    // { id: 'all', title: 'All' },
    { id: 'sitemaps', title: 'Sitemaps' },
    { id: 'user-flows', title: 'User Flows' },
    // { id: 'customer journeys' }
]

export const Sections = () => {
    const hasLoadedFiles = useHasLoadedFiles()
    const inHomeScreen = useInHomeScreen()
    return (
        <Tabs isLazy>
            {!inHomeScreen && (<TabList borderColor="rarchy-border" borderBottomWidth={!hasLoadedFiles ? 0 : 1}>
                {tabs?.map(tab => {
                    return (
                        <Tab key={tab?.id} fontWeight="medium" px={PX} pb={PB} mr={MR} borderBottomWidth={hasLoadedFiles ? 1 : 0} mb="-1px">
                            <SkeletonWrapper w="fit-content">
                                {tab?.title}
                            </SkeletonWrapper>
                        </Tab>
                    )
                })}
            </TabList>
            )}
            <TabPanels>
                {tabs?.map((tab) => {
                    const isAll = tab.id === 'all'
                    return (
                        <TabPanel
                            key={tab.id}
                            px={0}
                            py={!inHomeScreen ? 6 : 0}
                        >
                            {/* isAll && tabs.map(section => <Section key={section?.id} section={section} />) */}
                            {!isAll && <TemplatesGrid collection={tab?.id} />}

                        </TabPanel>
                    )
                })}
            </TabPanels>
        </Tabs>
    )
}