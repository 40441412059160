import {
    Button,
    Center,
    Kbd,
    Skeleton,
    Stack
} from "@chakra-ui/react"
import { getInSitemap, getInUserFlow } from '../../../../helpers';
import { useHasEditorLoaded, useHasLoadedFiles, useInEditor } from '../../../../hooks';

import React, { } from 'react';
import { SearchIcon } from '@chakra-ui/icons'

const HEIGHT = 40;

export const SearchButton = ({ onOpen, buttonRef, numberOfItems, minW, maxW }) => {
    const inEditor = useInEditor()
    const hasEditorLoaded = useHasEditorLoaded()
    const hasLoadedFiles = useHasLoadedFiles()
    const isLoaded = inEditor ? hasEditorLoaded : hasLoadedFiles
    return (
        <Button
            ref={buttonRef}
            justifyContent="space-between"
            fontWeight="400"
            w={[
                'sm', // 0-30em
                'md', // 30em-48em
                'lg', // 48em-62em
                'lg', // 62em+
            ]}
            minW={minW}
            maxW={maxW || "unset"}
            h={`${HEIGHT}px`}
            variant="outline"
            color="fg.subtle"
            _hover={{ /* bg: "rarchy-bg-subtle-hover-200", */ cursor: 'text' }}
            borderColor="rarchy-border-sitemap-editor"
            size="md"
            fontSize="md"
            rightIcon={isLoaded && (
                <Stack direction="row" spacing={1}>
                    <Kbd rounded="sm" color="gray.500">⌘</Kbd>
                    <Kbd rounded="sm" color="gray.500">K</Kbd>
                </Stack>
            )}
            onClick={onOpen}
        >
            <Skeleton isLoaded={isLoaded} w="fit-content" rounded="md">
                <Center pointerEvents="none"><SearchIcon mr={3} />{getPlaceholder(numberOfItems)}</Center>
            </Skeleton>
        </Button>
    )
}

export const getPlaceholder = (numberOfItems) => {
    const inSitemap = getInSitemap()
    const inUserFlow = getInUserFlow()
    return `Search ${numberOfItems.toLocaleString()} ${inSitemap ? 'pages' : inUserFlow ? 'elements' : 'items'}...`
}