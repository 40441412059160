import { Center, Icon, Image, useColorMode } from "@chakra-ui/react"
import { collection, doc, getFirestore, updateDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useInProjectsGridView, usePrevious } from "../../../hooks";
import { useLayoutEffect, useState } from "react";

import { RiFlowChart } from "react-icons/ri";
import { TbSitemap } from "react-icons/tb";
import ThumbnailDark from "../../../images/Thumbnail-dark.jpg";
import ThumbnailLight from "../../../images/Thumbnail.jpg";
import { getUserId } from "../../../helpers";

export const FileThumbnail = ({ file, forRecent }) => {

    const { colorMode } = useColorMode()
    const isUserFlow = file.collection === 'user-flows'

    const getThumbnail = () => file?.thumbnail?.images?.[colorMode] || (isUserFlow ? null : (colorMode === "light" ? ThumbnailLight : ThumbnailDark))

    const [src, setSrc] = useState(getThumbnail())
    const [imgError, setImgError] = useState(false) // stops infinite loop if refreshing src in thumbnail causes another error

    const inGridView = useInProjectsGridView()

    // color mode changes
    const prevColorMode = usePrevious(colorMode)
    useLayoutEffect(() => {
        if (colorMode !== prevColorMode) setSrc(getThumbnail()) // reset src
    }, [colorMode, prevColorMode])

    // user flow
    if (isUserFlow) return <Center h="inherit"><Icon as={RiFlowChart} fontSize={(inGridView || forRecent) ? "6xl" : "3xl"} color="rarchy-badge-icon"></Icon></Center>
    
    // continue

    const handleError = async (e) => {
        if (!imgError) {
            setImgError(true)
            const thumbnail = file?.thumbnail?.images?.[colorMode]
            if (thumbnail) {
                const downloadURL = await getDownloadURL(ref(getStorage(), `sitemaps/${file?.id}/thumbnails/${colorMode}.jpg`))
                if (downloadURL) {
                    setSrc(downloadURL)
                    if (downloadURL !== thumbnail) {
                        console.log('fixing 403 thumbnail error')
                        const collectionRef = collection(getFirestore(), 'sitemaps')
                        const docRef = doc(collectionRef, file?.id)
                        await updateDoc(docRef, {
                            [`thumbnail.images.${colorMode}`]: downloadURL,
                            updatedBy: getUserId(),
                            updatedAt: new Date()
                        })
                        setImgError(false)
                    }
                }
            }
        }
    }

    return (
        <Image
            loading="lazy"
            height={inGridView ? "inherit" : "unset"}
            objectFit="contain"
            onError={handleError}
            src={src}
            bg="rarchy-bg-sitemap-editor"
            fallbackStrategy="onError"
            fallback={<Center h="inherit"><Icon as={TbSitemap} fontSize={(inGridView || forRecent) ? "6xl" : "3xl"} color="rarchy-badge-icon"></Icon></Center>}
        />
    )
}