import {
    Box,
    HStack,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tooltip,
    useColorModeValue as mode,
    useDisclosure
} from "@chakra-ui/react"
import React, { useEffect, useState } from 'react'
import { defaultPalletteColors, getDefaultPalletteHeader, getInUserSitemap, getSitemap } from '../../../../../../../helpers'
import { getPageColor, getPageId } from '../../../utils/helpers'

import { ArrowBackIcon } from '@chakra-ui/icons'
import { ColorsLabels } from '../../../../../../Editor/Toolbar/Colors/Components/ColorsLabels'
import { Pallette } from "../../../../../../Editor/Toolbar/Colors/Components/Pallette"
import { ResetButton } from '../../../../../../Editor/Toolbar/Colors/Components/ResetButton'
import { RiPaintFill } from 'react-icons/ri'
import { addPageSectionChange } from '../../../../../../../store/actions/sitemap-actions'
import { changePageSectionColor } from '../../../utils/page-sections/color'
import { find } from 'lodash'
import { sendDataLayerEventForPageSections } from '.'
import { toggleUpgradeModal } from '../../../../../../../store/actions/ui-actions'
import { useDispatch } from 'react-redux'
import { usePrevious } from '../../../../../../../hooks'
import { useRef } from 'react'
import validateColor from 'validate-color'

export const Color = ({ node, section }) => {

    const sitemap = getSitemap()
    const prevSitemap = usePrevious(sitemap)

    const [sectionOpts, setSectionOpts] = useState({})

    const { isOpen, onToggle, onClose } = useDisclosure()

    const dispatch = useDispatch()

    const nodeId = getPageId(node);
    const nodeFromSitemapDocData = sitemap?.docs.pages[nodeId];
    const sectionId = sitemap?.ui?.PageSectionsOptionsPopover?.section?.id
    const sectionFromSitemapData = sitemap?.data?.page_sections?.[nodeId]?.find(s => s.id === sectionId) || null;
    // get updated section data from sitemap data (color attribute is updated here, not in PageSectionsOptionsPopover, so we can determine if color has changed)
    const nodeHasSetColor = nodeFromSitemapDocData?.pallette?.header || null
    const defaultColor = getDefaultPalletteHeader()
    const inputColor = sectionFromSitemapData?.color || nodeHasSetColor || defaultColor;

    const [inputVal, setInputVal] = useState(inputColor)
    const inputValRef = useRef(inputVal);

    useEffect(() => { inputValRef.current = inputVal }, [inputVal]);

    useEffect(() => {
        const { section, node } = sitemap?.ui.PageSectionsOptionsPopover
        setSectionOpts({ nodeWhenOpened: node, sectionWhenOpened: section, inputValue: inputColor })
    }, [])

    useEffect(() => { // update - if moved from one section to another with options popover open
        if (prevSitemap) {
            const sectionId = sitemap?.ui?.PageSectionsOptionsPopover?.section?.id
            const prevSectionId = prevSitemap?.ui?.PageSectionsOptionsPopover?.section?.id
            if (!sectionId || ((sectionId && prevSectionId) && (sectionId !== prevSectionId))) savePageSectionChanges()
        }
    }, [sitemap, prevSitemap, inputVal])

    // unmounting (save)
    useEffect(() => () => savePageSectionChanges(), [])

    if (!sectionFromSitemapData) return null;

    const handlePalletteChange = ({ header: color }) => {

        // should upgrade
        const inUserSitemap = getInUserSitemap()
        if (inUserSitemap) return dispatch(toggleUpgradeModal({ showing: true, screen: 'page-sections-colors' }))

        // continue
        setInputVal(color);
        changePageSectionColor({ node, section, color });
    }

    const savePageSectionChanges = () => {

        // should update
        const inUserSitemap = getInUserSitemap()
        if (inUserSitemap) return // don't save

        const { node, section } = sitemap?.ui.PageSectionsOptionsPopover;
        var color = inputValRef.current;

        // if color selected at close is not a color
        if (!validateColor(color)) {
            const dataFromPageSection = find(sitemap?.data?.page_sections?.[node.id], (s) => s?.id === section?.id);
            if (dataFromPageSection?.color) {
                color = dataFromPageSection.color; // set back to previous color if input isn't a color
            } else {
                return; // don't continue
            }
        }

        // if no previous section color, only update if color doesn't match existing pallette;
        if (section?.color !== color) {
            if (color === getPageColor(node)) {
                if (!section.color) return; // color is pallette header and was not set previously
                color = 'delete'; // delete color if setting back to pallette header color
            }
            // fail-safe for deleting color
            if (defaultPalletteColors.includes(color)) color = 'delete';
            //
            const change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-color', pageId: node.id, section: { id: section.id, color } }],
            };
            const history = {
                action: 'page-section-color',
                data: { pageId: node.id, section: { id: section.id }, newColor: color, oldColor: section.color ? section.color : undefined }
            };
            dispatch(addPageSectionChange({ change, history }));
            sendDataLayerEventForPageSections('Color', color);
        }

    }

    return (
        <Popover
            isLazy
            isOpen={isOpen}
            onClose={onClose}
            closeOnBlur
        >
            <PopoverTrigger>
                <Tooltip variant="rounded-sm" placement='top' openDelay={500} label='Color'>
                    <IconButton size="sm" icon={<RiPaintFill fontSize="16px" color={inputColor} />} variant="ghost" color={mode("gray.600", "whiteAlpha.900")} _hover={{ bgColor: "transparent" }} onClick={() => { savePageSectionChanges(); onToggle(); }} />
                </Tooltip>
            </PopoverTrigger>
            <PopoverContent w="210px" px={0} pt={1} pb={3} onClick={(e) => e.stopPropagation()}>
                <HStack justifyContent="space-between" my={1} ml={2}>
                    <IconButton variant="ghost" size="xs" icon={<ArrowBackIcon fontSize="lg" />} onClick={() => { savePageSectionChanges(); onClose(); }} />
                </HStack>
                <Box px={3}>
                    <Box py={1}>
                        <Pallette selectedColor={inputColor} handlePalletteChange={handlePalletteChange} />
                    </Box>
                    <ResetButton selectedColor={inputColor} mt={2.5} handlePalletteChange={() => handlePalletteChange({ header: getDefaultPalletteHeader() })} />
                </Box>
            </PopoverContent>
        </Popover>
    )
}