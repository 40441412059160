import React, { useEffect, useRef } from 'react';

export let coversCanvas;

const ID = "covers-canvas";

export const CoversCanvas = React.memo(() => {
    const canvasRef = useRef(null)
    useEffect(() => {
        coversCanvas = canvasRef.current.getContext('2d', { alpha: true });
    }, [])
    return <canvas id={ID} ref={canvasRef} />
})