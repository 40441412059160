import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transform } from '../../../canvas/utils/zoom';

import { PageButton } from '../../components/PageButton';
import { RiPaintFill } from 'react-icons/ri';
import { Icon } from '@chakra-ui/react';

export const PageColor = ({ d, showCovers }) => {
/*
    const fontSize = `${(showCovers ? 24 : 24) * transform.k}px`
    return (
        <PageButton
            title="Set Page Color"
            icon={<Icon as={RiPaintFill} />}
            fontSize={fontSize}
            d={d}
            mr={`${(showCovers ? 5 : 5) * transform.k}px`}
            onClick={(e) => {
                e.stopPropagation()
            }}
        />
    ) */
}