import {
    IconButton,
    Stack,
    Text,
    useBoolean,
} from '@chakra-ui/react'
import { compact, isEmpty, uniq } from 'lodash'
import { useGetPeople, useGetUserId } from '../../../../../hooks'

import { CloseIcon } from '@chakra-ui/icons'
import { FolderShareSelect } from './FolderShareSelect'
import { MidHeader } from '../../../../Settings/Components'
import { ShareWithTeam } from './ShareWithTeam'
import { UserAvatarDetail } from '../../../../Components/Shared/UserAvatarDetail'
import { getPeopleData } from '../../../../../helpers'
import { useShareFolderContext } from '../../hooks'

export const ShareFolder = () => {

    const userId = useGetUserId()

    const {
        folder,
        forNew,
        isSelectFocused,
        setIsSelectFocused,
        membersToAdd,
        setMembersToAdd,
        membersToRemove,
        setMembersToRemove
    } = useShareFolderContext()

    const [isPublic, setIsPublic] = useBoolean(folder.members === null || false)

    const owner = useGetPeople()?.byId?.[folder?.createdBy]

    const people = getPeopleData()?.filter(p => peopleFilter({ p, folder, membersToRemove }))

    const showSharedWithTeam = forNew || (isEmpty(membersToAdd) && !isSelectFocused)

    return (
        <Stack py={2} align="center" w="full">
            {forNew && <Text w="full" fontSize="sm" textAlign="left" fontWeight="medium">Share with your team (optional)</Text>}
            <Stack px={1} w="full" spacing={4}>
                {!forNew && <MidHeader title="People with access" />}
                <FolderShareSelect isPublic={isPublic} members={membersToAdd} setMembers={setMembersToAdd} setIsSelectFocused={setIsSelectFocused} />
                <Stack spacing={3} maxH="248px" overflowY="scroll">
                    {showSharedWithTeam && (
                        <>
                            <ShareWithTeam isPublic={isPublic} setIsPublic={setIsPublic} />
                            <Stack mt={-3} direction="row" justify="space-between" pr={1.5} align="center">
                                <UserAvatarDetail user={owner} avatarSize="smd" nameSize="sm" nameWeight="normal" w="200px" />
                                <Text fontSize="sm">Owner</Text>
                            </Stack>
                            {[...people]?.map(p => {
                                const isPersonOwner = p?.id === folder?.createdBy
                                const isUserMember = folder?.members?.includes(p?.id)
                                const isUser = p?.id === userId
                                const showRemoveButton = !isPersonOwner && isUserMember && !isUser
                                return (
                                    <Stack key={p?.id} direction="row" justify="space-between" pr={1.5} align="center">
                                        <UserAvatarDetail user={p} avatarSize="smd" nameSize="sm" nameWeight="normal" w="200px" />
                                        {showRemoveButton && (
                                            <IconButton
                                                variant="ghost"
                                                size="sm"
                                                icon={<CloseIcon fontSize="xs" />}
                                                onClick={() => {
                                                    setMembersToRemove(compact(uniq([...membersToRemove, p?.id])))
                                                }}
                                            />
                                        )}
                                    </Stack>
                                )
                            })}
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

const peopleFilter = ({ p, folder, membersToRemove }) => {
    const isOwner = p.id === folder?.createdBy;
    const includesMember = folder?.members?.includes(p?.id)
    const includedInMembersToRemove = membersToRemove.includes(p?.id)
    return includesMember && !isOwner && !includedInMembersToRemove
}