import { useGetSitemap, useInSitemap } from '../../../../hooks'

import { Box } from "@chakra-ui/react"
import { Menu } from './Components/Menu'
import React from 'react'
import { hideContextMenu } from './helpers'

export const ContextMenu = () => {
    const sitemap = useGetSitemap()
    const inSitemap = useInSitemap()
    const { ContextMenuDropdown } = sitemap?.ui || {}
    if (inSitemap && !ContextMenuDropdown?.showing) return null
    return (
        <Box
            pos="fixed"
            w="full"
            h="full"
            zIndex={2}
            display={ContextMenuDropdown?.showing ? "block" : "none"}
            onClick={() => hideContextMenu()}
        >
            <Menu />
        </Box>
    )
}