import { useState } from "react"
import { useDispatch } from "react-redux"
import { toggleNewFolderModal } from "../../../../../store/actions/folders-actions"
import { SplitModal } from "../../../../Components/Modals/SplitModal"
import { Box, Container, FormControl, FormLabel, Heading, Image, Input, Stack, useColorModeValue as mode } from "@chakra-ui/react"
import { useGetFolderById, useGetFolderIdFromPath, useGetUserId } from "../../../../../hooks"
import { SaveButton } from "./Save"
import { ShareFolder } from "../Share"
import { ShareFolderHOC } from "../Share/ShareFolderHOC"
import { CheckIcon } from "@chakra-ui/icons"

const FONT_SIZE = "sm"
const FONT_WEIGHT = "medium"
const MB = 2

export const NewFolderModalContainer = () => {

    const userId = useGetUserId()

    const dispatch = useDispatch()

    const parentFolderId = useGetFolderIdFromPath()
    const parentFolder = useGetFolderById(parentFolderId)

    // if creating a folder in the top level, default to private. Otherwise, use parent folder members as default
    const parentFolderMembers = parentFolder ? parentFolder?.members : [userId]

    const [name, setName] = useState(null)

    return (
        <SplitModal
            isCentered
            isOpen
            onClose={() => dispatch(toggleNewFolderModal())}
            size="4xl"
            h="xl"
            left={
                <ShareFolderHOC folder={{ members: parentFolderMembers, createdBy: userId, team: parentFolderId }} forNew>
                    {({ membersToAdd, membersToRemove }) => (
                        <Stack p={9} px={7} w="400px">
                            <Heading fontWeight="semibold" fontSize="2xl">
                                Create a folder
                            </Heading>
                            <Stack mt={4} spacing={8}>
                                <FormControl>
                                    <FormLabel fontSize={FONT_SIZE} fontWeight={FONT_WEIGHT} mb={MB}>Folder name</FormLabel>
                                    <Input autoFocus fontSize={FONT_SIZE} placeholder="Name your folder" onChange={(e) => { setName(e.target.value) }} />
                                </FormControl>
                                <ShareFolder />
                            </Stack>
                            <SaveButton name={name} membersToAdd={membersToAdd} membersToRemove={membersToRemove} parentFolderId={parentFolderId} />
                        </Stack>
                    )}
                </ShareFolderHOC>
            }
            right={
                <Box bgColor={mode("telegram.50", "telegram.800")} w="full">
                    <Box p={12} pt={16} overflow="hidden">
                        <Stack spacing={3}>
                            <ChecklistItem>
                                Make projects private when needed
                            </ChecklistItem>
                            <ChecklistItem>
                                Easily organise your sitemaps and user flows
                            </ChecklistItem>
                            <ChecklistItem>
                                Keep team projects streamlined and easy to find
                            </ChecklistItem>
                        </Stack>
                    </Box>
                    <Container overflow="hidden">
                        <Image pos="absolute" w="300px" top="220px" left="430px" src="https://help.rarchy.com/hubfs/NewFolder2.png" />
                        <Image pos="absolute" w="285px" bottom="-3px" right="0px" src="https://help.rarchy.com/hubfs/NewFolder1.png" />
                    </Container>
                </Box>
            }
        />
    )
}

const ChecklistItem = ({ children }) => <Box fontSize="15px" fontWeight="normal" color="fg.default"><CheckIcon fontSize="md" mr={4} color="rarchy-green" />{children}</Box>