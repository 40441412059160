import { AbsoluteCenter, Box, Button, Card, Center, Drawer, DrawerBody, DrawerContent, DrawerHeader, Flex, HStack, Heading, IconButton, Image, Link, List, ListItem, Spacer, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import { BsDisplay, BsPhone } from "react-icons/bs"
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import { GetCovers, HandleArrowPresses, HandleFullscreen } from "./helpers"
import { MdHeight, MdMobileOff, MdOutlineFitScreen, MdOutlineWidthWide } from "react-icons/md"
import { getURL, hideFullscreenCoverDrawer, switchCoversDevice, togglePageDrawer } from "../../../../../store/actions/sitemap-actions"
import { useEffect, useRef, useState } from "react"
import { useGetFullscreenCoverDrawer, useGetSitemap, useGetUserFlow, useInUserFlow } from "../../../../../hooks"

import { BiFullscreen } from "react-icons/bi"
import { Export } from "./Export"
import { ToggleButton } from "../../../../Editor/Toolbar/Components/ToggleButton/Button"
import { ToggleButtonGroup } from "../../../../Editor/Toolbar/Components/ToggleButton/ButtonGroup"
import { getNodeById } from "../../../utils/app"
import { useDispatch } from "react-redux"

const SIDES_WIDTH = 0 // 150;
const SIDEBAR_WIDTH = 200;

export const CoversGallery = () => {
    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const inUserFlow = useInUserFlow()
    const FullscreenCoverDrawer = useGetFullscreenCoverDrawer()

    const [device, setDevice] = useState(FullscreenCoverDrawer?.device || sitemap?.covers.device);
    const [fullscreen, setFullscreen] = useState(false)
    const [showNavbar, setShowNavbar] = useState(true)
    const [showSidebar, setShowSidebar] = useState(inUserFlow ? false : true)
    const [fit, setFit] = useState('full');
    const [covers, setCovers] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectedRef = useRef(null);
    const handleScroll = (behaviour) => setTimeout(() => { selectedRef?.current?.scrollIntoView({ behavior: behaviour || 'smooth', block: 'center' }); }, 50);
    HandleArrowPresses({ selectedIndex, setSelectedIndex, selectedRef, covers, handleScroll })
    // component will unmount
    useEffect(() => { return () => { setShowNavbar(true); } }, []);
    //
    HandleFullscreen({ fullscreen, showNavbar, setShowNavbar })
    const pageId = covers?.data?.[selectedIndex]?.page
    const pageData = sitemap?.docs?.pages[pageId];
    const pageURL = getURL(pageId);
    const cover = covers?.data?.[selectedIndex]
    const HEIGHT = `calc(100vh - ${!fullscreen ? 73 : 0}px)`
    GetCovers({ setCovers, setSelectedIndex, handleScroll })
    return (
        <Drawer size="full" isOpen onClose={() => dispatch(hideFullscreenCoverDrawer())} placement="bottom">
            <DrawerContent>
                <DrawerBody p={0}>
                    <Stack direction="row" h="full" spacing={0}>
                        {!fullscreen && showSidebar && (
                            <Flex
                                w={`${SIDEBAR_WIDTH}px`} h="100vh" fontSize="sm" bgColor="rarchy-bg-sitemap-editor"
                                direction="column"
                                borderRightWidth="1px"
                            >
                                <VStack w="full">
                                    <Box h="100vh" overflow="scroll" px={4}>
                                        <List>
                                            {covers?.data?.map((c, i) => {
                                                const isSelected = selectedIndex === i;
                                                return (
                                                    <ListItem key={c.page} ref={isSelected ? selectedRef : null}>
                                                        <Card my={4} pb={1.5} _hover={{ cursor: 'pointer' }} border={`1.5px solid`} bg={isSelected ? "rarchy-link" : "rarchy-bg-white"} borderColor={isSelected ? "rarchy-link" : "transparent"} onClick={() => setSelectedIndex(i)}>
                                                            <VStack spacing={1.5}>
                                                                <Image roundedTop="md" src={c.thumbnail} loading="lazy" borderBottom="1px solid" color="rarchy-bg-subtle-hover" />
                                                                <Text fontSize="xs" fontWeight="medium" px={3} color={isSelected ? "white" : "fg.muted"}>{c?.originalTitle}</Text>
                                                            </VStack>
                                                        </Card>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Box>
                                </VStack>
                            </Flex>
                        )}
                        <Flex w="full" h="100vh">
                            <VStack w="full" spacing={0}>
                                {showNavbar && (
                                    <DrawerHeader w="full" borderBottomWidth='1px' py={0} pos={fullscreen ? "absolute" : "unset"} left={0} background="rarchy-bg-sitemap-editor">
                                        <Flex h="72px">
                                            <Stack direction="row" spacing={5} align="center">
                                                {!inUserFlow && (
                                                    <IconButton size="md" icon={<HamburgerIcon fontSize="xl" />} variant="ghost" onClick={() => { setShowSidebar(!showSidebar); setFullscreen(false); }} />
                                                )}
                                                <Center h="72px">
                                                    <ToggleButtonGroup
                                                        size="sm"
                                                        value={fit}
                                                        isAttached
                                                        variant="outline"
                                                        isRadio
                                                        mr={5}
                                                        onChange={(d) => setFit(d)}
                                                    >
                                                        <ToggleButton value="full" icon={<MdOutlineFitScreen fontSize="20px" />} />
                                                        <ToggleButton value="wide" icon={<MdOutlineWidthWide fontSize="20px" />} />
                                                        <ToggleButton value="height" icon={<MdHeight fontSize="20px" />} />
                                                    </ToggleButtonGroup>
                                                    {!inUserFlow && (
                                                        <ToggleButtonGroup
                                                            size="sm"
                                                            value={device}
                                                            isAttached
                                                            variant="outline"
                                                            isRadio
                                                            onChange={(d) => setDevice(d)}
                                                        >
                                                            <ToggleButton value="desktop" icon={<BsDisplay fontSize="17px" />} />
                                                            <ToggleButton value="mobile" icon={<BsPhone fontSize="17px" />} />
                                                        </ToggleButtonGroup>
                                                    )}
                                                </Center>
                                            </Stack>
                                            <Spacer minW="75px" />
                                            <Center>
                                                <VStack spacing={1}>
                                                    <Text fontSize="md" noOfLines={1}>{pageData?.name}</Text>
                                                    <Link href={pageURL} fontSize="sm" color="rarchy-link" isExternal>
                                                        <Text noOfLines={1}>{pageURL}</Text>
                                                    </Link>
                                                </VStack>
                                            </Center>
                                            <Spacer minW="75px" />
                                            <HStack spacing={4}>
                                                {!inUserFlow && <Export />}
                                                <IconButton size="md" fontSize="lg" variant="ghost" icon={<BiFullscreen />} onClick={() => {
                                                    setFullscreen(!fullscreen);
                                                    setShowNavbar(fullscreen);
                                                    setShowSidebar(fullscreen);
                                                }} />
                                                <IconButton size="md" fontSize="sm" variant="ghost" icon={<CloseIcon />} onClick={() => dispatch(hideFullscreenCoverDrawer())} />
                                            </HStack>
                                        </Flex>
                                    </DrawerHeader>
                                )}
                                <Stack direction="row" spacing={0} w="full" h={HEIGHT} overflow="scroll" bgColor="rarchy-bg-sitemap-editor-subtle">
                                    <Center w={`${SIDES_WIDTH}px`} h={HEIGHT} display={SIDES_WIDTH === 0 ? "none" : "flex"}>
                                        <IconButton h={HEIGHT} variant="unstyled" fontSize="8xl" isDisabled icon={
                                            <ChevronLeftIcon />}
                                        />
                                    </Center>
                                    <Center w="full">
                                        {!cover && (
                                            <HStack align="start" spacing={9}>
                                                <Box color="rarchy-border">
                                                    <MdMobileOff fontSize="120px" />
                                                </Box>
                                                <VStack mt={10} spacing={6} align="center">
                                                    <Text fontSize="md" color="fg.muted">{`A cover for this page isn't set for the ${device} viewport yet...`}</Text>
                                                    {/* <HStack spacing={3}>
                                                        <Button variant="link" color="rarchy-link" size="lg" onClick={() => {
                                                            const node = getNodeById(sitemap?.data?.root, pageId);
                                                            if (node) {
                                                                dispatch(switchCoversDevice(device));
                                                                dispatch(togglePageDrawer({ node }))
                                                            }
                                                        }}>Capture a screenshot</Button><Text>or</Text>
                                                        <Button variant="link" color="rarchy-link" size="lg">Upload your own image</Button>
                                                    </HStack> */}
                                                </VStack>
                                            </HStack>
                                        )}
                                        {cover && (
                                            <Box w={fit === "full" ? `calc(100% - ${SIDES_WIDTH * 2}px)` : fit === "wide" ? "75%" : "unset"}>
                                                <Box w="full" h={HEIGHT}>
                                                    <Image
                                                        w="full"
                                                        src={cover?.original}
                                                        objectFit="contain"
                                                        maxH={fit === "height" ? HEIGHT : "unset"}
                                                        fallbackStrategy="onError"
                                                        fallback={
                                                            <AbsoluteCenter ml={showSidebar ? SIDEBAR_WIDTH / 2 : 0}>
                                                                <Spinner size="xl" />
                                                            </AbsoluteCenter>}
                                                    />
                                                </Box>
                                            </Box>
                                        )}

                                    </Center>
                                    <Center w={`${SIDES_WIDTH}px`} h={HEIGHT} display={SIDES_WIDTH === 0 ? "none" : "flex"}>
                                        <IconButton h={HEIGHT} variant="unstyled" fontSize="8xl" icon={
                                            <ChevronRightIcon />}
                                        />
                                    </Center>
                                </Stack>
                            </VStack>
                        </Flex>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}