import {
    IconButton,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { getCustomCover, getPageColor } from '../canvas/utils/helpers'

import { FaRegImages } from 'react-icons/fa';
import React from 'react';
import { getPalletteColors } from '../../../../store/actions/editor-actions';
import { showFullscreenCoverDrawer } from '../../../../store/actions/sitemap-actions';
import { transform } from '../canvas/utils/zoom';
import { useDispatch } from 'react-redux';
import { useInUserFlow } from '../../../../hooks';

export const Fullscreen = ({ d, x, y }) => {
    const dispatch = useDispatch()
    const inUserFlow = useInUserFlow()
    // continue
    const image = getCustomCover(d);
    const color = getPageColor(d)
    const pallette = getPalletteColors(color)
    const bgColor = mode(pallette[0], "whiteAlpha.200")
    if (!image) return null
    const fontSize = `${transform.k * 48}px`
    const left = x + (175.5 * transform.k) - (58 * transform.k), top = (y + (102.5 * transform.k))
    return (
        <Tooltip variant="rounded-sm" label="Open Gallery" placement="bottom" hasArrow openDelay={1000}>
            <IconButton
                icon={<FaRegImages />}
                fontSize={fontSize}
                h={`${50 * transform.k}px`}
                p={`${9 * transform.k}px`}
                minW={0}
                left={left}
                top={top}
                variant="ghost"
                pos="absolute"
                color={color}
                _hover={{ bgColor }}
                onClick={(e) => {
                    e.stopPropagation()
                    dispatch(showFullscreenCoverDrawer({ page: d?.[!inUserFlow ? 'id' : 'page'], device: d.device }))
                }}
            />
        </Tooltip>
    )
}