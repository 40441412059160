import { store } from '../../../../../store';
import { addFlowChange, hideFlowsOptionsPopover, toggleUserFlowSymbolButtons } from "../../../../../store/actions/flow-actions";
import { togglePageButtons } from '../../../../../store/actions/sitemap-actions';
import copy from 'fast-copy';
import { addSymbolChange } from '../../helpers';
import { isEmpty, compact, keys, cloneDeep } from 'lodash'
import { chain } from '../../../../../helpers/chain';

const dispatch = (f) => store.dispatch(f)

export const handleDeleteUserFlowSymbol = (nodes) => {

    if (!Array.isArray(nodes)) nodes = [nodes]; // fail-safe

    if (isEmpty(nodes)) return;

    var dataForRendering = {};

    const obj = [];

    const history = [];

    const nodeIdsToDelete = [...nodes.map(n => n.id)];

    nodes.forEach(node => {

        const oldSymbol = copy(node);

        /*** check if any connectors are linking to this symbol to delete ***/
        const { firestoreData, oldConnectorsData } = checkForLinkedConnectors(node);
        /*** check if any connectors are linking to this symbol to delete ***/

        // remove node completely
        dataForRendering[node.id] = undefined

        // there are linked connectors - delete
        if (!isEmpty(firestoreData)) {
            Object.keys(firestoreData).forEach(id => {
                const connectorDataThatIsLinkedToThisNodeThatWillBeDeleted = firestoreData[id];
                if (!nodeIdsToDelete.includes(id)) dataForRendering[id] = connectorDataThatIsLinkedToThisNodeThatWillBeDeleted // don't add connector data if node has already been set to delete
            })
        };

        /*** add linked connectors data for firestore ***/
        obj.push({ action: 'delete-symbol', id: node.id });
        Object.keys(firestoreData).forEach(id => obj.push({ action: 'delete-connector', id, ...firestoreData[id] }));
        /*** add linked connectors data for firestore ***/

        history.push({
            action: 'delete-symbol',
            node: node.id,
            data: { oldSymbol, oldConnectors: oldConnectorsData, newSymbol: dataForRendering }
        });

    });

    /*** dispatch edit so we can render to user immediately ***/
    dispatch(togglePageButtons({ showing: false }))
    dispatch(toggleUserFlowSymbolButtons({ showing: false }))
    addSymbolChange({ data: dataForRendering });
    /*** dispatch edit so we can render to user immediately ***/

    const change = { id: new Date().getTime(), data: obj };

    dispatch(addFlowChange({ change, history }));

    // hide flows options popover if showing
    dispatch(hideFlowsOptionsPopover());
}

const checkForLinkedConnectors = (nodeToBeDeleted) => {

    var firestoreData = {}
    var oldConnectorsData = {};

    const { flow } = store.getState();
    const { data } = flow;

    /* eslint-disable-next-line */
    const nodes = [...compact(chain(data).map((obj, id) => { if (obj) return { id, ...obj } }).value())];
    nodes.forEach(node => {
        if (!node) return;
        const { connectors } = node;
        if (connectors) {
            const positions = keys(connectors);
            if (positions) {
                oldConnectorsData[node.id] = { connectors: cloneDeep(connectors) }
                let isConnected = false;
                positions.forEach(pos => {
                    var position = connectors[pos];
                    if (isEmpty(position)) return;
                    keys(position).forEach(id => {
                        if (id === nodeToBeDeleted.id) {
                            isConnected = true;
                            // setup
                            if (!firestoreData[node.id]) firestoreData[node.id] = { connectors: { [pos]: position } };
                            // set connector to be deleted as undefined
                            firestoreData[node.id].connectors[pos][nodeToBeDeleted.id] = undefined;
                        }
                    })
                })
                if (!isConnected) delete oldConnectorsData[node.id];
            }
        }
    });
    return { firestoreData, oldConnectorsData };
}