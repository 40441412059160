import { Box, Center, Icon, Stack, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { toggleNewUserFlowModal } from "../../../../store/actions/ui-actions";
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper";

export const EmptyUserFlows = () => {
    const dispatch = useDispatch()
    return (
        <SkeletonWrapper w="fit-content">
            <Box
                mt={3}
                w="255px"
                h="215px"
                rounded="md"
                p={3}
                bg="rarchy-color-file"
                // p={2.5}
                // bgImage="linear-gradient(90deg, rgba(0,167,225,1) 0%, rgba(46,217,195,1) 100%) 1"
                borderColor="rarchy-border"
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                    dispatch(toggleNewUserFlowModal({ showing: true }))
                }}
            >
                <Center
                    border="1px solid"
                    borderColor="rarchy-border"
                    rounded="md"
                    h="full"
                    bgColor="rarchy-bg-sitemap-editor"
                >
                    <Stack w="full" align="center" spacing={8} justify="space-between">
                        <Icon as={AddIcon} fontSize="4xl" color="fg.muted" />
                        <Text fontSize="sm" fontWeight="medium" color="fg.muted">Create a new user flow</Text>
                    </Stack>
                </Center>
            </Box>
        </SkeletonWrapper>
    )
}