import { useState } from "react"
import {
    Avatar,
    Stack,
    Text,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Icon,
    AvatarBadge
} from "@chakra-ui/react"
import { useGetOrganization, useInOrganization } from "../../../../../hooks"
import { getUsersFullName } from "../../../../../helpers"
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons"
import { HiOutlineOfficeBuilding } from "react-icons/hi"
import { RiUserLine } from "react-icons/ri"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import { IoMdEyeOff } from "react-icons/io"
import { useShareFolderContext } from "../../hooks"

const options = [
    { key: 'public', text: 'Can edit and share', icon: AiOutlineUsergroupAdd },
    { key: 'private', text: 'Not shared', icon: IoMdEyeOff }
]

// FILES NOT IN A FOLDER CAN BE CHANGED BY ANYONE
// FILES IN A FOLDER INHERIT THE FOLDERS SHARE SETTING (USERS CAN ONLY FULLY EDIT FILES THAT ARE IN A PUBLIC FOLDER OR SHARED FOLDER) (Same as Team logic in Rarchy 1)

// SHARED FOLDERS COME UNDERNEATH SHARED WITH YOU

// RENAME
// any member can rename a folder that's public / shared with them

// DELETE
// anyone with access

// MOVE
// anyone with access

// SHARE (PUBLIC -> PRIVATE VV)
// anyone with access

// SHARE (PRIVATE)
// any shared user can add/remove other users
// no-one can remove the owner

// NEW FOLDER
// anyone with access
// if creating a new private folder within a private folder, all users in the parent folder need to be automatically included on save

// REMOVE FROM FOLDER
// any member can remove a folder that's public / shared with them, from a folder, if the folders parent is also shared with them


export const ShareWithTeam = ({ isPublic, setIsPublic }) => {

    const { setMembersToAdd } = useShareFolderContext()

    const inOrganization = useInOrganization()
    const organization = useGetOrganization()

    const [selected, setSelected] = useState(options[isPublic ? 0 : 1])

    const accountName = inOrganization ? organization?.name : getUsersFullName()

    return (
        <Stack direction="row" px={0} align="center" justify="space-between" mb={3}>
            <Stack direction="row" spacing={5} align="center">
                <Avatar w="40px" h="40px" name={accountName}>
                    <AvatarBadge top="12.5px" left="15px" bgColor="rarchy-border-sitemap-editor" p={1} boxSize='1.25em' size="sm">
                        <Icon as={inOrganization ? HiOutlineOfficeBuilding : RiUserLine} fontSize="md" color="rarchy-color-sitemap-editor" />
                    </AvatarBadge>
                </Avatar>
                <Tooltip variant="rounded-sm" label={accountName} openDelay={500} hasArrow>
                    <Text _hover={{ cursor: 'default' }} noOfLines={1} fontSize="sm">{accountName}</Text>
                </Tooltip>
            </Stack>
            <Menu placement="top-end" offset={[-1, -47]} autoSelect={false}>
                <MenuButton
                    as={Button}
                    size="sm"
                    pr={0}
                    variant="ghost"
                    iconSpacing={1}
                    leftIcon={<Icon as={selected?.icon} color="rarchy-color-sitemap-editor" fontSize="2xl" />}
                    rightIcon={<ChevronDownIcon fontSize="xl" />}
                    _hover={{ bgColor: 'transparent' }}
                    _active={{ bgColor: 'transparent' }}
                />
                <MenuList fontSize="sm" minW={-1}>
                    {options.map(option => {
                        return (
                            <Tooltip
                                key={option?.key}
                                variant="rounded-sm"
                                hasArrow
                                openDelay={250}
                            >
                                <MenuItem
                                    icon={<Icon as={option?.icon} fontSize="2xl" color="rarchy-color-sitemap-editor" />}
                                    py={2.5}
                                    command={selected?.key === option?.key ? <CheckIcon /> : null}
                                    onClick={() => {
                                        setSelected(option);
                                        // set public 
                                        if (option.key === "public") {
                                            setIsPublic.on()
                                            setMembersToAdd(null)
                                        }
                                        // set private 
                                        if (option.key === "private") {
                                            setIsPublic.off()
                                            setMembersToAdd([])
                                        }
                                    }}
                                >
                                    {option?.text}
                                </MenuItem>
                            </Tooltip>
                        )
                    })}
                </MenuList>
            </Menu>
        </Stack>
    )
}