import { Box, CloseButton, Container, Progress, Stack } from "@chakra-ui/react"
import { useGetImportingSitemap, useHasProcessedSitemapImport, useIsImportingIntoExistingSitemap, useIsImportingSitemap, useIsSitemapImportPending } from "../../../hooks"
import { useDispatch } from "react-redux"
import { clearProcessedSitemap } from "../../../store/actions/sitemaps-actions"
import { ImportStatus } from "./Shared/Status"

export const Banner = ({ inImport }) => {
    const dispatch = useDispatch()
    const isImportPending = useIsSitemapImportPending()
    const isImporting = useIsImportingSitemap()
    const isImportingIntoExistingSitemap = useIsImportingIntoExistingSitemap()
    const importing = useGetImportingSitemap()
    const hasProcessed = useHasProcessedSitemapImport()
    // don't show for the below
    if (inImport && isImportPending) return null
    if (!isImporting || isImportingIntoExistingSitemap) return null;
    // retrieved
    const { count, limit, total } = importing?.retrieved || {}
    // render
    const progressPercentage = hasProcessed ? 100 : ((count / ((total < limit ? total : limit)) * 100) || 0)
    return (
        <Box mt={inImport ? 0 : 1} w="full" px={!inImport ? 6 : 0}>
            <Container
                py={inImport ? 2.5 : 4}
                px={0}
                maxW="unset"
            >
                <Stack
                    spacing="4"
                    justify={{ base: 'start', md: 'space-between' }}
                    direction={{ base: 'column', md: 'row' }}
                >
                    <ImportStatus inBanner />
                    {!inImport && hasProcessed && (
                        <CloseButton onClick={() => dispatch(clearProcessedSitemap(importing))} />
                    )}
                </Stack>
            </Container>
            <Progress value={progressPercentage} h={0.5} mb={inImport ? 6 : 0} />
        </Box>
    )
}