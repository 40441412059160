import { ActionSVG, DecisionSVG, DocumentSVG, GridIcon, InputOutputSVG, PageSVG, PageSectionSVG, StartEndSVG } from '../../../Toolbar/User-Flows/Grid/icons';
import {
    Box,
    Flex,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    List,
    ListIcon,
    ListItem,
    Skeleton,
    Text,
    Tooltip,
    VStack,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { ImFileEmpty, ImFilesEmpty } from 'react-icons/im'
import React, { useEffect, useState } from 'react';
import { filter, isEmpty } from 'lodash';
import { goToElement, goToPage, populateSearchData } from '../helpers'
import { useGetItems, useGetSitemap, useGetUserFlow, useInEditor, useInSitemap, useInUserFlow } from '../../../../../hooks';

import { FoldersAndFiles } from './FoldersAndFiles';
import { LuListTree } from 'react-icons/lu'
import { SearchIcon } from '@chakra-ui/icons'
import { getDefaultPalletteHeader } from '../../../../../helpers';
import { getPageName } from '../../../../Sitemap/utils/app';
import { getPlaceholder } from '../SearchButton';
import { getURL } from '../../../../../store/actions/sitemap-actions';
import { useCombobox } from 'downshift'
import { useHistory } from 'react-router-dom';

const MIN_INPUT_LENGTH = 2;

export const SearchModal = ({ ref, isOpen, onClose, BCR, numberOfItems, onDrawerOpen, minW }) => {

    const [inputVal, setInputVal] = useState('')
    const [searchData, setSearchData] = useState([])
    const [filteredSearchData, setFilteredSearchData] = useState([])

    const history = useHistory()
    const inEditor = useInEditor()

    const inSitemap = useInSitemap()
    const sitemap = useGetSitemap()

    const inUserFlow = useInUserFlow()
    const flow = useGetUserFlow()

    const foldersAndFiles = useGetItems()

    useEffect(() => {
        return () => {
            // reset once closed
            if (!isEmpty(searchData)) {
                setSearchData([])
                setFilteredSearchData([])
                setInputVal('')
            }
        }
    }, []);

    const { root, section: subfolder } = sitemap?.data || {};
    let nodes = !inUserFlow ? sitemap?.data?.nodes : flow?.data?.nodes

    const bgColor = "rarchy-color-file"
    const textColor = mode('gray.600', 'whiteAlpha.800')
    const shouldShowPages = numberOfItems > 3000 ? inputVal.length > MIN_INPUT_LENGTH : true;
    const showLoading = numberOfItems > 1000;

    const {
        getInputProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
    } = useCombobox({
        defaultIsOpen: true,
        defaultHighlightedIndex: 0,
        inputValue: inputVal,
        onSelectedItemChange: ({ selectedItem: selected }) => {
            // in editor
            if (inEditor) {
                if (!inUserFlow) {
                    goToPage({ selected, nodes, root: subfolder ? subfolder : root, onClose });
                } else {
                    goToElement({ selected, nodes, onClose })
                }
            }
        },
        items: inEditor ? filteredSearchData : foldersAndFiles,
    });

    return (
        <>
            <Box
                ref={ref}
                rounded="lg"
                position="fixed"
                left={BCR.left}
                top={`${BCR.y}px`}
                w={`${BCR.width}px`}
                minW={minW}
                zIndex={2}
                m={0}
                boxShadow={filteredSearchData.length > MIN_INPUT_LENGTH ? "lg" : "none"}
                bgColor="rarchy-bg-white"
                size="xl"
                pb={2}
            >
                <InputGroup paddingInlineStart={4} paddingInlineEnd={4}>
                    <InputLeftElement
                        height={`${BCR.height}px`}
                        left="5px"
                        pointerEvents="none"
                        children={<SearchIcon w="1em" h="1em" color="gray.400" />}
                    />
                    <ComboboxInput
                        {...getInputProps()}
                        w="full"
                        height={`${BCR.height}px`}
                        lineHeight={`${BCR.height}px`}
                        pl="29px"
                        border="none"
                        fontSize="md"
                        placeholder={getPlaceholder(numberOfItems)}
                        onFocus={() => {
                            if (inEditor) {
                                setTimeout(() => {
                                    populateSearchData({ isOpen: true, searchData, setSearchData, filteredSearchData, setFilteredSearchData, shouldShowPages, sitemap, flow: inUserFlow ? flow : null })
                                }, showLoading ? 500 : -1);
                            }
                        }}
                        onChange={(e) => {
                            const inputVal = e.target.value.toLowerCase();
                            if (inEditor) {
                                if (shouldShowPages || (!shouldShowPages && (inputVal.length > MIN_INPUT_LENGTH))) {
                                    setFilteredSearchData(filter(searchData, (o) => o?.name?.toLowerCase()?.includes(inputVal)));
                                } else {
                                    setFilteredSearchData([])
                                }
                            }
                            setInputVal(inputVal)
                        }}
                        _placeholder={{ color: 'gray.400' }}
                        _focus={{ border: 0, boxShadow: 0 }}
                    />
                </InputGroup>
                <ComboboxList
                    isOpen={isOpen}
                    maxH="60vh"
                    overflow="scroll"
                    {...getMenuProps()}
                    px={inEditor ? 4 : 0}
                    py={inEditor ? 1 : 0}
                    margin={0}
                    borderTop="1px"
                    borderColor={bgColor}
                >
                    {!inEditor && (
                        <FoldersAndFiles
                            items={foldersAndFiles}
                            inputVal={inputVal}
                            getItemProps={getItemProps}
                            highlightedIndex={highlightedIndex}
                            bgColor={bgColor}
                            onClose={onClose}
                        />
                    )}
                    {!shouldShowPages ? (
                        <Text fontSize="sm" mt={1} pl={7} color={textColor}>{`Enter ${3 - (inputVal ? inputVal.length : 0)} ${inputVal && !isEmpty(inputVal) ? 'more' : ''} characters to start searching`}</Text>
                    ) : [...filteredSearchData].map((d, i) => {
                        const isActive = i === highlightedIndex;
                        const url = getURL(d.id)
                        return (
                            <ComboboxItem
                                {...getItemProps({ item: d, index: i })}
                                key={i}
                                {...listItemStyles}
                                bgColor={isActive ? bgColor : null}
                            >
                                <RenderIcon d={d} />
                                <Box ml={4} overflow="auto">
                                    {d.hierarchy && <Box fontWeight="medium" fontSize="xs" opacity={0.7}>{d.hierarchy}</Box>}
                                    <Box fontWeight="semibold">{getPageName(d.name) || d.typeLabel}</Box>
                                    {d.type && <Box fontWeight="medium" fontSize="xs" color="fg.subtle">{d.typeLabel}</Box>}
                                    {url && <Box fontSize="xs" color="fg.subtle">{url}</Box>}
                                </Box>
                            </ComboboxItem>
                        )
                    })}
                    {showLoading && shouldShowPages && isEmpty(searchData) ? (
                        <Loading />
                    ) : (shouldShowPages && isEmpty(filteredSearchData) || (!shouldShowPages && inputVal.length > MIN_INPUT_LENGTH)) && (
                        null /*<EmptyText color={textColor} /> */
                    )}
                </ComboboxList>
                {inSitemap && (
                    <Tooltip label="Open Pages List" openDelay={1000} variant="rounded">
                        <IconButton color={textColor} variant="ghost" size="sm" pos="absolute" px={2} top={1.5} right={3} fontSize="xl" icon={<LuListTree />} onClick={() => { onDrawerOpen(); onClose(); }} />
                    </Tooltip>
                )}
            </Box>
        </>
    )
}

const RenderIcon = ({ d }) => {
    const inUserFlow = useInUserFlow()
    // sitemap
    if (!inUserFlow) return <ListIcon as={d.children ? ImFilesEmpty : ImFileEmpty} fontSize="20px" opacity="0.4" />
    // user flow
    const stroke = getDefaultPalletteHeader() // colorMode === 'light' ? "#" : '#D1D2D5';
    const opacity = 0.75;
    let icon = null
    switch (d.type) {
        case 'page':
            icon = PageSVG({ id: d.type, stroke, size: 1.5, opacity })
            break;
        case 'page-sections':
            icon = PageSectionSVG({ id: d.type, stroke, size: 1.5, opacity })
            break;
        case 'action':
            icon = ActionSVG({ id: d.type, stroke, size: 1.8, opacity })
            break;
        case 'document':
            icon = DocumentSVG({ id: d.type, stroke, size: 1.8, opacity })
            break;
        case 'startEnd':
            icon = StartEndSVG({ id: d.type, stroke, size: 1.8, opacity })
            break;
        case 'decision':
            icon = DecisionSVG({ id: d.type, stroke, size: 1.8, opacity })
            break;
        case 'inputOutput':
            icon = InputOutputSVG({ id: d.type, stroke, size: 1.8, opacity })
            break;
        default:
            return ImFilesEmpty
    }
    return <Flex minW="45px" ml={d?.type?.startsWith("page") ? -2 : -1} justify="center">{icon}</Flex>
}

export const listItemStyles = { cursor: 'pointer', display: 'flex', minH: 16, mt: 2, paddingInlineStart: 4, paddingInlineEnd: 4, py: 2, rounded: 'lg', alignItems: 'center' }

const Loading = () => {
    return <VStack w="full" my={6} ml={4} spacing={10}>
        {Array.from(Array(9).keys()).map((i) => {
            return (
                <HStack alignItems="flex-start" spacing={6} w="full" key={i}>
                    <Skeleton w={6} h={6} mt={1} />
                    <VStack w="full" alignItems="flex-start">
                        <Skeleton h={2} w="25%" />
                        <Skeleton h={4} w="50%" />
                    </VStack>
                </HStack>
            )
        })}
    </VStack>
}

const EmptyText = ({ color }) => {
    return <Text fontSize="sm" mt={1} pl={7} color={color}>No pages found...</Text>
}

const ComboboxInput = React.forwardRef(({ ...props }, ref) => {
    return <Input {...props} ref={ref} />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }, ref) => {
    return <List py={2} {...props} ref={ref} />;
});

export const ComboboxItem = React.forwardRef(
    ({ ...props }, ref) => {
        return (
            <ListItem transition="background-color 5ms, color 5ms" {...props} ref={ref} />
        );
    }
);