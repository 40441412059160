import { useRef } from "react"
import { FormControl, FormHelperText, FormLabel, IconButton, Input, InputGroup, InputRightElement, useDisclosure } from "@chakra-ui/react"
import { HiEye, HiEyeOff } from "react-icons/hi"

export const PasswordField = ({ forLogin, forReset, errors, handleChange, handleBlur }) => {
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = useRef(null)
    const onClickReveal = () => {
        onToggle()
        if (inputRef.current) {
            const textInput = inputRef.current;
            const len = textInput.value.length;
            setTimeout(() => {
                textInput.setSelectionRange(len, len);
                textInput.focus({ preventScroll: true })
            }, 5);
        }
    }
    return (
        <FormControl id="password">
            <FormLabel htmlFor="password" fontSize={!forReset ? "md" : "sm"}>{!forReset ? "Password" : "New password"}</FormLabel>
            <InputGroup>
                <InputRightElement>
                    <IconButton
                        variant="text"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
                <Input
                    id="password"
                    ref={inputRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    isRequired
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="********"
                />
            </InputGroup>
            {!forLogin && (
                <FormHelperText mt={4} fontSize="xs" color={errors?.password ? "rarchy-red" : "fg.muted"}>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                </FormHelperText>
            )}
        </FormControl>
    )
}