import { Box, Button, Flex, Stack } from "@chakra-ui/react"
import { toggleNewSitemapModal } from "../../../../store/actions/ui-actions"
import { CloseIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"

export const Container = ({ children }) => {
    return (
        <Flex w="full" bgColor="rarchy-bg-subtle" justifyContent="center" pb={12}>
            <Box w="5xl" justifyContent="center" mt={4}>
                {children}
            </Box>
        </Flex>
    )
}

export const Close = ({ children }) => {
    const dispatch = useDispatch()
    return (
        <Stack w="full" alignItems="start" justifyContent="end" spacing={0} mb={3}>
            <Stack w="full" alignItems="end">
                <Button color="fg.muted" variant="ghost" fontWeight="medium" rightIcon={<CloseIcon />} onClick={() => dispatch(toggleNewSitemapModal())}>Close</Button>
            </Stack>
            {children}
        </Stack>
    )
}