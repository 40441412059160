import { Box, DarkMode, Heading, Stack } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../../hooks"
import { toTitleCase } from "../../../../helpers"

export const RightPeople = () => {
    const { planId } = useGetUpgradeModal()
    return (
        <Box w="full" h="full" bgColor="#283544" p={6} py={12} align="center">
            <DarkMode>
                <Stack spacing={16} align="center">
                    <Box>
                        <Box
                            as='video'
                            w="300px"
                            autoPlay
                            muted
                            priority="true"
                            preload="true"
                            loop
                            controls={false}
                            src={"https://help.rarchy.com/hubfs/Invite%20People%20-%20Dark.mp4"}
                        />
                    </Box>
                    <Stack spacing={6} px={20}>
                        <Heading fontSize="3xl" color="fg.default">{`Get the team together with Rarchy ${toTitleCase(planId)}`}</Heading>
                    </Stack>
                </Stack>
            </DarkMode>
        </Box>
    )
}