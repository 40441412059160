import {
    Button,
    Flex,
    HStack,
    Heading,
    IconButton,
    ModalHeader,
    Text
} from "@chakra-ui/react"
import { initSitemapSectionsDoc, setRoot } from '../../../../../../../../../store/actions/sitemap-actions'
import { restoreRevisionHistoryDrawerChanges, toggleRevisionHistoryDrawer } from '../../../../../../../../../store/actions/editor-actions'
import { useCanEditFile, useGetEditorDocIdFromPath, useGetRevisionHistoryDrawer, useGetSitemap, useGetUser, useInSitemap, useInUserFlow } from '../../../../../../../../../hooks'

import { ArrowBackIcon } from '@chakra-ui/icons'
import React from 'react'
import { chain } from '../../../../../../../../../helpers/chain'
import { createRoot } from '../../../../../../../../Sitemap/utils/app'
import dayjs from 'dayjs';
import { toggleUpgradeModal } from '../../../../../../../../../store/actions/ui-actions'
import { useDispatch } from 'react-redux'

export const Header = () => {
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    const inUserFlow = useInUserFlow()
    const docId = inUserFlow ? "elements" : "pages";
    return (
        <ModalHeader borderColor="rarchy-border-sitemap-editor" p={0} minH={8}>
            <Flex justifyContent="space-between" pl={1}>
                <HStack w="calc(100% - 300px)" justifyContent="space-between">
                    <HStack px={4} py="13.5px" spacing={3}>
                        <BackButton mr={5} />
                        <Title ml={5} />
                        <CurrentVersion />
                        <RestoreButton />
                    </HStack>
                    {!RevisionHistoryDrawer.retrieving && !RevisionHistoryDrawer.error && <Text fontSize="sm" fontWeight="normal" pr={6} color="fg.muted">{Object.keys(RevisionHistoryDrawer?.docs?.[docId] || {}).length.toLocaleString()} {docId}</Text>}
                </HStack>
                <Flex px={5} py={4} w="300px" borderLeftWidth="1px" borderColor="rarchy-border-sitemap-editor" align="center">
                    <Heading as='h3' size='sm' fontWeight="medium">Revision History</Heading>
                </Flex>
            </Flex>
        </ModalHeader>
    )
}

const BackButton = () => {
    const dispatch = useDispatch()
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    const sitemap = useGetSitemap()
    const inUserFlow = useInUserFlow()
    return (
        <IconButton
            size="sm"
            variant="ghost"
            icon={<ArrowBackIcon fontSize="lg" />}
            isLoading={RevisionHistoryDrawer.retrieving}
            onClick={async () => {
                if (RevisionHistoryDrawer.restoring) return;
                if (!inUserFlow) {
                    const pagesForRoot = chain(sitemap?.docs.pages).map((obj, key) => { return { ...obj, id: key } }).value();
                    const root = await createRoot(pagesForRoot);
                    dispatch(setRoot(root)) // reset pages
                    dispatch(initSitemapSectionsDoc({ pages: sitemap?.docs?.sections })) // reset page-sections
                }
                // close drawer
                dispatch(toggleRevisionHistoryDrawer()) // toggle drawer
            }}
        />
    )
}

const Title = () => {
    const title = GetTitle()
    return <Heading as='h3' size='sm' fontWeight="semibold" mr={6}>{title}</Heading>
}

const CurrentVersion = () => {
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    if (!RevisionHistoryDrawer.retrieving) {
        if (RevisionHistoryDrawer.error) return null;
        if (RevisionHistoryDrawer.selectedIndex == 0) {
            return <Text fontSize="sm" fontWeight="normal" color="fg.muted" lineHeight={1}>Current version</Text>
        }
    }
    return null
}

const RestoreButton = () => {
    const dispatch = useDispatch()
    const userCanEdit = useCanEditFile()
    const docId = useGetEditorDocIdFromPath()
    const user = useGetUser()
    const inUserDoc = docId === user?.id;
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    if (!userCanEdit) return null;
    if (!RevisionHistoryDrawer.retrieving && RevisionHistoryDrawer.selectedIndex !== 0) {
        return (
            <Button
                size="sm"
                isLoading={RevisionHistoryDrawer.restoring}
                onClick={() => {
                    if (inUserDoc) return dispatch(toggleUpgradeModal({ showing: true }))
                    if (userCanEdit) dispatch(restoreRevisionHistoryDrawerChanges())
                }}>
                {`Restore${RevisionHistoryDrawer.restored ? 'd!' : ''}`}
            </Button>
        )
    }
    return null
}

const GetTitle = () => {
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    var title = "Loading..."
    const { changes, selectedIndex } = RevisionHistoryDrawer;
    if (changes) {
        if (changes[selectedIndex]) {
            title = dayjs(changes[selectedIndex].updatedAt).format('MMMM D, h:mm A')
        }
    }
    return title;
}