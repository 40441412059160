import { Stack } from '@chakra-ui/react'
import { useGetUpgradeModal } from '../../../../hooks';

import { HeaderWithMenu } from './HeaderWithMenu';
import { Bulb } from './Bulb';
import { BackButton } from './BackButton';

export const Header = () => {
    const { screen } = useGetUpgradeModal()
    const isConfirm = screen?.startsWith('confirm')
    if (isConfirm) return null
    return (
        <Stack direction="row" align="center">
            <Bulb />
            <BackButton />
            <HeaderWithMenu />
        </Stack >
    )
}