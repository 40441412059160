import React from 'react';
import { useSelector } from 'react-redux';

import {
    IconButton,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { AddIcon } from '@chakra-ui/icons';
import { getSectionsCount, sendDataLayerEventForPageSections } from '.';
import { insertNewPageSection } from '../../../utils/page-sections/new';
import { getPageId } from '../../../utils/helpers';

export const Add = () => {
    const { sitemap } = useSelector(state => state);
    const { PageSectionsOptionsPopover } = sitemap?.ui;
    const { node, section } = PageSectionsOptionsPopover;
    const nodeId = getPageId(node);
    const sectionsCount = getSectionsCount(sitemap, nodeId);
    return (
        <Tooltip variant="rounded-sm" placement='top' openDelay={500} label='Add'>
            <IconButton size="sm" icon={<AddIcon />} color={mode("gray.600", "whiteAlpha.900")} variant="ghost" _hover={{ bgColor: "transparent" }}
                onClick={e => {
                    insertNewPageSection({ nodeId, index: section.index + 1 });
                    sendDataLayerEventForPageSections('Add', sectionsCount + 1);
                }}
            />
        </Tooltip>
    )
};