import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons"
import { HStack, Heading, Spinner, Text, useColorModeValue as mode } from "@chakra-ui/react"

export const Header = (props) => {
    const message = getMessage(props)
    return <Heading fontSize="2xl" mt={-0.5} mb={9}>{message}</Heading>
}

const getMessage = ({ isSignup, isSignUpPersonalize, isLogin, isPasswordReset, isSentPasswordResetEmail, isPasswordResetVerification }) => {
    if (isSignup) return "Create an account" 
    if (isSignUpPersonalize) return "Just a few more questions..."
    if (isLogin) return "Log in to your account"
    if (isPasswordReset) return "Forgotten your password?"
    if (isSentPasswordResetEmail) return "Sent reset email"
    if (isPasswordResetVerification) return "Reset your password"
}