import { Box, Code } from "@chakra-ui/react"

export const ErrorMessaging = ({ data }) => {
    const { error, url } = data;
    const isCrawl = data?.type === 'crawl'
    const isXML = data?.type === 'xml'
    const importLabel = isCrawl ? 'crawl' : 'import'
    // access
    if (error === "access") return (
        <>
            <Box>{`We were unable to access "${url}"...`}</Box>
            <Box>{`If this website exists, it has likely blocked access to external crawlers such as Rarchybot.`}</Box>
            <Box>{`To allow Rarchybot access, contact your webmaster or hosting provider and ask them to whitelist the below IP address:`}</Box>
            <Code rounded="md" p={2.5} children='34.173.108.16' />
        </>
    )
    // not-found
    if (error === "not-found") return (
        <>
            <Box>{`We received a 404 error when trying to fetch "${url}"...`}</Box>
            <Box>{`Please check that the URL was entered correctly, and click 'Retry' to try the ${importLabel} again.`}</Box>
            <Box>{`If this website exists, it may have blocked access to external crawlers such as Rarchybot.`}</Box>
            <Box>{`To allow Rarchybot access, contact your webmaster or hosting provider and ask them to whitelist the below IP address:`}</Box>
            <Code rounded="md" p={2.5} children='34.173.108.16' />
        </>
    )
    // empty
    if (error === "empty") return (
        <>
            <Box>{`We were unable to find any URLs ${isXML ? "in" : "on"} "${url}"...`}</Box>
            <Box>{`This is usually because ${isCrawl ? "the source code of the page doesn't" : isXML ? "the XML file doesn't" : ""} contain any links that we can follow to gather the data we need to power our visual sitemaps.`}</Box>
            <Box><strong>Tip: </strong>{`If you set any options before ${importLabel}ing, double-check them to ensure there are no accidental filters which could exclude us from finding any pages on "${url}".`}</Box>
        </>
    )
    return <Box>"{error}"</Box>
}