import 'react-checkbox-tree/lib/react-checkbox-tree.css' // one import here

import { Center, Flex, Spinner, VStack } from '@chakra-ui/react';
import {
    changeEditorPallette,
    initEditor,
    leftEditor,
    updateEditLevel
} from '../../store/actions/editor-actions';
import { getDefaultPalletteHeader, getIsDefaultPalletteHeader } from '../../helpers';
import { useCanFullyEditFile, useGetEditorCollectionFromPath, useGetEditorDocIdFromPath, useGetRevisionHistoryDrawer, useGetSitemap, useGetUserFlow, useInEditor, useInSitemap, useInUserFlow, useIsEditorDocAvailable, usePrevious } from '../../hooks'
import { useGetEditor, useShowEditorLoadingSpinner } from '../../hooks';

import App from '../Sitemap/app/';
import { Error } from './Error';
import React from 'react';
import { Toolbar } from './Toolbar';
import { getLatestEditLevel } from './Navbar/helpers';
import { hideContextMenu } from '../Sitemap/app/context-menu/helpers';
import { initSitemapCoversCollections } from '../../store/actions/sitemap-actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useFirestoreConnect } from "react-redux-firebase";

export const Editor = () => {

    const inEditor = useInEditor()

    const editorDocId = useGetEditorDocIdFromPath()

    const editor = useGetEditor()
    const sitemap = useGetSitemap() // this needs to only be editor for ui
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()

    // set listeners
    SetFirestoreListener(editorDocId)
    // try and get editor doc
    GetEditorDoc()
    // monitor for updated sitemap level
    MonitorEditLevel()
    // set pallette
    SetEditorPallette()
    // unmount
    LeftEditor(editorDocId)

    const isAvailable = useIsEditorDocAvailable()

    if (!inEditor) return;

    return (
        <>
            {!isAvailable
                ?
                <Error file={editor} />
                :
                (
                    <VStack gap={0}>
                        <Toolbar />
                        {!RevisionHistoryDrawer?.showing && (
                            <>
                                <App />
                                <div id="context-menu-mask" onClick={() => hideContextMenu()} style={{ display: sitemap?.ui?.ContextMenuDropdown?.showing ? 'block' : 'none' }} />
                            </>
                        )}
                    </VStack>
                )}
        </>
    )
}

export const LoadingSpinner = ({ h }) => {
    const showSpinner = useShowEditorLoadingSpinner();
    if (showSpinner) {
        return (
            <Center h={h || 'auto'} mt={-16}>
                <Flex>
                    <Spinner size="xl" speed="0.7s" color="gray.600" mt={0} />
                </Flex>
            </Center>
        )
    }
};

const LeftEditor = (editorDocId) => {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            // leave editor
            dispatch(leftEditor(editorDocId));
        }
    }, []);
}

const SetEditorPallette = () => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    useEffect(() => {
        const { header } = sitemap.pallette
        const isDefaultPalletteHeader = getIsDefaultPalletteHeader(header)
        if (isDefaultPalletteHeader) dispatch(changeEditorPallette({ header: getDefaultPalletteHeader() }))
    }, [])
}

const MonitorEditLevel = () => {
    const dispatch = useDispatch()
    var latestEditLevel = getLatestEditLevel();
    const prevEditor = usePrevious(useGetEditor())
    useEffect(() => {
        if (latestEditLevel !== prevEditor?.editLevel) {
            dispatch(updateEditLevel(latestEditLevel));
        }
    }, [latestEditLevel, prevEditor])
}

const SetFirestoreListener = (editorDocId) => {

    const inSitemap = useInSitemap()

    const inUserFlow = useInUserFlow()
    const flow = useGetUserFlow()

    const linkedSitemap = inUserFlow && flow?.sitemap;

    const collection = useGetEditorCollectionFromPath()

    const canFullyEdit = useCanFullyEditFile();

    const subListener = (subcollection, useCollection) => {
        return { collection: useCollection || collection, doc: editorDocId, subcollections: [{ collection: 'data', doc: subcollection }] }
    };

    useFirestoreConnect(() => {
        if (!editorDocId || !collection) return []
        // listen to doc
        let listenTo = [{ collection, doc: editorDocId }]
        // sitemap specific subcollections
        if (inSitemap || linkedSitemap) {
            listenTo = [
                ...listenTo,
                subListener('pages', 'sitemaps'), subListener('covers', 'sitemaps'), subListener('website_sections', 'sitemaps'), subListener('sections', 'sitemaps'), subListener('comments', 'sitemaps')
            ]
            // only listen to autocaptured if can fully edit
            if (inSitemap && canFullyEdit) listenTo.push(subListener('autocapturing'), subListener('autocaptured'));
        }
        if (inUserFlow) listenTo = [...listenTo, subListener('elements')]
        // return
        return listenTo;
    });
}

const GetEditorDoc = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        async function init() {
            dispatch(initEditor({ isNew: false })); // init
            const { collections } = await import('../Sitemap/app/covers/collections/exports') // load wireframes here
            dispatch(initSitemapCoversCollections(collections))
        }
        init()
    }, [])
};