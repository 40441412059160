import { getURLs } from '.';

export const exportSitemap = function (name) {

  var urls = getURLs();

  var xml =
    '<!-- Created using Rarchy.com --> <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap?.xsd">';
  for (var i = 0; i < urls.length; i++) {
    var url = urls[i].replace(/&/g, 'amp;');
    xml += '<url>';
    xml += '<loc>' + url + '</loc>';
    xml += '</url>';
  }
  xml += '</urlset>';

  var xmlContent = 'data:text/xml,';
  xmlContent += xml;

  var downloadLink = document.createElement("a");
  downloadLink.href = xmlContent;
  downloadLink.download = `${name}.xml`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  // save data to firebase storage
  // let blob = new Blob([encodedUri], { "type": "text/xml" });
  /* var storageRef = store.firebase.storage().ref().child(`/sitemaps/${sitemap?.id}/exports/${sitemap?.id}.xml`);
  storageRef.putString(encodedUri, 'data_url').then((snapshot) => {
    storageRef.getDownloadURL().then((url) => {
      if (url) {
        return message.success(
          <>
            <span style={{ fontWeight: 500, marginLeft: 12, marginRight: 12 }}>Export complete.</span>
            <a
              style={{ fontWeight: 500 }}
              href={url}
              onClick={(e) => { e.preventDefault(); downloadLink.click(); }}>Download Again</a>
          </>, 3);
      }
    });
  }); */
};
