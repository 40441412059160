import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useGetFolders } from '../../../../../hooks'
import { toggleShareFolderModal } from '../../../../../store/actions/folders-actions'
import { ShareFolderDropdownModalWrapper } from './Controller'

export const ShareFolderModal = () => {
    const dispatch = useDispatch()
    const { folder } = useGetFolders()?.ui?.ShareFolderModal
    const onClose = () => dispatch(toggleShareFolderModal())
    return (
        <Modal isOpen onClose={onClose} size="md" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <ShareFolderDropdownModalWrapper folder={folder} onClose={onClose} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}