import { Box, Center, Icon, MenuButton, Stack, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { CreateDropdown } from "../../../Components/Navbar/Create";
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper";

export const EmptySitemaps = () => {
    return (
        <SkeletonWrapper w="fit-content">
            <CreateDropdown defaultScreen="sitemap" placement="end" offset={[17.5, -255]} hideHeader button={
                <MenuButton
                    as={Box}
                    mt={3}
                    w="255px"
                    h="215px"
                    rounded="md"
                    p={3}
                    bg="rarchy-color-file"
                    borderColor="rarchy-border"
                    _hover={{ cursor: 'pointer' }}>
                    <Center
                        border="1px solid"
                        borderColor="rarchy-border"
                        rounded="md"
                        h="full"
                        bgColor="rarchy-bg-sitemap-editor"
                    >
                        <Stack w="full" align="center" spacing={8} justify="space-between">
                            <Icon as={AddIcon} fontSize="4xl" color="fg.muted" />
                            <Text fontSize="sm" fontWeight="medium" color="fg.muted">Create a new sitemap</Text>
                        </Stack>
                    </Center>
                </MenuButton>
            } />
        </SkeletonWrapper>
    )
}