import { Box } from "@chakra-ui/react"
import { ChecklistItem } from "."
import { useGetPlanLimits } from "../../../../../../shared/hooks/pricing"
import { useGetUpgradeModal } from "../../../../../hooks"

export const Screenshots = ({ forConfirmed, children }) => {
    const { planId } = useGetUpgradeModal()
    const limits = useGetPlanLimits(planId)
    return (
        <ChecklistItem forConfirmed={forConfirmed}>
            Capture
            <Box display="inline" fontWeight={forConfirmed ? "unset" :"semibold"}>
                {` ${limits?.screenshots?.toLocaleString()} full-page screenshots `}
                <Box display="inline" fontWeight="normal">{`per month`}</Box>
                {children}
            </Box>
        </ChecklistItem>
    )
}