import { ICON_SIZE, PY } from "../../Projects/Folders/Components/Options/Menu"
import { Icon, MenuItem, Stack, Text, createStandaloneToast } from "@chakra-ui/react"
import { getEditorSubfolderFromDoc, getInEditor, removeDuplicateQueryParams, setDefaultsForSharedURL } from "../../../helpers"

import { BsLink } from "react-icons/bs"
import { CheckCircleIcon } from "@chakra-ui/icons"
import { MenuItemSpinner } from "./MenuItemSpinner"
import copy from "copy-to-clipboard"
import { useState } from "react"
import { wait } from "../../../../shared/helpers"

const { toast } = createStandaloneToast()
const id = 'copy-toast'

export const CopyLinkMenuItem = ({ file, folder, onClose }) => {
    const [isCopying, setIsCopying] = useState(false)
    const URL = getCopiedURL({ file, folder })
    return (
        <MenuItem py={PY} icon={isCopying ? <MenuItemSpinner /> : <Icon as={BsLink} fontSize={ICON_SIZE} />} isDisabled={isCopying} onClick={async () => {
            setIsCopying(true)
            await wait(500)
            copy(URL)
            setIsCopying(false)
            onClose()
            showCopiedItemToast()
        }}>
            Copy link
        </MenuItem>
    )
}

export const getCopiedURL = ({ file, folder }) => {
    const isSitemap = file?.collection === 'sitemaps'
    const inSitemapInEditor = isSitemap && getInEditor()
    const subfolder = getSubfolder({ file, folder })
    const item = file || folder
    const baseUrl = `${window.location.origin}/app/${subfolder}/${item?.id}${window.location.search}${!window.location.search ? '?' : '&'}usp=sharing`;
    const URL = inSitemapInEditor ? removeDuplicateQueryParams(`${baseUrl}${setDefaultsForSharedURL(file, inSitemapInEditor)}`) : baseUrl
    return URL
}

const showCopiedItemToast = () => {
    if (!toast.isActive(id)) {
        toast({
            position: 'top',
            duration: 2900,
            isClosable: true,
            render: () => (
                <Stack mt={-0.5} align="center" w="fit-content" justify="space-between" direction="row" rounded="2xl" color="rarchy-color-toast" px={5} py={3} spacing={4} bgColor="rarchy-bg-toast">
                    <CheckCircleIcon />
                    <Text fontSize="sm" fontWeight="semibold">Link copied to clipboard</Text>
                </Stack>
            ),
        })
    }

}

const getSubfolder = ({ file, folder }) => {
    if (folder) return 'folder'
    return getEditorSubfolderFromDoc(file)
}