import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { draggingNewSymbol } from '../../../../../store/actions/flow-actions';
import { getDefaultPalletteHeader } from '../../../../../helpers';

export const GridIcon = ({ id, icon, margin, opacity }) => {
    const dispatch = useDispatch()
    const stroke = getDefaultPalletteHeader()
    return (
        <i
            style={{ margin: `0 ${!margin ? 4.5 : margin}px`, opacity }}
            onClick={() => {
                dispatch(draggingNewSymbol({ dragging: false }))
            }}
            onMouseDown={() => {
                dispatch(draggingNewSymbol({ dragging: true, symbol: id }))
            }}
        >
            {icon({ id, stroke })}
        </i>
    )
}

export const PageSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} stroke="transparent" opacity={opacity || 1} fill={stroke} strokeWidth="0" viewBox="0 0 16 16" width={`${size ? size : 1.3}em`} focusable="false" xmlns="http://www.w3.org/2000/svg"><path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path><path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path></svg>
);

export const PageSectionSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} stroke="transparent" opacity={opacity || 1} fill={stroke} strokeWidth="0" viewBox="0 0 16 16" focusable="false" width={`${size ? size : 1.3}em`} xmlns="http://www.w3.org/2000/svg"><path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path><path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path></svg>
)

export const StartEndSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} viewBox="0 0 64 64" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} fill="transparent" opacity={opacity || 1} fillRule="evenodd" strokeWidth="3.5" width={`${size ? size : 1.8}em`}><rect x="2" y="18" width="59" height="31" rx="15"></rect></svg>
);

export const DecisionSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} viewBox="0 0 64 64" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} fill="transparent" opacity={opacity || 1} fillRule="evenodd" strokeWidth="3.5" width={`${size ? size : 1.8}em`}><path d="M32.5 15.5l-30 17 30 17 30-17z"></path></svg>
)

export const ActionSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} viewBox="0 0 64 64" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} fill="transparent" opacity={opacity || 1} fillRule="evenodd" strokeWidth="3.5" width={`${size ? size : 1.8}em`}><path strokeWidth="3.5" d="M5 17.5h55v30h-55z"></path></svg>
)

export const DocumentSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} viewBox="0 0 64 64" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} fill="transparent" opacity={opacity || 1} fillRule="evenodd" strokeWidth="3.5" width={`${size ? size : 1.8}em`} style={{ marginTop: 1 }}><path d="M1.5 15.5h60v29.376c-30-12.006-30 12.346-60-2.13z"></path></svg>
)

export const InputOutputSVG = ({ id, stroke, size, opacity }) => (
    <svg id={id} viewBox="0 0 64 64" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} fill="transparent" opacity={opacity || 1} fillRule="evenodd" strokeWidth="3.5" width={`${size ? size : 1.8}em`}><path d="M1.5 46.5l8-30h52l-8 30z"></path></svg>
)