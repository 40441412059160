import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';


import {
    IconButton,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react";

import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { addWebsiteSectionChange, mergeWebsiteSectionsEdits, toggleWebsiteSectionOptionsPopover } from '../../../../../../../store/actions/sitemap-actions';
import { mapWebSectionsToArr } from '../index';

/*** popover ****/
export const DownUp = ({ WebsiteSectionOptionsPopover, website_sections, sitemap, user }) => {
    const dispatch = useDispatch();
    const { section } = WebsiteSectionOptionsPopover;
    const websiteSectionsCount = Object.keys(website_sections).length;
    const move = (orientation) => moveSection({ section, orientation, website_sections, dispatch })
    return (
        <>
            <IconButton size="sm" variant="ghost" icon={<ArrowUpIcon fontSize="md" />} isDisabled={section.index <= 0} onClick={() => move('up')} />
            <IconButton size="sm" variant="ghost" icon={<ArrowDownIcon fontSize="md" />} isDisabled={section.index >= (websiteSectionsCount - 1)} onClick={() => move('down')} />
        </>
    )
};

// eslint-disable-next-line
Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
};

const moveSection = ({ section, orientation, website_sections, dispatch }) => {
    // map to arr
    const websiteSectionsArr = mapWebSectionsToArr(website_sections);
    // old indexes
    const oldSectionIndexesForHistory = {};
    websiteSectionsArr.forEach((s, i) => { oldSectionIndexesForHistory[s.id] = { index: i } })
    // indexes
    const newIndex = section.index + (orientation === 'down' ? 1 : -1);
    // down
    if (orientation === 'down') {
        if (section.index < (websiteSectionsArr.length - 1)) {
            websiteSectionsArr.move(section.index, newIndex);
        } else { return; }
    };
    // up
    if (orientation === 'up') {
        if (section.index > 0) {
            websiteSectionsArr.move(section.index, newIndex);
        } else { return }
    };
    // map data for merge
    const newSectionIndexes = {};
    websiteSectionsArr.forEach((s, i) => newSectionIndexes[s.id] = { index: i });
    // transform into obj for merge
    /*** merge page edits ***/
    dispatch(mergeWebsiteSectionsEdits({ website_sections: newSectionIndexes }));
    dispatch(toggleWebsiteSectionOptionsPopover({ showing: false }));
    /*** merge page edits ***/
    const action = 'website-section-reorder'
    // save
    var obj = [];
    var websiteSectionKeys = Object.keys(newSectionIndexes);
    websiteSectionKeys.forEach(sectionId => {
        obj.push({ action, id: sectionId, ...newSectionIndexes[sectionId] });
    })
    const change = {
        id: new Date().getTime(),
        data: obj,
    };
    const history = {
        action,
        data: { newSectionIndexes, oldSectionIndexes: oldSectionIndexesForHistory },
    };
    // add page change in redux
    dispatch(addWebsiteSectionChange({ change, history }));
};