import React, { useState } from 'react'
import {
    Link,
    Button,
    Flex,
    InputGroup,
    InputLeftElement,
    Input,
    Box,
    VStack,
    useColorModeValue as mode,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    List,
    ListItem,
    ListIcon,
    Spinner,
    Alert,
    AlertIcon,
    Divider,
    Stack,
    Image,
    Center,
    AbsoluteCenter,
    Text
} from "@chakra-ui/react"

import { getPageName } from "../../../../../utils/app";

import { ArrowBackIcon, ArrowForwardIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { BiErrorCircle } from 'react-icons/bi'

import { useGetMonthlyScreenshotsRemaining, useGetPagesForCapture } from '../../hooks';

import { useGetSitemap } from '../../../../../../../hooks';
import { useEffect } from 'react';
import { getCoverData } from '../../../../../../../helpers';

import { isEmpty, compact, sortBy, take } from 'lodash'
import { chain } from '../../../../../../../helpers/chain';

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const listItemStyles = { display: 'flex', mt: 1, paddingInlineStart: 4, paddingInlineEnd: 4, py: 2, rounded: 'lg', alignItems: 'center' }

export const Capture = ({ activeStep, setActiveStep, checked, isAutocapturing, autocapturing, hasAutocaptured, autocaptured }) => {

    // component will unmount
    useEffect(() => { return () => { setActiveStep(0); } }, []);

    const [filter, setFilter] = useState('all pages')
    const [filteredListData, setFilteredListData] = useState([])
    const [imgView, setImgView] = useState(false)
    const [loadingImg, setLoadingImg] = useState(true)

    const sitemap = useGetSitemap();

    const beforeCaptureStarted = !isAutocapturing && !hasAutocaptured;
    const pagesForCapture = useGetPagesForCapture(checked);
    const pages = beforeCaptureStarted ? pagesForCapture : null
    const listData = mapListData({ pages, filter, autocapturing, autocaptured });
    const data = isEmpty(filteredListData) ? listData : filteredListData;

    const screenshotsRemaining = useGetMonthlyScreenshotsRemaining();

    if (beforeCaptureStarted && activeStep !== 3) return null;

    const numberOfPages = (pages?.length || listData?.length).toLocaleString()

    return (
        <>
            {imgView && (
                <Box pos="fixed" top={0} left={0} w="full" bgColor="rarchy-bg-white" h="100vh">
                    <Box p={4}>
                        <Button leftIcon={<ArrowBackIcon />} variant="ghost" onClick={() => setImgView(false)}>Back</Button>
                        <Divider mt={4} mb={0} />
                    </Box>
                    {loadingImg && (
                        <AbsoluteCenter>
                            <Spinner size="xl" />
                        </AbsoluteCenter>
                    )}
                    <Box w="full" overflow="scroll" pt={0} pb={3} px={4} h="calc(100vh - 90px)">
                        <Image src={imgView?.downloadURL} onLoad={() => { setLoadingImg(false) }} />
                    </Box>
                </Box>
            )}
            {!imgView && (
                <Box>
                    {beforeCaptureStarted && (
                        <Alert status='info' variant="info" mb={3}>
                            <AlertIcon color="gray.500" />
                            {`This will use ${(numberOfPages)} of your ${(screenshotsRemaining).toLocaleString()} screenshots remaining this billing period.`}
                        </Alert>
                    )}
                    <HStack direction="row" w="full" justifyContent="space-between" mb={1}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                            <Input fontSize="sm" variant="flushed" placeholder={`Search ${numberOfPages} pages...`}
                                onChange={(e) => {
                                    const search = e.target.value !== '' ? e.target.value.toLowerCase() : null
                                    const filteredData = compact([...listData].map(d => { if (d.url.toLowerCase().includes(search) || d.name.toLowerCase().includes(search)) return d }))
                                    setFilteredListData(filteredData)
                                }} />
                        </InputGroup>
                        {!beforeCaptureStarted && (
                            <Flex>
                                <Menu autoSelect={false} placement="bottom-end" justifyContent="flex-end">
                                    <MenuButton as={Button} variant="ghost" size="sm" textTransform="capitalize" rightIcon={<ChevronDownIcon />}>
                                        {filter}
                                    </MenuButton>
                                    <MenuList minW="1xs" fontSize="sm">
                                        {['all pages', !hasAutocaptured ? 'pending' : null, 'captured', 'errors'].map((f => {
                                            if (f) return <MenuItem key={f} textTransform="capitalize" icon={filter === f ? <CheckIcon /> : null} onClick={() => { setFilter(f) }}>{f}</MenuItem>
                                        }))}
                                    </MenuList>
                                </Menu>
                            </Flex>
                        )}
                    </HStack>
                    <List margin={0} overflow="scroll">
                        {!isEmpty(data) && [...data].map(d => {
                            const icon = renderIcon(d);
                            const hasCapturedSuccessfully = d.captured;
                            const showViewButton = !beforeCaptureStarted && hasCapturedSuccessfully;
                            return <ListItem
                                key={`search-${d.id}`}
                                {...listItemStyles}
                                _hover={{ bg: mode('gray.100', 'whiteAlpha.200') }}
                            >
                                <HStack alignItems="center" spacing={5} justify="space-between" w="full">
                                    <Stack direction="row" align="center">
                                        <ListIcon color={mode(icon.color?.[0], icon.color?.[1])} as={icon.icon} fontSize={`${icon.fontSize}px`} marginInlineStart="0.5rem" />
                                        <VStack ml={4} spacing={1} alignItems="flex-start">
                                            <Box fontWeight="semibold" fontSize="sm">{getPageName(d.name)}</Box>
                                            <Link target="_blank" href={d.url}><Box fontWeight="medium" fontSize="xs" opacity={0.7}>{d.url}</Box></Link>
                                        </VStack>
                                    </Stack>
                                    {showViewButton && (
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            colorScheme="blue"
                                            // rightIcon={<ArrowForwardIcon />}
                                            onClick={() => {
                                                var coverData = getCoverData({ sitemap, page: d.id });
                                                setImgView(coverData);
                                                setLoadingImg(true);
                                            }}>
                                            View
                                        </Button>
                                    )}
                                </HStack>
                            </ListItem>
                        })}
                    </List>
                </Box>
            )}
        </>
    )
}

const renderIcon = (d) => {
    var icon = AiOutlineFieldTime, color = ["gray.400", "whiteAlpha.600"], fontSize = 20; // pending default
    const isCaptured = d.captured, isCapturing = d.capturing, isError = d.error;
    if (isCaptured) {
        icon = CheckCircleIcon
        color = ["green.500", "green.600"]
    } else if (isCapturing) {
        icon = Spinner
    } else if (isError) {
        icon = BiErrorCircle
        color = ["red.500", "red.300"]
    }
    return { icon, color, fontSize }
}

const mapListData = (props) => {
    const { pages, filter, autocapturing, autocaptured } = props;
    // before capture
    if (!isEmpty(pages)) return sortBy(chain(pages)?.map((obj, id) => Object.assign({}, obj, { id })).value(), ['index']) || [];
    // capturing / captured
    const capturedPages = sortBy(chain(autocaptured?.pages)?.map((obj, id) => Object.assign({}, obj, { id })).value(), ['index']) || []
    // filter captured
    if (filter === 'captured') return capturedPages?.filter(page => !page.error);
    // filter errors
    if (filter === 'errors') return capturedPages?.filter(page => page.error);
    // filter pending
    if (filter === 'pending') {
        const pendingPages = sortBy(chain(autocapturing?.pages)?.map((obj, id) => Object.assign({}, obj, { id })).value(), ['index']) || [];
        const nextX = take(pendingPages, ((!autocapturing.batch || (autocapturing?.batch && !autocapturing?.batch?.retrying)) ? 25 : autocapturing?.batch?.retrying));
        nextX?.map(page => { page.capturing = true; return page; });
        return pendingPages;
    }
    // all pages
    if (!filter || filter === 'all pages') {
        const mergedPages = { ...autocapturing?.pages, ...autocaptured?.pages };
        const sortedPages = sortBy(chain(mergedPages)?.map((obj, id) => Object.assign({}, obj, { id })).value(), ['index']) || [];
        const nextX = take(sortedPages?.filter(page => !page.error && !page.captured), ((!autocapturing?.batch || (autocapturing?.batch && !autocapturing?.batch?.retrying)) ? 25 : autocapturing?.batch?.retrying));
        nextX?.map(page => { page.capturing = true; return page; });
        return sortedPages;
    }
    return [];
};