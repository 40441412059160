import {
    Box,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Skeleton,
    Text,
    VStack
} from "@chakra-ui/react"
import CheckboxTree, { expandNodesToLevel } from 'react-checkbox-tree'
import { isEmpty, map } from 'lodash'
import { mapFilteredTreeData, mapTreeData } from './helpers';
import { useEffect, useState } from "react"

import { SearchIcon } from "@chakra-ui/icons";
import { usePrevious } from "../../../../../hooks";
import { useSelector } from "react-redux"

export const PagesTree = ({ onClick, checked = [], setChecked = () => { }, maxH, opts = { showCheckbox: false, rightFilter: {}, showURLs: false } }) => {

    const { sitemap, ui } = useSelector(state => state)
    const [treeData, setTreeData] = useState([])
    const [filteredTreeData, setFilteredTreeData] = useState([])
    const [expanded, setExpanded] = useState([opts.showCheckbox ? 'select-all' : 'home'])
   
    useEffect(() => {
        (setTimeout(() => {
            const data = mapTreeData({ sitemap, opts })
            setTreeData(data)
            setExpanded(map(data, 'value'))
        }, 250));
    }, [])

    /*** changed filter ***/
    const filter = opts?.rightFilter?.element?.props?.filter;
    const prevFilter = usePrevious(filter);
    useEffect(() => {
        if (filter && prevFilter && (filter !== prevFilter)) {
            const data = mapTreeData({ sitemap, opts })
            setTreeData(data)
        }
    }, [filter, prevFilter])
    /*** changed filter ***/
    
    return (
        <Box w="full" className={`pages-tree ${ui.colorMode}`} pb={2}>
            <HStack mt={1} mb={5} spacing={3} pl={0} direction="row" w="full" justifyContent="space-between">
                <Search handleChange={(e) => {
                    if (e.target.value.length > 2) {
                        const filteredData = mapFilteredTreeData(treeData, e.target.value.toLowerCase());
                        setFilteredTreeData(!isEmpty(filteredData) ? filteredData : null);
                        setExpanded(expandNodesToLevel(filteredData, Infinity));
                    } else {
                        setFilteredTreeData([])
                        setExpanded(map(treeData, 'value'))
                    }
                }} />
                {opts?.rightFilter?.element}
            </HStack>
            {isEmpty(treeData) && (
                <>
                    <VStack alignItems="flex-start" mt={2} ml="36px">
                        <Skeleton height={4} w="25%" />
                    </VStack>
                    <VStack spacing="18px" alignItems="flex-start" mt="19px" ml="60px">
                        {[...Array(20)].map((o, i) => <Skeleton key={i} height={4} w="40%" />)}
                    </VStack>
                </>
            )}
            <Box maxH={maxH ? maxH : "auto"} overflow="scroll">
                {!filteredTreeData ? <Text px={9} py={3} fontSize="sm">No pages found...</Text>
                    : <CheckboxTree
                        // disabled={!canEdit}
                        nodes={!isEmpty(filteredTreeData) ? filteredTreeData : !filteredTreeData ? [] : treeData}
                        checked={checked}
                        onCheck={checked => setChecked(checked)}
                        expanded={expanded}
                        onExpand={expanded => setExpanded(expanded)}
                        optimisticToggle={false}
                        checkModel="leaf"
                        showNodeIcon={false /* opts.showCheckbox */}
                        onClick={onClick}
                    />}
            </Box>
        </Box>
    )
}

const Search = ({ initialFocusRef, searched, handleChange }) => {
    return (
        <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
            <Input fontSize="sm" variant="flushed" ref={initialFocusRef} value={searched} onChange={handleChange} placeholder="Enter 3 characters to start filtering..." />
        </InputGroup>
    )
}

const getNodeIds = (nodes) => {
    let ids = [];
    nodes?.forEach(({ value, children }) => {
        ids = [...ids, value, ...getNodeIds(children)];
    });
    return ids;
};