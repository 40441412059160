import { Box, Center, Heading, Stack } from "@chakra-ui/react"
import { useGetUser, useIsFilesEmpty } from "../../hooks"

import { Sections } from "../Projects/Components/Sections"
import { SkeletonWrapper } from "../Components/Shared/SkeletonWrapper"
import { WantToTry } from "./Sections/WantToTry"

export const Home = () => {
    const user = useGetUser()
    const isFilesEmpty = useIsFilesEmpty()
    return (
        <Stack p={6} spacing={10}>
            <SkeletonWrapper>
                <Box borderRadius="md" w="full" bg="linear-gradient(90deg, rgba(0,167,225,1) 0%, rgba(46,217,195,1) 100%)">
                    <Center p={16}>
                        <Heading fontSize="4xl" color="white" fontWeight="semibold">
                            {!isFilesEmpty ? `Welcome back, ${user?.firstName || ''}!` : `Welcome, ${user?.firstName || ''}. Let's get started...`}
                        </Heading>
                    </Center>
                </Box>
            </SkeletonWrapper>
            <WantToTry />
            <Box mt={-3}><Sections /></Box>
        </Stack>
    )
}

export const HomeSectionHeader = ({ title }) => {
    return <Heading fontSize="xl" fontWeight="semibold">{title}</Heading>
}