import {
    Heading,
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    HStack,
    Badge
} from "@chakra-ui/react"
import { useSelector } from "react-redux"


import { PagesTree } from "../../../Sitemap/app/components/PagesTree";
import { useGetSitemap } from "../../../../hooks";
import { goToPage } from "./helpers";

export const PagesDrawer = ({ isDrawerOpen, onDrawerClose }) => {
    const sitemap = useGetSitemap()
    const noOfPages = Object.keys(sitemap?.docs?.pages || {})?.length?.toLocaleString() || ""
    return (
        <Drawer
            isOpen={isDrawerOpen}
            onClose={onDrawerClose}
            placement='right'
            size="lg"
        >
            <DrawerContent>
                <DrawerHeader>
                    <HStack mt={2} spacing={2} direction="row" align="flex-end">
                        <Heading fontSize="xl" fontWeight="semibold">{`Pages`}</Heading>
                        <Badge px={2} py={0.5} rounded="md">{noOfPages}</Badge>
                    </HStack>
                </DrawerHeader>
                <DrawerBody mt={-2}>
                    <Pages onClose={onDrawerClose} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

const Pages = ({onClose}) => {
    const { sitemap } = useSelector(state => state)
    const { nodes, root, section: subfolder } = sitemap?.data || {};
    return (
        <PagesTree maxH="calc(100vh - 150px)" onClick={({ value: page }) => {
            goToPage({ selected: { id: page }, nodes, root: subfolder ? subfolder : root, onClose });
        }} />
    )
}

const getNodeIds = (nodes) => {
    let ids = [];
    nodes?.forEach(({ value, children }) => {
        ids = [...ids, value, ...getNodeIds(children)];
    });
    return ids;
};