import { ArrowBackIcon } from "@chakra-ui/icons"
import { Box, HStack, Heading, IconButton, Stack } from "@chakra-ui/react"
import { TemplatesGrid } from "../../../Templates/Components/Grid"
import { TemplatesHOC } from "../../../Templates/TemplatesHOC"

export const Templates = ({ w, inNewSitemapModal, setShowTemplatesScreen }) => {
    return (
        <TemplatesHOC>
            <Stack h="100vh" overflow="scroll" w={w || "unset"}>
                <HStack spacing={4} px={8} py={6} pb={2}>
                    <IconButton icon={<ArrowBackIcon fontSize="2xl" />} size="md" variant="ghost" onClick={setShowTemplatesScreen.toggle} />
                    <Heading fontSize="2xl" fontWeight="semibold">Browse templates</Heading>
                </HStack>
                <Box px={10} pb={16} overflow="scroll">
                    <TemplatesGrid inNewSitemapModal={inNewSitemapModal} collection="sitemaps" />
                </Box>
            </Stack>
        </TemplatesHOC>
    )
}