import { store } from '../../../../../../store';
import { render as renderPages } from '../../render';
import { render as renderUserFlow } from '../../../../user-flows/render'

import { transform } from '../zoom';
import { update } from '../../../../utils/app';
import { addSymbolChange, setSelectedUserFlowSymbols, update as updateUserFlow } from '../../../../user-flows/helpers'
import { mouseover } from '../listeners';
import { renderInteractionsCanvas } from '../../canvases';
import { hideWhileDragging, createDraggingPlaceholderNodeData, moveNodeUnderNewParent, getColKey } from './helpers';

import { dragsubject as dragsubjectNodes, dragstarted as dragstartedNodes, dragged as draggedNodes, dragended as dragendedNodes } from '../../../views/nodes';

import {
    insertPlaceholderForTree,
    insertPlaceholderForVerticalTree,
    insertPlaceholderForVerticalTreeMatrix,
    insertPlaceholderForIndent,
    getNewPositionAndRenderPlaceholder
} from './views';

import { colors as pageColors } from '../../components/node';
import { colors as websiteSectionColors } from '../../components/website-sections'

// import amplitude from 'amplitude-js/amplitude';

import { addPageChange, resetNodeHeights, togglePageSectionsOptionsPopover, addPageSectionChange, togglePageButtons, mergePagesEdits } from '../../../../../../store/actions/sitemap-actions';

import { hideCommentsPopover } from '../../../../comments/helpers.jsx';
import { addFlowChange, hideFlowsOptionsPopover, setUserFlowSymbolsAndConnectors, toggleUserFlowSymbolButtons } from '../../../../../../store/actions/flow-actions';
import { getNodeCoords } from '../../../views';
import { getX, getY } from '../helpers';
import { getCanvasTextEditor, getInUserFlow, getRevisionHistoryDrawer, getSitemap } from '../../../../../../helpers/index.js';
import { getCanEditInEditor } from '../../../../../Editor/Navbar/helpers';

import { event, ascending, drag as d3Drag } from 'd3';
import { isEmpty, omitBy, omit, isUndefined } from 'lodash'

export var dragging;
export var draggedNode;

export var colors = {}, colorByNodeIds = {};

var prevColKey = null;

var oldDataForHistory = [];
var oldSectionIndexesForHistory = {};

const render = (nodes, links) => {
    const inUserFlow = getInUserFlow()
    !inUserFlow ? renderPages() : renderUserFlow(nodes, links)
}

export const drag = () => {

    var nodes;

    function dragsubject() {

        const { sitemap, flow } = store.getState();

        const RevisionHistoryDrawer = getRevisionHistoryDrawer()
        const CanvasTextEditor = getCanvasTextEditor()

        const inUserFlow = getInUserFlow();
        const node = !inUserFlow ? mouseover.node : mouseover.node ? mouseover.node : mouseover.userFlowNode;

        const canEdit = getCanEditInEditor();

        /*** don't drag ***/
        if (!canEdit) return;
        if (!node) return;
        if (!inUserFlow && !node.parent && !mouseover.section) return; // can't drag home (but can reorder page sections)
        if (node.overflow) return;
        if (node.renaming) return;
        if (CanvasTextEditor.showing && CanvasTextEditor.node.id === node.id) return;
        if (RevisionHistoryDrawer.showing) return;
        /*** don't drag ***/

        if (inUserFlow) {

            dragging = { ...node };
            draggedNode = node;

            dragging.x0 = node.x // stops issue when clicking, long clicking
            dragging.y0 = node.y  // stops issue when clicking, long clicking

            // transform.invertX/Y is the mouse coords in the transformed canvas
            const x = transform.invertX(event.x)// - (barWidth / 2); // center node around mouse
            const y = transform.invertY(event.y)// - ((dragging.nodeHeight - (sitemap?.showCovers ? 265 : 0)) / 2); // center node around mouse
            dragging.x = transform.applyX(x - (x - node.x));
            dragging.y = transform.applyY(y - (y - node.y));

            if (sitemap?.ui.PageButtons.showing) store.dispatch(togglePageButtons({ showing: false, renaming: false }))
            if (flow?.ui.SymbolButtons.showing) store.dispatch(toggleUserFlowSymbolButtons({ ...flow.ui.SymbolButtons, zooming: true, renaming: false }))

            return dragging;
        }

        nodes = sitemap?.data.nodes;

        /*** nodes only ***/
        if (sitemap?.format === 'nodes') return dragsubjectNodes(event);
        /*** nodes only ***/

        /*** page sections only (not new!) ***/
        if (mouseover.section) {
            if (mouseover.section.section) {
                if (mouseover.section.section.id !== 'new') {
                    if (sitemap?.data.page_sections[node.id].length === 1) return; // can't drag if only one section in page
                    dragging = { dragging: true, ...mouseover.section };
                    return dragging;
                }
            }
            return;
        };
        /*** page sections only (not new!) ***/

        /*** whole pages only ***/
        dragging = { ...node };
        draggedNode = node;

        // transform.invertX/Y is the mouse coords in the transformed canvas
        const x = transform.invertX(event.x)// - (barWidth / 2); // center node around mouse
        const y = transform.invertY(event.y)// - ((dragging.nodeHeight - (sitemap?.showCovers ? 265 : 0)) / 2); // center node around mouse
        dragging.x = transform.applyX(x - (x - getX(node)));
        dragging.y = transform.applyY(y - (y - getY(node)));

        // close things we don't want open
        hideCommentsPopover()
        if (sitemap?.ui.PageButtons.showing) store.dispatch(togglePageButtons({ showing: false }))
        if (flow.ui.OptionsPopover.showing) store.dispatch(hideFlowsOptionsPopover());

        return dragging;
        /*** whole pages only ***/

    }

    function dragstarted() {
        const sitemap = getSitemap();
        const inUserFlow = getInUserFlow();
        if (!inUserFlow && sitemap?.format === 'nodes') return dragstartedNodes(event);
    }

    function dragged() {

        const { sitemap, flow } = store.getState();

        const inUserFlow = getInUserFlow();

        if (inUserFlow) {

            const { OptionsPopover } = flow.ui;
            // started dragging
            if (dragging && !dragging.started) {
                dragging.started = true;
                document.getElementById('interactions-canvas').style.cursor = "grabbing";
                if (OptionsPopover.showing) store.dispatch(hideFlowsOptionsPopover());
            }

            //
            const movex = Math.round((transform.invertX(event.x))) // / (225 / 12)) * (225 / 12);
            const movey = Math.round((transform.invertY(event.y))) // / (265 / 12)) * (265 / 12);
            //
            event.subject.x = movex
            event.subject.y = movey
            // update node coords
            const { topOfNode, bottomOfNode, leftOfNode, rightOfNode } = getNodeCoords(event.subject, sitemap)
            event.subject.topOfNode = topOfNode;
            event.subject.bottomOfNode = bottomOfNode;
            event.subject.leftOfNode = leftOfNode;
            event.subject.rightOfNode = rightOfNode;
            //
            render();
            //
            return
        }

        if (sitemap?.format === 'nodes') return draggedNodes(event);

        /*** dragging section (not new!) ***/
        if (dragging.section && dragging.section.id !== 'new') {

            /*** started dragging ***/
            if (dragging && !dragging.started) {
                dragging.started = true;

                store.dispatch(togglePageSectionsOptionsPopover({ showing: false }));

                /*** set old data here ***/
                const sectionData = sitemap?.data.page_sections ? sitemap?.data.page_sections[dragging.node.id] ? sitemap?.data.page_sections[dragging.node.id] : [] : [];
                sectionData.sort((a, b) => ascending(a.index, b.index)).forEach((s, i) => { s.index = i; oldSectionIndexesForHistory[s.id] = s.index });
                /*** set old data here ***/

                render(nodes);
            }
            /*** started dragging ***/

            dragging.section.dy = transform.invertY(event.y);
            renderInteractionsCanvas();

            return;
        }
        /*** dragging section (not new!) ***/

        /*** started dragging ***/
        if (dragging && !dragging.started) {

            if (draggedNode.children) hideWhileDragging(draggedNode, true);

            dragging.placeholder = createDraggingPlaceholderNodeData(draggedNode);

            dragging.started = true;

            /*** set old data here ***/
            draggedNode.parent.children.forEach((d, i) => {
                oldDataForHistory.push({
                    id: d.id,
                    index: i,
                    parent: d.parent.id,
                    website_section: d.website_section ? d.website_section : null // has to be null so it's deleted properly
                });
            });
            // website sections
            changeWebsiteSectionForChildrenNodes(draggedNode, draggedNode, oldDataForHistory, { forHistory: true });
            /*** set old data here ***/

            nodes.push(dragging.placeholder);

            update(nodes);
        };
        /*** started dragging ***/

        event.subject.x = subjectX(event);
        event.subject.y = subjectY(event);

        draggingMouseMove(event, sitemap?.data.nodes, {});
        draggingMouseMove(event, sitemap?.data.nodes, { left: true });
        draggingMouseMove(event, sitemap?.data.nodes, { right: true });

        renderInteractionsCanvas();

    }

    function dragended() {

        const sitemap = getSitemap();

        const inUserFlow = getInUserFlow();

        if (inUserFlow) return dragEndedUserFlow()

        if (sitemap?.format === 'nodes') return dragendedNodes(event);

        event.sourceEvent.preventDefault();
        event.sourceEvent.stopImmediatePropagation();

        document.getElementById('interactions-canvas').style.cursor = "default";

        // eslint-disable-next-line
        colors = {}, colorByNodeIds = {}; // reset for dragging listeners

        // click, not drag
        if (dragging && !dragging.started) return resetNode();

        // reset dragged section
        if (dragging.section && dragging.section.id !== 'new') return resetDraggedSection();

        var newParent = dragging.over;
        var reorder = dragging.placeholder;
        var firstNodeInWebsiteSection = dragging?.website_section?.id ? dragging?.website_section : null // IMPORTANT - make sure we check for id, for some reason this can come through as the string when dragging from text
        var node;

        hideWhileDragging(draggedNode, false); // re-show children nodes of dragged node that were hidden at start of drag

        if (!firstNodeInWebsiteSection) {
            if (!newParent && !reorder) {
                // do nothing
            } else if (newParent) { // dropped over page
                node = moveNodeUnderNewParent(draggedNode, newParent, 0);
                // amplitude tracking
                // amplitude.getInstance().logEvent('SITEMAP_MOVED_PAGE_UNDER_NEW_PARENT');
                //
            } else if (reorder) { // inserted into a specific index
                node = moveNodeUnderNewParent(draggedNode, reorder.parent, reorder.index);
                // amplitude tracking
                // amplitude.getInstance().logEvent('SITEMAP_REORDERED_PAGE');
            }
        } else {
            // first page in website section
            node = moveNodeUnderNewParent(draggedNode, { id: firstNodeInWebsiteSection.id, website_section: firstNodeInWebsiteSection.id, children: [] }, 0);
        }

        /*** reset node (will save changes as passed in node) ***/
        resetNode(node);
        /*** reset node (will save changes as passed in node) ***/

        // update();
    }

    const subjectX = (event) => {
        const sitemap = getSitemap();
        if (sitemap?.format === 'tree' || sitemap?.format === 'indent') {
            return transform.invertY(event.y);
        }
        if (sitemap?.format.startsWith('tree-vertical')) return transform.invertX(event.x);

    }

    const subjectY = (event) => {
        const sitemap = getSitemap();
        if (sitemap?.format === 'tree' || sitemap?.format === 'indent') {
            return transform.invertX(event.x);
        }
        if (sitemap?.format.startsWith('tree-vertical')) return transform.invertY(event.y);
    }

    return d3Drag()
        .subject(dragsubject)
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
}

const resetDraggedSection = () => {

    const sitemap = getSitemap();

    /*** transform new index data ***/
    const newSectionIndexes = {};
    const sectionData = sitemap?.data.page_sections ? sitemap?.data.page_sections[dragging.node.id] ? sitemap?.data.page_sections[dragging.node.id] : [] : [];
    sectionData.forEach(s => { newSectionIndexes[s.id] = s.index; })
    /*** transform new index data ***/

    if (!isEmpty(newSectionIndexes)) { // ensure new data is not empty before continuing

        if (JSON.stringify(newSectionIndexes) !== JSON.stringify(oldSectionIndexesForHistory)) { // ensure indexes have changed before continuing
            var obj = [];
            var sections = Object.keys(newSectionIndexes);
            sections.forEach(sectionId => {
                obj.push({ action: 'page-section-reorder', pageId: dragging.node.id, section: { id: sectionId, index: newSectionIndexes[sectionId] } });
            })
            const pageSectionChange = {
                id: new Date().getTime(),
                data: obj,
            };
            const history = {
                action: 'page-section-reorder',
                data: { pageId: dragging.node.id, newSectionIndexes, oldSectionIndexes: oldSectionIndexesForHistory },
            };
            // add change to autosave
            store.dispatch(addPageSectionChange({ change: pageSectionChange, history }));
        }
    }

    /*** only reset mouseover if was dragging (otherwise clicks on options won't work) ***/
    if (dragging?.started) {
        mouseover.node = null;
        mouseover.options = {};
    };
    /*** only reset mouseover if was dragging (otherwise clicks on options won't work) ***/

    /*** reset dragging ***/
    dragging = null;
    /*** reset dragging ***/

    // reset old data for history
    oldDataForHistory = [];
}

export const resetNode = (node) => {

    const inUserFlow = getInUserFlow();

    /*** only reset mouseover if was dragging (otherwise clicks on options won't work) ***/
    if (dragging?.started) {
        mouseover.node = null;
        mouseover.options = {};
        mouseover.website_section = null;
    };
    /*** only reset mouseover if was dragging (otherwise clicks on options won't work) ***/

    if (!inUserFlow) {

        // changed page
        if (node) {

            var data = [];

            node.parent.children.forEach((d, i) => {
                data.push({
                    action: 'reorder',
                    id: d.id,
                    index: i,
                    parent: node.parent.id,
                    website_section: node.website_section ? node.website_section : null
                });
            });

            // website sections (make sure every child has the same website_section as parent)
            changeWebsiteSectionForChildrenNodes(node, node, data);

            /*** merge page edits ***/
            const pages = {};
            data.forEach(d => pages[d.id] = omitBy(omit(d, ['action', 'id', 'cover', 'comments']), isUndefined));
            store.dispatch(mergePagesEdits({ pages }));
            /*** merge page edits ***/

            const change = { id: new Date().getTime(), data };
            const history = { action: 'reorder', data, prevData: oldDataForHistory };
            // only add change if user can edit sitemap
            const canEdit = getCanEditInEditor();
            if (canEdit) {
                setTimeout(() => { store.dispatch(addPageChange({ change, history })); }, 500);
            }
        };
    }

    /*** reset dragging ***/
    if (!inUserFlow) {
        if (dragging?.started) store.dispatch(resetNodeHeights(['dragging-placeholder'], { update: true }));
    }
    dragging = null;
    draggedNode = null;
    /*** reset dragging ***/

    if (!inUserFlow) {
        // reset old data for history
        oldDataForHistory = [];
        // update (slowing down renames)
        // update();
        render()
    }

    if (inUserFlow) {
        setSelectedUserFlowSymbols([]);
        updateUserFlow(); // need this or sections go screwy 
    }

};

function changeWebsiteSectionForChildrenNodes(d, node, data, opts = {}) {
    const { forHistory } = opts;
    var children = d.children ? d.children : d._children;
    var length = !children ? 0 : children.length;
    for (var i = 0; i < length; i++) {
        const obj = {
            id: children[i].id,
            index: i,
            parent: children[i].parent.id,
            website_section: node.website_section ? node.website_section : null
        };
        if (!forHistory) obj.action = 'reorder'; // only add action for new change (not for history)
        // push to new changes array
        data.push(obj);
        // recurse
        changeWebsiteSectionForChildrenNodes(children[i], node, data, opts);
    }
};

export const draggingMouseMove = async (event, nodes, opts) => {

    var colKey = getColKey(event, opts);

    document.getElementById('interactions-canvas').style.cursor = "grabbing";

    /*** still mousing over canvas ***/
    if (colKey === 'rgb(0, 0, 0)' && prevColKey === 'rgb(0, 0, 0)') return
    /*** still mousing over canvas ***/

    if (colKey !== prevColKey) {

        /*** mouseenter canvas ***/
        if (colKey === 'rgb(0, 0, 0)' && prevColKey !== 'rgb(0, 0, 0)') {
            dragging.over = null; // reset dragging page over
            dragging.website_section = null; // reset dragging page over
            render();
            return prevColKey = colKey;
        }

        /*** mouseenter canvas ***/

        /*** over page ***/
        var overPage = pageColors[colKey];
        if (overPage) {
            if (dragging?.started && (dragging.id !== overPage.id)) dragging.over = overPage;
            render();
            return prevColKey = colKey;
        } else {

            var overEmptyWebsiteSection = websiteSectionColors[colKey];
            if (overEmptyWebsiteSection?.droppedPagePlaceholder) {
                dragging.website_section = overEmptyWebsiteSection;
                render();
                return prevColKey = colKey;
            }

            var placeholderData = colors[colKey];
            if (placeholderData) {

                dragging.over = null; // no longer over page
                prevColKey = colKey;

                const { node, side } = placeholderData;
                if (node.id === dragging.placeholder.nodeId) {
                    if (node.side === dragging.placeholder.side) return;
                };
                insertPlaceholder({ node, side }, nodes);
            }
        }
        /*** over page ***/

    }

};

const insertPlaceholder = async ({ node, side }, nodes) => {

    const sitemap = getSitemap();

    var nodesWithoutPlaceholder = nodes.filter(d => d.id !== 'dragging-placeholder' && d.id !== dragging.id); // remove existing dragging placeholder node and dragging node

    /*** insert placeholder in correct index in new parent's children ***/
    var nodesWithPlaceholder = (format => {
        switch (format) {
            case 'tree':
                return insertPlaceholderForTree(nodesWithoutPlaceholder, { node, side });
            case 'tree-vertical':
                return insertPlaceholderForVerticalTree(nodesWithoutPlaceholder, { node, side });
            case 'tree-vertical-matrix':
                return insertPlaceholderForVerticalTreeMatrix(nodesWithoutPlaceholder, { node, side });
            case 'indent':
                return insertPlaceholderForIndent(nodesWithoutPlaceholder, { node, side });
            default:
                return;
        }
    })(sitemap?.format);
    /*** insert placeholder in correct index in new parent's children ***/

    /*** update tree layout and render ***/
    nodesWithPlaceholder = await getNewPositionAndRenderPlaceholder(nodesWithPlaceholder);
    /*** update tree layout and render ***/

    /*** update dragging placeholder ***/
    const placeholder = nodesWithPlaceholder.find(d => d.id === 'dragging-placeholder');
    if (placeholder) { dragging.placeholder = placeholder; }
    /*** update dragging placeholder ***/

}

const dragEndedUserFlow = () => {
    //
    if (!dragging) return resetNode();
    //
    const draggedNode = { ...dragging };
    // get sitemap
    const { sitemap, flow } = store.getState();

    const offsetX = draggedNode.x - draggedNode.x0
    const offsetY = draggedNode.y - draggedNode.y0

    const changes = [];
    const history = [];
    // get old x & y before continuing
    const oldX = dragging.x0
    const oldY = dragging.y0
    // new x & y
    const newX = dragging.started ? draggedNode.x : dragging.x0;
    const newY = dragging.started ? draggedNode.y : dragging.y0;

    // ONLY SAVE IF MOVED
    if (newX !== oldX || newY !== oldY) {
        // set nodes and links
        const nodes = [...flow.data.nodes], links = [...flow.data.links];
        const dataForSymbolChange = {}
        nodes.map(n => {

            const isSymbol = !n?.type?.startsWith('page');
            const accountForLegacySymbolYOffset = (isSymbol ? (215 / 2) : 0)

            if (n.id === draggedNode.id || n._selected) {

                const oldX = n.x, oldY = n.y - accountForLegacySymbolYOffset;

                n.x = n.id === draggedNode.id ? newX : n.x + offsetX;
                n.y = (n.id === draggedNode.id ? newY : n.y + offsetY) - accountForLegacySymbolYOffset;

                n.x = Math.round(n.x / (225 / 12)) * (225 / 12);
                n.y = Math.round(n.y / (265 / 12)) * (265 / 12);

                changes.push({ action: 'reposition', id: n.id, x: n.x, y: n.y })
                history.push({ action: 'reposition', node: n.id, data: { oldX, oldY, newX: n.x, newY: n.y } })
                // update node coords
                const { topOfNode, bottomOfNode, leftOfNode, rightOfNode } = getNodeCoords(n, sitemap)
                n.topOfNode = topOfNode;
                n.bottomOfNode = bottomOfNode;
                n.leftOfNode = leftOfNode;
                n.rightOfNode = rightOfNode;
                // for symbol change
                dataForSymbolChange[n.id] = { x: n.x, y: n.y }
                // return
                return n;
            }
        })

        store.dispatch(setUserFlowSymbolsAndConnectors({ nodes, links }));

        addSymbolChange({ data: dataForSymbolChange });

        /*** save ***/
        setTimeout(() => {
            store.dispatch(addFlowChange({
                change: {
                    id: new Date().getTime(),
                    data: changes
                },
                history
            }));
        }, 250);
        /*** save ***/
    }

    /*** reset node ***/
    resetNode();
    /*** reset node ***/
}