import { useContext, useEffect, useState } from "react"
import { TemplatesContext, templates } from "../TemplatesHOC"
import { Button, Flex, Stack, Text, Wrap } from "@chakra-ui/react"
import { TemplateCard } from "./TemplateCard"
import deepmerge from 'deepmerge'
import { Link } from "react-router-dom"
import { useGetUi, useInHomeScreen } from "../../../hooks"
import { compact, uniq, take, sortBy, cloneDeep } from 'lodash'

export const TemplatesGrid = ({ collection, inNewSitemapModal }) => {

    const { templatesJSON } = useContext(TemplatesContext)

    const inHomeScreen = useInHomeScreen() && !inNewSitemapModal
    const isSideMenuShowing = !useGetUi()?.sideMenu?.collapsed
    const [hasResizeListener, setHasResizeListener] = useState(false)
    const getItemsToShow = () => inHomeScreen ? Math.round((parent.innerWidth - (isSideMenuShowing ? 266 : 0)) / 330) : Infinity
    const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(getItemsToShow())

    const isAll = collection === 'all'

    let data = [...templatesJSON]?.filter(t => isAll || t?.collection === collection)?.map(t => {
        t = deepmerge(t, templates?.find(t2 => t2?.id === t?.id))
        t.label = `${t?.name} ${t?.collection === 'sitemaps' ? "Sitemap" : "User Flow"}`
        if (t?.collection === 'sitemaps') t.type = 'sections'
        return t;
    })
    let covers = compact(uniq(cloneDeep(data)?.map(t => {
        if (t?.collection === 'sitemaps') { t.type = 'covers'; return t; }
        return;
    })))

    useEffect(() => {
        const handleResize = () => {
            let noOfItems = getItemsToShow()
            if (noOfItems !== numberOfItemsToShow) setNumberOfItemsToShow(noOfItems || 1)
        }
        if (inHomeScreen && !hasResizeListener) setHasResizeListener(true); window.addEventListener('resize', handleResize);
        return () => {
            if (inHomeScreen) window.removeEventListener('resize', handleResize);
        };
    }, [numberOfItemsToShow]);

    data = take(sortBy([...data, ...covers], 'name'), numberOfItemsToShow)
    if (collection === 'user-flows') return <Text>Coming soon...</Text>

    return (
        <Flex>
            <Stack direction="column" align={numberOfItemsToShow === 1 ? "start" : "center"} spacing={12}>
                <Wrap spacingX={inNewSitemapModal ? 10 : 5} spacingY={6}>
                    {data?.map((t) => <TemplateCard key={`${t?.id}${t?.type ? `-${t?.type}` : ''}`} template={t} collection={collection} />)}
                </Wrap>
                {inHomeScreen && <Link to="/templates"> <Button w="fit-content" px={20}>Browse more templates</Button></Link>}
            </Stack>
        </Flex>
    )
}
