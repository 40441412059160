import { createContext, useState } from "react"
import { useBoolean } from '@chakra-ui/react'

export const MoveToFolderContext = createContext()

export const MoveToFolderHOC = ({ file, folder, onClose, children }) => {
    const [tabIndex, setTabIndex] = useState(0)
    const [showMoveToFolderScreen, setShowMoveToFolderScreen] = useBoolean(false)
    const [showCreateNewScreen, setShowCreateNewScreen] = useBoolean(false)
    const [showDetailScreen, setShowDetailScreen] = useState(false)
    const [searchInputVal, setSearchInputVal] = useState('')
    return (
        <MoveToFolderContext.Provider
            value={{
                file,
                folder,
                onClose,
                tabIndex,
                setTabIndex,
                showMoveToFolderScreen,
                setShowMoveToFolderScreen,
                showCreateNewScreen,
                setShowCreateNewScreen,
                showDetailScreen,
                setShowDetailScreen,
                searchInputVal,
                setSearchInputVal
            }}>
            {children({ showMoveToFolderScreen, setShowMoveToFolderScreen })}
        </MoveToFolderContext.Provider>
    )
}