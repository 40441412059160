import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    HStack
} from "@chakra-ui/react"
import { Delete } from './Delete';
import { Type } from './Type';
import { Color } from '../Shared/Color';
import { ArrangeMenu } from '../Shared/Arrange';

export const UserFlowsConnectorOptions = ({ OptionsPopover }) => {
    return (
        <HStack spacing={1}>
            <Type OptionsPopover={OptionsPopover} />
            <Color OptionsPopover={OptionsPopover} />
            <ArrangeMenu />
            <Delete OptionsPopover={OptionsPopover} />
        </HStack>
    )
}