import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack } from "@chakra-ui/react"
import { Labels, NewLabelButton, NewLabelPanel } from "./Labels"

import { Pallette } from "./Pallette"
import { ResetButton } from "./ResetButton"
import { toggleUpgradeModal } from "../../../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useGetColorsFromEditorDoc } from "../hooks"
import { useInUserEditorDoc } from "../../../../../hooks"
import { useState } from "react"

export const ColorsLabels = ({ selectedColor, handlePalletteChange }) => {
    const dispatch = useDispatch()
    const colors = useGetColorsFromEditorDoc()
    const [index, setIndex] = useState(colors?.length === 0 ? 1 : 0)
    const [showNewLabelPanel, setShowNewLabelPanel] = useState(false)
    const inUserEditorDoc = useInUserEditorDoc()
    return (
        <Accordion size="sm" allowToggle index={index} onChange={setIndex} reduceMotion color="rarchy-color-sitemap-editor">
            <AccordionItem border="none">
                <AccordionButton px={3} py={1.5}>
                    <Box as='span' fontSize="sm" fontWeight="medium" flex='1' textAlign='left' pr={3}>
                        <HStack justifyContent="space-between">
                            <Box>Labels</Box>
                            <NewLabelButton
                                showNewLabelPanel={showNewLabelPanel}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIndex(0)
                                    if (inUserEditorDoc) {
                                        dispatch(toggleUpgradeModal({ showing: true, screen: 'page-colors' }))
                                    } else {
                                        setShowNewLabelPanel(!showNewLabelPanel);
                                    }
                                }}
                                colors={colors}
                            />
                        </HStack>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel p={0}>
                    {showNewLabelPanel && <NewLabelPanel colors={colors} />}
                    <Labels colors={colors} selectedColor={selectedColor} handlePalletteChange={handlePalletteChange} />
                    <Box px={2.5}>
                        <ResetButton my={1} selectedColor={selectedColor} handlePalletteChange={handlePalletteChange} />
                    </Box>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem border="none">
                <AccordionButton px={3} py={1.5}>
                    <Box as='span' fontSize="sm" fontWeight="medium" flex='1' textAlign='left'>
                        Colors
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px={0} py={1.5}>
                    <Box px={3}>
                        <Pallette selectedColor={selectedColor} handlePalletteChange={handlePalletteChange} />
                    </Box>
                    <Box px={2.5}>
                        <ResetButton mt={3.5} selectedColor={selectedColor} handlePalletteChange={handlePalletteChange} />
                    </Box>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}