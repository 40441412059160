import { blobToBase64, defaultPalletteColors, getBase64Image, getInUserFlow, getSitemap, getUI, getUser, getUserFlow } from '../../../../../helpers';
import { cloneDeep, compact, isEmpty } from 'lodash';
import { collection, doc, getFirestore, updateDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"

import Compressor from 'compressorjs';
import { getPalletteColors } from '../../../../../store/actions/editor-actions';
import { getWidthAndHeight } from './svg';
import { render as renderSitemap } from '../../../../Sitemap/app/canvas/render';
import { render as renderUserFlow } from '../../../../Sitemap/user-flows/render';
import { zoomIdentity } from 'd3';

export const exportThumb = async () => {

    const light = "white";
    const dark = "#1A202C";

    const header = {
        light: defaultPalletteColors[0],
        dark: defaultPalletteColors[1]
    }

    const pallettes = {
        light: getPalletteColors(header.light),
        dark: getPalletteColors(header.dark)
    }

    const shouldSaveToStorage = true;

    const ui = getUI()
    const user = getUser()
    const sitemap = getSitemap()
    const flow = getUserFlow()

    if (isEmpty(sitemap?.data?.nodes)) return null; // don't get thumbnail if no nodes
    if (sitemap?.data?.section) return null; // don't get thumbnail if in subfolders

    console.log('getting thumbnail??')

    const inUserFlow = getInUserFlow()

    if (inUserFlow) return null; // only for sitemaps atm

    const { nodes } = inUserFlow ? flow?.data : sitemap?.data;

    let forThumbnail = inUserFlow ? nodes : [{ ...cloneDeep(nodes[0]), x: 0, y: 0 }];

    // this will be updated with new urls (if changed), and returned
    const thumbnail = { ...(inUserFlow ? flow : sitemap)?.thumbnail };

    if (!inUserFlow) {

        const isTree = sitemap?.format === 'tree';
        const x = isTree ? 'y' : 'x';
        const y = isTree ? 'x' : 'y'

        let closestChildUnderneathHome = findClosest(0, forThumbnail[0].children);

        const nodeWidthWithMargin = 225 + 30 // 30 is margin

        const cindex = closestChildUnderneathHome?.index;
        const indexes = [cindex - 1, cindex, cindex + 1];
        const start = -nodeWidthWithMargin;

        indexes.forEach((index, i) => {
            try {
                const node = forThumbnail?.[0]?.children?.[index];
                if (node) {
                    node[x] = start + (nodeWidthWithMargin * i);
                    node[y] = forThumbnail[0].nodeHeight + 60 // 326.5;
                    forThumbnail.push(node)
                }
            } catch (e) {
                console.error(e);
            }
        })

    }

    forThumbnail = compact(forThumbnail)

    const { width, height, left, top } = getWidthAndHeight(sitemap, { forThumbnail });

    const render = !inUserFlow ? renderSitemap : renderUserFlow;

    var ctx = window.C2S ? new window.C2S(width, height) : null;
    if (!ctx) return;

    const { base64CoversForExport } = await render(null, null, ctx, { forThumbnail });
    var cloned = ctx.getSvg();

    const transform = (svg) => {
        if (inUserFlow || (!inUserFlow && sitemap?.format !== 'nodes')) {
            var all = cloned.getElementsByTagName("*");
            for (var i = 0, max = all.length; i < max; i++) {
                const el = all[i];
                if (el) {
                    // get and set xy for images
                    if (el.tagName === 'image') {

                        if (base64CoversForExport[el.href.baseVal]) { el.setAttributeNS(null, 'href', base64CoversForExport[el.href.baseVal]); };

                        if (el.href.baseVal.includes('/svg')) {
                            el.setAttributeNS(null, 'href', el.href.baseVal);
                            el.removeAttribute('xlink:href'); // this has to be after base64 
                        }

                    }
                    // remove transparent rect
                    if (el.tagName === 'rect' && el.getAttribute('fill') === 'transparent') {
                        el.remove();
                    }
                }
            }
        };
        return svg;
    }

    const svgs = [{ colorMode: 'light', svg: await transform(cloned) }, { colorMode: 'dark', svg: await transform(cloned) }]

    await Promise.all(svgs.map(async (s, i) => {

        var fitted = zoomIdentity.translate(Math.abs(left), (Math.abs(top)));
        var g = cloned.getElementsByTagName("g")[1];
        if (g) {
            g.setAttribute('transform', `translate(${fitted.x},${fitted.y}) scale(${fitted.k})`);
        };

        // replace all 'transparents' to none (otherwise sketch messes up)
        var svgData = s.svg?.outerHTML.replaceAll("transparent", "none").replaceAll("Inter,sans-serif", "Helvetica")

        if (s.colorMode === 'light' && ui.colorMode !== s.colorMode) {
            svgData = svgData.replaceAll(dark, light).replaceAll(header.dark, header.light)
            pallettes[ui.colorMode].forEach((color, i) => {
                svgData = svgData.replaceAll(color, pallettes[s.colorMode][i]);
            })
        };

        if (s.colorMode === 'dark' && ui.colorMode !== s.colorMode) {
            svgData = svgData.replaceAll(light, dark).replaceAll(header.light, header.dark)
            pallettes[ui.colorMode].forEach((color, i) => {
                svgData = svgData.replaceAll(color, pallettes[s.colorMode][i]);
            })
        };

        const worker = new Worker(new URL('./worker.js', import.meta.url), {
            type: 'module'
        });

        worker.postMessage({
            width,
            height,
            svg: svgData,
            colorMode: s.colorMode
        })

        worker.onmessage = async (event) => {

            worker.terminate()

            const { blob } = event.data || {}

            if (!blob) return;

            const compressed = await new Promise((resolve, reject) => {
                new Compressor(blob, {
                    quality: s.colorMode === 'light' ? 0.4 : 0.9,
                    success: resolve,
                    error: reject,
                });
            });

            const oldBase64 = await getBase64Image(thumbnail.images?.[s.colorMode])
            const newBase64 = await blobToBase64(compressed)

            // really important - workaround for data:image/jpg vs data:image/jpeg which is throwing off the comparison
            const stripOldBase64 = (oldBase64 || "")?.split(',')?.[1];
            const stripNewBase64 = (newBase64 || "")?.split(',')?.[1];

            if (stripOldBase64 !== stripNewBase64) {

                console.log(`saving ${s.colorMode} thumbnail`);

                const storage = getStorage();
                const storageRef = ref(storage, `sitemaps/${sitemap?.id}/thumbnails/${s.colorMode}.jpg`);

                if (shouldSaveToStorage) {

                    const snapshot = await uploadBytes(storageRef, compressed, { cacheControl: 'max-age=31557600', contentType: 'image/jpg' })

                    const downloadURL = await getDownloadURL(snapshot.ref)

                    if (downloadURL !== thumbnail?.images?.[s.colorMode]) {

                        if (!thumbnail?.images) thumbnail.images = {} // fail-safe

                        thumbnail.images[s.colorMode] = downloadURL; // update downloadURL

                        /*** upload to firestore ***/
                        if (i === svgs?.length - 1) { // last thumbnail has been created

                            const firestore = getFirestore();

                            const collectionRef = collection(firestore, 'sitemaps')
                            const docRef = doc(collectionRef, sitemap?.id)

                            if (shouldSaveToStorage) {
                                try {
                                    await updateDoc(docRef, {
                                        thumbnail,
                                        updatedBy: user.id,
                                        updatedAt: new Date()
                                    })
                                } catch (e) {
                                    console.error(e)
                                }
                            }
                        }
                        /*** upload to firestore ***/

                    } else {

                        console.log('Returned the same downloadURL for a new thumb upload')

                    }

                }

                if (!shouldSaveToStorage) {
                    const img = document.getElementById(s.colorMode)
                    if (img) img.src = URL.createObjectURL(compressed)
                }

            }

        }

    }))

    return thumbnail

}

function findClosest(parentX, array) {
    return array?.reduce((best, current) => {
        return (current.x >= parentX && (!best || current.x < best.x))
            ? current
            : best;
    }, undefined);
}