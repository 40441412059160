
import { getX, getY, getRandomColor } from '../../../canvas/utils/helpers';
import { colors, colorByNodeIds } from '../../../canvas/utils/drag';
import { transform } from '../../../canvas/utils/zoom';
import { NODE_WIDTH } from '../../../canvas/components/drag';
import { getSitemap } from '../../../../../../helpers';

export const spacing = (context, nodes) => {

    nodes.forEach((d, i) => {
        if (!d) return;
        if (d.parent) {
            const x = getX(d), y = getY(d);
            addAbovePlaceholder(context, d, x, y, nodes, i);
            addBelowPlaceholder(context, d, x, y, nodes, i);
        }
    });

}

const addAbovePlaceholder = (context, d, x, y, nodes, nodeIndex) => {

    const sitemap = getSitemap();

    var margin = sitemap?.showCovers ? 30 : 20;

    const nodeAbove = nodes[nodeIndex - 1] && nodes[nodeIndex - 1].depth === d.depth ? nodes[nodeIndex - 1] : null;


    if (nodeAbove) {
        const barHeight = nodeAbove.nodeHeight;
        if ((nodeAbove.id === 'dragging-placeholder') && (nodeAbove.parent.id === d.parent.id)) return;
        const distanceToNodeAbove = d.x - (nodeAbove.x + barHeight);
        if (distanceToNodeAbove <= (sitemap?.showCovers ? 41 : 23)) margin = sitemap?.showCovers ? 15 : 11.5;
    }

    if (!d.overflow && d.parent) {
        var randomColorForAbove;
        if (!colorByNodeIds[d.id] || !colorByNodeIds[d.id]['above']) {
            /*** assign new random color to node id ***/
            randomColorForAbove = getRandomColor();
            colorByNodeIds[d.id] = { ...colorByNodeIds[d.id], 'above': randomColorForAbove };
            colors[randomColorForAbove] = { node: d, side: 'above' };
            /*** assign new random color to node id ***/
        } else {
            randomColorForAbove = colorByNodeIds[d.id]['above']; // use existing assigned color
        }

        context.fillStyle = randomColorForAbove;

        if (context.canvas.id === 'picker-canvas') {
            context.fillRect(transform.applyX(x), transform.applyY(y - margin), NODE_WIDTH * transform.k, margin * transform.k);
        } else {
            context.fillRect(x, y - margin, NODE_WIDTH, margin);
        }
    }

}

const addBelowPlaceholder = (context, d, x, y, nodes, nodeIndex) => {

    const sitemap = getSitemap();

    var margin = sitemap?.showCovers ? 30 : 20;

    const nodeBelow = nodes[nodeIndex + 1] && nodes[nodeIndex + 1].depth === d.depth ? nodes[nodeIndex + 1] : null;

    if (nodeBelow) {
        const barHeight = nodeBelow.nodeHeight;
        if ((nodeBelow.id === 'dragging-placeholder') && (nodeBelow.parent.id === d.parent.id)) return;
        const distanceToNodeBelow = nodeBelow.x - (d.x + barHeight);
        if (distanceToNodeBelow <= (sitemap?.showCovers ? 41 : 23)) margin = sitemap?.showCovers ? 15 : 11.5;
    }

    if (!d.overflow && d.parent) {
        var randomColorForBelow;
        if (!colorByNodeIds[d.id] || !colorByNodeIds[d.id]['below']) {
            /*** assign new random color to node id ***/
            randomColorForBelow = getRandomColor();
            colorByNodeIds[d.id] = { ...colorByNodeIds[d.id], 'below': randomColorForBelow };
            colors[randomColorForBelow] = { node: d, side: 'below' };
            /*** assign new random color to node id ***/
        } else {
            randomColorForBelow = colorByNodeIds[d.id]['below']; // use existing assigned color
        }

        context.fillStyle = randomColorForBelow;

        const barHeight = nodes[nodeIndex].nodeHeight;

        if (context.canvas.id === 'picker-canvas') {
            context.fillRect(transform.applyX(x), transform.applyY(y + barHeight), NODE_WIDTH * transform.k, margin * transform.k);
        } else {
            context.fillRect(x, y + barHeight, NODE_WIDTH, margin);
        }

    }

}