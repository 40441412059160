import {
    Box,
    Button,
    Divider,
    HStack,
    IconButton,
    PopoverContent
} from "@chakra-ui/react"
import React, { useState } from 'react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import { ColorPicker } from '../../../../../Editor/Toolbar/Colors/Components/ColorPicker';
import { Input } from '../../../../../Editor/Toolbar/Colors/Components/Input'
import { Pallette } from '../../../../../Editor/Toolbar/Colors/Components/Pallette';
import { ResetButton } from "../../../../../Editor/Toolbar/Colors/Components/ResetButton";
import { getDefaultPalletteHeader } from '../../../../../../helpers';

export const ColorContent = ({ handlePalletteChange, inputVal, setInputVal, inputColor, onClose }) => {

    const [showColorPicker, setShowColorPicker] = useState(false)

    return (
        <PopoverContent w="210px" px={3} pt={1} pb={3} onClick={(e) => e.stopPropagation()}>
            <HStack justifyContent="space-between" mt={1} w="full">
                <IconButton variant="ghost" size="xs" icon={<ArrowBackIcon fontSize="lg" />} onClick={() => { onClose(); }} />
                {/* <Button
                        variant="ghost"
                        size="sm"
                        justifyContent="end"
                        fontWeight="400"
                        fontSize="sm"
                        maxW="86px"
                        h={7}
                        onClick={() => handlePalletteChange({ header: getDefaultPalletteHeader() })}
                    >
                        Reset
                    </Button> */}
            </HStack>
            <Box pt={2}>
                <Pallette selectedColor={inputColor} handlePalletteChange={handlePalletteChange} />
                <ResetButton selectedColor={inputColor} mt={3.5} handlePalletteChange={() => handlePalletteChange({ header: getDefaultPalletteHeader() })} />
            </Box>
            {/* <Pallette handlePalletteChange={handlePalletteChange} />
            <Divider mt={1} mb={-1} />
            <Input inputVal={inputVal} setInputVal={setInputVal} handlePalletteChange={handlePalletteChange} inputColor={inputColor} showColorPicker={showColorPicker} setShowColorPicker={setShowColorPicker} />
            {showColorPicker && <ColorPicker inputColor={inputColor} handlePalletteChange={handlePalletteChange} />} */}
        </PopoverContent>
    )
}