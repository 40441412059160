import { HStack, IconButton, Skeleton, SkeletonCircle, Stack, Tooltip } from "@chakra-ui/react"

import { AiOutlineQuestionCircle } from "react-icons/ai"
import { ColorMode } from "../../../../Editor/Navbar/ColorMode"
import { Comments } from "../../../../Editor/Navbar/Comments"
import { Link } from "react-router-dom"
import { ProfileDropdown } from "./ProfileDropdown"
import { Search } from "../../../../Editor/Navbar/Search"
import { SettingsIcon } from "@chakra-ui/icons"
import { Share } from "../../../../Editor/Navbar/Share"
import { SignUp } from "../../../../Editor/Navbar/Signup"
// import { Notifications } from "../../../../Editor/Navbar/Notifications"
import { useInEditor } from "../../../../../hooks"

export const Right = () => {
    const inEditor = useInEditor()
    return (
        <Stack direction="row" spacing={4}>
            <Search />
            <HStack spacing={0}>
                <ColorMode />
                {inEditor && (
                    <Comments />
                )}
                {/* inEditor && isLoggedIn && <SkeletonCircleWrapper><Notifications /></SkeletonCircleWrapper>*/}
                <Link to="/app/help">
                    <Tooltip variant="rounded-sm" openDelay={250} label="Support" hasArrow>
                        <IconButton
                            icon={<AiOutlineQuestionCircle fontSize="20px" color="rarchy-color-sitemap-editor" />}
                            variant="ghost"
                            isRound
                        />
                    </Tooltip>
                </Link>
                {!inEditor && (
                    <Link to="/app/settings/your-account">
                        <Tooltip variant="rounded-sm" openDelay={250} label="Settings" hasArrow>
                            <IconButton
                                icon={<SettingsIcon fontSize="lg" color="rarchy-color-sitemap-editor" />}
                                variant="ghost"
                                isRound
                            />
                        </Tooltip>
                    </Link>
                )}
            </HStack>
            <HStack spacing={4}>
                {/* !inEditor && <CreateDropdown /> */}
                {inEditor && <Share />}
                {inEditor && <SignUp />}
                <ProfileDropdown />
            </HStack>
        </Stack>
    )
}

const SkeletonCircleWrapper = ({ isLoaded, children }) => {
    return (
        <SkeletonCircle
            w={isLoaded ? "fit-content" : "32px"}
            h={isLoaded ? "fit-content" : "32px"}
            isLoaded={isLoaded}
        >
            {children}
        </SkeletonCircle>
    )
}