import { useGetSitemap } from "../../../../../../hooks";
import { useMemo } from "react";
import { WireframeGrid } from ".";
import { isEmpty, orderBy, values, groupBy, compact, map } from 'lodash'

export const Popular = ({ searched }) => {

    const sitemap = useGetSitemap()
    const { collections, pages } = sitemap?.covers;

    const results = useMemo(() => orderBy(values(groupBy(compact(map(pages, 'collection')))).map(d => ({ key: d[0], count: d.length })), 'count', 'desc'), [pages])
    
    const data = useMemo(() => (results || [])?.map(o => {
        const collection = o?.key?.split("/")[0]
        return collections?.[collection]?.[o?.key]
    }), [results])

    if (isEmpty(results)) return null;
    if (!isEmpty(searched)) return null;

    return <WireframeGrid name="popular" data={data} />
}