import { Center, Stack, Text, Icon, Button, Box } from "@chakra-ui/react"
import { BorderedBox, SectionHeader } from "../../Components"
import { useGetClaims, } from "../../../../hooks"
import { getUsersFullName } from "../../../../helpers"
import { GoogleIcon } from "../../../Auth/SignupLogin/Components/ProviderIcons"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const ConnectedSocial = () => {
    const claims = useGetClaims()
    const name = getUsersFullName()
    const isConnectedToGoogle = Object.keys((claims?.firebase?.identities) || [])?.includes('google.com');
    return (
        <Stack spacing={3} mt={1.5}>
            <SectionHeader title="Connected social accounts" />
            <Box mt={isConnectedToGoogle ? 6 : 1.5} fontSize="sm">
                <SkeletonWrapper w="fit-content">
                    {isConnectedToGoogle ? "Services that you use to log in to Rarchy" : "You don't use any social accounts to log in to Rarchy"}
                </SkeletonWrapper>
            </Box>
            {isConnectedToGoogle && (
                <BorderedBox mt={1}>
                    <Stack direction="row" align="center" justify="space-between">
                        <Stack direction="row" align="center" spacing={5}>
                            <SkeletonWrapper w="max-content">
                                <Center border="1px solid" borderColor="rarchy-border" p={4} rounded="sm">
                                    <Icon as={GoogleIcon} fontSize="lg" />
                                </Center>
                            </SkeletonWrapper>
                            <Stack spacing={1.5}>
                                <SectionHeader title="Google" />
                                <SkeletonWrapper>
                                    <Text fontSize="sm">{name}</Text>
                                </SkeletonWrapper>
                            </Stack>
                        </Stack>
                        <SkeletonWrapper w="fit-content">
                            <Button fontSize="sm" px={5} isDisabled>Disconnect</Button>
                        </SkeletonWrapper>
                    </Stack>
                </BorderedBox>
            )
            }
        </Stack >
    )
}