import { useFirebase } from "react-redux-firebase"
import { Button, Stack, Text, useBoolean } from "@chakra-ui/react"
import { BorderedBox, MainHeader, MidHeader } from "../../../Components"
import { useGetSubscription, useIsOrganizationAdmin } from "../../../../../hooks"
import { useGetPriceWithCurrencySymbol } from "../../../../../../shared/hooks/pricing"

import { useGetShortPlanId } from "../../../../../../shared/hooks"

import { handleCustomerPortalSubscriptionUpdate } from "."
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"
import { UpgradeAnnual } from "../UpgradeAnnual"

export const Plan = () => {
    const firebase = useFirebase()
    const shortPlanId = useGetShortPlanId()
    const subscription = useGetSubscription()
    const isAdmin = useIsOrganizationAdmin()
    const [isLoading, setIsLoading] = useBoolean()
    const { customer_id, id: subscription_id, plan } = subscription || {};
    const price = plan?.amount / 100
    const pricewithCurrency = useGetPriceWithCurrencySymbol(price)
    const interval = plan?.interval === 'year' ? 'year' : 'month'
    return (
        <>
            <MidHeader title="Base plan" />
            <BorderedBox p={4}>
                <Stack spacing={5}>
                    <MainHeader title={<Text textTransform="capitalize">{`Rarchy ${shortPlanId}`}</Text>} />
                    <Stack w="full" direction="row" align="baseline" justify="space-between">
                        <SkeletonWrapper w="fit-content">
                            <Text fontSize="sm" fontWeight="medium">{`${pricewithCurrency} / ${interval}`}</Text>
                        </SkeletonWrapper>
                        <SkeletonWrapper w="fit-content">
                            {isAdmin && <UpgradeAnnual price={price} />}
                        </SkeletonWrapper>
                    </Stack>
                    {isAdmin && (
                        <SkeletonWrapper w="fit-content">
                            <Button
                                fontSize="sm"
                                isLoading={isLoading}
                                onClick={() => {
                                    handleCustomerPortalSubscriptionUpdate({ setIsLoading, customer_id, subscription_id, firebase })
                                }}>
                                Update plan
                            </Button>
                        </SkeletonWrapper>
                    )}
                </Stack>
            </BorderedBox>
        </>
    )
}