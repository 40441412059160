import { ChevronRightIcon } from "@chakra-ui/icons"
import { NavItem } from "../Components/NavItem"
import { HiOutlineFolder, HiOutlineTemplate } from "react-icons/hi"
import { Starred } from "../Starred"
import { useInOrganization } from "../../../hooks"

export const HomeMenu = () => {
    const inOrganization = useInOrganization()
    return (
        <>
            <NavItem
                pathname="/app/projects"
                icon={<HiOutlineFolder />}
                label="Projects"
                endElement={<ChevronRightIcon fontSize="2xl" color="fg.subtle" />}
            />
            <NavItem
                pathname="/app/templates"
                icon={<HiOutlineTemplate />}
                label="Templates"
                endElement={<ChevronRightIcon fontSize="2xl" color="fg.subtle" />}
            />
            {inOrganization && <Starred inSideMenu />}
        </>
    )
}