import { Editable, EditableInput, EditablePreview, Tooltip, useBoolean } from "@chakra-ui/react"
import { useCanEditFile, useInEditor, useInTrash, useIsTextOverflown } from "../../../hooks"
import { useEffect, useRef, useState } from "react"

import { renameFile } from "../../../store/actions/files-actions"
import { useDispatch } from "react-redux"

export const FileName = ({ file, opts = {} }) => {

    const [isEditing, setIsEditing] = useBoolean(false)

    const dispatch = useDispatch()
    const inTrash = useInTrash()

    const nameElementRef = useRef(null)
    const isNameOverflown = useIsTextOverflown(nameElementRef)
    
    const inEditor = useInEditor()
    const canEditFile = useCanEditFile()
    const canEdit = !inEditor || inEditor && canEditFile

    // set val if sitemap name has changed
    useEffect(() => {
        if (file?.name !== val) setVal(file?.name);
    }, [file?.name]);
    const [val, setVal] = useState(file?.name)
    
    const { w, fontSize, fontWeight } = opts

    const docId = file?.id
    const collectionId = file?.collection

    return (
        <Tooltip isDisabled={isEditing || !isNameOverflown} label={file?.name} variant="rounded-sm" openDelay={500} hasArrow>
            <Editable
                isPreviewFocusable={!inTrash || canEdit}
                isDisabled={inTrash || !canEdit}
                variant="flushed"
                p={0}
                value={val}
                fontSize={fontSize || "sm"}
                fontWeight={fontWeight || "medium"}
                minW={inEditor ? "unset" : "full"}
                maxW={inEditor ? "md" : (w || "full")}
                onEdit={setIsEditing.toggle}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                onSubmit={(name) => {
                    if (name && name !== file?.name) dispatch(renameFile({ collectionId, docId, name }))
                }}
            >
                <EditablePreview ref={nameElementRef} p={0} w="full" />
                <EditableInput p={0} onChange={(e) => setVal(e.target.value)} />
            </Editable>
        </Tooltip>
    )
}