import { createContext, useState } from "react"
import { useBoolean } from "@chakra-ui/react"
import { useGetOrganizationUsers } from "../../hooks"

export const AddUsersContext = createContext()

export const AddUsersHOC = ({ onClose, children }) => {
    const people = useGetOrganizationUsers()
    const [isSelectFocused, setIsSelectFocused] = useBoolean()
    const [inputValue, setInputValue] = useState('')
    const [peopleToInvite, setPeopleToInvite] = useState([])
    return (
        <AddUsersContext.Provider
            value={{
                people,
                onClose,
                isSelectFocused,
                setIsSelectFocused,
                inputValue,
                setInputValue,
                peopleToInvite,
                setPeopleToInvite
            }}>
            {children({ inputValue, setInputValue, setPeopleToInvite, peopleToInvite })}
        </AddUsersContext.Provider>
    )
}