
import canvasTxt from './text/canvasTxt';
// import canvasTxt from 'canvas-txt';
import { store } from '../../../../../store';
import { barWidth } from '../..';
import { getX, getY, getRandomColor, getPageColor, getDeviceByPage, shouldShowCovers } from '../utils/helpers';
import { mainCanvas } from '../canvases';

import { renamePage } from './text/utils';
import { getPageName } from '../../../utils/app';
import { getCanvasTextEditor, getInUserFlow } from '../../../../../helpers';
import { getPalletteColors } from '../../../../../store/actions/editor-actions';

import { event } from 'd3';

var colors = {};
const colorByNodeIds = {};

export const renderText = (context, d, hidden, pdf, renaming) => {

    const { ui } = store.getState();
    const CanvasTextEditor = getCanvasTextEditor()

    const inUserFlow = getInUserFlow()
    const showCovers = shouldShowCovers(d);

    var name = typeof renaming === 'string' ? renaming : inUserFlow ? getNameForUserFlowPage(d) : getPageName(d.name);

    const renamingPage = (CanvasTextEditor.showing && !CanvasTextEditor.section && CanvasTextEditor.node.id === d.id);

    /*** text-background ***/

    /*** assign event colors ***/
    var randomColor;
    if (hidden) {
        if (!colorByNodeIds[d.id]) {
            /*** assign new random color to node id ***/
            randomColor = getRandomColor();
            colorByNodeIds[d.id] = randomColor;
            colors[randomColor] = d;
            /*** assign new random color to node id ***/
        } else {
            randomColor = colorByNodeIds[d.id]; // use existing assigned color
        }
    };
    /*** assign event colors ***/

    context.lineJoin = 'round';
    context.fillStyle = (() => {
        if (!hidden) return showCovers ? ui.colorMode === 'light' ? 'white' : '#1A202C' : 'transparent'
        return randomColor
    })();

    /*** text-background ***/

    const y = getY(d) + (showCovers ? (d.nodeHeight - d.textRectHeight - 1) : 25)

    const device = getDeviceByPage(d)
    roundRect(context, getX(d) + 1, y + (hidden ? 12.5 : 0)/* + (showCovers ? 111 : 16.75) + (hidden ? 12.5 : 0)*/, barWidth - 2, !hidden ? d.textRectHeight : d.actualTextHeight, device === 'desktop' ? 4 : 14)

    if (renamingPage) return; // only render text on the interactions canvas if renaming

    /*** text ***/
    if (!hidden && name) {

        var fontSize = 17;
        var font = `${!pdf ? 600 : 500} ${fontSize}px Inter,sans-serif`;

        var x = getX(d) + (showCovers ? 12.5 : 17.5);

        var maxWidth = showCovers ? 200 : 195;
        var lineHeight = 21;

        const pallette = getPalletteColors(getPageColor(d))
        const fillStyle = pallette[7];

        var paddingTop = 10;

        canvasTxt.fontSize = fontSize;
        canvasTxt.align = showCovers ? 'center' : 'left';
        canvasTxt.fontWeight = !pdf ? 600 : 500;
        canvasTxt.font = 'Inter,sans-serif';
        canvasTxt.lineHeight = lineHeight;
        canvasTxt.vAlign = 'top';
        context.font = font;
        context.fillStyle = fillStyle;
        canvasTxt.drawText(context, name, x, y + paddingTop, maxWidth, lineHeight)
        context.textAlign = 'start'; // reset context text-align

    }
    /*** text ***/
};

function roundRect(ctx, x, y, width, height, radius, hidden) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, 0);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, 0);
    ctx.closePath();
    ctx.fill();
};

export const getNameForUserFlowPage = (d) => {
    const { sitemap } = store.getState()
    const page = sitemap?.docs.pages[d.page]
    if (page) return getPageName(page.name)
    if (d.text) return d.text
    if (d.name) return d.name
    return ''
}

export const mouseoverText = (colKey) => {
    var node = colors[colKey];
    if (node) {
        const { editor, sitemap, flow } = store.getState();
        if (editor?.ui.RevisionHistoryDrawer.showing) return // no clicking in revision history

        const inUserFlow = getInUserFlow()
        const nodes = !inUserFlow ? sitemap?.data.nodes : flow.data.nodes

        node = nodes.find(d => d.id === node.id) // get most up to date node data
        return node;
    }
};

export const clickText = (colKey) => {
    const { editor, sitemap, flow } = store.getState();
    if (editor?.ui.RevisionHistoryDrawer.showing) return // no clicking in revision history
    const inUserFlow = getInUserFlow()
    var node = colors[colKey];
    if (node) {
        const nodes = !inUserFlow ? sitemap?.data.nodes : flow.data.nodes
        node = nodes.find(d => d.id === node.id) // get most up to date node data
        mainCanvas.renaming = colors[colKey] = node
        // prevent default
        event.preventDefault();
        event.stopImmediatePropagation();
        // rename
        renamePage(node);
    }
};