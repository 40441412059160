import { Box } from "@chakra-ui/react"
import { useGetProjectsPathFilter, useGetProjectsSortFilter } from "../../../../../hooks"
import { Files } from "../../../../Files"
import { Folders } from "../../../Folders"

export const SectionsGrid = ({ tab }) => {

    const owner = useGetProjectsSortFilter('owner')
    const hasPathFilter = useGetProjectsPathFilter()

    const isFolders = tab.id === 'folders'
    const hasSharedFilter = hasPathFilter === 'shared-with-you' || owner === 'shared'

    return (
        <Box mt={3}>
            {(isFolders/* && !hasSharedFilter*/) && <Folders />}
            <Files collection={tab?.id} />
        </Box>
    )
}