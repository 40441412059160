import { Box, DarkMode, Icon, Image, Stack, Text, useBoolean } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons";

export const RightScreenshots = () => {
    const [showScrollButton, setShowScrollButton] = useBoolean(true)
    return (
        <Stack p={10} spacing={4} bgColor="rarchy-bg-upgrade-modal" onMouseLeave={setShowScrollButton.on}>
            <Box rounded="md" overflow="scroll" onScroll={setShowScrollButton.off} h="500px">
                <Image w="496px" key="revision-history-bg" src="https://help.rarchy.com/hubfs/Apple%20-%20Full-page.jpeg" />
                {showScrollButton && (
                    <DarkMode>
                        <Stack
                            spacing={0}
                            align="center"
                            pos="absolute"
                            ml="200px"
                            top="420px"
                        >
                            <Box color="fg.default">Scroll down</Box>
                            <Icon as={ChevronDownIcon} color="fg.default" fontSize="4xl" />
                        </Stack>
                    </DarkMode>
                )}
            </Box>
            <DarkMode>
                <Text color="fg.muted" fontSize="sm" fontStyle="italic">apple.com (full-page screenshot captured by Rarchy)</Text>
            </DarkMode>
        </Stack>
    )
}