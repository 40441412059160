import { coversCanvas } from './covers'
import { getInUserFlow } from '../../../../../helpers'
import { interactionsCanvas } from './interactions'
import { mainCanvas } from './main'
import { pickerCanvas } from './picker'
import { render as renderPages } from '../render'
import { render as renderUserFlow } from '../../../user-flows/render'
import { select } from 'd3'

export { CoversCanvas, coversCanvas } from './covers'
export { MainCanvas, mainCanvas } from './main'
export { PickerCanvas, pickerCanvas } from './picker'
export { InteractionsCanvas, interactionsCanvas, renderInteractionsCanvas } from './interactions'

CanvasRenderingContext2D.prototype.clear = CanvasRenderingContext2D.prototype.clear || function (preserveTransform) {
    if (preserveTransform) {
        this.save();
        this.setTransform(1, 0, 0, 1, 0, 0);
    }

    this.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (preserveTransform) this.restore();
};

const scaleCanvas = (canvas, context) => {

    var parent = document.getElementById("canvases-container");

    let width = parent?.clientWidth
    let height = parent?.offsetHeight

    if (!canvas) return;

    // assume the device pixel ratio is 1 if the browser doesn't specify it
    const devicePixelRatio = window.devicePixelRatio || 1;

    // determine the 'backing store ratio' of the canvas context
    const backingStoreRatio = (
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio || 1
    );

    // determine the actual ratio we want to draw at
    const ratio = devicePixelRatio / backingStoreRatio;

    if (devicePixelRatio !== backingStoreRatio) {
        // set the 'real' canvas size to the higher width/height
        canvas.width = width * ratio;
        canvas.height = height * ratio;

        // ...then scale it back down with CSS
        canvas.style.width = width + 'px';
        canvas.style.height = height + 'px';
    }
    else {
        // this is a normal 1:1 device; just scale it simply
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = '';
        canvas.style.height = '';
    }

    // scale the drawing context so everything will work at the higher ratio
    context.scale(ratio, ratio);

}

export const resizeCanvas = () => {
    const canResize = isResizing();
    const inUserFlow = getInUserFlow()
    if (canResize) !inUserFlow ? renderPages() : renderUserFlow()
}

const isResizing = () => {

    const canvases = [mainCanvas, pickerCanvas, coversCanvas, interactionsCanvas];

    var parent = document.getElementById('canvases-container');

    let width = parent?.offsetWidth
    let height = parent?.offsetHeight

    canvases.forEach(canvas => {

        const { id } = canvas?.canvas

        if (canvas?.canvas) {

            canvas.canvas.width = width
            canvas.canvas.style.width = width
            canvas.canvas.height = height
            canvas.canvas.style.height = height
        }

        const c = select(`#${id}`)
        scaleCanvas(c.node(), canvas);

    })

    return true;

}