import {
    Box,
    Button,
    PopoverTrigger
} from "@chakra-ui/react"

import { ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react';
import { RiPaintFill } from 'react-icons/ri';

export const TriggerButton = () => {
    return (
        <PopoverTrigger>
            <Box cursor="pointer" bgColor="rarchy-bg-sitemap-editor" rounded="md">
                <Button
                    variant="outline"
                    size="sm"
                    iconSpacing={3}
                    lineHeight="inherit"
                    leftIcon={<RiPaintFill />}
                    rightIcon={<ChevronDownIcon fontSize="xl" ml={-1} />}
                    color="rarchy-color-sitemap-editor"
                    borderColor="rarchy-border-sitemap-editor"
                    // borderWidth={0}
                    bgColor="rarchy-bg-sitemap-editor"
                >
                    Color
                </Button>
            </Box>
        </PopoverTrigger>
    )
}