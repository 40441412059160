import { isEmpty } from 'lodash'

const keys = ['pages', 'website_sections', 'sections', 'covers', 'commments']

export const isSavingChanges = (sitemap, flow) => {
    var saving = false
    // user flows
    if (flow.loaded) {
        if (!isEmpty(flow.changes.data)) saving = true
        if (flow.changes.saving) saving = true
    }
    // sitemap
    keys.forEach(k => {
        if (sitemap?.changes[k]) {
            if (!isEmpty(sitemap?.changes[k].data)) saving = true
            if (sitemap?.changes[k].saving) saving = true
        }
    })
    return saving
};

export const hasSavedChanges = (sitemap, prevSitemap) => {
    var saving = false;
    keys.forEach(k => {
        if (sitemap?.changes[k] && prevSitemap) {
            if ((!sitemap?.changes[k].saving && !sitemap?.changes[k].error) && prevSitemap.changes[k].saving) saving = true
        }
    })
    return saving
};

export const getTotalErrorCount = (sitemap) => {
    var errorCount = 0;
    keys.forEach(k => errorCount = errorCount + sitemap?.changes[k].errorCount)
    return errorCount;
}