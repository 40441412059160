import * as React from 'react'
import { AddIcon } from "@chakra-ui/icons";
import { Box, HStack, IconButton, Stack, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom";
import { SkeletonWrapper } from '../../Components/Shared/SkeletonWrapper';

export const NavGroup = (props) => {
    const { label, isLoaded, children } = props
    return (
        <Box>
            <HStack justifyContent="space-between" mb={3} pl={4} pr={2} mt={0}>
                <SkeletonWrapper w="fit-content" isLoaded={isLoaded || null}>
                    <Text
                        fontSize="xs"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        color="white"
                    >
                        {label}
                    </Text>
                    {label === 'Teams' && <Link to="/teams/new"><IconButton mr="-2px" size="xs" variant="ghost" icon={<AddIcon />} /></Link>}
                </SkeletonWrapper>
            </HStack>
            <Stack spacing="1">{children}</Stack>
        </Box>
    )
}