import { Stack } from "@chakra-ui/react"
import { MainHeader, MidHeader } from "../../../Components"
import { useGetOrganization } from "../../../../../hooks"
import { Plan } from "./Plan"
import { Seats } from "./Seats"
import { wait } from "../../../../../../shared/helpers"
import { getFirebase } from "react-redux-firebase"

export const Subscriptions = () => {
    const organization = useGetOrganization()
    return (
        <Stack spacing={6}>
            <MainHeader title={`Subscriptions for your team: ${organization?.name} `} />
            <Plan />
            <MidHeader title="Add-ons" />
            <Seats />
        </Stack>
    )
}

export const handleCustomerPortalSubscriptionUpdate = async ({ setIsLoading, customer_id, subscription_id }) => {
    const firebase = getFirebase()
    // is loading
    setIsLoading?.on()
    // session data
    const data = { customerId: customer_id, flow_data: { type: "subscription_update", subscription_update: { subscription: subscription_id } } };
    // redirect
    const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')(data)
    if (sessionURL?.data) window.location = sessionURL.data;
    // redirected
    await wait(1000)
    setIsLoading?.off()
}