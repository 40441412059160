import React, { useEffect, useRef, useState } from 'react'
import {
    Stack,
    Box,
    Avatar,
    PopoverBody,
    Text,
    useColorModeValue as mode,
    VStack,
    HStack
} from "@chakra-ui/react"

import { RevisionHistory } from './Revision-History'

import { RevisionHistoryDrawer as RevHistoryDrawer } from './Revision-History/Drawer'
import { useGetRevisionHistoryDrawer, useGetSitemap, useGetUser } from '../../../../../../hooks'

import { getAvatarSrc } from '../../../../../../helpers'
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'

import {find} from 'lodash'

dayjs.extend(localizedFormat);

export const Body = ({ canEdit }) => {
    const [lastUserToEdit, setlastUserToEdit] = useState(null)
    const sitemap = useGetSitemap()
    const user = useGetUser()
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
    const color = mode('gray.600', 'gray.300')
    useEffect(() => {
        if (user?.id && (!lastUserToEdit || (lastUserToEdit && lastUserToEdit.id !== sitemap?.updatedBy))) { // check if user as once this is populated in redux, sitemap members array will be fully populated
            if (sitemap?.updatedBy === "import") {
                setlastUserToEdit({ id: "import", firstName: "Imported", lastName: "Pages" })
            } else {
                if (sitemap?.updatedBy === user.id) setlastUserToEdit(user)
                // look in members first
                const userFoundInMembers = find(sitemap?.members, s => s.id === sitemap?.updatedBy)
                if (userFoundInMembers) setlastUserToEdit(userFoundInMembers)
            }
        }
    }, [user, lastUserToEdit, sitemap?.updatedBy, sitemap?.members])
    return (
        <>
            <PopoverBody>
                {canEdit && lastUserToEdit && (
                    <>
                        <VStack direction="row" alignItems="baseline" py={2} pt={1}>
                            <HStack direction="row" justifyContent="space-between" w="full">
                                <Text fontSize="13px" ml={1}>
                                    Last edited by
                                </Text>
                            </HStack>
                            <Stack direction="row" spacing={4} alignItems="center">
                                <Avatar size="sm" src={getAvatarSrc({ user: lastUserToEdit })} />
                                <Box w="80%">
                                    <Text fontSize="sm" fontWeight="semibold" color={color} w="245px" noOfLines={1}>
                                        {`${lastUserToEdit.firstName} ${lastUserToEdit.lastName} ${lastUserToEdit.id === user.id ? `(you)` : ''}`}
                                    </Text>
                                    <Text fontSize="sm" color={color} display="flex" alignItems="center" mt={1}>
                                        <RevisionHistory canEdit={canEdit} />
                                    </Text>
                                </Box>
                            </Stack>
                        </VStack>
                    </>
                )}
                {!canEdit && (
                    <VStack direction="row" alignItems="baseline" py={2}>
                        <Text fontSize="sm" ml={1}>
                            You don't have the required permissions to modify this file.
                        </Text>
                    </VStack>
                )}
                {RevisionHistoryDrawer.showing && <RevHistoryDrawer />} {/* has to be here so it works when logged out */}
            </PopoverBody>
        </>
    )
}