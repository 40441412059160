import { IconButton, Tooltip } from "@chakra-ui/react"

import { FiMoreHorizontal } from "react-icons/fi"
import { useInEditor } from "../../../hooks"

const ICON_COLOR = "rarchy-color-sitemap-editor"

export const FolderFileIconButton = ({ as, icon, onClick, opts = {} }) => {
    const inEditor = useInEditor()
    const { forFolderTopbar, bordered } = opts
    const WHITE = { color: (inEditor || forFolderTopbar) ? 'rarchy-color-sitemap-editor' : 'rarchy-bg-white', bg: (inEditor || forFolderTopbar) ? 'rarchy-bg-subtle-hover' : 'rarchy-link' }
    // return null
    // eslint-disable-next-line
    return (
        <Tooltip openDelay={500} isDisabled={!forFolderTopbar} label="More" hasArrow variant="rounded-sm">
            <IconButton
                as={as ? as : null}
                zIndex={0}
                pos="sticky"
                display="inline-grid"
                colorScheme="blue"
                border={`${bordered ? 1 : 0}px solid`}
                borderColor="rarchy-border"
                color={ICON_COLOR}
                bg="rarchy-bg-sitemap-editor"
                _hover={WHITE}
                _active={WHITE}
                icon={icon || <FiMoreHorizontal />}
                fontSize={forFolderTopbar || inEditor ? "2xl" : "xl"}
                size={forFolderTopbar ? "md" : "sm"}
                p={!inEditor ? 1.5 : 0}
                onClick={(e) => { e.stopPropagation(); if (onClick) onClick() }}
            />
        </Tooltip>
    )
}