import { Heading, VStack } from "@chakra-ui/react";
import { RadioButton, RadioButtonGroup } from "./Components/RadioButtonGroup";
import { useFormikContext } from "formik";

export const MaxDepth = () => {
    const { values, setFieldValue } = useFormikContext()
    return (
        <VStack w="full" spacing={3} alignItems="start">
            <Heading fontSize="md" fontWeight="semibold">Max Depth</Heading>
            <RadioButtonGroup defaultValue={values?.maxDepth?.toString() || "any"} size="md">
                {["any", "1", "2", "3", "4", "5", "6", "7", "8"].map(val => {
                    return <RadioButton key={val} value={val} onClick={() => {
                        setFieldValue("maxDepth", val)
                    }}>{val}</RadioButton>
                })}
            </RadioButtonGroup>
        </VStack>
    )
}