import { ChevronLeftIcon } from "@chakra-ui/icons"
import { AbsoluteCenter, Box, Heading, IconButton, Stack } from "@chakra-ui/react"
import { useGetFoldersByKeyOrPath, useGetAllTopLevelFolders, useGetAllSharedFolders } from "../../../../../../../hooks"
import { useMoveToFolderContext } from "../../../../../../Projects/Folders/hooks"
import { FolderItem } from "./FolderItem"
import { getFromAllTabName } from "../All"
import { isEmpty } from 'lodash'

export const FolderDetail = () => {
    const { showDetailScreen, setShowDetailScreen, searchInputVal } = useMoveToFolderContext()
    const { inFolder, history } = showDetailScreen || {}
    // from all
    const fromAllTab = inFolder?.startsWith('projects/')
    const isSharedWithYou = inFolder === 'projects/shared-with-you'
    // returns filter of folders, looking for folder with folderId 
    const fromAllTabFolder = { id: inFolder, name: getFromAllTabName(inFolder) }
    const normalFolder = (useGetFoldersByKeyOrPath({ key: "id", folderId: inFolder })?.[0] || {})
    const folder = fromAllTab ? fromAllTabFolder : normalFolder
    // get all folders (split out by owner / shared)
    const allTabFolders = useGetAllTopLevelFolders()
    const isSharedWithYouFolders = useGetAllSharedFolders()
    // immediate children of folderId
    const subfolders = useGetFoldersByKeyOrPath({ key: "team", folderId: folder?.id })
    // return folders
    const folders = !fromAllTab ? subfolders : (isSharedWithYou ? isSharedWithYouFolders : allTabFolders)
    // show messaging if empty
    const hasFolders = !isEmpty(folders)
    return (
        <Stack>
            <Stack direction="row" align="center" px={2.5} mb="-2px">
                <IconButton size="sm" variant="ghost" icon={<ChevronLeftIcon fontSize="2xl" />} onClick={() => {
                    let lastFolderId = history.pop()
                    // if parent is equal to folder, pop again (not the best code, but it works)
                    if (lastFolderId === folder?.id) lastFolderId = history.pop()
                    setShowDetailScreen(lastFolderId ? { inFolder: lastFolderId, history } : false)
                }} />
                <Heading noOfLines={1} fontSize="md" fontWeight="semibold" pr={1}>{folder?.name}</Heading>
            </Stack>
            <Box h="40vh" maxH="292px" overflowX="hidden" overflowY="auto">
                {!hasFolders && (
                    <AbsoluteCenter w="full" align="center" fontSize="xs" color="fg.muted">
                        Move here or create a folder.
                    </AbsoluteCenter>
                )}
                {hasFolders && folders?.map(folder => <FolderItem key={folder?.id} folder={folder} />)}
            </Box>
        </Stack>
    )
}