import { getCanvasTextEditor, getInUserFlow, getPageDrawer, getUserFlow } from "../../../../../../helpers";

import { handleDeleteUserFlowConnector } from "../../../../user-flows/utils/Connectors/delete";
import { handleDeleteUserFlowSymbol } from "../../../../user-flows/utils/Symbols/delete";
import { isEmpty } from 'lodash'
import { renderInteractionsCanvas } from "../../canvases";
import { select } from "d3";
import { setSelectedUserFlowSymbols } from "../../../../user-flows/helpers";
import { showingMultiSelectBox } from "../../../../../../store/actions/flow-actions";
import { store } from "../../../../../../store";
import { togglePageDrawer } from "../../../../../../store/actions/sitemap-actions";

export const pressed = {};

export const handleKeyDown = (e) => {

    const { flow } = store.getState();
    const CanvasTextEditor = getCanvasTextEditor()
    const inUserFlow = getInUserFlow();

    if (CanvasTextEditor.showing) return;  // ensure we do anything when renaming 

    // 8 = delete/backspace
    if (e.keyCode == 8) {
        if (CanvasTextEditor.showing) return;  // ensure we don't delete when renaming 
        // pages
        /* if (!inUserFlow) {
            if (PageButtons.showing) remove(PageButtons.node); // ensure we don't delete when renaming 
        } */
        // user flows
        if (inUserFlow) {
            const { SymbolButtons } = flow.ui;
            if (SymbolButtons.showing) handleDeleteUserFlowSymbol([SymbolButtons.node]);
            const { mouseoverConnector } = flow.utils;
            if (mouseoverConnector) handleDeleteUserFlowConnector(mouseoverConnector);
            // multi-delete
            const selectedNodes = flow?.data?.nodes.filter(d => d._selected);
            if (!isEmpty(selectedNodes)) handleDeleteUserFlowSymbol(selectedNodes);
        }
    }
    if (inUserFlow) {
        // multi-select
        if (e.metaKey) {
            pressed.metaKey = true;
            select(select('#interactions-canvas').node()).on(".zoom", null)
            // select all (interfers with other inputs too much) (e.g, any other input (such as flow rename / comment search etc) showing when in user flow)
            /* if (e.key === 'a') {
                e.preventDefault();
               setSelectedUserFlowSymbols(copy(flow.data.nodes).map(d => d.id));
            } */
            // copy

            // paste
        }
        // Escape
        if (e.key === 'Escape') {
            setSelectedUserFlowSymbols([])
        }
    }

    // Escape
    if (e.key === 'Escape') {
        const isPageDrawerShowing = getPageDrawer()?.showing
        if (isPageDrawerShowing) store.dispatch(togglePageDrawer())
    }

}

export const resetMetaKeyPress = () => {
    if (pressed.metaKey) {
        const { MultiSelectBox } = getUserFlow()?.utils; // has to be here otherwise attributes are outdated
        if (MultiSelectBox.showing) store.dispatch(showingMultiSelectBox({ showing: false }))
        pressed.metaKey = false;
        renderInteractionsCanvas();
    }
}