import { useContext } from "react"
import { MoveToFolderContext } from "../../Components/Shared/MoveToFolder/Components/MoveToFolderHOC.jsx";
import { ShareFolderContext } from "./Components/Share/ShareFolderHOC.jsx";

export const useMoveToFolderContext = () => {
    return useContext(MoveToFolderContext) || {}
}

export const useShareFolderContext = () => {
    return useContext(ShareFolderContext) || {}
}