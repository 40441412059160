import { Editable, EditableInput, EditablePreview, HStack, IconButton, Link, useColorModeValue as mode } from "@chakra-ui/react"
import React, { useEffect, useRef } from 'react'
import { addPageChange, getURL, mergePagesEdits } from '../../../../../store/actions/sitemap-actions'
import { useCanEditFile, useGetSitemap, useInUserFlow } from '../../../../../hooks'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import { getPageId } from '../../canvas/utils/helpers'
import { trim } from 'lodash'
import { useDispatch } from 'react-redux'

export const URL = () => {

    const dispatch = useDispatch()
    const sitemap = useGetSitemap()

    const { PageDrawer } = sitemap?.ui

    const nodeId = getPageId(PageDrawer.page)
    const url = getURL(nodeId);

    const inputRef = useRef()
    useEffect(() => {
        if (PageDrawer.editPageUrl) setTimeout(() => inputRef.current.children[0].focus(), 5);
    }, [PageDrawer.editPageUrl])

    const inUserFlow = useInUserFlow()
    const canEdit = useCanEditFile(inUserFlow ? sitemap : null)

    return (
        <HStack align="baseline" spacing={1} ml={-1}>
            <Link href={url} isExternal>
                <IconButton variant="ghost" size="xs" icon={<ExternalLinkIcon fontSize="sm" color="rarchy-link" />} />
            </Link>
            <Editable
                key={PageDrawer?.page?.id}
                variant="flushed"
                defaultValue={url}
                placeholder="Enter a URL for this page..."
                color="rarchy-link"
                ref={inputRef}
                isPreviewFocusable={canEdit}
                fontWeight="medium"
                fontSize="sm"
                w="full"
                onSubmit={(newValue) => { handleRename({ nodeId, newName: newValue, sitemap, canEdit, dispatch }) }}
            >
                <EditablePreview
                    variant="flushed"
                    noOfLines={1}
                    w="full"
                    pb={0}
                />
                <EditableInput
                    variant="flushed"
                    w="full"
                    pb={0}
                />
            </Editable>
        </HStack>
    )
}

const handleRename = async ({ nodeId, newName, sitemap, canEdit, dispatch }) => {
    // get new name from input
    var url = trim(newName);
    //
    var oldURL = getURL(nodeId);
    //
    if (!canEdit || (!url || url === '' || oldURL === url)) return;
    // save change
    const change = { id: new Date().getTime(), data: [{ action: 'change-page-url', id: nodeId, url }] };
    /*** update name in page drawer so it instant updates for user ***/
    var PageDrawer = { ...sitemap?.ui.PageDrawer, url };
    /*** update name in page drawer so it instant updates for user ***/
    /*** merge page edits ***/
    dispatch(mergePagesEdits({ pages: { [nodeId]: { url } } }))
    /*** merge page edits ***/
    // add change
    dispatch(addPageChange({ change, PageDrawer }));
}