import { Box, Button, CloseButton, Divider, HStack, Icon, Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text, Tooltip } from "@chakra-ui/react"
import { useCanFullyEditFile, useGetSitemap, useGetUserFlow, useIsUserFlowLinkedToSitemap } from "../../../../../hooks"
import { TbSitemap } from "react-icons/tb"
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons"
import { SelectSitemap } from "./SelectSitemap"
import { EditorSkeletonWrapper } from "../.."
import { useDispatch } from "react-redux"
import { changeLinkedSitemap } from "../../../../../store/actions/flow-actions"
import { LinkedUserFlows } from "./LinkedUserFlows"

export const LinkedSitemap = () => {
    const dispatch = useDispatch()
    const flow = useGetUserFlow()
    const sitemap = useGetSitemap()
    const isLinkedToSitemap = useIsUserFlowLinkedToSitemap()
    const canFullyEdit = useCanFullyEditFile()
    const name = (isLinkedToSitemap && sitemap?.name) ? sitemap?.name : "Link to Sitemap"
    return (
        <EditorSkeletonWrapper>
            <Popover placement="bottom-start" isLazy>
                <PopoverTrigger>
                    <Button
                        lineHeight="1.5"
                        pl={3}
                        pr={2}
                        variant="outline"
                        size="md"
                        h="8"
                        iconSpacing={3}
                        fontSize="sm"
                        leftIcon={<Icon as={TbSitemap} />}
                        rightIcon={<ChevronDownIcon fontSize="xl" />}
                        color="rarchy-color-sitemap-editor"
                    >
                        {name}
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody p={4} overflow="scroll">
                        <Stack mb={3} spacing={1.5}>
                            <Text fontSize="13.5px" color="fg.muted">
                                {!isLinkedToSitemap && "This user flow isn't linked to a sitemap yet"}
                                {isLinkedToSitemap && "This user flow is linked to"}
                            </Text>
                            {isLinkedToSitemap && (
                                <HStack w="full" justify="space-between">
                                    <HStack>
                                        <ExternalLinkIcon fontSize="13px" color="rarchy-link" />
                                        <Tooltip label={sitemap.name} hasArrow openDelay={500} variant="rounded-sm">
                                            <Box noOfLines={1}>
                                                <Link fontSize="sm" fontWeight="medium" color="rarchy-link" href={`/app/sitemap/${flow.sitemap}`} isExternal>
                                                    {sitemap.name}
                                                </Link>
                                            </Box>
                                        </Tooltip>
                                    </HStack>
                                    {canFullyEdit && <CloseButton size="sm" onClick={() => dispatch(changeLinkedSitemap(null))} />}
                                </HStack>
                            )}
                        </Stack>
                        <Divider />
                        {!canFullyEdit && !isLinkedToSitemap && (
                            <Box mt={4} color="rarchy-red" fontSize="sm">
                                You don't have the required permissions to link a sitemap to this user flow.
                            </Box>
                        )}
                        {canFullyEdit && !isLinkedToSitemap && <SelectSitemap flow={flow} menuPortalTarget={document.body} />}
                        {isLinkedToSitemap  && <LinkedUserFlows sitemap={sitemap} />}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </EditorSkeletonWrapper>
    )
}