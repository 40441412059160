import {
    Menu as ChakraMenu,
    IconButton,
    MenuButton,
    MenuItem,
    MenuList,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { handleDelete, reOpenComments, resolveComments, useCommentIsResolved } from '../../helpers.jsx'
import { useCanCommentOnFile, useGetUser } from '../../../../../hooks/index.js'

import { FiMoreVertical } from 'react-icons/fi'
import React from 'react'

export const Menu = ({ comment, page, setEditing }) => {

    // continue
    const user = useGetUser()
    const isInitialComment = !comment.parent
    const withoutComment = !comment?.comment?.string
    const isResolved = useCommentIsResolved(page);
    const commentIsReopened = comment.status === 're-opened';
    const canEditOrDeleteComment = comment.user.id === user.id || comment.user.id === 'removed'
    
    // can't comment
    const userCanComment = useCanCommentOnFile()
    if (!userCanComment) return null

    const showMenu = (isInitialComment && userCanComment) || (canEditOrDeleteComment && !withoutComment)
    
    return (
        <ChakraMenu autoSelect={false} placement="bottom-end">
            {showMenu && (
                <MenuButton
                    as={IconButton}
                    icon={<FiMoreVertical />}
                    variant="ghost"
                    fontSize="md"
                    size="xs"
                    onClick={e => e.stopPropagation()}
                />
            )}
            {showMenu && (
                <MenuList minW="1xs" fontSize="sm">
                    {canEditOrDeleteComment && <MenuItem onClick={(e) => { e.stopPropagation(); setEditing(comment.id) }}>Edit</MenuItem>}
                    {isInitialComment && userCanComment && !isResolved && <MenuItem onClick={(e) => { e.stopPropagation(); resolveComments(page) }}>Resolve</MenuItem>}
                    {isInitialComment && userCanComment && isResolved && <MenuItem onClick={(e) => { e.stopPropagation(); reOpenComments(page) }}>Re-open</MenuItem>}
                    {canEditOrDeleteComment && !commentIsReopened && <MenuItem color={mode('red.500', 'red.300')} onClick={() => handleDelete(comment, page)}>Delete</MenuItem>}
                </MenuList>
            )}
        </ChakraMenu>
    )
}