import {
    Box,
    Button,
    Circle,
    HStack,
    Heading,
    Icon,
    Stack,
    Text,
    VStack,
    createIcon,
    useId,
    useRadio,
    useRadioGroup,
    useStyleConfig
} from "@chakra-ui/react";
import { Children, cloneElement, isValidElement, useMemo } from "react"
import { FaChrome, FaSpider } from "react-icons/fa6";

import { BsQuestionCircle } from "react-icons/bs";
import { useFormikContext } from "formik";

export const Renderer = ({ isCrawl }) => {
    const { values, setFieldValue } = useFormikContext()
    return (
        <VStack w="full" spacing={5} alignItems="start">
            <Heading fontSize="md" fontWeight="semibold">Renderer</Heading>
            <RadioCardGroup defaultValue={values?.renderer || 'auto'} spacing="3" onChange={(val) => {
                setFieldValue('renderer', val)
            }}>
                {[
                    { value: 'auto', title: 'Standard', description: 'Our super-fast, default crawl renderer. You should be able to complete a full crawl of most websites.', icon: FaSpider },
                    { value: 'chrome', title: 'Chrome', description: 'A slower crawler that uses Chrome to render pages, for JavaScript heavy websites like SPAs/PWAs.', icon: FaChrome }
                ].map((option) => (
                    <RadioCard key={option?.value} value={option?.value}>
                        <VStack w="full" align="start">
                            <HStack>
                                <Icon as={option?.icon} fontSize="sm" color="fg.muted" />
                                <Text color="fg.emphasized" fontWeight="semibold" fontSize="15px">
                                    {option?.title}
                                </Text>
                            </HStack>
                            <Text color="fg.muted" fontSize="sm">
                                {option?.description}
                            </Text>
                        </VStack>
                    </RadioCard>
                ))}
            </RadioCardGroup>
            {/* <Button
                fontWeight="medium"
                variant="link"
                _hover={{ cursor: "pointer", textDecor: "underline", textDecorationColor: "rarchy-link", textUnderlineOffset: 4.5 }}
                rightIcon={<Icon as={BsQuestionCircle} fontSize="sm" mt={0.5} color="fg.muted" />}
                fontSize="sm"
                ml={1}
                color="rarchy-link"
            >
                Learn more about rendering
            </Button> */}
        </VStack>
    )
}

const RadioCardGroup = props => {

    const { children, name, defaultValue, value, onChange, ...rest } = props

    const { getRootProps, getRadioProps } = useRadioGroup({
        name,
        defaultValue,
        value,
        onChange
    })

    const cards = useMemo(
        () =>
            Children.toArray(children)
                .filter(isValidElement)
                .map(card => {
                    return cloneElement(card, {
                        radioProps: getRadioProps({
                            value: card.props.value
                        })
                    })
                }),
        [children, getRadioProps]
    )

    return <Stack {...getRootProps(rest)} flexDir="row" spacing={6}>{cards}</Stack>
}

export const RadioCard = props => {

    const { radioProps, children, ...rest } = props

    const { getInputProps, getCheckboxProps, getLabelProps, state } = useRadio(
        radioProps
    )
    const id = useId(undefined, "radio-button")

    const styles = useStyleConfig("RadioCard", props)
    const inputProps = getInputProps()
    const checkboxProps = getCheckboxProps()
    const labelProps = getLabelProps()

    return (
        <Box
            as="label"
            cursor="pointer"
            {...labelProps}
            sx={{
                ".focus-visible + [data-focus]": {
                    boxShadow: "outline",
                    zIndex: 1
                }
            }}
            _hover={{ boxShadow: "xs" }}
            border="1px solid"
            borderColor={state.isChecked ? "rarchy-link" : "rarchy-border"}
            borderRadius="lg" p={4}
            w="full"
        >
            <input {...inputProps} aria-labelledby={id} />
            <Box sx={styles} {...checkboxProps} {...rest}>
                <Stack direction="row">
                    <Box flex="1">{children}</Box>
                    {state.isChecked ? (
                        <Circle bg="accent" size="4">
                            <Icon as={CheckIcon} boxSize="2.5" color="fg.inverted" />
                        </Circle>
                    ) : (
                        <Circle borderWidth="2px" size="4" />
                    )}
                </Stack>
            </Box>
        </Box>
    )
}

export const CheckIcon = createIcon({
    displayName: "CheckIcon",
    viewBox: "0 0 12 10",
    path: (
        <polyline
            fill="none"
            strokeWidth="2px"
            stroke="currentColor"
            strokeDasharray="16px"
            points="1.5 6 4.5 9 10.5 1"
        />
    )
})
