import React from 'react';

import {
    IconButton, Tooltip
} from "@chakra-ui/react"
import { DeleteIcon } from '@chakra-ui/icons';
import { handleDeleteUserFlowSymbol } from '../../utils/Symbols/delete';

export const Delete = ({ OptionsPopover }) => {
    return (
        <Tooltip variant="rounded" placement='top' label='Delete' openDelay={1000}>
            <IconButton
                size="sm"
                variant="ghost"
                icon={<DeleteIcon color="rarchy-red" />}
                onClick={() => handleDeleteUserFlowSymbol(OptionsPopover.nodes)}
            />
        </Tooltip>
    )
}