import React, { useState } from 'react'
import {
    Button,
    Text,
    useColorModeValue as mode,
    HStack,
    Img,
    Box,
    Popover,
    PopoverTrigger,
    Tooltip,
} from "@chakra-ui/react"
import { useDispatch } from 'react-redux'
import { deleteCoverFromFirestore } from '../../../../../store/actions/sitemap-actions'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import dayjs from 'dayjs'
// import { RecurringScreenshots } from './Screenshot/Scheduled'
import { BsCalendar3 } from 'react-icons/bs'
import { getPageId } from '../../canvas/utils/helpers'
import { useCanEditFile, useGetSitemap, useGetUser, useInUserFlow } from '../../../../../hooks'
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers'

export const Cover = ({ PageDrawer, customCover }) => {
    const sitemap = useGetSitemap()
    const user = useGetUser()
    const firebase = useFirebase()
    const firestore = useFirestore()
    const [deleting, setDeleting] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const canEdit = useCanEditFile()
    const created = dayjs(parseInt(customCover?.generation?.substring(0, 13)))
    return (
        <Box>
            {customCover && (
                <HStack direction="row" spacing={5} alignItems="center" justifyContent="space-between">
                    <Popover isLazy gutter={9} placement="auto-end">
                        <PopoverTrigger>
                            <Tooltip variant="rounded" label={created.format('lll')} openDelay={500}>
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    leftIcon={<BsCalendar3 />}
                                    color={mode("gray.600", "whiteAlpha.700")}
                                // leftIcon={<FaHistory />} // for recurring scheduled screenshots
                                >
                                    <Text ml={1}>{created.format('ll')}</Text>
                                </Button>
                            </Tooltip>
                        </PopoverTrigger>
                        {/* <RecurringScreenshots PageDrawer={PageDrawer} customCover={customCover} /> */}
                    </Popover>
                    <HStack direction="row" spacing={2}>
                        <HStack>
                            {confirmDelete && !deleting && (
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setConfirmDelete(false)}>
                                    Cancel
                                </Button>
                            )}
                            {canEdit && (<Button
                                size="sm"
                                variant="ghost"
                                colorScheme="red"
                                isLoading={deleting}
                                loadingText='Deleting'
                                onClick={() => {
                                    if (!canEdit) return; // fail-safe
                                    if (confirmDelete) {
                                        setDeleting(true);
                                        setTimeout(() => handleCoverDelete({ sitemap, user, device: sitemap?.covers.device, firebase, firestore }), 250);
                                    } else {
                                        setConfirmDelete(true)
                                    }
                                }}>
                                {!confirmDelete ? "Delete" : "Confirm Delete"}
                            </Button>
                            )}
                        </HStack>
                    </HStack>
                </HStack>
            )}
            <Box mt={4}>
                <Img src={customCover ? customCover.downloadURL : null} />
            </Box>
        </Box>
    )
}

const handleCoverDelete = async ({ sitemap, user, device, firebase, firestore }) => {
    const { PageDrawer } = sitemap?.ui;
    const pageId = getPageId(PageDrawer.page);
    try {
        firebase.functions().httpsCallable('sitemaps-covers-deleteCoverFromStorage')({ sitemapId: sitemap?.id, page: pageId, device })
    } catch (e) {
        console.error(e)
    } finally {
        // always delete from firestore to allow another upload (if error deleting from storage)
        deleteCoverFromFirestore({ firestore, sitemapId: sitemap?.id, page: pageId, device, user }).catch(e => console.error(e))
    }
}