import { TabPanel } from "@chakra-ui/react"
import { useGetFoldersData } from "../../../../../../hooks"
import { FolderItem } from "./Shared/FolderItem"
import { take, sortBy } from 'lodash'

export const Recent = () => {
    const folders = useGetFoldersData()
    const sortedFoldersByNewlyCreated = take(sortBy(folders, f => f?.createdAt?.getTime()).reverse(), 5)
    return (
        <TabPanel px={0} py={2}>
            {sortedFoldersByNewlyCreated?.map(folder => {
                return <FolderItem key={folder?.id} folder={folder} />
            })}
        </TabPanel>
    )
}