import {
    Grid,
    Icon,
    IconButton,
    Tooltip
} from "@chakra-ui/react"

import { CheckIcon } from '@chakra-ui/icons';
import { TbBackslash } from "react-icons/tb";
import { useCanEditFile } from '../../../../../hooks';

export const defaultPallette = ['#000000', '#333333', '#4D4D4D', '#0062B1', '#009CE0', '#73D8FF', '#9F0500', '#D33115', '#F44E3B', '#C45100', '#E27300', '#FE9200', '#FB9E00', '#FCC400', '#FCDC00', '#808900', '#B0BC00', '#DBDF00', '#194D33', '#68BC00', '#A4DD00', '#0C797D', '#16A5A5', '#68CCCA', '#653294', '#7B64FF', '#AEA1FF', '#AB149E', '#FA28FF', '#FDA1FF']

export const Pallette = ({ selectedColor, existingLabels, handlePalletteChange }) => {
    const colorsOfExistingLabels = existingLabels?.map(c => c?.color)
    const canEdit = useCanEditFile()
    return (
        <Grid templateColumns="repeat(6, 1fr)" columnGap={2} rowGap={3}>
            {defaultPallette?.map((color, i) => {
                const isDisabled = colorsOfExistingLabels?.includes(color)
                const existingColor = existingLabels?.find(c => c.color === color)
                const label = existingColor?.label || existingColor?.color
                return (
                    <Tooltip key={i} isDisabled={!isDisabled} variant="rounded-sm" hasArrow label={label} openDelay={500}>
                        <IconButton
                            size="xs"
                            bgColor={color}
                            rounded="sm"
                            _hover={{
                                boxShadow: "md"
                            }}
                            isDisabled={!canEdit || isDisabled}
                            opacity={!canEdit || isDisabled ? '1 !important' : 1}
                            onClick={(e) => {
                                if (!canEdit) return;
                                // continue
                                e.stopPropagation(); handlePalletteChange({ header: color.toUpperCase() })
                            }}
                            icon={(
                                selectedColor === color
                                    ? <CheckIcon fontSize="sm" color="white" />
                                    : isDisabled
                                        ? <Icon as={TbBackslash} fontSize="xl" color="white" />
                                        : null
                            )
                            }
                        />
                    </Tooltip>
                )
            })}
        </Grid>
    )
}