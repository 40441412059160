import { store } from '../../../../../../store';
import { hitPageSectionsLimit } from './helpers';
import { updatePageSectionsData, togglePageSectionsOptionsPopover, addPageSectionChange } from '../../../../../../store/actions/sitemap-actions';
import { getPageId } from '../helpers';
import { toggleUpgradeModal } from '../../../../../../store/actions/ui-actions';
import { ascending } from 'd3';
import { cloneDeep } from 'lodash'

export const clonePageSection = ({ node, section }) => {
    
    const { sitemap, user } = store.getState();
    
    const pageId = getPageId(node);

    const sectionId = Date.now();
    const clonedSection = { pageId, data: { ...section, id: sectionId, index: section.index + 1 } };

    var sections = cloneDeep(sitemap?.data.page_sections );
    var sectionData = sections[clonedSection.pageId] ? sections[clonedSection.pageId] : [];

    // don't continue if in user sitemap and already have 3 sections
    const inUserSitemap = sitemap?.id === user.id;
    if ((inUserSitemap) && hitPageSectionsLimit(sectionData.length + 1)) return store.dispatch(toggleUpgradeModal({ showing: true, screen: 'page-sections' }));

    /*** reorder before updating ***/
    sectionData.splice(clonedSection.data.index, 0, clonedSection.data);
    sectionData.map((section, i) => { section.index = i; return section }); // set new indexes
    sectionData.sort((a, b) => ascending(a.index, b.index)); // re-sort before update
    /*** reorder before updating ***/
    sections[clonedSection.pageId] = sectionData;

    /*** dispatch so we can render immediately ***/
    store.dispatch(updatePageSectionsData(sections));
    // hide options popover
    store.dispatch(togglePageSectionsOptionsPopover({ showing: false }));
    /*** dispatch so we can render immediately ***/

    var changeData = { pageId, section: clonedSection.data };
    const change = {
        id: new Date().getTime(),
        data: [{ action: 'page-section-clone', ...changeData }],
    };
    const history = {
        action: 'page-section-clone',
        data: changeData
    };

    store.dispatch(addPageSectionChange({ change, history }));
}