import * as CryptoJS from 'crypto-js';

import { Box, SlideFade, Stack } from "@chakra-ui/react"
import { findIndex, isEmpty } from 'lodash'
import { getPathnameFromURL, processNewSitemap } from "../../../../store/actions/sitemaps-actions";
import { useEffect, useState } from "react";
import { useGetNewSitemapModal, useGetUserId, useInSitemap } from "../../../../hooks";

import { Formik } from "formik";
import { Header } from "./Header";
import { ImportDetail } from "./Detail";
import { ImportHistory } from "./History";
import { Options } from "./Options";
import { getNewFirestoreDocId } from "../../../../helpers";
import { store } from "../../../../store";
import { toggleNewSitemapModal } from "../../../../store/actions/ui-actions";
import { useDispatch } from "react-redux";

export const tabs = [
    { key: 'crawl', label: "Crawl Website" },
    { key: 'xml', label: "XML Sitemap" },
    { key: 'csv', label: "CSV File" },
    { key: 'history', label: "History" },
]

export const SitemapImportDrawer = () => {

    const userId = useGetUserId()

    const NewSitemapModal = useGetNewSitemapModal()
    const { createType, showDetailScreen, detailId, prefill } = NewSitemapModal || {}

    const [tabIndex, setTabIndex] = useState(findIndex(tabs, t => t.key === createType))

    /*** import ID ***/
    const inSitemap = useInSitemap()
    const getNewImportId = () => inSitemap ? inSitemap?.id : getNewFirestoreDocId("sitemaps_processing", { newDocId: true })
    const [importId, setImportId] = useState(getNewImportId())
    /*** import ID ***/

    const isCrawl = tabIndex === 0
    const isXML = tabIndex === 1
    const isCSV = tabIndex === 2
    const isHistory = tabIndex === 3

    useEffect(() => {
        const { prefill } = NewSitemapModal || {}
        if (prefill) {
            const createTypeIndex = findIndex(tabs, t => t.key === NewSitemapModal?.createType)
            if (tabIndex !== createTypeIndex) setTabIndex(createTypeIndex)
        }
    }, [NewSitemapModal])

    // close drawer if user presses back in browser
    CloseDrawerOnBrowserBackPress()

    if (!userId) return null

    return (

        <Box zIndex={9} pos="fixed" w="full" top={0} left={0} h="100vh" bgColor="rarchy-bg-white" overflowY="scroll">
            <SlideFade in offsetY='200px' w="sm">
                {!showDetailScreen && (
                    <Formik
                        enableReinitialize
                        initialValues={{
                            respectRobotsTxt: true,
                            extractSEOTags: true,
                            pageNameSource: 'directory',
                            restrictToSubfolder: false,
                            importId,
                            ...prefill
                        }}
                        onSubmit={(values) => {

                            const { url, importId, fileName } = values;

                            if (!url) return;

                            try {

                                const sitemapDoc = {
                                    type: isCrawl ? 'crawl' : isXML ? 'xml' : isCSV ? 'csv' : null,
                                    url,
                                    options: createSitemapImportOptions({ isCrawl, values }),
                                    fileName: fileName || null,
                                    importId
                                };

                                // process new sitemap (redux)
                                store.dispatch(processNewSitemap(sitemapDoc));
                                // set new import id
                                setImportId(getNewImportId())
                                
                            } catch (e) {
                                console.error(e)
                            }

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            if (!isEmpty(errors)) console.log(errors);
                            return (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <Header
                                            tabs={tabs}
                                            tabIndex={tabIndex}
                                            setTabIndex={setTabIndex}
                                            prefill={prefill}
                                        />
                                    </form>
                                    {isHistory && <ImportHistory />}
                                    {/* needs to be seperate otherwise input doesn't submit on enter */}
                                    {!isHistory && (
                                        <Options
                                            isCrawl={isCrawl}
                                            isXML={isXML}
                                            isCSV={isCSV}
                                        />
                                    )}
                                </>
                            )
                        }}
                    </Formik>
                )}
                {showDetailScreen && <ImportDetail detailId={detailId} />}
            </SlideFade>
        </Box>
    )
}

const CloseDrawerOnBrowserBackPress = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        window.history.pushState(null, null, location.href);
        window.onpopstate = function () {
            window.history.go(1);
            dispatch(toggleNewSitemapModal({ showing: false }))
        };
    }, []);
    // removes pop state function above on unmount - REALLY IMPORTANT THIS IS KEPT
    useEffect(() => {
        return () => {
            window.onpopstate = () => { }
        }
    }, [])
}

export const createSitemapImportOptions = ({ isCrawl, values }) => {

    var options = {};

    /*** Restrict to Subfolder ***/
    if (values?.restrictToSubfolder) {
        const pathname = getPathnameFromURL(values?.url)
        if (pathname) options.include = [pathname]
    }
    /*** Restrict to Subfolder ***/

    /*** Max Pages ***/
    if (values.maxPages) {
        options.maxPages = parseInt(values.maxPages)
    }
    /*** Max Pages ***/

    /*** Max Depth ***/
    if (values.maxDepth && values?.maxDepth !== "any") {
        options.maxDepth = parseInt(values.maxDepth)
    }
    /*** Max Depth ***/

    /*** include/exclude ***/
    if (!isEmpty(values.include)) options.include = [...(options.include || []), ...Array.isArray(values.include) ? values.include : values?.include?.replace(/\n/g, ",").split(",")];
    if (!isEmpty(values.exclude)) options.exclude = Array.isArray(values.exclude) ? values.exclude : values?.exclude?.replace(/\n/g, ",").split(",");
    /*** include/exclude ***/

    /*** exclude selectors ***/
    if (!isEmpty(values.exclude_selectors)) options.exclude_selectors = Array.isArray(values.exclude_selectors) ? values.exclude_selectors : values?.exclude_selectors?.replace(/\n/g, ",").split(",");
    /*** exclude selectors ***/

    if (isCrawl) {
        /*** SEO Tags ***/
        options.extractSEOTags = values.extractSEOTags ? values.extractSEOTags : false // extract seo tags
        if (options.extractSEOTags) {
            if (values.pageNameSource) options.pageNameSource = values.pageNameSource // use seo tags for page name
            if (values.excludePageNameSourceText && values.excludePageNameSourceText !== '') {
                options.excludePageNameSourceText = values.excludePageNameSourceText.replace(/\n/g, ",").split(",");
            }
        }
        /*** SEO Tags ***/

        /*** renderer ***/
        // options.renderer = 'chrome' // chrome by default
        if (values.renderer === 'chrome') options.renderer = values.renderer; // only set if chrome (default is undefined)
        /*** renderer ***/

        options.respectRobotsTxt = values.respectRobotsTxt ? values.respectRobotsTxt : false // respect robots.txt

        /*** password encryption ***/
        /*** HTTP Basic Auth  ***/
        const { username, password } = values;
        if (password) {
            var encrypted = CryptoJS.AES.encrypt(password, '199019922095');
            encrypted = encrypted.toString();
            options.authentication = { username, pass: encrypted };
        }
        /*** HTTP Basic Auth  ***/
        /*** password encryption ***/

    };

    return options;

}