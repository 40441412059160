import { Stack, Wrap } from "@chakra-ui/react"
import { useGetFilesDataByFolder, useHasLoadedFiles, useInOrganization, useInProjectsGridView, useInTrash } from "../../hooks";

import { EmptySitemaps } from "./Components/Empty/Sitemaps";
import { EmptyUserFlows } from "./Components/Empty/UserFlows";
import { FileCard } from "./Components/Card"
import { UpgradeToCreateFile } from "./Components/UpgradeToCreateFile";
import { isEmpty } from 'lodash'
import { useMemo } from "react";

export const Files = ({ forRecent, collection }) => {

    const inTrash = useInTrash()
    const files = useGetFilesDataByFolder({ forRecent, collection });
    const inGridView = useInProjectsGridView() || forRecent
    const hasLoadedFiles = useHasLoadedFiles()

    const inOrganization = useInOrganization()

    const placeholders = useMemo(() => { return collection !== 'all' && collection !== 'folders' ? [...Array(5).keys()]?.map(id => { return { id, collection } }) : [] }, [collection])

    const isFilesEmpty = isEmpty(files)

    const emptySitemaps = !inTrash && (isFilesEmpty && collection === 'sitemaps')
    const emptyUserFlows = !inTrash && (isFilesEmpty && collection === 'user-flows')

    const hasSitemaps = !inTrash && inOrganization && (!isFilesEmpty && collection === 'sitemaps')
    const hasUserFlows = !inTrash && inOrganization && (!isFilesEmpty && collection === 'user-flows')


    return (
        inGridView && (
            <>
                <Stack direction="column">
                    <Wrap spacingX={5} spacingY={6}>
                        {emptySitemaps && hasLoadedFiles && <EmptySitemaps />}
                        {emptyUserFlows && hasLoadedFiles && <EmptyUserFlows />}
                        {(!hasLoadedFiles ? placeholders : files)?.map(file => <FileCard key={`${file?.id}-${file?.collection}`} file={file} collection={collection} forRecent={forRecent} />)}
                        {hasSitemaps && hasLoadedFiles && <EmptySitemaps />}
                        {hasUserFlows && hasLoadedFiles && <EmptyUserFlows />}
                        {!forRecent && <UpgradeToCreateFile collection={collection} />}
                    </Wrap>
                </Stack>
            </>
        )
    )
}