import React from 'react';

import {
    IconButton,
    Tooltip
} from "@chakra-ui/react"
import { DeleteIcon } from '@chakra-ui/icons';
import { handleDeleteUserFlowConnector } from '../../utils/Connectors/delete';


export const Delete = ({ OptionsPopover }) => {
    const link = OptionsPopover.links[0];
    return (
        <Tooltip variant="rounded" placement='top' label='Delete' openDelay={1000}>
            <IconButton
                size="sm"
                variant="ghost"
                icon={<DeleteIcon color="rarchy-red" />}
                onClick={() => handleDeleteUserFlowConnector(link)}
            />
        </Tooltip>
    )
}