import { Box, UnorderedList, VStack } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../hooks"
import { toTitleCase } from "../../../helpers"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { ScreenshotsPageColorsAndMoreListItem, UnlimitedFilesListItem, UnlimitedPagesPlusImportListItem } from "../Components/Messaging/ListItems"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"

export const PageSectionsColors = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                {`Categorize your content using individual page section colors in Rarchy ${toTitleCase(planId)}. ${canStartNewTrial ? `Try for free for ${TRIAL_DAYS} days.` : 'Upgrade your plan today.'}`}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                <UnlimitedFilesListItem />
                <UnlimitedPagesPlusImportListItem />
                <ScreenshotsPageColorsAndMoreListItem />
            </UnorderedList>
        </VStack>
    )
}