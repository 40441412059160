import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Code,
    Heading,
    Stack,
    VStack
} from "@chakra-ui/react"

import { Link } from 'react-router-dom'
import { UnderlineLink } from '../Auth/SignupLogin/Components/UnderlineLink'

const Title = ({ children }) => <Box flex='1' py={2.5} textAlign='left' fontWeight="medium">{children}</Box>

export const FAQs = () => {
    return (
        <Stack py={6} spacing={12}>
            <Heading fontSize="3xl" fontWeight="semibold">
                Frequently asked questions
            </Heading>
            <VStack w="2xl" align="start" pb={20}>
                <Box w="full">
                    <Accordion defaultIndex={[0]} allowMultiple fontSize="sm">
                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        I started a free trial, but I still can't access paid features?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    When you start a free trial, we create a new 'organization' account for you, and transfer any files created from your free account.
                                </Box>
                                <Box mb={2.5}>
                                    When we confirmed your free trial, we should have automatically switched you into this account, but sometimes this may not be the case.
                                </Box>
                                You can switch between your different accounts by clicking the dropdown below the "Rarchy" logo in the top of the left sidemenu.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        I can't crawl my website / import my sitemap?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    Occasionally, websites may block access to external crawlers such as Rarchybot.
                                </Box>
                                <Box mb={2.5}>To allow Rarchybot access, contact your webmaster or hosting provider and ask them to whitelist the following IP address: <Code rounded="sm" children='34.173.108.16' px={2} />
                                </Box>
                                <Box>
                                    If you are still having trouble crawling/importing, please <UnderlineLink><Link to="/app/help/ticket">get in touch</Link></UnderlineLink> and we will investigate this further for you.
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        I crawled my website, but some pages are missing?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    We offer two types of crawler, our standard "fast" crawler which uses AJAX, and a slower "Chrome" crawler which renders full webpages in a Chrome browser.
                                </Box>
                                <Box mb={2.5}>
                                    Although the Chrome crawler is slower than our standard crawler, in some instances it may find more pages due to reading the DOM after JavaScript has been executed.
                                </Box>
                                <Box mb={2.5}>
                                    If you are finding pages are missing from your crawl, try selecting the Chrome crawler before running your crawl again.
                                </Box>
                                <Box>
                                    If you are still having trouble crawling, please <UnderlineLink><Link to="/app/help/ticket">get in touch</Link></UnderlineLink> and we will investigate this further for you.
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        My screenshots aren't rendering correctly / at all?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    If we are unable to capture sceeenshots for your pages, it is likely that the website has blocked access to external crawlers such as Rarchybot.
                                </Box>
                                <Box mb={2.5}>To allow Rarchybot access, contact your webmaster or hosting provider and ask them to whitelist the following IP address: <Code rounded="sm" children='34.173.108.16' px={2} />
                                </Box>
                                <Box mb={2.5}>If your screenshots aren't rendering correctly, please try a combination of the provided options to see if this improves. Ensuring "Lazy Load" & "Wait 5 Seconds" are checked, should solve most rendering issues.
                                </Box>
                                <Box>
                                    If you are still having trouble capturing screenshots, please <UnderlineLink><Link to="/app/help/ticket">get in touch</Link></UnderlineLink> and we will investigate this further for you.
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>


                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        I shared a file with someone, but they haven't received the invitation email?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    Occasionally, a recipients email provider will stop our emails from being delivered.
                                </Box>
                                If a user hasn't recieved their invitation email within 60 minutes, please <UnderlineLink><Link to="/app/help/ticket">get in touch</Link></UnderlineLink> and we will resolve this for you.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        What's the difference between file comment/edit access?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    If you've shared a file with comment access, the invited user will only be able to comment on this file.
                                </Box>
                                If you've shared a file with edit access, the invited user will be able to edit this file, but not archive the file, or access plan-limited features such as screenshots.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        I added a team member, but they haven't received the invitation email?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    Occasionally, a recipients email provider will stop our emails from being delivered.
                                </Box>
                                If a user hasn't recieved their invitation email within 60 minutes, please <UnderlineLink><Link to="/app/help/ticket">get in touch</Link></UnderlineLink> and we will resolve this for you.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        What's the difference between team member roles?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mb={2.5}>
                                    A team member with the "user" role can access all features, but can't add other team members or make any billing changes.
                                </Box>
                                A team member with the "admin" role can access all features, and can add team members, make billing changes such as upgrading plans, and can delete the account.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton fontSize="sm">
                                    <Title>
                                        How do I cancel my subscription?
                                    </Title>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                You cancel your account by going to your <UnderlineLink><Link to="/settings/org-details">organization's settings</Link></UnderlineLink> and selecting 'Delete' from the 'Delete organization' section.
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
            </VStack>
        </Stack>
    )
}