import { store } from '../../../../../../store';
import { hitPageSectionsLimit } from './helpers';
import { updatePageSectionsData, togglePageSectionsOptionsPopover, addPageSectionChange } from '../../../../../../store/actions/sitemap-actions';
import { sendDataLayerEventForPageSections } from '../../components/page-sections/options';
import { toggleUpgradeModal } from '../../../../../../store/actions/ui-actions';
import { ascending } from 'd3';
import { cloneDeep } from 'lodash'

export const insertNewPageSection = ({ nodeId, index }) => {

    const { sitemap, user } = store.getState();

    const sectionId = Date.now();
    const newSection = { pageId: nodeId, data: { id: sectionId, index, new: true } };
    var sections = cloneDeep(sitemap?.data.page_sections);
    var sectionData = sections[newSection.pageId] ? sections[newSection.pageId] : [];

    // don't continue if in user sitemap and already have 3 sections
    const inUserSitemap = sitemap?.id === user.id;
    if ((inUserSitemap) && hitPageSectionsLimit(sectionData.length + 1)) return store.dispatch(toggleUpgradeModal({ showing: true, screen: 'page-sections' }))

    /*** reorder before updating ***/
    sectionData.splice(newSection.data.index, 0, { ...newSection.data, title: '' });
    sectionData.map((section, i) => { section.index = i; return section }); // set new indexes
    sectionData.sort((a, b) => ascending(a.index, b.index)); // re-sort before update
    /*** reorder before updating ***/
    sections[newSection.pageId] = sectionData;

    /*** dispatch so we can render immediately ***/
    store.dispatch(updatePageSectionsData(sections));
    /*** dispatch so we can render immediately ***/

    var changeData = { pageId: nodeId, section: newSection.data };

    const change = {
        id: new Date().getTime(),
        data: [{ action: 'page-section-new', ...changeData }],
    };

    const history = { action: 'page-section-new', data: changeData };

    store.dispatch(addPageSectionChange({ change, history }));

    sendDataLayerEventForPageSections('Add', 1);
}