import { Box, UnorderedList, VStack } from "@chakra-ui/react"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { CollaborateWithOthersListItem, RestoreFilesListItem, UnlimitedFilesListItem } from "../Components/Messaging/ListItems"

export const RevisionHistory = () => {
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                <strong>Unlock detailed revision history for all your sitemaps and user flows.</strong>
                {` Easily view and restore earlier versions from all users. ${canStartNewTrial ? `Try for free for ${TRIAL_DAYS} days.` : 'Upgrade your plan today.'}`}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                <RestoreFilesListItem />
                <CollaborateWithOthersListItem />
                <UnlimitedFilesListItem />
            </UnorderedList>
        </VStack>
    )
}