import { Text } from "@chakra-ui/react"
import { UnderlineLink } from "./UnderlineLink"
import { Link } from "react-router-dom";

export const Footer = ({ isLogin, isSignupPersonalize }) => {
    if (isSignupPersonalize) return null;
    return (
        <Text mt={2} color="fg.muted">
            {isLogin ? "Don't have an account yet?" : "Have an account with us?"}
            <Link to={isLogin ? "/signup" : "/login"}>
                <UnderlineLink fontWeight="medium" ml={2}>{isLogin ? "Sign up for free" : "Log in"}</UnderlineLink>
            </Link>
        </Text>
    )
}