import { useState } from "react"
import { useDispatch } from "react-redux"
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, MenuItem, useDisclosure } from "@chakra-ui/react"
import { PY } from ".."
import { BsTrash } from "react-icons/bs"
import { mergeImportedSitemapById } from "../../../../../../../store/actions/sitemaps-actions"
import { getFirestore, doc, deleteDoc } from "firebase/firestore";
import { wait } from "../../../../../../../../shared/helpers"

export const Delete = ({ imported }) => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isDeleting, setIsDeleting] = useState(false)
    const firestore = getFirestore()
    return (
        <>
            <MenuItem py={PY} icon={<Icon as={BsTrash} fontSize="lg" />} onClick={(e) => {
                // e.preventDefault();
                // e.stopPropagation();
                onOpen();
            }}>
                Delete
            </MenuItem>
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Import
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure? This import will be permanently deleted, and can't be restored.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onClose} fontSize="sm">
                                Cancel
                            </Button>
                            <Button
                                colorScheme='red'
                                isLoading={isDeleting}
                                fontSize="sm"
                                onClick={async () => {
                                    setIsDeleting(true)
                                    await deleteDoc(doc(firestore, "sitemaps_imported", imported?.id));
                                    dispatch(mergeImportedSitemapById({ [imported?.id]: undefined }))
                                    await wait(1000)
                                    onClose()
                                }}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}