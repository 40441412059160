import {
    Box,
    Text,
} from "@chakra-ui/react"
import { SwitcherAccounts } from "../../../../../SideMenu/Header/AccountSwitcher";

export const Accounts = ({ inNav, onClose }) => {
    return (
        <Box>
            <Text px={3} pt={3} pb={2} fontSize="xs" fontWeight="semibold">Switch account</Text>
            <SwitcherAccounts inNav={inNav} onClose={onClose} />
        </Box>
    )
};