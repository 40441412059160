import { Select, components } from 'chakra-react-select'
import { useGetOrganizationUsers, useGetUserId } from '../../../../../hooks';
import { Avatar, Box, Stack, Text } from '@chakra-ui/react';
import { getUsersFullName } from '../../../../../helpers';

import { compact, map } from 'lodash'

export const FolderShareSelect = ({ isPublic, setMembers, setIsSelectFocused }) => {
    const userId = useGetUserId()
    const users = compact([...useGetOrganizationUsers()]?.map(user => {
        if (user?.id === userId) return null; // can't add yourself
        const name = getUsersFullName(user)
        return { ...user, name }
    }))
    return (
        <Box display={isPublic ? "none" : "block"}>
            <Select
                components={{
                    DropdownIndicator: null, MultiValueLabel: (props) => (<components.MultiValueLabel {...props}>
                        {props.data.name}
                    </components.MultiValueLabel>)
                }}
                // menuIsOpen
                openMenuOnClick={false}
                openMenuOnFocus={false}
                onMenuOpen={setIsSelectFocused.on}
                onMenuClose={setIsSelectFocused.off}
                isMulti
                getOptionLabel={o => o.name}
                getOptionValue={o => o.id}
                size="md"
                placeholder="Type one or more names or emails"
                onChange={(e) => {
                    const newPeopleToShare = map(e, 'id')
                    setMembers(newPeopleToShare)
                }}
                isRequired
                formatOptionLabel={user => {
                    return (
                        <Stack direction="row" align="center" spacing={5}>
                            <Avatar size="sm" name={user?.name} src={user?.photoURL} />
                            <Stack spacing={0}>
                                <Text>{user?.name}</Text>
                                <Text fontSize="xs" color="fg.muted">{user?.email}</Text>
                            </Stack>
                        </Stack>
                    )
                }}
                options={[...users]}
                chakraStyles={chakraStyles}
                noOptionsMessage={() => null}
            >
            </Select>
        </Box>
    )
}

const chakraStyles = {
    container: (prev, { selectProps }) => ({
        ...prev,
        cursor: "text",
        borderRadius: "md",
        bgColor: "rarchy-bg-white"
    }),
    input: (prev, { selectProps }) => ({
        ...prev,
        minHeight: 30,
        cursor: "text",
        fontSize: "sm",
        mx: 1,
        borderRadius: "md"
    }),
    placeholder: (prev, { selectProps }) => ({
        ...prev,
        pl: 1,
        fontSize: "sm"
    }),
    menu: (prev, { selectProps }) => ({
        ...prev,
        pos: "relative",
    }),
    menuList: (prev, { selectProps }) => ({
        ...prev,
        mt: -1.5,
        border: "none",
        boxShadow: "none"
    }),
    option: (prev, { selectProps }) => ({
        ...prev,
        h: "54px",
        fontSize: "sm",
    }),
    valueContainer: (prev, { selectProps }) => ({
        ...prev,
        pl: 2
    }),
    multiValue: (prev, props) => {
        const isError = props?.data.error;
        return ({
            ...prev,
            py: 1,
            // borderWidth: "1px", 
            // borderColor: isError ? "red.600" : null,
            color: isError ? "rarchy-red" : null,
            fontWeight: isError ? "semibold" : null,
        })
    },
    noOptionsMessage: (prev, { selectProps }) => ({
        ...prev,
        px: 4,
        py: 2,
        textAlign: 'left',
        fontSize: "sm",
        color: "gray.500"
    })
};