import { ChevronLeftIcon } from "@chakra-ui/icons"
import { NavItem } from "../Components/NavItem"

import { useGetSubfolderDepth, useInEditor, useInProjects } from "../../../hooks"
import { HiOutlineHome } from "react-icons/hi"

export const Home = () => {
    const inEditor = useInEditor()
    const subfolderDepth = useGetSubfolderDepth()
    const { label, url } = GetLabelAndURL()
    if (inEditor || subfolderDepth === 0) return (
        <NavItem
            pathname="/app"
            w="full"
            icon={<HiOutlineHome />}
            label="Home"
        />
    )
    return (
        <NavItem
            pathname={url || "/app"}
            icon={<ChevronLeftIcon />}
            label={label}
        />
    )
}

const GetLabelAndURL = () => {
    const subfolderDepth = useGetSubfolderDepth()
    const isInProjects = useInProjects()
    // always back to home if one level deep
    if (subfolderDepth === 1) {
        return {
            label: "Back to Home"
        }
    }
    // in projects
    if (isInProjects) {
        return {
            label: "Back to all projects", url: "/app/projects"
        }
    }
    return {
        label: "Back to Home" // default
    }
}