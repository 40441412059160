import { createStandaloneToast } from '@chakra-ui/react'
import { getCanCommentInEditor, getCanEditInEditor, getCanFullyEditFile } from '../../Navbar/helpers'
import { getInUserFlow, getUserFlow, getUser } from '../../../../helpers'

const { toast } = createStandaloneToast()

const id = 'permissions'

export const showEditorPermissionsToast = (opts = {}) => {

    const inUserFlow = getInUserFlow()
    const flow = getUserFlow()
    const user = getUser()

    let { canComment, canFullyEdit, doc } = opts
    
    const canEdit = canComment ? getCanCommentInEditor(doc) : canFullyEdit ? getCanFullyEditFile(doc) : getCanEditInEditor(doc)
    if (canEdit) return true;

    const isSitemap = doc?.type === 'sitemap';

    const isTryingToEditSitemapAsUserFlowCollaborator = isSitemap && (inUserFlow && flow.collaborators?.[user?.id]) ? true : false;

    if (!toast.isActive(id)) {
        toast({
            id,
            containerStyle: {
                maxWidth: '366px',
                marginLeft: 7,
                marginBottom: 7
            },
            position: 'bottom-left',
            title: 'Editing is not allowed',
            description: isTryingToEditSitemapAsUserFlowCollaborator ? "You don't have edit permissions on the linked sitemap" : "You don't have the required permissions to modify this sitemap.",
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }
}