import { Redirect } from "react-router-dom"
import { useGetLocation, useInOrganization } from "../../../../hooks";
import { DarkMode, Stack, StackDivider } from "@chakra-ui/react";
import { UserSettings } from "./User";
import { OrganizationSettings } from "./Organization";
import { NavItem } from "../../Components/NavItem";
import { FiMoreHorizontal } from "react-icons/fi";

export const SettingsMenu = () => {
    const { pathname } = useGetLocation()
    const inOrganization = useInOrganization()
    if (pathname === "/settings") return <Redirect to={"/app/settings/your-account"} />
    return (
        <DarkMode>
            <Stack pt={2.5} spacing={1.5} divider={<StackDivider />}>
                <UserSettings />
                {inOrganization  && <OrganizationSettings />}
                <NavItem mt={2} label="Manage accounts" icon={<FiMoreHorizontal />} pathname="/settings/manage-accounts" />
            </Stack>
        </DarkMode>
    )
}