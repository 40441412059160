import React, { useState, useEffect } from 'react'

import {
    Box,
    HStack,
    Avatar,
    Button,
    chakra
} from "@chakra-ui/react"

import { MentionsInput, Mention } from 'react-mentions'
import { useCommentIsResolved, getDefaultMentionsStyle } from '../helpers'

import { useCanCommentOnFile, useGetUi, usePrevious } from '../../../../hooks/index.js'
import { find } from 'lodash'
import { getPeopleData } from '../../../../helpers/index.js'

const ChakraMentionsInput = chakra(MentionsInput)

export const TextArea = ({ view, page, comment, setEditing, handleSubmit }) => {
    // continue
    const ui = useGetUi()
    const [focused, setFocused] = useState(false)
    const [inputVal, setInputVal] = useState('')
    const prevComment = usePrevious(comment)
    const isResolved = useCommentIsResolved(page)
    useEffect(() => {
        // newly editing
        if (comment && !prevComment) {
            setFocused(true)
            setInputVal(comment)
        }
    }, [comment, prevComment])

    // can comment
    const userCanComment = useCanCommentOnFile()
    if (!userCanComment) return null

    const people = getPeopleData({ onlyMember: true })

    const inputHasText = inputVal && inputVal !== ""
    const defaultMentionsStyle = getDefaultMentionsStyle(ui, { minHeight: (!focused || focused && !inputHasText) ? 35 : null })
    const handleCancel = () => {
        setInputVal('')
        setFocused(false)
        if (setEditing) setEditing(false)
    }

    return (
        <Box mt={3} mb={view !== 'edit' ? 1 : 0}>
            <ChakraMentionsInput
                autoFocus={view === 'add'}
                className={`mentions-textarea ${ui.colorMode}`}
                bgColor="rarchy-bg-white"
                value={inputVal}
                fontSize="sm"
                style={defaultMentionsStyle}
                placeholder={view !== "reply" ? "Mention people using '@'" : isResolved ? "Adding a comment will re-open this discussion..." : "Reply or add others with @"}
                onChange={(event, textValue) => setInputVal(textValue)}
                borderWidth="1px"
                rounded="md"
                borderColor="rarchy-border"
                allowSpaceInQuery
                minHeight={14}
                onFocus={(e) => setFocused(true)}
                onBlur={() => { if (!inputHasText) setFocused(false) }}
            >
                <Mention
                    style={{}}
                    trigger="@"
                    data={people}
                    markup="@{{__id__}}"
                    appendSpaceOnAdd
                    displayTransform={(id, display) => {
                        const person = find(people, p => { return p.id === id })
                        return person ? `@${person?.firstName || ''} ${person?.lastName || ''}` : `@${id}`
                    }}
                    renderSuggestion={(
                        suggestion,
                        highlightedDisplay
                    ) => {
                        const person = find(people, p => { return p.id === suggestion.id })
                        const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
                        if (!name) return;
                        return (
                            <HStack direction="row" spacing={3} alignItems="center">
                                <Avatar size="sm" name={name} src={person.photoURL} />
                                <Box>{`${person?.firstName || ''} ${person?.lastName || ''}`}</Box>
                            </HStack>
                        )
                    }}
                />
            </ChakraMentionsInput>
            {inputHasText && (
                <HStack mt={3} mb={1} justifyContent="flex-end" direction="row" spacing={3} alignItems="center">
                    {renderButtons({ view, inputVal, handleCancel, handleSubmit })}
                </HStack>
            )}
        </Box>
    )
}

const renderButtons = ({ view, inputVal, handleCancel, handleSubmit }) => {
    const inputHasText = inputVal && inputVal !== ""
    return (
        <>
            <Button fontSize="sm" size="sm" variant="ghost" onClick={(e) => { e.stopPropagation(); handleCancel(e) }}>Cancel</Button>
            <Button fontSize="sm" size="sm" colorScheme="blue" isDisabled={!inputHasText} onClick={(e) => { e.stopPropagation(); handleSubmit(inputVal); handleCancel(); }}>{view === 'reply' ? 'Reply' : 'Save'}</Button>
        </>
    )
}