import { TabPanel } from "@chakra-ui/react"
import { FolderItem } from "./Shared/FolderItem"
import { upperFirst } from 'lodash'

// <Icon as={HiOutlineFolderOpen} fontSize="md" pos="absolute" left={2} />
// <Icon as={RiUserShared2Line} fontSize="sm" pos="absolute" left={2} mt={0.5} />

export const All = () => {
    return (
        <TabPanel px={0} py={2}>
            {["projects/your-projects", "projects/shared-with-you"].map(id => {
                return <FolderItem key={id} folder={{ id, name: getFromAllTabName(id) }} />
            })}
        </TabPanel>
    )
}

export const getFromAllTabName = (id) => {
    return upperFirst(id?.split("/")?.[1]?.replaceAll("-", " "))
}