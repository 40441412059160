import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux'
import { collapse, update } from '../utils/app';
import { getInSitemap, getInUserFlow } from '../../../helpers';
import {
  mergeCommentsEdits,
  mergeCoversEdits,
  mergePageSectionsEdits,
  mergePagesEdits,
  mergeSeoEdits,
  mergeWebsiteSectionsEdits,
  setRoot,
  setSection,
  togglePageDrawer,
  unsetSection
} from '../../../store/actions/sitemap-actions';

import PagesCanvas from './canvas';
import { Canvas as UserFlowsCanvas } from '../user-flows'
import { center } from './canvas/utils/zoom';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { isEmpty } from 'lodash'
import merge from 'deepmerge';
import { switchAccount } from '../../../store/actions/auth-actions';
import { withRouter } from 'react-router-dom';

export const barWidth = 225;
export const barHeight = 40 + (33) + 18; // 18 = header
export const indentedBarMargin = 20; // 18 = header

class App extends PureComponent {

  componentDidMount() {

    if (import.meta.env.DEV) {
      setTimeout(() => {
        // store.dispatch(toggleRecurringScreenshotsDrawer({ page: this.props.sitemap?.data.root }))
      }, 500);
    }

    // in personal account but sitemap was created in an organization that the user is in ***/
    // notifyIfinPersonalButShouldBeOrganization(this.props);
    // send data to GTM
    const { sitemap } = this.props;
    const { comments, collaborators } = sitemap;
    // only send viewed sitemap event if not new or generate
    if (sitemap?.id !== 'new' && sitemap?.id !== 'generate') {
      window.dataLayer.push({ event: 'viewed_sitemap', sitemap: { format: sitemap?.format, show_covers: sitemap?.showCovers, pallette: sitemap?.pallette?.header, covers_device: sitemap?.covers.device, number_of_comments: !isEmpty(Object.keys(comments)) ? Object.keys(comments).length : 0, number_of_collaborators: collaborators ? collaborators.length : 0 } });
    }

    // REMOVE THIS IN PROD
    // if (import.meta.env.DEV) setTimeout(() => showCommentsPopover(sitemap?.data.root), 2000);
    // if (import.meta.env.DEV) setTimeout(() => store.dispatch(togglePageDrawer({ node: sitemap?.data.root })), 1000);
    // if (import.meta.env.DEV) setTimeout(() => store.dispatch(initSection(sitemap?.data.root)), 500);
  }

  componentDidUpdate(prevProps) {

    const { sitemap } = this.props;
    const prevSitemap = prevProps.sitemap;
    const { section } = sitemap?.data;

    // check for merging page data
    checkForPageEdits(this.props, prevProps);
    // check for merging website sections data
    checkForWebsiteSectionEdits(this.props, prevProps);
    // check for merging page sections data
    checkForPageSectionsEdits(this.props, prevProps);
    // check for merging covers data
    checkForCoverEdits(this.props, prevProps);
    // check for merging comments data
    checkForCommentsEdits(this.props, prevProps);
    // check for merging seo data
    checkForSeoEdits(this.props, prevProps);
    // check for merging recurring screenshots data
    // checkForRecurringScreenshotsEdits(this.props, prevProps);

    // return if not in a sitemap section
    if (!section && !prevSitemap.data.section) return;
    // section has been created
    if (
      (section && !prevSitemap.data.section) ||
      (section && prevSitemap.data.section && section.id !== prevSitemap.data.section.id)
    ) {
      // make sure children are collapsed
      if (section.children) section.children.forEach(d => collapse(d, true)); // true = no update
      // center node
      center();
      // update
      update({ noSimulation: true });
    }
    // section has been cancelled
    if (!section && prevSitemap.data.section) {
      // center node
      center();
      // update
      return update();
    }
  }

  render() {
    const inSitemap = getInSitemap()
    const inUserFlow = getInUserFlow()
    return (
      <>
        {inSitemap && <PagesCanvas />}
        {inUserFlow && <UserFlowsCanvas />}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return state
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setRoot,
    setSection,
    unsetSection,
    mergePagesEdits,
    mergeWebsiteSectionsEdits,
    mergePageSectionsEdits,
    mergeCoversEdits,
    mergeCommentsEdits,
    mergeSeoEdits,
    togglePageDrawer,
    switchAccount
  }, dispatch)
}

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), firebaseConnect((props) => {
  return [];
}))(App));

/* const notifyIfinPersonalButShouldBeOrganization = (props) => {
  const { inUserAccount, sitemap, organizations } = props;
  if (inUserAccount) {
    var shouldBeInOrganization;
    if (!isEmpty(organizations)) {
      organizations.forEach(org => {
        if (includes(org.teams, sitemap?.team)) shouldBeInOrganization = { id: org.id, name: org.name };
      });
      if (shouldBeInOrganization) {
        notification.open({
          message: 'Are you in the right account?',
          // eslint-disable-next-line
          description: <> <div style={{ marginBottom: 9 }}>{`This sitemap belongs to `}<strong>{shouldBeInOrganization.name}</strong>{` but you are viewing it from your personal account.`}</div><a style={{ fontSize: 15, fontWeight: 500 }} onClick={(e) => { e.preventDefault(); props.switchAccount(shouldBeInOrganization.id); notification.destroy(); }}>{`Switch Account`}</a></>,
          placement: "topRight",
          duration: 0
        });
      }
    }
  }
  return false;
} */

const checkForPageEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const newData = checkForCollaboratingEditsToMerge(props, prevProps, 'pages');
  if (!newData) return;
  // merge page edits
  props.mergePagesEdits(newData);
};

const checkForWebsiteSectionEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const website_sections = checkForCollaboratingEditsToMerge(props, prevProps, 'website_sections');
  if (!website_sections) return;
  // merge page edits (return .data as we are merging whole doc)
  props.mergeWebsiteSectionsEdits({ website_sections: website_sections.data });
};

const checkForPageSectionsEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const newData = checkForCollaboratingEditsToMerge(props, prevProps, 'sections');
  if (!newData) return;
  // merge page edits
  props.mergePageSectionsEdits(newData);
};

const checkForCoverEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const newData = checkForCollaboratingEditsToMerge(props, prevProps, 'covers');
  if (!newData) return;
  // merge cover edits
  props.mergeCoversEdits(newData);
};

const checkForCommentsEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const newData = checkForCollaboratingEditsToMerge(props, prevProps, 'comments');
  if (!newData) return;
  // merge comments edits
  props.mergeCommentsEdits(newData);
};

const checkForSeoEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const newData = checkForCollaboratingEditsToMerge(props, prevProps, 'seo');
  if (!newData) return;
  // merge comments edits
  props.mergeSeoEdits(newData);
};

const checkForRecurringScreenshotsEdits = async (props, prevProps) => {
  // return here if no edits to merge
  const newData = checkForCollaboratingEditsToMerge(props, prevProps, 'scheduled_screenshots');
  if (!newData) return;
  // merge comments edits
  props.mergeRecurringScreenshotsEdits(newData);
};

export const checkForCollaboratingEditsToMerge = (props, prevProps, doc) => {
  /*** retrieve new sitemaps ****/
  const { sitemap, user } = props;
  const { id: sitemapId } = sitemap;
  const sitemaps = props.firestore.data.sitemaps;
  const prevSitemaps = prevProps.firestore.data.sitemaps;
  if (!sitemaps || !prevSitemaps) return;
  // no sitemap
  if (!sitemaps[sitemapId] || !prevSitemaps[sitemapId]) return;
  if (!sitemaps[sitemapId].data || !prevSitemaps[sitemapId].data) return;
  if (!sitemaps[sitemapId].data[doc]) return; // DON'T check for (!prevSitemaps[sitemapId].data.doc) here because it will not fire for the first ever doc change
  // if requested doc has changed in firestore listener (if stored in firestore)
  if (prevSitemaps[sitemapId].data[doc] && (JSON.stringify(sitemaps[sitemapId].data[doc]) !== JSON.stringify(prevSitemaps[sitemapId].data[doc]))) {
    // don't merge users own page and comments edits (have to always merge covers edits so we can merge thumbnails that have been created server-side with users uid)
    if (doc !== 'covers' && (sitemaps[sitemapId].data[doc].lastEdit === user.id)) return;
    // return new data to merge
    const docData = sitemaps[sitemapId].data[doc];
    const key = doc === 'website_sections' ? 'data' : 'pages';
    if (!docData || isEmpty(docData[key])) return;

    // RETURN DATA (for all docs apart pages/covers)
    if (doc !== 'pages' && doc !== 'covers') return docData;

    // ONLY MERGE CHANGES IF PAGES OR COVERS (AS NEED TO MERGE FOR FIRESTORE STORAGE VS FILE STORAGE)
    // if pages saved in firestore, have to loop through and delete any page that exists in current pages, but not in update (as we now merge them together due to storage changes being passed through firestore for collaboration)
    const pagesSavedInCloudStorage = sitemap?.docs.filesystem[doc] === 'storage';
    if (doc === 'pages' && !pagesSavedInCloudStorage) {
      if (!isEmpty(sitemap?.docs[doc])) {
        Object.keys(sitemap?.docs[doc]).forEach(pageId => {
          if (!docData[key][pageId]) delete sitemap?.docs[doc][pageId];
        });
      }
    };
    return { ...docData, [key]: clean(merge(sitemap?.docs[doc], docData[key]), doc) };
  };
};

function clean(object, doc) {
  if (doc === 'covers') return object;
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        clean(v);
      }
      // eslint-disable-next-line
      if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
        if (Array.isArray(object)) {
          object.splice(k, 1);
        } else {
          delete object[k];
        }
      }
    });
  return object;
}