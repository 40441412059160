import { centerNode, centerToComments, transform } from './utils/zoom';
import { coversCanvas, interactionsCanvas, mainCanvas, pickerCanvas, renderInteractionsCanvas } from './canvases';
import { getX, getY, shouldShowCovers } from './utils/helpers';
import { renderCover, renderHeader, renderLinks, renderNodeRect, renderPageSections, renderText, renderWebsiteSections } from './components';

import { dragging } from './utils/drag';
import { getSitemap } from '../../../../helpers';
import { isEmpty } from 'lodash'
import { showDownloadingCoversModal } from '../../../Editor/Toolbar/Export/ExportProgressModal';
import { store } from '../../../../store';
import { updateNodes } from '../views/nodes';

export var nodes = [];
export var links = [];

export var covers;
export var main;
export var picker;
export var interactions;

export var visibleNodes = [];

export const render = (n, l, pdf, opts = {}) => {

    const { forThumbnail } = opts;

    if (n) nodes = n;
    if (l) links = l;

    covers = coversCanvas;
    main = mainCanvas;
    picker = pickerCanvas;
    interactions = interactionsCanvas;

    const sitemap = getSitemap()
    if (sitemap?.format === 'nodes') return updateNodes(pdf);

    if (pdf) {
        return draw(pdf, { visibleNodes: forThumbnail ? forThumbnail : nodes }, { pdf, forThumbnail });
    }

    const visibleArea = getVisibleArea(transform);

    visibleNodes = [];

    for (var i = nodes.length; i--;) {
        var d = nodes[i];
        if (!d.parent) {
            visibleNodes.push(d); // always render home
        } else {
            const visible = isInside(getX(d), getY(d), visibleArea.left, visibleArea.top - (d.nodeHeight * 0.5), visibleArea.right, visibleArea.bottom + (d.nodeHeight * 0.5));
            if (visible) visibleNodes.push(d);
            if (!visible && (d.searched || d.goToComments)) visibleNodes.push(d); // make sure these are always visible
        }
    };

    window.requestAnimationFrame(function () {
        draw(coversCanvas, { visibleNodes }, { covers: true });
        draw(mainCanvas, { visibleNodes }, {});
        draw(pickerCanvas, { visibleNodes }, { hidden: true });
        renderInteractionsCanvas();
    })

};

export const draw = async (context, { visibleNodes }, opts) => {

    if (!context) return;

    const sitemap = getSitemap()

    if (!sitemap?.loaded) return;

    const notInSubfolder = !sitemap?.data?.section;

    const { hidden, covers, pdf, forThumbnail } = opts;
    var isMainContext = isEmpty(opts);

    var searched = null, goToComments = null;

    context.save();
    if (!pdf) context.clear();

    context.translate(transform.x, transform.y);
    context.scale(transform.k, transform.k);

    if ((isMainContext || pdf)) renderLinks(context, links, { forThumbnail });

    let base64CoversForExport = {};

    // don't render website-section homepages 
    visibleNodes = visibleNodes.filter(d => {
        if (!d.parent && d.website_section) if (notInSubfolder) return;
        return d;
    });

    /*** exporting covers ***/
    if (sitemap?.showCovers) {
        if (pdf) {

            await showDownloadingCoversModal(context, { nodes: visibleNodes, forThumbnail });
            const { ExportCoversProgressModal } = store.getState().sitemap?.ui;
            if (!ExportCoversProgressModal.showing) return { visibleNodes, base64CoversForExport };

            await Promise.all(visibleNodes.map(async d => {
                const coverByUrlKey = await renderCover(context, d, true, true); // last true is export
                base64CoversForExport = { ...coverByUrlKey, ...base64CoversForExport }
            }));

        }
    };
    /*** exporting covers ***/

    /*** visible nodes only ***/
    visibleNodes.forEach(d => {

        const showCovers = shouldShowCovers(d);

        if (showCovers && !d.hideWhileDragging) {
            const visible = true;
            if (!pdf) {
                if ((isMainContext) && (context.dragging && (context.dragging.id === d.id))) { renderCover(context, d, visible); }
                else if (covers) { renderCover(context, d, visible); }
            }
        }

        if (d.parent && !d.parent.children) return; // don't render node if collapsed
        if (d.hideWhileDragging) return
        if (dragging?.started && d?.id === dragging?.id) return;

        const draggingPlaceholder = d.id === 'dragging-placeholder' && dragging?.started;

        if (!covers) {
            renderNodeRect(context, d, hidden, draggingPlaceholder);
            if (draggingPlaceholder) return;
            if (!showCovers) renderPageSections(context, d, hidden, { forThumbnail });
            renderHeader(context, d, hidden);
            renderText(context, d, hidden, pdf);
        };

        if (isMainContext) {
            if (d.searched) searched = d;
            if (d.goToComments) goToComments = d;
        };

    });
    /*** visible nodes only ***/

    if (notInSubfolder) renderWebsiteSections(context, hidden)

    context.restore();

    if (searched) centerNode(searched);
    if (goToComments) centerToComments(goToComments);

    if (pdf) return { visibleNodes, base64CoversForExport };

}

export function isInside(x, y, z1, z2, z3, z4) {
    var x1 = Math.min(z1, z3);
    var x2 = Math.max(z1, z3);
    var y1 = Math.min(z2, z4);
    var y2 = Math.max(z2, z4);
    if ((x1 <= x) && (x <= x2) && (y1 <= y) && (y <= y2)) {
        return true;
    } else {
        return false;
        // eslint-disable-next-line
    };
};

export const minmax = (arr, key) => {
    const values = arr.map(value => {
        if (key === 'x') return getX(value);
        if (key === 'y') return getY(value) + value['nodeHeight'];
    });
    return {
        min: Math.min.apply(null, values),
        max: Math.max.apply(null, values)
    };
};

function getVisibleArea(t) {

    const sitemap = getSitemap()

    const { _maxNodeHeight } = sitemap?.data?.heights || {};

    var l = t.invert([0, 0]), r = t.invert([window.innerWidth, window.innerHeight]);
    var left = Math.trunc(l[0]);
    var top = Math.trunc(l[1]);
    var right = Math.trunc(r[0]);
    var bottom = Math.trunc(r[1]);

    /*** add 25% boundaries ***/
    left = left - 225;
    top = top - _maxNodeHeight;
    /*** add 25% boundaries ***/

    return { left, top, right, bottom };
}