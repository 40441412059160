import { Badge, Box, HStack, Heading, Stack } from "@chakra-ui/react"
import { useInHomeScreen, useInOrganization } from "../../hooks"
import { SkeletonWrapper } from "../Components/Shared/SkeletonWrapper"
import { TopBar } from "./Components/Topbar"
import { Sections } from "./Components/Sections"
import { TemplatesHOC } from "./TemplatesHOC"


export const Templates = () => {
    const inHomeScreen = useInHomeScreen()
    return (
        <TemplatesHOC>
            <Box pt={6} pb={inHomeScreen ? 0 : 6}>
                <Stack spacing={5}>
                    <HStack align="center" spacing={4}>
                        <SkeletonWrapper w="fit-content">
                            <Heading fontSize={inHomeScreen ? "xl" : "3xl"} fontWeight="semibold" py={.5}>
                                Templates
                            </Heading>
                        </SkeletonWrapper>
                        {!inHomeScreen && (
                            <SkeletonWrapper w="fit-content">
                                <Badge mt={1} rounded="sm" px={3.5} py={.5} colorScheme="orange" fontSize="sm">New</Badge>
                            </SkeletonWrapper>
                        )}
                    </HStack>
                    {!inHomeScreen && <TopBar />}
                    <Sections />
                </Stack>
            </Box>
        </TemplatesHOC>
    )
}