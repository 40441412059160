import { getInUserFlow, getIsUserFlowLinkedToSitemap } from '../../../../../helpers';
import { getURL, toggleExportCoversProgressModal } from '../../../../../store/actions/sitemap-actions';

import SVGtoPDF from 'svg-to-pdfkit';
import { getWidthAndHeight } from '../utils/svg';
import { render as renderSitemap } from '../../../../Sitemap/app/canvas/render';
import { render as renderUserFlow } from '../../../../Sitemap/user-flows/render';
import { store } from '../../../../../store';
import { wait } from '../../../../../../shared/helpers';
import { zoomIdentity } from 'd3'

export const renderPDF = async ({ size, layout, showName, showWebsite, setIsLoading }) => {

    setIsLoading(true);

    await wait(500);

    store.dispatch(toggleExportCoversProgressModal({ showing: true }));

    const { sitemap, editor } = store.getState();
    const inUserFlow = getInUserFlow();

    var { width, height } = sizes[size].layout[layout];

    const render = !inUserFlow ? renderSitemap : renderUserFlow;

    var ctx = window.C2S ? new window.C2S(width, height) : null;
    if (!ctx) return;

    const { visibleNodes, base64CoversForExport } = await render(null, null, ctx);

    const { ExportCoversProgressModal } = store.getState().sitemap?.ui;

    const showCovers = !inUserFlow && sitemap?.showCovers;
    const isUserFlowLinkedToSitemap = getIsUserFlowLinkedToSitemap();

    if ((isUserFlowLinkedToSitemap || showCovers) && !ExportCoversProgressModal.showing) return;

    // cancel covers download
    if (!store.getState().sitemap?.ui.ExportPDFDrawer.showing) return; // store.getState to ensure latest data

    var cloned = ctx.getSvg();

    if (inUserFlow || (!inUserFlow && sitemap?.format !== 'nodes')) {

        await Promise.all([...cloned.getElementsByTagName('image')].map(async (el, i) => {

            if (base64CoversForExport[el.href.baseVal]) {
                el.setAttributeNS(null, 'href', base64CoversForExport[el.href.baseVal]);
            } else {

                const height = parseInt(el.getAttribute("height"))
                const isPageSectionWireframe = height < 200

                el.setAttribute("x", el.transform.baseVal[0].matrix.e);
                el.setAttribute("y", el.transform.baseVal[0].matrix.f);

                let response = await fetch(el.href.baseVal, { cache: "force-cache" });
                const text = await response.text();
                var doc = new DOMParser().parseFromString(text, 'application/xml');

                if (isPageSectionWireframe) {
                    // create group
                    const g = doc.createElement("g")
                    // match scale
                    g.setAttribute("transform", "scale(0.9)")
                    // wrap in group
                    for (const child of doc.documentElement.children) g.innerHTML = g.innerHTML + child.outerHTML
                    // replace all children with group
                    doc.documentElement.replaceChildren(g)
                }

                doc.documentElement.setAttribute("x", el.getAttribute('x') - (isPageSectionWireframe ? 0 : 5));
                doc.documentElement.setAttribute("y", el.getAttribute('y') - (isPageSectionWireframe ? 2.5 : 0));

                if (!isPageSectionWireframe) {
                    doc.documentElement.setAttribute("width", 230);
                    doc.documentElement.setAttribute("height", 230);
                }

                el.parentNode.insertBefore(doc.documentElement, el);
                el.remove();

            };

        }
        ))

        /*** wrap node in link ***/
        const nodesById = {}
        visibleNodes?.forEach(node => nodesById[node.id] = node);
        var rects = cloned.getElementsByTagName("path");
        // eslint-disable-next-line
        for (var i = 0, max = rects.length; i < max; i++) {
            const el = rects[i];
            const nodeId = el.getAttribute('stroke-linejoin');
            if (nodeId) {
                const node = nodesById[nodeId];
                const link = document.createElementNS("http://www.w3.org/2000/svg", "a");
                link.setAttribute("href", getURL(node?.id));
                link.setAttribute("target", "_blank");

                const clonedEl = el.cloneNode();
                clonedEl.innerHTML = el.innerHTML; // ensure text is carried over
                link.appendChild(clonedEl);

                el.parentNode.insertBefore(link, el.nextSibling);
                el.parentNode.removeChild(el);
            }
        }
        /*** wrap node in link ***/

    };


    var doc = new window.PDFDocument({ compress: false, size: [width, height] });
    doc.info['Title'] = `${editor?.name}.pdf`;

    // name
    if (showName) {
        doc.fontSize(15);
        doc.text(editor?.name, 25, 25);
    };
    // website
    if (showWebsite) {
        if (showName) {
            doc.moveDown();
            doc.fontSize(12);
            doc.fillColor('grey');
            doc.text(sitemap?.domain);
        } else {
            doc.fontSize(15);
            doc.text((isUserFlowLinkedToSitemap ? sitemap?.name : sitemap?.domain), 25, 25);
        }
    };

    var marginTop = showName && showWebsite ? 75 : showName && !showWebsite ? 25 : 0;

    var fitted = zoomToFit({ size, layout, sitemap, opts: { marginTop, centerY: true } });

    var g = cloned.getElementsByTagName("g")[1];
    if (g) {
        g.setAttribute('transform', `translate(${fitted.translate.x},${fitted.translate.y}) scale(${fitted.translate.k})`);
    };

    SVGtoPDF(doc, cloned, 0, 0, { useCSS: true, preserveAspectRatio: 'none' });

    let stream = doc.pipe(window.blobStream());
    stream.on('finish', function () {
        var iframe = document.getElementById('export-pdf-preview');
        if (stream) {
            iframe.src = stream.toBlobURL('application/pdf');
        }
    });

    doc.end();

    setIsLoading(false);

    if (ExportCoversProgressModal.showing) store.dispatch(toggleExportCoversProgressModal({ showing: false }));

};

export const zoomToFit = ({ size, layout, sitemap, opts }) => {

    var { width, height } = sizes[size].layout[layout];

    const { centerY } = opts;

    const box = getWidthAndHeight(sitemap);

    var scale = 0.9 / Math.max(box.width / width, box.height / height);

    // Reset transform.
    let newTransform = zoomIdentity;
    // Center [0, 0].
    newTransform = newTransform.translate(width / 2, height / 2);
    // Apply scale.
    newTransform = newTransform.scale(scale);
    // Center elements.
    newTransform = newTransform.translate(-box.left - box.width / 2, -box.top - box.height / 2);

    return { translate: { ...newTransform, y: centerY ? newTransform.y : 0 }, x: 0, y: 0 };
};

const sizes = {
    'A1': { layout: { 'portrait': { width: 1684, height: 2384 }, 'landscape': { width: 2384, height: 1684 } } },
    'A2': { layout: { 'portrait': { width: 1191, height: 1684 }, 'landscape': { width: 1684, height: 1191 } } },
    'A3': { layout: { 'portrait': { width: 842, height: 1191 }, 'landscape': { width: 1191, height: 842 } } },
    'A4': { layout: { 'portrait': { width: 595, height: 842 }, 'landscape': { width: 842, height: 595 } } }
};
