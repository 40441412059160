import { coversCanvas, interactionsCanvas, mainCanvas, pickerCanvas, renderInteractionsCanvas } from '../app/canvas/canvases';
import { getIsUserFlowLinkedToSitemap, getPageDrawer, getUserFlow } from '../../../helpers';

import copy from 'fast-copy';
import { renderConnectors } from './components/connectors';
import { renderCover } from '../app/canvas/components';
import { renderNodes } from './components/nodes';
import { showDownloadingCoversModal } from '../../Editor/Toolbar/Export/ExportProgressModal';
import { store } from '../../../store';
import { transform } from '../app/canvas/utils/zoom';

export var nodes = [];
export var links = [];

export var userFlows;
export var covers;
export var main;
export var picker;
export var interactions;


export const render = (n, l, pdf, opts = {}) => {

    const { forThumbnail } = opts;

    if (n) nodes = copy(n);
    if (l) links = copy(l);

    covers = coversCanvas;
    main = mainCanvas;
    picker = pickerCanvas;
    interactions = interactionsCanvas;

    // draw(coversCanvas, { covers: true }); all get's written in main-canvas (so 'arrange' works)
    if (!pdf) {
        draw(mainCanvas, {});
        draw(pickerCanvas, { hidden: true });
    }
    if (pdf) {
        return draw(pdf, { pdf, forThumbnail });
    }

    renderInteractionsCanvas();

};

export const draw = async (context, opts) => {

    if (!context) return;
    
    const flow = getUserFlow()
    if (!flow?.loaded) return;

    const { hidden, pdf, forThumbnail } = opts;

    context.save();
    if (!pdf) context.clear();

    context.translate(transform.x, transform.y);
    context.scale(transform.k, transform.k);

    let base64CoversForExport = {};

    /*** exporting covers ***/
    const isLinkedToSitemap = getIsUserFlowLinkedToSitemap()
    if (isLinkedToSitemap && pdf) {

        await showDownloadingCoversModal(context, { nodes, forThumbnail });
        const { ExportCoversProgressModal } = store.getState().sitemap?.ui;
        if (!ExportCoversProgressModal.showing) return { visibleNodes: nodes, base64CoversForExport };

        await Promise.all(nodes.map(async d => {
            const coverByUrlKey = await renderCover(context, d, false, true); // last true is export
            base64CoversForExport = { ...coverByUrlKey, ...base64CoversForExport }
        }));

    }
    /*** exporting covers ***/

    nodes = renderNodes(nodes, context, opts)

    renderConnectors(context, links, nodes, hidden)

    context.restore();

    if (pdf) return { base64CoversForExport };

}