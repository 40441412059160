import { getOrganization } from '../../helpers'
import { addOrganization } from './organizations-actions'
import deepmerge from 'deepmerge'
import { getPeople } from './people-actions';
import { isEqual } from 'lodash'

export const setOrganization = claims => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const { organizations } = getState();
    dispatch({
      type: 'SET_ORGANIZATION',
      async payload() {
        //
        const { organization: orgId } = claims;
        const organization = organizations.data?.find(o => o.d === orgId);
        if (organization) return organization;
        // in organization
        const organizationDoc = await firestore.doc(`organizations/${orgId}`).get().catch(e => { console.error(e); });
        //
        if (organizationDoc?.exists) {
          // init profitwell (production only)
          if (import.meta.env.PROD) window.dataLayer.push({ 'event': 'start_pw', 'pw_user_id': organizationDoc.data()?.subscription?.customer_id });
          // return so we can continue
          return { id: orgId, ...organizationDoc.data() };
        }
      },
    }).then(({ value: org }) => {
      if (org) dispatch(addOrganization(org))
    }).catch(e => {
      console.error(e);
      return { error: e };
    });
  };
};

// new - merges in action
export const mergeOrganizationChange = (data) => {
  return (dispatch, getState) => {
    const { organization } = getState()
    dispatch({
      type: 'MERGE_ORGANIZATION_CHANGE', data: deepmerge(organization, data)
    })
  }
}

// overwrites doc from firestore merge
export const mergeOrganizationDocChanges = doc => {
  return (dispatch, getState) => {
    // merge
    dispatch({ type: 'MERGE_ORGANIZATION_DOC_CHANGES', doc })
    // dispatch get people if user keys have changed
    const organization = getOrganization()
    if (!isEqual(Object.keys(organization?.users), Object.keys(doc?.users))) dispatch(getPeople())
  };
};