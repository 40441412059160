import { useState } from "react";
import { Box, Button, Flex, Icon, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Skeleton, Stack, Text } from "@chakra-ui/react";
import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { toggleNewUserFlowModal } from "../../../store/actions/ui-actions";
import { useDispatch } from "react-redux";
import { NewSitemapMenu } from "../../Sitemaps/New/Menu";
import { TbSitemap } from "react-icons/tb";
import { RiFlowChart } from "react-icons/ri";
import { SkeletonWrapper } from "../Shared/SkeletonWrapper";
import { motionPropsZeroAnimation } from "../../../helpers";

const PX = 4

export const CreateDropdown = ({ button, placement, offset, hideDefaultHeader, hideHeader, defaultScreen, matchWidth, ignoreSkeleton }) => {
    const dispatch = useDispatch()
    const [screen, setScreen] = useState(defaultScreen || 'default')
    const isDefault = screen === "default"
    const isSitemap = screen === "sitemap"
    return (
        <Menu
            autoSelect={false}
            isLazy
            placement={placement || "bottom-end"}
            onOpen={() => {
                if (defaultScreen && screen !== defaultScreen) setScreen(defaultScreen)
            }}
            matchWidth={matchWidth && isDefault || false}
            offset={offset || null}
            closeOnSelect={isSitemap ? true : false}
            onClose={() => {
                setTimeout(() => {
                    setScreen('default')
                }, 250);
            }}
        >
            <Flex w="full">
                <SkeletonWrapper isLoaded={ignoreSkeleton}>
                    {button || <MenuButton as={Button} colorScheme="blue" fontSize="sm" px={3.5} h={9} minW={9} leftIcon={<AddIcon fontSize="xs" />} iconSpacing={3}>Create</MenuButton>}
                </SkeletonWrapper>
            </Flex>
            <MenuList fontSize="sm" motionProps={motionPropsZeroAnimation}>
                {isDefault && (
                    <MenuGroup as={Box} title={hideDefaultHeader ? null : <Text mt={-1.5} fontSize="lg">Create new</Text>} fontSize="md">
                        <MenuItem px={PX} py={2.5} icon={<Icon as={TbSitemap} fontSize="xl" />} onClick={(e) => setScreen('sitemap')}>
                            <Stack direction="row" justifyContent="space-between">
                                <Text>Visual Sitemap</Text>
                                <ChevronRightIcon fontSize="xl" />
                            </Stack>
                        </MenuItem>
                        <MenuItem px={PX} py={2.5} icon={<Icon as={RiFlowChart} fontSize="xl" />} onClick={() => dispatch(toggleNewUserFlowModal())}>
                            User Flow
                        </MenuItem>
                    </MenuGroup>
                )
                }
                {isSitemap && <NewSitemapMenu hideHeader={hideHeader} setScreen={setScreen} />}
            </MenuList>
        </Menu>
    )
}