import { Stack, Text, Button, useBoolean } from "@chakra-ui/react"
import { SectionHeader } from "../../Components"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { wait } from "../../../../../shared/helpers"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const DeleteAccount = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoggingOut, setIsLoggingOut] = useBoolean()
    return (
        <Stack spacing={5}>
            <SectionHeader title="Delete your account" />
            <Stack spacing={3}>
                <Text fontSize="sm">
                    <SkeletonWrapper w="fit-content">
                        By deleting your account, you'll no longer be able to access any of your data or log in to Rarchy.
                    </SkeletonWrapper>
                </Text>
            </Stack>
            <SkeletonWrapper w="fit-content">
                <Button
                    mt={3}
                    fontSize="sm"
                    w="250px"
                    isLoading={isLoggingOut}
                    onClick={async () => {
                        /* setIsLoggingOut.toggle()
                        await wait(2000)
                        dispatch(logOut(history))
                        setIsLoggingOut.toggle() */
                    }}>
                    Delete account
                </Button>
            </SkeletonWrapper>
        </Stack>
    )
}