import { Box } from "@chakra-ui/react"
import { YourAccount } from "./Account/YourAccount"
import { LoginAndSecurity } from "./Account/LoginAndSecurity"
import { OrgDetails } from "./Organization/Details"
import { InvoiceHistory } from "./Organization/InvoiceHistory"
import { People } from "./Organization/People"
import { Billing } from "./Organization/Billing"
import { Accounts } from "./Accounts"
import { useInOrganization, useIsOrganizationAdmin } from "../../hooks"

export const Settings = ({ match }) => {
    const inOrganization = useInOrganization()
    const isAdmin = useIsOrganizationAdmin()
    const { subfolder } = match?.params || {}
    return (
        <Box pt={5}>
            <>
                {subfolder === 'your-account' && <YourAccount />}
                {subfolder === 'login-and-security' && <LoginAndSecurity />}
            </>
            {inOrganization && (
                <>
                    {subfolder === 'org-details' && <OrgDetails />}
                    {subfolder === 'invoice-history' && isAdmin && <InvoiceHistory />}
                    {subfolder === 'billing-and-plans' && <Billing />}
                    {subfolder === 'people' && <People />}
                </>
            )}
            {subfolder === 'manage-accounts' && <Accounts />}
        </Box>
    )
}