import { Box, Button, ButtonGroup, useId, useRadio, useRadioGroup, useColorModeValue as mode } from '@chakra-ui/react'
import { Children, cloneElement, isValidElement, useMemo } from 'react'

export const RadioButtonGroup = (props) => {
    const { children, name, defaultValue, value, onChange, ...rest } = props
    const { getRootProps, getRadioProps } = useRadioGroup({
        name,
        defaultValue,
        value,
        onChange,
    })
    const buttons = useMemo(
        () =>
            Children.toArray(children)
                .filter(isValidElement)
                .map((button, index, array) => {
                    const isFirstItem = index === 0
                    const isLastItem = array.length === index + 1
                    const styleProps = Object.assign({
                        ...(isFirstItem && !isLastItem
                            ? {
                                borderRightRadius: 0,
                            }
                            : {}),
                        ...(!isFirstItem && isLastItem
                            ? {
                                borderLeftRadius: 0,
                            }
                            : {}),
                        ...(!isFirstItem && !isLastItem
                            ? {
                                borderRadius: 0,
                            }
                            : {}),
                        ...(!isLastItem
                            ? {
                                mr: '-px',
                            }
                            : {}),
                    })
                    return cloneElement(button, {
                        ...styleProps,
                        radioProps: getRadioProps({
                            value: button.props.value,
                            disabled: props.isDisabled || button.props.isDisabled,
                        }),
                    })
                }),
        [children, getRadioProps, props.isDisabled],
    )
    return (
        <ButtonGroup isAttached {...getRootProps(rest)}>
            {buttons}
        </ButtonGroup>
    )
}

export const RadioButton = (props) => {
    const { radioProps, ...rest } = props
    const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(radioProps)
    const id = useId(undefined, 'radio-button')
    const inputProps = getInputProps()
    const checkboxProps = getCheckboxProps()
    const labelProps = getLabelProps()
    const bgColor = "rarchy-bg-sitemap-editor"
    return (
        <Box
            as="label"
            textTransform="capitalize"
            cursor="pointer"
            {...labelProps}
            sx={{
                '.focus-visible + [data-focus]': {
                    boxShadow: 'outline',
                    zIndex: 1,
                },
            }}
        >
            <input {...inputProps} aria-labelledby={id} />
            <Button
                px={9}
                as="div"
                color="rarchy-color-sitemap-editor"
                borderColor="rarchy-border-sitemap-editor"
                fontWeight="medium"
                borderWidth={1}
                bg="transparent"
                _active={{ bg: bgColor }}
                _checked={{
                    color: mode('gray.700', 'whiteAlpha.900'),
                    bg: "rarchy-border-sitemap-editor",
                }}
                {...checkboxProps}
                {...rest}
            />
        </Box>
    )
}