import {
    Button
} from "@chakra-ui/react"
import React from 'react';
import { getIsDefaultPalletteHeader } from "../../../../../helpers";
import { showEditorPermissionsToast } from '../../../App/Components/PermissionsToast';

export const ResetButton = ({ selectedColor, handlePalletteChange, ...props }) => {
    const isDefaultPallette = getIsDefaultPalletteHeader(selectedColor)
    if (isDefaultPallette) return null
    return (
        <Button
            w="full"
            size="sm"
            fontWeight="medium"
            fontSize="xs"
            h={7}
            {...props}
            onClick={(e) => {
                // can edit
                const canEdit = showEditorPermissionsToast(); if (!canEdit) return;
                // continue
                e.stopPropagation(); handlePalletteChange({ header: null })
            }}
            mb={0.5}
        >
            Clear
        </Button>
    )
}