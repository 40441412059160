import { BsTrash } from "react-icons/bs"
import { useFolderHasSubfolders, useGetFilesDataByFolder, useGetFolderIdFromPath, useGetFolders, useGetUserId } from "../../../../../../hooks"
import { ICON_SIZE, PY } from "../Menu"
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Icon, MenuItem, Stack, useBoolean } from "@chakra-ui/react"
import { getIsItemOwner } from "../../../../../../helpers"
import { useDispatch } from "react-redux"
import { mergeFolderChange, toggleDeleteFolderModal } from "../../../../../../store/actions/folders-actions"
import { useHistory } from "react-router-dom"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { archiveFile } from "../../../../../Files/Components/Options/Delete"
import { wait } from "../../../../../../../shared/helpers"

export const DeleteFolder = ({ folder }) => {
    const dispatch = useDispatch()
    const isOwner = getIsItemOwner(folder)
    if (!isOwner) return null
    return (
        <MenuItem
            py={PY}
            icon={<Icon as={BsTrash} fontSize={ICON_SIZE} mr={0.5} />}
            onClick={() => { dispatch(toggleDeleteFolderModal(folder)) }}
        >
            Delete
        </MenuItem>
    )
}

export const DeleteFolderModal = () => {

    const dispatch = useDispatch()

    const [isDeleting, setIsDeleting] = useBoolean()

    const history = useHistory()

    const { folder } = useGetFolders().ui?.DeleteFolderModal || {}

    const files = useGetFilesDataByFolder({ folderId: folder?.id })

    const hasSubfolders = useFolderHasSubfolders({ folderId: folder?.id })

    const inFolder = useGetFolderIdFromPath() === folder?.id

    const userId = useGetUserId()

    return (

        <AlertDialog isOpen>
            <AlertDialogOverlay>
                <AlertDialogContent pb={3}>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {`Delete "${folder?.name}"`}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Stack>
                            {hasSubfolders && <Box>{`"${folder?.name}" has subfolders. You'll need to delete all subfolders before you can delete this folder.`}</Box>}
                            {!hasSubfolders && <Box>This folder will be permanently deleted and all the items within will be archived.</Box>}
                        </Stack>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={() => dispatch(toggleDeleteFolderModal())} fontSize="sm">
                            {hasSubfolders ? "Close" : "Cancel"}
                        </Button>
                        {!hasSubfolders && (
                            <Button
                                isLoading={isDeleting}
                                colorScheme='red'
                                ml={3}
                                fontSize="sm"
                                onClick={async (e) => {

                                    if (hasSubfolders) return;

                                    setIsDeleting.toggle()

                                    try {

                                        const now = new Date()

                                        const docData = {
                                            archived: true,
                                            archivedAt: now,
                                            deleteAt: new Date(now.setDate(now.getDate() + 30)),
                                            updatedBy: userId,
                                            updatedAt: now
                                        }

                                        await setDoc(doc(getFirestore(), "teams", folder?.id), docData, { merge: true })

                                        // go to projects if in folder
                                        if (inFolder) history.push("/app/projects");

                                        dispatch(mergeFolderChange({ [folder?.id]: undefined }))

                                        // manually set all files within folder as archived (doesn't update firestore, that's done through the "teamDeleted" function)
                                        files?.forEach(file => archiveFile({ dispatch, file }, { shouldSetInFirestore: false }))

                                        // close modal
                                        dispatch(toggleDeleteFolderModal())

                                    } catch (e) {
                                        console.error(e)
                                    } finally {
                                        setIsDeleting.toggle()
                                    }

                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}