import { findIndex, isEmpty } from 'lodash'
import { getCoverData, getInUserFlow } from "../../../../../helpers";
import { useGetFullscreenCoverDrawer, useGetSitemap, useGetUserFlow } from "../../../../../hooks";

import { getPageName } from "../../../utils/app";
import { useEffect } from "react";
import { useHotkeys } from 'react-hotkeys-hook'
import { useMouseEnteredTopBoundary } from "./MousePosition";

export const GetCovers = ({ setCovers, setSelectedIndex, handleScroll }) => {
    const sitemap = useGetSitemap()
    const flow = useGetUserFlow()
    const FullscreenCoverDrawer = useGetFullscreenCoverDrawer()
    useEffect(() => {
        const covers = getCoversData({ sitemap, flow })
        setCovers(covers);
        setSelectedIndex(findIndex(covers?.data, c => c.page === FullscreenCoverDrawer?.page) || 0)
        setTimeout(() => handleScroll('auto'), 250);
    }, [])
}

export const getCoversData = ({ sitemap, flow }, all) => {

    const { FullscreenCoverDrawer } = sitemap?.ui;

    const images = { data: [] };

    if (!FullscreenCoverDrawer.showing) return images;
    if (isEmpty(sitemap?.covers?.pages)) return images;

    const inUserFlow = getInUserFlow();

    if (!FullscreenCoverDrawer.preview) {

        const nodes = !inUserFlow ? (sitemap?.data.section ? sitemap?.data.section.descendants() : sitemap?.data.root.descendants()) : flow?.data?.nodes;

        /*** only gets covers from pages that are visible (default) ***/
        if (!all) {
            nodes.forEach((d, i) => {
                if (inUserFlow && d?.page !== FullscreenCoverDrawer?.page) return;
                var coverData = getCoverData({ sitemap, page: d?.[!inUserFlow ? 'id' : 'page'], device: FullscreenCoverDrawer?.device });
                if (coverData) {
                    const name = getPageName(d.name);
                    images.data.push({ page: d?.[!inUserFlow ? 'id' : 'page'], original: coverData.preview || coverData.downloadURL, thumbnail: coverData.preview || coverData.thumbDownloadURL, description: name, originalTitle: name, thumbnailTitle: name, thumbnailLabel: name })
                    if (d?.[!inUserFlow ? 'id' : 'page'] === FullscreenCoverDrawer.page) images.startIndex = images.data.length - 1;
                }
            });
        }
        /*** only gets covers from pages that are visible (default) ***/

        /*** gets all covers (regardless of what pages are visible) ***/
        if (all && !sitemap?.data.section) {
            Object.keys(sitemap?.covers?.pages).forEach(page => {
                var coverData = getCoverData({ sitemap, page });
                if (coverData && coverData.downloadURL) {
                    const pageData = sitemap?.docs.pages[page];
                    if (pageData) {
                        const name = getPageName(pageData.name);
                        images.data.push({ page, original: coverData.preview ? coverData.preview : coverData.downloadURL, thumbnail: coverData.preview ? coverData.preview : coverData.thumbDownloadURL, description: name, originalTitle: name, thumbnailTitle: name, thumbnailLabel: name })
                        if (page === FullscreenCoverDrawer.page) images.startIndex = images.data.length - 1;
                    }
                }
            });
        }
        if (all && sitemap?.data.section) {
            const recurse = (d) => {
                var coverData = getCoverData({ sitemap, page: d.id });
                if (coverData) {
                    const name = getPageName(d.name);
                    images.data.push({ page: d.id, original: coverData.preview ? coverData.preview : coverData.downloadURL, thumbnail: coverData.preview ? coverData.preview : coverData.thumbDownloadURL, description: name, originalTitle: name, thumbnailTitle: name, thumbnailLabel: name })
                    if (d.id === FullscreenCoverDrawer.page) images.startIndex = images.data.length - 1;
                }
                if (d.children) d.children.forEach(recurse);
                if (d._children) d._children.forEach(recurse);
            }
            recurse(sitemap?.data.section)
        }
        /*** gets all covers (regardless of what pages are visible) ***/
    };

    // previewing autocapture
    if (FullscreenCoverDrawer.preview) {
        images.data.push({ original: FullscreenCoverDrawer.preview, thumbnail: FullscreenCoverDrawer.preview })
    };

    images.count = images.data.length;

    return images;
}

export const HandleFullscreen = ({ fullscreen, showNavbar, setShowNavbar }) => {
    const inFullscreenBoundary = useMouseEnteredTopBoundary(fullscreen);
    useEffect(() => {
        if (fullscreen) {
            if (inFullscreenBoundary && !showNavbar) setShowNavbar(true);
            if (!inFullscreenBoundary && showNavbar) setShowNavbar(false);
        }
    })
}

export const HandleArrowPresses = ({ selectedIndex, setSelectedIndex, handleScroll, covers }) => {
    const handlePress = (direction) => {
        const oldIndex = parseInt(selectedIndex)
        let index = oldIndex;
        switch (direction) {
            case 'Up':
            case 'Left':
                index--
                break;
            case 'Down':
            case 'Right':
                index++
                break;
        }
        if (index < 0) index = 0;
        if (index > (covers?.data?.length || 1) - 1) index = (covers?.data?.length || 1) - 1;
        setSelectedIndex(index);
        handleScroll()
    }
    useHotkeys('Up', () => handlePress('Up'), [selectedIndex])
    useHotkeys('Down', () => handlePress('Down'), [selectedIndex])
    useHotkeys('Left', () => handlePress('Left'), [selectedIndex])
    useHotkeys('Right', () => handlePress('Right'), [selectedIndex])
}