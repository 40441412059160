import { addConnectorChange, addSymbolChange } from "../../../Sitemap/user-flows/helpers";
import { addFlowChange, redoFlowsUserChange, undoFlowsUserChange } from "../../../../store/actions/flow-actions";
import { isEmpty, keys } from 'lodash';

import deepmerge from 'deepmerge';
import { store } from "../../../../store";

const dispatch = (f) => store.dispatch(f);

export const handleUndoClickForUserFlow = () => {

    const { flow } = store.getState();
    const { history } = flow;

    const lastUndo = history.undo[history.undo.length - 1];
    if (!lastUndo) return console.log('no last undo');

    let symbolsData = {};
    const symbolsChangeData = [];

    let connectorsData = {};
    const connectorsChangeData = [];

    lastUndo?.reverse()?.forEach(undo => {
        const { action } = undo;
        switch (action) {
            case 'delete-symbol':
                // data for rendering
                symbolsData = deepmerge(symbolsData, { [undo.node]: { ...undo.data.oldSymbol } });
                /*** convert data for firestore ***/
                symbolsChangeData.push({ id: undo.node, ...undo.data.oldSymbol });
                keys(undo.data.oldConnectors).forEach(id => {
                    symbolsData = deepmerge(symbolsData, { [id]: { ...undo.data.oldConnectors[id] } }) // data for rendering (as oldConnectors contains linked connector data, need to loop through and update existing nodes, not just the one that was deleted)
                    symbolsChangeData.push({ action, id, ...undo.data.oldConnectors[id] })
                });
                /*** convert data for firestore ***/
                break;
            case 'reposition':
                // data for rendering
                symbolsData = deepmerge(symbolsData, { [undo.node]: { x: undo.data.oldX, y: undo.data.oldY } });
                /*** convert data for firestore ***/
                symbolsChangeData.push({ action, id: undo.node, x: undo.data.oldX, y: undo.data.oldY });
                /*** convert data for firestore ***/
                break;
            case 'new-symbol':
            case 'symbol-change':
                symbolsData = deepmerge(symbolsData, { [undo.node]: undo.data.oldSymbol });
                // change
                symbolsChangeData.push({ action, id: undo.node, ...undo.data.oldSymbol });
                break;
            case 'connector-change':
                connectorsData = deepmerge(connectorsData, { [undo.node]: undo.data.oldConnector });
                // change
                connectorsChangeData.push({ action, id: undo.node, ...undo.data.oldConnector });
                break;
            case 'new-symbol-and-connector':
                symbolsData = deepmerge(symbolsData, { ...undo.data.oldSymbol });
                // change
                symbolsChangeData.push({ action, id: undo.nodes.symbol, ...undo.data.oldSymbol });
                connectorsData = deepmerge(connectorsData, { [undo.nodes.connector]: undo.data.oldConnector });
                // change
                connectorsChangeData.push({ action, id: undo.nodes.connector, ...undo.data.oldConnector });
                break;
            default:
                break;
        };
    });
    // update flow data immediately
    if (!isEmpty(symbolsData)) addSymbolChange({ data: symbolsData })
    if (!isEmpty(connectorsData)) addConnectorChange({ data: connectorsData })
    // add undo user change in redux
    dispatch(undoFlowsUserChange());
    // save change in firestore via redux
    const change = { id: new Date().getTime(), data: !isEmpty(symbolsChangeData) ? symbolsChangeData : connectorsChangeData };
    dispatch(addFlowChange({ change }));
    return;

}

export const handleRedoClickForUserFlow = () => {

    const { flow } = store.getState();
    const { history } = flow;

    const lastRedo = history.redo[history.redo.length - 1];
    if (!lastRedo) return;

    let symbolsData = {};
    const symbolsChangeData = [];

    let connectorsData = {};
    const connectorsChangeData = [];

    lastRedo?.reverse()?.forEach(redo => {
        const { action } = redo;
        switch (action) {
            case 'delete-symbol':
                // data for rendering
                symbolsData = deepmerge(symbolsData, redo.data.newSymbol);
                /*** convert data for firestore ***/
                keys(redo.data.newSymbol).forEach(id => {
                    const o = redo.data.newSymbol[id];
                    if (isEmpty(o)) return symbolsChangeData.push({ action, id });
                    if (o.connectors) symbolsChangeData.push({ action, id, ...o });
                });
                /*** convert data for firestore ***/
                break;
            case 'reposition':
                // data for rendering
                symbolsData = deepmerge(symbolsData, { [redo.node]: { x: redo.data.newX, y: redo.data.newY } });
                /*** convert data for firestore ***/
                symbolsChangeData.push({ action, id: redo.node, x: redo.data.newX, y: redo.data.newY });
                /*** convert data for firestore ***/
                break;
            case 'new-symbol':
            case 'symbol-change':
                symbolsData = deepmerge(symbolsData, { [redo.node]: redo.data.newSymbol });
                // change
                symbolsChangeData.push({ action, id: redo.node, ...redo.data.newSymbol });
                break;
            case 'connector-change':
                connectorsData = deepmerge(connectorsData, { [redo.node]: redo.data.newConnector });
                // change
                connectorsChangeData.push({ action, id: redo.node, ...redo.data.newConnector });
                break;
            default:
                break;
        };
    });
    // update flow data immediately
    if (!isEmpty(symbolsData)) addSymbolChange({ data: symbolsData })
    if (!isEmpty(connectorsData)) addConnectorChange({ data: connectorsData })
    // add redo user change in redux
    dispatch(redoFlowsUserChange());
    // save change in firestore via redux
    const change = { id: new Date().getTime(), data: !isEmpty(symbolsChangeData) ? symbolsChangeData : connectorsChangeData };
    dispatch(addFlowChange({ change }));
}