import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    HStack,
    Box,
    Tooltip,
    Button
} from "@chakra-ui/react"

import { RiUserAddFill, RiUserShared2Fill } from 'react-icons/ri';
import { useCanCommentOnFile, useGetEditor, useHasEditorLoaded, useIsLoggedInAndGotUser } from '../../../../hooks';
import { toggleShareFileModal } from '../../../../store/actions/files-actions';
import { EditorSkeletonWrapper } from '../../Toolbar';

export const Share = () => {
    const dispatch = useDispatch()
    const file = useGetEditor()
    const numberOfCollaborators = useSelector(state => getNumberOfCollaborators(state))
    const isLoggedIn = useIsLoggedInAndGotUser()
    const loadedEditor = useHasEditorLoaded()
    const canCommentOnFile = useCanCommentOnFile()
    const showShare = !loadedEditor || (isLoggedIn && canCommentOnFile)
    return (
        <EditorSkeletonWrapper isLoaded={loadedEditor}>
            {showShare && (
                <Tooltip
                    variant="rounded"
                    label={numberOfCollaborators === 0 ? null : (
                        <HStack>
                            <RiUserShared2Fill />
                            <Box>{`Shared with ${numberOfCollaborators} ${numberOfCollaborators > 1 ? 'people' : 'person'}`}
                            </Box>
                        </HStack>
                    )}
                    openDelay={250}
                >
                    <Button
                        iconSpacing={3}
                        leftIcon={numberOfCollaborators > 0 ? <RiUserShared2Fill /> : <RiUserAddFill />}
                        fontSize="sm"
                        h={9}
                        minW={9}
                        colorScheme="blue"
                        onClick={() => dispatch(toggleShareFileModal(file))}
                    >
                        Share
                    </Button>
                </Tooltip>
            )}
        </EditorSkeletonWrapper>
    )
}

const getNumberOfCollaborators = state => {
    return Object.keys((state?.firestore?.data?.sitemaps?.[state?.sitemaps?.id]?.collaborators) || []).length
}