import { Divider, HStack, Stack, Text } from "@chakra-ui/react"
import { XMLUpload } from "../../Import/XML/Upload"
import { CSVUpload } from "../../Import/CSV"

export const Upload = ({ isXML, isCSV }) => {
    return (
        <Stack w="full">
            {isXML && (
                <HStack spacing={4} my={7}>
                    <Divider />
                    <Text fontSize="sm" fontWeight="medium" color="fg.muted" whiteSpace="nowrap">
                        or import from file
                    </Text>
                    <Divider />
                </HStack>
            )}
            {isXML && <XMLUpload />}
            {isCSV && <CSVUpload />}
        </Stack>
    )
}