import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"

import * as Sentry from "@sentry/react";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"

import App from './App';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { PricingProvider } from "./screens/Components/PricingProvider";
import { Provider } from 'react-redux';
import React from 'react';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { createFirestoreInstance } from 'redux-firestore'
import { createRoot } from 'react-dom/client';
import firebase from './config';
import { globalErrorHandling } from './errors';
// import { unregister } from './registerServiceWorker';
import history from "./history";
import { isInProd } from '../shared/helpers';
import myTheme from "./theme"
import { store } from "./store";

// // import amplitude from 'amplitude-js/amplitude';

// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en' // English.

/* import {
  CalendarDefaultTheme,
} from '@uselessdev/datepicker' */

// import { TrackJS } from "trackjs";

// service workers
// import registerServiceWorker from './registerServiceWorker';


// registerServiceWorker();

// unregister();

// TimeAgo.addDefaultLocale(en)

/*** init Sentry ***/
if (isInProd) {
  try {
    Sentry.init({
      dsn: "https://b9a2f8c39d2d99f5dd3d8c78f9ca1b99@o4506726513442816.ingest.us.sentry.io/4507353759678464",
      integrations: [],
      ignoreErrors: [/does not exist/],
    });
  } catch (e) { console.error(e) }
}
/*** init Sentry ***/

/*** init GTM ***/
TagManager.initialize({
  gtmId: import.meta.env.VITE__GTM_ID,
  auth: import.meta.env.VITE__GTM_AUTH,
  preview: import.meta.env.VITE__GTM_PREVIEW
});
/*** init GTM ***/

// extendTheme(CalendarDefaultTheme, { styles }) (passed to theme for calendar previously)

// const proTheme = extendTheme(theme)

const rrfProps = {
  firebase,
  config: {
    useFirestoreForProfile: true,
    userProfile: 'users',
    enableClaims: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
}

const render = () => {

  // amplitude.getInstance().init(import.meta.env.VITE__AMPLITUDE); // init amplitude
  window.hsConversationsSettings = { loadImmediately: false }; // ensure hubspot chatbot doesnt load immediately

  const container = document.getElementById('root');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript

  root.render(
    <ReactReduxFirebaseProvider {...rrfProps}>
      <HubspotProvider>
        <Provider store={store}>
          <Router history={history} basename="app">
            <ChakraProvider theme={myTheme()} portalZIndex={11} toastOptions={{ defaultOptions: { position: 'top' } }}>
              <ColorModeScript initialColorMode={myTheme().config.initialColorMode} />
              <PricingProvider> {/*** here so it only mounts once ***/}
                <App />
              </PricingProvider>
            </ChakraProvider>
          </Router>
        </Provider>
      </HubspotProvider>
    </ReactReduxFirebaseProvider>
  );
};

// fail-safe
if (!window.location.pathname?.startsWith("/")) window.location.replace("/app")

/*** don't display console.log in production ***/
function noop() { }
if (isInProd) {
  console.log = noop;
  console.warn = noop;
  // console.error = noop; // show error messages in production
}
/*** don't display console.log in production ***/

// Render once
render();

// error handling
globalErrorHandling();

// registerServiceWorker();