import { Badge, Box, ListIcon, Stack, Text } from "@chakra-ui/react"
import { ComboboxItem, listItemStyles } from "."
import { isEmpty, orderBy, sortBy } from 'lodash'

import { Bread } from "../../../../Projects/Folders/Folder";
import { BsFolder } from "react-icons/bs";
import { Link } from "react-router-dom";
import { RiFlowChart } from "react-icons/ri";
import { TbSitemap } from "react-icons/tb";
import { chain } from "../../../../../helpers/chain";
import { getCollectionLabelFromDoc } from "../../../../../helpers";

export const FoldersAndFiles = ({ items, inputVal, getItemProps, highlightedIndex, bgColor, onClose }) => {

    let indexCount = 0;
    const grouped = sortBy(chain(items)
        .groupBy("collection")
        .map((value, key) => {
            const isFolders = key === 'undefined'
            // sort value
            value = orderBy(value, [isFolders ? 'createdAt' : 'updatedAt'], ['desc'])
            // set indexes
            value = value.map(v => {
                v.index = indexCount;
                indexCount = indexCount + 1;
                return v;
            })
            // check for filter
            value = value.filter(v => v?.name?.toLowerCase().includes(inputVal))
            // return  
            return { key: isFolders ? 'folders' : `${getCollectionLabelFromDoc({ collection: key })}s`, data: value }
        })
        .value(), 'key')
    indexCount = 0;

    return (
        <>
            {[...grouped]?.filter(g => !isEmpty(g?.data)).map(g => {
                return (
                    <Box key={g?.key} mb={2}>
                        <Stack px={5} direction="row" bg="rarchy-bg-subtle" py={1.5} align="center" spacing={3} justify="space-between">
                            <Text fontSize="sm" fontWeight="semibold" textTransform="capitalize">{g?.key}</Text>
                            <Badge>{g?.data?.length}</Badge>
                        </Stack>
                        {[...g?.data]?.map(d => {
                            const isActive = d.index === highlightedIndex;
                            return (
                                <Link
                                    key={d.id}
                                    to={d?.link}
                                    onClick={onClose}
                                >
                                    <Box px={2.5}>
                                        <ComboboxItem
                                            {...getItemProps({ item: d, index: d.index })}
                                            key={d.index}
                                            {...listItemStyles}
                                            bgColor={isActive ? bgColor : null}
                                        >
                                            <ListIcon as={d.collection === 'sitemaps' ? TbSitemap : d.collection === 'user-flows' ? RiFlowChart : BsFolder} fontSize="24px" opacity="0.66" />
                                            <Box ml={4} overflow="auto">
                                                <Bread itemId={d?.id} forSearch />
                                                <Box fontWeight="semibold">{d?.name}</Box>
                                            </Box>
                                        </ComboboxItem>
                                    </Box>
                                </Link>
                            )
                        })}
                    </Box>
                )
            })}
        </>
    )
}