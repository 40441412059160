import { Alert, Heading, VStack } from "@chakra-ui/react";
import { useGetRedirectUserQueryParams } from "../../../../hooks";
import { getItemNameFromDoc } from "../../../../helpers";

export const InvitationMessaging = () => {
    const { invitedBy, key, name, message, permission } = useGetRedirectUserQueryParams();
    const forOrganization = key === 'organizations'
    const itemDocName = getItemNameFromDoc({ collection: key })
    return (
        <VStack align="start" spacing={6}>
            <Heading fontSize="lg" color="rarchy-link" fontWeight="medium" lineHeight="1.5">
                {!forOrganization && (
                    <>
                        {`${invitedBy} has invited you to ${permission === 'comment' ? 'comment on' : 'edit'} their ${itemDocName?.toLowerCase()}: "${name}"`}
                    </>
                )}
                {forOrganization && (
                    <>
                        {`${invitedBy} has invited you to join their team: "${name}"`}
                    </>
                )}
            </Heading>
            {message && <Alert bg="rarchy-bg-subtle-hover">{message}</Alert>}
        </VStack>
    )
}