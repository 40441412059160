import { Box, Button, Icon, IconButton, Stack, Tooltip } from "@chakra-ui/react"
import { Bread } from ".."
import { New } from "../../../Components/New"
import { FolderMenu } from "../../Components/Options/Menu"
import { ShareDropdown } from "../../Components/Share/Dropdown"
import { SharedAvatarGroup } from "../../../../Components/Shared/AvatarGroup"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"

export const Topbar = ({ folder }) => {
    return (
        <Stack direction="row" w="full" justify="space-between" align="center">
            <Bread />
            <Stack direction="row">
                <SkeletonWrapper w="fit-content">
                    <ShareDropdown
                        folder={folder}
                        button={<IconButton variant="unstyled" icon={<SharedAvatarGroup folder={folder} forFolderTopbar />} />}
                    />
                </SkeletonWrapper>
                <SkeletonWrapper w="fit-content">
                    <ShareDropdown
                        folder={folder}
                        button={
                            <Box>
                                <Tooltip label="Invite people to this folder" hasArrow variant="rounded-sm" openDelay={500}>
                                    <IconButton variant="ghost" icon={<Icon as={AiOutlineUsergroupAdd} fontSize="2xl" />} />
                                </Tooltip>
                            </Box>
                        }
                    />
                </SkeletonWrapper>
                <SkeletonWrapper w="fit-content">
                    <FolderMenu folder={folder} opts={{ forFolderTopbar: true }} />
                </SkeletonWrapper>
                <SkeletonWrapper w="fit-content">
                    <New />
                </SkeletonWrapper>
                <SkeletonWrapper w="fit-content">
                    <ShareDropdown folder={folder} button={<Button px={8} fontSize="sm">Share</Button>} />
                </SkeletonWrapper>
            </Stack>
        </Stack>
    )
}