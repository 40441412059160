import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from "@chakra-ui/react"
import { useFormikContext } from "formik"
import { GoToStripe } from "../Components/GoToStripe"

export const Details = () => {
    const { values, errors, touched, handleChange, handleBlur } = useFormikContext()
    const { email, orgName, orgWebsite } = values
    return (
        <Stack w="full" spacing={5}>
            <Stack w="full" spacing={5} mt={4}>
                <FormControl id="orgName" isRequired isInvalid={!!errors.orgName && touched.orgName}>
                    <FormLabel fontSize="sm">Account name</FormLabel>
                    <Input
                        name="orgName"
                        defaultValue={orgName || null}
                        isRequired
                        placeholder="Name your account (i.e your company's name)"
                        fontSize="sm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormErrorMessage fontSize="xs">{errors.orgName}</FormErrorMessage>
                </FormControl>
                <FormControl id="orgWebsite" isRequired isInvalid={!!errors.orgWebsite && touched.orgWebsite}>
                    <FormLabel fontSize="sm">Company Website</FormLabel>
                    <Input
                        name="orgWebsite"
                        defaultValue={orgWebsite || null}
                        isRequired
                        placeholder="The website of the company you work for"
                        fontSize="sm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormErrorMessage fontSize="xs">{errors.orgWebsite}</FormErrorMessage>
                </FormControl>
                <FormControl id="email">
                    <FormLabel fontSize="sm">Billing Email</FormLabel>
                    <Input
                        name="email"
                        defaultValue={email}
                        type="email"
                        isRequired
                        placeholder="Enter your work email"
                        fontSize="sm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormControl>
            </Stack>
            <Stack spacing={3.5}>
                <GoToStripe />
            </Stack>
        </Stack>
    )
}