import React, { useEffect, useRef } from 'react';

export let pickerCanvas;

const ID = "picker-canvas";

export const PickerCanvas = React.memo(() => {
    const canvasRef = useRef(null);
    useEffect(() => {
        pickerCanvas = canvasRef.current.getContext('2d', { alpha: true, willReadFrequently: true });
    }, [])
    return <canvas id={ID} ref={canvasRef} style={{ display: 'none' }} />
})
