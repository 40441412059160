import { Box, ListItem, UnorderedList, VStack } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../hooks"
import { TRIAL_DAYS, useCanStartNewTrial, useGetPlanLimits } from "../../../../shared/hooks/pricing"
import { toTitleCase } from "../../../helpers"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { CollaborateWithOthersListItem, UnlimitedFilesListItem } from "../Components/Messaging/ListItems"

export const Screenshots = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    const limits = useGetPlanLimits(planId)
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                Capture up to <strong>{limits?.screenshots?.toLocaleString()} full-page screenshots</strong>{` every month with Rarchy ${toTitleCase(planId)}. ${canStartNewTrial ? `Try for free for ${TRIAL_DAYS} days.` : 'Upgrade your plan today.'}`}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                <UnlimitedFilesListItem />
                <ListItem>
                    {`Import up to ${limits?.screenshots?.toLocaleString()} pages per sitemap: crawl your site or upload your existing sitemap`}
                </ListItem>
                <CollaborateWithOthersListItem />
            </UnorderedList>
        </VStack>
    )
}