import { Button, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, VStack } from "@chakra-ui/react";
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { PERMISSIONS, getPermissionWithSuffix, getPermissionsKey } from "./helpers";
import { useCanEditFile, useGetUser, useIsOrganizationAdmin } from "../../../../hooks";
import { toTitleCase } from "../../../../helpers";

// ORG
// no-one can remove the owner
// users with "user" role can't add / edit
// anyone with edit access can add/remove/change user permissions
// people can't change their own permissions

// FILE
// no-one can remove the owner
// people with full edit access can change anyone
// people with edit access can change anyone
// people with comment access can't change anyone

export const Permissions = ({
    file,
    user,
    forOrganization,
    newPermission,
    onChange = () => { },
    opts = { variant: "ghost", size: "sm" }
}) => {

    // ensure user can't change their own permissions (org & file)
    const isUserChangingThemself = useGetUser()?.id === user?.id
    // ensure "users" can't change admins to users

    const isOrganizationAdmin = useIsOrganizationAdmin()
    const canEditFile = useCanEditFile(file || {})

    const canEdit = isUserChangingThemself ? false : (!forOrganization ? canEditFile : isOrganizationAdmin)

    const key = getPermissionsKey(forOrganization)

    const isDisabled = !newPermission && (user?.owner || !canEdit)

    return (
        <>
            <Menu autoSelect={false} placement="bottom-end">
                <MenuButton
                    as={Button}
                    isDisabled={isDisabled}
                    variant={opts.variant}
                    size={opts.size}
                    fontSize="sm"
                    rightIcon={isDisabled ? null : <ChevronDownIcon />}
                    fontWeight="medium"
                    _hover={{ cursor: isDisabled ? 'unset' : 'pointer', bgColor: !isDisabled ? "rarchy-bg-subtle-hover-200" : "unset" }}
                >
                    {`${toTitleCase(getPermissionWithSuffix((user?.[key] || newPermission), key, user?.owner))} ${isUserChangingThemself ? "(you)" : ""}`}
                </MenuButton>
                <MenuList fontSize="sm" minW="150px">
                    {PERMISSIONS[key]?.map(permission => {
                        const samePermissionAsUser = user?.[key] === permission
                        const isPermission = (samePermissionAsUser || (newPermission === permission));
                        const p = getPermissionWithSuffix(permission, key, user?.owner);
                        return (
                            <MenuItem
                                key={permission}
                                py={2}
                                textTransform="capitalize"
                                onClick={() => {
                                    if (!samePermissionAsUser) onChange(permission)
                                }}>
                                <VStack align="start" spacing={1}>
                                    <HStack spacing={3}>
                                        {isPermission && <CheckIcon pos="absolute" ml={1} color="fg.muted" fontSize="xs" />}
                                        <Text fontSize="sm" px={8}>{p}</Text>
                                    </HStack>
                                </VStack>
                            </MenuItem>
                        )
                    })}
                    {canEdit && !newPermission && (
                        <>
                            <MenuDivider />
                            <MenuItem
                                key="delete"
                                align="end"
                                px={10}
                                py={2}
                                color="rarchy-red"
                                onClick={() => onChange(null)}>
                                Remove access
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Menu>
        </>
    )
}