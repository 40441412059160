import React, { useState } from 'react'
import {
    Box,
    Divider,
    Button,
    Center,
    HStack,
    Icon,
    Spinner,
    Square,
    Text,
    VStack,
    Progress,
} from '@chakra-ui/react'
import Dropzone from 'react-dropzone'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useFirebase } from 'react-redux-firebase'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

export const XMLUpload = () => {
    const firebase = useFirebase()
    const [uploading, setUploading] = useState()
    const { values, setFieldValue, handleSubmit } = useFormikContext()
    const isDisabled = !isEmpty(uploading)
    return (
        <VStack spacing={9}>
            <Box w="full">
                <Dropzone
                    acceptedFiles="text/xml"
                    noDrag={isDisabled}
                    noClick={isDisabled}
                    maxFiles={1}
                    validator={(file) => {
                        const isXML = file.type === 'text/xml'
                        if (!isXML) {
                            alert('Only an XML file can be uploaded');
                            return { code: "not-valid-type" };
                        }
                        //
                        const isLt5M = file.size / 1024 / 1024 < 5;
                        if (!isLt5M) {
                            alert('File must be smaller than 5MB');
                            return { code: "size-too-large" };
                        }
                        return null;
                    }}
                    onDrop={(acceptedFiles) => {

                        const file = acceptedFiles[0];
                        if (!file) return;

                        setUploading({ name: file?.name, progress: 0 })

                        // set fileName in formik
                        setFieldValue('fileName', file?.name)

                        // upload cover
                        let storageRef = firebase.storage().ref().child(`sitemaps_processing/xml/${values?.importId}.xml`)
                        
                        let uploadTask = storageRef.put(file);

                        uploadTask.on(
                            'state_changed',
                            snapshot => {
                                var percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                                percent = percent < 1 ? 1 : percent
                                setUploading({ name: file.name, progress: percent < 100 ? percent : 99 })
                            },
                            error => {
                                console.log(error)
                            },
                            async () => {
                                setUploading({ name: file.name, progress: 100 }) // set to success
                                setFieldValue("url", await storageRef.getDownloadURL())
                                setTimeout(() => handleSubmit(), 500);
                            }, 1500);
                    }}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <>
                            <Center
                                rounded="md"
                                borderWidth="1px"
                                borderColor="rarchy-border"
                                _hover={{ cursor: !isEmpty(uploading) ? 'not-allowed' : 'pointer' }}
                                boxShadow="xs"
                                p="6"
                                bg={isDragActive ? "rarchy-bg-subtle" : "rarchy-bg-sitemap-editor"}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <VStack spacing="4">
                                    <Square size="20" rounded="lg">
                                        <Icon color="fg.subtle" as={AiOutlineCloudUpload} boxSize="20" />
                                    </Square>
                                    <VStack spacing="6">
                                        <HStack spacing="1" whiteSpace="nowrap">
                                            {!isDragActive && <Button variant="link" colorScheme="blue" size="md">
                                                Click to upload
                                            </Button>}
                                            <Text>
                                                {isDragActive ? 'Drop file to upload' : 'or drop file here'}
                                            </Text>
                                        </HStack>
                                        <Text fontSize="sm" color="fg.subtle">
                                            XML files only. 5MB max file size.
                                        </Text>
                                    </VStack>
                                </VStack>
                            </Center>
                            {!isEmpty(uploading) && (
                                <>
                                    <Divider />
                                    <HStack spacing={4} p={2}>
                                        {uploading.progress < 100 && <Spinner size="md" color="rarchy-link" />}
                                        {uploading.progress === 100 && <CheckCircleIcon fontSize="2xl" color="green.500" />}
                                        <VStack w="full" alignItems="start">
                                            <Text noOfLines={1} fontSize="13px">{uploading.name}</Text>
                                            <Progress w="full" rounded="md" colorScheme={uploading.progress === 100 ? 'green' : 'twitter'} size='xs' value={uploading.progress} />
                                        </VStack>
                                    </HStack>
                                </>
                            )}
                        </>
                    )}
                </Dropzone>
            </Box>
        </VStack>
    )
}