import { useDispatch } from 'react-redux'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    Stack,
    Box,
    IconButton
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { AddUsersHOC } from './AddUsersHOC'
import { Invite } from '../Components/Shared/People/Invite'
import { People } from '../Components/Shared/People'
import { toggleAddUsersModal } from '../../store/actions/ui-actions'
import { isEmpty } from 'lodash'

export const AddUsersModal = () => {

    const dispatch = useDispatch()
    const onClose = () => dispatch(toggleAddUsersModal())

    return (
        <AddUsersHOC onClose={onClose}>
            {({ setInputValue, peopleToInvite, setPeopleToInvite }) => {
                const hasPeopleToInvite = !isEmpty(peopleToInvite)
                return (
                    <Modal isOpen onClose={onClose} size="lg" isCentered>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader px={7}>
                                <Stack direction="row" spacing={3}>
                                    {hasPeopleToInvite && (
                                        <IconButton
                                            variant="ghost"
                                            size="sm"
                                            icon={<ArrowBackIcon fontSize="xl" />}
                                            onClick={() => {
                                                setInputValue('')
                                                setPeopleToInvite([])
                                            }}
                                        />
                                    )}
                                    <Box>Your team</Box>
                                </Stack>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody px={0} py={0}>
                                <Stack spacing={4} mb={6}>
                                    <Invite forOrganization />
                                    <People forOrganization />
                                </Stack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                )
            }}
        </AddUsersHOC>
    )
}
