import { Box, Button, Stack, Text, useBoolean } from "@chakra-ui/react"
import { MainHeader } from "../../components"
import { useGetSubscription, useIsOrganizationAdmin } from "../../../../hooks"
import { useFirebase } from "react-redux-firebase"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const InvoiceHistory = () => {
    const [isLoading, setIsLoading] = useBoolean()
    const firebase = useFirebase()
    const subscription = useGetSubscription()
    const isAdmin = useIsOrganizationAdmin()
    const { customer_id } = subscription || {}
    return (
        <Stack maxW="3xl" spacing={7}>
            <Box>
                <MainHeader title="Invoice history" />
                <Stack spacing={6} mt={10}>
                    <SkeletonWrapper w="fit-content">
                        <Text>
                            We're working on building out this section, which we hope to release very soon.
                        </Text>
                    </SkeletonWrapper>
                    <SkeletonWrapper w="fit-content">
                        <Box>
                            In the meantime, you can view your invoice history in <Button variant="link"
                                isDisabled={!isAdmin}
                                fontWeight="medium"
                                color="rarchy-link"
                                isLoading={isLoading}
                                onClick={async () => {
                                    setIsLoading.toggle()
                                    const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')({ customerId: customer_id })
                                    window.open(sessionURL.data, '_blank');
                                    setIsLoading.toggle()
                                }}>
                                our billing platform
                            </Button> (opens in a new tab)
                        </Box>
                    </SkeletonWrapper>
                </Stack>
            </Box>
        </Stack>
    )
}