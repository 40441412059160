import { Box, DarkMode, Heading, Icon, Image, Stack, useColorModeValue as mode } from "@chakra-ui/react"
import { FaRegFileCode, FaRegFileExcel, FaSpider } from "react-icons/fa"
import { TRIAL_DAYS, useCanStartNewTrial, useGetPagesImportLimitByPlan, useIsTrialing } from "../../../../../shared/hooks/pricing"
import { useGetUpgradeModal, useInOrganization } from "../../../../hooks"

import { RepeatClockIcon } from "@chakra-ui/icons"
import { toTitleCase } from "../../../../helpers"

const LightImage = "https://help.rarchy.com/hubfs/%2B%205%2c000-min-1080.png"
const DarkImage = "https://help.rarchy.com/hubfs/%2B%205%2c000%20-%20Dark-min-1080.png"

export const RightPagesPerImport = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    const isTrialing = useIsTrialing()
    const inOrganization = useInOrganization()
    const pages_per_import = useGetPagesImportLimitByPlan({ planId })
    return (
        <Box w="full" h="full" bgColor="rarchy-bg-upgrade-modal" p={6} py={12} align="center">
            <DarkMode>
                <Stack spacing={10} align="center" justifyContent="space-between">
                    <Stack spacing={0}>
                        <Stack direction="row" w="full" justify="space-between" px={16}>
                            <Icon as={FaSpider} fontSize="5xl" color="fg.muted" />
                            <Icon as={FaRegFileCode} fontSize="5xl" color="fg.muted" />
                            <Icon as={FaRegFileExcel} fontSize="5xl" color="fg.muted" />
                            <RepeatClockIcon fontSize="5xl" color="fg.muted" />
                        </Stack>
                        <Image src={mode(LightImage, DarkImage)} w="420px" py={12} />
                    </Stack>
                    <Stack spacing={6} px={12}>
                        <Heading fontSize="2xl" color="fg.default">
                            {`Import up to ${pages_per_import?.toLocaleString()} pages per sitemap with Rarchy ${toTitleCase(planId)}`}
                        </Heading>
                        <Box color="fg.muted" fontSize="sm">
                            {canStartNewTrial && `Start a free ${TRIAL_DAYS}-day trial of Rarchy ${toTitleCase(planId)} `}
                            {isTrialing && `Upgrade your trial to Rarchy ${toTitleCase(planId)} `}
                            {inOrganization && !isTrialing && `Upgrade your plan to Rarchy ${toTitleCase(planId)} `}
                            {!inOrganization && !canStartNewTrial && `Upgrade to Rarchy ${toTitleCase(planId)} `}
                            {`to import up to ${pages_per_import?.toLocaleString()} pages per sitemap.`}
                        </Box>
                    </Stack>
                </Stack>
            </DarkMode>
        </Box>
    )
}