import React from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Tooltip
} from "@chakra-ui/react"
import { useDispatch, useSelector } from 'react-redux'
import { addSymbolChange } from '../../helpers'
import { BsDisplay, BsPhone } from 'react-icons/bs'
import { hideFlowsOptionsPopover } from '../../../../../store/actions/flow-actions'

const HEIGHT = "33px"

export const Device = ({ OptionsPopover }) => {
    const dispatch = useDispatch();
    const handleClick = (device) => {
        const { nodes } = OptionsPopover;
        const data = {}; nodes.forEach(d => data[d.id] = { device })
        const oldData = {}; nodes.forEach(({ id, device }) => oldData[id] = { ...oldData[id], device });
        addSymbolChange({ data, oldData });
        dispatch(hideFlowsOptionsPopover());
    }
    const selectedDevice = OptionsPopover.nodes[0]?.device || 'desktop';
    return (
        <Menu autoSelect={false} isLazy>
            <Tooltip variant="rounded" placement='top' label='Device' openDelay={1000}>
                <MenuButton as={IconButton} size="sm" variant="ghost" icon={devices[selectedDevice].icon} onClick={e => e.stopPropagation()} />
            </Tooltip>
            <MenuList fontSize="sm" minW={0} onClick={(e) => handleClick(e.target.value)}>
                {Object.keys(devices).map((device, i) => (
                    <MenuItem
                        key={i}
                        h={HEIGHT}
                        value={device}
                        icon={devices[device].icon}
                    >
                        {devices[device].text}
                    </MenuItem>
                ))}
                <MenuItem pl={9} onClick={() => handleClick(null)}>
                    Reset
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

const devices = {
    desktop: {
        text: 'Desktop',
        icon: <BsDisplay />,
    },
    mobile: {
        text: 'Mobile',
        icon: <BsPhone />,
    }
}