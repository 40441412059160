import { Stack } from "@chakra-ui/react"
import { UseCases } from "../../../Auth/SignupLogin/Signup/Segmentation/UseCases"
import { SectionHeader } from "../../Components"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { useGetUserId } from "../../../../hooks"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const UseCase = () => {
    const userId = useGetUserId()
    return (
        <Stack spacing={3} mt={1.5} maxW="xs">
            <SectionHeader title="What will you be using Rarchy for?" />
            {userId && (
                <SkeletonWrapper w="fit-content">
                <UseCases
                    forAccount
                    onChange={async (primary_use_case) => {
                        const userRef = doc(getFirestore(), 'users', userId);
                        await setDoc(userRef, { selfReported: { primary_use_case } }, { merge: true });
                    }} />
                    </SkeletonWrapper>
            )}
        </Stack>
    )
}