import React, { useEffect, useRef } from 'react'
import {
    PopoverHeader,
    HStack,
    Text,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { IoCloudDoneOutline, IoCloudOfflineOutline } from 'react-icons/io5'
import { useCanEditFile } from '../../../../../../hooks'

export const Header = ({ canEdit, online, sitemap, stopped }) => {
    return (
        <PopoverHeader p={0} borderBottom="none">
            <HStack
                w="full"
                ml="-1px"
                mt="-1px"
                bgColor={mode(renderBarColor({ canEdit, online, stopped }), "whiteAlpha.50")}
                borderTopLeftRadius="md"
                borderTopRightRadius="md"
                px={3.5}
                py={2}
                spacing={3}
                color={renderTitleColor({ canEdit, online, stopped })}
            >
                {renderIcon({ canEdit, online, stopped })}
                <Text fontSize="sm" fontWeight="semibold">{GetTitle({ stopped, sitemap, online })}</Text>
            </HStack>
        </PopoverHeader>
    )
}

const GetTitle = ({ stopped, sitemap, online }) => {
    const canEdit = useCanEditFile()
    if (stopped) return "Autosaving has been disabled";
    if (!stopped && !canEdit) return "Editing is not allowed";
    if (!stopped && canEdit && online) return "All changes saved";
    if (!stopped && canEdit && !online) return "You are offline";
}

const renderIcon = ({ canEdit, online, stopped }) => {
    const fontSize = "17px";
    if (!canEdit || !online || stopped) return <IoCloudOfflineOutline color="rarchy-red" fontSize={fontSize}/>
    return <IoCloudDoneOutline fontSize={fontSize} />
}

const renderTitleColor = ({ canEdit, online, stopped }) => {
    const ok = mode("green.600", "green.300")
    if (!canEdit || !online || stopped) return 'rarchy-red'
    return ok;
}

const renderBarColor = ({ canEdit, online, stopped }) => {
    if (!canEdit || !online || stopped) return '#FED7D7'
    return '#E6f4EA'
}