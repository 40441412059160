import { Button, Center, Image } from "@chakra-ui/react"
import Confirmed from '../../Images/Confirmed.svg'
import { useDispatch } from "react-redux"
import { setUpgradeModalScreen } from "../../../../store/actions/ui-actions"

const showButton = false

export const RightConfirmed = () => {
    const dispatch = useDispatch()
    return (
        <Center key="confirmed-bg" bgColor="rarchy-bg-upgrade-modal" w="full">
            {showButton && (
                <Button onClick={() => dispatch(setUpgradeModalScreen({ screen: 'confirming' }))}>
                    Set confirming
                </Button>
            )}
            <Image w="50%" src={Confirmed} />
        </Center>
    )
}