import { ArrowForwardIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Code, Collapse, Icon, MenuItem, Stack, StackDivider, Tooltip, useBoolean } from "@chakra-ui/react"
import { useState } from "react"
import { isCurrentImportLimitGreaterThanImported, useDidSitemapImportError, useHasSitemapImportFinishedSuccessfully, useIsSitemapImportCompleted, useIsSitemapImportCrawl, useIsSitemapImportPausedByUser } from "../../helpers"
import { useFirebase } from "react-redux-firebase"
import { GrRedo } from "react-icons/gr"
import { MaxDepth } from "../../../../Import/Shared/MaxDepth"
import { Exclude } from "../../../../Import/Shared/Exclude"
import { Include } from "../../../../Import/Shared/Include"
import { SEO } from "../../../../Import/Crawl/SEO"
import { useFormikContext } from "formik"
import { Cancel } from "./Cancel"
import { Pause } from "./Pause"
import { Resume } from "./Resume"
import { IoMdRefresh } from "react-icons/io"
import { PY } from "../../History/Menu"
import { useDispatch } from "react-redux"
import { toggleNewSitemapModal } from "../../../../../../store/actions/ui-actions"
import { ErrorMessaging } from "./Errors"
import { wait } from "../../../../../../../shared/helpers"

export const Topbar = ({ data, handleSubmit, isCreating }) => {
    const { setFieldValue } = useFormikContext()
    const [toggleOptions, setToggleOptions] = useState(false)
    const [isCanceling, setIsCanceling] = useState(false)
    const [isPausing, setIsPausing] = useState(false)
    const [isResuming, setIsResuming] = useState(false)
    const [isRetrying, setIsRetrying] = useState(false)
    const isCrawl = useIsSitemapImportCrawl(data)
    const hasErrored = useDidSitemapImportError(data)
    const isPausedByUser = useIsSitemapImportPausedByUser(data)
    const isCompleted = useIsSitemapImportCompleted(data)
    const hasFinished = useHasSitemapImportFinishedSuccessfully(data)
    // const isNowUnderImportLimit = isCurrentImportLimitGreaterThanImported(data?.retrieved?.limit)
    const ToggleIcon = !toggleOptions ? ChevronDownIcon : ChevronUpIcon;
    const showCancelPause = !hasFinished && !isPausedByUser && !hasErrored && isCrawl
    return (
        <Box mt={10} w="full" borderBottom="1px solid" borderColor="rarchy-border" pb={12}>
            {hasErrored && (
                <Stack direction="row" w="full" justifyContent="space-between">
                    <Stack maxW="2xl" spacing={5}>
                        <ErrorMessaging data={data} />
                    </Stack>
                    <RetryImportButtonMenuItem data={data} />
                </Stack>
            )}
            {showCancelPause && (
                <Stack direction="row" align="end" justify="end" spacing={6}>
                    <Cancel data={data} isCanceling={isCanceling} setIsCanceling={setIsCanceling} isPausing={isPausing} />
                    <Pause data={data} isPausing={isPausing} setIsPausing={setIsPausing} isCanceling={isCanceling} />
                </Stack>
            )}
            {hasFinished && (
                <Stack direction="row" align="end" justify="end" spacing={6}>
                    {!isPausedByUser && ( // disabled showing this when paused so we can get new Rarchy out
                        <Button
                            size="lg"
                            variant="ghost"
                            rightIcon={<ToggleIcon fontSize="xl" />}
                            fontSize="md"
                            px={4}
                            isDisabled={isResuming}
                            onClick={() => setToggleOptions(!toggleOptions)}
                        >
                            Options
                        </Button>
                    )}
                    {/* !isCompleted && !isPausedByUser && ( // disabled so we can get new Rarchy out
                        <Tooltip maxW="150px" textAlign="center" variant="rounded-sm" label="Hit import limit" shouldWrapChildren isDisabled={isNowUnderImportLimit}>
                            <Button variant="outline" fontSize="md" iconSpacing={3} leftIcon={<Icon as={GrRedo} fontSize="lg" />} size="lg" isDisabled={!isNowUnderImportLimit} onClick={(e) => { setFieldValue("submitVariant", "resume"); handleSubmit(); }}>
                                Recrawl
                            </Button>
                        </Tooltip>
                    )*/}
                    {!isCompleted && isPausedByUser && (
                        <Resume data={data} isResuming={isResuming} setIsResuming={setIsResuming} />
                    )}
                    <Button
                        colorScheme="blue"
                        rightIcon={<ArrowForwardIcon />}
                        size="lg"
                        isDisabled={isResuming}
                        isLoading={isCreating}
                        onClick={(e) => { setFieldValue("submitVariant", "create"); handleSubmit() }
                            // remove arrow forward icon and turn this into a dropdown for create new sitemap / import into sitemap
                        }>
                        Create Sitemap
                    </Button>
                </Stack>
            )
            }
            <Collapse in={toggleOptions} animateOpacity>
                <Box
                    p='40px'
                    borderWidth="1px"
                    mt={6}
                    rounded='md'
                    shadow='lg'
                >
                    {/* wrapped in this but don't need the data */}
                    <Stack divider={<StackDivider />} spacing={7}>
                        <MaxDepth />
                        <Include />
                        <Exclude />
                        {isCrawl && <SEO />}
                    </Stack>
                </Box>
            </Collapse>
        </Box>
    )
}

export const RetryImportButtonMenuItem = ({ data, forMenu }) => {
    const dispatch = useDispatch()
    const [isRetrying, setIsRetrying] = useBoolean()
    const isCrawl = useIsSitemapImportCrawl(data)
    const handleClick = async () => {
        if (!forMenu) {
            setIsRetrying.toggle()
            await wait(500)
            setIsRetrying.toggle()
        }
        dispatch(toggleNewSitemapModal({
            showing: true,
            createType: data?.type,
            prefill: { url: data.url, ...data.options }
        }))
    }
    if (forMenu) return (
        <MenuItem
            py={PY}
            icon={<Icon as={IoMdRefresh} fontSize="xl" />}
            onClick={handleClick}
        >
            {`${data?.error ? 'Retry' : ''} ${isCrawl ? "Crawl" : "Import"} ${!data?.error ? 'again' : ''}`}
        </MenuItem>
    )
    return (
        <Button
            rightIcon={<Icon as={ArrowForwardIcon} fontSize="xl" />}
            size="lg"
            iconSpacing={4}
            isLoading={isRetrying}
            onClick={handleClick}
        >
            Retry
        </Button>
    )
} 