import { Box, Heading, Icon, IconButton, Input, InputGroup, InputLeftElement, Stack, VStack } from "@chakra-ui/react";
import { BsArrowsAngleContract, BsArrowsAngleExpand } from "react-icons/bs";
import { compact, filter, isEmpty, uniq } from 'lodash'
import { shouldIncludeOrExcludeURL, withinMaxDepthBySubfolder } from "../helpers";
import { useEffect, useMemo, useState } from "react"

import { DETAIL_W } from "..";
import { ExportMenu } from "./Export";
import { SearchIcon } from "@chakra-ui/icons";
import { VirtualizedTable } from "./Virtualized";
import { toTitleCase } from "../../../../../../helpers"

export const Pages = ({ data = {}, options }) => {

    const { pages, options: importedOptions, retrieved, error } = data;

    const [filteredSearchData, setFilteredSearchData] = useState(null)

    const [width, setWidth] = useState(DETAIL_W)

    // reset once closed
    useEffect(() => {
        return () => {
            if (!isEmpty(pages?.data)) setFilteredSearchData(null)
        }
    }, []);

    const ExpandContractIcon = (width === DETAIL_W ? BsArrowsAngleExpand : BsArrowsAngleContract)

    const pagesFilteredForOptions = useMemo(() => getPagesFilteredForOptions({ pages, retrieved, importedOptions, options }), [pages, retrieved, importedOptions, options]);

    return (
        <Box mt={10} w={width} pb={20}>
            {pagesFilteredForOptions && (
                <>
                    <Stack mb={6} w="full" direction="row" justify="end">
                        <VStack w="full" align="start" spacing={5}>
                            <Heading mb={0} fontSize="2xl">Pages</Heading>
                            <InputGroup size="sm" w="2xs">
                                <InputLeftElement w={5} pointerEvents="none" children={<SearchIcon color="gray.400" />} />
                                <Input
                                    type="text"
                                    variant="flushed"
                                    fontSize="md"
                                    placeholder={`Search ${(pagesFilteredForOptions?.length || [])?.toLocaleString() || 0} pages...`}
                                    pl="30px"
                                    onChange={(e) => {
                                        const inputVal = e.target.value.toLowerCase();
                                        setFilteredSearchData(filter(pagesFilteredForOptions, (o) => {
                                            if (o?.name?.toLowerCase()?.includes(inputVal)) return o;
                                            if (o?.url?.toLowerCase()?.includes(inputVal)) return o;
                                            if (o?.title?.toLowerCase()?.includes(inputVal)) return o;
                                            if (o?.description?.toLowerCase()?.includes(inputVal)) return o;
                                            if (o?.h1?.toLowerCase()?.includes(inputVal)) return o;
                                        }));
                                    }}
                                />
                            </InputGroup>
                        </VStack>
                        <Stack direction="row" spacing={3} align="end">
                            <ExportMenu data={data} />
                            <IconButton variant="ghost" px={3.5} icon={<Icon as={ExpandContractIcon} fontSize="xl" transform="rotate(45deg)" />} onClick={() => {
                                setWidth(width === DETAIL_W ? "full" : DETAIL_W)
                            }} />
                        </Stack>
                    </Stack>
                    <VirtualizedTable width={width} data={data} pages={filteredSearchData || pagesFilteredForOptions} />
                    {error && pages?.loaded && isEmpty((filteredSearchData || pagesFilteredForOptions)) && (
                        <Box py={7} pl={5} color="fg.subtle" key="empty">
                            We couldn't find any pages to import
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

const getPagesFilteredForOptions = ({ pages, retrieved, importedOptions, options }) => {
    return uniq(compact(!pages?.loaded ? [] /* Array.from(Array(retrieved?.count), (_, i) => { return { name: 'test', url: 'test' } }) */
                : (pages?.data || [])
                    ?.map(page => {
                        if (!page) return;
                        if (!withinMaxDepthBySubfolder(page?.url, options)) return;
                        // page name
                        page.name = getPageName({ page, options: { ...importedOptions, ...options } });
                        // include/exclude
                        if (shouldIncludeOrExcludeURL(page.url, options)) return page;
                    })))
}

const getPageName = ({ page, options }) => {

    const pageNameSource = options?.pageNameSource || 'directory';

    const excludePageNameSourceText = (options?.excludePageNameSourceText || "").replace(/[^a-zA-Z0-9 ]/g, "\\W").replace(/\n/g, ",").split(","); // replace(/[^a-zA-Z0-9 ]/g, "\\W") replaces special characters, so things like excluding " | " works

    let name;

    // page name source from SEO
    if (page?.[pageNameSource]) {
        name = page?.[pageNameSource]
    }

    // from directory
    if (!page?.[pageNameSource]) {
        const split = compact(page?.url?.split("/"));
        if (split.length === 2) return 'Home';
        name = toTitleCase(split?.pop().replace(/-/g, " ") || "");
    }

    // exclude text from page name
    let nameWithExcludedText;
    if (Array.isArray(excludePageNameSourceText)) {
        excludePageNameSourceText.forEach((textToReplace) => {
            nameWithExcludedText = name.replace(new RegExp(textToReplace, "g"), "");
        });
    }

    return nameWithExcludedText || name; // ensures we don't save pages with no name (defaults back to the name before excluding text, if nameWithExcludedText is empty)
}