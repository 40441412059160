import { Accordion, AccordionButton, AccordionIcon, AccordionItem, Badge, Box, Button, Center, Circle, Divider, HStack, Input, Text, Tooltip, VStack } from "@chakra-ui/react"
import { Panel, getNumberOfPagesByColor } from "./Panel"
import { isEmpty, sortBy } from "lodash"
import { useEffect, useMemo, useState } from "react"

import { CheckIcon } from "@chakra-ui/icons"
import { ColorLabel } from "./class"
import { useCanEditFile } from "../../../../../../hooks"

export const Labels = ({ colors, selectedColor, handlePalletteChange }) => {
    const [index, setIndex] = useState(null)
    const [isMouseoverColor, setIsMouseoverColor] = useState()
    const [labelsCountMap, setLabelsCountMap] = useState({})
    const canEdit = useCanEditFile()
    const isDisabled = !canEdit
    useEffect(() => {
        const map = {}
        colors?.forEach(c => { map[c?.color] = getNumberOfPagesByColor(c?.color); })
        setLabelsCountMap(map)
    }, [])
    const sortedColors = useMemo(() => {
        const colorsWithCount = colors?.map(c => { c.count = labelsCountMap[c?.color]; return c; })
        return sortBy(colorsWithCount, 'count').reverse()
    }, [colors, labelsCountMap])
    return (
        <VStack spacing={1.5} alignItems="start" pb={1.5}>
            <VStack w="full" spacing={0}>
                {isEmpty(colors) && (
                    <Text py={2} pt={3} fontSize="xs" color="fg.muted">No labels have been added yet</Text>
                )}
                <Accordion size="sm" allowToggle w="full" index={index} onChange={setIndex}>
                    {sortedColors?.map((color, i) => {
                        const isSelected = selectedColor === color?.color
                        const isMouseover = !isDisabled && (isMouseoverColor === color?.color)
                        return (
                            <AccordionItem key={i} border="none">
                                {({ isExpanded }) => {
                                    return (
                                        <>
                                            <AccordionButton
                                                as="div"
                                                h="28px"
                                                _hover={{ cursor: 'pointer', bgColor: isDisabled ? "unset" : "rarchy-bg-subtle-hover" }}
                                                paddingInlineStart={2.5}
                                                paddingInlineEnd={3}
                                                py={1}
                                                onClick={(e) => {
                                                    if (isDisabled) return e.preventDefault()
                                                }}
                                                onMouseEnter={() => {
                                                    setIsMouseoverColor(color?.color)
                                                }}
                                                onMouseLeave={() => {
                                                    setIsMouseoverColor(null)
                                                }}
                                            >
                                                <HStack
                                                    w="full"
                                                    justify="space-between"
                                                    paddingInline={0}
                                                    pr={1.5}
                                                    onClick={(e) => {
                                                        if (isDisabled) return;
                                                        e.preventDefault()
                                                        handlePalletteChange({ header: color?.color?.toUpperCase() })
                                                    }}
                                                >
                                                    <HStack w="full">
                                                        <Center
                                                            minW={3}
                                                            minH={3}
                                                            bgColor={isSelected ? "transparent" : color?.color}
                                                            rounded="full"
                                                            _hover={{ boxShadow: "md" }}
                                                        >
                                                            {isSelected && (
                                                                <CheckIcon fontSize="xs" color={color?.color} />
                                                            )}
                                                            {isMouseover && !isSelected && (
                                                                <Circle w="1" h="1" bgColor="white" />
                                                            )}
                                                        </Center>
                                                        <LabelName color={color} isDisabled={isDisabled} />
                                                    </HStack>
                                                </HStack>
                                                {(isExpanded || (!isDisabled && isMouseover)) && (
                                                    <AccordionIcon _hover={{ bgColor: "rarchy-bg-subtle-hover-200" }} rounded="md" />
                                                )}
                                                {!isMouseover && !isExpanded && (
                                                    labelsCountMap?.[color?.color] > 0
                                                        ?
                                                        <Badge
                                                            mr={.5}
                                                            fontSize="xs"
                                                            color="rarchy-color-sitemap-editor">
                                                            {labelsCountMap?.[color?.color]?.toLocaleString()}
                                                        </Badge>
                                                        : null
                                                )}
                                            </AccordionButton>
                                            {isExpanded && (
                                                <Panel
                                                    color={color}
                                                    colors={colors}
                                                    handlePalletteChange={(c, opts) => {
                                                        color?.changeLabelColor(c, opts)
                                                    }}
                                                    handleDelete={() => {
                                                        color.deleteLabel()
                                                        setIndex(null)
                                                    }}
                                                />
                                            )}
                                        </>
                                    )
                                }}
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </VStack>
        </VStack>
    )
}

export const LabelName = ({ color }) => {
    const label = color?.label || color?.color
    return (
        <Tooltip label={color?.label} variant="rounded-sm" hasArrow openDelay={500}>
            <Box
                fontSize="13px"
                color={color?.label ? "rarchy-color-sitemap-editor" : "fg.muted"}
                noOfLines={1}
            >
                {label}
            </Box>
        </Tooltip>
    )
}

export const NewLabelButton = ({ showNewLabelPanel, onClick }) => {
    const canEdit = useCanEditFile()
    const isDisabled = !canEdit
    if (isDisabled) return null
    return (
        <Button
            as="div"
            size="sm"
            fontSize="13px"
            variant="link"
            fontWeight="medium"
            color={showNewLabelPanel ? "fg.muted" : "rarchy-link"}
            iconSpacing={2}
            ml={2.5}
            onClick={onClick}
        >
            {showNewLabelPanel ? 'Close' : 'Add new'}
        </Button>
    )
}

export const NewLabelPanel = ({ colors }) => {
    const [inputVal, setInputVal] = useState()
    const [selectedColor, setSelectedColor] = useState()
    useEffect(() => {
        return () => {
            setInputVal(null)
            setSelectedColor(null)
        }
    }, [])
    return (
        <VStack spacing={1.5} mx={3}>
            <Panel
                color={selectedColor}
                colors={colors}
                onEditableChange={(val) => setInputVal(val)}
                handlePalletteChange={(color) => setSelectedColor(new ColorLabel({ color }))}
                forNew
            />
            <Button
                isDisabled={!inputVal || !selectedColor}
                w="full"
                colorScheme="blue"
                size="sm"
                fontWeight="semibold"
                fontSize="xs"
                h={7}
                mb={1}
                onClick={() => {
                    selectedColor.saveNewLabel()
                }}
            >
                Save
            </Button>
            <Divider w="full" mb={1.5} />
        </VStack>
    )
}