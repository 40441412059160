import { Button, Stack, Text, createStandaloneToast } from "@chakra-ui/react"
import { mergeFileChange } from "../../../../../../store/actions/files-actions"
import { mergeFolderChange } from "../../../../../../store/actions/folders-actions"
import { getAccountId } from "../../../../../../helpers"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { wait } from "../../../../../../../shared/helpers"

// SAVE
export const moveToFolder = async ({ file, folder, folderId, dispatch }) => {
    const accountId = getAccountId()
    const item = file || folder
    const folderIdToSet = !folderId ? (file ? accountId : null) : folderId // set accountId for team for file, and null for folder if empty
    // save
    await setDoc(doc(getFirestore(), item?.collection || "teams", item?.id), { team: folderIdToSet }, { merge: true })
    // update in redux
    if (file) dispatch(mergeFileChange(file?.id, file?.collection, { team: folderId }))
    if (folder) dispatch(mergeFolderChange({ [folder?.id]: { team: folderId } }))
}

// show confirmation/undo toast
const { toast } = createStandaloneToast()
const id = 'moved-to-folder-toast'
export const showMoveToFolderToast = ({ file, folder, oldFolderId, dispatch }) => {
    if (!toast.isActive(id)) {
        toast({
            position: 'top',
            duration: 2900,
            isClosable: true,
            render: () => (
                <Stack mt={-0.5} align="center" w="fit-content" justify="space-between" direction="row" rounded="2xl" color="rarchy-color-toast" px={5} py={3} spacing={4} bgColor="rarchy-bg-toast">
                    <Text fontSize="sm" fontWeight="semibold">Moved to folder</Text>
                    <Button
                        size="sm"
                        variant="ghost"
                        color="rarchy-color-toast"
                        bgColor="rarchy-bg-toast-button"
                        _hover={{ bgColor: "rarchy-bg-toast-button" }}
                        onClick={async () => {
                            await wait(500)
                            toast.closeAll()
                            moveToFolder({ file, folder, folderId: oldFolderId, dispatch })
                        }}
                    >
                        Undo
                    </Button>
                </Stack>
            ),
        })
    }

}