import { getDefaultPalletteHeader, getEditor, getInUserFlow, getIsDefaultPalletteHeader, getIsLinkedUserFlowPage, getIsNavbarHidden, getSitemap } from '../../../../../helpers';
import { isEmpty, isUndefined, omitBy } from 'lodash'

import { checkIfAutocapturing } from '../../../../Editor/Toolbar/Capture/helpers';
import { dragging } from './drag';
import { rgb } from 'd3';
import { transform } from './zoom';

export const getRandomColor = () => {
    function c() {
        var hex = Math.floor(Math.random() * 256).toString(16);
        return ("0" + String(hex)).substr(-2); // pad with zero
    }
    return hexToRgb(c() + c() + c());
}

function hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    return rgb(r, g, b).toString();
}

export const getX = (d) => {
    const sitemap = getSitemap();
    const inUserFlow = getInUserFlow();
    if (!inUserFlow && (sitemap?.format === 'tree' || sitemap?.format === 'indent')) return d?.y;
    if (inUserFlow) {
        /*** multi-select dragging ***/
        if (dragging && (d?.id !== dragging?.id && d?._selected)) {
            return d?.x + (dragging?.x - dragging?.x0);
        }
        /*** multi-select dragging ***/
    }
    return d?.x;
}

export const getY = (d) => {
    const sitemap = getSitemap();
    const inUserFlow = getInUserFlow();
    if (!inUserFlow && (sitemap?.format === 'tree' || sitemap?.format === 'indent')) return d?.x;
    if (inUserFlow) {
        /*** multi-select dragging ***/
        if (dragging && (d?.id !== dragging?.id && d?._selected)) {
            return d?.y + (dragging?.y - dragging?.y0);
        }
        /*** multi-select dragging ***/
    }
    return d?.y;
}

export const getCanvasContainerOffsetWidth = () => {
    var parent = document.getElementById("canvases-container")
    return parent?.offsetWidth
}

export const getCanvasContainerOffsetTop = () => {
    var parent = document.getElementById("canvases-container")
    return parent?.offsetTop
}

export const applyYWithCavasOffset = (y) => {
    return transform.applyY(y) + getCanvasContainerOffsetTop()
}

export const getCustomCover = (node) => {
    const sitemap = getSitemap();
    const { covers } = sitemap;
    const pageId = getPageId(node);
    const device = getDeviceByPage(node);
    if (covers?.pages?.[pageId]?.devices?.[device]) {
        const { thumbDownloadURL, downloadURL, generation, preview } = covers.pages[pageId].devices[device];
        if (covers?.pages?.[pageId]?.cover === 'wireframe') return;
        if (preview) return { thumbDownloadURL: preview, generation: 'preview' };
        const data = omitBy({ thumbDownloadURL, downloadURL, generation }, isUndefined);
        if (!isEmpty(data)) return data;
    }
}

export const getCoversDeviceFromStorage = (sitemapId) => {
    var device = localStorage.getItem(`${sitemapId}#device`);
    if (!device) {
        localStorage.setItem(`${sitemapId}#device`, 'desktop');
        return 'desktop';
    }
    return device !== '[object Object]' ? device : 'desktop';
}

export const getDeviceByPage = (d) => {
    const sitemap = getSitemap();
    const inUserFlow = getInUserFlow();
    if (inUserFlow) {
        if (d?.type === 'page-sections') return 'desktop';
        return d?.device || sitemap?.covers?.device;
    }
    return sitemap?.showCovers ? sitemap?.covers?.device : 'desktop';
}

export const shouldShowCovers = (d) => {
    const sitemap = getSitemap()
    return getInUserFlow() ? (d?.type === 'page' ? true : false) : sitemap?.showCovers;
}

export const getPageId = (d) => {
    const linkedPageInUserFlow = getIsLinkedUserFlowPage(d);
    return linkedPageInUserFlow ? d?.page : d?.id;
}

export const isCustomCoverPending = (state, node) => {

    const { sitemap } = state
    const { covers } = sitemap
    const { device } = covers;

    /*** single capture ***/
    if (!isEmpty(covers.pages[node.id])) {
        if (covers.pages[node.id].devices) {
            const cover = covers.pages[node.id].devices[device];
            if (cover && cover.token) {
                if (cover.token === 'capturing') return true // cover.token;
            }
        }
    }
    /*** single capture ***/
    /*** autocapture ***/
    const autocapturing = checkIfAutocapturing(state)
    const isAutocapturing = !isEmpty(autocapturing)
    if (isAutocapturing) {
        const { device: autocapturingDevice } = autocapturing
        if (device === autocapturingDevice) {
            if (autocapturing?.pages[node.id]) return true
        }
    }
    /*** autocapture ***/
}

export const getPageColor = (d, opts) => {

    const { forWireframe, usePageFromDocs } = opts || {};

    const editor = getEditor();
    const sitemap = getSitemap();
    const inUserFlow = getInUserFlow();

    if (d) {

        /*** user flows ***/
        if (inUserFlow) {
            if (!d.page) {
                if (d.color) return d.color;
            } else {
                const page = sitemap?.docs?.pages?.[d.page];
                if (page) {
                    const colorSetByParent = parentPageHasChildrenColorSet(d.page);
                    d = !colorSetByParent ? page : colorSetByParent; // replace data with linked page data for color (should return d.pallette.header below if linked page has pallette)  
                }
            }
        };
        /*** user flows ***/


        if (usePageFromDocs) {
            const colorSetByParent = parentPageHasChildrenColorSet(d.id);
            d = !colorSetByParent ? sitemap.docs.pages[d.id] : colorSetByParent;
        }

        if (d?.pallette?.header) return d.pallette.header;

        var color;

        if (d.ancestors) {
            d.ancestors().forEach(o => {
                if (o?.pallette?.header && o?.pallette?.children === true) {
                    color = o.pallette.header;
                    return color;
                }
            });
        }

        if (color) return color;
    }

    function parentPageHasChildrenColorSet(pageId) {
        const pageFromDocs = sitemap?.docs?.pages?.[pageId]
        if (pageFromDocs) {
            const parent = sitemap?.docs.pages?.[pageFromDocs?.parent];
            if (parent) {
                if (parent?.pallette?.header && parent?.pallette?.children === true) {
                    return parent;
                } else {
                    return parentPageHasChildrenColorSet(pageFromDocs.parent)
                }
            }
        }
        return null;
    }

    // anything below here should be default pallette

    if (forWireframe) {
        // this means that wireframes aren't as bright white 
        // (uses same header as "light" colorMode, and doesn't seem to have broken anything...) 
        if (getIsDefaultPalletteHeader(editor?.pallette?.header)) return getDefaultPalletteHeader("light")
    }

    return editor?.pallette?.header;
}

export const getCanvasHeight = () => `calc(100vh - ${!getIsNavbarHidden() ? 101 : 48}px)`