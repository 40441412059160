import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ToggleButtonGroup } from '../Components/ToggleButton/ButtonGroup';
import { ToggleButton } from '../Components/ToggleButton/Button';
import { Box, IconButton, useColorModeValue as mode, useRadio } from "@chakra-ui/react"
import { MdGridOff, MdGridOn } from 'react-icons/md';
import { AiOutlineDash, AiOutlineLine } from 'react-icons/ai';
import { BsFileRichtext } from 'react-icons/bs'
import { } from 'react';
import { EditorSkeletonWrapper } from '..';

export const Connector = () => {
    const type = localStorage.getItem(`userFlowConnectorType`);
    const [connectorType, setConnectorType] = useState(type ? type : 'solid')

    const changeConnectorType = (type) => {
        localStorage.setItem(`userFlowConnectorType`, type)
        setConnectorType(type)
    }

    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                defaultValue={connectorType}
                isAttached
                variant="outline"
                isRadio
            >
                <ToggleButton value="solid" icon={<AiOutlineLine fontSize="21px" />} onClick={() => changeConnectorType("solid")} />
                <ToggleButton value="dash" icon={<AiOutlineDash fontSize="21px" />} onClick={() => changeConnectorType("dash")} />
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}