import React, { useState, useRef, useLayoutEffect } from 'react'
import { HStack } from "@chakra-ui/react"
import { Menu } from './buttons/menu'
import { Files } from './buttons/files'
import { transform } from '../../canvas/utils/zoom'
import { useSelector } from 'react-redux'
import { GoToUrl } from './buttons/goToUrl'
import { LinkPage } from './buttons/link-page'
import { getDeviceByPage, shouldShowCovers } from '../../canvas/utils/helpers'
import { Status } from './buttons/status'
import { SHOW_STATUS } from '../../canvas/components/header'
import { useInUserFlow } from '../../../../../hooks'
import { PageColor } from './buttons/color'

export const TopRow = ({ d, x, y }) => {

    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const inUserFlow = useInUserFlow()
    const showCovers = shouldShowCovers(d);
    let left = (x + ((225 - 20) * transform.k)) - width, top = (y + ((showCovers ? (!inUserFlow ? 20 : 12.5) : (!inUserFlow ? 7.5 : 13)) * transform.k))

    useLayoutEffect(() => setWidth(ref.current.offsetWidth), []);

    const linkedUserFlowPage = inUserFlow && d.page;

    const device = getDeviceByPage(d);
    if (device === 'mobile') top += (2.5 * transform.k)
    
    return (
        <>
            {SHOW_STATUS && <Status d={d} x={left} y={top} />}
            <HStack ref={ref} pos="absolute" left={left} top={top} spacing={`${(showCovers ? 4 /*4*/ : 2) * transform.k}px`}>
                {(!inUserFlow || (inUserFlow && linkedUserFlowPage)) && (
                    <>
                        <Menu d={d} showCovers={showCovers} />
                        <PageColor d={d} showCovers={showCovers} />
                        <GoToUrl d={d} showCovers={showCovers} />
                        <Files d={d} showCovers={showCovers} />
                    </>
                )}
                {inUserFlow && !linkedUserFlowPage && (
                    <LinkPage d={d} showCovers={showCovers} />
                )}
            </HStack>
        </>
    )
}