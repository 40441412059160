import { Alert, AlertIcon, Text } from "@chakra-ui/react";
import { UnderlineLink } from "./UnderlineLink";
import { Link } from "react-router-dom";

export const Error = ({ auth }) => {
    return (
        <Alert status='error' rounded="sm" fontSize="sm" fontWeight="medium">
            <AlertIcon />
            {returnError(auth.isError)}
        </Alert>
    )
}

const returnError = error => {
    switch (error.code) {
        case 'auth/email-already-in-use':
            // Thrown if the user corresponding to the given email already has an account.
            return <><Text>You already have an account with us. </Text><Link to="/login"><UnderlineLink ml={3}>Log in to your account</UnderlineLink></Link></>;
        case 'auth/wrong-password':
            // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
            return "The password you've entered is incorrect";
        case 'auth/user-not-found':
            // Thrown if there is no user corresponding to the given email.
            return <><Text>We couldn't find your account. </Text><Link to="/signup"><UnderlineLink ml={3}>Sign up for free</UnderlineLink></Link></>;
        case 'auth/invalid-email':
            // Thrown if the email address is not valid.
            return "Please enter a valid email address";
        case 'auth/user-disabled':
            // Thrown if the user corresponding to the given email has been disabled.
            return "Your account has been disabled. Please contact us for assistance"
        case 'auth/weak-password':
            // Thrown if the password given is too weak.
            return "Your password should be at least 8 characters"
        case 'auth/expired-action-code':
            // Thrown if the password reset code has expired.
            return <><Text>Your reset code has expired. </Text><Link to="/login/reset"><UnderlineLink ml={3}>Try reset again.</UnderlineLink></Link></>;
        case 'auth/invalid-action-code':
            // Thrown if the password reset code is invalid. This can happen if the code is malformed or has already been used.
            return <><Text>Your reset code is invalid. </Text><Link to="/login/reset"><UnderlineLink ml={3}>Try reset again.</UnderlineLink></Link></>;
        default:
            return "We couldn't log you in. Please check your details & try again."
    }
};