import { addCommentsChange, hidePageCommentsPopover, showPageCommentsPopover } from '../../../store/actions/sitemap-actions';
import { applyYWithCavasOffset, getPageId, getX, getY } from '../app/canvas/utils/helpers';
import { cloneDeep, compact, find, isEmpty, keys, last, sortBy } from 'lodash'
import { getPeopleData, getSitemap, getUser, sendHubspotCustomEvent } from '../../../helpers'; // useGetUser won't work as in map

import $ from 'jquery';
import { Link } from "@chakra-ui/react"
import React from 'react';
import calendar from 'dayjs/plugin/calendar';
import { chain } from '../../../helpers/chain';
import dayjs from 'dayjs';
import { getCanCommentInEditor } from '../../Editor/Navbar/helpers';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import reactStringReplace from 'react-string-replace';
import { showEditorPermissionsToast } from '../../Editor/App/Components/PermissionsToast';
import { store } from '../../../store';
import { transform } from '../app/canvas/utils/zoom';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useGetSitemap } from '../../../hooks';

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(calendar);
dayjs.updateLocale('en', {
    calendar: {
        sameDay: '[Today at] h:mm A',
        lastDay: '[YD at] h:mm A',
        lastWeek: 'll h:mm A',
        sameElse: 'll h:mm A',
    }
});

export const renderComments = (page) => {

    const sitemap = getSitemap()
    const people = getPeopleData()
    //
    const { comments } = sitemap
    //
    if (!comments?.[page]) return;
    //
    const parentId = comments[page] && Object.keys(comments[page]).sort()[0];
    //
    const commentsArray = compact(chain(comments[page])
        .map((comment, key) => {
            if (!comment || !key) return undefined;
            var data = cloneDeep(comment);
            data.id = key;
            data.time = commentTime(key);
            const user = commentUser(people, comment.user);
            if (user) data.user = user;
            if (key !== parentId) data.parent = parentId;
            data.comment = commentMentions(people, data.comment);
            return data;
        })
        .value().sort());
    //
    var sortedCommentsData = sortBy(commentsArray, 'id');
    if (commentsArray.length > 0) return sortedCommentsData;
    //
    return undefined;
};

/* mentions*/
export const autoExpandMentions = (e) => $(e).css({ 'height': '32' }).height(e.scrollHeight);
export const resetAutoExpandMentions = (e) => $('.textarea-with-mentions textarea').css({ 'height': '32' }).height(32);
export const hasMentions = (comment) => {
    return comment && comment.includes('@') ? true : false;
}

export const commentMentions = (people, comment) => {
    const { ui } = store.getState()
    const obj = { string: '', rendered: '' };
    /*** rendered mentions ***/
    obj.rendered = reactStringReplace(comment, /@(\w+)/g, (match, i) => {
        const member = find(people, ['id', match]);
        if (!member) return match.match(/\b[a-zA-Z0-9]{28}\b/ig) ? "" : `@${match}`; // return nothing if @id, return text if @text
        return <Link key={`${match}-${i}`} color={ui.colorMode === "light" ? "blue.600" : "blue.300"} /* href={`mailto:${member.email}`}*/>@{`${member.firstName} ${member.lastName}`}</Link>
    });
    /*** rendered mentions ***/
    /*** string of comment ***/
    var mentionsRegex = /(?:^|\W)@(\w+)(?!\w)/g, match;
    /* eslint-disable-next-line */
    while (match = mentionsRegex.exec(comment)) {
        var regex = new RegExp(match[1]);
        const member = find(people, ['id', match[1]]);
        if (member) comment = comment.replace(regex, `${member.firstName} ${member.lastName}`);
    }
    obj.string = comment;
    /*** string of comment ***/
    return obj;
};
/* mentions*/

/* populate data */
export const commentUser = (people, userId) => {
    const user = getUser()
    // current user - set as safeguard when creating comment
    if (userId === user?.id) return { id: user.id, firstName: user.firstName, lastName: user.lastName, photoURL: user.photoURL };
    const person = find(people, p => p.id === userId);
    if (person) return person;
    if (!person) return { id: "removed", firstName: 'Unknown', lastName: 'User' };
};

export const commentTime = timestamp => `${dayjs(parseInt(timestamp)).calendar()}`;
/* populate data */

/* show/hide popovers */
export const showCommentsPopover = (node) => {
    const pageId = getPageId(node);
    const sitemap = getSitemap();
    const { comments } = sitemap
    // can comment 
    const hasComments = !isEmpty(comments[pageId]);
    const userCanComment = getCanCommentInEditor()
    if (!hasComments && !userCanComment) return !showEditorPermissionsToast({ canComment: true });
    // continue
    /*** show page comments popover ***/
    const { x, y } = getCanvasBoundingClientRect(node);
    const right = (x + (225 * transform.k) + 15), top = y -1;
    store.dispatch(showPageCommentsPopover({ page: pageId, node, offset: [right, top] }));
    /*** show page comments popover ***/
}
export const hideCommentsPopover = () => {
    const sitemap = getSitemap();
    const { PageCommentsPopover } = sitemap?.ui || {};
    if (PageCommentsPopover.showing) store.dispatch(hidePageCommentsPopover());
}
// clear any comment popovers that are showing when switching between formats/covers/devices etc
export const hideAllCommentPopovers = () => {
    const sitemap = getSitemap();
    /*** close comment popover if open ***/
    const { PageCommentsPopover } = sitemap?.ui || {};
    if (PageCommentsPopover.showing) hideCommentsPopover()
    /*** close comment popover if open ***/
}
/* show/hide popovers */

/*** replying, editing and deleting ***/

export const resolveComments = (pageId) => {
    const { user, sitemap } = store.getState();
    var newPageComment = { [new Date().getTime()]: { user: user.id, status: "resolved" } };
    const change = { id: new Date().getTime(), data: [{ [pageId]: newPageComment }] };
    const mergedComments = { [pageId]: { ...sitemap?.comments[pageId], ...newPageComment } };
    store.dispatch(addCommentsChange({ change, comment: mergedComments }));
}
export const reOpenComments = (pageId) => {
    const { user, sitemap } = store.getState();
    var newPageComment = { [new Date().getTime()]: { user: user.id, status: "re-opened" } };
    const change = { id: new Date().getTime(), data: [{ [pageId]: newPageComment }] };
    const mergedComments = { [pageId]: { ...sitemap?.comments[pageId], ...newPageComment } };
    store.dispatch(addCommentsChange({ change, comment: mergedComments }));
}

// handle add
export const handleAddSubmit = (comment) => {
    comment = parseMentions(comment)
    const { sitemap, user } = store.getState()
    const { page } = sitemap?.ui.PageCommentsPopover;
    var newPageComment = { [page]: { [new Date().getTime()]: { user: user.id, comment } } };
    const change = { id: new Date().getTime(), data: [newPageComment] };
    store.dispatch(addCommentsChange({ change, comment: newPageComment }));
    // send to GA
    sendDataLayerEventForNewComment(comment)
}

// handle reply to comment in redux
export const handleReplySubmit = (comment, page) => {

    comment = parseMentions(comment)
    const { sitemap, user } = store.getState()

    const comments = sitemap?.comments?.pages?.[page] || {};
    const markedAsResolved = areCommentsMarkedAsResolved(comments);

    const obj = { user: user.id, comment };
    if (markedAsResolved) obj.status = "re-opened" // comment was marked as resolved, new comment re-opens

    var newPageComment = { [new Date().getTime()]: obj };
    const change = { id: new Date().getTime(), data: [{ [page]: newPageComment }] };
    const mergedComments = { [page]: { ...sitemap?.comments[page], ...newPageComment } };
    // add comments change
    store.dispatch(addCommentsChange({ change, comment: mergedComments }));
    // send to GA
    sendDataLayerEventForNewComment(comment, sitemap?.comments[page])
};

// handle edit comment in redux
export const handleEditSubmit = (editing, comment, page) => {
    comment = parseMentions(comment)
    const { sitemap, user } = store.getState()
    var newPageComment = { [editing]: { user: user.id, comment }, };
    const change = { id: new Date().getTime(), data: [{ [page]: newPageComment }] };
    const mergedComments = { [page]: { ...sitemap?.comments[page], ...newPageComment } };
    store.dispatch(addCommentsChange({ change, comment: mergedComments }));
};

export const parseMentions = comment => comment.replace(/{{2,}|}{2,}/g, '')

// delete page comment in redux
export const handleDelete = (comment, page) => {
    const { sitemap } = store.getState()
    // delete comment reply
    if (comment.parent) {
        const change = { id: new Date().getTime(), data: [{ [page]: { [comment.id]: store.firestore.FieldValue.delete() } }] };
        const comments = cloneDeep(sitemap?.comments);
        delete comments[page][comment.id];
        store.dispatch(addCommentsChange({ change, comment: comments }))
    } else {
        // delete comment thread
        const change = { id: new Date().getTime(), data: [{ [page]: store.firestore.FieldValue.delete() }] };
        store.dispatch(addCommentsChange({ change, comment: { [page]: undefined } }));
        hideCommentsPopover()
    }
};

/*** replying, editing and deleting ***/

export const getDefaultMentionsStyle = (ui, opts = {}) => {
    return {
        input: {
            padding: "6px 12px"
        },
        highlighter: {
            minHeight: opts.minHeight ? opts.minHeight : 60, maxHeight: 150, paddingBottom: 6
        },
        suggestions: {
            backgroundColor: ui.colorMode === "light" ? "white" : "#2d3748",
            border: '1px solid',
            borderColor: ui.colorMode === "light" ? "#E2E8F0" : "rgba(255, 255, 255, 0.16)",
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
            borderRadius: "5px",
            list: {
                fontSize: 14,
            },
            item: {
                padding: '9px 15px',
                borderRadius: "5px",
                '&focused': {
                    backgroundColor: ui.colorMode === "light" ? "#EDF2F7" : "rgba(255, 255, 255, 0.06)",
                },
            },
        },
    }
}
/*** replying, editing and deleting ***/

const sendDataLayerEventForNewComment = (newComment, pageComments) => {
    const commentsCount = Object.keys(pageComments || {})?.length || 0
    var mentionsCount = (newComment.match(/@/g) || []).length;
    const obj = { [`ga_event`]: { category: "Sitemap Interactions", action: `New Comment`, label: `${commentsCount} + 1` } };
    window.dataLayer.push({
        event: 'generic_ga_event',
        ...obj,
        mentions_count: mentionsCount
    });
    sendHubspotCustomEvent('added_comment', { number_of_comments: commentsCount + 1 })
}

export const useCommentIsResolved = (pageId) => {
    const sitemap = useGetSitemap()
    const comments = sitemap?.comments[pageId]
    return areCommentsMarkedAsResolved(comments)
}

export const areCommentsMarkedAsResolved = (comments) => {
    if (isEmpty(comments)) comments = {};
    return comments[last(keys(comments).sort())]?.status === 'resolved';
}

export const getCommentsCount = (comments) => {
    const markedAsResolved = areCommentsMarkedAsResolved(comments);
    return markedAsResolved ? 0 : keys(comments)?.map(id => ({ id, ...comments[id] }))?.filter(c => c.status !== 'resolved' && (c.comment && (c.status === 're-opened' || c.status !== 're-opened')))?.length;

}

const getCanvasBoundingClientRect = (d) => {
    const x = transform.applyX(getX(d));
    const y = applyYWithCavasOffset(getY(d));
    return { x, y };
}