import {
    Button,
    Center,
    Divider,
    HStack,
    Icon,
    Progress,
    Spinner,
    Square,
    Text,
    VStack,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { cloneDeep, isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase, useFirestore } from 'react-redux-firebase'

import { AiOutlineCloudUpload } from 'react-icons/ai'
import { CheckCircleIcon } from '@chakra-ui/icons'
import Dropzone from 'react-dropzone'
import { getMetadata } from 'firebase/storage'
import { mergePagesEdits } from '../../../../../store/actions/sitemap-actions'

export const DropzoneFiles = ({ files, setFiles, onClose }) => {
    const state = useSelector(state => state)
    const firestore = useFirestore()
    const firebase = useFirebase()
    const dispatch = useDispatch()
    const [uploading, setUploading] = useState({})
    const bgColor = mode('white', 'gray.800')
    return (
        <Dropzone
            noDrag={!isEmpty(uploading)}
            noClick={!isEmpty(uploading)}
            maxFiles={1}
            onDrop={(acceptedFiles) => {
                const { sitemap, user } = state
                const { PageDrawer } = sitemap?.ui
                const file = acceptedFiles[0]
                setUploading({ name: file.name, progress: 0 })
                //
                var existingFile = files.find(f => f.name === file.name);
                const id = existingFile ? existingFile.id : Date.now();
                //
                var uploadTask = firebase.storage().ref().child(`sitemaps/${sitemap?.id}/files/${PageDrawer.page.id}/${file.name}`).put(file, { customMetadata: { id, sitemapId: sitemap?.id, userId: user.id } });
                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        var percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                        percent = percent < 1 ? 1 : percent
                        setUploading({ name: file.name, progress: percent < 100 ? percent : 99 })
                    },
                    error => {
                        console.log(error)
                    },
                    async () => {

                        const metadata = await getMetadata(uploadTask.snapshot.ref)

                        var existingFile = files.find(f => f.name === metadata.name);

                        /*** update pages file count in firestore (if not overwritten) ***/
                        if (!existingFile) {

                            var pages = { [PageDrawer.page.id]: { files: firestore.FieldValue.increment(1) } };
                            await firestore.doc(`sitemaps/${sitemap?.id}/data/pages`).set({ pages, lastEdit: user.id, updatedAt: new Date() }, { merge: true }).catch(e => console.error( 'pages', e));

                            /*** update pages data in redux ***/
                            var updatedPageData = cloneDeep(sitemap?.docs.pages[PageDrawer.page.id]);
                            updatedPageData.files ? updatedPageData.files++ : updatedPageData.files = 1;
                            // merge page edits
                            dispatch(mergePagesEdits({ pages: updatedPageData }));
                            /*** update pages data in redux ***/

                        }
                        /*** update pages file count in firestore (if not overwritten) ***/

                        const kind = metadata.contentType.split('/')[0];
                        const { name, size, updated, fullPath } = metadata;
                        const type = name.split(/[.;+_]/).pop();
                        const downloadURL = await firebase.storage().ref().child(fullPath).getDownloadURL();

                        /*** update files doc ***/
                        const filesData = { [metadata.customMetadata.id]: { key: name, name, downloadURL, size, kind, type, uploaded: updated, fullPath } };
                        await firestore.doc(`sitemaps/${sitemap?.id}/data/pages/files/${PageDrawer.page.id}`).set({ files: filesData, lastEdit: user.id, updatedAt: new Date() }, { merge: true }).catch(e => console.error('files', e));
                        /*** update files doc ***/

                        setUploading({ name: file.name, progress: 100 }) // set to success

                        setTimeout(() => {

                            files = [...files];

                            /*** if over-writing ***/
                            const existingIndex = files.findIndex(f => f.fullPath === fullPath);
                            if (existingIndex >= 0) files.splice(existingIndex, 1);
                            /*** if over-writing ***/

                            files.unshift({ id: metadata.customMetadata.id, key: name, name, downloadURL, size, kind, type, uploaded: updated, fullPath, });
                            setFiles(files)

                            setUploading({}) // clear uploading

                            // push file upload event to dataLayer
                            window.dataLayer.push({ event: 'fileUploaded', fileSize: (size / Math.pow(1024, 2)).toFixed(2) });

                            // close popover
                            onClose()

                        }, 1500);

                    }
                );
            }}>
            {({ getRootProps, getInputProps, isDragActive }) => (
                <>
                    <Center
                        _hover={{ cursor: !isEmpty(uploading) ? 'not-allowed' : 'pointer' }}
                        p="6"
                        rounded="md"
                        bg={isDragActive ? "rarchy-bg-subtle" : bgColor}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <VStack spacing="2">
                            <Square size="6" bg="bg-subtle" rounded="lg">
                                <Icon as={AiOutlineCloudUpload} boxSize="5" color="fg.muted" />
                            </Square>
                            <VStack spacing="3">
                                <HStack spacing="1" whiteSpace="nowrap">
                                    {!isDragActive && <Button variant="link" colorScheme="blue" size="sm">
                                        Click to upload
                                    </Button>}
                                    <Text fontSize="sm" color="fg.muted">
                                        {isDragActive ? 'Drop file to upload' : 'or drag and drop file'}
                                    </Text>
                                </HStack>
                                <Text fontSize="xs" color="fg.subtle">
                                    500MB max file size
                                </Text>
                            </VStack>
                        </VStack>
                    </Center>
                    {!isEmpty(uploading) && (
                        <>
                            <Divider />
                            <HStack spacing={4} p={2}>
                                {uploading.progress < 100 && <Spinner size="sm" color={uploading.progress === 100 ? 'green' : 'rarchy-link'} />}
                                {uploading.progress === 100 && <CheckCircleIcon color="green.500" />}
                                <VStack w="full" alignItems="start">
                                    <Text noOfLines={1} fontSize="13px">{uploading.name}</Text>
                                    <Progress w="full" rounded="md" colorScheme={uploading.progress === 100 ? 'green' : 'twitter'} size='xs' value={uploading.progress} />
                                </VStack>
                            </HStack>
                        </>
                    )}
                </>
            )}
        </Dropzone>
    )
}