import { Box, Center, Stack, WrapItem } from "@chakra-ui/react"
import { useGetFoldersData, useInTrash } from "../../../hooks";

import { FileName } from "./Name"
import { FileOptions } from "./Options";
import { FileThumbnail } from "./Thumbnail";
import { FolderBadge } from "./FolderBadge";
import { Link } from "react-router-dom";
import { SharedAvatarGroup } from "../../Components/Shared/AvatarGroup";
import { SkeletonWrapper } from "../../Components/Shared/SkeletonWrapper";
import { Subtext } from "./Subtext";
import { useState } from "react";

const CARD_WIDTH = 255;

export const NON_FILEGROUP_DIVISION = 1.275;

export const FileCard = ({ file, collection, forRecent }) => {

    const [isMouseover, setIsMouseover] = useState(null)
    const inTrash = useInTrash()
    const folders = useGetFoldersData()
    const forFileGroup = collection ? true : false; // collection is supplied if needing grid to display underneath grouping of files, such as sitemaps or user flows
    const shrinkIfNonGroup = (num) => num / (!forFileGroup ? NON_FILEGROUP_DIVISION : 1)
    const docId = file?.id

    const link = !inTrash ? file?.link || window?.location?.href : window?.location?.href
        
    return (
        <WrapItem
            key={docId}
            mt={3}
            w={`${shrinkIfNonGroup(CARD_WIDTH)}px`}
            onMouseLeave={() => setIsMouseover(null)}
        >
            <Stack w="full" overflow="hidden" mt={0} spacing={0}>
                <SkeletonWrapper>
                    <FileOptions file={file} isMouseover={isMouseover === docId} opts={{ bordered: true }} />
                    <Link to={link} onMouseEnter={() => {
                        setIsMouseover(docId)
                    }}>
                        <Stack>
                            <Box rounded="md" bg="rarchy-color-file" p={3}>
                                <Center
                                    bg="rarchy-bg-sitemap-editor"
                                    p={forFileGroup ? 3 : 2}
                                    border="1px solid"
                                    borderColor="rarchy-border"
                                    rounded="md"
                                >
                                    <Center
                                        w={`${shrinkIfNonGroup(245)}px`}
                                        h={`${shrinkIfNonGroup(165)}px`}
                                    >
                                        <FileThumbnail file={file} forRecent={forRecent} />
                                    </Center>
                                </Center>
                            </Box>
                            <FileName file={file} />
                        </Stack>
                        <Stack direction="row" align="center" mt={1} spacing={3}>
                            <SharedAvatarGroup file={file} forRecent={forRecent} />
                            <Subtext file={file} forFileGroup={forFileGroup} />
                            <FolderBadge file={file} folders={folders} />
                        </Stack>
                    </Link>
                </SkeletonWrapper>
            </Stack>
        </WrapItem>
    )
}