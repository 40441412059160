import {
    Popover,
    PopoverContent
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from 'react'
import { getCanCommentInEditor, getCanEditInEditor } from '../../helpers'
import { isEmpty, keys } from 'lodash'
import { saveCommentsChanges, saveCoverChanges, savePageChanges, savePageSectionChanges, saveWebsiteSectionChanges } from '../../../../../store/actions/sitemap-actions'
import { useCanEditFile, useGetAccountId, useGetAuth, useGetClaims, useGetEditor, useGetFolders, useGetSitemap, useGetUser, useGetUserFlow, useIsLoggedIn } from '../../../../../hooks'

import { Body } from './Components/Body'
import { Detector } from 'react-detect-offline'
import { EditorSkeletonWrapper } from '../../../Toolbar'
import { Header } from './Components/Header'
import { TriggerButton } from './Components/TriggerButton'
import { isSavingChanges } from './helpers'
import { motionPropsZeroAnimation } from '../../../../../helpers'
import { saveFlowElementsChanges } from '../../../../../store/actions/flow-actions'
import { switchTeam } from '../../../../../store/actions/auth-actions'
import { useDispatch } from 'react-redux'

export const AutoSave = () => {
    const editor = useGetEditor()
    const sitemap = useGetSitemap()
    const user = useGetUser()
    const flow = useGetUserFlow()
    const [stopped, setStopped] = useState(false)
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const dispatch = useDispatch();
    useInterval(() => { if (!stopped) handleAutosave(stopped, { sitemap, flow, user }, dispatch) }, 2500)
    useEffect(() => {
        /*** saving ***/
        const isSaving = isSavingChanges(sitemap, flow)
        // count errors (up to 5)
        if (!stopped && getTotalErrorCount({ sitemap, flow }) >= 5) {
            /* Modal.warning({
                title: "We're having issues saving your changes and had to disable autosaving",
                content: (
                    <p style={{ marginTop: 24, fontWeight: 500, marginBottom: 0 }}>Please refresh the page and try making any edits again. We apologise for any inconvenience.</p>
                )
            }) */;
            alert('There has been an error saving your edits. Please refresh the page and try your changes again.');
            setSaving(false);
            setStopped(true);
        }
        // saved
        if (!isSaving && saving) {
            setSaved(true)
            setTimeout(() => setSaved(false), 2000);
        }
        if (isSaving !== saving) setSaving(isSaving)
        /*** saving ***/
    }, [sitemap, flow, saving])
    SwitchFolderIfAccess()
    // const isLoggingIn = !firebase.profile?.isLoaded
    const isLoggedIn = useIsLoggedIn()
    const userCanEditFile = useCanEditFile()
    const canEdit = isLoggedIn && userCanEditFile
    return (
        <EditorSkeletonWrapper>
            <Detector render={({ online }) => (
                <Popover placement="bottom-start">
                    <TriggerButton canEdit={canEdit} online={online} editor={editor} sitemap={sitemap} saving={saving} saved={saved} stopped={stopped} />
                    <PopoverContent motionProps={motionPropsZeroAnimation}>
                        <Header canEdit={canEdit} online={online} sitemap={sitemap} stopped={stopped} />
                        <Body canEdit={canEdit} />
                    </PopoverContent>
                </Popover>
            )} />
        </EditorSkeletonWrapper>
    )
}

const handleAutosave = async (stopped, props, dispatch) => {

    if (stopped) return;
    //
    const { sitemap, flow, user } = props;

    if (!user.token) return; // user is not signed in - don't continue

    const canEdit = getCanEditInEditor();
    const canComment = getCanCommentInEditor();

    if (canEdit) {

        // in user flows
        if (flow?.loaded) {
            // save elements changes
            if (!isEmpty(flow.changes.data) && !flow.changes.saving) {
                dispatch(saveFlowElementsChanges())
            }
        }

        // save page changes
        if (!isEmpty(sitemap?.changes?.pages?.data) && !sitemap?.changes?.pages?.saving) {
            dispatch(savePageChanges())
        }
        // save website section changes
        if (!isEmpty(sitemap?.changes?.website_sections?.data) && !sitemap?.changes?.website_sections?.saving) {
            dispatch(saveWebsiteSectionChanges());
        }
        // save page sections changes
        if (!isEmpty(sitemap?.changes?.sections?.data) && !sitemap?.changes?.sections?.saving) {
            dispatch(savePageSectionChanges())
        }
        // save cover changes
        if (!isEmpty(sitemap?.changes?.covers?.data) && !sitemap?.changes?.covers?.saving) {
            dispatch(saveCoverChanges())
        }
    }

    if (canComment) { // user can only comment
        // save comments changes
        if (!isEmpty(sitemap?.changes?.comments?.data) && !sitemap?.changes?.comments?.saving) {
            dispatch(saveCommentsChanges())
        }
    }

};

const getTotalErrorCount = ({ sitemap, flow }) => {
    const { pages, covers, comments, sections, website_sections } = sitemap?.changes || {};
    return parseInt(pages?.errorCount || 0) + parseInt(covers?.errorCount || 0) + parseInt(comments?.errorCount || 0) + parseInt(sections?.errorCount || 0) + parseInt(website_sections?.errorCount || 0) + parseInt(flow?.changes?.errorCount || 0);
}

function useInterval(callback, delay) {

    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);

}

const SwitchFolderIfAccess = () => {
    const dispatch = useDispatch()
    const editor = useGetEditor()
    const accountId = useGetAccountId()
    const folders = useGetFolders()?.byId
    const { team: folderToken } = useGetClaims() || {}
    const { switching } = useGetAuth()
    useEffect(() => {
        if (!switching?.team) {
            const editorFolder = editor.team;
            const folderIds = [...keys(folders), accountId]
            if (folderIds.includes(editorFolder) && (editorFolder && (folderToken !== editorFolder))) {
                console.log("user has access to folder, but doesn't have the folder token")
                dispatch(switchTeam(editorFolder))
            }
        }
    }, [editor, folders, folderToken, accountId])
}