import { clickNodesNode, mouseoverNodesNode } from '../../views/nodes';
import { clickPageSection, mouseoverSection } from '../components/page-sections';
import { clickText, mouseoverText } from '../components/text';
import { clickUserFlowConnector, mouseoverUserFlowConnector } from '../../../user-flows/components/connectors';
import { clickUserFlowNode, mouseoverUserFlowNode } from '../../../user-flows/components/nodes';
import { clickWebsiteSection, mouseoverWebsiteSection } from '../components/website-sections';
import { event, select } from 'd3';
import { getCanvasTextEditor, getInUserFlow } from '../../../../../helpers';
import { isEmpty, values } from 'lodash'
import { mouseoverConnector, showingMultiSelectBox, showingNewConnectorLine, toggleUserFlowSymbolButtons } from '../../../../../store/actions/flow-actions';
import { pickerCanvas, renderInteractionsCanvas } from '../canvases';
import { pressed, resetMetaKeyPress } from './events/keydown';
import { togglePageButtons, toggleWebsiteSectionNewButton } from '../../../../../store/actions/sitemap-actions';

import { droppedNewSymbol } from '../../../../Editor/Toolbar/User-Flows/Grid';
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers';
import { mouseoverNode } from '../components/node';
import { onContextMenuClick } from '../../context-menu/helpers';
import { render as renderPages } from '../render';
import { render as renderUserFlow } from '../../../user-flows/render';
import { setSelectedUserFlowSymbols } from '../../../user-flows/helpers';
import { store } from '../../../../../store';
import { toggleCanvasTextEditor } from '../../../../../store/actions/editor-actions';
import { transform } from './zoom';

export let mouseover = { node: false, options: {}, text: false };

export let mousePos = { x: 0, y: 0 }

export let prevColKey = 'rgb(0, 0, 0)';

export const resetMouseoverListeners = (props = {}) => {
    const { onlyResetNode } = props;
    if (onlyResetNode) {
        mouseover.node = false;
        mouseover.options = {}
    } else { // fully reset
        mouseover = { node: false, options: {} }
    }
}

export const renderListeners = () => {

    select('#interactions-canvas').on('mousemove', () => { mousemove(event) })

    const inUserFlow = getInUserFlow()
    const canEditInEditor = getCanEditInEditor()

    if (inUserFlow) {
        // listens to mouseup for when dragging new connector lines in user flows (started from page-buttons/user-flows)
        select('#interactions-canvas').on('mouseup', () => {
            const { NewSymbol, NewConnectorLine } = store.getState().flow.utils; // has to be here otherwise attributes are outdated
            if (pressed.metaKey) resetMetaKeyPress();
            if (NewSymbol.dragging) droppedNewSymbol({ NewSymbol, x: event.offsetX, y: event.offsetY })
            if (NewConnectorLine.showing) store.dispatch(showingNewConnectorLine({ showing: false }))
        })
        select('#interactions-canvas').on('mousedown', () => {
            if (pressed.metaKey) {
                if (canEditInEditor) store.dispatch(showingMultiSelectBox({ showing: true, x: transform.invertX(event.offsetX), y: transform.invertY(event.offsetY) }))
                renderInteractionsCanvas(event);
            } else {
                if (!mouseover?.userFlowNode) setSelectedUserFlowSymbols([]);
            }
        })
    }

    select('#interactions-canvas').on('click', function (e) {

        var colKey = getColKey(event);

        const { editor, sitemap } = store.getState();

        if (sitemap?.format !== 'nodes') {
            if (mouseover.userFlowNode) return clickUserFlowNode(mouseover.userFlowNode)
            if (mouseover.userFlowConnector) return clickUserFlowConnector(mouseover.userFlowConnector)
            /*** sections ***/
            if (mouseover.section) return clickPageSection(colKey);
            /*** sections ***/
            /*** text ***/
            if (mouseover.text) return clickText(colKey);
            if (editor?.ui.CanvasTextEditor.showing) store.dispatch(toggleCanvasTextEditor({ showing: false }))
            /*** text ***/
        } else {
            if (mouseover.node) return clickNodesNode(mouseover.node);
        }

        if (mouseover.website_section) return clickWebsiteSection(mouseover.website_section, sitemap)

    });

    select('#interactions-canvas').on('contextmenu', function () {
        event.preventDefault();
        onContextMenuClick();
        prevColKey = null;
        return false;
    });

}

const render = () => {
    const inUserFlow = getInUserFlow()
    !inUserFlow ? renderPages() : renderUserFlow()
}

const mousemoveUserFlows = (event) => {
    const { flow } = store.getState()
    const inUserFlow = getInUserFlow()
    if (!inUserFlow) return;
    const { MultiSelectBox } = flow.utils;
    if (MultiSelectBox.showing) return renderInteractionsCanvas(event);
    // new connector line
    if (flow.utils.NewSymbol.dragging) return renderInteractionsCanvas(event)
    if (flow.utils.NewConnectorLine.showing) return renderInteractionsCanvas(event)
}

var mousemove = (event) => {

    event.preventDefault();
    event.stopImmediatePropagation();

    if (event.srcElement.id !== 'interactions-canvas') return;

    var colKey = getColKey(event);

    mousePos = { x: transform.invertX(event.offsetX), y: transform.invertY(event.offsetY) };

    // user flows only
    mousemoveUserFlows(event)

    /*** still mousing over canvas ***/
    if (colKey === 'rgb(0, 0, 0)' && prevColKey === 'rgb(0, 0, 0)') return document.getElementById('interactions-canvas').style.cursor = "default";
    /*** still mousing over canvas ***/

    const { sitemap, flow } = store.getState()

    /*** mouseenter canvas ***/
    if (colKey === 'rgb(0, 0, 0)' && prevColKey !== 'rgb(0, 0, 0)') {
        /*** not mousing over node ***/

        resetMouseoverListeners() // has to be first

        if (sitemap?.ui.PageButtons.showing) store.dispatch(togglePageButtons({ showing: false }))
        if (sitemap?.ui.WebsiteSectionNewButton.showing) store.dispatch(toggleWebsiteSectionNewButton({ showing: false }))
        if (flow.ui.SymbolButtons.showing) store.dispatch(toggleUserFlowSymbolButtons({ showing: false }))
        if (flow.utils.mouseoverConnector) store.dispatch(mouseoverConnector())

        /*** not mousing over node ***/
        prevColKey = 'rgb(0, 0, 0)';
        return;
    };
    /*** mouseenter canvas ***/

    // always have text cursor when mousing over renaming text
    const CanvasTextEditor = getCanvasTextEditor()
    const renamingPage = CanvasTextEditor.showing && mouseover.text && CanvasTextEditor.node.id === mouseover.text.id;
    if (renamingPage) document.getElementById('interactions-canvas').style.cursor = 'text';

    if (colKey !== prevColKey) {
        updateMouseEnterMouseOut(colKey);
        prevColKey = colKey;
    };

};

const updateMouseEnterMouseOut = (colKey) => {

    const { editor, sitemap } = store.getState();
    const inUserFlow = getInUserFlow();

    /*** nodes view only ***/
    if (sitemap?.format === 'nodes') {
        if (isMouseoverNodesNode(colKey)) {
            document.getElementById('interactions-canvas').style.cursor = 'pointer';
            renderInteractionsCanvas();
        }
        return;
    }
    /*** nodes view only ***/

    // user flows only
    if (inUserFlow) {
        if (!editor?.ui.RevisionHistoryDrawer.showing) {
            isMouseoverUserFlowNode(colKey)
            isMouseoverUserFlowConnector(colKey)
        }
    }

    /*** descending in order of z-index ***/

    isMouseoverWebsiteSection(colKey); // mouseenter

    isMouseoverText(colKey); // mouseenter

    isMouseoverSection(colKey); // mouseenter

    isMouseoverNode(colKey); // mouseenter
    /*** descending in order of z-index ***/

    const shouldShowPointerForWebsiteSection = mouseover.website_section && (!mouseover.website_section.newPagePlaceholder && !mouseover.website_section.newSectionPlaceholder);
    const showPointer = mouseover.text || shouldShowPointerForWebsiteSection || mouseover.section || !isEmpty(mouseover.options);
    document.getElementById('interactions-canvas').style.cursor = showPointer ? 'pointer' : 'default';

    // only in revision history drawer
    if (editor?.ui.RevisionHistoryDrawer.showing) {
        document.getElementById('interactions-canvas').style.cursor = mouseover.expandCollapse ? 'pointer' : 'default';
    }

    var overAnyElement = !values(mouseover).every(isEmpty);
    if (overAnyElement) {
        render();
        renderInteractionsCanvas();
    }

}

const isMouseoverWebsiteSection = (colKey) => {
    const section = mouseoverWebsiteSection(colKey);
    if (section) {
        mouseover.website_section = section;
        return true;
    } else {
        if (mouseover.website_section) mouseover.website_section = false;
        return false;
    }
}

const isMouseoverNodesNode = (colKey) => {
    const node = mouseoverNodesNode(colKey);
    if (node) {
        mouseover = { node };
        return true;
    } else {
        delete mouseover.node;
        return false;
    }
}

const isMouseoverSection = (colKey) => {
    const { flow } = store.getState()
    if (flow.utils.MultiSelectBox.showing) return false;
    const { node, section } = mouseoverSection(colKey) ? mouseoverSection(colKey) : {};
    if (node) {
        if (node._selected) return false;
        mouseover.section = { node, section };
        return true;
    } else {
        if (mouseover.section) {
            mouseover.section = false;
        }
        return false;
    }
}

const isMouseoverText = (colKey) => {
    const { flow } = store.getState()
    if (flow.utils.MultiSelectBox.showing) return false;
    const node = mouseoverText(colKey);
    if (node) {
        if (node._selected) return false;
        mouseover.text = mouseover.node = node;
        return true;
    } else {
        if (mouseover.text) mouseover.text = false;
        return false;
    }
}

const isMouseoverNode = (colKey) => {
    const { flow } = store.getState()
    if (flow.utils.MultiSelectBox.showing) return false;
    // THIS IS WHERE YOU CAN ENSURE MOUSEOVER.NODE IS STILL ASSIGNED WHEN MOUSING OVER OTHER ELEMENTS
    const node = mouseoverNode(colKey);
    if (node) {
        mouseover.node = node;
        return true;
    } else {
        var overAnyNodeOptions = !values(mouseover.options).every(isEmpty);
        if (overAnyNodeOptions) {
            mouseover.node = mouseover.options.node;
            return true;
        } else if (mouseover.text) {
            mouseover.node = mouseover.text;
            return true;
        } else if (mouseover.section) {
            mouseover.node = mouseover.section.node;
        } else {
            if (mouseover.node) mouseover.node = false;
            return false;
        }
    }
}

const isMouseoverUserFlowNode = (colKey) => {
    const { flow } = store.getState()
    // mouseoverNode = page
    const node = mouseoverUserFlowNode(colKey) || mouseoverNode(colKey) || null;
    if (node) {
        if (flow.utils.NewSymbol.dragging) return false;
        if (flow.utils.MultiSelectBox.showing) return false;
        mouseover.userFlowNode = node;
        return true;
    } else {
        if (mouseover.userFlowNode) mouseover.userFlowNode = false;
        return false;
    }
}

const isMouseoverUserFlowConnector = (colKey) => {
    const { editor, sitemap, flow } = store.getState();
    if (editor?.ui.RevisionHistoryDrawer.showing) return false;
    if (flow.utils.MultiSelectBox.showing) return false;
    const { colorId, link } = mouseoverUserFlowConnector(colKey) || {};
    if (link && !flow.utils.NewConnectorLine.showing) {
        if (!flow.utils.NewSymbol.dragging) {
            mouseover.userFlowConnector = { colorId, link }
            store.dispatch(mouseoverConnector(link))
            if (sitemap?.ui.PageButtons.showing) store.dispatch(togglePageButtons({ showing: false }))
            if (flow.ui.SymbolButtons.showing) store.dispatch(toggleUserFlowSymbolButtons({ showing: false }))
            return true;
        }
    } else {
        if (mouseover.userFlowConnector) mouseover.userFlowConnector = false;
        return false;
    }
}

function getColKey(event) {
    const devicePixelRatio = window.devicePixelRatio || 1;
    // Get mouse positions from the main canvas. 
    var mouseX = ((event.offsetX) * devicePixelRatio); var mouseY = ((event.offsetY) * devicePixelRatio);
    // Pick the colour from the mouse position.   
    var col = pickerCanvas.getImageData(mouseX, mouseY, 1, 1).data;
    // Then stringify the values in a way our map-object can read it. 
    var colKey = 'rgb(' + col[0] + ', ' + col[1] + ', ' + col[2] + ')';
    return colKey;
}