import { Avatar, Icon, Stack, Text } from "@chakra-ui/react"
import { useGetOrganization, useIsOrganizationAdmin } from "../../../../../hooks"
import { NavItem } from "../../../Components/NavItem"
import { BiCart, BiCreditCardFront, BiListUl } from "react-icons/bi"
import { TbUsersPlus } from "react-icons/tb"
import { SkeletonCircleWrapper, SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"

export const OrganizationSettings = () => {
    const organization = useGetOrganization()
    const isAdmin = useIsOrganizationAdmin()
    const name = organization?.name
    return (
        <Stack mt={5}>
            <Stack direction="row" align="center" spacing={3.5}>
                <SkeletonCircleWrapper>
                    <Avatar size="smd" name={name} />
                </SkeletonCircleWrapper>
                <SkeletonWrapper w="fit-content">
                    <Text color="fg.default" noOfLines={1} fontWeight="semibold">{name}</Text>
                </SkeletonWrapper>
            </Stack>
            <Stack spacing={1.5} my={2.5}>
                <NavItem
                    pathname={"/app/settings/org-details"}
                    label="Organization details"
                    icon={<BiListUl />}
                />
                <NavItem
                    pathname={"/app/settings/people"}
                    label="People"
                    icon={<TbUsersPlus />}
                />
                <NavItem
                    pathname={"/app/settings/billing-and-plans"}
                    label="Billing & plans"
                    icon={<BiCreditCardFront />}
                />
                {isAdmin && (
                    <NavItem
                        pathname={"/app/settings/invoice-history"}
                        label="Invoice history"
                        icon={<BiCart />}
                    />
                )}
            </Stack>
        </Stack>
    )
}