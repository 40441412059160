import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react"
import { All } from "./All"
import { Recent } from "./Recent"
import { FolderDetail } from "./Shared/FolderDetail"
import { SearchFoldersList } from "../Search"
import { useMoveToFolderContext } from "../../../../../Projects/Folders/hooks"
import { isEmpty } from 'lodash'

export const Folders = () => {
    const { tabIndex, setTabIndex, showDetailScreen, searchInputVal } = useMoveToFolderContext()
    const showTabs = !showDetailScreen && isEmpty(searchInputVal)
    const showDetail = showDetailScreen && isEmpty(searchInputVal)
    const showSearchResults = !isEmpty(searchInputVal)
    return (
        <>
            {showTabs && (
                <Tabs index={tabIndex} isFitted size="sm" borderBottomColor="transparent" px={0} onChange={(index) => setTabIndex(index)}>
                    <TabList mb="-1px">
                        <Tab fontWeight="semibold">Recent</Tab>
                        <Tab fontWeight="semibold">All</Tab>
                    </TabList>
                    <TabPanels h="40vh" maxH="300px" overflowX="hidden" overflowY="auto">
                        <Recent />
                        <All />
                    </TabPanels>
                </Tabs>
            )}
            {showDetail && <FolderDetail />}
            {showSearchResults && <SearchFoldersList />}
        </>
    )
}