import { Box, Divider, Menu, MenuButton, MenuItem, MenuList, Portal, Stack, DarkMode } from "@chakra-ui/react"
import { AccountButton } from "./AccountButton"
import { AccountItem } from "./AccountItem"
import { useGetAccounts, useIsMemberOfOrganizations } from "../../../../hooks"
import { useDispatch } from "react-redux"
import { switchAccount } from "../../../../store/actions/auth-actions"
import { Upgrade } from "../Upgrade"
import { getShortPlanId } from "../../../../../shared/helpers"
import { compact } from 'lodash'

export const AccountSwitcher = ({ inSideMenu }) => {
    const isMemberOfOrganizations = useIsMemberOfOrganizations()
    return (
        <DarkMode>
            <Menu placement="bottom" isLazy bgColor="red">
                <AccountButton inSideMenu={inSideMenu} />
                <Portal>
                    <MenuList p={0} fontSize="sm" w="240px">
                        <Box px={4} py={3} fontSize="xs" fontWeight="semibold" color="fg.muted">
                            {isMemberOfOrganizations ? "Switch to another account" : "No other accounts found"}
                        </Box>
                        <Divider />
                        <Stack py={2} spacing={0}>
                            <SwitcherAccounts inSideMenu={inSideMenu} />
                            {!isMemberOfOrganizations && <Box px={2.5}><Upgrade /></Box>}
                        </Stack>
                    </MenuList>
                </Portal>
            </Menu>
        </DarkMode>
    )
}

export const SwitcherAccounts = (opts = {}) => {
    const { isCurrent, inSideMenu, inNav, onClose } = opts
    const accounts = useGetAccounts()
    const dispatch = useDispatch()
    const toList = compact([...accounts]?.map((account, i) => {
        const shortPlanId = getShortPlanId(account?.type === 'user' ? 'free' : account?.subscription?.plan?.id)
        // don't include current team if not in nav
        if (!inNav) {
            if (isCurrent && !account.isCurrent) return; if (!isCurrent && account.isCurrent) return;
        }
        const isOrganization = account.type === 'organization'
        const usersCount = !isOrganization ? 1 : Object.keys(account?.users || {})?.length
        return (
            <MenuItem bgColor="transparent" as={!inNav && isCurrent ? MenuButton : MenuItem} key={i} px={inNav ? 3.5 : 3} py={2} _hover={{ cursor: 'pointer', bgColor: !isCurrent ? "rarchy-bg-subtle-hover" : "auto" }}
                onClick={() => {
                    if (isCurrent) return;
                    dispatch(switchAccount(account?.id));
                    if (onClose) onClose()
                }}>
                <AccountItem
                    isCurrent={isCurrent}
                    inSideMenu={inSideMenu}
                    inNav={inNav}
                    account={account}
                    shortPlanId={shortPlanId}
                    usersCount={usersCount}
                />
            </MenuItem>
        )
    }))
    return toList
}