import { store } from '../../../../../../store';
import { picker } from '../../render';
import { barWidth } from '../../..';
import { stratify } from '../../../../utils/app';
import { transform } from '../zoom';
import { dragging } from '.';
import { getSitemap } from '../../../../../../helpers';
import { without, isEmpty } from 'lodash'

export const hideWhileDragging = (node, hide) => {
    if (!node) return;
    recurse(node);
    //
    function recurse(d) {
        if (d.id !== node.id) {
            d.hideWhileDragging = hide; // don't hide dragging node
        }
        if (d.children) d.children.forEach(recurse);
        if (d._children) d._children.forEach(recurse);
    }
};

export const createDraggingPlaceholderNodeData = (nodeData, side) => {

    if (!nodeData) return;

    var node = stratify([{ parent: null }]);
    delete node.data;

    node = { ...nodeData };

    node.nodeId = nodeData.id;
    node.id = 'dragging-placeholder';

    /*** ensures nodeHeight is calculated from the dragged node NOT the parent node ***/
    if (dragging) node.nodeHeight = dragging.nodeHeight;
    /*** ensures nodeHeight is calculated from the dragged node NOT the parent node ***/

    if (side) node.side = side;

    return node;
}

export const moveNodeUnderNewParent = function (node, newParent, index) {

    const sitemap = getSitemap();

    if (node.id === newParent.id) return; // fail-safe, shouldn't really ever trigger - stops node from assigning self as it's parent (will loop big time)

    /*** node hasn't changed ***/
    if ((newParent.id === node.parent.id) && (node.index === index)) return; // will get cleaned up by dragended function
    /*** node hasn't changed ***/

    /******** Dropped Node ********/

    const { website_section } = node;
    const { website_section: parent_website_section } = newParent;
    // get latest data (for undo/redo to not fuck up)
    const nodeFromSitemapData = sitemap?.data.nodes.find(d => d.id === node.id); // get most up to date node data
    node = nodeFromSitemapData ? nodeFromSitemapData : node;
    const newParentFromSitemapData = sitemap?.data.nodes.find(d => d.id === newParent.id);
    newParent = newParentFromSitemapData ? newParentFromSitemapData : newParent;

    // website sections
    if (!parent_website_section && website_section) node.website_section = null; // dropped from website section into main pages 
    if (parent_website_section && (parent_website_section !== website_section)) { // dropped into website section
        node.website_section = parent_website_section;
    }
    /******** Dropped Node ********/

    // remove dragged node from current parent
    node.parent.children = without(node.parent.children, node);

    // remove children array if no more children
    if (isEmpty(node.parent.children)) { node.parent.children = null; };

    // If children, push dropped node to children array
    if (newParent.children) {
        newParent.children.splice(index, 0, node);
        // If collapsed, push dropped node to collapsed children array
    } else {
        newParent.children = [];
        newParent.children.push(node);
    };

    node.depth = newParent.depth + 1;
    node.parent = newParent;

    /*** has to be here ***/
    // redo indexes for removed node parent
    node.parent.children.forEach((d, i) => { d.index = i; });
    // redo indexes for new parent
    newParent.children.forEach((d, i) => { d.index = i; });
    /*** has to be here ***/

    /******** Dropped Node ********/

    /******** Children Nodes ********/

    changeChildrenNodes(node);

    return node;

    function changeChildrenNodes(d) {

        if (d.id === newParent.id) return; // fail-safe, shouldn't really ever trigger

        d.x0 = newParent.x;
        d.y0 = newParent.y;
        if (newParent.website_section) d.website_section = newParent.website_section;

        var children = d.children ? d.children : d._children;
        var length = !children ? 0 : children.length;

        for (var i = 0; i < length; i++) {
            children[i].depth = children[i].parent.depth + 1;
            children[i].website_section = newParent.website_section;
            changeChildrenNodes(children[i]);
        }
    }
};

export const getColKey = (event, opts) => {
    const { left, right } = opts;
    const devicePixelRatio = window.devicePixelRatio || 1;
    // Get mouse positions from the main canvas. 
    var mouseX = ((event.sourceEvent.offsetX) * devicePixelRatio); var mouseY = ((event.sourceEvent.offsetY) * devicePixelRatio);
    //
    if (left) mouseX = mouseX - ((barWidth * 0.25) * transform.k)
    if (right) mouseX = mouseX + ((barWidth * 0.25) * transform.k);

    // Pick the colour from the mouse position.   
    var col = picker.getImageData(mouseX, mouseY, 1, 1).data;
    // Then stringify the values in a way our map-object can read it. 
    var colKey = 'rgb(' + col[0] + ', ' + col[1] + ', ' + col[2] + ')';
    return colKey;
}