import { getInUserFlow, getUI } from '../../../../helpers';

import { event } from 'd3';
import { mouseover } from '../canvas/utils/listeners';
import { renderInteractionsCanvas } from '../canvas/canvases';
import { showEditorPermissionsToast } from '../../../Editor/App/Components/PermissionsToast';
import { store } from '../../../../store';
import { toggleContextMenuDropdown } from '../../../../store/actions/sitemap-actions';

export const onContextMenuClick = () => {
    
    const { sitemap, flow } = store.getState();

    var node = mouseover.node;

    if (node) {

        if (sitemap?.format !== 'nodes') {

            const inUserFlow = getInUserFlow();
            const nodes = !inUserFlow ? sitemap?.data?.nodes : flow?.data?.nodes
            node = nodes.find(d => d.id === node.id) // get most up to date node data
        }

        /*** reset canvas ***/
        mouseover.node = null;
        mouseover.options = {};
        renderInteractionsCanvas();
        /*** reset canvas ***/

        showContextMenu({ event, node });
    }
};

export const showContextMenu = ({ event, left, top, node, trigger }) => {
    event.preventDefault()
    event.stopPropagation()
    // can edit
    const canEdit = showEditorPermissionsToast(); if (!canEdit) return;
    // only show in user flows if linked page
    const inUserFlow = getInUserFlow();
    if (inUserFlow && !node.page) return; 
    // continue
    let x = trigger === "button" ? left : event.offsetX - 30
    let parent = document.getElementById("canvases-container");
    const y = (trigger === "button" ? top : (event.offsetY + 60)) - parent.offsetTop;
    // adjust x for open sidemenu
    const isSidebarShowing = !getUI()?.sideMenu?.collapsed
    if (isSidebarShowing) x = x - 266;
    // open menu
    store.dispatch(toggleContextMenuDropdown(true, { node, offset: [x, y] }));
}

export const hideContextMenu = () => {
    store.dispatch(toggleContextMenuDropdown(false, {}/* empty props */));
}