import {
    IconButton,
    Text,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { areCommentsMarkedAsResolved, getCommentsCount, showCommentsPopover } from '../../comments/helpers.jsx';
import { useCanCommentOnFile, useGetSitemap, useInUserFlow } from '../../../../hooks';

import { ChatIcon } from '@chakra-ui/icons';
import { getPageColor } from '../canvas/utils/helpers';
import { getPalletteColors } from '../../../../store/actions/editor-actions';
import { isEmpty } from 'lodash'
import { togglePageButtons } from '../../../../store/actions/sitemap-actions';
import { transform } from '../canvas/utils/zoom';
import { useDispatch } from 'react-redux';

export const CommentsButton = ({ d, x, y }) => {

    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    const inUserFlow = useInUserFlow()

    const showCovers = inUserFlow ? (d.type === 'page' ? true : false) : sitemap?.showCovers
    const targetButtonWidth = Math.ceil((showCovers ? 30 : 27) * transform.k)
    const buttonWidth = (targetButtonWidth % 2 === 0) ? targetButtonWidth : targetButtonWidth + 1
    const left = getLeft({ d, x, buttonWidth }), top = getTop({ d, y, buttonWidth })

    const header = getPageColor(d)
    const pallette = getPalletteColors(header)

    const pageId = d[inUserFlow ? `page` : `id`]

    const comments = sitemap?.comments[pageId]
    const markedAsResolved = areCommentsMarkedAsResolved(comments)

    const hasComments = !isEmpty(comments) && !markedAsResolved

    const canComment = useCanCommentOnFile()
    if (!hasComments && !canComment) return null
    
    return (
        <IconButton
            color={hasComments ? "transparent" : mode("white", "gray.800")}
            icon={renderIcon({ sitemap, nodeId: d.id, buttonWidth })}
            h={`${buttonWidth}px`}
            minW={`${buttonWidth}px`}
            left={left}
            top={top}
            bgColor={hasComments ? "transparent" : header}
            outline={`${2 * transform.k}px solid`}
            outlineColor={hasComments ? "transparent" : mode("white", "gray.800")}
            _hover={{ bgColor: hasComments ? "transparent" : pallette[5] }}
            _active={{ bgColor: hasComments ? "transparent" : pallette[5] }}
            rounded="full"
            pos="absolute"
            onClick={(e) => {
                e.stopPropagation()
                showCommentsPopover(d)
                dispatch(togglePageButtons({ showing: false }))
            }}
            paddingInlineStart={0}
            paddingInlineEnd={0}
        />
    )
}

const getLeft = ({ d, x, buttonWidth }) => {
    return x + (225 * transform.k) - (buttonWidth / 2) - (0.5 * transform.k)
}

const getTop = ({ d, y, buttonWidth }) => {
    return (y + (3.5 * transform.k) - (buttonWidth / 2))
}

const renderIcon = ({ sitemap, nodeId, buttonWidth }) => {
    const comments = sitemap?.comments?.[nodeId] || {};
    const markedAsResolved = areCommentsMarkedAsResolved(comments);
    const commentsCount = getCommentsCount(comments);
    const targetFontSize = Math.floor(buttonWidth * 0.5)
    const fontSize = (targetFontSize % 2 === 0) ? targetFontSize : targetFontSize + 1;
    return (markedAsResolved || commentsCount === 0) ? <ChatIcon fontSize={fontSize} /> : <Text fontSize={fontSize}>{commentsCount}</Text>
}