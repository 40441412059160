import { NavItem } from "../../Components/NavItem"
import { HiOutlineFolderOpen } from "react-icons/hi"
import { useInOrganization } from "../../../../hooks"
import { Icon } from "@chakra-ui/react"
import { RiUserLine, RiUserShared2Line } from "react-icons/ri"
import { IoChevronForward } from "react-icons/io5"
import { Recent } from "./Recent"
import { All } from "./All"
import { EmptyFolders } from "../../../Projects/Folders/Components/Empty"
import { useDispatch } from "react-redux"
import { Starred } from "../../Starred"

export const ProjectsMenu = () => {
    const inOrganization = useInOrganization()
    return (
        <>
            {inOrganization && (
                <NavItem
                    pathname="/app/projects"
                    label="All projects"
                    icon={<HiOutlineFolderOpen />}
                />
            )}
            <NavItem
                pathname="/app/projects/your-projects"
                icon={<RiUserLine />}
                label="Your projects"
                endElement={<Icon as={IoChevronForward} opacity={1} fontSize="lg" mt={1.5} />}
            />
            <NavItem
                pathname="/app/projects/shared-with-you"
                label="Shared with you"
                icon={<RiUserShared2Line />}
                endElement={<Icon as={IoChevronForward} opacity={1} fontSize="lg" mt={1.5} />}
            />
            <Starred inSideMenu />
            <EmptyFolders inSideMenu />
            {/* <Recent /> */}
            {/* <All /> */}
        </>
    )
}