import { expandToNode, update } from '../../../Sitemap/utils/app';
import { searchTree } from '../Search/helpers';
import { find } from'lodash';

export const handleSelectedPage = (sitemap, selectedPage) => {

    const { nodes } = sitemap?.data;

    if (sitemap?.format === 'nodes') return;

    // node not showing (aka in _children not in dom)
    var node = find(nodes, (d) => { return d.id === selectedPage });

    /*** node is visible on page, can go straight to it ***/
    if (node) {
        node.goToComments = true;
        update();
    }
    /*** node is visible on page, can go straight to it ***/

    /*** node isn't visible on page (i.e collapsed) ***/
    if (!node) {
        var paths = searchTree(sitemap?.data.root, selectedPage);
        if (typeof (paths) !== "undefined") return expandToNode(paths, { comments: true });
    }
    /*** node isn't visible on page (i.e collapsed) ***/

}