import {
    IconButton,
    Tooltip,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { compact, isEmpty, keys } from 'lodash'

import { LuUnlink } from 'react-icons/lu';
import React from 'react';
import { addSymbolChange } from '../../helpers';
import { chain } from '../../../../../helpers/chain';
import copy from 'fast-copy';
import { getPageName } from '../../../utils/app';
import { getUserFlow } from '../../../../../helpers';
import { hideFlowsOptionsPopover } from '../../../../../store/actions/flow-actions';
import { store } from '../../../../../store';
import { useDispatch } from 'react-redux';

export const Unlink = ({ OptionsPopover }) => {
    const dispatch = useDispatch()
    return (
        <Tooltip variant="rounded" placement='top' label='Unlink Page' openDelay={1000}>
            <IconButton
                size="sm"
                variant="ghost"
                color={mode("gray.600", "whiteAlpha.900")}
                icon={<LuUnlink fontSize="16px" />}
                onClick={() => {
                    const { nodes } = OptionsPopover;
                    const { newConnectorsData, oldConnectorsData } = checkForLinkedPageSectionsConnectors(nodes.map(({ id }) => id))
                    const data = { ...newConnectorsData }; nodes.forEach(d => data[d.id] = { ...data[d.id], page: null, text: getPageName(d.name) });
                    const oldData = { ...oldConnectorsData }; nodes.forEach(({ id, page, connectors }) => oldData[id] = { ...oldData[id], page, connectors });
                    addSymbolChange({ data, oldData });
                    dispatch(hideFlowsOptionsPopover());
                    // setSelectedUserFlowSymbols([]);
                }}
            />
        </Tooltip>
    )
}

const checkForLinkedPageSectionsConnectors = (nodesToBeUnlinked) => {

    var firestoreData = {}
    var oldConnectorsData = {};

    const flow = getUserFlow()
    const { data } = flow;

    /* eslint-disable-next-line */
    const nodes = [...compact(chain(copy(data)).map((obj, id) => { if (obj) return { id, ...obj } }).value())];

    nodes.forEach(node => {
        if (!node) return;
        const { connectors } = node;
        if (connectors) {
            const positions = keys(connectors);
            if (positions) {
                let isConnected = false;
                positions.forEach(pos => {
                    var position = connectors[pos];
                    if (isEmpty(position)) return;
                    keys(position).forEach(id => {
                        const sectionPos = nodesToBeUnlinked.includes(node.id) ? 'start' : 'end';
                        // connector starts from section in node we are unlinking || connector ends at section in node we are unlinking
                        if (nodesToBeUnlinked.includes(node.id) || nodesToBeUnlinked.includes(id)) {
                            // setup
                            if (!firestoreData[node.id]) firestoreData[node.id] = { connectors: { [pos]: position } };
                            const connector = firestoreData[node.id].connectors[pos][id];
                            if (connector[`${sectionPos}Section`]) {
                                isConnected = true;
                                oldConnectorsData[node.id] = copy({ ...oldConnectorsData[node.id], connectors: firestoreData[node.id].connectors })
                                firestoreData[node.id].connectors[pos][id][`${sectionPos}Section`] = null; // HAS TO BE NULL
                            }
                        }
                    })
                })
                if (!isConnected) delete oldConnectorsData[node.id];
            }
        }
    });
    // NEED COPY EVERYWHERE AS OBJS KEEP GETTING MUTATED FOR SOME REASON
    return { newConnectorsData: copy(firestoreData), oldConnectorsData: copy(oldConnectorsData) };
}