import React from 'react'
import { useSelector } from 'react-redux'

import {
    Avatar,
    HStack,
    VStack,
    Text,
    useColorModeValue as mode
} from "@chakra-ui/react"

import { getAvatarSrc } from '../../../../helpers'
import { TextArea } from '../Components/TextArea'
import { commentTime, handleAddSubmit } from '../helpers.jsx'

export const AddPageComment = ({ page }) => {
    const state = useSelector(state => state)
    const { user } = state
    const handleSubmit = (comment) => handleAddSubmit(comment)
    return (
        <>
            <HStack direction="row" spacing={4} mb={2}>
                <Avatar
                    size="xs"
                    w={7}
                    h={7}
                    src={getAvatarSrc({ user })}
                />
                <VStack alignItems="left">
                    <Text mb={-2} fontSize="sm" fontWeight={500} noOfLines={1}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</Text>
                    <Text fontSize="xs" color={mode("gray.500", "whiteAlpha.700")}>{commentTime(new Date().getTime())}</Text>
                </VStack>
            </HStack>
            <TextArea view="add" page={page} handleSubmit={handleSubmit} />
        </>
    )
}