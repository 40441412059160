import { chakra } from '@chakra-ui/react'

export const LogoSymbol = () => {
    return (
        <chakra.svg width="22px" viewBox="0 0 155 141" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path fill="#2dd8c3" d="M53.657,55.337 C53.657,55.12 53.684,54.909 53.69,54.693 C53.704,54.22 53.719,53.749 53.761,53.279 C53.792,52.91 53.843,52.546 53.892,52.181 C53.943,51.801 53.996,51.422 54.065,51.045 C54.143,50.623 54.238,50.208 54.337,49.794 C54.414,49.477 54.491,49.16 54.581,48.846 C54.705,48.412 54.844,47.986 54.991,47.563 C55.095,47.265 55.2,46.969 55.316,46.675 C55.476,46.27 55.648,45.872 55.83,45.478 C55.973,45.165 56.121,44.856 56.277,44.549 C56.456,44.199 56.646,43.856 56.843,43.516 C57.039,43.177 57.24,42.843 57.453,42.513 C57.64,42.222 57.835,41.939 58.035,41.657 C58.286,41.304 58.543,40.958 58.814,40.617 C59.011,40.369 59.215,40.129 59.422,39.889 C59.715,39.55 60.013,39.218 60.326,38.895 C60.555,38.659 60.79,38.433 61.028,38.207 C61.336,37.915 61.645,37.627 61.972,37.35 C62.265,37.099 62.569,36.862 62.875,36.626 C63.165,36.403 63.452,36.18 63.754,35.968 C64.143,35.696 64.545,35.444 64.951,35.195 C65.133,35.083 65.301,34.956 65.486,34.848 L77.316,28.018 L35.494,3.872 C24.177,-2.663 9.707,1.215 3.174,12.531 C-3.359,23.848 0.517,38.317 11.833,44.851 L53.657,68.997 L53.657,55.337" id="Fill-1" fillOpacity="0.800000012"></path>
                <path fill="#00A7E1" d="M151.459,12.531 C144.926,1.215 130.456,-2.662 119.14,3.872 L65.486,34.848 C65.301,34.956 65.133,35.083 64.951,35.195 C64.545,35.444 64.143,35.696 63.754,35.968 C63.452,36.18 63.165,36.403 62.875,36.626 C62.569,36.862 62.265,37.099 61.972,37.35 C61.645,37.627 61.336,37.915 61.028,38.207 C60.79,38.433 60.555,38.659 60.326,38.895 C60.013,39.218 59.715,39.55 59.422,39.889 C59.215,40.129 59.011,40.369 58.814,40.617 C58.543,40.958 58.286,41.304 58.035,41.657 C57.835,41.939 57.64,42.222 57.453,42.513 C57.24,42.843 57.039,43.177 56.843,43.516 C56.646,43.856 56.456,44.199 56.277,44.549 C56.121,44.856 55.973,45.165 55.83,45.478 C55.648,45.872 55.476,46.27 55.316,46.675 C55.2,46.969 55.095,47.265 54.991,47.563 C54.844,47.986 54.705,48.412 54.581,48.846 C54.491,49.16 54.414,49.477 54.337,49.794 C54.238,50.208 54.143,50.623 54.065,51.045 C53.996,51.422 53.943,51.801 53.892,52.181 C53.843,52.546 53.792,52.91 53.761,53.279 C53.719,53.749 53.704,54.22 53.69,54.693 C53.684,54.909 53.657,55.12 53.657,55.337 L53.657,116.401 C53.657,129.468 64.249,140.061 77.316,140.061 C90.383,140.061 100.976,129.468 100.976,116.401 L100.976,68.997 L142.799,44.851 C154.115,38.317 157.993,23.848 151.459,12.531" id="Fill-3"></path>
            </g>
        </chakra.svg>
    )
}