import { Box, Center, DarkMode, Heading, Image, Stack, useColorModeValue as mode } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../../hooks"
import { toTitleCase } from "../../../../helpers"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../../shared/hooks/pricing"

const LightImage = "https://help.rarchy.com/hubfs/Page%20Sections/Page%20Sections%20-%20Light.png"
const DarkImage = "https://help.rarchy.com/hubfs/Page%20Sections/Page%20Sections%20-%20Dark.png"

export const RightPageSections = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <Box w="full" h="full" bgColor="rarchy-bg-upgrade-modal" p={6} py={12} align="center">
            <DarkMode>
                <Stack spacing={12}>
                    <Center>
                        <Image w="390px" src={mode(LightImage, DarkImage)} />
                    </Center>
                    <Stack spacing={6} px={12}>
                        <Heading fontSize="3xl" color="fg.default">{`Get unlimited page sections with Rarchy ${toTitleCase(planId)}`}</Heading>
                        <Box color="fg.muted" fontSize="md">
                            {`${canStartNewTrial ? `Start a free ${TRIAL_DAYS}-day trial of` : 'Upgrade to'} Rarchy ${toTitleCase(planId)} to unlock unlimited page sections and plan your page content in more detail.`}
                        </Box>
                    </Stack>
                </Stack>
            </DarkMode>
        </Box>
    )
}