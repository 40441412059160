import { Skeleton, Stack, Wrap, useRadioGroup } from "@chakra-ui/react"
import * as React from 'react'
import { ButtonRadio } from './ButtonRadio'
import { SkeletonWrapper } from "../../../../../Components/Shared/SkeletonWrapper"

export const ButtonRadioGroup = (props) => {
    const { options, isLoaded, ...rest } = props
    const { getRadioProps, getRootProps } = useRadioGroup(rest)
    return (
        <Wrap
            spacingX={5}
            spacingY={5}
            align="center"
            {...getRootProps()}
        >
            {options?.map((option, i) => (
                option && (
                    <SkeletonWrapper key={option.value} w="fit-content">
                        <ButtonRadio
                            index={i}
                            icon={option.icon}
                            isHero={option.isHero}
                            description={option.description}
                            label={option.label}
                            link={option.link}
                            onClick={option.onClick}
                            {...getRadioProps({
                                value: option.value,
                            })}
                        />
                    </SkeletonWrapper>
                )))}
        </Wrap>
    )
}