import { isEmpty, compact } from 'lodash'

export const withinMaxDepthBySubfolder = (link, options) => {
    if (link === 'null') return;
    link = new URL(link)?.pathname
    const depth = compact(`${link}`?.substring(1).split("/"))?.length;
    if (!options?.maxDepth || options?.maxDepth === 0 || options?.maxDepth === "any" || (depth <= options?.maxDepth)) return true;
}

export const shouldIncludeOrExcludeURL = (link, options) => {
    // include/exclude
    let { include, exclude } = options || {};
    // ensure includes/excludes is always an array
    include = Array.isArray(include) ? include : (include?.replace(/\n/g, ",").split(",") || []);
    exclude = Array.isArray(exclude) ? exclude : (exclude?.replace(/\n/g, ",").split(",") || []);
    // regexes
    let includeRegex = toRegexFromArray(include);
    let excludeRegex = toRegexFromArray(exclude);
    // include
    var includeMatch = !isEmpty(include) ? includeRegex.exec(link) : true;
    // exclude
    var excludeMatch = !isEmpty(exclude) ? !excludeRegex.exec(link) : true;
    // if link, insert into array 
    if (includeMatch && excludeMatch) return link;
}

function toRegexFromArray(arr) {
    return new RegExp(arr.map((r) => (r + "").replace(/\\/g, "")).join("|"), "g");
};