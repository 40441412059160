import { Box, Center, DarkMode, Heading, Image, Stack, useColorModeValue as mode } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../../hooks"
import { toTitleCase } from "../../../../helpers"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../../shared/hooks/pricing"

const LightImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Light-1080.png"
const DarkImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Dark-1080.png"

export const RightPageColors = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <Box w="full" h="full" bgColor="rarchy-bg-upgrade-modal" p={6} py={12} align="center">
            <DarkMode>
                <Stack spacing={12}>
                    <Center>
                    <Image w="330px" src={mode(LightImage, DarkImage)} />
                    </Center>
                    <Stack spacing={6} px={16}>
                        <Heading fontSize="2xl" color="fg.default">{`Color code your sitemap pages with Rarchy ${toTitleCase(planId)}`}</Heading>
                        <Box color="fg.muted" fontSize="md">
                            {`${canStartNewTrial ? `Start a free ${TRIAL_DAYS}-day trial of` : 'Upgrade to'} Rarchy ${toTitleCase(planId)} to unlock individual page colors.`}
                        </Box>
                    </Stack>
                </Stack>
            </DarkMode>
        </Box>
    )
}