import { getPageName, update } from '../app';
import { store } from '../../../../store';
import { addPageChange, addCoversChange, togglePageButtons, mergePagesEdits, removeSubfoldersFromTabs } from '../../../../store/actions/sitemap-actions';
import { getFirestore } from 'redux-firestore';

import { handlePageSectionsFromPageChange } from '../../app/canvas/utils/page-sections/helpers';
import { getSitemap } from '../../../../helpers';

import { event } from 'd3';

import { isEmpty, merge } from 'lodash'

export const remove = function (node) {
  // stops node being clicked
  if (event) event.stopPropagation();
  // never delete parent node
  if (!node.parent) return;
  // no longer need this as we have revision-history for pages now
  /*** 
  // check for custom covers
  const { sitemap } = store.getState();
  const descendants = node.descendants();
  const covers = sitemap?.covers.pages;
  var hasCustomCover = false;
  descendants.forEach(page => {
    if (!isEmpty(covers[page.id]) && !isEmpty(covers[page.id].devices)) hasCustomCover = true;
  });
  // prompt user to delete any custom covers before they can delete page
  if (hasCustomCover) return store.dispatch(toggleDeleteCoversConfirm(true)); */
  // delete pages
  deletePages(node);
};

const deletePages = (node) => {
  /*** set old indexes for existing children of same parent ***/
  const childrenWithOldIndexes = [];
  node.parent.children.forEach((d, i) => { childrenWithOldIndexes.push({ id: d.id, index: i }) });
  /*** set old indexes for existing children of same parent ***/
  //
  var index = node.parent.children ? node.parent.children.indexOf(node) : -1;
  //
  if (index > -1) {
    node.parent.children.splice(index, 1);
    // assign new indexes
    node.parent.children.forEach((d, i) => d.index = i);
    // remove array if last child removed
    if (node.parent.children.length === 0) node.parent.children = null;
  }
  //
  update();
  //
  const firestore = getFirestore();
  const { user } = store.getState();
  const sitemap = getSitemap();
  //
  const pagesToRemove = getPagesToRemove(node);
  var firestorePageData = [], PageSectionsPagesToDelete = [], firestoreCoversData = []//, firestoreCommentsData = [];
  // subfolder tabs
  const subfolderTabsToRemove = [];
  pagesToRemove.forEach(page => {
    // remove page data
    firestorePageData.push({ action: 'remove', id: page.id });
    // remove subfolder tabs
    subfolderTabsToRemove.push(page.id);
    /*** only need this for user sitemaps as we have revision-history for pages now */
    const inUserSitemap = sitemap?.id === user.id;
    if (inUserSitemap) {
      // also remove page sections
      if (page.pageSections) PageSectionsPagesToDelete.push(page.id)
      // also remove covers
      if (page.cover) firestoreCoversData.push({ [page.id]: firestore.FieldValue.delete() })
      // also remove comments - no longer need this as no comments in user sitemap at all
      // if (page.comments) firestoreCommentsData.push({ [page.id]: firestore.FieldValue.delete() })
    }
  });

  // remove subfolder tabs
  store.dispatch(removeSubfoldersFromTabs(subfolderTabsToRemove));

  /*** set new indexes for existing children of same parent ***/
  const childrenWithNewIndexes = [];
  if (!isEmpty(node.parent.children)) node.parent.children.forEach((d, i) => { if (d.id !== node.id) childrenWithNewIndexes.push({ id: d.id, index: i }) });
  /*** set new indexes for existing children of same parent ***/

  const pageChange = { id: new Date().getTime(), data: [...firestorePageData, ...childrenWithNewIndexes] };
  const coversToDelete = { id: new Date().getTime(), data: firestoreCoversData };
  // const commentsToDelete = { id: new Date().getTime(), data: firestoreCommentsData };

  const history = {
    action: 'remove',
    data: pagesToRemove,
    childrenWithOldIndexes, // for undo
    childrenWithNewIndexes // for redo
  };

  /*** merge page edits ***/
  const pagesObj = {};
  pagesToRemove.forEach(d => pagesObj[d.id] = undefined);
  childrenWithNewIndexes.forEach(c => pagesObj[c.id] = { index: c.index });
  // var pages = merge(sitemap?.docs.pages, pagesObj);
  store.dispatch(mergePagesEdits({ pages: pagesObj }))
  /*** merge page edits ***/

  setTimeout(() => {
    // save page changes
    store.dispatch(addPageChange({ change: pageChange, history }));
    // delete page sections
    if (!isEmpty(PageSectionsPagesToDelete)) handlePageSectionsFromPageChange('remove', { pages: PageSectionsPagesToDelete });
    // delete page covers
    if (!isEmpty(coversToDelete.data)) store.dispatch(addCoversChange({ change: coversToDelete, covers: { [node.id]: undefined } }));
    // delete page comments
    // if (!isEmpty(commentsToDelete.data)) store.dispatch(addCommentsChange({ change: commentsToDelete }));
  }, 500);
  // amplitude tracking
  // amplitude.getInstance().logEvent('SITEMAP_DELETED_PAGE');
  //
  store.dispatch(togglePageButtons({ showing: false }));
}

function getPagesToRemove(removedNode) {
  // get sitemap data
  const sitemap = getSitemap();
  //
  const data = [];
  function recurse(node) {
    // push id to array
    if (node.id !== 'home') {
      var obj = {
        id: node.id,
        name: getPageName(node.name),
        index: node.index,
        parent: node.parent ? node.parent.id : null,
        overflow: node.overflow,
        url: node.url,
        website_section: node.website_section
      };
      // get any page sections
      if (!isEmpty(sitemap?.data.page_sections[node.id])) obj.pageSections = sitemap?.data.page_sections[node.id];
      // get cover if from wireframe collection
      if (sitemap?.covers.pages[node.id]) {
        if (sitemap?.covers.pages[node.id].collection) obj.cover = sitemap?.covers.pages[node.id];
      }
      // get any comments
      if (sitemap?.comments[node.id]) obj.comments = sitemap?.comments[node.id];
      // push obj to data array
      data.push(obj);
    }
    if (node.children) node.children.forEach(recurse);
    if (node._children) node._children.forEach(recurse);
  }
  recurse(removedNode);
  return data;
}