import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItemOption,
    MenuOptionGroup,
    Button,
} from '@chakra-ui/react'
import { useState } from 'react'

export const JobFunction = ({ onChange }) => {
    const [selectedOption, setSelectedOption] = useState({ label: 'Select your job function...' })
    return (
        <Menu minW="full" matchWidth>
            <MenuButton as={Button} fontSize="sm" rightIcon={<ChevronDownIcon />} minW="full" fontWeight="normal" textAlign="left">
                {selectedOption?.label}
            </MenuButton>
            <MenuList>
                <MenuOptionGroup type='radio' value={selectedOption?.value}>
                    {data.map(option => {
                        return <MenuItemOption fontSize="sm" key={option.value} value={option.value} onClick={() => {
                            setSelectedOption(option)
                            onChange(option.value)
                        }}>{option.label}</MenuItemOption>
                    })}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    )
}

const data = [
    { label: "UX / UI Designer", value: "ux-ui" },
    { label: "Developer", value: "developer" },
    { label: "Content Strategy", value: "content-strategy" },
    { label: "Project Manager", value: "project-manager" },
    { label: "Product Owner / Manager", value: "product-owner-manager" },
    { label: "C-level / Founder", value: "c-level-founder" },
    { label: "Graphic Designer", value: "designer" },
    { label: "Technical SEO", value: "seo" },
    { label: "Other", value: "other" },
]