import { Box, Stack, StackDivider, Text } from "@chakra-ui/react"
import { MainHeader, SectionHeader } from "../../components"
import { Name } from "./Name"
import { Email } from "./Email"
import { UseCase } from "./UseCase"
import { ConnectedSocial } from "./ConnectedSocial"
import { Photo } from "./Photo"
import { Theme } from "./Theme"

export const YourAccount = () => {
    return (
        <Stack maxW="3xl">
            <Box mb={7}>
                <MainHeader title="Your account" />
                <Stack spacing={3.5} divider={<StackDivider />}>
                    <Photo />
                    <Name />
                    <Email />
                    <UseCase />
                    <ConnectedSocial />
                </Stack>
            </Box>
            <Theme />
        </Stack>
    )
}