import { FormControl, FormLabel, HStack, Heading, Input, Stack, Switch, Textarea, VStack } from "@chakra-ui/react"
import { RadioButton, RadioButtonGroup } from "../Shared/Components/RadioButtonGroup"
import { useState } from "react"
import { useFormikContext } from "formik"

export const SEO = () => {
    const { values, setFieldValue } = useFormikContext()
    const [isChecked, setIsChecked] = useState(values?.extractSEOTags === false ? false : true)
    return (
        <VStack w="lg" spacing={6} alignItems="start">
            <FormControl display='flex' alignItems='center'>
                <Switch isChecked={isChecked} defaultChecked={isChecked} id='extractSEOTags' onChange={() => setIsChecked(!isChecked)} />
                <FormLabel mb={0} ml={6} fontSize="md" fontWeight="semibold">
                    Extract SEO tags
                </FormLabel>
            </FormControl>
            {isChecked && (
                <>
                    <FormControl>
                        <Stack spacing={3}>
                            <Heading fontSize="sm" fontWeight="medium">Use page name from</Heading>
                            <RadioButtonGroup defaultValue="directory" size="sm">
                                {
                                    [
                                        { label: "Directory", value: "directory" },
                                        { label: "Page Title", value: "title" },
                                        { label: "H1 Tag", value: "h1" }
                                    ]
                                        .map(val => {
                                            return <RadioButton onClick={() => setFieldValue("pageNameSource", val.value)} key={val.value} value={val.value}>{val.label}</RadioButton>
                                        })
                                }
                            </RadioButtonGroup>
                        </Stack>
                    </FormControl>
                    <FormControl id="excludePageNameSourceText">
                        <VStack spacing={3} align="start">
                            <Heading fontSize="sm" fontWeight="medium">Exclude text from page name</Heading>
                            <Textarea bgColor="rarchy-bg-white" w="xl" placeholder="List one regular expression per line" size="sm" type="text" rounded="md" onChange={(e) => {
                                setFieldValue("excludePageNameSourceText", e.target.value)
                            }} />
                        </VStack>
                    </FormControl>
                </>
            )}
        </VStack>
    )
}