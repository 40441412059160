import React from 'react';
import { useDispatch } from 'react-redux';

import {
    IconButton,
} from "@chakra-ui/react";

import { AddIcon } from '@chakra-ui/icons';
import { addWebsiteSectionChange, mergeWebsiteSectionsEdits, toggleWebsiteSectionOptionsPopover } from '../../../../../../../store/actions/sitemap-actions';
import { createNodeId } from '../../../../../utils/app';
import { mapWebSectionsToArr } from '../index';

/*** popover ****/
export const Add = ({ WebsiteSectionOptionsPopover, website_sections, sitemap, user }) => {
    const dispatch = useDispatch();
    const { section } = WebsiteSectionOptionsPopover;
    return (
        <IconButton
            size="sm"
            variant="ghost"
            icon={<AddIcon fontSize="xs" />}
            onClick={() => {
                handleAddWebsiteSection({ newSectionIndex: section.index + 1, website_sections, dispatch })
            }}
        />
    )
};

export const handleAddWebsiteSection = ({ newSectionIndex, website_sections, dispatch }) => {

    // map to arr
    const websiteSectionsArr = mapWebSectionsToArr(website_sections);
    // old indexes
    const oldSectionIndexesForHistory = {};
    websiteSectionsArr.forEach((s, i) => { oldSectionIndexesForHistory[s.id] = { index: i } });
    //
    const newWebsiteSection = { id: createNodeId(), title: 'Website Section', index: newSectionIndex }
    websiteSectionsArr.splice(newWebsiteSection.index, 0, newWebsiteSection);
    const newWebsiteSectionsData = {};
    websiteSectionsArr.forEach((s, i) => {
        if (s.id === newWebsiteSection.id) { // new sections
            newWebsiteSectionsData[s.id] = { ...newWebsiteSection }
        } else { // existing sections
            newWebsiteSectionsData[s.id] = { index: i }
        };
    });

    /*** merge page edits ***/
    dispatch(mergeWebsiteSectionsEdits({ website_sections: newWebsiteSectionsData }));
    dispatch(toggleWebsiteSectionOptionsPopover({ showing: false }));
    /*** merge page edits ***/

    const newSectionIndexes = {};
    websiteSectionsArr.forEach((s, i) => newSectionIndexes[s.id] = { index: i });

    // save
    var obj = [];
    var websiteSectionKeys = Object.keys(newSectionIndexes);
    websiteSectionKeys.filter(k => k !== newWebsiteSection.id).forEach(sectionId => {
        obj.push({ action: 'website-section-reorder', id: sectionId, ...newSectionIndexes[sectionId] });
    });

    // new website section
    obj.push({ action: 'website-section-add', ...newWebsiteSection });

    // save
    const change = {
        id: new Date().getTime(),
        data: obj,
    };
    const history = {
        action: 'website-section-add',
        data: { section: newWebsiteSection, newSectionIndexes, oldSectionIndexes: oldSectionIndexesForHistory },
    };

    // add page change in redux
    dispatch(addWebsiteSectionChange({ change, history }));

    // add new page
    const node = {
        id: createNodeId(),
        name: 'Page',
        index: 0,
        website_section: newWebsiteSection.id,
        parent: { id: newWebsiteSection.id }
    };
    // saveNewPageChange(node); // not sure if we should automatically add new page

};

/* export const sendDataLayerEventForPageSections = (action, label) => {
    const obj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Page Section: ${action}`, label } };
    window.dataLayer.push({
        event: 'generic_ga_event',
        ...obj
    });
}; */