import { Button, HStack, Stack, Text, useBoolean } from "@chakra-ui/react";
import { useGetOrganization, useGetSubscription, useIsOrganizationAdmin } from "../../../../hooks";
import { BorderedBox, MainHeader } from "../../Components";
import { FaCcAmex, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { useFirebase } from "react-redux-firebase";
import { wait } from "../../../../../shared/helpers";
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper";

// payment_method_update

export const PaymentMethod = () => {
    const [isRemoving, setIsRemoving] = useBoolean()
    const [isUpdating, setIsUpdating] = useBoolean()
    const firebase = useFirebase()
    const organization = useGetOrganization()
    const subscription = useGetSubscription()
    const isAdmin = useIsOrganizationAdmin()

    const { customer_id, payment_card, paymentCard } = subscription || {};
    const { brand, last4, exp_month, exp_year } = (payment_card || paymentCard) || {};
    const name = brand === "amex" ? "American Express" : brand
    const month = new Date(exp_year, exp_month, 0).toLocaleString('default', { month: 'long' });
    return (
        <Stack spacing={6}>
            <MainHeader title={`Payment method for your team: ${organization?.name} `} />
            <BorderedBox p={4}>
                <Stack spacing={4}>
                    <HStack spacing={5} mt={-1}> {/*** adjusting for icon padding ***/}
                        <SkeletonWrapper w="fit-content">
                            {card_icons[brand?.toLowerCase()]}
                        </SkeletonWrapper>
                        <SkeletonWrapper w="fit-content">
                            <Text fontWeight="semibold" textTransform="capitalize" fontSize="lg">{`${name} •••• ${last4}`}</Text>
                        </SkeletonWrapper>
                    </HStack>
                    <SkeletonWrapper w="fit-content">
                        <Text fontSize="sm">{`Expires ${month} ${exp_year}`}</Text>
                    </SkeletonWrapper>
                    {isAdmin && (
                        <Stack direction="row" w="full" spacing={2}>
                            <SkeletonWrapper w="max-content">
                                <Button w="full" fontSize="sm" size="md" isLoading={isRemoving} isDisabled={isUpdating} onClick={async () => {
                                    setIsRemoving.toggle()
                                    try {
                                        const data = { customerId: customer_id };
                                        const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')(data)
                                        if (sessionURL?.data) window.location = sessionURL.data;
                                    } catch (e) {
                                        console.error(e);
                                    }
                                    await wait(1000)
                                    setIsRemoving.toggle()
                                }}>
                                    Remove
                                </Button>
                            </SkeletonWrapper>
                            <SkeletonWrapper w="max-content">
                                <Button w="full" fontSize="sm" size="md" isLoading={isUpdating} isDisabled={isRemoving} onClick={async () => {
                                    setIsUpdating.toggle()
                                    try {
                                        const data = { customerId: customer_id, flow_data: { type: "payment_method_update" } };
                                        const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')(data)
                                        if (sessionURL?.data) window.location = sessionURL.data;
                                    } catch (e) {
                                        console.error(e);
                                    }
                                    await wait(1000)
                                    setIsUpdating.toggle()
                                }}>
                                    Update
                                </Button>
                            </SkeletonWrapper>
                        </Stack>
                    )}
                </Stack>
            </BorderedBox>
        </Stack>
    )
}

const FONT_SIZE = "48px"

const card_icons = {
    'visa': <FaCcVisa fontSize={FONT_SIZE} />,
    'mastercard': <FaCcMastercard fontSize={FONT_SIZE} />,
    'amex': <FaCcAmex fontSize={FONT_SIZE} />,
    'jcb': <FaCcJcb fontSize={FONT_SIZE} />,
    'discover': <FaCcDiscover fontSize={FONT_SIZE} />
}