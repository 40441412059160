import { Avatar, AvatarGroup, Tooltip } from "@chakra-ui/react"
import { useGetFilteredPeopleData, useGetOrganization, useGetUserId, useInOrganization, useInProjectsGridView } from "../../../../hooks"

import { getUsersFullName } from "../../../../helpers"
import { isEmpty } from 'lodash'
import { toggleShareFileModal } from "../../../../store/actions/files-actions";
import { toggleShareFolderModal } from "../../../../store/actions/folders-actions";
import { useDispatch } from "react-redux";

const TooltipAvatar = (props) => {
    const { label, ...rest } = props
    return (
        <Tooltip hasArrow label={label} openDelay={250} variant="rounded-sm">
            <Avatar loading="lazy" {...rest} ignoreFallback />
        </Tooltip>
    )
};

export const SharedAvatarGroup = ({ folder, file, forRecent, forFolderTopbar }) => {

    const dispatch = useDispatch()

    let people = useGetFilteredPeopleData({ folder, file });
    const inOrganization = useInOrganization()
    const organization = useGetOrganization()
    const inGridView = useInProjectsGridView() || forRecent
    const userId = useGetUserId()

    const item = folder || file

    // file
    if (file) {
        if (people.length === 1) {
            return inGridView ? null : "--" // not shared with anyone
        }
    }
    // folder
    if (folder) {
        const isPrivateToUser = ((!inOrganization && folder?.members === null) || (!isEmpty(folder?.members) && (people.length === 1 && people[0]?.id === userId)))
        if (isPrivateToUser) {
            if (!forFolderTopbar) {
                return inGridView ? null : "--" // private to user 
            }
        }
    }

    return (
        !isEmpty(people)
            ?
            <AvatarGroup
                ml={-0.5}
                max={inGridView ? 3 : 5}
                size={forFolderTopbar ? "sm" : (inGridView ? "xxs" : "xs")}
                spacing="-9px"
            >
                {people?.map((user, i) => {
                    const isOrganization = user?.type === 'organization';
                    const name = isOrganization ? organization?.name : `${getUsersFullName(user)}`
                    const nameWithSuffix = /* isOrganization ? name : */ (`${name} ${user?.id === item?.createdBy ? '(Owner)' : (user?.access || folder) ? `(Can ${user?.access || 'edit'})` : ''}`)
                    return (
                        <TooltipAvatar
                            key={user?.id || i}
                            name={name}
                            src={user?.photoURL}
                            label={nameWithSuffix}
                            onClick={(e) => {
                                if (!forFolderTopbar) {
                                    e.stopPropagation();
                                    if (folder) dispatch(toggleShareFolderModal(folder))
                                    if (file) dispatch(toggleShareFileModal(file))
                                    e.preventDefault();
                                }
                            }} />
                    )
                })}
            </AvatarGroup>
            :
            null
    )
}