import { Stack, Text, Link as ChakraLink, Skeleton } from "@chakra-ui/react"
import { MainHeader, SectionHeader } from "../../Components"
import { Link } from "react-router-dom"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const Password = () => {
    return (
        <Stack spacing={5} pb={7}>
            <MainHeader title="Login" mb={3} />
            <Stack spacing={3}>
                <SectionHeader title="Password" />
                <Text fontSize="sm">
                    <SkeletonWrapper w="fit-content">
                    To reset your password, or add a password for the first time, you will need to use the <ChakraLink as={Link} to="/login/reset" textDecor="underline" color="rarchy-link">password reset page</ChakraLink> so we can verify your identity.
                    </SkeletonWrapper>
                </Text>
            </Stack>
        </Stack>
    )
}