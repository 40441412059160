import { Box, Button, Icon, Wrap, useColorMode } from "@chakra-ui/react";
import { useGetFoldersByKeyOrPath, useGetItems, useHasFolders, useInProjectsGridView } from "../../../hooks";

import { BsFolderPlus } from "react-icons/bs";
import { EmptyFolders } from "./Components/Empty";
import { FolderComponent } from "./Components/Folder";
import { ListRow } from "../Components/Sections/List";
import { SkeletonWrapper } from "../../Components/Shared/SkeletonWrapper";
import { getFolderThumbnail } from "../../../helpers";
import { toggleNewFolderModal } from "../../../store/actions/folders-actions";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

export const Folders = ({ tab }) => {
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const inGridView = useInProjectsGridView()
    // filters all folders under path (no options provided)
    const hasFolders = useHasFolders()
    const folders = useGetFoldersByKeyOrPath()
    const items = useGetItems()
    const data = useMemo(() => folders?.map(folder => {
        const folderItems = items?.filter(f => f.team === folder.id)
        const thumbnail = getFolderThumbnail(folderItems, colorMode)
        const count = folderItems?.length || 0;
        return { ...folder, thumbnail, count }
    }), [folders])
    if (tab && !['all', 'folders'].includes(tab?.id)) return null
    return (
        <>
            <EmptyFolders />
            {inGridView
                ? (
                    <Wrap spacingX={5} spacingY={2.5} align="center">
                        <SkeletonWrapper w="fit-content">
                            {hasFolders && (
                                <Box w="255px">
                                    <Button
                                        justifyContent="start"
                                        variant="outline"
                                        borderWidth="1.5px"
                                        pl={6}
                                        pr={10}
                                        iconSpacing={5}
                                        leftIcon={<Icon as={BsFolderPlus} color="fg.muted" fontSize="2xl" />}
                                        fontWeight="medium"
                                        size="lg"
                                        fontSize="sm"
                                        onClick={() => dispatch(toggleNewFolderModal())}
                                    >
                                        Create a folder
                                    </Button>
                                </Box>
                            )}
                        </SkeletonWrapper>
                        {data.map(folder => <FolderComponent key={folder?.id} folder={folder} thumbnail={folder.thumbnail} count={folder.count} />)}
                    </Wrap>
                )
                : data?.map(folder => <ListRow key={folder?.id} row={{ type: 'folder', ...folder }} />
                )}
        </>
    )
}