import { CheckIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons"
import { Avatar, Box, Button, Circle, Icon, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup, Tooltip, useColorModeValue as mode } from "@chakra-ui/react"

import { useRef, useState } from "react"
import { getAvatarSrc } from "../../../../../helpers"

import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"

import { HiOutlineOfficeBuilding, HiOutlinePhone } from "react-icons/hi"
import { HiOutlineComputerDesktop, HiOutlineBookOpen, HiOutlineCurrencyDollar, HiOutlineChartBar, HiOutlineShoppingCart } from "react-icons/hi2"
import { PiHandHeart } from "react-icons/pi"
import { MdOutlineHealthAndSafety } from "react-icons/md"
import { templates } from "../../../TemplatesHOC"

import { compact, uniqBy } from 'lodash'

export const Industry = () => {
    const inputRef = useRef()
    const [industry, setIndustry] = useState(null)
    const options = getOptions()
    const selected = options.find(o => o.key === industry)
    return (
        <Menu placement="bottom-start" autoSelect initialFocusRef={inputRef}>
            <SkeletonWrapper>
                <Tooltip label={selected?.title} variant="rounded-sm" placement="top" hasArrow openDelay={250}>
                    <MenuButton
                        as={Button}
                        onFocus={e => e.preventDefault()}
                        rightIcon={<ChevronDownIcon fontSize="xl" />}
                        pl={3.5}
                        pr={2.5}
                        variant="outline"
                        fontSize="sm"
                        fontWeight="normal"
                        _expanded={{ borderColor: 'rarchy-blue', bgColor: "rarchy-bg-white" }}
                    >
                        {selected?.title}
                    </MenuButton>
                </Tooltip>
            </SkeletonWrapper>
            <MenuList fontSize="sm" w="325px">
                <MenuOptionGroup
                    as={Box}
                    pt={0}
                    mt={1}
                    pb={1}
                    title={
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<SearchIcon color="gray.400" />}
                            />
                            <Input
                                ref={inputRef}
                                fontSize="sm"
                                placeholder="Search industry"
                                _placeholder={{ color: mode("gray.400", "whiteAlpha.700") }}
                            />
                        </InputGroup>
                    }
                    type='radio'
                >
                </MenuOptionGroup>
                <MenuDivider />
                <Box maxH="sm" overflow="scroll">
                    {options.map((o, i) => {
                        return (
                            <MenuItem
                                key={o.key || i}
                                py={2}
                                icon={
                                    !o.user ?
                                        <Circle
                                            w="40px"
                                            h="40px"
                                            bg="rarchy-bg-subtle-hover-200"
                                        >
                                            <Icon as={o.icon} fontSize={o.key === 'shared' ? "2xl" : "xl"} />
                                        </Circle> : <Avatar w="40px" h="40px" src={getAvatarSrc({ user: o.user })} />
                                }
                                command={industry === o.key ? <CheckIcon mr={2} fontSize="lg" /> : null}
                                onClick={() => {
                                    setTimeout(() => {
                                        setIndustry(o.key)
                                    }, 100);
                                }}
                            >
                                {o.label}
                            </MenuItem>
                        )
                    })}
                </Box>
            </MenuList>
        </Menu>
    )
}

export const TEMPLATE_INDUSTRY_ICONS = {
    'Education': HiOutlineBookOpen,
    'Software': HiOutlineComputerDesktop,
    'E-commerce': HiOutlineShoppingCart,
    'Non-profit': PiHandHeart,
    'Healthcare': MdOutlineHealthAndSafety,
    'Digital Marketing': HiOutlineChartBar,
    'Financial Services': HiOutlineCurrencyDollar,
    'Telecommunications': HiOutlinePhone
}

const getOptions = () => {
    const data = compact(uniqBy([...templates].map(t => {
        return {
            key: t.industry,
            title: t.industry,
            label: t.industry,
            icon: TEMPLATE_INDUSTRY_ICONS[t?.industry],
        }
    }), 'key'))
    return [
        { key: null, title: 'Industry', label: 'Any industry', icon: HiOutlineOfficeBuilding },
        ...data
    ]
}