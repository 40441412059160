import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useHasLoadedFiles, useInHomeScreen, useInOrganization, useInProjectsGridView, useInTrash, useIsViewingFolder } from "../../../../hooks";

import { ProjectsView } from "../ProjectsView";
import { Section } from "./Section";
import { SectionsGrid } from "./Grid";
import { SectionsList } from "./List";
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper";
import { SortBy } from "../Topbar/Menus/SortBy";

const PX = 2;
const PB = 2;
const MR = 5;

const tabs = [
    { id: 'all', title: 'All' },
    { id: 'folders', title: 'Folders' },
    { id: 'sitemaps', title: 'Sitemaps' },
    { id: 'user-flows', title: 'User Flows' },
    // { id: 'customer journeys' }
]

export const Sections = () => {
    const inTrash = useInTrash()
    const inHomeScreen = useInHomeScreen()
    const inOrganization = useInOrganization()
    const hasLoadedFiles = useHasLoadedFiles()
    const filteredTabs = tabs.filter(tab => {
        if (!hasLoadedFiles) return tab;
        const isFolders = tab.id === 'folders'
        return (isFolders && (!inOrganization || (inHomeScreen || inTrash))) ? null : tab
    })
    const isViewingFolder = useIsViewingFolder()
    const inGridView = useInProjectsGridView()
    return (
        <Tabs isLazy>
            {!inHomeScreen && (
                <TabList borderColor="rarchy-border" borderBottomWidth={(!hasLoadedFiles || (isViewingFolder && inGridView)) ? 0 : 1}>
                    {filteredTabs?.map(tab => {
                        return (
                            <Tab key={tab.id} fontWeight="medium" px={PX} pb={PB} mr={MR} borderBottomWidth={hasLoadedFiles ? 1 : 0} mb="-1px">
                                <SkeletonWrapper w="fit-content">
                                    {tab.title}
                                </SkeletonWrapper>
                            </Tab>
                        )
                    })}
                </TabList>
            )}
            {isViewingFolder && inGridView && (
                <Stack
                    direction="row"
                    mt={7}
                    mb={-6}
                    w="full"
                    align="center"
                    justify="space-between"
                    borderBottom={`${hasLoadedFiles ? 1 : 0}px solid`} borderColor="rarchy-border"
                >
                    <SortBy />
                    <ProjectsView showInSections />
                </Stack>
            )}
            <TabPanels>
                {filteredTabs?.map((tab) => {
                    const isAll = tab.id === 'all' || inHomeScreen
                    return (
                        <TabPanel
                            key={tab.id}
                            px={0}
                            pt={!inGridView ? (isViewingFolder ? 0 : 3) : isViewingFolder ? 9 : 3}
                            pb={6}
                            mt={isViewingFolder && !inGridView ? -1 : 0}
                        >

                            {/*** ALL TAB ***/}
                            {isAll && filteredTabs.map(section => <Section key={section?.id} section={section} />)}
                            {/*** ALL TAB ***/}

                            {/*** ALL OTHER TABS ***/}
                            {inGridView && <SectionsGrid tab={tab} />}
                            {!inGridView && <SectionsList tab={tab} />}
                            {/*** ALL OTHER TABS ***/}

                        </TabPanel>
                    )
                })}
            </TabPanels>
        </Tabs>
    )
}