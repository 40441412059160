import React from 'react';
import { useDispatch } from 'react-redux';

import { TriggerButton } from './Components/TriggerButton'

import { toggleCaptureDrawer } from '../../../../store/actions/sitemap-actions';
import { useCheckIfAutocapturing } from '../../../Sitemap/app/covers/Capture/hooks';
import { useGetSitemap, useCanFullyEditFile, useHasEditorLoaded } from '../../../../hooks';
import { EditorSkeletonWrapper } from '..';


export const Capture = () => {

    const dispatch = useDispatch()

    // autocapturing
    const isAutocapturing = useCheckIfAutocapturing()
    // can continue
    const canFullyEdit = useCanFullyEditFile()
    const sitemap = useGetSitemap()
    // continue
    const showButton = canFullyEdit && sitemap?.showCovers;

    if (!showButton) return null;

    return (
        <EditorSkeletonWrapper>
            <TriggerButton
                onOpen={() => dispatch(toggleCaptureDrawer())}
                sitemap={sitemap}
                isAutocapturing={isAutocapturing}
            />
        </EditorSkeletonWrapper>
    )
}