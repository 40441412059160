import { store } from '../../../../../../store';
import { getFirestore } from 'redux-firestore';
import { updatePageSectionsData, addPageSectionChange } from '../../../../../../store/actions/sitemap-actions';
import { getSitemap } from '../../../../../../helpers';
import { cloneDeep, omitBy, pick, isUndefined } from 'lodash'

export const hitPageSectionsLimit = (newCount) => {
    if (newCount > 3) return true;
    return false;
};

export const handlePageSectionsFromPageChange = (action, data) => {
    const sitemap = getSitemap();
    const { pages } = data;
    const firestore = getFirestore();
    var sections = cloneDeep(sitemap?.data.page_sections );
    var firestoreData = {};
    switch (action) {
        case 'remove':
            // remove
            pages.forEach(pageId => {
                delete sections[pageId];
                firestoreData[pageId] = firestore.FieldValue.delete();
            });
            break;
        case 'clone':
            Object.keys(pages).forEach(pageId => {
                sections[pageId] = pages[pageId];
                if (!firestoreData[pageId]) firestoreData[pageId] = {};
                pages[pageId].forEach(section => firestoreData[pageId][section.id] = cleanPageSectionDataForFirestore(section));
            });
            break;
        default:
            break;
    };
    /*** render immediately ***/
    store.dispatch(updatePageSectionsData(sections));
    /*** render immediately ***/
    /*** add change to update firestore ***/
    const pageSectionChange = { id: new Date().getTime(), data: [firestoreData] };
    store.dispatch(addPageSectionChange({ change: pageSectionChange }));
    /*** add change to update firestore ***/
}

export const cleanPageSectionDataForFirestore = (section) => {
    return omitBy(pick(section, ['index', 'title', 'color', 'wireframe']), isUndefined);
}