import { Box, DarkMode, Heading, Stack, useColorModeValue as mode, Image } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../../hooks"
import { getCollectionLabelFromDoc, toTitleCase } from "../../../../helpers"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../../shared/hooks/pricing"

const LightImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Light-1080.png"
const DarkImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Dark-1080.png"

const UserFlowsImage = "https://help.rarchy.com/hubfs/User%20Flows%20-%20Light-min%20-%201080.png"

export const RightNewFile = () => {
    const { planId, screen } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    const collection = screen.split("_")?.[1]
    const itemLabel = getCollectionLabelFromDoc({ collection })
    const isUserFlows = collection === 'user-flows'
    return (
        <Box key="new-file" w="full" h="full" bgColor="rarchy-bg-upgrade-modal" p={6} py={12} align="center">
            <DarkMode>
                <Stack spacing={10} align="center">
                    <Image
                        src={mode(isUserFlows ? UserFlowsImage : LightImage, isUserFlows ? UserFlowsImage : DarkImage)}
                        w={isUserFlows ? "80%" : "63%"}
                    />
                    <Stack spacing={6} px={12}>
                        <Heading fontSize="2xl" color="fg.default">{`Create unlimited ${itemLabel}s with Rarchy ${toTitleCase(planId)}`}</Heading>
                        <Box color="fg.muted" fontSize="sm">
                            {`${canStartNewTrial ? `Start a free ${TRIAL_DAYS}-day trial of` : 'Upgrade to' } Rarchy ${toTitleCase(planId)} to create unlimited sitemaps and user flows.`}
                        </Box>
                    </Stack>
                </Stack>
            </DarkMode>
        </Box>
    )
}