import { Avatar, Box, HStack, VStack } from "@chakra-ui/react"
import { useGetPhotoURL } from "../../../hooks"
import { getUser, getUsersFullName } from "../../../helpers"
import { SkeletonCircleWrapper, SkeletonWrapper } from "./SkeletonWrapper"

export const UserAvatarDetail = ({ avatarSize, user, nameSize, emailSize, nameWeight, emailColor, w }) => {
    user = user || getUser()
    const name = getUsersFullName(user)
    const photoURL = useGetPhotoURL(user)
    return (
        <HStack spacing={4} alignItems="center">
            <SkeletonCircleWrapper>
                <Avatar
                    size={avatarSize || "md"}
                    objectFit="cover"
                    name={name}
                    src={photoURL}
                />
            </SkeletonCircleWrapper>
            <VStack alignItems="start" spacing={2}>
                <SkeletonWrapper>
                    <VStack spacing={nameSize === "sm" ? 0 : 0.5} alignItems="start">
                        <Box
                            color="fg.default"
                            fontWeight={nameWeight || "semibold"}
                            title={name} w={w || "156px"}
                            noOfLines={1}
                            fontSize={nameSize || "md"}
                        >
                            {name}
                        </Box>
                        <Box
                            fontSize={emailSize || "sm"}
                            title={user.email}
                            fontWeight="normal"
                            color={emailColor || "fg.subtle"}
                            w={w || "156px"}
                            noOfLines={1}
                        >
                            {user.email}
                        </Box>
                    </VStack>
                </SkeletonWrapper>
            </VStack>
        </HStack>
    )
}