import { collection, getDocs, getFirestore, query, where } from "firebase/firestore"
import { compact, join } from 'lodash';
import { getAccountId, getFolderIdFromPath, getUserId } from '../../helpers';
import { getBlob, getMetadata, getStorage, ref } from 'firebase/storage';

import { chain } from "../../helpers/chain";
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { toggleNewSitemapModal } from './ui-actions';
import updateLocale from 'dayjs/plugin/updateLocale';
import { wait } from "../../../shared/helpers";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

/*** Importing Sitemaps ***/
export const getImportedSitemaps = () => {
  return (dispatch, getState) => {
    const firestore = getFirestore()
    const userId = getUserId()
    const accountId = getAccountId()
    dispatch({
      type: 'GET_IMPORTED_SITEMAPS',
      async payload() {
        await wait(1000)
        const importedRef = collection(firestore, 'sitemaps_imported')
        const querySnapshot = await getDocs(query(importedRef, where("createdBy", "==", userId), where("account", "==", accountId)));
        const arr = []
        querySnapshot?.forEach(async (doc) => {
          arr.push({ id: doc.id, ...doc.data() })
        });
        const byId = await chain(arr).keyBy('id').value();
        return { byId }
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

export const mergeImportedSitemapById = (data, importing, showingDetailScreen) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'MERGE_IMPORTED_SITEMAP_BY_ID',
      data,
    })
    if (showingDetailScreen && importing?.processed) dispatch(clearProcessedSitemap(importing))
  };
};

export const getImportedSitemapPages = ({ detailId, isEmpty }) => {
  return (dispatch, getState) => {
    const { byId } = { ...getState()?.sitemaps?.imported } || {}
    if (!byId[detailId]) byId[detailId] = {}
    dispatch({
      type: 'GET_IMPORTED_SITEMAP_PAGES',
      async payload() {
        byId[detailId].pages = await getImportPagesDataFromStorage({ imported: byId?.[detailId], isEmpty })
        return { byId }
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

export const getImportPagesDataFromStorage = async ({ importing, imported, isEmpty }) => {

  const storage = getStorage()
  const importToUse = ((imported || importing) || {})
  const importId = importToUse?.id
  let data = importToUse?.pages?.data || []
  if (importId && !isEmpty) {
    const importedRef = ref(storage, `sitemaps_imported/${importId}/data.json`)
    const importingRef = ref(storage, `sitemaps_processing/${importId}/progress.json`)

    // try and get path reference normally, if there is an error, try and get importing (workaround for race condition when sitemaps finished importing)

    const pathReferenceToUse = await (async () => {
      let refToUse;
      // imported
      try {
        if ((imported || importing?.processed)) {
          if (await getMetadata(importedRef)) refToUse = importedRef
        }
      } catch (e) {
      } finally {
        if (await getMetadata(importingRef)) refToUse = importingRef // processing
      }
      return refToUse;
    })()

    const blob = pathReferenceToUse ? await (async () => {
      let data;
      data = await getBlob(pathReferenceToUse).catch(async e => {
        // console.error(e); // don't need to console error
        data = await getBlob(importedRef)
      })
      return data
    })() : null

    const pages = blob ? JSON.parse(await blob.text()) : data
    pages?.sort((a, b) => (a.url > b.url) ? 1 : ((b.url > a.url) ? -1 : 0)) // sort pages
    data = pages;

  }

  return { data, loading: false, loaded: true };
}
/*** Importing Sitemaps ***/

let clearingCount = 0; // to fix issue with clearProcessedSitemap being sent twice immediately
export const clearProcessedSitemap = (processing) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    dispatch({
      type: 'CLEAR_PROCESSED_SITEMAP',
      async payload() {
        // delete processing sitemap
        if (processing?.processed) {
          if (clearingCount === 0) {
            clearingCount++;
            await firestore.doc(`sitemaps_processing/${processing.id}`).delete();
            clearingCount = 0;
          }
        }
      },
    }).catch(e => {
      console.error(e);
      clearingCount = 0;
    });
  };
};

/*** END OF NEW ***/

export const processNewSitemap = (sitemapDoc) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const userId = getUserId()
    const accountId = getAccountId()

    const { importId, ...rest } = sitemapDoc

    dispatch({

      type: 'PROCESS_NEW_SITEMAP',
      meta: { sitemap: { ...rest } },
      async payload() {

        // ensure url is trimmed (if there is one)
        if (rest.url) rest.url = rest.url.trim()

        /*** ensure same domain if importing into existing sitemap ***/
        /* if (sitemapDoc.sitemapId) {
          // importing into sitemap without a domain
          if (!sitemap?.domain) {
            // set domain from imported URL
            if (sitemapDoc.url) await firestore.doc(`sitemaps/${sitemapDoc.sitemapId}`).set({ domain: getDomainFromURL(sitemapDoc.url) }, { merge: true });
          } else {
            // sitemap domain and importing domain don't match
            if (sitemapDoc.url && (getDomainFromURL(sitemap?.domain, true) !== getDomainFromURL(sitemapDoc.url, true))) {
              throw new Error('different-domain')
            }
          }
        } */
        /*** ensure same domain if importing into existing sitemap ***/

        const data = {
          createdAt: new Date(),
          createdBy: userId,
          team: getFolderIdFromPath({ returnAccountId: true }),
          account: accountId,
          ...rest
        };
        // delay for loading
        await wait(500)
        // process sitemap
        await firestore.doc(`sitemaps_processing/${importId}`).set(data)
        // go to detail screen in sitemap import modal
        dispatch(toggleNewSitemapModal({ showing: true, showDetailScreen: true, detailId: importId }))
        // return data
        return { sitemap: { ...rest } }
      },
    }).catch(e => {
      console.error(e)
    });
  };
};

/*** sitemaps view ***/

// rejig this for files
export const getSitemapsView = () => {
  const hasView = localStorage.getItem(`sitemaps-view`);
  return (hasView && (hasView === 'list' || hasView === 'grid')) ? hasView : 'list';
};

/*** sitemaps view ***/

export const getDomainFromURL = (url, noProtocol) => {
  var urlParts = url.replace('http://', '').replace('https://', '').split(/[/?#]/);
  var domain = urlParts[0];
  if (noProtocol) return domain;
  return `https://${domain}`;
};

export const getPathnameFromURL = (url = "") => {
  var urlParts = compact(url?.replace('http://', '')?.replace('https://', '')?.split(/[/?#]/))
  const withoutDomain = urlParts.splice(1);
  if (withoutDomain.length > 0) return `/${join(withoutDomain, "/")}`
};