import { getCollectionLabelFromDoc, getEditorCollectionFromPath } from "../../../../../helpers"
import { ChecklistItem } from "."

export const RestoreFiles = ({ forConfirmed }) => {
    const itemLabel = getCollectionLabelFromDoc({ collection: getEditorCollectionFromPath() })
    return (
        <ChecklistItem forConfirmed={forConfirmed}>
            {`Recover previously deleted ${itemLabel}s with one-click restores up to 30 days after deletion.`}
        </ChecklistItem>
    )
}