import { createRoot, update } from '../../screens/Sitemap/utils/app';
import { endRenamePageSection, handleRenamePage } from '../../screens/Sitemap/app/canvas/components/text/utils';
import { getCanvasTextEditor, getEditor, getEditorCollectionFromPath, getEditorDocIdFromPath, getInSitemap, getInUserFlow, getIsLinkedUserFlowPage, getItemNameFromDoc, getUser, getUserId } from '../../helpers';
import { groupBy, isEmpty, keys, mapKeys, sortBy, uniqBy } from 'lodash';
import { initSitemap, initSitemapSectionsDoc, setRoot, togglePageButtons, whitelistPageSectionKeys, whitelistPagesKeys, whitelistWebsiteSectionKeys } from './sitemap-actions';
import { initUserFlow, whitelistElementsKeys } from './flow-actions';
import { renderInteractionsCanvas, resizeCanvas } from '../../screens/Sitemap/app/canvas/canvases';

import Values from 'values.js';
import { chain } from '../../helpers/chain';
import copy from 'fast-copy';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import { render as renderPages } from '../../screens/Sitemap/app/canvas/render';
import { render as renderUserFlow } from '../../screens/Sitemap/user-flows/render'
import { showEditorPermissionsToast } from '../../screens/Editor/App/Components/PermissionsToast';
// import amplitude from 'amplitude-js/amplitude';
import { store } from '..';
import { useGetEditorDocIdFromPath } from '../../hooks';
import { wait } from '../../../shared/helpers';

const render = () => {
    const inUserFlow = getInUserFlow()
    !inUserFlow ? renderPages() : renderUserFlow()
}

export const initEditor = ({ isNew }) => {
    return (dispatch, getState) => {
        const state = getState();
        const firestore = getFirestore();
        const editorDocId = useGetEditorDocIdFromPath()
        const collection = getEditorCollectionFromPath()
        const doc = state?.files?.data?.find(doc => doc.id === editorDocId && doc.collection === collection);
        // do we already have doc
        dispatch({
            type: 'INIT_EDITOR',
            meta: {
                id: editorDocId,
                name: getEditorDocName(doc?.name),
                collection // NEED THIS FOR NAVBAR RENAME
            },
            async payload() {
                if (!isNew) {
                    if (!doc) {
                        const editorDoc = await firestore.doc(`${collection}/${editorDocId}`).get();
                        if (editorDoc.exists) {
                            return editorDoc.data();
                        } else {
                            throw new Error('not-found');
                        }
                    } else {
                        return doc;
                    }
                }
            }
        }).then(({ value: doc }) => {
            resizeCanvas() // need this here to ensure that all canvases resize on load
            return doc;
        }).then((doc) => {
            dispatch(initEditorDoc(doc));
        }).catch(e => { console.error(e); return { error: e } });
    };
};

export const initEditorDoc = (doc) => {
    return (dispatch, getState) => {

        const inSitemap = getInSitemap()
        const inUserFlow = getInUserFlow()

        const user = getUser()
        const editor = getEditor()

        const docId = getEditorDocIdFromPath()
        //
        const error = doc?.archived && 'archived' || null // not-found will have been picked up in init_editor
        // meta
        const meta = {
            id: docId,
            name: doc?.name, // || 'New Sitemap',
            team: doc.team,
            createdBy: doc.createdBy,
            updatedBy: doc.updatedBy,
            sitemap: doc.sitemap,
            domain: doc.domain,
            updatedAt: doc.updatedAt ? typeof doc.updatedAt?.toDate === 'function' ? doc.updatedAt.toDate() : doc.updatedAt : new Date(),
            thumbnail: doc.thumbnail || { images: { light: null, dark: null } },
            pallette: getPallette(doc),
            colors: doc?.colors,
            collaborators: doc?.collaborators,
            editLevel: editor?.editLevel,
            error,
            archived: doc.archived,
            archivedAt: doc.archived ? (doc.archivedAt ? typeof doc.archivedAt?.toDate === 'function' ? doc.archivedAt.toDate() : doc.archivedAt : new Date()) : null,
            deleteAt: doc.archived ? (doc.deleteAt ? typeof doc.deleteAt?.toDate === 'function' ? doc.deleteAt.toDate() : doc.deleteAt : null) : null
        }
        // dispatch
        dispatch({
            type: 'INIT_EDITOR_DOC',
            meta,
            async payload() {

                if (error) throw new Error(error); // has to be to reject properly

                // amplitude tracking
                // amplitude.getInstance().logEvent('VIEWED_EDITOR', { id: editor?.id });

                let payload = { editLevel: null }

                /*** set editor edit access ***/
                // member of sitemaps team
                if (user?.token?.claims?.team === doc?.team) {
                    payload.editLevel = 'full';
                    return payload;
                }
                // user is an external collaborator
                const collaborator = doc?.collaborators?.[user.id] || {}
                payload.editLevel = collaborator?.access || null
                /*** set editor edit access ***/

                await wait(1000);

                return payload;

            },
        }).then(() => {
            if (inSitemap) dispatch(initSitemap({ id: docId, ...meta })) // false = new sitemap
            if (inUserFlow) dispatch(initUserFlow({ id: docId, ...meta, docs: { elements: doc.data || {} } }))
        }).catch(e => { console.error(e); return { error: e } });
    };
};

function getEditorDocName(name) {
    if (name) return name;
    const collection = getEditorCollectionFromPath()
    return `Loading ${((getItemNameFromDoc({ collection })) || '').toLowerCase()}...`;
}

export const leftEditor = editorDocId => {
    return (dispatch, getState) => {
        // amplitude.getInstance().logEvent('LEFT_EDITOR', { id: editorDocId }); // amplitude tracking
        dispatch({ type: 'LEFT_EDITOR' });
    };
};

export const updateEditLevel = editLevel => {
    return async (dispatch, getState) => {
        dispatch({ type: 'UPDATE_EDIT_LEVEL', editLevel });
    }
}

export const setZoomLevel = (zoomLevel) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_ZOOM_LEVEL', zoomLevel })
    };
};

export const changeEditorPallette = pallette => {
    return async (dispatch, getState) => {
        const { editor } = getState();
        pallette = { ...editor?.pallette, ...pallette, colors: getPalletteColors(pallette.header) };
        dispatch({ type: 'CHANGE_EDITOR_PALLETTE', pallette });
        update();
    };
};

const palletteColors = {}; // cache
export const getPalletteColors = (header) => {
    if (palletteColors[header]) return palletteColors[header];
    const color = new Values(header);
    var colors = color.all(5).map(c => c.hex.toUpperCase());
    const pallette = [`#${colors[2]}`, `#${colors[5]}`, `#${colors[8]}`, `#${colors[12]}`, `#${colors[14]}`, `#${colors[17]}`, `#${colors[20]}`, `#${colors[23]}`];
    palletteColors[header] = pallette;
    return pallette;
}

export const getPallette = (doc) => {
    const { editor } = store.getState();
    if (!doc || (doc && isEmpty(doc.pallette))) return { ...editor?.pallette, colors: getPalletteColors(editor?.pallette?.header) };
    return { ...editor?.pallette, ...doc.pallette, colors: getPalletteColors(doc.pallette.header) };
};

/*** REVISION HISTORY ***/
export const toggleRevisionHistoryDrawer = () => {

    return (dispatch, getState) => {

        const { editor, flow } = getState()
        const { RevisionHistoryDrawer } = editor?.ui;

        const inUserFlow = getInUserFlow()
        // user flow logic needs to be here
        if (inUserFlow && !flow?.loaded) return;

        const { showing } = RevisionHistoryDrawer

        // dispatch
        dispatch({ type: 'TOGGLE_REVISION_HISTORY_DRAWER', showing: !showing });
        // send to GA

        if (!showing) {
            const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Revision History: Opened Drawer` } };
            window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
        }

    };
};

export const retrieveRevisionHistoryDrawerChanges = () => {
    return (dispatch, getState) => {
        const { editor, sitemap } = getState();
        const { RevisionHistoryDrawer } = editor?.ui || {};
        const docType = getEditorCollectionFromPath()
        const docId = editor?.id;
        if (!docId) return;
        dispatch({
            type: 'RETRIEVE_REVISION_HISTORY_DRAWER_CHANGES',
            async payload() {

                const state = { changes: RevisionHistoryDrawer.changes, editors: RevisionHistoryDrawer.editors }; // initial state
                // get refs
                let deltaRef = getFirebase().storage().ref().child(`/${docType}/${docId}/history/changes.json`);
                // doc doesn't exist
                const deltaDownloadURL = await deltaRef.getDownloadURL().catch(e => {
                    if (e.code === 'storage/object-not-found') {
                        const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: "Revision History: List Empty" } };
                        window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
                        // throw e;
                    }
                });
                // get changes
                let changes = [];
                if (deltaDownloadURL) {
                    changes = await fetch(deltaDownloadURL, { headers: { 'Accept': 'application/json' } }).then(resp => {
                        if (resp.ok) return resp.json()
                    }).then(async data => {
                        // convert all timestamps to Firestore
                        data.map(d => { d.updatedAt = new store.firestore.Timestamp(d.updatedAt._seconds, d.updatedAt._nanoseconds).toDate(); return d; })
                        // get user ids from all changes
                        const userIds = keys(groupBy([...data], d => d.editedBy));
                        // get all details for users
                        const userDetails = await getAllHistoryUsers(sitemap, state.editors, userIds);
                        // merge userDetails into existing editors object
                        state.editors = { ...state.editors, ...userDetails }
                        // transform object to get required details, and dedup by id
                        const obj = uniqBy(data.map((d, i) => {
                            const { editedBy } = d;
                            const { id, firstName, lastName, email, photoURL } = state.editors[editedBy];
                            return { id: d.updatedAt.getTime(), ...d, updatedAt: d.updatedAt.getTime(), editedBy: { id, firstName, lastName, email, photoURL } }
                        }), 'id');
                        // send to GA
                        const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Revision History: List Changes`, label: data.length } };
                        window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
                        //
                        return obj;
                    });
                }

                // merge change arrays with existing data
                state.changes = sortBy(uniqBy([...state.changes, ...changes], 'id'), 'updatedAt').reverse();

                // set initial root if no existing changes
                if (isEmpty(RevisionHistoryDrawer.changes)) state.data = { root: sitemap?.data?.root };

                return state;
            },
        }).catch(e => { console.error(e) });
    };
};

export const setRevisionHistoryDrawerSelectedIndex = ({ selectedIndex, pages, website_sections, sections, elements, root }) => {
    return (dispatch, getState) => {
        // send to GA
        const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Revision History: Selected Change`, label: selectedIndex } };
        window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
        // dispatch
        dispatch({ type: 'SET_REVISION_HISTORY_DRAWER_SELECTED_INDEX', selectedIndex, pages, website_sections, sections, elements, root });
    };
};

export const restoreRevisionHistoryDrawerChanges = () => {

    return (dispatch, getState) => {

        const { editor, sitemap, flow, user } = getState();
        const { RevisionHistoryDrawer } = editor?.ui;

        const inUserFlow = getInUserFlow()
        const firestore = getFirestore();

        const pagesSavedInFirestore = sitemap?.docs.filesystem['pages'] === 'firestore';

        dispatch({
            type: 'RESTORE_REVISION_HISTORY_DRAWER_CHANGES',
            async payload() {

                await wait(1000);

                let pages, website_sections, sections;

                if (!inUserFlow) {

                    // NEED TO SET THIS UP FOR STORAGE PAGES TOO!!!!!!
                    /*** get pages data ***/
                    pages = copy(RevisionHistoryDrawer.docs.pages);
                    // take opportunity to clean up doc (can delete empty as we're over-riding entire doc)
                    mapKeys(pages, (v, k) => { if (isEmpty(v)) delete pages[k]; });
                    // ensure only whitelisted keys are saved to firestore
                    pages = whitelistPagesKeys(pages)
                    /*** get pages data ***/

                    /*** get websiteSections data ***/
                    website_sections = copy(RevisionHistoryDrawer.docs.website_sections);
                    // take opportunity to clean up doc (can delete empty as we're over-riding entire doc)
                    mapKeys(website_sections, (v, k) => { if (isEmpty(v)) delete website_sections[k]; });
                    // ensure only whitelisted keys are saved to firestore
                    website_sections = whitelistWebsiteSectionKeys(website_sections)
                    /*** get websiteSections data ***/

                    /*** get page sections data ***/
                    sections = copy(RevisionHistoryDrawer.docs.sections)
                    // take opportunity to clean up doc (can delete empty as we're over-riding entire doc)
                    mapKeys(sections, (v, k) => { if (isEmpty(v)) delete sections[k]; });
                    // ensure only whitelisted keys are saved to firestore
                    sections = whitelistPageSectionKeys(sections)
                    /*** get page sections data ***/

                    // save page changes
                    if (JSON.stringify(sitemap?.docs?.pages) !== JSON.stringify(pages)) {
                        if (!isEmpty(pages)) {
                            await firestore.doc(`sitemaps/${sitemap?.id}/data/pages`).set({ lastEdit: user.id, pages }, { merge: !pagesSavedInFirestore });
                        }; // ONLY MERGE IF PAGES SAVED IN FILE STORAGE
                    }

                    // save website section changes
                    if (JSON.stringify(sitemap?.docs?.website_sections) !== JSON.stringify(pages)) {
                        if (!isEmpty(website_sections)) {
                            await firestore.doc(`sitemaps/${sitemap?.id}/data/website_sections`).set({
                                lastEdit: user.id, data: website_sections
                            });
                        }; // REMEMBER THAT WE ARE OVER-RIDING THE WHOLE DOCUMENT WITH SET
                    }

                    // save page section changes
                    if (JSON.stringify(sitemap?.docs?.sections) !== JSON.stringify(sections)) {
                        if (!isEmpty(sections)) {
                            await firestore.doc(`sitemaps/${sitemap?.id}/data/sections`).set({ lastEdit: user.id, pages: sections });
                        };
                    }
                };

                let elements;
                if (inUserFlow) {

                    // ensure only whitelisted keys are saved to firestore
                    elements = whitelistElementsKeys(copy(RevisionHistoryDrawer.docs.elements));
                    // take opportunity to clean up doc (can delete empty as we're over-riding entire doc)
                    mapKeys(elements, (v, k) => { if (isEmpty(v)) delete elements[k]; });

                    // save website section changes
                    if (JSON.stringify(flow?.docs.elements) !== JSON.stringify(elements)) {
                        if (!isEmpty(elements)) { await firestore.doc(`user-flows/${flow?.id}/data/elements`).set({ elements, lastEdit: user.id }); }; // REMEMBER THAT WE ARE OVER-RIDING THE WHOLE DOCUMENT WITH SET
                    }

                }

                return { pages, website_sections, sections, elements }

            },
        }).then(async ({ value }) => {

            if (!inUserFlow) {

                const { pages, sections } = value;

                if (pages) {

                    const pagesForRoot = chain(pages).map((obj, key) => { return { ...obj, id: key } }).value();
                    const root = await createRoot(pagesForRoot);

                    dispatch(setRoot(root)) // set pages
                    dispatch(initSitemapSectionsDoc({ pages: sections })) // set page_sections
                }

                // send to GA
                const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Revision History: Restored Version`, label: RevisionHistoryDrawer.selectedIndex } };
                window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });

            }
        }).then(() => {
            setTimeout(() => dispatch(toggleRevisionHistoryDrawer()), 1000);
        }).catch(e => { console.error(e); return { error: e } });
    };
};

const getAllHistoryUsers = async (sitemap, editors, userIds) => {
    const members = sitemap?.members ? sitemap?.members : [];
    const usersObj = {};
    // get users
    await Promise.all([...userIds].map(async userId => {
        // already in list of editors
        if (editors[userId]) return editors[userId];
        // see if user exists in sitemap members first
        const userIsMember = find(members, (member) => member.id === userId);
        if (userIsMember) {
            usersObj[userId] = userIsMember;
        } else {
            // user is import
            if (userId === 'import') return usersObj[userId] = { id: userId, firstName: "Imported", lastName: "Pages" };
            // doesn't exist in members - need to get their details
            const user = await store.firestore.doc(`users/${userId}`).get();
            if (!user.exists) return usersObj[userId] = { id: userId, firstName: "Deleted", lastName: "User" };
            const { firstName, lastName, email, photoURL } = user.data();
            usersObj[userId] = { id: userId, firstName, lastName, email, photoURL };
        }
    }));
    // return data
    return usersObj;
}
/*** REVISION HISTORY ***/

export const toggleCanvasTextEditor = (props) => {
    return (dispatch, getState) => {
        // 
        const { sitemap, flow } = getState()
        const inUserFlow = getInUserFlow()

        const { node } = props
        // check to see if user can rename sitemap page within userflow
        const provideDoc = (!inUserFlow || getIsLinkedUserFlowPage(node)) ? sitemap : flow
        // can edit
        const canEdit = showEditorPermissionsToast({ doc: provideDoc }); if (!canEdit) return;
        // continue
        const CanvasTextEditor = getCanvasTextEditor()
        /*** save ***/
        if (props.showing === false) handleCanvasEditorTextChange(CanvasTextEditor)
        /*** save ***/
        dispatch({ type: 'TOGGLE_CANVAS_TEXT_EDITOR', ...props })
        dispatch(togglePageButtons({ showing: false }))
        render();
    };
};

export const setCanvasTextEditorInputVal = (newString) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CANVAS_TEXT_EDITOR_INPUT_VAL', newString })
        renderInteractionsCanvas()
    };
};

const handleCanvasEditorTextChange = (CanvasTextEditor) => {
    var { node, section, newString } = CanvasTextEditor || {}
    if (section) {
        endRenamePageSection(node, section, newString)
    } else {
        handleRenamePage(node, newString)
    }
}

export const saveColorLabelChange = colors => {
    return async (dispatch, getState) => {

        const userId = getUserId()

        const collection = getEditorCollectionFromPath()
        const docId = getEditorDocIdFromPath()

        const firestore = getFirestore();

        dispatch({
            type: 'SAVE_COLOR_LABEL_CHANGE',
            meta: colors,
            async payload() {

                /*** ensure FieldValue.delete is set if deleting label ***/
                let colorsObjForFirestore = { ...colors }
                Object.keys(colorsObjForFirestore)?.forEach(c => {
                    if (colorsObjForFirestore[c] === undefined) colorsObjForFirestore[c] = firestore.FieldValue.delete()
                })
                /*** ensure FieldValue.delete is set if deleting label ***/
                
                await firestore.doc(`${collection}/${docId}`).set({ colors: colorsObjForFirestore, updatedBy: userId, updatedAt: new Date() }, { merge: true });
                
            },
        }).catch(e => {
            console.error(e);
            return { error: e };
        });
    };
};

export const addColorLabel = color => {
    return async (dispatch, getState) => {

        const userId = getUserId()

        const collection = getEditorCollectionFromPath()
        const docId = getEditorDocIdFromPath()

        const firestore = getFirestore();

        dispatch({
            type: 'ADD_COLOR_LABEL',
            meta: { [color]: {} },
            async payload() {
                await firestore.doc(`${collection}/${docId}`).set({ colors: { [color]: {} }, updatedBy: userId, updatedAt: new Date() }, { merge: true });
                return { color };
            },
        }).catch(e => {
            console.error(e);
            return { error: e };
        });
    };
};

export const renameColorLabel = (color) => {

    return async (dispatch, getState) => {

        const firestore = getFirestore();

        const collection = getEditorCollectionFromPath()
        const docId = getEditorDocIdFromPath()

        const userId = getUserId()

        dispatch({
            type: 'RENAME_COLOR_LABEL',
            meta: color,
            async payload() {
                await firestore.doc(`${collection}/${docId}`).set(
                    {
                        colors: color,
                        updatedBy: userId,
                        updatedAt: new Date()
                    },
                    { merge: true }
                );
                return { color };
            },
        }).catch(e => {
            console.error(e);
            return { error: e };
        });
    };
};