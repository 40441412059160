import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'

import { GoogleIcon } from '../Components/ProviderIcons'
import { Formik } from 'formik';
import { signInWithGoogle, signUpWithEmailAndPassword } from "../../../../store/actions/auth-actions"

import { AuthContainer } from '..';
import { SetTitle, useGetRedirectUserQueryParams, useIsSigningUpOrLoggingIn } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import { PasswordField } from '../Components/Password';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { HUBSPOT_PORTAL_ID, HUBSPOT_SIGNUP_FORM_ID } from '../../../../../shared/helpers';

export const Signup = () => {

    SetTitle('Create an account')

    const dispatch = useDispatch()

    const { email } = useGetRedirectUserQueryParams()
    const isLoading = useIsSigningUpOrLoggingIn()

    // init Hubspot signup form here (so we have accurate stats)
    useHubspotForm({
        region: "na1",
        portalId: HUBSPOT_PORTAL_ID,
        formId: HUBSPOT_SIGNUP_FORM_ID,
        target: '#hubspot-signup-form'
    });

    return (
        <AuthContainer type="signup">
            <VStack spacing={6} w="full">
                <Formik
                    initialValues={{ firstName: '', lastName: '', email: email || '', password: '' }}
                    onSubmit={(values, actions) => dispatch(signUpWithEmailAndPassword(values))}
                    validate={(values) => {
                        const errors = {};
                        const attributes = Object.keys(values)
                        attributes.forEach(attr => { // disabled validation for now
                            // if (!values[attr]) errors[attr] = "This field is required"
                        })
                        return errors;
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={6}>
                                <HStack>
                                    <FormControl id="firstName">
                                        <FormLabel>First name</FormLabel>
                                        <Input
                                            autoComplete="off"
                                            name="firstName"
                                            isRequired
                                            placeholder="Enter your first name"
                                            bgColor="rarchy-bg-white"
                                            fontSize="md"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormControl>
                                    <FormControl id="lastName">
                                        <FormLabel>Last name</FormLabel>
                                        <Input
                                            autoComplete="off"
                                            name="lastName"
                                            isRequired
                                            placeholder="Enter your last name"
                                            bgColor="rarchy-bg-white"
                                            fontSize="md"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormControl>
                                </HStack>
                                <FormControl id="email-address">
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Input
                                        autoComplete="off"
                                        name="email"
                                        defaultValue={email || null}
                                        type="email"
                                        isRequired
                                        placeholder="Enter your work email"
                                        bgColor="rarchy-bg-white"
                                        fontSize="md"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormControl>
                                <PasswordField handleChange={handleChange} handleBlur={handleBlur} />
                                <Button
                                    colorScheme="blue"
                                    fontSize="sm"
                                    type="submit"
                                    iconSpacing={3.5}
                                    loadingText="Creating account..."
                                    isLoading={isLoading}
                                    mt={3}
                                >
                                    Sign up for free
                                </Button>
                            </Stack>
                        </form>
                    )}
                </Formik>
                <Stack w="full" spacing="5">
                    <HStack>
                        <Divider />
                        <Text fontSize="sm" whiteSpace="nowrap" color="fg.muted">
                            or continue with
                        </Text>
                        <Divider />
                    </HStack>
                    <Button
                        variant="outline"
                        fontSize="sm"
                        fontWeight="medium"
                        borderColor="rarchy-border"
                        _hover={{ bgColor: "rarchy-bg-subtle" }}
                        leftIcon={<GoogleIcon />}
                        onClick={() => dispatch(signInWithGoogle())}
                    >
                        Sign up with Google
                    </Button>
                </Stack>
            </VStack>
        </AuthContainer>
    )
}