import React, { useState, useEffect } from 'react';
import { usePrevious } from '../../../../../hooks';

const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    useEffect(() => {
        const updateMousePosition = ev => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);
    return mousePosition;
};

const HEIGHT = 74;

export const useMouseEnteredTopBoundary = (fullscreen) => {
    const mousePosition = useMousePosition();
    const prevMousePosition = usePrevious(mousePosition);
    const [entered, setEntered] = useState(false);
    useEffect(() => {
        if (fullscreen) {
            if (!entered) {
                if (mousePosition?.y < HEIGHT && ((prevMousePosition?.y > HEIGHT) && (prevMousePosition?.y > mousePosition?.y))) {
                    setEntered(true);
                }
            } else {
                if (mousePosition?.y > HEIGHT) setEntered(false);
            }
        } else {
            if (entered) setEntered(false);
        }
    })
    return entered;
}

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return scrollPosition;
};

export default useMousePosition;