import { Stack, Text, Button, useBoolean } from "@chakra-ui/react"
import { MainHeader, SectionHeader } from "../../Components"
import { useDispatch } from "react-redux"
import { logOut } from "../../../../store/actions/auth-actions"
import { useHistory } from "react-router-dom"
import { wait } from "../../../../../shared/helpers"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const Security = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoggingOut, setIsLoggingOut] = useBoolean()
    return (
        <Stack spacing={5}>
            <MainHeader title="Security" mb={3} />
            <Stack spacing={3}>
                <SectionHeader title="Sign out from all devices" />
                <Text fontSize="sm">
                    <SkeletonWrapper w="fit-content">
                        Logged in on a shared device but forgot to sign out? End all sessions by signing out from all devices.
                    </SkeletonWrapper>
                </Text>
            </Stack>
            <SkeletonWrapper w="fit-content">
                <Button
                    mt={3}
                    fontSize="sm"
                    w="250px"
                    isLoading={isLoggingOut}
                    onClick={async () => {
                        setIsLoggingOut.toggle()
                        await wait(2000)
                        dispatch(logOut(history))
                        setIsLoggingOut.toggle()
                    }}>
                    Sign out from all devices
                </Button>
            </SkeletonWrapper>
        </Stack>
    )
}