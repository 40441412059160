import { useState } from "react"
import { Box, Button, Stack, Text } from "@chakra-ui/react"
import { AuthContainer } from ".."
import { SetTitle } from "../../../../hooks"
import { Formik } from "formik"
import { getFirebase } from "react-redux-firebase"
import { Error } from "../Components/Error"
import { PasswordField } from "../Components/Password"
import { useHistory } from "react-router-dom"
import queryString from 'query-string';
import { wait } from "../../../../../shared/helpers"

export const VerifyResetPassword = () => {
    SetTitle("Reset your password to access your Rarchy account")
    const [wasSuccessfulReset, setWasSuccessfulReset] = useState(false)
    const history = useHistory()
    const { oobCode: code, /* mode */ } = queryString.parse(window.location.search); // mode isnt used (resetPassword), but can maybe use to differentiate in the future (verify email reset etc)
    return (
        <AuthContainer type="reset-verify">
            <Formik
                initialValues={{ password: '' }}
                onSubmit={async (values, { setErrors }) => {
                    const { password } = values
                    const auth = getFirebase().auth()
                    try {
                        await auth.confirmPasswordReset(code, password)
                        setWasSuccessfulReset(true)
                        await wait(3000)
                        history.push("/app/login")
                        setWasSuccessfulReset(false)
                    } catch (e) {
                        setErrors({ firebaseError: e })
                    }
                }}
                validate={(values) => {
                    const errors = {};
                    const attributes = Object.keys(values)
                    attributes.forEach(attr => {
                        if (!values[attr]) errors[attr] = "You missed this one"
                        if (values[attr].length < 8) errors[attr] = "Use 8 or more characters" // disabled length validation for now
                    })
                    return errors;
                }}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    isValid,
                    isSubmitting,
                }) => {
                    return (
                        <>
                            {!wasSuccessfulReset && (
                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={4}>
                                        {errors?.firebaseError && (
                                            <Box mt={-4} mb={3}>
                                                <Error auth={{ isError: errors?.firebaseError }} />
                                            </Box>
                                        )}
                                        <Text fontSize="sm" color="fg.muted" mt={-4}>Please set a new password for your account.</Text>
                                        <PasswordField forReset errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
                                        <Button
                                            colorScheme="blue"
                                            fontSize="sm"
                                            type="submit"
                                            iconSpacing={4}
                                            isDisabled={!isValid}
                                            isLoading={isSubmitting}
                                            mt={3}
                                        >
                                            Set password
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                            {wasSuccessfulReset && (
                                <Stack spacing={5}>
                                    <Text>We've successfully reset your password.</Text>
                                    <Text>Redirecting you to login shortly...</Text>
                                </Stack>
                            )}
                        </>
                    )
                }}
            </Formik>
        </AuthContainer>
    )
}