import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'
import { toggleSitemapsNavbar } from '../../../../store/actions/ui-actions'
import { useColorMode } from '@chakra-ui/react'
import { ToggleButton } from '../Components/ToggleButton/Button';
import { ToggleButtonGroup } from '../Components/ToggleButton/ButtonGroup';
import { getIsNavbarHidden } from '../../../../helpers';
import { EditorSkeletonWrapper } from '..';

export const Toggle = () => {
    const dispatch = useDispatch()
    const isNavbarHidden = getIsNavbarHidden()
    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                defaultValue={null}
                // variant="outline"
                mr={1}
            >
                <ToggleButton fontSize="20px" icon={!isNavbarHidden ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} onClick={() => dispatch(toggleSitemapsNavbar())} />
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}