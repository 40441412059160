import { useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useShouldDisableMove } from "./hooks"
import { wait } from "../../../../../../../shared/helpers"
import { moveToFolder, showMoveToFolderToast } from "./helpers"
import { useMoveToFolderContext } from "../../../../../Projects/Folders/hooks"

export const Move = () => {
    const dispatch = useDispatch()
    const [isMoving, setIsMoving] = useState(false)
    const { file, folder, showDetailScreen, onClose } = useMoveToFolderContext()
    const { inFolder } = showDetailScreen
    const item = file || folder
    const isDisabled = useShouldDisableMove({ item, folder, folderId: inFolder })
    return (
        <Button
            fontSize="sm"
            isDisabled={isDisabled}
            isLoading={isMoving}
            onClick={async () => {
                const oldFolderId = item?.team
                setIsMoving(true)
                await wait(500)
                // save
                await moveToFolder({ file, folder, folderId: inFolder, dispatch })
                // 
                onClose()
                await wait(250)
                showMoveToFolderToast({ file, folder, oldFolderId, dispatch })
                setIsMoving(false)
            }}
        >
            Move to folder
        </Button>
    )
}