import { Box, Stack, StackDivider, Text } from "@chakra-ui/react"
import { MainHeader, MidHeader, SectionHeader } from "../../components"
import { Name } from "./Name"
import { Website } from "./Website"
import { Description } from "./Description"
import { Delete } from "./Delete"
import { useIsOrganizationAdmin } from "../../../../hooks"

export const OrgDetails = () => {
    const isAdmin = useIsOrganizationAdmin()
    return (
        <Stack maxW="3xl" spacing={7}>
            <Box>
                <MainHeader title="Organization details" />
                <Stack spacing={3.5} divider={<StackDivider />} mt={5}>
                    <Name />
                    <Website />
                    <Description />
                </Stack>
            </Box>
            {isAdmin && <MidHeader title="Manage your account" />}
            {isAdmin && <Delete />}
        </Stack>
    )
}