import { Button, Icon } from "@chakra-ui/react"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { AiOutlinePauseCircle } from "react-icons/ai"
import { wait } from "../../../../../../../shared/helpers"

export const Pause = ({ data, isPausing, setIsPausing, isCanceling }) => {
    return (
        <Button
            variant="outline"
            fontSize="md"
            iconSpacing={3}
            leftIcon={<Icon as={AiOutlinePauseCircle} fontSize="xl" />}
            size="lg"
            isDisabled={isCanceling}
            isLoading={isPausing}
            onClick={async () => {
                setIsPausing(true)
                await setDoc(doc(getFirestore(), "sitemaps_processing", data.id), { options: { paused: true } }, { merge: true });
                await wait(500)
            }}>
            Pause
        </Button>
    )
}