import { useDispatch } from "react-redux"
import { Icon, MenuItem } from "@chakra-ui/react"
import { RiUserShared2Line } from "react-icons/ri"
import { FolderFileIconButton } from "../../../Components/Shared/FolderFileIconButton"

import { ICON_SIZE, PY } from '../../../Projects/Folders/Components/Options/Menu'
import { toggleShareFileModal } from "../../../../store/actions/files-actions"

export const FileShare = ({ file, inDropdown, opts = {} }) => {
    const dispatch = useDispatch()
    const handleClick = () => dispatch(toggleShareFileModal(file))
    return (
        inDropdown ?
            <MenuItem
                py={PY}
                icon={<Icon as={RiUserShared2Line} fontSize={ICON_SIZE} />}
                onClick={handleClick}
            >
                Share
            </MenuItem>
            :
            <FolderFileIconButton
                icon={<RiUserShared2Line />}
                opts={opts}
                onClick={handleClick}
            />
    )
}