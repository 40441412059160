import { Box, Button, Stack, Textarea, useBoolean } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useContext, useEffect } from "react";
import { AddUsersContext } from "../../../../Users/AddUsersHOC";
import { ShareFileContext } from "../../../../Files/Components/Share/ShareFileHOC";
import { wait } from "../../../../../../shared/helpers";
import { getEncodedEmail, getOrganization, getUserId, sendHubspotCustomEvent } from "../../../../../helpers";
import { store } from "../../../../../store";
import { addUsersToPeople, getPermissionsKey, getUsersKey } from "../helpers";
import { mergeOrganizationChange } from "../../../../../store/actions/organization-actions";
import { mergeFileChange } from "../../../../../store/actions/files-actions";
import { getFirestore, setDoc, doc } from "firebase/firestore"
import { AdditionalSeats } from "../../../../UpgradeAccount/Screens/AdditionalSeats";
import { PayToday } from "../../../../UpgradeAccount/Components/PayToday";
import { useGetOrganization, useGetSubscription, useGetTotalNumberOfSeats } from "../../../../../hooks";
import { useGetNumberOfSeatsOverLimit } from "../../../../../../shared/hooks/pricing";
import { handleCustomerPortalSubscriptionUpdate } from "../../../../Settings/Organization/Billing/Subscriptions";
import { isEmpty } from 'lodash'

export const SendInvite = ({ forOrganization }) => {

    const [isLoading, setIsLoading] = useBoolean()

    const { setInputValue, peopleToInvite, setPeopleToInvite } = useContext(forOrganization ? AddUsersContext : ShareFileContext)
    const { values, setFieldValue, handleSubmit, isSubmitting } = useFormikContext()

    const organization = useGetOrganization()

    const subscription = useGetSubscription()
    const { customer_id, id: subscription_id } = subscription || {};

    const validatedPeopleToInvite = peopleToInvite?.filter(p => !p?.error)

    const total_number_of_seats = useGetTotalNumberOfSeats()

    const numberOfSeatsOverLimit = useGetNumberOfSeatsOverLimit(total_number_of_seats - (Object.keys(organization?.users).length), validatedPeopleToInvite.length)
    const additional_seats_needed = !forOrganization ? 0 : numberOfSeatsOverLimit

    useEffect(() => {
        if (additional_seats_needed > values?.seats) setFieldValue('seats', additional_seats_needed)
    }, [additional_seats_needed, values?.seats])

    const noValidPeopleToInvite = isEmpty(validatedPeopleToInvite)
    const hasHitSeatsLimit = forOrganization && additional_seats_needed

    const isDisabled = noValidPeopleToInvite || hasHitSeatsLimit

    return (
        <Stack mt={4} spacing={0}>
            {hasHitSeatsLimit && (
                <Stack spacing={4} mb={5}>
                    <Box fontSize="sm" color="fg.muted">You've reached the number of seats available in your plan.</Box>
                    <AdditionalSeats />
                    <PayToday forAddons />
                    <Button
                        colorScheme="blue"
                        fontSize="sm"
                        onClick={() => {
                            handleCustomerPortalSubscriptionUpdate({ setIsLoading, customer_id, subscription_id })
                        }}
                        isLoading={isLoading}
                    >
                        Add seats
                    </Button>
                </Stack>
            )}
            {!hasHitSeatsLimit && (
                <Textarea
                    fontSize="sm"
                    pt={3}
                    minH="150px"
                    placeholder="Message"
                    onChange={(e) => setFieldValue("message", e.target.value)}
                />
            )}
            {!hasHitSeatsLimit && (
                <Stack mt={9} mb={3} spacing={4} direction="row" justifyContent="end">
                    <Button
                        isDisabled={isSubmitting}
                        variant="ghost"
                        fontSize="sm"
                        colorScheme="blue"
                        onClick={() => { setInputValue(''); setPeopleToInvite([]); }}
                    >
                        Cancel
                    </Button>
                    <Button px={6} colorScheme="blue" fontSize="sm" isDisabled={isDisabled} onClick={handleSubmit} isLoading={isSubmitting}>{forOrganization ? "Invite" : "Share"}</Button>
                </Stack>
            )}
        </Stack>
    )
}

export const handleSendInvite = async ({ values, forOrganization, file, setInputValue, peopleToInvite, setPeopleToInvite }) => {

    try {

        const dispatch = store.dispatch

        const userId = getUserId()

        const organization = getOrganization()

        const permissionsKey = getPermissionsKey(forOrganization)

        const usersKey = `${getUsersKey(forOrganization)}s`

        const users = {}

        peopleToInvite?.forEach(p => {
            const encodedEmail = getEncodedEmail(p.email)
            users[encodedEmail] = { invitedBy: userId, invitedAt: new Date(), [permissionsKey]: values?.permission, message: values?.message };
        })

        await wait(500)

        // save change to firestore
        const collectionId = forOrganization ? 'organizations' : file?.collection
        const docId = forOrganization ? organization?.id : file?.id
        const ref = doc(getFirestore(), collectionId, docId);

        // if (import.meta.env.PROD) {
        await setDoc(ref, { [usersKey]: users, updatedBy: userId, updatedAt: new Date() }, { merge: true });
        // }

        // update organization
        if (forOrganization) {
            dispatch(mergeOrganizationChange({ [usersKey]: users }))
        }

        // update file
        if (!forOrganization) {
            dispatch(mergeFileChange(file?.id, file?.collection, { [usersKey]: users }))
        }

        // update in people
        addUsersToPeople(users)

        // reset inputs
        setInputValue(''); setPeopleToInvite([]);

        // send event to Hubspot
        sendHubspotCustomEvent('invited_users', { number_of_users_invited: (Object.keys(users)?.length || 1), collection: forOrganization ? 'organizations' : file?.collection })

    } catch (e) {
        console.error(e)
    }
}