import { Button } from "@chakra-ui/react"
import { toggleNewFolderModal } from "../../../../../store/actions/folders-actions"

import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { useState } from "react"
import { saveNewFolderInFirestore } from "./helpers"

export const SaveButton = (props) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const [isSaving, setIsSaving] = useState(false)

    // continue
    return (
        <Button
            mt={12}
            colorScheme="blue"
            fontSize="sm"
            isDisabled={!props?.name}
            isLoading={isSaving}
            onClick={async () => {
                setIsSaving(true)
                try {
                    const { folderId } = await saveNewFolderInFirestore({ ...props, dispatch })
                    history.push(`/app/folder/${folderId}`)
                } catch (e) {
                    console.error(e)
                } finally {
                    dispatch(toggleNewFolderModal())
                    setIsSaving(false)
                }
            }}>
            Save
        </Button>
    )
}