import { Box, Stack } from "@chakra-ui/react"
import { useCanFullyEditFile, useGetOrganization, useGetUserId } from "../../../../hooks"
import { MidHeader } from "../../../Settings/Components"
import { UserAvatarDetail } from "../UserAvatarDetail"
import { Permissions } from "./Permissions"
import { useDispatch } from "react-redux"
import { Formik } from "formik"
import { getFirestore, doc, setDoc, deleteField } from "firebase/firestore"
import { useContext } from "react"
import { AddUsersContext } from "../../../Users/AddUsersHOC"
import { ShareFileContext } from "../../../Files/Components/Share/ShareFileHOC"
import { AccountFolderAccess } from "../../../Files/Components/Share/AccountFolderAccess"
import { getPermissionsKey, getUsersKey } from "./helpers"
import { getEncodedEmail } from "../../../../helpers"
import { mergeFileChange } from "../../../../store/actions/files-actions"
import { mergeOrganizationChange } from "../../../../store/actions/organization-actions"
import { isEmpty } from 'lodash'

export const People = ({ forOrganization }) => {

    const dispatch = useDispatch()

    const forFileShare = !forOrganization

    const { file, people, peopleToInvite } = useContext(forOrganization ? AddUsersContext : ShareFileContext)

    const organization = useGetOrganization()

    const canFullyEdit = useCanFullyEditFile(file)

    const userId = useGetUserId()

    //
    if (!isEmpty(peopleToInvite)) return null

    return (
        <Stack mt={2} spacing={1.5}>
            <Box px={7}><MidHeader title={forOrganization ? "Team members" : "People with access"} /></Box>
            <Stack mt={1} spacing={0} maxH="33vh" overflow="scroll">
                {forFileShare && canFullyEdit && <AccountFolderAccess />}
                {[...people]?.map(person => {
                    return (
                        <Formik
                            key={person?.id}
                            initialValues={{ permission: person?.permission }}
                            onSubmit={async (values, actions) => {

                                try {

                                    const { permission } = values

                                    const encodedId = getEncodedEmail(person?.id)

                                    if (forOrganization) {
                                        dispatch(mergeOrganizationChange(
                                            { [`${getUsersKey(forOrganization)}s`]: { [encodedId]: permission ? { [getPermissionsKey(forOrganization)]: permission } : undefined } }
                                        ))
                                    }

                                    if (forFileShare) {
                                        dispatch(mergeFileChange(file?.id, file?.collection, { collaborators: { [encodedId]: permission ? { access: permission } : undefined } }))
                                    }

                                    // save to firestore
                                    const ref = doc(
                                        getFirestore(),
                                        forOrganization ? 'organizations' : file?.collection,
                                        forOrganization ? organization?.id : file?.id
                                    );
                                    await setDoc(ref, { [`${getUsersKey(forOrganization)}s`]: { [encodedId]: permission ? { [getPermissionsKey(forOrganization)]: permission } : deleteField() }, updatedBy: userId, updatedAt: new Date() }, { merge: true });
                                    // end
                                } catch (e) {
                                    console.error(e)
                                }
                            }}
                            validate={(values) => {
                                const errors = {};
                                const attributes = Object.keys(values)
                                attributes.forEach(attr => { // disabled validation for now
                                    // if (!values[attr]) errors[attr] = "This field is required"
                                })
                                return errors;
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                isValid,
                                isSubmitting,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Stack px={7} py={1.5} direction="row" align="center" justify="space-between" _hover={{ bgColor: 'rarchy-bg-subtle-hover' }}>
                                            <UserAvatarDetail
                                                w="full"
                                                user={person}
                                                avatarSize="sm"
                                                nameSize="sm"
                                                nameWeight="medium"
                                                emailSize="xs"
                                                emailColor="fg.muted"
                                            />
                                            <Permissions
                                                file={file}
                                                user={person}
                                                forOrganization={forOrganization}
                                                onChange={(permission) => {
                                                    setFieldValue("permission", permission)
                                                    handleSubmit()
                                                }}
                                            />
                                        </Stack>
                                    </form>
                                )
                            }}
                        </Formik>
                    )
                })}
            </Stack>
        </Stack>
    )
}