import {
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Portal
} from '@chakra-ui/react'
import { useDidSitemapImportError, useIsSitemapImportCompleted, useIsSitemapImportCrawl, useIsSitemapImportPausedByUser } from '../../helpers'

import { AddIcon } from '@chakra-ui/icons'
import { AiOutlinePlayCircle } from 'react-icons/ai'
import { Delete } from './Actions/Delete'
import { FiMoreHorizontal } from 'react-icons/fi'
import { GrRedo } from 'react-icons/gr'
import { IoMdRefresh } from 'react-icons/io'
import { RetryImportButtonMenuItem } from '../../Detail/Topbar'

const ICON_COLOR = "rarchy-color-sitemap-editor"
const FONT_SIZE = "sm"
const ICON_SIZE = "lg"
export const PY = 2

export const HistoryMenu = ({ imported }) => {
    const WHITE = { color: 'rarchy-bg-white', bg: 'rarchy-link' }
    const isCrawl = useIsSitemapImportCrawl(imported)
    const hasErrored = useDidSitemapImportError(imported)
    const isPausedByUser = useIsSitemapImportPausedByUser(imported)
    const isCompleted = useIsSitemapImportCompleted(imported)
    return (
        <Menu placement="top-end" offset={[0, -47]} autoSelect={false} isLazy closeOnSelect={false}>
            <IconButton
                as={MenuButton}
                zIndex={0}
                pos="sticky"
                display="inline-grid"
                colorScheme="blue"
                color={ICON_COLOR}
                bg="rarchy-bg-sitemap-editor"
                _hover={WHITE}
                _active={WHITE}
                icon={<FiMoreHorizontal />}
                fontSize="xl"
                size="sm"
                p={1.5}
                onClick={(e) => { e.stopPropagation() }}
            />
            <Portal>
                <MenuList w="240px" boxShadow="xl" fontSize={FONT_SIZE} onClick={e => { e.preventDefault(); e.stopPropagation(); return false; }}>
                    {/* !hasErrored && !isPausedByUser && !isCompleted && ( // incomplete (disabled so we can get new Rarchy out)
                        <MenuItem py={PY} icon={<Icon as={GrRedo} fontSize={ICON_SIZE} />}>
                            Resume Crawl
                        </MenuItem>
                    )*/}
                    {!hasErrored && isPausedByUser && isCrawl && ( // paused
                        <MenuItem py={PY} icon={<Icon as={AiOutlinePlayCircle} fontSize={ICON_SIZE} />}>
                            Resume Crawl
                        </MenuItem>
                    )}
                    {/* !hasErrored && (isPausedByUser || isCompleted) && ( // finished
                        <MenuItem py={PY} icon={<Icon as={AddIcon} fontSize="sm" mx={1} ml={0.5} />}>
                            Create Sitemap
                        </MenuItem>
                     ) */}
                    <RetryImportButtonMenuItem data={imported} forMenu />
                    <Delete imported={imported} />
                </MenuList>
            </Portal>
        </Menu>
    )
}