import { useGetFirebaseFromState, useGetUser } from "."

export const useShouldShowSitemapEditorOnboarding = () => {
    const firebase = useGetFirebaseFromState()
    const user = useGetUser()
    const createdAt = parseInt(firebase?.auth?.createdAt)
    // created after 7:30pm 20/2/24 (release date)
    if (/* import.meta.env.DEV || */ (createdAt > 1708421426079)) {
        if (/* import.meta.env.DEV || */ !user?.onboarding?.sitemap_editor?.shown) return true
    }
    return false
}