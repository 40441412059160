import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Stack,
    Flex,
    useMediaQuery,
    Box
} from '@chakra-ui/react'

export const SplitModal = ({ isOpen, onClose, isFull, size, h, left, right, rightW, isCentered }) => {
    const [hideRight] = useMediaQuery('(max-width: 1100px)')
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={hideRight ? null : size} isCentered={isCentered || false}>
            <ModalOverlay />
            <ModalContent w="fit-content">
                <ModalCloseButton />
                <ModalBody p={0}>
                    {isFull || hideRight
                        ?
                        <Left isFull={isFull || hideRight} h={h}>{left}</Left>
                        : (
                            <Stack direction="row" spacing={0} h={h}>
                                <Left h={h}>{left}</Left>
                                <Right w={rightW}>
                                    {right}
                                </Right>
                            </Stack>
                        )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const Left = ({ isFull, h, children }) => {
    if (isFull) return <Box h={h}>{children}</Box>;
    return (
        <Flex h={h}>
            {children}
        </Flex>
    )
}

const Right = ({ w, bgColor, children }) => {
    const width = w || "2xl"
    return (
        <Flex w={width} bgColor={bgColor || "rarchy-bg-subtle"} roundedRight="md" p={0}>
            {children}
        </Flex>
    )
}