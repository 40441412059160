import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ToggleButtonGroup } from '../../Components/ToggleButton/ButtonGroup'
import { ActionSVG, DecisionSVG, DocumentSVG, GridIcon, InputOutputSVG, PageSectionSVG, PageSVG, StartEndSVG } from './icons'
import { store } from '../../../../../store'
import { addFlowChange, draggingNewSymbol, insertNewSymbol } from '../../../../../store/actions/flow-actions'
import { randomString } from '../../../../Sitemap/user-flows/components/mouseover/buttons/new-symbol/components/Button'
import { transform } from '../../../../Sitemap/app/canvas/utils/zoom'
import { useEffect } from 'react'
import { useState } from 'react'
import { ToggleButton } from '../../Components/ToggleButton/Button'
import { addSymbolChange } from '../../../../Sitemap/user-flows/helpers'
import { EditorSkeletonWrapper } from '../..'

export const Grid = () => {
    const [defaultSymbol, setDefaultSymbol] = useState('action')
    useEffect(() => {
        var defaultSymbol = localStorage.getItem(`userFlowDefaultSymbol`);
        if (!defaultSymbol) defaultSymbol = localStorage.setItem(`userFlowDefaultSymbol`, 'action');
        setDefaultSymbol(defaultSymbol)
    }, [])
    const changeDefaultSymbol = (symbol) => {
        localStorage.setItem(`userFlowDefaultSymbol`, symbol);
        setDefaultSymbol(symbol);
    }

    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                value={defaultSymbol}
                isAttached
                variant="outline"
                isRadio
            >
                <ToggleButton value="page" onClick={() => changeDefaultSymbol("page")} icon={
                    <GridIcon
                        id="page"
                        icon={PageSVG}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
                <ToggleButton value="page-sections" onClick={() => changeDefaultSymbol("page-sections")} icon={
                    <GridIcon
                        id="page-sections"
                        icon={PageSectionSVG}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
                <ToggleButton value="action" onClick={() => changeDefaultSymbol("action")} icon={
                    <GridIcon
                        id="action"
                        icon={ActionSVG}
                        margin={6}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
                <ToggleButton value="startEnd" onClick={() => changeDefaultSymbol("startEnd")} icon={
                    <GridIcon
                        id="startEnd"
                        icon={StartEndSVG}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
                <ToggleButton value="decision" onClick={() => changeDefaultSymbol("decision")} icon={
                    <GridIcon
                        id="decision"
                        icon={DecisionSVG}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
                <ToggleButton value="document" onClick={() => changeDefaultSymbol("document")} icon={
                    <GridIcon
                        id="document"
                        icon={DocumentSVG}
                        margin={6}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
                <ToggleButton value="inputOutput" onClick={() => changeDefaultSymbol("inputOutput")} icon={
                    <GridIcon
                        id="inputOutput"
                        icon={InputOutputSVG}
                        margin={6}
                    // selected={sitemap?.format === 'tree-vertical'}
                    />
                } />
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}

export const droppedNewSymbol = ({ NewSymbol, x, y }) => {

    let width = 225;
    let height = NewSymbol.symbol === 'page-sections' ? 117 : NewSymbol.symbol === 'page' ? 267 : 36;

    const newSymbolOffsetX = x - ((width / 2) * transform.k);
    const newSymbolOffsetY = y - ((height / 2) * transform.k);

    const movex = Math.round((transform.invertX(newSymbolOffsetX)))// / (width / 12)) * (width / 12);
    const movey = Math.round((transform.invertY(newSymbolOffsetY)))// / (height / 12)) * (height / 12);

    const newNodeId = randomString(10);

    var symbol = { type: NewSymbol.symbol, x: movex, y: movey };
    if (symbol?.type?.includes('page')) symbol.text = 'Page';
    /* eslint-disable-next-line */
    if (symbol?.type === 'page') symbol.wireframe = 'landing-pages/content-4';

    const isSymbol = !symbol?.type?.startsWith('page');
    const accountForLegacySymbolYOffset = (isSymbol ? (215 / 2) : 0);
    symbol.y = symbol.y - accountForLegacySymbolYOffset

    // dispatch edit so we can render to user immediately ***/
    addSymbolChange({
        data: { [newNodeId]: { ...symbol } },
        oldData: { [newNodeId]: undefined },
    });

    store.dispatch(draggingNewSymbol({ dragging: false }))
}