import { Icon } from "@chakra-ui/react"
import { MdTipsAndUpdates } from "react-icons/md"
import { useGetUpgradeModal } from "../../../../hooks"
import { INITIAL_SCREENS } from "../.."

export const Bulb = () => {
    const { screen } = useGetUpgradeModal() 
    const isConfirming = screen === 'confirming'
    const showBulbIcon = !isConfirming && INITIAL_SCREENS?.some(v => screen?.startsWith(v))
    if (!showBulbIcon) return null
    return (
        <Icon as={MdTipsAndUpdates} color="#ECC94B" fontSize="2xl" ml={-2} mr={1} />
    )
}