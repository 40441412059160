import { AccordionPanel, Box, Button, CloseButton, Editable, EditableInput, EditablePreview, HStack, Icon, Text, Tooltip, VStack, useBoolean } from "@chakra-ui/react"
import { useEffect, useState } from "react"

import { FiEdit3 } from "react-icons/fi"
import { Pallette } from "../Pallette"
import { getPageColor } from "../../../../../Sitemap/app/canvas/utils/helpers"
import { getSitemap } from "../../../../../../helpers"
import { handleDelete } from "../../../../../Sitemap/comments/helpers"

export const Panel = ({ forNew, colors, color, onEditableChange, handlePalletteChange, handleDelete }) => {
    const [pageCount, setPageCount] = useState(0)
    const [showConfirmationOverlay, setShowConfirmationOverlay] = useState(null)
    useEffect(() => {
        if (!forNew) setPageCount(getNumberOfPagesByColor(color?.color))
    }, [])
    return (
        <AccordionPanel px={3} pt={.5} pb={1.5}>
            <VStack align="start" spacing={3} borderTop="1px solid" borderBottom="1px solid" borderColor="rarchy-border" pt={2} pb={3}>
                <HStack spacing={forNew ? 2.5 : 2}>
                    <Icon as={FiEdit3} fontSize="xs" ml={-.5} />
                    <Tooltip label={color?.label} variant="rounded-sm" hasArrow openDelay={500}>
                        <Editable
                            w="160px"
                            align="left"
                            placeholder={forNew ? "Name your new label..." : color?.color}
                            defaultValue={color?.label}
                            variant="flushed"
                            color={(forNew || color?.label) ? "rarchy-color-sitemap-editor" : "fg.muted"}
                            onClick={(e) => e.preventDefault()}
                            onSubmit={(val) => {
                                if (onEditableChange) {
                                    onEditableChange(val)
                                } else {
                                    color?.renameLabel(val)
                                }
                            }}
                        >
                            <EditablePreview
                                fontSize="13px"
                                py={.5}
                                w="inherit"
                                noOfLines={1}
                                borderBottom="1px dashed"
                                borderBottomColor="rarchy-border-400"
                            />
                            <EditableInput
                                fontSize="13px"
                                py={.5}
                                w="inherit"
                                color="rarchy-color-sitemap-editor"
                                noOfLines={1}
                            />
                        </Editable>
                    </Tooltip>
                </HStack>
                {pageCount > 0 && (
                    <Text fontSize="xs">{`${pageCount?.toLocaleString()} pages have this label`}</Text>
                )}
                <Box bgColor="var(--popper-bg)">
                    {showConfirmationOverlay && (
                        <Box pos="absolute" w="184px" h="168px" zIndex={1} bgColor="inherit">
                            <Box w="inherit" h="inherit" border="1px solid" borderColor="rarchy-border" borderRadius="md" bgColor="rarchy-bg-sitemap-editor">
                                <VStack align="start" w="full" pt={1.5} px={2.5} pb={2.5} justifyContent="space-between" h="inherit">
                                    <VStack w="inherit" spacing={1}>
                                        <Box w="inherit" textAlign="right" justifyContent="end">
                                            <CloseButton float="right" size="sm" fontSize="xs" onClick={() => setShowConfirmationOverlay(null)} />
                                        </Box>
                                        <Text w="inherit" noOfLines={5} size="sm" fontSize="13px" px={1}>
                                            Changing the color of this label will also update all pages that have this label set.
                                        </Text>
                                    </VStack>
                                    <Button
                                        colorScheme="blue"
                                        h={7}
                                        size="sm"
                                        fontSize="xs"
                                        w="full"
                                        mb={1}
                                        onClick={() => {
                                            handlePalletteChange(showConfirmationOverlay, { overwritePages: true }) // showConfirmationOverlay is the color
                                            setShowConfirmationOverlay(null)
                                        }}
                                    >
                                        I understand, continue
                                    </Button>
                                </VStack>
                            </Box>
                        </Box>
                    )}
                    <Pallette
                        selectedColor={color?.color}
                        existingLabels={colors}
                        handlePalletteChange={(c) => {
                            if (pageCount > 0) {
                                setShowConfirmationOverlay(c?.header)
                            } else {
                                handlePalletteChange(c?.header)
                            }
                        }}
                    />
                </Box>
                {!forNew && pageCount === 0 && (
                    <Button
                        w="full"
                        colorScheme="red"
                        size="sm"
                        fontWeight="semibold"
                        fontSize="xs"
                        h={7}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                )}
            </VStack>
        </AccordionPanel >
    )
}

export const getNumberOfPagesByColor = (color) => {
    const sitemap = getSitemap()
    const pages = sitemap?.docs?.pages || {};
    let count = 0;
    Object.keys(pages).forEach(id => {
        const page = pages[id]
        const pageColor = getPageColor({ id, ...page }, { usePageFromDocs: true });
        if (pageColor === color) count++
    });
    return count;
}