import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Grid,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useGetSitemap, usePrevious } from '../../../../../hooks';

import { Options } from './Options';
import { Render } from './Render';
import { renderPDF } from './utils';
import { toggleExportPDFDrawer } from '../../../../../store/actions/sitemap-actions'
import { useDispatch } from 'react-redux'

export const ExportPDFDrawer = () => {

    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false);

    const [showName, setShowName] = useState(true)
    const prevShowName = usePrevious(showName)

    const [showWebsite, setShowWebsite] = useState(true)
    const prevShowWebsite = usePrevious(showWebsite)

    const [size, setSize] = useState('A4')
    const prevSize = usePrevious(size)
    
    const [layout, setLayout] = useState('portrait')
    const prevLayout = usePrevious(layout)

    const sitemap = useGetSitemap()

    const { ExportPDFDrawer } = sitemap?.ui
    const { showing } = ExportPDFDrawer
    const prevShowing = usePrevious(showing)

    useEffect(() => { if (showing && !prevShowing) fetchPDF(); }, [showing, prevShowing])

    useEffect(() => {
        if (showing) {
            if (
                (showName !== prevShowName)
                || (showWebsite !== prevShowWebsite)
                || (size !== prevSize)
                || (layout !== prevLayout)
            ) {
                fetchPDF();
            }
        }
    }, [showing, showName, prevShowName, showWebsite, prevShowWebsite, size, prevSize, layout, prevLayout])

    async function fetchPDF() {
        renderPDF({ size, layout, showName, showWebsite, setIsLoading })
    }

    if (!showing) return

    return (
        <Drawer
            isOpen
            placement='right'
            onClose={() => { dispatch(toggleExportPDFDrawer({ showing: false })) }}
            size="full"
        >
            <DrawerOverlay />
            <DrawerContent p={0}>
                <DrawerBody p={0}>
                    <Flex w="full" h="100vh">
                        <Render isLoading={isLoading} />
                        <Options
                            showName={showName}
                            setShowName={setShowName}
                            showWebsite={showWebsite}
                            setShowWebsite={setShowWebsite}
                            size={size}
                            setSize={setSize}
                            layout={layout}
                            setLayout={setLayout}
                        />
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}