import { Skeleton, SkeletonCircle } from "@chakra-ui/react"
import { useHasLoadedFiles } from "../../../hooks"

export const SkeletonWrapper = ({ w, isLoaded, children }) => {
    const hasLoadedFiles = useHasLoadedFiles()
    return <Skeleton w={w || "full"} isLoaded={isLoaded || hasLoadedFiles} rounded="md">{children}</Skeleton>
}

export const SkeletonCircleWrapper = ({ children, isLoaded, size, w, h }) => {
    const hasLoadedFiles = useHasLoadedFiles()
    return <SkeletonCircle w={w || "unset"} h={h || "unset"} size={size || "unset"} isLoaded={isLoaded || hasLoadedFiles}>{children}</SkeletonCircle>
}