import React from 'react'
import {
    HStack,
    Avatar,
    Text,
    Flex,
    VStack,
    Tooltip,
    IconButton,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { Menu } from './Menu'
import { getAvatarSrc } from '../../../../../helpers'
import { CheckIcon } from '@chakra-ui/icons'
import { useCanCommentOnFile, useGetSitemap } from '../../../../../hooks'
import { resolveComments, useCommentIsResolved } from '../../helpers.jsx'

export const Header = ({ comment, page, setEditing }) => {
    const sitemap = useGetSitemap()
    const userCanComment = useCanCommentOnFile()
    const isResolved = useCommentIsResolved(page);
    const isInitialComment = !comment.parent
    const inDrawer = sitemap?.ui.PageCommentsDrawer?.showing
    const shouldShowResolveButton = userCanComment && !inDrawer && isInitialComment && !isResolved;
    return (
        <Flex justifyContent="space-between" align="start">
            <HStack direction="row" spacing={4} mb={2}>
                <Avatar
                    size="xs"
                    w={7}
                    h={7}
                    src={comment.user.id !== 'removed' ? getAvatarSrc({ user: comment.user }) : null}
                />
                <VStack alignItems="left">
                    <Text mb={-2} fontSize="sm" fontWeight={500} noOfLines={1}>{`${comment?.user?.firstName || ''} ${comment?.user?.lastName || ''}`}</Text>
                    <Text fontSize="xs" color={mode("gray.500", "whiteAlpha.700")}>{comment?.time}</Text>
                </VStack>
            </HStack>
            <HStack direction="row" mt={0.5}>
                {shouldShowResolveButton && (
                    <Tooltip
                        placement="top-end"
                        label="Mark as resolved and hide discussion"
                        openDelay={500}
                        variant="rounded">
                        <IconButton icon={<CheckIcon />} rounded="full" variant="ghost" size="xs" onClick={() => resolveComments(page)} />
                    </Tooltip>
                )}
                <Menu comment={comment} page={page} setEditing={setEditing} />
            </HStack>
        </Flex>
    )
}