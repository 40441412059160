import React from 'react';
import { useDispatch } from 'react-redux';
import { transform } from '../../../canvas/utils/zoom';

import { togglePageDrawer } from '../../../../../../store/actions/sitemap-actions';
import { PageButton } from '../../components/PageButton';
import { LinkIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import { useGetSitemap } from '../../../../../../hooks';
import { getCanEditInEditor } from '../../../../../Editor/Navbar/helpers';

export const LinkPage = ({ d, showCovers }) => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    const canEditSitemap = getCanEditInEditor(sitemap)
    if (!canEditSitemap) return null
    const fontSize = `${(showCovers ? 27 : 16) * transform.k}`
    return (
        <Tooltip placement="bottom" label="Link Page" openDelay={1000} variant="rounded-sm" hasArrow>
            <PageButton
                icon={<LinkIcon />}
                fontSize={fontSize}
                d={d}
                onClick={(e) => {
                    e.stopPropagation()
                    dispatch(togglePageDrawer({ node: d, defaultIndex: 'pages' }));
                }}
            />
        </Tooltip>
    )
}