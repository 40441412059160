import { Button, FormControl, FormErrorMessage, FormLabel, Link, Stack, Text } from "@chakra-ui/react"
import { Field, Formik } from "formik"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { every, isEmpty } from 'lodash'
import { useGetAuth, useGetUserId } from "../../../../../hooks"

import { AuthContainer } from "../.."
import { Industry } from "./Industry"
import { JobFunction } from "./JobFunction"
import { UseCases } from "./UseCases"
import { useHistory } from "react-router-dom"
import { wait } from "../../../../../../shared/helpers"

export const SignUpSegmentation = () => {
    const history = useHistory()
    const auth = useGetAuth()
    const userId = useGetUserId()
    const firestore = getFirestore()
    return (
        <AuthContainer type="signup/2">
            <Formik
                initialValues={{ industry: null, job_function: null, primary_use_case: null }}
                onSubmit={async (values, actions) => {
                    try {
                        const anyFieldsFilled = !every(values, isEmpty);
                        if (userId && anyFieldsFilled) {
                            const userRef = doc(firestore, 'users', userId);
                            await setDoc(userRef, { selfReported: values }, { merge: true });
                        }
                        await wait(1000);
                        history.push(`${auth.redirectUser || '/app'}`);
                    } catch (e) {
                        console.error(e)
                    }
                }}
                validate={(values) => {
                    const errors = {};
                    const attributes = Object.keys(values)
                    attributes.forEach(attr => { // disabled validation for now
                        // if (!values[attr]) errors[attr] = "This field is required"
                    })
                    return errors;
                }}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={6}>
                                <FormControl id="job_function" isRequired isInvalid={!!errors.job_function && touched.job_function}>
                                    <FormLabel htmlFor="job_function">Job Function</FormLabel>
                                    <Field
                                        as={JobFunction}
                                        id="job_function"
                                        name="job_function"
                                        onChange={(value) => setFieldValue("job_function", value)}
                                    />
                                    <FormErrorMessage>{errors.job_function}</FormErrorMessage>
                                </FormControl>
                                <FormControl id="industry" isRequired isInvalid={!!errors.industry && touched.industry}>
                                    <FormLabel htmlFor="industry">Industry</FormLabel>
                                    <Field
                                        as={Industry}
                                        id="industry"
                                        name="industry"
                                        onChange={(value) => setFieldValue("industry", value)}
                                    />
                                    <FormErrorMessage>{errors.industry}</FormErrorMessage>
                                </FormControl>
                                <FormControl id="primary_use_case" isRequired isInvalid={!!errors.primary_use_case && touched.primary_use_case}>
                                    <FormLabel htmlFor="primary_use_case">What will you be using Rarchy for?</FormLabel>
                                    <Field
                                        as={UseCases}
                                        id="primary_use_case"
                                        name="primary_use_case"
                                        onChange={(value) => setFieldValue("primary_use_case", value)}
                                    />
                                    <FormErrorMessage>{errors.primary_use_case}</FormErrorMessage>
                                </FormControl>
                                <Button
                                    colorScheme="blue"
                                    fontSize="sm"
                                    type="submit"
                                    iconSpacing={4}
                                    loadingText="Signing in to Rarchy"
                                    isDisabled={!isValid}
                                    isLoading={isSubmitting}
                                    mt={3}
                                >
                                    Continue to Rarchy
                                </Button>
                                <Text fontSize="xs" color="fg.muted">We collect this information to enhance your experience as a Rarchy user. We will never monetise or share this data with third parties, ever. Learn more in our <Link isExternal href="https://rarchy.com/privacy-policy">privacy policy</Link>.</Text>
                            </Stack>
                        </form>
                    )
                }}
            </Formik>
        </AuthContainer>
    )
}