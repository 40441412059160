import * as ExportToCsv from "export-to-csv";

import { getSitemap } from '../../../../../helpers';
import { getURL } from '../../../../../store/actions/sitemap-actions';
import { store } from '../../../../../store';

// import { decodeURIComponentSafe } from '../../../utils/app';

export const exportCSV = function (name) {

  const sitemap = getSitemap()

  const { download, generateCsv, mkConfig } = ExportToCsv

  const pages = getPageData(sitemap);

  const csvConfig = mkConfig({
    filename: name,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    columnHeaders: Object.keys(pages[0]).map(p => { return { key: p, displayLabel: toTitleCase(p.replace(/([a-z])([A-Z])/g, '$1 $2')) } })
  });

  const csv = generateCsv(csvConfig)(pages);

  download(csvConfig)(csv)

  // const csvExporter = new ExportToCsv(options);
  // csvExporter.generateCsv(pages);

  // save data to firebase storage (no longer need this as Morten has churned)
  /* const csvData = csvExporter.generateCsv(pages, true);
  let blob = new Blob([csvData], { "type": "text/csv;charset=utf8;" });
  var storageRef = store.firebase.storage().ref().child(`/sitemaps/${sitemap?.id}/exports/${sitemap?.id}.csv`);
  storageRef.put(blob).then((snapshot) => {
    storageRef.getDownloadURL().then((url) => {
      if (url) return message.success(<><span style={{ fontWeight: 500, marginLeft: 12, marginRight: 12 }}>Export complete.</span><a style={{ fontWeight: 500 }} href={url}>Download Again</a></>, 3);
    });
  }); */

};

const getPageData = (sitemap) => {

  const { root, seo, website_sections } = sitemap?.data;
  //
  var pages = [];
  let maxDepth = 0;
  //
  function getPages(node) {
    const seoPageData = seo[node.id] ? seo[node.id] : {}
    // push al 
    const url = getURL(node.id);
    let path = url.replace(/^[^:]+:\/\/[^/?#]+/, '').replace(/^\//g, '/'); // if (!path) path = "/" // for homepage
    const paths = path.split("/");
    // calc max depth
    if ((paths.length - 1) > maxDepth) maxDepth++ // = paths.length - 1;
    // push page data
    const name = node.name // decodeURIComponentSafe(node.name)
    const parentName = node.parent ? node.parent.name : null; // decodeURIComponentSafe(node.parent.name) : null;
    pages.push({
      id: node.id,
      name,
      slug: node.parent ? `${name.replace(/\s/g, '-').toLowerCase()}` : '',
      parentId: node?.parent?.id || '',
      parentName,
      parentSlug: node.parent ? `${parentName.replace(/\s/g, '-').toLowerCase()}` : '',
      url,
      title: seoPageData?.title || '',
      description: seoPageData?.description || '',
      h1: seoPageData?.h1 || '',
      keywords: seoPageData?.keywords || '',
      path,
      paths,
      websiteSection: website_sections?.[node.website_section]?.title || ''
    });
    //
    if (node.children) node.children.forEach(getPages);
    if (node._children) node._children.forEach(getPages);
    // website_sections
    node.website_sections?.forEach(getPages);
  }
  //
  getPages(root);

  pages.map(p => {
    for (let i = 1; i <= maxDepth; i++) { p[`path depth (${i})`] = (i === p.paths.length - 1 ? p.path : ""); };
    delete p.path;
    delete p.paths;
    return p;
  })
  //
  return pages;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return (txt.charAt(0).toUpperCase() + (txt.substr(1) ? txt.substr(1) : "").toLowerCase());
  });
};