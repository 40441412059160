import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Spinner } from '@chakra-ui/react';
import { toggleExportCoversProgressModal, updateExportCoversProgressModalWithProgress } from '../../../../store/actions/sitemap-actions';
import { useGetSitemap, useInUserEditorDoc } from '../../../../hooks';

import React from 'react';
import { renderCover } from '../../../Sitemap/app/canvas/components';
import { store } from '../../../../store';
import { useDispatch } from 'react-redux';
import { wait } from '../../../../../shared/helpers';

export const ExportProgressModal = () => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()

    const inUserSitemap = useInUserEditorDoc()
    const progress = useGetExportProgress();

    const handleClose = () => dispatch(toggleExportCoversProgressModal({ showing: false, cancelled: true }));

    const { ExportCoversProgressModal, ExportPDFDrawer } = sitemap?.ui || {}
    if (ExportCoversProgressModal?.forThumbnail) return null
    
    return (
        <Modal
            isOpen={!inUserSitemap && ExportCoversProgressModal?.showing}
            onClose={handleClose}
            size="lg"
            returnFocusOnClose={false}
        >
            <ModalOverlay />
            <ModalContent rounded="lg" top={4} right={ExportPDFDrawer?.showing ? 152 : 0 }>
                <ModalHeader>Downloading Covers...</ModalHeader>
                <ModalBody py={3}>
                    <Progress size="sm" value={progress === 100 ? 99 : progress} rounded="md" />
                </ModalBody>
                <ModalFooter>
                    {progress < 100 && (
                        <Button colorScheme="red" variant="ghost" onClick={handleClose}>
                            Cancel
                        </Button>
                    )}
                    {progress === 100 && (
                        <Button variant="ghost" isLoading loadingText="Exporting" iconSpacing={4} />
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const useGetExportProgress = () => {
    const sitemap = useGetSitemap()
    const { ExportCoversProgressModal } = sitemap?.ui || {}
    const { progress } = ExportCoversProgressModal || 0
    return progress
}

export const showDownloadingCoversModal = async (context, { nodes, forThumbnail }) => {

    store.dispatch(toggleExportCoversProgressModal({ showing: true, forThumbnail })); // show

    if (!forThumbnail) await wait(500);

    /*** batch the download of custom covers so we don't hit rate limits ***/
    try {
        const coversCount = nodes.length;
        const chunkSize = 20;
        const clonedCoversData = [...nodes];
        const cacheKeysChunks = new Array(Math.ceil(clonedCoversData.length / chunkSize)).fill().map(_ => {
            return clonedCoversData.splice(0, chunkSize);
        });
        var downloadedCount = 0;
        for (let cacheKeys of cacheKeysChunks) {
            const { ExportCoversProgressModal } = store.getState().sitemap?.ui;
            if (!ExportCoversProgressModal.showing) return;
            await Promise.all(
                cacheKeys.map(async (d) => {
                    try {
                        await renderCover(context, d, false, true); // last true is export
                    } catch (e) {
                        console.error(e);
                    }
                })
            )
            downloadedCount = downloadedCount + cacheKeys.length;
            store.dispatch(updateExportCoversProgressModalWithProgress({ progress: Math.round((downloadedCount / coversCount) * 100) }));
        };
    } catch (e) {
        // console.error(e);
    }
    /*** batch the download of custom covers so we don't hit rate limits ***/
}