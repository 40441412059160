import { NewConnectorLineButton } from "./connectors/components/Button";
import React from "react"
import { useGetUserFlow } from "../../../../../../hooks";

export const SymbolButtons = () => {
    const flow = useGetUserFlow()
    const { node: d, section } = flow.ui.SymbolButtons;
    const { NewConnectorLine } = flow?.utils;
    return (
        <>
            {/* New Connector Line Buttons */}
            {!section && [...positions].map((pos, i) => (
                <NewConnectorLineButton key={i} d={d} startPos={pos} />
            ))}
            {section && ["left", "right"].map((pos, i) => (
                <NewConnectorLineButton key={i} d={d} startPos={pos} section={section} />
            ))}
            {/* New Connector Line Buttons */}
            {/* New Symbol Buttons */}
            {/*!NewConnectorLine.showing && !section && (
                [...positions].map((pos, i) => (
                    <NewSymbolButton key={i} d={d} startPos={pos} />
                ))
            ) */}
            {/* New Symbol Buttons */}
        </>
    )
}

const positions = [
    "top", "bottom", "left", "right"
]