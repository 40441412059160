import { getCanvasContainerOffsetTop, getRandomColor } from '../../../app/canvas/utils/helpers';
import { getConnectorX, getConnectorY, getMidXBetweenNodes, getMidYBetweenNodes, getStartConnectorIsBelowEndConnector, getStartConnectorIsToTheLeftOfEndConnector } from './helpers';
import { showOptionsOnClick, update } from '../../helpers';

import { dragging } from '../../../app/canvas/utils/drag';
import { drawBottomStarts } from './sides/bottom';
import { drawLeftStarts } from './sides/left';
import { drawRightStarts } from './sides/right';
import { drawTopStarts } from './sides/top';
import { event } from 'd3';
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers';
import { mouseover } from '../../../app/canvas/utils/listeners';
import { sortBy } from 'lodash'
import { store } from '../../../../../store';

export var colors = {};
const colorByNodeIds = {};

export const renderConnectors = (context, links, nodes, hidden) => {
    const sortedLinksByIndex = sortBy(links, [l => { return l.index ? l.index : 0 }]);
    //
    sortedLinksByIndex.forEach(link => {
        var startNode = dragging && dragging.started && link.start.id === dragging.id ? dragging : nodes.find(n => n.id === link.start.id)
        const startSection = getSectionData(startNode.sections, link.start.section);
        var endNode = dragging && dragging.started && link.end.id === dragging.id ? dragging : nodes.find(n => n.id === link.end.id)
        const endSection = getSectionData(endNode?.sections, link?.end?.section);
        if (startNode && endNode) {
            drawLinks({ context, link, startNode, startSection, startPos: link.start.position, endNode, endSection, endPos: link.end.position, type: link.type, hidden })
        }
    })
}

const getSectionData = (sections, sectionId) => {
    const sectionIdData = sections ? sections.find(s => s.id === sectionId) : null;
    return sectionIdData;
}

export const drawLinks = ({ context, link, startNode, startSection, startPos, endNode, endSection, endPos, type, hidden }) => {

    // link is link data (same format as stored in firestore)
    const { editor } = store.getState();

    /*** assign event colors ***/
    var randomColor;
    const colorId = `${startNode.id}#${startPos}-${endNode.id}#${endPos}`
    if (hidden) {
        if (!colorByNodeIds[colorId]) {
            /*** assign new random color to node id ***/
            randomColor = getRandomColor();
            colorByNodeIds[colorId] = randomColor;
            colors[randomColor] = { colorId, link };
            /*** assign new random color to node id ***/
        } else {
            randomColor = colorByNodeIds[colorId]; // use existing assigned color
            colors[randomColor] = { colorId, link }; // ensure connector is updated with latest data - IMPORTANT - TEST THIS IS WORKING BY CHANGE CONNECTOR TYPE AND IMMEDIATELY CHANGING BACK - IT SHOULD SHOW THE UPDATED TYPE WHEN OPENING CONNECTOR OPTIONS A SECOND TIME
        }
    };
    /*** assign event colors ***/

    /*** draw lines ***/
    context.lineCap = "butt"
    context.strokeStyle = hidden ? randomColor : getConnectorColor({ link, editor })
    context.fillStyle = "transparent"
    if (mouseover.userFlowConnector && mouseover.userFlowConnector.colorId === colorId) {
        context.lineWidth = !hidden ? 4 : 25
    } else {
        context.lineWidth = !hidden ? 2 : 25
    }
    context.beginPath();

    if (type === 'dash' && !hidden) context.setLineDash([12, 6]);

    const startConnectorX = getConnectorX(startNode, startSection, startPos)
    const endConnectorX = getConnectorX(endNode, endSection, endPos, true /* endConnector */)
    const startConnectorY = getConnectorY(startNode, startSection, startPos)
    const endConnectorY = getConnectorY(endNode, endSection, endPos, true /* endConnector */)

    const minPadding = 30

    const startConnectorIsToTheLeftOfEndConnector = getStartConnectorIsToTheLeftOfEndConnector(startConnectorX, endConnectorX)
    const startConnectorIsBelowEndConnector = getStartConnectorIsBelowEndConnector(startConnectorY, endConnectorY, minPadding);

    const midXBetweenNodes = getMidXBetweenNodes(startNode, endNode)
    const midYBetweenNodes = getMidYBetweenNodes(startNode, endNode, startConnectorIsBelowEndConnector)

    const values = { endPos, startNode, endNode, startConnectorX, endConnectorX, startConnectorY, endConnectorY, minPadding, startConnectorIsToTheLeftOfEndConnector, startConnectorIsBelowEndConnector, midXBetweenNodes, midYBetweenNodes }

    switch (startPos) {
        case "bottom":
            drawBottomStarts(context, values)
            break;
        case "top":
            drawTopStarts(context, values)
            break;
        case "left":
            drawLeftStarts(context, values)
            break;
        case "right":
            drawRightStarts(context, values)
            break;
        default:
            context.moveTo(startConnectorX, startConnectorY);
            context.lineTo(endConnectorX, endConnectorY)
            break;
    }

    context.stroke();

    context.setLineDash([]); // reset line dash

    if (!hidden) drawArrowhead({
        context,
        to: {
            x: endConnectorX + (["left", "right"].includes(endPos) ? endPos === "left" ? -5 : 5 : 0) + (endSection ? (endPos === "left" ? 21 : -21) : 0), // for arrowhead
            y: endConnectorY + (["top", "bottom"].includes(endPos) ? endPos === "top" ? -5 : 5 : 0)
        },
        startConnectorX,
        startConnectorY,
        startSection,
        startPos,
        endSection,
        endPos,
        radius: 7.5,
        fillStyle: getConnectorColor({ link, editor })
    })

    /*** draw lines ***/
}

const getConnectorColor = ({ link, editor }) => {
    return link.color ? link.color : editor?.pallette?.header
}

export const drawArrowhead = ({ context, from, to, startPos, endPos, startSection, endSection, startConnectorX,
    startConnectorY, radius, fillStyle }) => {

    const { colorMode } = store.getState().ui;

    if (startSection || endSection) {

        const start = startSection ? { x: startConnectorX, y: startConnectorY } : to;

        const startX = start?.x + (startSection ? startPos === "left" ? 0 : -23 : (endPos === "right" ? 16 : -16));
        const endX = start?.x - (startSection ? startPos === "left" ? -23 : 0 : (endPos === "right" ? -3.5 : 3.5));

        const LINE_WIDTH = context.lineWidth;

        /*** border ***/
        context.beginPath();
        context.lineWidth = LINE_WIDTH * (LINE_WIDTH === 2 ? 5 : 2.5);
        context.strokeStyle = colorMode === "light" ? "white" : "#1A202C";
        context.moveTo(startX, start?.y);
        context.lineTo(endX, start?.y);
        context.stroke();
        /*** border ***/

        /*** connector ***/
        context.lineWidth = LINE_WIDTH;
        context.strokeStyle = fillStyle;
        context.stroke();
        /*** connector ***/
    }

    var x_center = to.x;
    var y_center = to.y;

    var angle;
    var x;
    var y;

    context.beginPath();

    angle = !endPos ? Math.atan2(to.y - from.y, to.x - from.x) : endPos === "left" ? [0, 0] : endPos === "right" ? [0, -90] : endPos === "top" ? [90, 0] : [-90, 0];
    if (endPos) angle = Math.atan2(angle[0], angle[1])
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    context.fillStyle = fillStyle

    context.moveTo(x, y);

    angle += (1.0 / 3.0) * (2 * Math.PI)
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    context.lineTo(x, y);

    angle += (1.0 / 3.0) * (2 * Math.PI)
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    context.lineTo(x, y);

    context.closePath();

    context.fill();
    context.fillStyle = "transparent"

}

export const mouseoverUserFlowConnector = (colKey) => {
    const canEdit = getCanEditInEditor();
    if (!canEdit) return;
    // user can edit
    var { colorId, link } = colors[colKey] || {}
    if (link) return { colorId, link }
}

export const clickUserFlowConnector = ({ link }) => {
    const canEdit = getCanEditInEditor();
    if (!canEdit) return;
    const containerOffsetTop = getCanvasContainerOffsetTop()
    // show options menu
    showOptionsOnClick({ links: [link], connectorOptions: true, x: event.offsetX, y: (event.offsetY + containerOffsetTop) - 54 }); // 54 is height of options popover
};