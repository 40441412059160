import { useRef } from "react"
import { Icon, Stack, Text, Tooltip } from "@chakra-ui/react"
import { BsFolderFill } from "react-icons/bs"
import { IoChevronForward } from "react-icons/io5"
import { useMoveToFolderContext } from "../../../../../../Projects/Folders/hooks"
import { useGetItems, useIsTextOverflown } from "../../../../../../../hooks"

const PX = 3
const PY = 2
const H = "56px"

export const FolderItem = ({ folder }) => {
    const { showDetailScreen, setShowDetailScreen, setSearchInputVal } = useMoveToFolderContext()
    const items = useGetItems()
    const nameElementRef = useRef(null)
    const isNameOverflown = useIsTextOverflown(nameElementRef)
    const count = items?.filter(f => f.team === folder.id)?.length || 0
    const { name } = folder || {}
    const fromAllTab = folder?.id?.startsWith('projects/')
    return (
        <Tooltip label={name} isDisabled={!isNameOverflown} variant="rounded-sm" openDelay={500} hasArrow>
        <Stack
            px={PX}
            py={PY}
            _hover={{ cursor: 'pointer', bgColor: 'rarchy-bg-subtle-hover' }}
            onClick={() => {
                const history = showDetailScreen?.history || [];
                history.push(folder?.id)
                setSearchInputVal('')
                setShowDetailScreen({ inFolder: folder?.id, history })
            }}
            w="full" direction="row" h={H} align="center" justify="space-between">
                <Stack direction="row" align="center">
                    <Icon as={BsFolderFill} w="40px" h="40px" color="rarchy-color-folder" />
                    {/* <Icon as={HiOutlineFolderOpen} fontSize="md" pos="absolute" left={2} /> */}
                    <Stack spacing={0}>
                        <Text noOfLines={1} ref={nameElementRef}>{name}</Text>
                        {!fromAllTab && (
                            <Text
                                fontSize="xs"
                                isTruncated
                                noOfLines={1}
                                fontWeight="normal"
                                color="fg.subtle"
                            >
                                {`${count} item${count !== 1 ? 's' : ''}`}
                            </Text>
                        )}
                    </Stack>
                </Stack>
            <Icon as={IoChevronForward} opacity={1} fontSize="md" />
        </Stack>
            </Tooltip>
    )
}