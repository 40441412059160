import { Button, FormControl, Input, Stack, Text, useBoolean } from "@chakra-ui/react"
import { useGetOrganization, useGetUserId, useIsOrganizationAdmin } from "../../../../hooks"
import { SectionHeader } from "../../Components"
import { Formik } from "formik"
import { wait } from "../../../../../shared/helpers"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { mergeOrganizationChange } from "../../../../store/actions/organization-actions"
import { useDispatch } from "react-redux"

export const Website = () => {
    const dispatch = useDispatch()
    const [isEditing, setIsEditing] = useBoolean()
    const userId = useGetUserId()
    const organization = useGetOrganization()
    const isAdmin = useIsOrganizationAdmin()
    const domain = organization?.domain
    return (
        <Stack spacing={3} mt={1.5}>
            <SectionHeader title="Website" />
            <Stack direction="row" w="full" justify="space-between">
                {!isEditing && (
                    <>
                        <Text fontSize="sm">{domain}</Text>
                        {isAdmin && <Button fontSize="sm" px={6} onClick={setIsEditing.toggle}>Edit</Button>}
                    </>
                )}
                {isEditing && (
                    <Formik
                        initialValues={{ domain }}
                        onSubmit={async (values, actions) => {
                            try {
                                let { domain } = values;
                                // can't be empty, revert to current name if empty
                                if (!domain) domain = organization?.domain;
                                // no changes, don't continue
                                if (organization?.domain === domain) return;
                                // wait a bit
                                await wait(500)
                                // save
                                const ref = doc(getFirestore(), 'organizations', organization?.id);
                                await setDoc(ref, { domain, updatedAt: new Date(), updatedBy: userId }, { merge: true });
                                dispatch(mergeOrganizationChange({ domain }))
                            } catch (e) {

                            } finally {
                                setIsEditing.toggle()
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            return (
                                <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                                    <Stack direction="row" w="full" spacing={3}>
                                        <FormControl id="domain">
                                            <Input
                                                defaultValue={values?.domain}
                                                fontSize="sm"
                                                name="domain"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isRequired
                                                placeholder="Enter your organizations website"
                                            />
                                        </FormControl>
                                        <Stack direction="row" spacing={3}>
                                            <Button fontSize="sm" px={6} onClick={setIsEditing.toggle}>Cancel</Button>
                                            <Button fontSize="sm" colorScheme="blue" px={6} onClick={(e) => { handleSubmit(e); }} isLoading={isSubmitting}>Save</Button>
                                        </Stack>
                                    </Stack>
                                </form>
                            )
                        }}
                    </Formik>
                )}
            </Stack>
        </Stack>
    )
}