import {
    DrawerHeader,
    Flex,
    HStack,
    Heading,
    Text
} from "@chakra-ui/react"

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const Header = ({ isAutocapturing, hasAutocaptured, autocaptured }) => {
    return (
        <DrawerHeader fontSize="xl" borderBottomWidth="0px" px={6} mt={.5} pb={1}>
            <Flex alignItems="center" justifyContent="space-between">
                <HStack spacing={4}>
                    <Heading
                        size="sm"
                        fontWeight="semibold"
                    >
                        {renderTitle({ isAutocapturing, hasAutocaptured, autocaptured })}
                    </Heading>
                    {hasAutocaptured && (
                        <Text
                            fontWeight="medium"
                            fontSize="sm"
                            color="fg.muted"
                        >
                            {`${dayjs(autocaptured?.capturedAt?.toDate()).format('lll')}`}
                        </Text>
                    )}
                </HStack>
            </Flex>
        </DrawerHeader>
    )
}

const renderTitle = ({ isAutocapturing, hasAutocaptured }) => {
    if (isAutocapturing) return 'Capturing screenshots...'
    if (hasAutocaptured) return `Captured`
    return 'Capture screenshots'
}