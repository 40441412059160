import {
    Stack,
    StackDivider,
} from '@chakra-ui/react'

import { BasicAuth } from './BasicAuth'
import { RespectRobots } from './RespectRobots'
import { SEO } from './SEO'



export const Settings = ({ existingSitemap }) => {
    return (
        <Stack w="full" spacing={7} divider={<StackDivider />}>
            <SEO existingSitemap={existingSitemap} />
            <BasicAuth existingSitemap={existingSitemap} />
            <RespectRobots existingSitemap={existingSitemap} />
        </Stack>
    )
}