import React, { useState, useLayoutEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import { transform } from "../../../../../../app/canvas/utils/zoom";
import { addFlowChange, changedConnector, insertNewSymbol } from "../../../../../../../../store/actions/flow-actions";
import { getConnectorX, getConnectorY } from "../../../../connectors/helpers";
import { useGetEditor, useGetSitemap, useGetUserFlow } from "../../../../../../../../hooks";
import { addConnectorChange, addSymbolChange } from "../../../../../helpers";

export const NewSymbolButton = ({ d, startPos }) => {

    const dispatch = useDispatch()

    const editor = useGetEditor()
    const flow = useGetUserFlow()

    const [mouseover, setMouseover] = useState(false)
    const [transparent, setTransparent] = useState(false)
    useLayoutEffect(() => { return () => { if (mouseover) setMouseover(false); if (transparent) setTransparent(false) } }, [mouseover, transparent])

    const BUTTON_WIDTH = 22;
    var targetButtonWidth = Math.ceil((!mouseover ? BUTTON_WIDTH : (BUTTON_WIDTH * 1.1)) * transform.k)
    var maxButtonWidth = !mouseover ? (BUTTON_WIDTH / 3) : ((BUTTON_WIDTH / 3) * 1.2);
    targetButtonWidth = targetButtonWidth < maxButtonWidth ? maxButtonWidth : targetButtonWidth // ensure this is never under 7
    const buttonWidth = (targetButtonWidth % 2 == 0) ? targetButtonWidth : targetButtonWidth + 1

    var hasConnectorToOrFrom = false;

    flow.data?.links?.forEach(l => {
        if (l.start.id === d.id && l.start.position === startPos || l.end.id === d.id && l.end.position === startPos) {
            hasConnectorToOrFrom = true;
        }
    });

    if (hasConnectorToOrFrom) return null;

    const left = getLeft({ d, startPos, buttonWidth }), top = getTop({ d, startPos, buttonWidth });

    return (
        <IconButton
            h={`${buttonWidth}px`}
            minW={`${buttonWidth}px`}
            left={left}
            top={top}
            bgColor={editor?.pallette?.colors?.[4]}
            icon={<AddIcon fontSize={`${transform.k * 12}px`} />}
            color={mode("white", "gray.800")}
            rounded="50%"
            pos="absolute"
            paddingInlineStart={0}
            paddingInlineEnd={0}
            onMouseEnter={() => setMouseover(true)}
            onMouseLeave={() => setMouseover(false)}
            onClick={(e) => {
                e.preventDefault()
                handleNewSymbolAndConnectorClick(dispatch, d, startPos)
            }}
            _hover={{ bgColor: transparent ? 'transparent' : editor?.pallette?.color?.[6], color: transparent ? 'transparent' : 'white' }}
        />
    )
}

const LEFT_TOP_OFFSET = 35;

const getLeft = ({ d, startPos, buttonWidth }) => {
    const offset = startPos === "left" ? -LEFT_TOP_OFFSET : startPos === "right" ? LEFT_TOP_OFFSET : 0;
    return transform.applyX(getConnectorX(d, null, startPos) + offset) - buttonWidth / 2
}

const getTop = ({ d, startPos, buttonWidth }) => {
    const offset = startPos === "top" ? -LEFT_TOP_OFFSET : startPos === "bottom" ? LEFT_TOP_OFFSET : 0;
    return transform.applyY(getConnectorY(d, null, startPos) + offset) - buttonWidth / 2
}

const handleNewSymbolAndConnectorClick = (dispatch, start, fromPosition) => {

    const toPosition = getToPosition(fromPosition);

    /*** new symbol ***/
    const newNodeId = randomString(10);
    // get line type
    const defaultSymbol = localStorage.getItem(`userFlowDefaultSymbol`);
    const newSymbol = defaultSymbol ? defaultSymbol : 'action';
    var symbol = { type: newSymbol, x: getX(fromPosition, start), y: getY(fromPosition, start, newSymbol) };
    if (newSymbol === 'page') symbol.text = 'Page';
    /*** new symbol ***/

    /*** new connector ***/
    const lineData = { [newNodeId]: { position: toPosition } };
    const lineType = localStorage.getItem(`userFlowConnectorType`);
    if (lineType && lineType !== 'solid') lineData[newNodeId].type = lineType;
    const connectorData = { connectors: { [fromPosition]: lineData } };
    /*** new connector ***/

    /*** dispatch so we can render immediately ***/
    addSymbolChange({ data: { [newNodeId]: symbol } })
    addConnectorChange({ data: { [start.id]: connectorData } })
    // dispatch(insertNewSymbol({ [newNodeId]: symbol }));
    // dispatch(changedConnector({ [start.id]: { ...connectorData } }));
    /*** dispatch so we can render immediately ***/

    /*** save ***/
    const change = [
        {
            id: new Date().getTime(), data: [{ id: newNodeId, action: 'new-symbol', ...symbol }]
        }, {
            id: new Date().getTime() + 1, data: [{ id: start.id, action: 'connector-change', ...connectorData }]
        },
    ];
    const history = [{
        action: 'new-symbol-and-connector',
        nodes: { symbol: newNodeId, connector: start.id },
        data: {
            oldSymbol: { [newNodeId]: undefined },
            newSymbol: { ...symbol },
            oldConnector: { connectors: { [fromPosition]: { [newNodeId]: undefined } } },
            newConnector: { ...connectorData }
        }
    }];
    console.log(history);
    dispatch(addFlowChange({ change, history }));
    /*** save ***/

    function getToPosition(position) {
        if (position.includes('top')) return 'bottom';
        if (position.includes('bottom')) return 'top';
        if (position.includes('left')) return 'right';
        if (position.includes('right')) return 'left';
    };

    function getX(position, node) {
        if (position.includes('left')) return node.x - 360;
        if (position.includes('right')) return node.x + 360;
        return node.x;
    };

    function getY(position, node, newSymbol) {
        const additional = node.type === 'page' ? newSymbol === 'page' && position === 'bottom' ? (87.5 * 2) : 87.5 : 0;
        if (position.includes('top')) return node.y - (newSymbol === 'page' ? 287.5 + additional : 200 + additional);
        if (position.includes('bottom')) {
            return node.y + (newSymbol === 'page' && node.type !== 'page' ? 287.5 + additional : 200 + additional);
        }
        return node.y;
    };
}

export const randomString = (len, charSet) => {
    charSet = charSet || 'abcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
};