import { ARC_TO_RADIUS } from "../helpers";
// test config by dragging end node - NOT start node
export const drawLeftStarts = (context, values) => {
    const { endPos, startNode, endNode, startConnectorX, endConnectorX, startConnectorY, endConnectorY, minPadding, startConnectorIsToTheLeftOfEndConnector, startConnectorIsBelowEndConnector, midXBetweenNodes, midYBetweenNodes } = values;
    // always start drawing here
    context.moveTo(startConnectorX, startConnectorY);
    // bottom
    if (endPos === 'bottom') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY < startNode.topOfNode) {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, startNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, startNode.topOfNode - minPadding, midXBetweenNodes, startNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startNode.topOfNode - minPadding, midXBetweenNodes, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) context.arcTo(endConnectorX, startConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                const startX = endNode.leftOfNode > startNode.leftOfNode ? startNode.leftOfNode : endNode.leftOfNode;
                const endY = startNode.bottomOfNode > endConnectorY ? startNode.bottomOfNode : endConnectorY
                context.arcTo(startX - minPadding, startConnectorY, startX - minPadding, endY + minPadding, ARC_TO_RADIUS)
                context.arcTo(startX - minPadding, endY + minPadding, endConnectorX, endY + minPadding, ARC_TO_RADIUS)
                context.arcTo(endConnectorX, endY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (endNode.rightOfNode > startNode.leftOfNode - minPadding) {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(midXBetweenNodes, startConnectorY, midXBetweenNodes, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    // top
    if (endPos === 'top') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                const endY = endConnectorY < startNode.topOfNode ? endConnectorY : startNode.topOfNode
                const startX = endNode.leftOfNode > startNode.leftOfNode ? startConnectorX : endNode.leftOfNode
                context.arcTo(startX - minPadding, startConnectorY, startX - minPadding, endY - minPadding, ARC_TO_RADIUS)
                context.arcTo(startX - minPadding, endY - minPadding, endConnectorX, endY - minPadding, ARC_TO_RADIUS)
                context.arcTo(endConnectorX, endY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (endNode.rightOfNode < startConnectorX - minPadding) {
                    context.arcTo(midXBetweenNodes, startConnectorY, midXBetweenNodes, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, endConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, endConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) context.arcTo(endConnectorX, startConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY > startNode.bottomOfNode + minPadding) {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, startNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, startNode.bottomOfNode + minPadding, midXBetweenNodes, startNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startNode.bottomOfNode + minPadding, midXBetweenNodes, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, endConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    // left
    if (endPos === "left") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY + minPadding < startNode.topOfNode) {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, endConnectorY, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, startNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, startNode.topOfNode - minPadding, midXBetweenNodes, startNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startNode.topOfNode - minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(endConnectorX - minPadding, startConnectorY, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(endConnectorX - minPadding, startConnectorY, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY > startNode.bottomOfNode) {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, startNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, startNode.bottomOfNode + minPadding, midXBetweenNodes, startNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startNode.bottomOfNode + minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    if (endPos === "right") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endNode.bottomOfNode + minPadding > startNode.topOfNode) {
                    const endY = endNode.topOfNode > startNode.topOfNode ? startNode.topOfNode : endNode.topOfNode
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, endY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, endY - minPadding, endConnectorX + minPadding, endY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endY - minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, midYBetweenNodes, endConnectorX + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, midYBetweenNodes, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(midXBetweenNodes, startConnectorY, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                context.arcTo(midXBetweenNodes, startConnectorY, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.bottomOfNode < endNode.topOfNode) {
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, midYBetweenNodes, endConnectorX + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, midYBetweenNodes, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    const endY = endNode.bottomOfNode < startNode.bottomOfNode ? startNode.bottomOfNode : endNode.bottomOfNode
                    context.arcTo(startConnectorX - minPadding, startConnectorY, startConnectorX - minPadding, endY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX - minPadding, endY + minPadding, endConnectorX + minPadding, endY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endY + minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    context.lineTo(endConnectorX, endConnectorY); // always end here
}