

export const changeProjectsView = (view) => {
  return (dispatch, getState) => {
    localStorage.setItem(`projectsView`, view)
    dispatch({ type: 'CHANGE_PROJECTS_VIEW', view })
  };
};

export const changeProjectsSortBy = (sortBy) => {
  return (dispatch, getState) => {
    dispatch({ type: 'CHANGE_PROJECTS_SORT_BY', sortBy })
  };
};

export const changeProjectsOwner = (owner) => {
  return (dispatch, getState) => {
    dispatch({ type: 'CHANGE_PROJECTS_OWNER', owner })
  };
};

export const changeProjectsModified = (modified) => {
  return (dispatch, getState) => {
    dispatch({ type: 'CHANGE_PROJECTS_MODIFIED', modified })
  };
};