import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons'
import {
    Button,
    Center,
    HStack,
    Icon,
    Square,
    Text,
    useColorModeValue as mode,
    VStack,
    Divider,
    Spinner,
    Progress,
    Flex,
    IconButton,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { isEmpty } from 'lodash'

export const DropzoneComponent = ({ attachFilesToForm, ...rest }) => {
    const bgColor = mode('white', 'gray.800')
    const [uploading, setUploading] = useState([])
    return (
        <>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    let list = new DataTransfer();
                    acceptedFiles.forEach(file => {
                        list.items.add(file)
                        setUploading([...uploading, { name: file?.name, progress: 100 }])
                    })
                    attachFilesToForm(list.files);
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <Center
                        borderWidth="1px"
                        rounded="lg"
                        p="6"
                        py="9"
                        bg={bgColor}
                        _hover={{ cursor: 'pointer' }}
                        {...rest}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <VStack spacing="2">
                            <Square size="6" bg="bg-subtle" rounded="lg">
                                <Icon as={AiOutlineCloudUpload} boxSize="5" color="fg.muted" />
                            </Square>
                            <VStack spacing="2">
                                <HStack spacing="1" whiteSpace="nowrap">
                                    <Button variant="link" colorScheme="blue" size="sm">
                                        Click to upload
                                    </Button>
                                    <Text fontSize="sm" color="fg.muted">
                                        or drag and drop files
                                    </Text>
                                </HStack>
                            </VStack>
                        </VStack>
                    </Center>
                )}
            </Dropzone>
            {!isEmpty(uploading) && (
                <VStack divider={<Divider />} align="start" w="full">
                    {uploading?.map(upload => {
                        return (
                            <HStack spacing={4} p={2} w="full">
                                {upload?.progress < 100 && <Spinner size="sm" color="rarchy-link" />}
                                {upload?.progress === 100 && <CheckCircleIcon fontSize="lg" color="green.500" />}
                                <VStack w="full" alignItems="start">
                                    <HStack w="full" justifyContent="space-between">
                                        <Text noOfLines={1} fontSize="13px">{upload?.name}</Text>
                                        <Flex><IconButton size="xs" variant="ghost" icon={<CloseIcon />} onClick={() => {
                                            setUploading(uploading.filter(item => item.name !== upload.name))
                                        }} /></Flex>
                                    </HStack>
                                    <Progress w="full" rounded="md" colorScheme={upload?.progress === 100 ? 'green' : 'twitter'} size='xs' value={upload?.progress} />
                                </VStack>
                            </HStack>
                        )
                    })}
                </VStack>
            )}
        </>
    )
}