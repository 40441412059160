import {
    Box,
    ListItem,
    UnorderedList,
    useColorModeValue as mode
} from "@chakra-ui/react"
import React, { useEffect, useState } from 'react'
import { handleEditSubmit, handleReplySubmit, renderComments } from '../helpers.jsx'

import { Comment } from './Components/Comment'
import { Header } from './Components/Header'
import { TextArea } from '../Components/TextArea'
import { animateScroll } from "react-scroll";
import { usePrevious } from '../../../../hooks/index.js'

export const PageComments = ({ sitemap, page }) => {

    const { PageCommentsPopover, PageCommentsDrawer } = sitemap?.ui;

    const [editing, setEditing] = useState(false)
    page = page ? page : PageCommentsPopover?.page // so we can also use this component in navbar drawer

    const comments = renderComments(page)
    const prevComments = usePrevious(comments)
    // scroll to the bottom on mount
    useEffect(() => scrollToBottom(), [])
    // scroll to the bottom on new comment
    useEffect(() => { if (prevComments && (prevComments.length < comments.length)) scrollToBottom() })
    // reply / edit submit
    const handleSubmit = (comment) => !editing ? handleReplySubmit(comment, page) : handleEditSubmit(editing, comment, page)
    const inDrawer = PageCommentsDrawer?.showing
    return (
        <Box>
            <UnorderedList ml={0} maxH={!inDrawer ? "40vh" : "auto"} overflowY="scroll" id="page-comments-list">
                {comments.map((comment, i) => {
                    return (
                        <ListItem key={comment.id} listStyleType="none">
                            <Box pt={i === 0 ? 0 : 2} pb={i === comments.length - 1 ? 0 : 2} borderBottom="1px solid" borderColor={i === comments.length - 1 ? "transparent" : mode("gray.200", "gray.600")}>
                                <Header comment={comment} page={page} setEditing={setEditing} inDrawer={inDrawer} />
                                {editing !== comment.id && <Comment comment={comment} />}
                                {editing && editing === comment.id && <TextArea view="edit" page={page} comment={comment.comment.string} setEditing={setEditing} handleSubmit={handleSubmit} />}
                            </Box>
                            {/* i !== comments.length - 1 && <Divider />*/}
                        </ListItem>
                    )
                })}
            </UnorderedList>
            {!editing && <Box my={1}><TextArea view="reply" page={page} handleSubmit={handleSubmit} /></Box>}
        </Box>
    )
}

const scrollToBottom = () => {
    animateScroll.scrollToBottom({ containerId: "page-comments-list", duration: 0 });
}