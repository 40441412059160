import { ArrowBackIcon } from "@chakra-ui/icons"
import { Box, HStack, Heading, IconButton, Stack } from "@chakra-ui/react"
import { useEffect } from "react"
import { SearchFolders } from "./Components/Search"
import { Footer } from "./Components/Footer"
import { Folders } from "./Components/Folders"
import { CreateNewScreen } from "./Components/Footer/New"
import { useMoveToFolderContext } from "../../../Projects/Folders/hooks"

export const MoveToFolderScreen = () => {
    const { setShowMoveToFolderScreen, showCreateNewScreen } = useMoveToFolderContext()
    ResetOnUnmount()
    return (
        <Stack p={0} spacing={3} justifyContent="space-between">
            <HStack px={3}>
                <IconButton variant="ghost" size="sm" icon={<ArrowBackIcon fontSize="xl" />} onClick={setShowMoveToFolderScreen.off} />
                <Heading fontSize="md" fontWeight="semibold">Move to folder</Heading>
            </HStack>
            <Box pos="relative" pb={`${showCreateNewScreen ? 0 : 64}px`} px={0}>
                {!showCreateNewScreen && (
                    <>
                        <SearchFolders />
                        <Folders />
                        <Footer />
                    </>
                )}
                {showCreateNewScreen && <CreateNewScreen />}
            </Box>
        </Stack>
    )
}

const ResetOnUnmount = () => {
    const { setShowMoveToFolderScreen, setTabIndex, setShowCreateNewScreen, setShowDetailScreen } = useMoveToFolderContext()
    useEffect(() => {
        return () => {
            setTimeout(() => {
                setShowMoveToFolderScreen.off()
                setTabIndex(0)
                setShowCreateNewScreen.off()
                setShowDetailScreen(false) // object is provided when true
            }, 250);
        }
    }, [setShowMoveToFolderScreen, setTabIndex, setShowDetailScreen])
}