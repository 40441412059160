import { Box, UnorderedList, VStack } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../hooks"
import { TRIAL_DAYS, useCanStartNewTrial, useGetPagesImportLimitByPlan } from "../../../../shared/hooks/pricing"
import { toTitleCase } from "../../../helpers"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { CollaborateWithOthersListItem, FolderPermissionsListItem, ScreenshotsPageColorsAndMoreListItem } from "../Components/Messaging/ListItems"

export const PagesPerImport = () => {
    const canStartNewTrial = useCanStartNewTrial()
    const { planId } = useGetUpgradeModal()
    const pages_per_import = useGetPagesImportLimitByPlan({ planId })
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                {`Import up to `}<strong>{pages_per_import?.toLocaleString()} pages per sitemap</strong> {` with Rarchy ${toTitleCase(planId)}. ${canStartNewTrial ? `Try for free for ${TRIAL_DAYS} days.` : 'Upgrade your plan today.'}`}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                <CollaborateWithOthersListItem />
                <FolderPermissionsListItem />
                <ScreenshotsPageColorsAndMoreListItem />
            </UnorderedList>
        </VStack>
    )
}