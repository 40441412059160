import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Heading, InputGroup, InputLeftElement, Input, Stack, IconButton, Icon, Text, Button, Tooltip, Skeleton, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetSitemapImportHistory } from "../../../../../hooks";

import { useDispatch } from "react-redux";
import { getImportedSitemaps } from "../../../../../store/actions/sitemaps-actions";

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { SearchIcon } from "@chakra-ui/icons";
import { BsArrowsAngleContract, BsArrowsAngleExpand, BsQuestionCircle } from "react-icons/bs";
import { toggleNewSitemapModal } from "../../../../../store/actions/ui-actions";
import { convertObjToArrayWithKey, getImportingWebsiteLabel } from "../../../../../helpers";
import { UpgradePopover } from "../../../Import/Shared/Status/UpgradePopover";
import { HistoryMenu } from "./Menu";
import { getIsImportCountOverLimit } from "../helpers";

import { getTypeLabel } from "../Detail";
import { isEmpty, orderBy, filter } from 'lodash'

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

const DEFAULT_WIDTH = "5xl"
const DUMMY_LOADING_DATA = [
    { id: 1, url: 'a', status: 'a', type: 'a', retrieved: { count: 0 } },
    { id: 2, url: 'a', status: 'a', type: 'a', retrieved: { count: 0 } },
    { id: 3, url: 'a', status: 'a', type: 'a', retrieved: { count: 0 } }
]

export const ImportHistory = () => {
    const dispatch = useDispatch()
    const imported = useGetSitemapImportHistory()
    const data = convertObjToArrayWithKey({ ...imported?.byId })
    const [filteredSearchData, setFilteredSearchData] = useState(null)
    useEffect(() => {
        if (!imported?.loaded) dispatch(getImportedSitemaps())
    }, [])
    // reset once closed
    useEffect(() => {
        return () => {
            if (!isEmpty(data)) setFilteredSearchData(null)
        }
    }, []);
    const [width, setWidth] = useState(DEFAULT_WIDTH)
    const ExpandContractIcon = (width === DEFAULT_WIDTH ? BsArrowsAngleExpand : BsArrowsAngleContract)
    const orderedData = !imported?.loaded ? DUMMY_LOADING_DATA : orderBy((filteredSearchData || [...data]), 'createdAt', 'desc');
    return (
        <Flex w="full" justify="center" mt={10} px={20}>
            <Box w={width}>
                <Stack mb={6} w="full" direction="row" justify="end">
                    <VStack w="full" align="start" spacing={5}>
                        <Heading mb={0} fontSize="2xl">History</Heading>
                        <InputGroup size="sm" w="2xs">
                            <InputLeftElement w={5} pointerEvents="none" children={<SearchIcon color="gray.400" />} />
                            <Input
                                type="text"
                                variant="flushed"
                                fontSize="md"
                                placeholder={`Search ${data?.length || 0} imports...`}
                                pl="30px"
                                onChange={(e) => {
                                    const inputVal = e.target.value.toLowerCase();
                                    const filtered = filter(data, (o) => {
                                        if (o?.url?.toLowerCase()?.includes(inputVal)) return o;
                                        if (o?.status?.toLowerCase()?.includes(inputVal)) return o;
                                        if (o?.type?.toLowerCase()?.includes(inputVal)) return o;
                                        if (o?.retrieved?.count?.toString()?.includes(inputVal?.toString())) return o;
                                    });
                                    setFilteredSearchData(!isEmpty(filtered) ? filtered : null);
                                }}
                            />
                        </InputGroup>
                    </VStack>
                    <Stack direction="row" spacing={3} align="end">
                        <IconButton variant="ghost" icon={<Icon as={ExpandContractIcon} fontSize="xl" transform="rotate(45deg)" />} onClick={() => {
                            setWidth(width === DEFAULT_WIDTH ? "full" : DEFAULT_WIDTH)
                        }} />
                    </Stack>
                </Stack>
                <TableContainer w={width} pb={16}>
                    <Table size='sm' display="table">
                        <Thead>
                            <Tr>
                                <Th>Website</Th>
                                <Th>Status</Th>
                                <Th>Type</Th>
                                <Th textAlign="center">Pages</Th>
                                <Th>Completed</Th>
                                <Th />
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isEmpty(orderedData) && <Box py={7} pl={5} color="fg.subtle" key="empty">Your import history will be displayed here</Box>}
                            {orderedData?.map(d => {
                                const created = `${dayjs(d.createdAt?.toDate()?.toString())?.locale('en-long')?.fromNow()}`
                                const status = getStatus(d)
                                const statusIcon = getStatusIcon(status)
                                const isIncomplete = status === 'incomplete'
                                return (
                                    <Tr key={d.id} display="table-row" verticalAlign="middle" w="full" _hover={{ bg: 'rarchy-bg-subtle', cursor: 'pointer' }} h="60px" onClick={() => {
                                        dispatch(toggleNewSitemapModal({ showing: true, fromHistory: true, showDetailScreen: true, detailId: d.id }))
                                    }}>
                                        <Tooltip
                                            offset={[7, -7]}
                                            variant="rounded-sm"
                                            label={getImportingWebsiteLabel(d)} placement="bottom-start"
                                            openDelay={500}
                                        >
                                            <Td display="table-cell" fontSize="md" fontWeight="medium">
                                                <SkeletonWrapper>
                                                    <Text maxW="md" noOfLines={1}>
                                                        {getImportingWebsiteLabel(d)}
                                                    </Text>
                                                </SkeletonWrapper>
                                            </Td>
                                        </Tooltip>
                                        <Td display="table-cell">
                                            <SkeletonWrapper>
                                                {!isIncomplete && (
                                                    <Stack direction="row" align='center'>
                                                        {statusIcon}
                                                        <Text textTransform="capitalize">{status}</Text>
                                                    </Stack>
                                                )}
                                                {isIncomplete && (
                                                    <UpgradePopover
                                                        finishedEarly
                                                        button={
                                                            <Button
                                                                textTransform="capitalize"
                                                                fontWeight="normal"
                                                                size="sm"
                                                                variant="link"
                                                                rightIcon={statusIcon}
                                                                color="unset"
                                                                textDecor="underline"
                                                                textDecorationColor="rarchy-border-400"
                                                                textUnderlineOffset={4}
                                                            >
                                                                {status}
                                                            </Button>
                                                        }
                                                        isCrawl={d.type === 'crawl'}
                                                        data={d}
                                                    />
                                                )}
                                            </SkeletonWrapper>
                                        </Td>
                                        <Td display="table-cell" textTransform="capitalize">
                                            <SkeletonWrapper>
                                                {getTypeLabel(d?.type) || "-"}
                                            </SkeletonWrapper>
                                        </Td>
                                        <Td display="table-cell" textAlign="center">
                                            <SkeletonWrapper>
                                                {(d?.retrieved?.count || 0)?.toLocaleString() || "-"}
                                            </SkeletonWrapper>
                                        </Td>
                                        <Td display="table-cell">
                                            <SkeletonWrapper>
                                                <Tooltip variant="rounded-sm" label={dayjs(d.createdAt?.toDate()).format('lll')} placement="bottom-start" openDelay={500}>
                                                    <Text>{created}</Text>
                                                </Tooltip>
                                            </SkeletonWrapper>
                                        </Td>
                                        <Td display="table-cell" textAlign="end">
                                            <SkeletonWrapper noRender>
                                                <HistoryMenu imported={d} />
                                            </SkeletonWrapper>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Flex>
    )
}

const SkeletonWrapper = ({ children, w, noRender }) => {
    const imported = useGetSitemapImportHistory()
    if (noRender && !imported?.loaded) return <Box w={w} />
    return <Skeleton w={w || "full"} isLoaded={imported?.loaded} rounded="md">{children}</Skeleton>
}

const getStatus = (imported) => {
    const isImportCountOverLimit = getIsImportCountOverLimit(imported)
    if (imported?.options?.paused) return "paused"
    if (imported.error) return 'error';
    return isImportCountOverLimit ? "incomplete" : "completed"
}

export const getStatusIcon = (status) => {
    let icon;
    // if (status === 'completed') icon = BsCheckCircle
    if (status === 'incomplete') icon = BsQuestionCircle
    // if (status === 'error') icon = BsExclamationCircle
    if (icon) return <Icon color={status === 'error' ? "rarchy-red" : "fg.muted"} as={icon} fontSize="xs" />
}