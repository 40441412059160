import { Box, Stack, Text, WrapItem, Button, Icon, Image } from "@chakra-ui/react"
import { SharedAvatarGroup } from "../../../Components/Shared/AvatarGroup"
import { useHasLoadedFiles, useInProjectsGridView, useInTrash } from "../../../../hooks"
import { useHistory } from "react-router-dom"
import { BsFolderFill } from "react-icons/bs"
import { useState } from "react"
import { FolderMenu } from "./Options/Menu"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const FolderComponent = ({ folder, thumbnail, count }) => {
    const history = useHistory()
    const inTrash = useInTrash()
    const inGridView = useInProjectsGridView()
    const [showMenu, setShowMenu] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const hasLoadedFiles = useHasLoadedFiles()
    return (
        <WrapItem
            // draggable
            key={folder?.id}
            ml={inGridView ? 0 : -2}
            onMouseEnter={() => { if (inGridView && hasLoadedFiles) setShowMenu(true) }}
            onMouseLeave={() => { if (inGridView && !isMenuOpen && hasLoadedFiles) setShowMenu(false) }}
        >
            <Button
                as={Box}
                variant="ghost"
                _hover={{ cursor: 'pointer', bgColor: !inGridView ? "transparent" : "rarchy-bg-subtle-hover" }}
                justifyContent="start"
                w={inGridView ? "255px" : "unset"}
                size="xl"
                px={2}
                py={hasLoadedFiles ? 1 : 3}
                pl={2}
                fontSize="sm"
                key={folder.id}
                leftIcon={<Icon as={BsFolderFill} w="56px" h="56px" color="rarchy-color-folder" />} iconSpacing={3}
                isTruncated
                onClick={() => {
                    if (!inTrash) history.push(`/app/folder/${folder.id}`);
                }}
            >
                <Stack w="full" direction="row" justify="start">
                    {hasLoadedFiles && (
                        <Box bg="rarchy-bg-sitemap-editor" w="35px" rounded="sm" p="1px" pos="absolute" left="18px" top="19px">
                            {thumbnail && (
                                <Image
                                    objectFit="contain"
                                    rounded="sm"
                                    h="28px"
                                    bg="rarchy-bg-sitemap-editor" 
                                    src={thumbnail}
                                    fallbackStrategy="onError"
                                    fallback={<Box bg="rarchy-bg-sitemap-editor" />}
                                />
                            )}
                        </Box>
                    )}
                    <Stack direction="row" justify="space-between" w="full" align="center">
                        <Stack align="start" spacing={1}>
                            <SkeletonWrapper w="fit-content">
                                <Text align="start" isTruncated fontWeight="medium" w={!showMenu ? "fit-content" : "130px"}>
                                    {folder?.name}
                                </Text>
                            </SkeletonWrapper>
                            <Stack direction="row" align="center">
                                {inGridView && hasLoadedFiles && <SharedAvatarGroup folder={folder} />}
                                <SkeletonWrapper w="max-content">
                                    <Text fontSize="xs" isTruncated noOfLines={1} fontWeight="normal" color="fg.subtle">{`${count} item${count !== 1 ? 's' : ''}`}</Text>
                                </SkeletonWrapper>
                            </Stack>
                        </Stack>
                        {showMenu && (
                            <FolderMenu
                                folder={folder}
                                onOpen={() => {
                                    setIsMenuOpen(true)
                                }}
                                onClose={() => {
                                    setIsMenuOpen(false)
                                    setShowMenu(false)
                                }}
                            />
                        )}
                    </Stack>
                </Stack>
            </Button>
        </WrapItem>
    )
}