import {
    Box,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    Portal,
    Text,
    useColorMode
} from "@chakra-ui/react"
import CheckboxTree, { expandNodesToLevel } from 'react-checkbox-tree'
import React, { useState } from 'react';

import { EditorSkeletonWrapper } from '..';
import { Search } from './Components/Search';
import { TriggerButton } from './Components/TriggerButton'
import { addOrGoToSubfolderTab } from '../../../Sitemap/app/subfolders';
import { isEmpty } from 'lodash'
import { motionPropsZeroAnimation } from '../../../../helpers';
import { renderPagesForTree } from './helpers'
import { useDispatch } from 'react-redux';
import { useGetSitemap } from '../../../../hooks';

export const Subfolders = ({ location }) => {
    const sitemap = useGetSitemap()
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const [subfolders, setSubfolders] = useState([])
    const [filteredSubfolders, setFilteredSubfolders] = useState([])
    const [expanded, setExpanded] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const data = isEmpty(filteredSubfolders) ? subfolders : filteredSubfolders
    return (
        <EditorSkeletonWrapper>
            <Popover
                isLazy
                placement="bottom-start"
                onOpen={() => { setIsOpen(true); setSubfolders(renderPagesForTree(sitemap).pages) }}
                onClose={() => { setIsOpen(false); setFilteredSubfolders([]) }}
            >
                {({ onClose }) => (
                    <>
                        <TriggerButton location={location} />
                        <Portal>
                            <PopoverContent w="sm" motionProps={motionPropsZeroAnimation}>
                                <Search isOpen={isOpen} subfolders={subfolders} setFilteredSubfolders={setFilteredSubfolders} setExpanded={setExpanded} expandNodesToLevel={expandNodesToLevel} />
                                <PopoverBody maxHeight="60vh" p={0} overflow="scroll">
                                    {isEmpty(subfolders) && (<HStack p={3}><Text ml={7} fontSize="sm">No subfolders found</Text></HStack>)}
                                    {!isEmpty(subfolders) && (
                                        <Box p={2} pr={4} className={`subfolders-tree ${colorMode} no-checkbox`}>
                                            <CheckboxTree
                                                showNodeIcon={false}
                                                nodes={data}
                                                expanded={expanded}
                                                onExpand={expanded => setExpanded(expanded)}
                                                optimisticToggle={false}
                                                checkModel="leaf"
                                                onClick={obj => {
                                                    onClose();
                                                    addOrGoToSubfolderTab({ sitemap, subfolderId: obj.value, dispatch, action: 'go' });
                                                }}
                                            />
                                        </Box>
                                    )}
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </>)}
            </Popover>
        </EditorSkeletonWrapper>
    )
}