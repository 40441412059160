import { Button, Icon, MenuItem, Stack, Text, createStandaloneToast, useBoolean } from "@chakra-ui/react"
import { deleteField, doc, getFirestore, setDoc } from "firebase/firestore"
import { getAccountId, getUserId } from "../../../../helpers"
import { useGetEditorDocIdFromPath, useInOrganization, useInTrash } from "../../../../hooks"

import { BsTrash } from "react-icons/bs"
import { DeleteFileAlertDialog } from "../../../Sitemaps/Components/Delete"
import { MenuItemSpinner } from "../../../Components/Shared/MenuItemSpinner"
import { PY } from "../../../Projects/Folders/Components/Options/Menu"
import { mergeFileChange } from "../../../../store/actions/files-actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { wait } from "../../../../../shared/helpers"

const { toast } = createStandaloneToast()
const id = 'trash-toast'

export const DeleteFile = ({ file, showDeleteAlert, setShowDeleteAlert, onClose }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const inTrash = useInTrash()
    const editorDocId = useGetEditorDocIdFromPath()
    const [isDeleting, setIsDeleting] = useBoolean(false)
    const inOrganization = useInOrganization()

    const shouldArchive = !inTrash && inOrganization

    return (
        <>
            <MenuItem
                py={PY}
                isDisabled={isDeleting}
                icon={isDeleting ? <MenuItemSpinner /> : <Icon as={BsTrash} fontSize="xl" />}
                onClick={async () => {
                    setIsDeleting.toggle()
                    // delete file
                    if (!shouldArchive) {
                        await wait(250)
                        setShowDeleteAlert(true)
                    }
                    // archive file
                    if (shouldArchive) {
                        await wait(500)
                        try {
                            await archiveFile({ dispatch, file }, { shouldSetInFirestore: true })
                            setIsDeleting.toggle()
                            const inFileToBeArchived = file?.id === editorDocId
                            if (inFileToBeArchived) history.push(`/app/projects`)
                        } catch (e) {
                            console.error(e)
                        }
                        // close menu
                        if (onClose) onClose()
                    }
                    setIsDeleting.toggle()
                }}>
                {shouldArchive ? "Move to trash" : `Delete ${inTrash ? "permanently" : ""}`}
            </MenuItem>
            {showDeleteAlert && <DeleteFileAlertDialog file={file} showDeleteAlert={showDeleteAlert} setShowDeleteAlert={setShowDeleteAlert} onClose={onClose} />}
        </>
    )
}

export const archiveFile = async ({ dispatch, file }, { shouldSetInFirestore }) => {
    const userId = getUserId()
    const accountId = getAccountId()
    // save to firestore
    const now = new Date()
    const docData = {
        updatedBy: userId,
        archived: true,
        archivedAt: now,
        deleteAt: new Date(now.setDate(now.getDate() + 30)),
        account: accountId // new, needed for archiving
    }
    // set to false when deleting folder
    if (shouldSetInFirestore) {
        const fileRef = doc(getFirestore(), file?.collection, file?.id);
        await setDoc(fileRef, docData, { merge: true });
        // successful
        showTrashToast({ file })
    }
    // continue dispatch
    dispatch(mergeFileChange(file?.id, file?.collection, { ...docData, setToArchived: true }))
}

const showTrashToast = ({ file }) => {
    if (!toast.isActive(id)) {
        toast({
            position: 'top',
            duration: 2900,
            isClosable: true,
            render:() => <TrashToast file={file} />,
        })
    }

}

const TrashToast = ({ file }) => {
    const dispatch = useDispatch()
    const [isRestoring, setIsRestoring] = useBoolean(false)
    return (
        <Stack mt={-0.5} align="center" w="full" justify="space-between" direction="row" rounded="2xl" color="rarchy-color-toast" px={5} py={3} bgColor="rarchy-bg-toast">
            <Text fontSize="sm" fontWeight="semibold">Moved to trash</Text>
            <Button
                size="sm"
                variant="ghost"
                color="rarchy-color-toast"
                bgColor="rarchy-bg-toast-button"
                _hover={{ bgColor: "rarchy-bg-toast-button" }}
                isLoading={isRestoring}
                onClick={async () => {
                    try {
                        setIsRestoring.toggle()
                        await wait(500)
                        const userId = getUserId()
                        const docData = (forFirestore) => {
                            return {
                                updatedBy: userId,
                                archived: false,
                                archivedAt: forFirestore ? deleteField() : null,
                                deleteAt: forFirestore ? deleteField() : null,
                                account: forFirestore ? deleteField() : null,
                            }
                        }
                        const fileRef = doc(getFirestore(), file?.collection, file?.id);
                        await setDoc(fileRef, docData(true), { merge: true });
                        // successful
                        dispatch(mergeFileChange(file?.id, file?.collection, { ...docData(), setToArchived: false }))
                    } catch (e) {
                        console.error(e)
                    } finally {
                        setIsRestoring.toggle()
                        toast.closeAll()
                    }
                }}>
                Undo
            </Button>
        </Stack>
    )
}