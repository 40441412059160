import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Projects } from "../Projects"
import { useGetClaims, useGetFilesData, useGetFoldersData, useInOrganization, usePrevious } from "../../hooks"
import { getFolders } from "../../store/actions/folders-actions"
import { getFiles } from "../../store/actions/files-actions"
import { Redirect } from "react-router-dom"
import { isEmpty, map } from 'lodash'

export const Trash = () => {
    GetFilesDataOnMount()
    const inOrganization = useInOrganization()
    if (!inOrganization) return <Redirect to="/app" />
    return <Projects />
}

const GetFilesDataOnMount = () => {
    const dispatch = useDispatch()
    const claims = useGetClaims()
    const prevClaims = usePrevious(claims)
    const filesData = useGetFilesData()
    const inOrganization = useInOrganization()
    const foldersData = useGetFoldersData()
    const filteredFilesDataForSetToArchived = filesData?.filter(f => !f?.setToArchived)
    useEffect(() => {
        if (!inOrganization) return;
        // just got claims (mounted)
        if (!isEmpty(claims) && isEmpty(prevClaims)) {
            // no archived files we haven't retrieved yet (filtered to remove "setToArchived")
            if (isEmpty(filteredFilesDataForSetToArchived)) {
                if (isEmpty(foldersData)) {
                    dispatch(getFolders({ claims, archived: true }))
                } else {
                    dispatch(getFiles({ claims, folderIds: map([...foldersData], 'id'), archived: true }))
                }
            }
        }
    }, [claims, prevClaims])
}