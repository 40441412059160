import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, Popover, PopoverTrigger, PopoverContent, Menu, MenuList, MenuItem, useColorMode, useColorModeValue as mode, Link as ChakraLink } from '@chakra-ui/react';

import { HiOutlineSupport } from 'react-icons/hi'
import { QuestionOutlineIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { BsBook, BsQuestionCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { toggleRevisionHistoryDrawer } from '../../../../store/actions/editor-actions'
import { BiBuoy } from 'react-icons/bi';
import { EditorSkeletonWrapper } from '..';

export const Help = () => {
    const { ui } = useSelector(state => state)
    const dispatch = useDispatch()
    const toggleRevHist = () => dispatch(toggleRevisionHistoryDrawer())
    return (
        <EditorSkeletonWrapper>
            <Popover
                placement="bottom-end"
                isLazy
            >
                <>
                    <PopoverTrigger>
                        <Box cursor="pointer" mr={2} bgColor="rarchy-bg-sitemap-editor" rounded="md">
                            <Link to="/app/help">
                                <Button leftIcon={<QuestionOutlineIcon fontSize="sm" />} iconSpacing={2.5} variant="outline" color="rarchy-color-sitemap-editor" borderColor="rarchy-border-sitemap-editor" borderWidth={"1px"} size="sm" paddingInline={3.5}>
                                    Help
                                </Button>
                            </Link>
                        </Box>
                    </PopoverTrigger>
                    <PopoverContent p={0} w={180} border="none" boxShadow="sm">
                        <Menu defaultIsOpen autoSelect={false}>
                            <MenuList minW={0} width={184} variant="noShadow" fontSize="sm">
                                <Link to="/app/help">
                                    <MenuItem icon={<HiOutlineSupport fontSize="15px" />} iconSpacing={4}>Help & Support</MenuItem>
                                </Link>
                                <Link to="/app/help">
                                    <MenuItem icon={<BsQuestionCircle fontSize="15px" />} iconSpacing={4}>Submit a ticket</MenuItem>
                                </Link>
                                <ChakraLink isExternal href="https://help.rarchy.com">
                                    <MenuItem icon={<BsBook fontSize="15px" />} iconSpacing={4}>Knowledge Base</MenuItem>
                                </ChakraLink>
                            </MenuList>
                        </Menu>
                    </PopoverContent>
                </>
            </Popover>
        </EditorSkeletonWrapper>
    )
}