import { Box, CloseButton, DarkMode, Image, Tooltip, useColorMode } from "@chakra-ui/react"
import { useGetFilesData, useGetStarredFolders } from "../../hooks"

import { HiOutlineFolder } from "react-icons/hi"
import { NavGroup } from "./Components/NavGroup"
import { NavItem } from "./Components/NavItem"
import { getFolderThumbnail } from "../../helpers"
import { isEmpty } from 'lodash'
import { setStarred } from "../../store/actions/user-actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useState } from "react"

export const Starred = ({ inSideMenu }) => {
    const fromColorMode = useColorMode()?.colorMode
    const colorMode = inSideMenu ? "dark" : fromColorMode
    const history = useHistory()
    const [mouseover, setMouseover] = useState(false)
    const starredFolders = useGetStarredFolders()
    const filesData = useGetFilesData()
    const hasFolders = !isEmpty(starredFolders)
    return (
        <DarkMode>
            <Box w="full" mt={6}>
                <NavGroup label="Starred" isLoaded={hasFolders}>
                    {hasFolders && starredFolders?.map(folder => {
                        const folderItems = filesData?.filter(f => f.team === folder.id)
                        const thumbnail = getFolderThumbnail(folderItems, colorMode)
                        const isMouseover = folder?.id === mouseover;
                        return (
                            <NavItem
                                key={folder?.id}
                                icon={
                                    thumbnail
                                        ?
                                        <Box w="25px" rounded="sm" borderColor="rarchy-border" bg="rarchy-bg-sitemap-editor" borderWidth="1px">
                                            <Image rounded="sm" h="22px" objectFit="contain" src={thumbnail} />
                                        </Box>
                                        :
                                        <HiOutlineFolder />
                                }
                                endElement={isMouseover ? <UnstarButton folder={folder} inSideMenu /> : null}
                                label={folder?.name}
                                onMouseEnter={() => setMouseover(folder.id)}
                                onMouseLeave={() => setMouseover(null)}
                                onClick={() => history.push(`folder/${folder.id}`)}
                            />
                        )
                    })}
                </NavGroup>
            </Box >
        </DarkMode>
    )
}

const UnstarButton = ({ folder, inSideMenu }) => {
    const dispatch = useDispatch()
    return (
        <DarkMode>
            <Tooltip
                variant="rounded-sm"
                placement="bottom-end"
                openDelay={1000}
                label="Unstar folder">
                <CloseButton
                    size="sm"
                    fontSize="xs"
                    rounded="md"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setStarred({ id: folder.id, isStarred: false, type: 'folders' }))
                        e.preventDefault();
                    }}
                />
            </Tooltip>
        </DarkMode>
    )
}