import { add, clone, remove } from '../../utils/edit';

import { goToSection } from '../subfolders';
import { hideContextMenu } from './helpers';
import { renamePage } from '../canvas/components/text/utils';
import { showCommentsPopover } from '../../comments/helpers.jsx';
import { togglePageDrawer } from '../../../../store/actions/sitemap-actions';

export const selectAction = async ({ e, id, node, sitemap, dispatch }) => {
    e.stopPropagation();
    switch (id) {
        case 'add':
            add(node)
            break;
        case 'delete':
            remove(node)
            break;
        case 'rename':
            renamePage(node)
            break;
        case 'edit-url':
            dispatch(togglePageDrawer({ showing: true, node, editPageUrl: true }))
            break;
        case 'clone':
            clone(node)
            break;
        case 'comments':
            showCommentsPopover(node)
            break;
        case 'subfolder': {
            goToSection({ node, sitemap, subfolderId: node.id, dispatch });
            break;
        }
        case 'open-drawer':
            dispatch(togglePageDrawer({ showing: true, node }))
            break;
        default:
            break;
    }
    // hide context menu
    hideContextMenu()
}