import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    Stack
} from "@chakra-ui/react"
import React, { useState } from 'react'
import { captureCoverFromWebsite, getURL } from '../../../../../../store/actions/sitemap-actions'
import { isNil, omitBy } from 'lodash'
import { useCanFullyEditFile, useGetSitemap } from '../../../../../../hooks'

import { AiOutlineCamera } from 'react-icons/ai'
import { RemainingScreenshots } from '../../../covers/Capture/Components/Remaining'
import { Settings } from './Settings'
import { getCapturing } from './helpers'
import { getPageId } from '../../../canvas/utils/helpers'
import { isValidURL } from '../../../../../../helpers'
import { useDispatch } from 'react-redux'

export const defaultScreenshotSettings = {
    fullpage: true,
    lazyload: false,
    waitForFiveSeconds: false,
    hidden: "",
    httpAuth: { username: "", password: "" }
}

export const Screenshot = () => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()

    const { PageDrawer } = sitemap?.ui

    const pageId = getPageId(PageDrawer.page);

    const [urlToCapture, setUrlToCapture] = useState(getURL(pageId));
    const [settings, setSettings] = useState(defaultScreenshotSettings);

    const handleSubmit = () => {
        // can edit
        if (!canFullyEdit) return;
        // continue
        if (urlToCapture && isValidURL(urlToCapture)) {
            const { device } = sitemap?.covers;
            dispatch(captureCoverFromWebsite({ url: urlToCapture, page: pageId, device, ...omitBy(settings, isNil) }))
        }
    }

    const { pages, device } = sitemap?.covers
    const isCapturing = getCapturing({ page: pageId, pages, device })

    const canFullyEdit = useCanFullyEditFile()

    return (
        <>
            <Box pl={3} py={1.5}>
                <RemainingScreenshots />
            </Box>
            <Stack>
                <InputGroup mt={3}>
                    <Input
                        // variant="filled"
                        pl={3}
                        variant="flushed"
                        value={urlToCapture}
                        placeholder="Enter a URL..."
                        onChange={(e) => setUrlToCapture(e.target.value)}
                        isDisabled={isCapturing || !canFullyEdit}
                    />
                    <Flex>
                        <Button
                            ml={5}
                            fontSize="sm"
                            colorScheme="blue"
                            leftIcon={<AiOutlineCamera fontSize="16px" />}
                            onClick={handleSubmit}
                            loadingText="Capturing"
                            isLoading={isCapturing}
                            isDisabled={isCapturing || !canFullyEdit}
                        >
                            Capture
                        </Button>
                    </Flex>
                </InputGroup>
                <Box mt={5} px={3} w="full">
                    <Settings showTitle settings={settings} setSettings={setSettings} isDisabled={isCapturing || !canFullyEdit} />
                </Box>
            </Stack>
        </>
    )
}