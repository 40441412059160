import React, { useEffect, useRef, useState } from 'react'
import { SYMBOL_HEIGHT, SYMBOL_LINEHEIGHT, SYMBOL_WIDTH, drawText as getActualHeightOfSymbolText, getUserFlowSymbolTextWidthAndHeight } from '../../../../user-flows/components/nodes';
import { Textarea, useOutsideClick } from '@chakra-ui/react';
import { applyYWithCavasOffset, getPageColor, getX, getY, shouldShowCovers } from '../../utils/helpers';
import { getPalletteColors, setCanvasTextEditorInputVal, toggleCanvasTextEditor } from '../../../../../../store/actions/editor-actions';
import { useGetUserFlow, useInUserFlow, usePrevious } from '../../../../../../hooks';

import { getCanvasTextEditor } from '../../../../../../helpers';
import { interactionsCanvas } from '../../canvases';
import { resetNodeHeights } from '../../../../../../store/actions/sitemap-actions';
import { setSectionAttrs } from '../page-sections';
import { transform } from '../../utils/zoom';
import { useDispatch } from 'react-redux';

const SECTION_WIDTH = 175;

export const CanvasTextEditorInput = () => {

    const dispatch = useDispatch()

    const inUserFlow = useInUserFlow()
    const flow = useGetUserFlow()

    const [initialValue, setInitialValue] = useState('');
    const inputElement = useRef(null);

    const CanvasTextEditor = getCanvasTextEditor()
    let { node, section, newString } = CanvasTextEditor || {};

    // on mount
    useEffect(() => {
        setInitialValue(newString)
        if (inputElement?.current) setTimeout(() => inputElement.current.focus(), 10)
    }, [])

    useOutsideClick({ ref: inputElement, handler: () => { if (CanvasTextEditor?.showing) dispatch(toggleCanvasTextEditor({ showing: false })) } })

    const showCovers = shouldShowCovers(node);

    // update node when in user flows (so we can be sure heights are correct when adding / removing new line)
    if (inUserFlow) {
        node = (flow?.data?.nodes || [])?.find(n => n.id === node.id);
        if (section) section = (node?.sections || [])?.find(s => s.id === section.id)
    }

    const isUserFlowSymbol = inUserFlow && !node?.type?.includes('page');
    const pallette = getPalletteColors(getPageColor(node))
    const left = getLeft({ node, section, isUserFlowSymbol, showCovers })
    const top = getTop({ node, section, isUserFlowSymbol, showCovers, newString: CanvasTextEditor.newString })

    // render
    return <Textarea
        pos="absolute"
        bgColor={"transparent"}
        resize="none"
        minH={0}
        w={`${getWidth({ node, section, isUserFlowSymbol, showCovers }) * transform.k}px`}
        h={`${getHeight({ node, section, isUserFlowSymbol }) * transform.k}px`}
        lineHeight={`${Math.round((section ? 17 : isUserFlowSymbol ? SYMBOL_LINEHEIGHT : 21) * transform.k)}px`}
        variant="ghost"
        fontWeight={section ? 500 : 600}
        left={`${left}px`}
        top={`${top}px`}
        p={0}
        spellCheck="false"
        fontSize={`${((section || isUserFlowSymbol) ? 15 : 17) * transform.k}px`}
        rounded={0}
        textAlign={(showCovers || isUserFlowSymbol) ? 'center' : 'left'}
        color={section ? section.tColor : pallette[7]}
        defaultValue={initialValue}
        onFocus={e => e.target.select()}
        onChange={(e) => {
            const newString = e.target.value;
            dispatch(setCanvasTextEditorInputVal(newString)); // has to be first so node heights are correct
            if (section) setSectionAttrs({ nodes: [node] }); // reset section attrs for renaming node
            if (!isUserFlowSymbol) dispatch(resetNodeHeights([node.id], { update: true }));
        }}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                if (e.shiftKey) return; // adds line break for if using Shift + Enter
                dispatch(toggleCanvasTextEditor({ showing: false }))
            }
        }}
        ref={inputElement}
    />
}

const getWidth = ({ node, section, isUserFlowSymbol, showCovers }) => {
    let width = 0;
    if (isUserFlowSymbol) {
        width = getUserFlowSymbolTextWidthAndHeight(node.type).width;
    } else {
        width = section ? SECTION_WIDTH : (showCovers ? 200 : 195)
    }
    return width;
}

const getHeight = ({ node, section, isUserFlowSymbol }) => {
    let height = 0;
    if (isUserFlowSymbol) {
        height = getUserFlowSymbolTextWidthAndHeight(node.type).height;
    } else {
        height = section ? section.rectHeight : node.actualTextHeight
    }
    return height;
}

const getLeft = ({ node, section, isUserFlowSymbol, showCovers }) => {
    let left = 0;
    if (isUserFlowSymbol) {
        left = getX(node) + (((SYMBOL_WIDTH - getUserFlowSymbolTextWidthAndHeight(node.type).width)) / 2);
    } else {
        left = section ? section.tx : (getX(node) + (showCovers ? 12 : 17));
    }
    return transform.applyX(left)
}

const getTop = ({ node, section, isUserFlowSymbol, showCovers, newString }) => {
    let top = 0;
    if (isUserFlowSymbol) {
        const { actualHeight } = getActualHeightOfSymbolText(interactionsCanvas, { ...node, text: newString }, getX(node), node.topOfNode, true);
        top = node.topOfNode + 4.75 /* fix moving nodey offset from 24 - 19.25 */ + ((SYMBOL_HEIGHT - actualHeight) / 2) - (node.type === 'document' ? 5 : 0);
    } else {
        top = section ? section.y + 7 : (getY(node) + (showCovers ? (node.nodeHeight - (node.textRectHeight - 10) - 1) : 35));

    }
    return applyYWithCavasOffset(top)
};