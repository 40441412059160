import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text } from "@chakra-ui/react"
import { AuthContainer } from ".."
import { SetTitle } from "../../../../hooks"
import { Formik } from "formik"
import { validate } from 'email-validator'
import { getFirebase } from "react-redux-firebase"
import { Error } from "../Components/Error"
import { logOut } from "../../../../store/actions/auth-actions"

export const ResetPassword = () => {
    SetTitle("Reset your password to access your Rarchy account")
    const dispatch = useDispatch()
    const [sentResetEmail, setSentResetEmail] = useState(false)
    // log out if not already logged out
    useEffect(() => { dispatch(logOut()) }, [])
    return (
        <AuthContainer type={sentResetEmail ? "sent-reset-email" : "reset"}>
            {!sentResetEmail && (
                <Formik
                    initialValues={{ email: '' }}
                    validate={(values) => {
                        const errors = {};
                        const attributes = Object.keys(values)
                        attributes.forEach(attr => { // disabled validation for now
                            if (!values[attr]) errors[attr] = "You missed this one"
                            if (!validate(values[attr])) errors[attr] = "Please enter a valid email address."
                        })
                        return errors;
                    }}
                    onSubmit={async (values, { setErrors }) => {
                        const { email } = values
                        const auth = getFirebase().auth()
                        try {
                            await auth.sendPasswordResetEmail(email)
                            setSentResetEmail(true)
                        } catch (e) {
                            setErrors({ firebaseError: e })
                        }
                    }}
                >
                    {({
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        isValid,
                        isSubmitting,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={4}>
                                    {errors?.firebaseError && (
                                        <Box mt={-4} mb={3}>
                                            <Error auth={{ isError: errors?.firebaseError }} />
                                        </Box>
                                    )}
                                    <Text fontSize="sm" mt={-4}>Don't worry, we'll send you a message to help you reset your password.</Text>
                                    <FormControl id="email" isRequired isInvalid={!!errors.email && touched.email}>
                                        <FormLabel fontSize="sm">Email address</FormLabel>
                                        <Input name="email" onChange={handleChange} placeholder="Enter your email" isDisabled={isSubmitting} />
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <Button
                                        colorScheme="blue"
                                        fontSize="sm"
                                        type="submit"
                                        iconSpacing={4}
                                        // loadingText="Signing in to Rarchy"
                                        isDisabled={!isValid}
                                        isLoading={isSubmitting}
                                        mt={3}
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </form>
                        )
                    }}
                </Formik>
            )}
            {sentResetEmail && (
                <Text>
                    {`Please check your email for further instructions on how to reset your password.`}
                </Text>
            )}
        </AuthContainer>
    )
}