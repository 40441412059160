import { store } from '../../../../../../store';
import { addPageChange, addPageSectionChange, mergePagesEdits, updatePageSectionsData } from '../../../../../../store/actions/sitemap-actions';

// import amplitude from 'amplitude-js/amplitude';
import { sendDataLayerEventForPageSections } from '../page-sections/options';
import { getNameForUserFlowPage } from '../text';
import { addSymbolChange } from '../../../../user-flows/helpers';
import { getPageName } from '../../../../utils/app';
import { getPageId } from '../../utils/helpers';
import { getCanvasTextEditor, getInSitemap, getInUserFlow, getIsLinkedUserFlowPage, getSitemap } from '../../../../../../helpers';
import { toggleCanvasTextEditor } from '../../../../../../store/actions/editor-actions';
import { trim } from 'lodash'

// this includes user-flow pages - both linked and unlinked
export const renamePage = async (node) => {

    const inSitemap = getInSitemap()
    const CanvasTextEditor = getCanvasTextEditor()

    // name
    const isLinkedPageInUserFlow = getIsLinkedUserFlowPage(node)
    // get name
    const newString = getPageName(node[inSitemap || isLinkedPageInUserFlow ? 'name' : 'text']);
    // save any changes if input was already being used on another page
    if (CanvasTextEditor.showing) {
        store.dispatch(toggleCanvasTextEditor({ showing: false }));
        await wait(10); // need this otherwise showing:true runs too fast and text from old page/section is shown in input (textarea isn't unmounted)
    }
    // turn the canvas text editor on
    store.dispatch(toggleCanvasTextEditor({ showing: true, newString, node, section: undefined }))
}

export const renameUserFlowSymbol = async (node) => {
    const CanvasTextEditor = getCanvasTextEditor()
    // save any changes if input was already being used on another page
    if (CanvasTextEditor.showing) {
        store.dispatch(toggleCanvasTextEditor({ showing: false }));
        await wait(10); // need this otherwise showing:true runs too fast and text from old page/section is shown in input (textarea isn't unmounted)
    }
    // turn the canvas text editor on
    store.dispatch(toggleCanvasTextEditor({ showing: true, newString: node.text, node }))
}

export const handleRenamePage = async (node, newName) => {

    const sitemap = getSitemap()

    const inUserFlow = getInUserFlow();

    const isLinkedPageInUserFlow = getIsLinkedUserFlowPage(node);
    const needToUpdatePagesDoc = !inUserFlow || isLinkedPageInUserFlow;
    const nodeId = getPageId(node);

    const oldName = !inUserFlow ? getPageName(node.name) : getNameForUserFlowPage(node);

    if (oldName === '' && newName === '') newName = 'Page'; // oldName === '' means new page has been added and user hasn't typed in a new page name immediately

    // change page name
    var changed = changedPageName({ node, nodeId, oldName, newName, sitemap, isLinkedPageInUserFlow, needToUpdatePagesDoc });
    // save PAGES change
    if (changed) {
        if (needToUpdatePagesDoc) {
            var obj = [{
                action: 'rename',
                id: nodeId,
                name: newName,
            }];
            const change = {
                id: new Date().getTime(),
                data: obj,
            };
            const history = {
                action: 'rename',
                node: nodeId,
                data: { oldName, newName },
            };
            setTimeout(() => {
                store.dispatch(addPageChange({ change, history }));
            }, 500);
            // amplitude tracking
            // amplitude.getInstance().logEvent('SITEMAP_RENAMED_PAGE');
        }
    }
};

const changedPageName = ({ node, nodeId, oldName, newName, sitemap, isLinkedPageInUserFlow, needToUpdatePagesDoc }) => {
    //
    newName = trim(newName);
    // return false if name hasn't changed
    if (oldName === newName || !newName) return false;
    // merge page edits
    if (needToUpdatePagesDoc) {
        const pageDocData = { ...(sitemap?.docs.pages[nodeId] ? sitemap?.docs.pages[nodeId] : { index: node.index, parent: node.parent.id }) }; // have to create our own sitemap doc data for when adding a page for the first time and renaming immediately
        if (node.website_section) pageDocData.website_section = node.website_section;
        store.dispatch(mergePagesEdits({ pages: { [nodeId]: { ...pageDocData, name: newName } } }))
    }
    // so user-flows can re-render
    const inUserFlow = getInUserFlow()
    if (inUserFlow && !isLinkedPageInUserFlow) {
        addSymbolChange({
            data: { [node.id]: { text: newName } },
            oldData: { [node.id]: { text: oldName } },
        });
    }
    //
    return true;
};

export const renamePageSection = async (node, section) => {
    const CanvasTextEditor = getCanvasTextEditor()
    // save any changes if input was already being used on another page
    if (CanvasTextEditor.showing) {
        store.dispatch(toggleCanvasTextEditor({ showing: false }));
        await wait(10); // need this otherwise showing:true runs too fast and text from old page/section is shown in input (textarea isn't unmounted)
    }
    // turn the canvas text editor on
    store.dispatch(toggleCanvasTextEditor({ showing: true, newString: section.title, node, section }))
}

export const endRenamePageSection = async (node, section, newTitle) => {

    const { sitemap } = store.getState()
    const CanvasTextEditor = getCanvasTextEditor()

    const nodeId = getPageId(node);

    if (CanvasTextEditor.input === 'textarea') {

        const oldTitle = section.title

        // change page name
        var changed = changedPageSectionName(section, oldTitle, newTitle);
        if (changed) {
            /*** render immediately ***/
            var sections = { ...sitemap?.data.page_sections };
            sections[nodeId].map(s => {
                if (s.id.toString() === section.id.toString()) s.title = newTitle;
                return s;
            });
            // update sections data in redux
            store.dispatch(updatePageSectionsData(sections));
            /*** render immediately ***/

            const change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-rename', pageId: nodeId, section: { id: section.id, title: newTitle } }],
            };
            const history = {
                action: 'page-section-rename',
                data: { pageId: nodeId, section, newTitle, oldTitle }
            };

            store.dispatch(addPageSectionChange({ change, history }));

            sendDataLayerEventForPageSections(`Rename`, newTitle);
        };

    }
};

const changedPageSectionName = (section, oldTitle, newTitle) => {
    // trim
    newTitle = trim(newTitle);
    // return false if name hasn't changed
    if (oldTitle === newTitle || !newTitle) return false;
    // change name in node
    section.title = newTitle;
    //
    return true;
};

function wait(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
}