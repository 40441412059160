import { useEffect, useRef, useState } from "react"
import { Editable, EditableInput, EditablePreview, Tooltip } from "@chakra-ui/react"
import { getFirestore, setDoc, doc } from "firebase/firestore"
import { mergeFolderChange } from "../../../../store/actions/folders-actions"
import { useDispatch } from "react-redux"
import { useGetUserId, useIsTextOverflown, usePrevious } from "../../../../hooks"

// default is large

export const FolderName = ({ folder, opts = {} }) => {
    const dispatch = useDispatch()
    const userId = useGetUserId()
    const nameElementRef = useRef(null)
    const isNameOverflown = useIsTextOverflown(nameElementRef)
    const [folderName, setFolderName] = useState(folder?.name)
    ClearFolderNameOnFolderChange({ folder, setFolderName })
    const { fontSize, w } = opts
    return (
        <Tooltip label={folderName} isDisabled={!isNameOverflown} variant="rounded-sm" openDelay={500} hasArrow>
            <Editable
                value={folderName}
                fontSize={fontSize || "3xl"}
                fontWeight="semibold"
                variant="flushed"
                w={w || "fit-content"}
                onChange={nextValue => setFolderName(nextValue)}
                onSubmit={async () => {
                    // has changed
                    if ((folderName && (folderName !== folder?.name))) {
                        // update in redux
                        dispatch(mergeFolderChange({ [folder?.id]: { name: folderName } }))
                        // save to firestore
                        const teamsRef = doc(getFirestore(), 'teams', folder?.id);
                        await setDoc(
                            teamsRef,
                            { name: folderName, updatedAt: new Date(), updatedBy: userId },
                            { merge: true }
                        );
                    }
                }}
            >
                <EditablePreview ref={nameElementRef} w={w || "fit-content"} py={0.5} />
                <EditableInput w={w || "fit-content"} py={0.5} />
            </Editable>
        </Tooltip>
    )
}

const ClearFolderNameOnFolderChange = ({ folder, setFolderName }) => {
    const prevFolder = usePrevious(folder)
    useEffect(() => {
        if (folder?.id !== prevFolder?.id) setFolderName(folder?.name)
    }, [folder, prevFolder])
}