import { useEffect } from "react"
import { Button } from "@chakra-ui/react"
import { getFirestore, doc, deleteDoc } from "firebase/firestore"
import { wait } from "../../../../../../../shared/helpers"
import { useGetImportingSitemap, usePrevious } from "../../../../../../hooks"
import { useDispatch } from "react-redux"
import { toggleNewSitemapModal } from "../../../../../../store/actions/ui-actions"

export const Cancel = ({ data, isCanceling, setIsCanceling, isPausing }) => {
    const dispatch = useDispatch()
    const importing = useGetImportingSitemap()
    const prevImporting = usePrevious(importing)
    // cancelled crawl
    useEffect(() => {
        return () => {
            if (isCanceling) dispatch(toggleNewSitemapModal({ showing: true, createType: prevImporting?.type }))
        }
    }, [isCanceling])
    // return    
    return (
        <Button
            variant="ghost"
            colorScheme="red"
            fontSize="md"
            iconSpacing={3}
            size="lg"
            isDisabled={isPausing}
            isLoading={isCanceling}
            onClick={async () => {
                setIsCanceling(true)
                await deleteDoc(doc(getFirestore(), "sitemaps_processing", data.id))
                await wait(500)
            }}>
            Cancel
        </Button>
    )
}