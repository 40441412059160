import { Stack } from "@chakra-ui/react"
import { Industry } from "./Menus/Industry"

export const TopBar = () => {
    return (
        <Stack direction="row" w="full" justifyContent="space-between">
            <Stack direction="row" spacing={5}>
                <Stack direction="row" spacing={3}>
                    <Industry />
                </Stack>
            </Stack>
        </Stack>
    )
}