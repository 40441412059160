import { FiChevronDown, FiChevronLeft, FiChevronRight, FiChevronUp } from 'react-icons/fi';
import {
    IconButton,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { collapse, expandOnClick } from '../../utils/app';
import { useGetSitemap, useInUserFlow } from '../../../../hooks';

import React from 'react';
import { getPageColor } from '../canvas/utils/helpers';
import { getPalletteColors } from '../../../../store/actions/editor-actions';
import { transform } from '../canvas/utils/zoom';
import { useSelector } from 'react-redux';

export const ExpandCollapse = ({ d, x, y }) => {
    
    const sitemap = useGetSitemap()
    const header = getPageColor(d)
    const pallette = getPalletteColors(header)
    const color = "rarchy-bg-sitemap-editor"
    const showCovers = useInUserFlow() ? true : sitemap?.showCovers;

    // const targetButtonWidth = Math.floor((showCovers ? 24 : 20) * transform.k);
    // const buttonWidth = (targetButtonWidth % 2 == 0) ? targetButtonWidth : targetButtonWidth + 1

    const targetButtonWidth = Math.ceil((showCovers ? 27 : 23) * transform.k)
    const buttonWidth = (targetButtonWidth % 2 === 0) ? targetButtonWidth : targetButtonWidth + 1

    return d.parent && (d.children || d._children) ? (
        <IconButton
            color={color}
            icon={renderIcon(d, sitemap, buttonWidth)}
            h={`${buttonWidth}px`}
            minW={`${buttonWidth}px`}
            left={Math.floor(getLeft(x, targetButtonWidth, sitemap))}
            top={Math.ceil(getTop(d, y, sitemap))}
            bgColor={header}
            outline={`${2 * transform.k}px solid`}
            outlineColor={color}
            _hover={{ bgColor: pallette[5] }}
            rounded="full"
            pos="absolute"
            title={`${d._children ? "Expand" : "Collapse"} Subfolder`}
            onClick={(e) => {
                e.stopPropagation()
                if (d.children) {
                    collapse(d);
                } else if (d._children) {
                    expandOnClick(d);
                }
            }}
        />
    ) : null
}

const getLeft = (x, buttonWidth, sitemap) => {
    if (sitemap?.format === 'tree') return x + ((sitemap?.showCovers ? 202 : 205) * transform.k) + (buttonWidth / 2)
    if (sitemap?.format === 'tree-vertical-matrix') return x - ((sitemap?.showCovers ? 6.5 : 3) * transform.k) + (buttonWidth / 2)
    return x + (112.5 * transform.k) - (buttonWidth / 2)
}

const getTop = (d, y, sitemap) => {
    const accountForCoversY = (6.5 * transform.k);
    if (sitemap?.format === 'tree') return (y + ((sitemap?.showCovers ? 105 : (d.nodeHeight / 2) - 20) * transform.k)) + accountForCoversY;
    if (sitemap?.format?.includes('tree-vertical')) return (y + (d.nodeHeight * transform.k)) - accountForCoversY
}

const renderIcon = (d, sitemap, buttonWidth) => {
    const targetFontSize = Math.floor(buttonWidth * 0.75)
    const fontSize = (targetFontSize % 2 == 0) ? targetFontSize : targetFontSize + 1;
    if (sitemap?.format === 'tree') {
        if (d._children) return <FiChevronRight fontSize={fontSize} />
        return <FiChevronLeft fontSize={fontSize} />
    } else {
        if (d._children) return <FiChevronDown fontSize={fontSize} />
        return <FiChevronUp fontSize={fontSize} />
    }
}