import {
    Box,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
} from "@chakra-ui/react"
import { hideCommentsPopover, useCommentIsResolved } from './helpers'

import { AddPageComment } from './Add'
import { PageComments } from './Comments'
import { isEmpty } from 'lodash'
import { select } from "d3"
import { useGetSitemap } from '../../../hooks'

export const PageCommentsPopover = () => {
    const sitemap = useGetSitemap()
    const { comments, ui } = sitemap
    const { PageCommentsPopover } = ui;
    const hasComments = !isEmpty(comments[PageCommentsPopover.page]);
    const isResolved = useCommentIsResolved(PageCommentsPopover.page);
    if (!PageCommentsPopover?.showing) return null
    return (
        <Popover
            returnFocusOnClose={false}
            isOpen
            placement="bottom-start"
            closeOnBlur={false}
            offset={PageCommentsPopover.offset}
        >
            <PopoverTrigger><Box display="none"/></PopoverTrigger>
            <PopoverContent onClick={e => e.stopPropagation()}>
                <PopoverBody bgColor={isResolved ? "rarchy-bg-subtle" : "unset"}>
                    {!hasComments && <AddPageComment page={PageCommentsPopover.page} />}
                    {hasComments && <PageComments sitemap={sitemap} />}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

// Updates on any click
select('body').on('click', () => hideCommentsPopover());