import { NavItem } from "../../Components/NavItem"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { Redirect } from "react-router-dom"
import { BiMessageAltEdit } from "react-icons/bi"
import { useGetLocation } from "../../../../hooks"

export const HelpMenu = () => {
    const { pathname } = useGetLocation()
    if (pathname === "/help") return <Redirect to="/app/help/faqs" />
    return (
        <>
            <NavItem
                pathname="/app/help/faqs"
                icon={<AiOutlineQuestionCircle />}
                label="FAQS"
            />
            {/* <NavItem
                pathname="/help/knowledge"
                label="Knowledge base"
                icon={<AiOutlineUsergroupAdd />}
                endElement={<ChevronRightIcon fontSize="2xl" color="fg.subtle" />}
            /> */}
            <NavItem
                pathname="/app/help/ticket"
                icon={<BiMessageAltEdit />}
                label="Submit a ticket"
            />
        </>
    )
}