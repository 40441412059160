import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Divider,
    HStack,
    Text,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { Delete } from './Delete';
import { Type } from './Type';
import { Color } from '../Shared/Color';
import { ArrangeMenu } from '../Shared/Arrange';
import { Device } from './Device';
import { Unlink } from './Unlink';
import { isEmpty } from 'lodash'

export const UserFlowsSymbolOptions = ({ OptionsPopover }) => {
    const { nodes } = OptionsPopover;
    const multiple = nodes.length > 1;
    const linkedPages = nodes.filter(d => d.page);
    const allLinkedPages = (!multiple && nodes[0]?.page) || linkedPages.length === nodes.length;
    const showCoverPages = nodes.filter(d => d.type === 'page');
    const allShowCovers = (!multiple && nodes[0]?.type === 'page') || showCoverPages.length === nodes.length;
    const selectedNodes = nodes.filter(d => d._selected);
    return (
        <HStack spacing={3}>
            {!isEmpty(selectedNodes) && <HStack ml={3} spacing={4}><Text fontSize="sm" color={mode("gray.600", "whiteAlpha.900")}>{`${selectedNodes.length} selected`}</Text><Divider h="18px" orientation="vertical" /></HStack>}
            <HStack spacing={1}>
                {allLinkedPages && <Unlink OptionsPopover={OptionsPopover} />}
                {allShowCovers && allLinkedPages && <Device OptionsPopover={OptionsPopover} />}
                {isEmpty(linkedPages) && <Type OptionsPopover={OptionsPopover} multiple={multiple} />}
                <Color OptionsPopover={OptionsPopover} />
                <ArrangeMenu />
                <Delete OptionsPopover={OptionsPopover} />
            </HStack>
        </HStack>
    )
}