import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
} from "@chakra-ui/react"
import { ToggleButtonGroup } from '../Components/ToggleButton/ButtonGroup';
import { ToggleButton } from '../Components/ToggleButton/Button';
import { hideCommentsPopover } from '../../../Sitemap/comments/helpers.jsx';
import { showCovers, hideCovers } from '../../../../store/actions/sitemap-actions';
import { update } from '../../../Sitemap/utils/app';
import { BsFileRichtext, BsFileText } from 'react-icons/bs'
import { EditorSkeletonWrapper } from '..';

export const Views = () => {
    const state = useSelector(state => state, shallowEqual)
    const { sitemap } = state
    const dispatch = useDispatch()
    const handleCoversChange = (show) => {
        // dispatch to redux
        dispatch(show === "false" ? hideCovers(sitemap?.id) : showCovers(sitemap?.id))
        // persist
        localStorage.setItem(`${sitemap?.id}#showCovers`, show);
        // hide comment popover if showing
        hideCommentsPopover();
        // update
        update({ noTransition: true });
    }
    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                value={sitemap?.showCovers ? "true" : "false"}
                isAttached
                variant="outline"
                isRadio
                onChange={handleCoversChange}
            >
                <ToggleButton value={"true"} icon={<BsFileRichtext fontSize="18px" />} />
                <ToggleButton value={"false"} icon={<BsFileText fontSize="18px" />} />
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}