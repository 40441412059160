import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    IconButton,
    TableContainer,
    Stack,
    Icon,
    Link,
    Skeleton,
} from "@chakra-ui/react";

import { TbChevronLeft, TbChevronLeftPipe, TbChevronRight, TbChevronRightPipe } from "react-icons/tb";
import _makeData from "./_makeData";


export const VirtualizedTable = ({ width, data, pages }) => {

    const dataToUse = useMemo(() => data?.pages?.loaded ? pages : _makeData(), [data?.pages])
    
    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "URL",
                accessor: "url",
                Cell: ({ cell: { value } }) => <Link href={value} _hover={{ color: 'rarchy-link', textDecor: "underline" }} isExternal>{value}</Link>
            },
            ...data?.type === 'crawl' ? [{
                Header: "SEO - Page Title",
                accessor: "title"
            },
            {
                Header: 'SEO - Meta Description',
                accessor: "description"
            },
            {
                Header: 'SEO - H1 Tag',
                accessor: "h1"

            }] : []
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            data: dataToUse,
            columns,
            initialState: { pageIndex: 0, pageSize: 100 }
        },
        usePagination
    );

    return (
        <>
            <TableContainer w={width} maxH={`calc(100vh - 275px)`} overflowY="scroll" borderBottom="1px solid" borderColor="rarchy-border-sitemap-editor">
                <Table {...getTableProps()} size='sm' display="table">
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} alignItems="stretch">
                                {headerGroup.headers.map((column) => (
                                    <Th {...column.getHeaderProps()} minW="fit-content">{column.render("Header")}</Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <Td {...cell.getCellProps()} fontSize="sm" minW="fit-content" color={cell?.value ? "fg.default" : "fg.muted"}>
                                                <Skeleton isLoaded={data?.pages?.loaded}>
                                                    {cell?.value ? cell.render("Cell") : "--"}
                                                </Skeleton>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            {data?.pages?.loaded && (
                <Stack direction="row" justifyContent="end" alignItems="center" py={3} spacing={12}>
                    <Flex alignItems="center" fontSize="sm" color="fg.muted">
                        {`${((pageIndex * pageSize) + 1)?.toLocaleString()}-${(rows?.length < 100 ? rows?.length : (pageIndex * pageSize) + pageSize).toLocaleString()} of ${rows?.length?.toLocaleString()} pages`}
                    </Flex>
                    <Stack direction="row">
                        <IconButton
                            variant="ghost"
                            size="sm"
                            onClick={() => gotoPage(0)}
                            isDisabled={!canPreviousPage}
                            icon={<Icon as={TbChevronLeftPipe} fontSize="xl" />}
                        />
                        <IconButton
                            variant="ghost"
                            size="sm"
                            onClick={previousPage}
                            isDisabled={!canPreviousPage}
                            icon={<Icon as={TbChevronLeft} fontSize="xl" />}
                        />
                        <IconButton
                            variant="ghost"
                            size="sm"
                            onClick={nextPage}
                            isDisabled={!canNextPage}
                            icon={<Icon as={TbChevronRight} fontSize="xl" />}
                        />
                        <IconButton
                            variant="ghost"
                            size="sm"
                            onClick={() => gotoPage(pageCount - 1)}
                            isDisabled={!canNextPage}
                            icon={<Icon as={TbChevronRightPipe} fontSize="xl" />}
                        />
                    </Stack>
                </Stack>
            )}
        </>
    );
}