import { IconButton, Tooltip } from "@chakra-ui/react"
import { useHasLoadedFiles, useInProjectsGridView, useIsViewingFolder } from "../../../hooks"
import { BsGrid, BsListUl } from "react-icons/bs"
import { useDispatch } from "react-redux"
import { changeProjectsView } from "../../../store/actions/projects-actions"
import { toTitleCase } from "../../../helpers"

export const ProjectsView = ({ showInSections }) => {
    const dispatch = useDispatch()
    const inGridView = useInProjectsGridView()
    const isViewingFolder = useIsViewingFolder()
    const hasLoadedFiles = useHasLoadedFiles()
    if (!hasLoadedFiles || (showInSections && !isViewingFolder)) return null
    const FONT_SIZE = `${showInSections ? 21 : 21}px`
    return (
            <Tooltip hasArrow label={`View as ${toTitleCase(inGridView ? 'list' : 'grid')}`} variant="rounded-sm" openDelay={500}>
                <IconButton
                    variant={showInSections ? "ghost" : "solid"}
                    icon={inGridView ? <BsListUl fontSize={FONT_SIZE} /> : <BsGrid fontSize={FONT_SIZE} />}
                    onClick={() => {
                        dispatch(changeProjectsView(inGridView ? 'list' : 'grid'))
                    }}
                />
            </Tooltip>
    )
}