import { Box, HStack, Heading, Stack } from "@chakra-ui/react"
import { useGetHeadingTitleFromSubfolder, useInTrash } from '../../hooks';

import { New } from './Components/New';
import React from 'react'
import { Sections } from './Components/Sections';
import { SkeletonWrapper } from '../Components/Shared/SkeletonWrapper';
import { TopBar } from './Components/Topbar';

export const Projects = () => {
    const inTrash = useInTrash()
    const title = useGetHeadingTitleFromSubfolder()
    return (
        <Box py={6}>
            <Stack spacing={5}>
                <HStack justifyContent="space-between" align="center">
                    <SkeletonWrapper w="fit-content">
                        <Heading fontSize="3xl" fontWeight="semibold" py={.5}>
                            {!inTrash ? title : "Trash"}
                        </Heading>
                    </SkeletonWrapper>
                    {!inTrash && <New />}
                </HStack>
                {!inTrash && <TopBar />}
                <Sections />
            </Stack>
        </Box>
    )

}