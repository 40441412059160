import {
    IconButton,
    VStack,
} from "@chakra-ui/react"
import React, { useEffect, useState } from 'react';
import { useGetSitemap, useIsPageCommentsDrawerShowing, usePrevious } from '../../../../hooks';

import { ChatIcon } from '@chakra-ui/icons';
import { Header } from './Components/Header';
import { Pages } from './Components/Pages';
import { handleSelectedPage } from './utils';
import queryString from 'query-string';
import { togglePageCommentsDrawer } from '../../../../store/actions/sitemap-actions';
import { useDispatch } from 'react-redux';

export const Comments = () => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const prevSitemap = usePrevious(sitemap)

    // im
    useEffect(() => {
        if (sitemap?.loaded && !prevSitemap?.loaded) {
            setTimeout(() => {
                const { pc: pageId } = queryString.parse(window.location.search); // pc = page comment
                if (pageId) handleSelectedPage(sitemap, pageId)
            }, 500);
        }
    }, [sitemap])

    return (
        <>
            <IconButton
                onClick={() => { dispatch(togglePageCommentsDrawer()) }}
                isRound
                size="md"
                fontSize="lg"
                aria-label="Show comments"
                variant="ghost"
                icon={<ChatIcon />}
                color="rarchy-color-sitemap-editor"
            />
        </>
    )
}

export const PageCommentsDrawer = () => {
    const [filter, setFilter] = useState('all')
    const isShowingPageCommentsDrawer = useIsPageCommentsDrawerShowing()
    if (!isShowingPageCommentsDrawer) return null
    return (
        <VStack
        w="full"
            pt={3}
            paddingInline={6}
        >
            <Header />
            <Pages filter={filter} setFilter={setFilter} inNavbar />
        </VStack>
    )
}