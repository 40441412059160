import { Divider, HStack, IconButton, Stack } from "@chakra-ui/react"
import { useGetEditor, useGetFilesData, useGetUi, useInEditor } from "../../../../../hooks"

import { AutoSave } from "../../../../Editor/Navbar/Details/AutoSave"
import { EditorSkeletonWrapper } from "../../../../Editor/Toolbar"
import { FileMenu } from "../../../../Files/Components/Options/Menu"
import { FileName } from "../../../../Files/Components/Name"
import { HamburgerIcon } from "@chakra-ui/icons"
import { Link } from "react-router-dom"
import { Logo } from "../../../../Auth/SignupLogin/Components/Logo"
import { LogoSymbol } from "../../../../../store/LogoSymbol"
import { toggleSideMenu } from "../../../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useMemo } from "react"

export const Left = ({ inSideMenu }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const inEditor = useInEditor()
    const editor = useGetEditor()
    // ensures name/domain updates in file menu are shown in editor
    const file = useGetFilesData()?.find(f => f.id === editor?.id && f?.collection === editor?.collection) || editor
    const isSideMenuCollapsed = useGetUi()?.sideMenu?.collapsed
    const showHamburger = ((inEditor && isSideMenuCollapsed) || (inSideMenu || isSideMenuCollapsed))
    return (
        <Stack
            direction="row"
            // w="256px"
            spacing={2}
            align="center"
        >
            {showHamburger && (
                <IconButton
                    icon={<HamburgerIcon />}
                    variant="ghost"
                    fontSize="2xl"
                    isRound
                    color={inSideMenu ? "whiteAlpha.900" : "auto"}
                    _hover={{ bgColor: inSideMenu ? "whiteAlpha.200" : "rarchy-bg-subtle-hover" }}
                    onClick={() => dispatch(toggleSideMenu())}
                />
            )}
            {inEditor && !inSideMenu && (
                <Stack direction="row" align="center" spacing={3}>
                    {isSideMenuCollapsed && (
                        <>
                            <IconButton
                                onClick={() => history.push("/app")}
                                icon={<LogoSymbol />}
                                variant="ghost"
                                color="#01a7e1" // in editor
                                fontSize="md"
                                size="sm"
                            />
                            <Divider orientation="vertical" h="25px" mr={2} />
                        </>
                    )}
                    <HStack mr={2} spacing={2}>
                        <HStack spacing={4}>
                            <EditorSkeletonWrapper>
                                <FileName file={file} opts={{ w: "max-content", fontSize: "md", fontWeight: "semibold" }} />
                            </EditorSkeletonWrapper>
                            <EditorSkeletonWrapper><FileMenu file={file} /></EditorSkeletonWrapper>
                        </HStack>
                        <AutoSave />
                    </HStack>
                </Stack>
            )}
            {inSideMenu && <Link to="/app"><Logo w="108px" inSideMenu={inSideMenu} /></Link>}
        </Stack>
    )
}