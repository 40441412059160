import { Box, HStack } from "@chakra-ui/react"
import { CreateNewButton } from "./New"
import { Move } from "./Move"

export const Footer = () => {
    return (
        <Box
            p={3}
            pos="absolute"
            w="full"
            borderBottomRadius="md"
            borderTop="1px solid"
            borderColor="rarchy-bg-subtle-hover"
        >
            <HStack w="full" justify="space-between">
                <CreateNewButton />
                <Move />
            </HStack>
        </Box>
    )
}