import { Icon, MenuItem } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { wait } from "../../../../../shared/helpers"
import { getFirestore, doc, setDoc, deleteField, getDoc } from "firebase/firestore"
import { mergeFileChange } from "../../../../store/actions/files-actions"
import { getAccountId, getInEditor, getUserId } from "../../../../helpers"
import { MenuItemSpinner } from "../../../Components/Shared/MenuItemSpinner"
import { GrUndo } from "react-icons/gr"

export const RestoreFile = ({ file, onClose }) => {

    const dispatch = useDispatch()

    const [isRestoring, setIsRestoring] = useState(false)

    return (
        <>
            <MenuItem
                py={2}
                isDisabled={isRestoring}
                icon={isRestoring ? <MenuItemSpinner /> : <Icon as={GrUndo} fontSize="xl" />}
                onClick={async () => {
                    setIsRestoring(true)
                    await wait(500)
                    try {
                        // restore file
                        await restoreFile({ dispatch, file })
                        // close menu
                        if (onClose) onClose()
                    } catch (e) {
                        console.error(e)
                    } finally {
                        setIsRestoring(false)
                    }
                }}>
                Restore
            </MenuItem>
        </>
    )
}

export const restoreFile = async ({ dispatch, history, file }) => {

    const userId = getUserId()
    const accountId = getAccountId()
    const inEditor = getInEditor()
    // ensure folder still exists before saving (otherwise save to "Your projects" (accountId))
    const team = await (async () => {
        const teamDoc = await getDoc(doc(getFirestore(), "teams", file?.team));
        return (teamDoc.exists() && !teamDoc?.data()?.archived) ? file?.team : accountId;
    })()
    // save to firestore
    const docData = (forFirestore) => {
        return {
            updatedBy: userId,
            archived: false,
            team,
            archivedAt: forFirestore ? deleteField() : null,
            deleteAt: forFirestore ? deleteField() : null,
            account: forFirestore ? deleteField() : null,
        }
    }
    try {
        const fileRef = doc(getFirestore(), file?.collection, file?.id);
        await setDoc(fileRef, docData(true), { merge: true });
        // go to file if in editor
        if (inEditor) {
            window.location.reload();
        } else {
            dispatch(mergeFileChange(file?.id, file?.collection, { ...docData(), setToArchived: false }))
        }
    } catch (e) {
        console.error(e)
    }

}