import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Tooltip, useColorModeValue as mode } from "@chakra-ui/react"
import React from "react"
import { GrPowerReset } from "react-icons/gr"
import { RiBringForward, RiBringToFront, RiSendBackward, RiSendToBack } from "react-icons/ri"

// 0 is the base layer (default if node doesn't have index)
import { store } from "../../../../../../store";
import { addConnectorChange, addSymbolChange } from "../../../helpers";
import { isEmpty, min, max, map } from 'lodash'

export const ArrangeMenu = () => {
    return (
        <Menu size="sm" autoSelect={false} placement="bottom-start" isLazy>
            <Tooltip variant="rounded" placement='top' label='Arrange' openDelay={1000}>
                <MenuButton as={IconButton} color={mode("gray.600", "whiteAlpha.900")} size="sm" variant="ghost" icon={<RiBringToFront fontSize="16px" />} onClick={e => e.stopPropagation()} />
            </Tooltip>
            <MenuList fontSize="sm" minW={0}>
                <MenuItem icon={<RiBringToFront />} onClick={() => { handleArrangementClick('front') }}>Bring to Front</MenuItem>
                <MenuItem icon={<RiSendToBack />} onClick={() => { handleArrangementClick('back') }}>Send to Back </MenuItem>
                <MenuItem icon={<RiBringForward />} onClick={() => { handleArrangementClick('forward') }}>Bring Forward</MenuItem>
                <MenuItem icon={<RiSendBackward />} onClick={() => { handleArrangementClick('backward') }}>Send Backward</MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => { handleArrangementClick('reset') }}>Reset</MenuItem>
            </MenuList>
        </Menu>
    )
}

const handleArrangementClick = (action) => {

    // NEED TO UPDATE TO HANDLE MULTI-SELECT

    const { flow } = store.getState();
    const { OptionsPopover } = flow.ui;
    const { symbolOptions, connectorOptions } = OptionsPopover;

    const nodes = symbolOptions ? OptionsPopover.nodes : connectorOptions ? OptionsPopover.links : null;
    if (!nodes) return;

    let dataForRendering = {};
    let oldIndex;

    nodes.forEach(node => {

        const nodeId = connectorOptions ? node.end.id : node.id;
        const data = connectorOptions ? flow.data.links : flow.data.nodes;

        const nodesByIndexes = {};
        [...data].forEach(d => {
            const dId = connectorOptions ? d.end.id : d.id;
            if (d.index) {
                if (!nodesByIndexes[d.index]) nodesByIndexes[d.index] = [];
                nodesByIndexes[d.index].push(dId);
            }
        })

        // max index
        var maxIndex = max(map(data, 'index'));
        maxIndex = maxIndex ? maxIndex > 0 ? maxIndex : 1 : 0;
        // min index
        var minIndex = min(map(data, 'index'));
        minIndex = minIndex ? minIndex < 0 ? minIndex : -1 : 0;

        const maxForwardIndex = getMaxForwardIndex(maxIndex, nodesByIndexes, nodeId);
        const minBackwardIndex = getMinBackwardIndex(minIndex, nodesByIndexes, nodeId);

        let index;

        if (action === 'front') {
            if (node.index === maxForwardIndex) return; // already at max
            index = maxIndex + 1;
        }
        if (action === 'back') {
            if (node.index === minBackwardIndex) return; // already at min
            index = minIndex - 1
        }
        if (action === 'forward') {
            if (node.index === maxForwardIndex) return; // already at max
            index = node.index ? node.index + 1 : 1
        }
        if (action === 'backward') {
            if (node.index === minBackwardIndex) return; // already at min
            index = node.index ? node.index - 1 : -1
        }

        if (action === 'reset') {
            if (!node.index || node.index === 0) return; // already reset
            index = 0;
        }

        dataForRendering[nodeId] = { index };

        // just for connectors
        oldIndex = node.index ? node.index : undefined

    });

    if (symbolOptions) {
        const oldData = {}; nodes.forEach(({ id, index }) => oldData[id] = { ...oldData[id], index });
        addSymbolChange({ data: dataForRendering, oldData });
        console.log(dataForRendering);
    } else {
        /*** save ***/
        const link = nodes[0];
        const data = { [link.start.id]: { connectors: { [link.start.position]: { ...dataForRendering } } }}
        const oldData = { [link.start.id]: { connectors: { [link.start.position]: { [link.start.id]: { index: oldIndex } } } } }
        addConnectorChange({ data, oldData })
        /*** save ***/
    }
}

const getMaxForwardIndex = (maxIndex, nodesByIndexes, nodeId) => {
    if (!isEmpty(nodesByIndexes) && !isEmpty(nodesByIndexes[maxIndex])) {
        const numberOfNodesWithMaxIndex = nodesByIndexes[maxIndex].length;
        if (nodesByIndexes[maxIndex].includes(nodeId)) {
            // node to bring forward is the only one at maxIndex - can't go further
            if (numberOfNodesWithMaxIndex === 1) return parseInt(maxIndex);
        }
    }
    //
    return parseInt(maxIndex ? maxIndex + 1 : 1);
}

const getMinBackwardIndex = (minIndex, nodesByIndexes, nodeId) => {
    if (!isEmpty(nodesByIndexes) && !isEmpty(nodesByIndexes[minIndex])) {
        const numberOfNodesWithMinIndex = nodesByIndexes[minIndex].length;
        if (nodesByIndexes[minIndex].includes(nodeId)) {
            // node to bring forward is the only one at maxIndex - can't go further
            if (numberOfNodesWithMinIndex === 1) return parseInt(minIndex);
        }
    }
    //
    return parseInt(minIndex ? minIndex - 1 : -1);
}