import { AbsoluteCenter, Button, Container, Divider, Flex, HStack, Heading, Text, VStack } from "@chakra-ui/react"
import { useCanFullyEditFile, useGetEditor } from "../../hooks"

import { Link } from "react-router-dom"
import { WarningTwoIcon } from "@chakra-ui/icons"
import dayjs from 'dayjs';
import { getCollectionLabelFromDoc } from "../../helpers"
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { restoreFile } from "../Files/Components/Options/Restore"
import updateLocale from 'dayjs/plugin/updateLocale';
import { useDispatch } from "react-redux"
import { useState } from "react"

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

// dayjs.updateLocale('en', { relativeTime: { future: "in %s", past: "%s ago", s: 'seconds time', m: "a minutes time", mm: "%d minutes time", h: "an hours time", hh: "%d hours time", d: "a days time", dd: "%d days time", M: "%d days time" } })

export const Error = ({ file }) => {
    const [isRestoring, setIsRestoring] = useState(false);
    const dispatch = useDispatch()
    const hasFullAccess = useCanFullyEditFile()
    const fileLabel = getCollectionLabelFromDoc(file)
    const { name, error, archivedAt, deleteAt, collection } = file;
    const message = renderMessaging({ name, error, archivedAt, deleteAt, collection })
    const isArchived = error === 'archived'
    const manuallyArchived = isArchived && (archivedAt && deleteAt)
    return (
        <Container pt={24}>
            <VStack spacing={16} align="center" w="full">
                <WarningTwoIcon fontSize="90px" color="rarchy-red" />
                <VStack spacing={9} align="start" w="md">
                    <VStack align="start" spacing={6}>
                        <Heading fontSize="2xl">
                            {`${message}`}
                        </Heading>
                        {isArchived && (
                            manuallyArchived
                                ? <Text color="rarchy-red" fontWeight="medium">
                                    {`It will be permanently deleted ${dayjs(deleteAt?.toString()).fromNow()} on ${dayjs(deleteAt?.toString()).format('ll')}`}
                                </Text>
                                : <Text fontSize="md" color="fg.muted">
                                    {`This file was archived as it's organization's account was deleted.`}
                                </Text>
                        )}
                        <Text fontSize="md" color="fg.muted">
                            If you think this is an error, please get in touch with us.
                        </Text>
                    </VStack>
                    <Divider />
                    <HStack spacing={6} w="full" justify="end">
                        {manuallyArchived && hasFullAccess && (
                            <Button
                                isLoading={isRestoring}
                                loadingText="Restoring..."
                                textTransform="capitalize"
                                onClick={() => {
                                    setIsRestoring(true)
                                    restoreFile({ dispatch, file }) // restore file
                                }}>
                                {`Restore ${fileLabel} `}
                            </Button>
                        )}
                        {!manuallyArchived && <Link to="/app/help/ticket"><Button variant="outline">Get in touch</Button></Link>}
                        <Link to="/app"><Button colorScheme="blue">Go back</Button></Link>
                    </HStack>
                </VStack>
            </VStack>
        </Container>
    )
}

const renderMessaging = ({ name, error, archivedAt, deleteAt, collection }) => {
    const label = getCollectionLabelFromDoc({ collection })
    if (error === 'archived') {
        const manuallyArchived = (archivedAt && deleteAt);
        if (!manuallyArchived) return `"${name}" has been archived.`
        return `This sitemap was set to archive on ${dayjs(archivedAt?.toString()).format('ll')}`
    }
    if (error === 'not-found') return `Sorry! We couldn't find any ${label || 'file'}s with this URL.`
}