import React from 'react'

import {
    Box,
    Center,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Stack,
    Text,
    Icon,
} from "@chakra-ui/react"
import { TbSitemap } from "react-icons/tb"
import { useDispatch } from 'react-redux'
import { addSymbolChange } from '../../../user-flows/helpers'
import { togglePageDrawer } from '../../../../../store/actions/sitemap-actions'
import { LinkIcon } from '@chakra-ui/icons'
import { PagesTree } from '../../components/PagesTree'
import { useCanEditFile, useGetSitemap, useGetUi, useGetUserFlow, useIsUserFlowLinkedToSitemap } from '../../../../../hooks'


export const Pages = () => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    const ui = useGetUi()
    const flow = useGetUserFlow()
    const isLinkedToSitemap = useIsUserFlowLinkedToSitemap()
    // can edit
    const canEdit = useCanEditFile() // use this instead of permissions toast, so users can link pages from sitemap, even if they can't edit pages
    if (!canEdit) return null;
    // continue
    return (
        <Box pb={4} w="full" className={`linked-pages-tree ${ui.colorMode} no-checkbox`}>
            <Accordion allowToggle mb={4} mt={2}>
                <AccordionItem border="none" rounded="md" bgColor="rarchy-bg-subtle">
                    <AccordionButton>
                        <LinkIcon mr={3} fontSize="xs" />
                        <Box as="span" flex='1' textAlign='left' fontSize="sm" fontWeight="medium">
                            Select a page to link from your sitemap
                        </Box>
                        {/* <Button variant="link" fontSize="xs">Learn more</Button> */}
                    </AccordionButton>
                    <AccordionPanel pb={4} px={6} fontSize="sm" color="fg.muted">
                        When a page is linked in this user flow, any edits to this page will also be reflected in the linked sitemap, and vice versa.
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {!isLinkedToSitemap && (
                <Center justify="center">
                    <Stack mt={12} align="center" spacing={9}>
                        <Icon as={TbSitemap} fontSize="100px" color="fg.subtle" opacity={0.8} />
                        <Text color="fg.muted">Link to a sitemap to view pages</Text>
                    </Stack>
                </Center>
            )}
            {isLinkedToSitemap && (
                <PagesTree maxH="calc(100vh - 260px)" onClick={({ value: page }) => {
                    if (page.includes('website_section_')) return;
                    // continue
                    const { PageDrawer } = sitemap?.ui
                    const { page: node } = PageDrawer;
                    const data = { [node.id]: { page, wireframe: undefined } }
                    const oldData = { [node.id]: { page: node.page, device: node.device, wireframe: node.wireframe } }
                    addSymbolChange({ data, oldData });
                    dispatch(togglePageDrawer({ node: PageDrawer.page }));
                }} />
            )}
        </Box>
    )
}