import { Avatar, Box, Button, Circle, FormControl, FormHelperText, FormLabel, Icon, IconButton, Input, Stack, Text, useBoolean } from "@chakra-ui/react"
import { getUsersFullName } from "../../../../helpers"
import { useGetPhotoURL, useGetUser } from "../../../../hooks"
import { SectionHeader } from "../../Components"
import { useRef, useState } from "react"
import { BiCamera } from "react-icons/bi"
import { getDownloadURL, getStorage, ref, deleteObject, uploadBytesResumable } from "firebase/storage";
import { getFirebase } from 'react-redux-firebase';
import { SkeletonCircleWrapper, SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const Photo = () => {

    const [isMouseover, setIsMouseover] = useBoolean()
    const [previewImage, setPreviewImage] = useState(null)
    const [isRemoving, setIsRemoving] = useBoolean()

    const user = useGetUser()
    const name = getUsersFullName()
    const fileInputRef = useRef(null)

    const photoURL = useGetPhotoURL()

    const firebase = getFirebase()

    const hasPhoto = photoURL ? true : false

    const handleFileChange = (event) => {

        const file = event.target.files?.[0];

        setPreviewImage(URL.createObjectURL(file))

        if (file) {

            getAvatarThumbnail(file, async (thumbnail) => {

                var fileToUpload = thumbnail.size < file.size ? thumbnail : file;

                const photoRef = ref(getStorage(), `users/${user?.id}/profile.png`);
                //
                const uploadTask = uploadBytesResumable(photoRef, fileToUpload, { cacheControl: 'public,max-age=86400', customMetadata: { userId: user?.id } })

                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        setPreviewImage(null)
                    },
                    async () => {
                        const photoURL = await getDownloadURL(uploadTask.snapshot.ref)
                        try {
                            await firebase.updateAuth({ photoURL }, true);
                            //
                        } catch (e) {
                            console.error(e);
                        }
                        setPreviewImage(null)
                    }
                );

            })
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };

    return (
        <Stack mt={10} mb={2.5}>
            {hasPhoto && <SectionHeader title="Profile photo" />}
            <Stack direction="row" spacing={5} align="center" w="full">
                <SkeletonCircleWrapper>
                    <Avatar
                        name={name}
                        src={previewImage || user?.photoURL}
                        size="gx"
                        onMouseEnter={() => { setIsMouseover.on() }}
                        onMouseLeave={() => { setIsMouseover.off() }}
                        onClick={handleButtonClick}
                    />
                </SkeletonCircleWrapper>
                {isMouseover && (
                    <IconButton w="80px" h="80px" pos="absolute" bgColor="blackAlpha.700" pointerEvents="none" isRound icon={<Icon as={BiCamera} color="white" fontSize="3xl" />} />
                )}
                <input type="file" accept=".jpg,.png" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                <Stack direction="row" spacing={5} align="center" w="full" justify="space-between">
                    {!hasPhoto && (
                        <>
                            <Stack align="start">
                                <SectionHeader title="Upload your profile photo" />
                                <SkeletonWrapper w="fit-content">
                                    <Text fontSize="sm">This helps your teammates recognise you in Rarchy.</Text>
                                </SkeletonWrapper>
                            </Stack>
                            <SkeletonWrapper w="fit-content">
                                <Button fontSize="sm" px={6} onClick={handleButtonClick}>Upload photo</Button>
                            </SkeletonWrapper>
                        </>
                    )}
                </Stack>
                {hasPhoto && (
                    <Stack direction="row" spacing={4} align="center" w="full" justify="end">
                        <SkeletonWrapper w="fit-content">
                            <Button
                                fontSize="sm"
                                px={5}
                                variant="ghost"
                                isLoading={isRemoving}
                                onClick={async () => {
                                    setIsRemoving.on()
                                    await firebase.updateAuth({ photoURL: '' }, true);
                                    // Delete the file
                                    const photoRef = ref(getStorage(), `users/${user?.id}/profile.png`);
                                    await deleteObject(photoRef)
                                    setIsRemoving.off()
                                }}>
                                Remove photo
                            </Button>
                        </SkeletonWrapper>
                        <SkeletonWrapper w="fit-content">
                            <Button fontSize="sm" px={5} onClick={handleButtonClick}>Change photo</Button>
                        </SkeletonWrapper>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

const getAvatarThumbnail = (file, done) => {
    const width = 128;
    const height = 128;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        /* eslint-disable-next-line */
        img.onload = () => {
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext('2d');
            // img.width and img.height will contain the original dimensions
            ctx.drawImage(img, 0, 0, width, height);
            ctx.canvas.toBlob((blob) => {
                const thumbnail = new File([blob], "profile", {
                    type: 'image/jpeg',
                    lastModified: Date.now()
                });
                done(thumbnail);
            }, 'image/jpeg', 1);
        }
        reader.onerror = error => console.error(error);
    };
}