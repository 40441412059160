import { useState } from "react";
import {
    Box,
    Text,
    Menu,
    MenuList,
    MenuItem,
    MenuOptionGroup
} from "@chakra-ui/react"
import { useDispatch } from 'react-redux'
import { logOut } from "../../../../../../store/actions/auth-actions";
import { useHistory } from "react-router-dom";
import { Accounts } from "./Accounts";
import { useHasEditorLoaded, useInEditor, useIsLoggedIn } from "../../../../../../hooks";
import { ProfileAvatar } from "./ProfileAvatar";
import { Link } from "react-router-dom";
import { UserAvatarDetail } from "../../../../Shared/UserAvatarDetail";
import { wait } from "../../../../../../../shared/helpers";
import { MenuItemSpinner } from "../../../../Shared/MenuItemSpinner";
import { motionPropsZeroAnimation } from "../../../../../../helpers";

const PY = 2

export const ProfileDropdown = () => {

    const [isSigningOut, setIsSigningOut] = useState(false)

    const history = useHistory()

    const dispatch = useDispatch()
    const inEditor = useInEditor()

    const loadedEditor = useHasEditorLoaded()

    const isLoggedIn = useIsLoggedIn()

    if (inEditor && loadedEditor && !isLoggedIn) return null;

    return (
        <Menu autoSelect={false} closeOnSelect={false}>
            {({ onClose }) => {
                return (
                    <>
                        <ProfileAvatar />
                        <MenuList p={0} fontSize="sm" w="275px" motionProps={motionPropsZeroAnimation}>
                            <MenuOptionGroup
                                as={Box}
                                m={0}
                                borderBottom="1px solid"
                                borderColor="rarchy-border"
                                paddingInline={0}
                                p={3}
                                title={<UserAvatarDetail emailSize="xs" />}
                                type='radio'
                            >
                            </MenuOptionGroup>
                            <Box maxH="200px" overflow="scroll"> {/* scrollable! */}
                                <Accounts inNav onClose={onClose} />
                            </Box>
                            <Box py={2} borderTop="1px solid" borderColor="rarchy-border">
                                <Link to="/app/settings/your-account">
                                    <MenuItem py={PY} onClick={onClose}>Account settings</MenuItem>
                                </Link>
                                <Link to="/app/help/faqs">
                                    <MenuItem py={PY} onClick={onClose}>Get help</MenuItem>
                                </Link>
                                <MenuItem
                                    py={PY}
                                    isDisabled={isSigningOut}
                                    onClick={async () => {
                                        setIsSigningOut(true)
                                        try {
                                            await wait(500)
                                            dispatch(logOut(history))
                                            onClose()
                                        } catch (e) {

                                        } finally {
                                            setIsSigningOut(false)
                                        }
                                    }}
                                >
                                    {isSigningOut && <MenuItemSpinner mr={3} />}
                                    <Text>Sign out</Text>
                                </MenuItem>
                            </Box>
                        </MenuList>
                    </>
                )
            }}
        </Menu>
    )
};