import { ARC_TO_RADIUS } from "../helpers";
// test config by dragging end node - NOT start node
export const drawTopStarts = (context, values) => {
    const { endPos, startNode, endNode, startConnectorX, endConnectorX, startConnectorY, endConnectorY, minPadding, startConnectorIsToTheLeftOfEndConnector, startConnectorIsBelowEndConnector, midXBetweenNodes, midYBetweenNodes } = values;
    // always start drawing here
    context.moveTo(startConnectorX, startConnectorY);
    // top
    if (endPos === 'top') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startConnectorX >= endNode.x) {
                    context.arcTo(startConnectorX, midYBetweenNodes, endNode.x - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endNode.x - minPadding, midYBetweenNodes, endNode.x - minPadding, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.x - minPadding, endConnectorY - minPadding, endConnectorX, (startConnectorY >= endConnectorY ? endConnectorY : startConnectorY) - minPadding, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, (startConnectorY >= endConnectorY ? endConnectorY : startConnectorY) - minPadding, endConnectorX, (startConnectorY >= endConnectorY ? endConnectorY : startConnectorY) - minPadding, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX, (startConnectorY >= endConnectorY ? endConnectorY : startConnectorY) - minPadding, endConnectorX, (startConnectorY >= endConnectorY ? endConnectorY : startConnectorY), ARC_TO_RADIUS)
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if ((startConnectorX - minPadding) >= (endNode.rightOfNode)) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, startConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, midYBetweenNodes, endNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, midYBetweenNodes, endNode.rightOfNode + minPadding, endConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, endConnectorY - minPadding, endConnectorX, endConnectorY - minPadding, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX, endConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if ((endConnectorX + minPadding) >= (startNode.x)) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, startNode.x - minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.x - minPadding, startConnectorY - minPadding, startNode.x - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.x - minPadding, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, endConnectorX, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, startConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if ((endConnectorX - minPadding) >= (startNode.rightOfNode)) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, endConnectorX, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, startConnectorY - minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, startNode.rightOfNode + minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY - minPadding, startNode.rightOfNode + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    // bottom
    if (endPos === 'bottom') {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            if ((startConnectorY) < (endConnectorY + minPadding)) {
                context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY + minPadding, ARC_TO_RADIUS)
                context.arcTo(midXBetweenNodes, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            } else {
                context.arcTo(startConnectorX, midYBetweenNodes, endConnectorX, midYBetweenNodes, ARC_TO_RADIUS)
                context.arcTo(endConnectorX, midYBetweenNodes, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if ((endNode.x - minPadding) < (startNode.rightOfNode + minPadding)) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, endNode.rightOfNode + minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, startConnectorY - minPadding, endNode.rightOfNode + minPadding, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.rightOfNode + minPadding, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if ((startNode.x - minPadding) < (endNode.rightOfNode + minPadding)) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, endNode.leftOfNode - minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.leftOfNode - minPadding, startConnectorY - minPadding, endNode.leftOfNode - minPadding, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endNode.leftOfNode - minPadding, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY + minPadding, endConnectorX, endConnectorY + minPadding, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX, endConnectorY + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
    }
    // left
    if (endPos === "left") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY < startNode.topOfNode - (minPadding * 0.5)) {
                    context.arcTo(startConnectorX, startConnectorY, startConnectorX, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (startConnectorY > (endNode.bottomOfNode + minPadding)) { // top of start node is below the very bottom of the end node
                    context.arcTo(startConnectorX, midYBetweenNodes, endConnectorX - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, midYBetweenNodes, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endNode.bottomOfNode + minPadding, endConnectorX - minPadding, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, endNode.bottomOfNode + minPadding, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (((startNode.rightOfNode) + minPadding) > endConnectorX) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, startNode.leftOfNode - minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, startConnectorY - minPadding, startNode.leftOfNode - minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (startConnectorY <= endNode.topOfNode) { // top of start node is above the end node connector
                    if (startNode.x <= endNode.x) {
                        context.arcTo(startConnectorX, startConnectorY - minPadding, startNode.leftOfNode - minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                        context.arcTo(startNode.leftOfNode - minPadding, startConnectorY - minPadding, startNode.leftOfNode - minPadding, endConnectorY, ARC_TO_RADIUS)
                        context.arcTo(startNode.leftOfNode - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                    } else {
                        context.arcTo(startConnectorX, startConnectorY - minPadding, endConnectorX - minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX - minPadding, startConnectorY - minPadding, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                    }
                } else {
                    context.arcTo(startConnectorX, endNode.topOfNode - minPadding, endConnectorX - minPadding, endNode.topOfNode - minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, endNode.topOfNode - minPadding, endConnectorX - minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX - minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    // right
    if (endPos === "right") {
        // start is in bottom
        if (startConnectorIsBelowEndConnector) {
            // start is in bottom right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (endConnectorY < startNode.topOfNode - (minPadding * 0.5)) {
                    context.arcTo(startConnectorX, startConnectorY, startConnectorX, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startConnectorX, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in bottom left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startConnectorY <= (endNode.bottomOfNode + minPadding)) {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endNode.bottomOfNode + minPadding, endConnectorX + minPadding, endNode.bottomOfNode + minPadding, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endNode.bottomOfNode + minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, midYBetweenNodes, endConnectorX + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, midYBetweenNodes, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                }
                context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
            }
        }
        // start is in top
        if (!startConnectorIsBelowEndConnector) {
            // start is in top left
            if (startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.rightOfNode < endNode.rightOfNode) {
                    if (startNode.topOfNode < endNode.topOfNode) {
                        context.arcTo(startConnectorX, startConnectorY - minPadding, endConnectorX + minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX + minPadding, startConnectorY - minPadding, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                    } else {
                        context.arcTo(startConnectorX, (endNode.topOfNode - minPadding), endConnectorX + minPadding, (endNode.topOfNode - minPadding), ARC_TO_RADIUS)
                        context.arcTo(endConnectorX + minPadding, (endNode.topOfNode - minPadding), endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                        context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                    }
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, startNode.rightOfNode + minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, startConnectorY - minPadding, startNode.rightOfNode + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(startNode.rightOfNode + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
            // start is in top right
            if (!startConnectorIsToTheLeftOfEndConnector) {
                if (startNode.leftOfNode > (endConnectorX + minPadding)) { // top of start node is above the end node connector
                    context.arcTo(startConnectorX, startConnectorY - minPadding, midXBetweenNodes, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, startConnectorY - minPadding, midXBetweenNodes, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(midXBetweenNodes, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                } else {
                    context.arcTo(startConnectorX, startConnectorY - minPadding, startNode.leftOfNode - minPadding, startConnectorY - minPadding, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, startConnectorY - minPadding, startNode.leftOfNode - minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(startNode.leftOfNode - minPadding, midYBetweenNodes, endConnectorX + minPadding, midYBetweenNodes, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, midYBetweenNodes, endConnectorX + minPadding, endConnectorY, ARC_TO_RADIUS)
                    context.arcTo(endConnectorX + minPadding, endConnectorY, endConnectorX, endConnectorY, ARC_TO_RADIUS)
                }
            }
        }
    }
    context.lineTo(endConnectorX, endConnectorY); // always end here
}