import { getSitemap, getUser } from '../../../../helpers';
import { keys } from 'lodash'

export const hitPersonalSitemapPageLimit = (futureCount) => {

    const sitemap = getSitemap()
    const user = getUser()
    const inUserSitemap = sitemap?.id === user.id;
    if (!inUserSitemap) return; // don't continue if user is logged in and not in user sitemap
    //
    var pagesCount = keys(sitemap?.docs.pages).length + futureCount;
    //
    if (pagesCount > 50) return true;

}