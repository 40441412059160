import { HStack, Box, Tooltip } from "@chakra-ui/react"
import { WarningIcon } from "@chakra-ui/icons"

export const Error = ({ user, forOrganization }) => {
    return (
        <Tooltip label={renderMessage({ error: user.error }, forOrganization)} openDelay={500} hasArrow variant="rounded-sm">
            <HStack>
                <WarningIcon />
                <Box>{user.email}</Box>
            </HStack>
        </Tooltip>
    )
}

const renderMessage = ({ error, forOrganization }) => {
    if (error === 'same') {
        return "You can't add yourself"
    }
    if (forOrganization) {
        return "Already a member of this account"
    }
    return "Already shared with this person"
}