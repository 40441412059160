import { Box, Heading } from "@chakra-ui/react"
import { SkeletonWrapper } from "../Components/Shared/SkeletonWrapper"

export const MainHeader = ({ title, fontSize }) => {
    return (
        <SkeletonWrapper w="fit-content">
            <Heading fontSize={fontSize || "lg"} fontWeight="semibold" color="fg.default">{title}</Heading>
        </SkeletonWrapper>
    )
}

export const MidHeader = ({ title }) => {
    return (
        <SkeletonWrapper w="fit-content">
            <Heading fontSize="md" fontWeight="semibold" color="fg.default">{title}</Heading>
        </SkeletonWrapper>
    )
}

export const SectionHeader = ({ title }) => {
    return (
        <SkeletonWrapper w="fit-content">
            <Heading fontSize="sm" fontWeight="semibold" color="fg.default">{title}</Heading>
        </SkeletonWrapper>
    )

}

export const BorderedBox = ({ mt, p, children }) => {
    return (
        <Box mt={mt || "unset"} border="1px solid" borderColor="rarchy-border" rounded="md" p={p || 5}>
            {children}
        </Box>
    )
}