import React, { useEffect, useRef } from 'react';

export let mainCanvas;

const ID = "main-canvas";

export const MainCanvas = React.memo(() => {
    const canvasRef = useRef(null);
    useEffect(() => {
        mainCanvas = canvasRef.current.getContext('2d', { alpha: true });
    }, [])
    return <canvas id={ID} ref={canvasRef} />
})
