import React, { useState, useEffect, useRef } from 'react';
import {
    Popover,
    useDisclosure,
} from "@chakra-ui/react"
import { useDispatch } from 'react-redux';
import { savePageColorChange } from '../../../../app/context-menu/Components/Color';
import { ColorButton } from './Button';
import { ColorContent } from './Content';
import { getInputColor } from '../../../utils/Shared/color';
import { addConnectorChange, addSymbolChange } from '../../../helpers';
import { mergePagesEdits } from '../../../../../../store/actions/sitemap-actions';
import { useFirestore } from 'react-redux-firebase';
import copy from 'fast-copy';
import { useGetUserFlow, useGetSitemap, useGetUser, useGetUi } from '../../../../../../hooks';
import { defaultPalletteColors } from '../../../../../../helpers';

export const Color = ({ OptionsPopover }) => {

    const sitemap = useGetSitemap()
    const user = useGetUser()
    const ui = useGetUi()
    const flow = useGetUserFlow()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const dispatch = useDispatch()
    const firestore = useFirestore()

    const nodes = copy(OptionsPopover.nodes); // need this so multi-select works
    const node = (nodes && nodes[0]) || (OptionsPopover.links && OptionsPopover.links[0]);

    const inputColor = getInputColor({ node, flow, colorMode: ui.colorMode });

    const [inputVal, setInputVal] = useState(inputColor)
    const inputValRef = useRef(inputVal);

    useEffect(() => { inputValRef.current = inputVal }, [inputVal]);

    // unmounting (save)
    useEffect(() => () => saveChanges(), [])

    if (!node) return;

    const handlePalletteChange = ({ header: color }) => {
        // if (this.props.showUpgradeMessaging) return;
        setInputVal(color);
        // dispatch edit so we can render to user immediately
        if (node.id) { // symbol
            if (node.page) {
                /*** merge page edits ***/
                const pageFromSitemapPagesDoc = sitemap?.docs.pages[node.page];
                if (pageFromSitemapPagesDoc) {
                    // var pages = { ...sitemap?.docs.pages, [node.page]: { ...pageFromSitemapPagesDoc, pallette: { header: color } } };
                    dispatch(mergePagesEdits({ pages: { [node.page]: { ...pageFromSitemapPagesDoc, pallette: { header: color } } } }))
                }
                /*** merge page edits ***/
            } else {
                const obj = {}; nodes.forEach(d => obj[d.id] = { color })
                addSymbolChange({ data: obj })
                // dispatch(changedSymbol(obj));
            }
        } else {
            // connector
            addConnectorChange({
                data: { [node.start.id]: { connectors: { [node.start.position]: { [node.end.id]: { color } } } } }
            })
            // dispatch(changedConnector({ [node.start.id]: { connectors: { [node.start.position]: { [node.end.id]: { color } } } } }));
        }
    }

    const saveChanges = () => {

        // should update
        const inUserSitemap = sitemap?.id === user.id;
        if (inUserSitemap) return // don't save

        const oldColor = node.color;
        const newColor = inputValRef.current;

        /*** color hasn't changed ***/
        if (!oldColor && defaultPalletteColors.includes(newColor)) return;
        if (oldColor === newColor) return;
        /*** color hasn't changed ***/

        if (node.id) {
            // linked page
            if (node.page) {
                savePageColorChange({ node: { ...node, id: node.page, pallette: { header: newColor } }, sitemap, firestore, dispatch })
            } else {
                const data = {}; nodes.forEach(d => data[d.id] = { color: newColor });
                const oldData = {}; nodes.forEach(({ id, color }) => oldData[id] = { ...oldData[id], color });
                addSymbolChange({ data, oldData });

            }
            // symbol
        } else {
            // connector
            const data = { [node.start.id]: { connectors: { [node.start.position]: { [node.end.id]: { color: newColor } } } } };
            const oldData = { [node.start.id]: { connectors: { [node.start.position]: { [node.end.id]: { color: node.color } } } } };
            addConnectorChange({ data, oldData });
        }
    }

    return (
        <Popover
            isLazy
            position="bottom"
            offset={[78, nodes?.length > 1 ? -28 : 48]}
            isOpen={isOpen}
            onClose={onClose}
            closeOnBlur
        >
            <ColorButton onToggle={onToggle} />
            <ColorContent handlePalletteChange={handlePalletteChange} inputVal={inputVal} setInputVal={setInputVal} inputColor={inputColor} onClose={onClose} />
        </Popover>
    )
}
