import {
    Center, Spinner
} from '@chakra-ui/react'

export const Render = ({ isLoading }) => {
    return (
        <Center w="full">
            {isLoading && <Spinner size="xl" speed='0.7s' />}
            <iframe style={{ display: isLoading ? "none" : "block" }} id="export-pdf-preview" title="Export PDF" width="100%" height="100%" />
        </Center>
    )
}