import { Text, VStack } from '@chakra-ui/react';
import { compact, isEmpty } from 'lodash'

import { getPageName } from '../../../utils/app';
import { getURL } from '../../../../../store/actions/sitemap-actions';

export const mapTreeData = ({ sitemap, opts }) => {
    const inSubfolder = sitemap?.data?.section;
    const root = inSubfolder || sitemap?.data.root;
    const { pages } = renderPagesForTree({ root, sitemap, opts });
    const websiteSections = [];
    if (!inSubfolder) {
        root?.website_sections?.forEach((s) => {
            const children = (renderPagesForTree({ root: s, sitemap, opts }) || {})?.pages;
            if (!isEmpty(children)) {
                websiteSections.push({
                    label: sitemap?.data?.website_sections?.[s.id]?.title,
                    value: `website_section_${sitemap?.data?.website_sections?.[s.id]?.id}`,
                    children: renderPagesForTree({ root: s, sitemap, opts }).pages,
                    showCheckbox: opts.showCheckbox
                })
            }
        });
    }
    // normal data
    const data = [{
        label: renderLabel(root.name, getURL(root.id)),
        name: root.name,
        value: root.id,
        children: pages,
        showCheckbox: opts.showCheckbox
    }, ...websiteSections];

    // select all
    const selectAll = [{
        label: `Select All`,
        value: "select-all",
        children: [...pages, ...websiteSections],
        showCheckbox: true
    }]
    // return
    return opts.showCheckbox ? selectAll : data;

}

function renderPagesForTree({ root, opts }) {
    const { showCheckbox, rightFilter } = opts;
    const filter = rightFilter?.filter;
    function recurse(node) {
        const showPage = filter ? filter(node) : true;
        var children = node.children ? node.children : node._children;
        const url = getURL(node.id);
        const label = renderLabel(node.name, url);
        const obj = {
            label,
            name: node.name,
            value: !showCheckbox ? node.id : `${node.id}${children ? '-folder' : ''}`, showCheckbox
        };
        if (children) {
            // include parent page so it can be selected by itself (if not disabled)
            obj.children = (!showCheckbox || (filter && !showPage)) ? [] : [{ label, value: node.id, name: node.name, showCheckbox }]
            obj.children = [...obj.children, ...compact(children.map(recurse))]
            if (isEmpty(obj.children)) delete obj.children;
        }
        if (!showPage && isEmpty(obj.children)) return;
        if (!showPage && !isEmpty(obj.children)) obj.disabled = false
        return obj
    }
    // run recurse
    const includeHome = root?.id === 'home' && showCheckbox && filter(root); // include homepage if required
    const treeNodes = [includeHome && { label: renderLabel(root?.name, getURL(root.id)), value: root?.id, showCheckbox }, ...(root?.children || [])?.map(node => { return recurse(node) })];
    //
    return { pages: compact(treeNodes), count: 0 };
}

export const renderLabel = (name, url) => {
    return (
        <VStack align="start" spacing={0.5}>
            <Text>{getPageName(name)}</Text>
            {url && <Text ml={0.5} fontSize="xs" color="fg.subtle">{url}</Text>}
        </VStack>
    )
}

/*** filtered search ***/
export const mapFilteredTreeData = (treeData, str) => {
    let allChildren = [];
    // filter through depth "1" of pages
    treeData?.forEach(td => {
        let { children } = hierarchiesFilter(td.children, str);
        allChildren = [...allChildren, ...children];
    })
    // filter children
    allChildren = filter(allChildren);
    // filter
    return !isEmpty(allChildren) ? allChildren : [];
}

function hierarchiesFilter(hierarchies, search) {
    if (!hierarchies || !hierarchies.length) return { hierarchies: [], hasExpandedChildren: false };
    var oneIsExpanded = false;
    for (var i = 0; i < hierarchies.length; i++) {
        hierarchies[i].showChildren = false;
        hierarchies[i].matchesSearch = false;
        if (search.length) {
            var rx = new RegExp(search, 'i');
            if (hierarchies?.[i]?.name?.match(rx)) {
                hierarchies[i].matchesSearch = true;
                oneIsExpanded = true;
            }
        }
        // if the node has children which are expanded, we need to display it so its children that
        // should be highlighted are visible
        var hasExpandedChildren = hierarchiesFilter(hierarchies[i].children, search).hasExpandedChildren;
        if (hasExpandedChildren) {
            hierarchies[i].showChildren = true
            oneIsExpanded = true
        }
    }
    return { children: hierarchies, hasExpandedChildren: oneIsExpanded };
};

function filter(arr) {
    var matches = [];
    if (!Array.isArray(arr)) return matches;
    arr.forEach(function (o) {
        if (o.matchesSearch) {
            if (o.children && !o.showChildren) { o._children = o.children; o.children = null; };
            matches.push(o);
        } else {
            if (o._children) { o.children = o._children };
            let childResults = filter(o.children, c => c.matchesSearch || (!c.matchesSearch && c.showChildren));
            if (childResults.length) {
                o.showChildren = true
                matches.push(Object.assign({}, o, { children: filter(childResults) }));
            }
        }
    })
    return matches;
}
/*** filtered search ***/