import {
    Alert,
    AlertIcon,
    Button,
    Link as ChakraLink,
    Flex,
    HStack,
    Heading,
    Image,
    Stack,
    Text,
    VStack,
    useBoolean,
} from '@chakra-ui/react';
import { useGetSitemap, useInUserEditorDoc } from '../../../../../../../../hooks';
import { useMemo, useState } from 'react';

// import { Options } from './Options';
// import { FreeAlert } from '../../FreeAlert';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { BiCamera } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Select } from 'chakra-react-select'
import { getCoversDeviceFromStorage } from '../../../../../canvas/utils/helpers';
import { keys } from 'lodash';
import { useFirebase } from 'react-redux-firebase';
import { useGetPagesForCapture } from '../../../hooks';

const chakraStyles = {
    container: (prev, { selectProps }) => ({
        ...prev,
        width: 340
    }),
    placeholder: (prev, { selectProps }) => ({
        ...prev,
        fontSize: "sm"
    }),
    singleValue: (prev, { selectProps }) => ({
        ...prev,
        fontSize: "sm",
    }),
    input: (prev, { selectProps }) => ({
        ...prev,
        fontSize: "sm",
        h: 30,
    }),
    option: (prev, { selectProps }) => ({
        ...prev,
        fontSize: "sm"
    }),
    noOptionsMessage: (prev, { selectProps }) => ({
        ...prev,
        px: 4,
        py: 2,
        textAlign: 'left',
        fontSize: "sm",
        color: "gray.500"
    })
};

const createOption = (label) => ({
    label,
    value: label,
});

export const Preview = ({ activeStep, options, setOptions, checked }) => {

    const sitemap = useGetSitemap()
    const firebase = useFirebase()

    const pages = useGetPagesForCapture(checked)

    const URLs = useMemo(() => keys(pages)?.map(id => createOption(pages[id].url)), [pages])

    const [selectedURL, setSelectedURL] = useState(URLs[0]?.value);

    const [isLoading, setIsLoading] = useBoolean(false);
    const [previewImage, setPreviewImage] = useState(null);

    const handlePreview = async () => {
        setPreviewImage(null)
        setIsLoading.toggle()
        try {
            const device = getCoversDeviceFromStorage(sitemap?.id);
            const preview = await firebase.functions().httpsCallable('sitemaps-covers-getCoverFromWebsite')({ sitemap: sitemap?.id, ...options, url: selectedURL, device, preview: true });
            if (preview?.data?.image) setPreviewImage(preview.data.image);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading.toggle()
        }
    }

    if (activeStep !== 2) return null;

    return (
        <VStack alignItems="start" spacing={2.5} p={0} pb={3} overflow="scroll" minH="600px">
            {/* inUserSitemap && <FreeAlert />*/}
            <Heading size="sm" fontWeight="semibold">Preview</Heading>
            <VStack alignItems="start" spacing={5} py={2} px={1}>
                {!previewImage && (
                    <HStack justify="space-between" w="full">
                        <Select
                            isDisabled={isLoading}
                            bgColor="rarchy-bg-subtle"
                            borderTopRadius="md"
                            name="colors"
                            options={URLs}
                            placeholder="Select a URL to preview..."
                            noOptionsMessage={() => "We couldn't find any URLs containing this text..."}
                            variant="flushed"
                            chakraStyles={chakraStyles}
                            isClearable={false}
                            onChange={({ value }) => setSelectedURL(value)}
                            defaultValue={URLs[0]}
                        />
                        <Flex>
                            <Button fontSize="sm" variant="outline" leftIcon={<BiCamera />} isDisabled={!selectedURL} isLoading={isLoading} onClick={handlePreview}>Preview</Button>
                        </Flex>
                    </HStack>
                )}
                <Alert status='info' px={4} py={4} variant="info" alignItems="flex-start" fontWeight="normal">
                    <AlertIcon color="gray.500" />
                    <VStack align="start" ml={1}>
                        {!previewImage && (
                            <Text>
                                If this is your first time capturing screenshots for this website, we recommend previewing first to ensure the pages render correctly with your chosen options.
                            </Text>
                        )}
                        {previewImage && (
                            <Text>
                                Not rendering correctly? Press 'Back' to modify your chosen options and try again. If you are having issues, please <ChakraLink as={Link} to="/app/help/ticket" color="rarchy-link" fontWeight="semibold"> submit a ticket</ChakraLink> and we will gladly assist.
                            </Text>
                        )}
                    </VStack>
                </Alert>
                {previewImage && (
                    <Stack align="start" spacing={4}>
                        <Button
                            variant="ghost"
                            fontSize="sm"
                            px={2}
                            pr={4}
                            leftIcon={<ArrowBackIcon />}
                            onClick={() => setPreviewImage(null)}
                        >
                            Back
                        </Button>
                        <Image src={previewImage} />
                    </Stack>
                )}
            </VStack>
        </VStack>
    )
}