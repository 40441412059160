import { getCanvasTextEditor, getInUserFlow, getIsTemplateDrawerOpen, getRevisionHistoryDrawer } from '../../../../../../helpers';
import { getDeviceByPage, getX, getY, shouldShowCovers } from '../../utils/helpers';

import { barWidth } from '../../..';
import { interactionsCanvas } from '../../canvases';
import { store } from '../../../../../../store';
import { togglePageButtons } from '../../../../../../store/actions/sitemap-actions';

export const renderOptions = (d) => {

    const { sitemap, flow, ui } = store.getState();

    const inUserFlow = getInUserFlow();
    const showCovers = shouldShowCovers(d);

    const { NewConnectorLine } = flow?.utils;

    const CanvasTextEditor = getCanvasTextEditor()
    const RevisionHistoryDrawer = getRevisionHistoryDrawer()

    const isTemplateDrawerOpen = getIsTemplateDrawerOpen()
    if (isTemplateDrawerOpen) return;
    
    const { PageButtons } = sitemap?.ui || {};

    const isShowingOptionsForNode = PageButtons?.node?.id === d?.id;

    if (isShowingOptionsForNode && PageButtons.zooming) return;

    if (!PageButtons.showing || (!isShowingOptionsForNode)) {
        if (!CanvasTextEditor.showing || (CanvasTextEditor.showing && CanvasTextEditor.node.id !== d.id)) { // only show if not renaming page
            store.dispatch(togglePageButtons({ showing: true, node: d }));
        } else {
            return;
        }
    }

    if (RevisionHistoryDrawer.showing) return;

    if (showCovers) {
        if (!inUserFlow || (inUserFlow && !NewConnectorLine.showing)) {
            // add cover
            interactionsCanvas.globalAlpha = 0.98;
            interactionsCanvas.fillStyle = ui.colorMode === 'light' ? 'white' : '#1A202C';
            const device = getDeviceByPage(d)
            roundRect(interactionsCanvas, getX(d) + 1.1, getY(d) + 1, barWidth - 2, (d.nodeHeight - d.textRectHeight), device === 'desktop' ? 5 : 15) // device === 'desktop' ? 5 : 15)
            interactionsCanvas.globalAlpha = 1;
            /*** cover buttons only ***/
        }
    };

};

const roundRect = (ctx, x, y, width, height, radius) => {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, 0); // radius for full
    ctx.arcTo(x, y + height, x, y, 0); // radius for full
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.fill();
};