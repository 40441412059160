import { addFlowChange, hideFlowsOptionsPopover, mergeFlowEdits, setUserFlowSymbolsAndConnectors, showFlowsOptionsPopover } from "../../../store/actions/flow-actions";
import { compact, isEmpty, isEqual, isNumber, keys } from 'lodash'
import { getNodeCoords, updateNodesHeights } from "../app/views";
import { getRevisionHistoryDrawer, getSitemap, getUserFlow } from "../../../helpers";

import { chain } from "../../../helpers/chain";
import copy from 'fast-copy';
import { event } from 'd3';
import { getNameForUserFlowPage } from '../app/canvas/components/text';
import { setSectionAttrs } from '../app/canvas/components/page-sections';
import { store } from '../../../store';

export const update = () => {

    const sitemap = getSitemap()
    const flow = getUserFlow()

    // rev
    const RevisionHistoryDrawer = getRevisionHistoryDrawer()
    // get flow
    if (!flow || !flow.loaded) return;
    //
    const elementsToUse = !RevisionHistoryDrawer.showing ? flow?.docs?.elements : RevisionHistoryDrawer?.docs?.elements;
    //
    var mappedData = compact(chain({ ...elementsToUse }).map((obj, id) => {
        const isSymbol = !obj?.type?.startsWith('page');
        // don't return if don't have x/y
        if (isNumber(obj.x) && isNumber(obj.y)) return {
            id,
            ...obj,
            connectors: obj.connectors || {},
            y: isSymbol ? obj.y + (215 / 2) : obj.y // legacy fix
        }
    }).value());
    // page symbols
    var pageSymbols = mappedData.filter(d => d?.type?.includes('page')).map(d => {
        d.name = getNameForUserFlowPage(d);
        return d;
    });
    // update page symbol heights
    pageSymbols = updateNodesHeights(pageSymbols);
    // set section attrs
    pageSymbols = setSectionAttrs({ nodes: pageSymbols });
    // other symbols
    var otherSymbols = mappedData.filter(d => !d?.type?.includes('page')).map(d => {
        d.nodeHeight = 90;
        const { topOfNode, bottomOfNode, leftOfNode, rightOfNode } = getNodeCoords(d, sitemap)
        d.topOfNode = topOfNode
        d.bottomOfNode = bottomOfNode
        d.leftOfNode = leftOfNode
        d.rightOfNode = rightOfNode // this is coord of right of node, NOT distance from x to right of node like in sitemap pages
        return d
    })
    // nodes
    const symbols = [...pageSymbols, ...otherSymbols]

    /*** searched ***/
    // const searched = flow?.data?.nodes?.find(f => f.searched);
    // if (searched) symbols.map(s => { if (searched.id === s.id) s.searched = true; return s })
    /*** searched ***/

    // links
    const connectors = [];
    symbols.forEach(n => {
        if (!n) return n;
        const { connectors: connectorsObj } = n;
        if (connectorsObj) {
            const positions = Object.keys(connectorsObj);
            positions.forEach(position => {
                const data = connectorsObj[position];
                if (isEmpty(data)) return;
                const ids = keys(data);
                ids.forEach(id => {
                    var link = data[id];
                    if (isEmpty(link)) return;
                    var obj = {
                        start: { id: n.id, section: link.startSection, position },
                        end: { id: id, section: link.endSection, position: link.position },
                        color: link.color ? link.color : undefined,
                        type: link.type ? link.type : 'solid',
                    };
                    if (link.index) obj.index = link.index;
                    if (link.icon) obj.icon = link.icon;
                    connectors.push(obj);
                });
            });
        }
    });
    // set nodes & links
    if (!isEqual(symbols, flow.data?.nodes) || !isEqual(connectors, flow.data?.links)) {
        store.dispatch(setUserFlowSymbolsAndConnectors({ nodes: symbols, links: connectors }));
    }
};

export const addSymbolChange = ({ data, oldData }) => {
    // dispatch edit so we can render to user immediately
    store.dispatch(mergeFlowEdits({ elements: { ...data } }))
    // store.dispatch(resetNodeHeights(Object.keys(data), { update: true })); // best practice - particularly for when switching between linked pages (not sure we need this anymore)
    /*** save ***/
    if (!oldData) return; // only save here if oldData supplied for history
    const obj = []; keys(data).forEach(id => obj.push({ action: 'symbol-change', id, ...data[id] }));
    const change = { id: new Date().getTime(), data: obj };
    const history = []; keys(oldData).forEach(id => history.push({
        action: 'symbol-change',
        node: id,
        data: {
            oldSymbol: oldData[id],
            newSymbol: data[id]
        },
    }));
    setTimeout(() => { store.dispatch(addFlowChange({ change, history })); }, 500);
    /*** save ***/
}

export const addConnectorChange = ({ data, oldData }) => {
    // dispatch edit so we can render to user immediately
    store.dispatch(mergeFlowEdits({ elements: { ...data } }))
    /*** save ***/
    if (!oldData) return; // only save here if oldData supplied for history
    const obj = []; keys(data).forEach(id => obj.push({ action: 'connector-change', id, ...data[id] }));
    const change = { id: new Date().getTime(), data: obj };
    const history = []; keys(oldData).forEach(id => history.push({
        action: 'connector-change',
        node: id,
        data: {
            oldConnector: oldData[id],
            newConnector: data[id]
        },
    }));
    setTimeout(() => { store.dispatch(addFlowChange({ change, history })); }, 500);
    /*** save ***/
}

export const showOptionsOnClick = ({ nodes, symbolOptions, links, connectorOptions, x, y }) => {
    if (event) event.stopPropagation();
    store.dispatch(
        showFlowsOptionsPopover({
            nodes,
            links,
            offset: [x, y],
            symbolOptions,
            connectorOptions,
            // iconOptions
        })
    );
};

export const setSelectedUserFlowSymbols = (nodeIds) => {
    const { flow } = store.getState();
    const nodes = copy(flow.data.nodes).map(d => {
        delete d._selected;
        if (nodeIds.includes(d.id)) d._selected = true;
        return d;
    })
    if (!isEqual(nodes, flow.data.nodes)) {
        store.dispatch(setUserFlowSymbolsAndConnectors({ nodes }))
        if (!isEmpty(nodeIds)) {
            showOptionsOnClick({ nodes: nodes.filter(d => d._selected), symbolOptions: true, x: window.innerWidth / 2, y: window.innerHeight - 72 });
        } else {
            store.dispatch(hideFlowsOptionsPopover());
        }
    }
}