import { useDispatch } from "react-redux"
import { Box, Button, Flex, HStack, Stack, Text, useBoolean } from "@chakra-ui/react"
import { LinkIcon } from "@chakra-ui/icons"
import { MidHeader } from "../../../Settings/Components"
import { toggleShareFileModal } from "../../../../store/actions/files-actions"
import { getCopiedURL } from "../../../Components/Shared/CopyLinkMenuItem"
import copy from 'copy-to-clipboard';
import { useGetShareFileModal } from "../../../../hooks"
import { ShareFileContext } from "./ShareFileHOC"
import { useContext } from "react"
import { isEmpty } from 'lodash'

export const General = () => {
    const { file, peopleToInvite } = useContext(ShareFileContext)
    const [copied, setCopied] = useBoolean(false)
    const URL = getCopiedURL({ file })
    if (!isEmpty(peopleToInvite)) return null
    return (
        <Stack px={7}>
            <MidHeader title="Share a view-only link" />
            <HStack justify="space-between">
                <Text fontSize="13px" w="full" color="fg.muted">Anyone on the internet with the link has view-only access</Text>
                <Flex>
                    <Button
                        variant="outline"
                        fontSize="sm"
                        fontWeight="medium"
                        color="rarchy-link"
                        // isLoading={copied}
                        leftIcon={<LinkIcon />}
                        onClick={() => {
                            copy(URL)
                            setCopied.on()
                            setTimeout(() => setCopied.off(), 2000)
                        }}
                    >
                        {!copied ? 'Copy link' : 'Copied!'}
                    </Button>
                </Flex>
                {/* <Button
                    px={6}
                    colorScheme="blue"
                    fontSize="sm"
                    onClick={() => {
                        dispatch(toggleShareFileModal())
                    }}
                >
                    Done
                </Button> */}
            </HStack>
        </Stack>
    )
}