import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
    Box,
    Menu,
    Button,
    PopoverTrigger,
    MenuList,
    MenuItem,
    Popover,
    Portal,
    PopoverContent,
} from "@chakra-ui/react"

// // import amplitude from 'amplitude-js/amplitude';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useCanEditFile, useGetEditor, useGetUserFlow, useInUserFlow } from '../../../../hooks';
import { toggleExportPDFDrawer } from '../../../../store/actions/sitemap-actions';
import { FiDownloadCloud } from 'react-icons/fi';

import { exportSitemap, exportCSV, exportSVG } from './utils'
import { exportThumb } from './utils/thumb'
import { showEditorPermissionsToast } from '../../App/Components/PermissionsToast';
import { EditorSkeletonWrapper } from '..';
import { sendHubspotCustomEvent } from '../../../../helpers';

export const Export = () => {

    const dispatch = useDispatch()
    const editor = useGetEditor()
    const inUserFlow = useInUserFlow()
    
    const canEdit = useCanEditFile()
    if (!canEdit) return null

    const { name } = editor

    const HandleExport = async (key) => {
        const canEdit = showEditorPermissionsToast({ canFullyEdit: true }); if (!canEdit) return;
        switch (key) {
            case 'thumbnail':
                await exportThumb(name); break;
            case 'pdf':
                dispatch(toggleExportPDFDrawer({ showing: true }));
                // amplitude.getInstance().logEvent('EXPORTED_SITEMAP_TO_PDF');
                break;
            case 'svg':
                await exportSVG(name); // amplitude.getInstance().logEvent('EXPORTED_SITEMAP_TO_SVG');
                break;
            case 'xml':
                exportSitemap(name); // amplitude.getInstance().logEvent('EXPORTED_SITEMAP_TO_XML');
                break;
            case 'csv':
                exportCSV(name); // amplitude.getInstance().logEvent('EXPORTED_SITEMAP_TO_CSV');
                break;
        }
        // send event to Hubspot
        if (key !== 'thumbnail') sendHubspotCustomEvent('exported_sitemap', { export_type: key })
    }

    return (
        <EditorSkeletonWrapper>
            <Popover placement="bottom-start" isLazy>
                {({ onClose }) => (
                    <>
                        <PopoverTrigger>
                            <Box cursor="pointer" bgColor="rarchy-bg-sitemap-editor" rounded="md">
                                <Button
                                    variant="outline"
                                    size="sm"
                                    iconSpacing={3}
                                    leftIcon={<FiDownloadCloud />}
                                    rightIcon={<ChevronDownIcon fontSize="xl" ml={-1} />}
                                    color="rarchy-color-sitemap-editor"
                                    borderColor="rarchy-border-sitemap-editor"
                                    // borderWidth={0}
                                    bgColor="rarchy-bg-sitemap-editor"
                                >
                                    Export
                                </Button>
                            </Box>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent border="none" boxShadow="sm">
                                <Menu defaultIsOpen autoSelect={false}>
                                    <MenuList fontSize="sm" variant="noShadow" minW="150px" p={2} py={2} borderRadius="md">
                                        {import.meta.env.DEV && <MenuItem onClick={() => HandleExport("thumbnail")}>Export to Thumb</MenuItem>}
                                        <MenuItem borderRadius="md" py={2} onClick={() => HandleExport("svg")}>Export to SVG</MenuItem>
                                        <MenuItem borderRadius="md" py={2} onClick={() => HandleExport("pdf")}>Export to PDF</MenuItem>
                                        {!inUserFlow && <MenuItem borderRadius="md" py={2} onClick={() => HandleExport("xml")} >Export to XML</MenuItem>}
                                        {!inUserFlow && <MenuItem borderRadius="md" py={2} onClick={() => HandleExport("csv")} >Export to CSV</MenuItem>}
                                    </MenuList>
                                </Menu>
                            </PopoverContent>
                        </Portal>
                    </>
                )}
            </Popover>
        </EditorSkeletonWrapper>
    )
}