import { useDispatch } from 'react-redux';
import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Stack,
    Text
} from '@chakra-ui/react'
import { GoogleIcon } from '../Components/ProviderIcons'

import { Formik } from 'formik'
import { signInWithGoogle, LogInWithEmailAndPassword } from "../../../../store/actions/auth-actions"

import { Link } from 'react-router-dom'
import { UnderlineLink } from '../Components/UnderlineLink'
import { SetTitle, useGetRedirectUserQueryParams, useIsSigningUpOrLoggingIn } from '../../../../hooks';
import { AuthContainer } from '..';
import { PasswordField } from '../Components/Password';

export const Login = () => {
    const dispatch = useDispatch()
    SetTitle('Log in to your Rarchy account')
    const { email } = useGetRedirectUserQueryParams()
    const isLoggingIn = useIsSigningUpOrLoggingIn()
    return (
        <AuthContainer type="login">
            <Stack spacing={6} w="full">
                <Formik
                    initialValues={{ email: email || '', password: '' }}
                    onSubmit={(values, actions) => {
                        dispatch(LogInWithEmailAndPassword(values));
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={6}>
                                <FormControl id="email">
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Input
                                        autoComplete="off"
                                        name="email"
                                        defaultValue={email || null}
                                        type="email"
                                        isRequired
                                        placeholder="Enter your email"
                                        bgColor="rarchy-bg-white"
                                        fontSize="md"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormControl>
                                <PasswordField forLogin errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
                                <HStack justify="end">
                                    <Link to="/login/reset">
                                        <UnderlineLink ml={2} fontSize="sm">Forgot Password?</UnderlineLink>
                                    </Link>
                                </HStack>
                                <Button
                                    colorScheme="blue"
                                    fontSize="sm"
                                    type="submit"
                                    iconSpacing={3.5}
                                    loadingText="Logging in..."
                                    isLoading={isLoggingIn}
                                >Log in</Button>
                            </Stack>
                        </form>
                    )}
                </Formik>
                <Stack w="full" spacing="5">
                    <HStack>
                        <Divider />
                        <Text fontSize="sm" whiteSpace="nowrap" color="fg.muted">
                            or continue with
                        </Text>
                        <Divider />
                    </HStack>
                    <Button
                        variant="outline"
                        fontSize="sm"
                        fontWeight="medium"
                        borderColor="rarchy-border"
                        _hover={{ bgColor: "rarchy-bg-subtle" }}
                        leftIcon={<GoogleIcon />}
                        onClick={() => dispatch(signInWithGoogle())}
                    >
                        Log in with Google
                    </Button>
                </Stack>
            </Stack>
        </AuthContainer>
    )
}