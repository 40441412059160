import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { redirectUser } from './store/actions/auth-actions';
import { getEditorDocIdFromPath } from './helpers';
import { useGetLocation } from './hooks';

export const publicPages = ['/app/login', '/app/login/reset', '/app/signup', '/app/logout', '/app/verify', '/app/invitations'];

export const useIsPublicPage = () => {
  const location = useGetLocation();
  if (publicPages.find(path => location.pathname.startsWith(path))) return true;
  return false;
}

class PrivateRoute extends Component {
  componentDidMount() {
    /*** store intended url for user ***/
    if (!this.props.auth.redirectUser && this.props.location) {
      const currentPath = `${this.props.location.pathname}${this.props.location.search}`;
      let redirect = false;
      publicPages.forEach(p => {
        if (currentPath.startsWith(p)) redirect = true;
      })
      if (!redirect) this.props.redirectUser(currentPath);
    }
    /*** store intended url for user ***/
  }
  render() {
    const { organization, inEditor, isNotAuth } = this.props;
    if (organization?.subscription) {
      if (organization?.subscription?.status !== "active" && organization?.subscription?.status !== "trialing") {
        const path = `${this.props.location.pathname}`;
        /*** customer that hasn't paid ***/
        if (!path.includes('/settings') && !path?.includes('/help/ticket')) {
          return <Redirect to="/app/settings/billing-and-plans" />
        }
        /*** customer that hasn't paid ***/
      }
    }
    const { pathname, search } = this.props.location;
    if (pathname.includes('/signup') || pathname.includes('/verify') || pathname.includes('/login/reset')) return null; // don't return anything for these pages
    if (inEditor) {
      const editorId = getEditorDocIdFromPath()
      if (editorId.length === 20 || editorId.length === 28) return this.props.children;
    }
    const { redirectUser } = this.props.auth;
    return isNotAuth ?
      <Redirect to={{ pathname: pathname?.includes('invitations') || search?.includes('?url=') ? '/app/signup' : '/app/login', state: { from: redirectUser || '/app' } }} />
      :
      this.props.children
  }
}

function mapStateToProps(state) {
  const { auth } = state.firebase;
  return {
    ...state,
    isNotAuth: auth.isLoaded && auth.isEmpty
  };
}

export default withRouter(connect(mapStateToProps, { redirectUser })(PrivateRoute));
