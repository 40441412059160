import React from 'react'

import {
    Text,
} from "@chakra-ui/react"
import Linkify from 'react-linkify'

export const Comment = ({ comment }) => {
    const hasComment = comment?.comment?.string; 
    const hasStatus = comment?.status;
    return (
        <>
            {hasStatus && (<Text mt={0} mb={hasComment ? 2 : 0} fontSize="xs" fontStyle="italic">{getStatusText(comment.status)}</Text>)}
            <Text fontSize="sm" py={0.5}>
                <Linkify properties={{ target: '_blank' }}>{comment?.comment?.rendered}</Linkify>
            </Text>
        </>
    )
}

const getStatusText = (status) => {
    if (status === 'resolved') return 'Marked as resolved';
    if (status === 're-opened') return 'Re-opened';
}