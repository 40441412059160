import { Box, Button, useDisclosure } from "@chakra-ui/react"
import { Folders } from "../../Folders"

import { useHasFreeUserNotCreatedFileYet, useHasLoadedFiles, useInHomeScreen, useInOrganization, useInProjectsGridView, useIsFilesEmpty, useIsViewingFolder } from "../../../../hooks"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Files } from "../../../Files"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"
import { Templates } from "../../../Templates"

export const Section = ({ section }) => {

    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })

    const inGridView = useInProjectsGridView()

    const isViewingFolder = useIsViewingFolder()

    const inHomeScreen = useInHomeScreen()

    const isRecent = section.id === 'all'
    const isFolders = section.id === 'folders'

    const hasFreeUserNotCreatedFile = useHasFreeUserNotCreatedFileYet()
    const isFilesEmpty = useIsFilesEmpty()
    const hasLoadedFiles = useHasLoadedFiles()

    const showRecent = isRecent && !isViewingFolder /* && inHomeScreen */ && (!isFilesEmpty || (isFilesEmpty && !hasLoadedFiles))

    const showTemplatesRow = inHomeScreen && hasLoadedFiles && (isRecent && hasFreeUserNotCreatedFile)

    const showFolder = !inHomeScreen && (isFolders && inGridView)

    const showTitle = ((!inGridView && showRecent) || (inGridView && (!isRecent || (isRecent && showRecent)) && (!isFolders || (showFolder))))

    return (
        <Box key={section?.id} display={(showTitle || showTemplatesRow) ? "block" : "none"}>
            <SkeletonWrapper w="fit-content">
                <Button
                    display={showTitle ? "flex" : "none"}
                    mt={isRecent || (isViewingFolder && isFolders) ? 5 : 0}
                    pl={0.5}
                    size="md"
                    fontSize="xl"
                    variant={isRecent ? "unstyled" : "ghost"}
                    _hover={{ cursor: isRecent ? "auto" : "pointer" }}
                    onClick={() => { if (!isRecent) onToggle() }}
                    leftIcon={isRecent ? null : <ChevronRightIcon fontSize="xl" transitionDuration="100ms" transform={`rotate(${isOpen ? 90 : 0}deg)`} />}
                >
                    {section.title === 'All' ? "Recent" : section.title}
                </Button>
            </SkeletonWrapper>
            <Box pt={isRecent || !isOpen ? 2.5 : 3} pb={isRecent ? (inGridView ? 10 : 5) : !isOpen ? 1.5 : 9}>
                {showRecent && <Files forRecent />}
                {showTemplatesRow && <Box mt={showRecent ? 6 : 0}><Templates /></Box>}
                {inGridView && isOpen && (
                    <Box>
                        <>
                            {showFolder && <Folders />}
                            <Files collection={section?.id} />
                        </>
                    </Box>
                )}
            </Box>
        </Box>
    )
}