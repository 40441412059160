import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
    Modal,
    ModalContent,
    useColorModeValue as mode
} from "@chakra-ui/react"


import { retrieveRevisionHistoryDrawerChanges } from '../../../../../../../../store/actions/editor-actions'
import { Header } from './Components/Header'
import { App } from './Components/App'
import { List } from './Components/List'

export const RevisionHistoryDrawer = () => {
    RetrieveChangesOnMount()
    return (
        <Modal isOpen motionPreset="none" size="full">
            <ModalContent overflow="hidden" bgColor={mode("white", "gray.800")}>
                <Header />
                <App />
                <List />
            </ModalContent>
        </Modal>
    )
}

const RetrieveChangesOnMount = () => {
    const dispatch = useDispatch()
    useEffect(() => dispatch(retrieveRevisionHistoryDrawerChanges()), [])
    return;
}