import { store } from '../../../../../store';
import { mouseoverConnector, hideFlowsOptionsPopover } from '../../../../../store/actions/flow-actions';

import { addConnectorChange } from '../../helpers';

export const handleDeleteUserFlowConnector = (link) => {
    // need to fix to handle delete page-section links
    const { flow } = store.getState();
    /*** save ***/
    const data = { [link.start.id]: { connectors: { [link.start.position]: { [link.end.id]: undefined } } } };
    const oldData = {
        [link.start.id]: {
            connectors: {
                [link.start.position]: {
                    [link.end.id]: { position: link.end.position, type: link.type, color: link.color, index: link.index, endSection: link.end.section, startSection: link.start.section /*, icon: node.icon*/ },
                }
            }
        }
    }
    addConnectorChange({ data, oldData })
    /*** save ***/
    store.dispatch(mouseoverConnector())
    if (flow.ui.OptionsPopover.showing) store.dispatch(hideFlowsOptionsPopover());
}