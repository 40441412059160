import { useGetLocation, useInEditor, useInHelp, useInHomeScreen, useInProjects } from "../../hooks";
import { HomeMenu } from "./Screens/Home"
import { ProjectsMenu } from "./Screens/Projects"
import { HelpMenu } from "./Screens/Help";

export const Default = () => {
    const inEditor = useInEditor()
    const { pathname } = useGetLocation()
    const inHome = useInHomeScreen()
    const inPricing = pathname?.includes("/app/pricing")
    const inProjects = useInProjects()
    const inHelp = useInHelp()
    return (
        <>
            {(inEditor || inHome || inPricing) && <HomeMenu />}
            {inProjects && <ProjectsMenu />}
            {inHelp && <HelpMenu />}
        </>
    )
}