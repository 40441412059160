import { store } from '../../../../../store';
import { nodes } from '../render';
import { interactionsCanvas, pickerCanvas } from '../canvases';
import { renderNodeRect, renderCover, renderText, renderHeader } from '.';
import { renderPageSections, setSectionAttrs } from './page-sections';
import { renderUserFlowSymbol } from '../../../user-flows/components/nodes';

import { spacing as treeSpacing } from '../../views/spacing/tree/index'
import { spacing as treeVerticalSpacing } from '../../views/spacing/tree/vertical'
import { spacing as treeVerticalMatrixSpacing } from '../../views/spacing/tree/vertical-matrix'
import { spacing as indentSpacing } from '../../views/spacing/indent'
import { getPageId, shouldShowCovers } from '../utils/helpers';
import { getInUserFlow, getSitemap } from '../../../../../helpers';

import { partition, sortBy, groupBy, isEmpty } from 'lodash'

export const NODE_WIDTH = 225;

export const renderDrag = (dragging) => {

    if (dragging.section) {
        renderPageSections(interactionsCanvas, dragging);
        return;
    };

    renderDraggingNode(interactionsCanvas, dragging);

    const inUserFlow = getInUserFlow()
    if (!inUserFlow && dragging) renderDraggingSpacing(nodes, dragging);
};

const renderDraggingSpacing = (nodes, dragging) => {

    const sitemap = getSitemap()

    var filteredNodes = nodes.filter(d => d.id !== dragging.id && !d.hideWhileDragging && d.id !== 'dragging-placeholder');

    const mainNodesOrWebsiteSections = partition(filteredNodes, (n) => !n.website_section);

    // main nodes
    let mainNodes = mainNodesOrWebsiteSections[0];
    mainNodes = sortBy(mainNodes, ['depth', 'x']);
    createSpacing(mainNodes);

    // website sections
    let website_sections = groupBy(mainNodesOrWebsiteSections[1], 'website_section');
    const websiteSectionIds = Object.keys(website_sections);
    websiteSectionIds.forEach((sectionId) => {
        // filter out any homepages that have been inserted previously (fail-safe)
        let nodes = sortBy(website_sections[sectionId].filter(s => s.parent), ['depth', 'x']);
        createSpacing(nodes);
    });

    function createSpacing(nodes) {
        switch (sitemap?.format) {
            case 'tree':
                treeSpacing(pickerCanvas, nodes);
                break;
            case 'tree-vertical':
                treeVerticalSpacing(pickerCanvas, nodes);
                break;
            case 'tree-vertical-matrix':
                treeVerticalMatrixSpacing(pickerCanvas, nodes);
                break;
            case 'indent':
                indentSpacing(pickerCanvas, nodes);
                break;
            default:
                break;
        }
    }

}

const renderDraggingNode = (context, dragging) => {

    const { sitemap, flow } = store.getState();
    const inUserFlow = getInUserFlow()
    // dragging page
    if (!inUserFlow) {
        renderDraggingPage(context, dragging, sitemap, flow);
    } else {
        // dragging user-flow symbol
        renderUserFlowSymbol({ context, opts: {}, d: dragging, x: dragging.x, y: dragging.y });
        [...flow.data.nodes.filter(d => (d._selected && d?.type?.includes('page') && d.id !== dragging.id))].forEach(d => {
            renderDraggingPage(context, d, sitemap, flow);
        })
        if (dragging.type.includes('page')) renderDraggingPage(context, dragging, sitemap, flow);
    }
}

const renderDraggingPage = (context, dragging, sitemap, flow) => {

    const inUserFlow = getInUserFlow()
    const showCovers = shouldShowCovers(dragging);

    if (!inUserFlow) {
        if (dragging.over) context.globalAlpha = 0.95;
    }

    renderNodeRect(context, dragging, false);

    /*** covers ***/
    if (showCovers) renderCover(context, dragging, true /* visible */);
    /*** covers ***/

    /*** sections ***/
    if (!showCovers) {
        const draggingId = getPageId(dragging);
        const hasSections = !isEmpty(sitemap?.data.page_sections[draggingId]);
        setSectionAttrs({ nodes: hasSections ? !inUserFlow ? [...sitemap?.data?.nodes, dragging] : [...flow?.data?.nodes?.filter(d => d._selected), dragging] : sitemap?.data.nodes }); // update dragging attrs
        renderPageSections(context, dragging, false);
    }
    /*** sections ***/

    renderHeader(context, dragging, false);
    renderText(context, dragging, false);

    context.globalAlpha = 1; // reset global

}