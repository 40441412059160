import { store } from '../../../../../../store';

import { render as renderPages } from '../../render';

import { transform } from '../zoom';

import { sectionWidth, getPageSectionHeights, setSectionAttrs } from '../../components/page-sections';
import { resetNodeHeights, updatePageSectionsData, addPageSectionChange, togglePageSectionsOptionsPopover } from '../../../../../../store/actions/sitemap-actions';

import { sendDataLayerEventForPageSections } from '../../components/page-sections/options';

import { getIsNavbarHidden, getSitemap } from '../../../../../../helpers';
import $ from 'jquery';
import { event, select } from 'd3';
import { trim } from 'lodash'

var node, section = {};
var input = select('#rename-page-section');

export const renamePageSectionBlock = ({ node, section }) => {
    //
    init(node, section);
    // assign page name to input
    setTimeout(() => {
        // focus cursor at end of input
        $('#rename-page-section').val(section.title).focus();
        // render
        renderPages();
    }, 50);
    //
    const { x, y } = getRenameCanvasBoundingClientRect(section);
    const isNavbarHidden = getIsNavbarHidden()
    //
    input
        .attr('spellcheck', false)
        .attr('maxlength', 100)
        .style('left', () => {
            if (node.x < 0) return `${Math.ceil(x)}px`;
            if (node.x > 0) return `${Math.floor(x)}px`;
            return `${x}px`;
        })
        .style('padding-left', () => `${8 * transform.k}px`)
        .style('padding-top', () => `${6 * transform.k}px`)
        .style('top', () => {
            const offset = isNavbarHidden ? 40 : (62 + 40);
            return `${y + offset}px`;
        })
        .style('width', `${sectionWidth * transform.k}px`)
        .style('height', `${section.rectHeight * transform.k}px`)
        .style('font-size', `${15 * transform.k}px`)
        .style('line-height', () => {
            return `${Math.round(17 * transform.k)}px`;
        })
        .style('color', section.tColor)
        .style('text-align', 'left')
        .on('input', (e) => {
            section.renaming = event.target.value;
            const { textHeight } = getPageSectionHeights(section);
            if (textHeight !== section.textHeight) {
                // reset section attrs for renaming node
                setSectionAttrs({ nodes: [node] });
                // reset node heights (with update)
                store.dispatch(resetNodeHeights([node.id], { update: true }));
                /*** update input styles ***/
                const { x, y } = getRenameCanvasBoundingClientRect(section);
                input.style('left', () => {
                    if (node.x < 0) return `${Math.ceil(x)}px`;
                    if (node.x > 0) return `${Math.floor(x)}px`;
                    return `${Math.round(x)}px`;
                });
                input.style('top', () => {
                    const offset = isNavbarHidden ? 40 : (62 + 40);
                    return `${y + offset}px`;
                });
                input.style('height', `${section.rectHeight * transform.k}px`);
                /*** update input styles ***/
            }
        })
        .classed('hidden', false);
};

const getRenameCanvasBoundingClientRect = (s) => {
    const x = transform.applyX(s.x);
    const y = transform.applyY(s.y);
    return { x, y };
};

const init = (d, s) => {
    // init if rename input doesn't exist
    if (input.empty()) input = select('body').append('textarea').attr('id', 'rename-page-section');
    // reset if input is still visible while trying to rename another page
    if (!input.classed('hidden')) end();
    // this has to be last
    node = d;
    section = s;
};

export const end = async () => {
    // return if input is not showing;
    if (input.empty() || input.classed('hidden')) return false;
    // get input value
    const newTitle = $('#rename-page-section').val();
    const oldTitle = section.title;
    // change page name
    var changed = changeName(oldTitle, newTitle);
    // reset input & page
    reset();
    // update redux changes object if changed
    if (changed) {
        /*** render immediately ***/
        const sitemap = getSitemap();
        var sections = { ...sitemap?.data.page_sections };
        sections[node.id].map(s => {
            if (s.id.toString() === section.id.toString()) s.title = newTitle;
            return s;
        });
        // update sections data in redux
        store.dispatch(updatePageSectionsData(sections));
        /*** render immediately ***/

        const change = {
            id: new Date().getTime(),
            data: [{ action: 'page-section-rename', pageId: node.id, section: { id: section.id, title: newTitle } }],
        };
        const history = {
            action: 'page-section-rename',
            data: { pageId: node.id, section, newTitle, oldTitle }
        };

        store.dispatch(addPageSectionChange({ change, history }));

        sendDataLayerEventForPageSections(`Rename`, newTitle);
    };
    // reset node + section;
    node = {};
    section = {};
};

export const changeName = (oldTitle, newTitle) => {
    newTitle = trim(newTitle);
    // return false if name hasn't changed
    if (oldTitle === newTitle || !newTitle) return false;
    return true;
};

const reset = () => {
    // reset input
    if (!input.empty() && !input.classed('hidden')) {
        input.attr('class', 'hidden');
        $('#rename-page-section').val('');
    }
    // reset page
    section.renaming = false;
    // re-render
    renderPages();
};

// Updates when enter key has been pressed
$('body').on('keydown', '#rename-page-section', e => {
    if (e.which === 13) {
        e.preventDefault();
        end();
        store.dispatch(togglePageSectionsOptionsPopover({ showing: false }));
    }
});

// updates on click within rename input
$('body').on('click', '#rename-page-section', e => {
    e.preventDefault();
    e.stopPropagation();
});

// Updates on any click
$('body').on('click', e => { end(); });