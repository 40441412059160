import { ArrowDownIcon } from '@chakra-ui/icons'
import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from '@chakra-ui/react'

export const ExportMenu = ({ data }) => {
    /* // put this live later
    const hasLoadedPages = data?.pages?.loaded
    if (!hasLoadedPages) return null;
    return (
        <Menu placement="bottom-end">
            <MenuButton as={Button} fontSize="sm" variant="ghost" iconSpacing={2.5} px={3} rightIcon={<ArrowDownIcon />}>
                Export
            </MenuButton>
            <MenuList fontSize="sm" variant="noShadow" minW="150px" p={2} py={2}>
                <MenuItem borderRadius="sm">Export to XML</MenuItem>
                <MenuItem borderRadius="sm">Export to CSV</MenuItem>
            </MenuList>
        </Menu>
    ) */
}