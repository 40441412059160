import {
  Box,
  Flex,
  HStack,
  Skeleton,
  Stack,
  useColorMode
} from "@chakra-ui/react"
import { useGetRevisionHistoryDrawer, useHasEditorLoaded, useInUserFlow } from '../../../hooks';

import { Capture } from './Capture'
import { Colors } from './Colors'
import { Devices } from './Devices'
import { Export } from './Export'
import { Formats } from './Formats'
import { Help } from './Help'
import { LinkedSitemap } from './User-Flows/Linked';
import React from 'react';
import { Subfolders } from './Subfolders'
import { Toggle } from './Toggle'
import { UndoRedo } from './UndoRedo'
import { Connector as UserFlowsConnector } from './User-Flows/Connector'
import { Grid as UserFlowsGrid } from './User-Flows/Grid'
import { Views } from './Views'
import { getIsNavbarHidden } from '../../../helpers';

export const Toolbar = () => {
  const inUserFlow = useInUserFlow()
  const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()
  const { colorMode } = useColorMode()
  const isNavbarHidden = getIsNavbarHidden()
  const NotInRevHistory = !RevisionHistoryDrawer.showing
  const isDark = colorMode === 'dark'
  const boxShadow = isDark ? null : "xs"
  return (
    <Box
      zIndex={2}
      w="full"
      px={5}
      py={isNavbarHidden ? 2 : 2.5}
      pt={isNavbarHidden ? 2 : 0}
      boxShadow={boxShadow}
      borderBottom={isDark ? "1px" : null}
      bgColor="rarchy-bg-sitemap-editor"
      borderColor="rarchy-border-sitemap-editor"
    >
      <Flex h="100%">
        <Stack w="full" direction="row" justifyContent={NotInRevHistory ? "space-between" : "left"}>
          <HStack spacing={4}>
            <UndoRedo />
            {!inUserFlow && (
              <>
                <Formats />
                <Views />
              </>
            )}
            {inUserFlow && NotInRevHistory && (
              <>
                <LinkedSitemap />
                <Devices />
              </>
            )}
            {!inUserFlow && <Devices />}
            {!inUserFlow && NotInRevHistory && (
              <>
                <Capture />
                <Colors />
                <Subfolders location="toolbar" />
              </>
            )}
            {inUserFlow && NotInRevHistory && (
              <>
                <UserFlowsGrid />
                <UserFlowsConnector />
              </>
            )}
            {NotInRevHistory && <Export inUserFlow={inUserFlow} />}
          </HStack>
          {NotInRevHistory && (
            <>
              <Stack direction="row">
                <Help />
                <Toggle />
              </Stack>
            </>
          )}
        </Stack>
      </Flex>
    </Box>
  )
}

export const EditorSkeletonWrapper = ({ children }) => {
  const hasEditorLoaded = useHasEditorLoaded()
  if (!children) return null
  return <Skeleton isLoaded={hasEditorLoaded} rounded="md">{children}</Skeleton>
}