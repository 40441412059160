import { createDraggingPlaceholderNodeData } from '../helpers';
import { sortBy, find } from 'lodash'

export const insertPlaceholderForIndent = (nodesWithPlaceholder, { node, side }) => {

    var newPlaceholderNode = createDraggingPlaceholderNodeData(node, side);
    
    newPlaceholderNode.index = node.index;

    // eslint-disable-next-line
    var parentsChildNodes = nodesWithPlaceholder.filter(d => {
        if (d.parent && node.parent) {
            if (d.parent.id === node.parent.id) {
                return d;
            }
        }
    });

    if (side === 'below') {
        if (node.children) { // insert as child
            newPlaceholderNode.parent = nodesWithPlaceholder.find(d => d.id === node.id);
            newPlaceholderNode.index = 0;
        } else {
            parentsChildNodes.push(newPlaceholderNode);
        }
    };

    parentsChildNodes = sortBy(parentsChildNodes, ['index']);

    parentsChildNodes.forEach((d, i) => { d.index = i }); // sort nodes by index

    nodesWithPlaceholder.push(newPlaceholderNode);

    /* update indexes in nodes with placeholder to return */
    nodesWithPlaceholder.forEach(d => {
        const nodeFromParentsChildNodes = find(parentsChildNodes, c => c.id === d.id);
        if (nodeFromParentsChildNodes) { d.index = nodeFromParentsChildNodes.index; }
    });
    /* update indexes in nodes with placeholder to return */

    nodesWithPlaceholder = sortBy(nodesWithPlaceholder, ['depth', 'index']);

    return nodesWithPlaceholder;

};