import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react"
import { useGetOrganization, useIsOrganizationAdmin } from "../../../../../hooks"
import { SectionHeader } from "../../../Components"
import { DeleteModal } from "./Modal"

import dayjs from 'dayjs'
import { useFirebase } from "react-redux-firebase"

export const Delete = () => {
    const { isOpen, onToggle, onClose } = useDisclosure()
    const firebase = useFirebase()
    const organization = useGetOrganization()
    const isAdmin = useIsOrganizationAdmin()
    const { cancel_at_period_end, cancel_at, customer_id, id: subscription_id } = organization?.subscription || []
    const cancel_date = cancel_at ? dayjs(new Date(cancel_at * 1000)).format('LL') : null
    return (
        <>
            <Stack spacing={3} mt={1.5}>
                <SectionHeader title="Delete organization" />
                <Stack direction="row" w="full" justify="space-between">
                    <Text fontSize="sm">
                        {!cancel_date && "Delete this organization and all resources within."}
                        {cancel_date && `This organization will be deleted on ${cancel_date}`}
                    </Text>
                    {isAdmin && !cancel_date && (
                        <Button
                            fontSize="sm"
                            colorScheme="red"
                            px={6}
                            isDisabled={!isAdmin}
                            onClick={onToggle}>
                            Delete
                        </Button>
                    )}
                    {isAdmin && cancel_date && (
                        <Button
                            fontSize="sm"
                            colorScheme="green"
                            isDisabled={!isAdmin}
                            onClick={async () => {
                                const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')({
                                    customerId: customer_id, after_completion: {
                                        type: 'redirect',
                                        redirect: {
                                            return_url: `${window.location.origin}${window.location.pathname}/${'settings/org-details'}`,
                                        },
                                    }, }) // fallback - open billing portal
                                if (sessionURL?.data) window.location = sessionURL.data;
                            }}

                        >
                            Renew plan
                        </Button>
                    )}
                </Stack>
            </Stack>
            <DeleteModal organization={organization} isOpen={isOpen} onClose={onClose} />
        </>
    )
}