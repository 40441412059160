import { Text } from "@chakra-ui/react"

import { useGetItemNameFromDoc, useInTrash } from "../../../hooks";

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

const locale = { name: 'en-short', relativeTime: { future: "in %s", past: "%s", s: 'just now', m: "1m ago", mm: "%dm ago", h: "an hour ago", hh: "%dh ago", d: "a day ago", dd: "%dd ago", M: "a month ago", MM: "%dm ago", y: "1y ago", yy: "%dy ago" } }

dayjs.locale(locale, null, true) // load locale for later use

export const Subtext = ({ file, forFileGroup }) => {
    const docTypeName = useGetItemNameFromDoc(file)
    const inTrash = useInTrash()
    const edited = `Edited ${dayjs(file?.updatedAt?.toString())?.locale('en-short')?.fromNow()}`
    const deleting = `Deleting on ${dayjs(file?.deleteAt?.toString()).format('ll')}`
    return (
        <>
            {!inTrash && (
                <Text
                    whiteSpace="nowrap"
                    display="inline"
                    color="fg.subtle"
                    fontSize="xs"
                >
                    {!forFileGroup ? `${docTypeName}` : edited}
                </Text>
            )}
            {inTrash && (
                <Text
                    whiteSpace="nowrap"
                    display="inline"
                    color={!inTrash ? "fg.subtle" : "rarchy-red"}
                    fontSize="xs"
                >
                    {deleting}
                </Text>
            )}
        </>
    )
}