import { Skeleton, Stack, Text, UnorderedList } from "@chakra-ui/react"
import { ChecklistItem, CollaborateWithOthersListItem, FolderPermissionsListItem, ScreenshotsListItem, UnlimitedFilesListItem, UnlimitedPagesPlusImportListItem } from "../../Components/Messaging/ListItems"

export const ConfirmedList = ({ isConfirming }) => {
    return (
        <Stack spacing={6}>
            <Stack>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <Text fontSize="sm">You've unlocked a <strong>ton</strong> of additional features.</Text>
                </SkeletonWrapper>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <Text fontSize="sm">Here are just a few of them...</Text>
                </SkeletonWrapper>
            </Stack>
            <UnorderedList spacing={3.5}>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <UnlimitedFilesListItem forConfirmed />
                </SkeletonWrapper>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <CollaborateWithOthersListItem forConfirmed />
                </SkeletonWrapper>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <UnlimitedPagesPlusImportListItem forConfirmed />
                </SkeletonWrapper>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <ScreenshotsListItem forConfirmed />
                </SkeletonWrapper>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <FolderPermissionsListItem forConfirmed />
                </SkeletonWrapper>
                <SkeletonWrapper isConfirming={isConfirming}>
                    <ChecklistItem forConfirmed>Add page colors, upload files, and more</ChecklistItem>
                </SkeletonWrapper>
            </UnorderedList>
        </Stack>
    )
}

const SkeletonWrapper = ({ isConfirming, children }) => <Skeleton w="fit-content" isLoaded={!isConfirming} rounded="sm">{children}</Skeleton>
