import * as React from 'react'
import { Box } from "@chakra-ui/react"

export const UnderlineLink = (props) => {
    const color = props.color || "rarchy-link"
    return (
        <Box
            mt={0.5}
            as="span"
            pos="relative"
            display="inline-block"
            transition="opacity 0.2s"
            color={color}
            _hover={{
                cursor: 'pointer',
                _after: {
                    bg: color
                }
            }}
            _after={{
                content: `""`,
                display: 'block',
                w: 'full',
                h: '2px',
                bottom: 0,
                bg: 'transparent',
                insetX: 0,
                insetY: 0,
            }}
            {...props}
        />
    )
}