import { Button, Flex, HStack, Progress, Text, Tooltip, useBoolean } from "@chakra-ui/react"
import { clearBulkCaptureDoc, useGetPagesForCapture, useWillHitMonthlyScreenshotsRemaining } from "../../hooks"
import { compact, isEmpty, keys, map } from 'lodash'
import { useCanFullyEditFile, useGetOrganization, useGetSitemap, useGetUser } from "../../../../../../../hooks"

import { ArrowForwardIcon } from "@chakra-ui/icons"
import { render } from "../../../../canvas/render"
import { sendHubspotCustomEvent } from "../../../../../../../helpers"
import { useFirestore } from "react-redux-firebase"

export const TopBar = ({ activeStep, setActiveStep, goToPrevious, goToNext, canProgress, checked, setChecked, options, isAutocapturing, autocapturing, hasAutocaptured, autocaptured }) => {

    const sitemap = useGetSitemap()
    const firestore = useFirestore()

    const user = useGetUser()
    const organization = useGetOrganization()

    const canFullyEdit = useCanFullyEditFile()

    const [isCapturing, setIsCapturing] = useBoolean(false)
    const [isClearing, setIsClearing] = useBoolean(false)

    const willHitScreenshotsLimit = useWillHitMonthlyScreenshotsRemaining(checked?.length)

    const percentComplete = Math.round((100 * (keys(autocaptured.pages)).length) / autocapturing.total) || 0
    const beforeCaptureStarted = !isAutocapturing && !hasAutocaptured;

    const errors = compact(map(autocaptured.pages, (item, id) => item?.error ? id : null))
    const pages = useGetPagesForCapture(!isEmpty(errors) ? errors : checked);

    const hasAutocapturedWithErrors = hasAutocaptured && !isEmpty(errors);

    const inUserSitemap = sitemap?.id === user.id

    const handleCapture = async () => {
        const { device } = sitemap?.covers || 'desktop'
        try {
            if (willHitScreenshotsLimit) return // fail-safe
            if (!canFullyEdit) return // fail-safe
            // no full-page screenshots in free account
            // eslint-disable-next-line
            await firestore.doc(`sitemaps/${sitemap?.id}/data/autocaptured`).delete();
            await firestore.doc(`sitemaps/${sitemap?.id}/data/autocapturing`).set({
                createdAt: new Date(),
                createdBy: user.id,
                organization: organization.id,
                device,
                pages,
                options: { ...options, fullpage: inUserSitemap ? false : options?.fullpage },
                email: true,
                total: keys(pages).length
            });
            // send event to Hubspot
            sendHubspotCustomEvent('bulk_capture_screenshots', { number_of_screenshots: keys(pages).length })
        } catch (e) {
            console.error(e)
        } finally {
            setIsCapturing.off()
            // setChecked([]);
            // setActiveStep(0);
            setTimeout(() => render(), 1000) // re-render so isCustomCoverPending can update without user interaction
        }
    }

    const handleStopped = () => {
        if (!autocapturing?.stopped) firestore.doc(`sitemaps/${sitemap?.id}/data/autocapturing`).set({ stopped: true }, { merge: true })
    }

    return (
        <HStack w="full" spacing={2} justifyContent="flex-end">
            {beforeCaptureStarted && (
                <>
                    {activeStep > 0 && (
                        <Button
                            fontSize="sm"
                            variant="ghost"
                            onClick={() => goToPrevious()}>
                            Back
                        </Button>
                    )}
                    {activeStep < 3 && (
                        <Tooltip variant="rounded-sm" showArrow placement="bottom-end" openDelay={500} label={!canProgress ? "Select pages to continue..." : ""}>
                            <Button
                                px={5}
                                colorScheme="blue"
                                isDisabled={!canProgress}
                                rightIcon={<ArrowForwardIcon />}
                                onClick={() => goToNext()}>
                                Next
                            </Button>
                        </Tooltip>
                    )}
                    {activeStep === 3 && (
                        <Button
                            colorScheme="blue"
                            isDisabled={!canProgress}
                            isLoading={isCapturing}
                            rightIcon={<ArrowForwardIcon />}
                            onClick={() => {
                                setIsCapturing.on()
                                setTimeout(() => { handleCapture() }, 500);
                            }}>
                            Capture
                        </Button>
                    )}
                </>
            )}
            {(isAutocapturing || hasAutocaptured || hasAutocapturedWithErrors) && (
                <HStack w="full">
                    {isAutocapturing && (
                        <Flex alignItems="center" w="full">
                            <HStack direction="row" spacing={2} w="full">
                                <Progress value={percentComplete} w="90%" rounded="md" />
                                <Text fontSize="sm" color="fg.muted" mr={6} >
                                    {`${percentComplete}%`}
                                </Text>
                            </HStack>
                            <Button isLoading={autocapturing?.stopped} loadingText="Stopping" size="md" fontSize="sm" colorScheme="red" variant="outline" px={6} onClick={() => handleStopped()}>
                                Stop
                            </Button>
                        </Flex>
                    )}
                    {(hasAutocaptured || hasAutocapturedWithErrors) && (
                        <HStack justifyContent="flex-end" w="full">
                            {hasAutocapturedWithErrors && (
                                <Button colorScheme="red" fontSize="sm" variant="outline" onClick={() => handleCapture(errors)} mr={3}>
                                    {`Retry ${errors?.length} errors`}
                                </Button>
                            )}
                            {hasAutocaptured && (
                                <Button
                                    fontSize="15px"
                                    colorScheme="blue"
                                    py={5}
                                    px={4}
                                    isLoading={isClearing}
                                    rightIcon={<ArrowForwardIcon />}
                                    onClick={() => {
                                        setIsClearing.toggle()
                                        setTimeout(() => {
                                            clearBulkCaptureDoc(setActiveStep)
                                            setIsClearing.toggle()
                                        }, 500);
                                    }}
                                >
                                    Capture again
                                </Button>
                            )}
                        </HStack>
                    )}
                </HStack>
            )}
        </HStack>
    )
}