import { forwardRef } from 'react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody
} from '@chakra-ui/react'
import { ShareFolderDropdownModalWrapper } from './Controller'

export const ShareDropdown = forwardRef(({ folder, button }, ref) => {
    return (
        <Popover isLazy offset={[1000, 7.5]}>
            {({ isOpen, onClose }) => (
                <>
                    <PopoverTrigger ref={ref}>{button}</PopoverTrigger>
                    <PopoverContent w="sm" mr={7}>
                        <PopoverBody>
                            <ShareFolderDropdownModalWrapper folder={folder} onClose={onClose} />
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    )
})