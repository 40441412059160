import { useDispatch } from "react-redux"
import { toggleOnboardingModal } from "../../../store/actions/ui-actions"
import { Box, Button, Center, Checkbox, Heading, Image, Stack, Text } from "@chakra-ui/react"
import { useGetOnboardingModal, useGetUser } from "../../../hooks"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import ConfirmedImg from "../../UpgradeAccount/Images/Confirmed.svg"
import { uniqBy } from 'lodash'

const steps = [
    {
        title: 'Get familiar with the sitemap editor',
        label: 'The sitemap editor is your drag-and-drop canvas for building your visual sitemap. ',
        description: 'Right click to open page editing options...',
        video: 'https://help.rarchy.com/hubfs/Onboarding/Sitemap%20Editor/ContextMenu.mp4'
    },
    {
        title: 'Switch between page covers and page sections',
        label: 'The sitemap editor is your drag-and-drop canvas for building your visual sitemap. ',
        description: '...and toggle your view to switch between page covers and page sections',
        video: 'https://help.rarchy.com/hubfs/Onboarding/Sitemap%20Editor/Views.mp4'
    },
    {
        title: 'Add wireframes, SEO elements, URLS and more to your pages',
        label: 'Edit your URL and page titles, and use wireframes to plan page designs.',
        description: 'Click on the folder icon to choose a wireframe, add SEO elements, and more...',
        video: 'https://help.rarchy.com/hubfs/Onboarding/Sitemap%20Editor/PageDrawer.mp4'
    },
    {
        title: 'Share and collaborate with your team or clients',
        label: 'Collaborate with others by adding comments and sharing your projects.',
        description: 'Add comments and share your sitemap or user flow with others...',
        video: 'https://help.rarchy.com/hubfs/Onboarding/Sitemap%20Editor/Comments.mp4'
    },
    { title: 'Now go put those tips into practice!', label: 'These tips should help you get started with our sitemap editor. More questions? Get in touch!' }
]

export const SitemapEditorOnboarding = ({ side, stepIndex, setStepIndex }) => {

    return (
        <>
            {side === 'left' && <SitemapEditorLeft stepIndex={stepIndex} setStepIndex={setStepIndex} />}
            {side === 'right' && <SitemapEditorRight stepIndex={stepIndex} setStepIndex={setStepIndex} />}
        </>
    )
}

const SitemapEditorLeft = ({ stepIndex, setStepIndex }) => {
    const dispatch = useDispatch()
    const user = useGetUser()
    const NewSitemapModal = useGetOnboardingModal()
    const { key } = NewSitemapModal || {}
    const isLastStep = stepIndex === steps?.length - 1
    return (
        <Stack w="md" p={9} py={10} mb={2} spacing={7}>
            <Heading fontSize="2xl" fontWeight="semibold">
                {steps?.[stepIndex]?.title}
            </Heading>
            <Text color="fg.muted" fontSize="15px">{steps?.[stepIndex]?.label}</Text>
            <Stack spacing={5} direction="column">
                {uniqBy([{ title: 'Create your first sitemap' }, ...steps], 'title').slice(0, -1)?.map((step, i) => {
                    const isStep = stepIndex === i - 1
                    return (
                        <Checkbox
                            size="lg"
                            key={i}
                            isChecked={stepIndex > i - 1}
                            value={step?.title}
                            spacing={5}
                            alignItems="top"
                            fontWeight={isStep ? "semibold" : "normal"}
                            color={(isStep || isLastStep) ? 'fg.default' : 'fg.subtle'}
                        >
                            <Text mt={-0.25} fontSize="sm">{step?.title}</Text>
                        </Checkbox>
                    )
                })}
            </Stack>
            <Button
                mt={2}
                colorScheme="blue"
                onClick={() => {
                    if (isLastStep) {
                        dispatch(toggleOnboardingModal())
                        if (!user?.onboarding?.[key]?.completed) {
                            setDoc(doc(getFirestore(), 'users', user?.id), { onboarding: { [key]: { completed: new Date() } } }, { merge: true })
                        }
                    } else {
                        setStepIndex(stepIndex + 1)
                    }
                }}>{isLastStep ? "Finish" : "Continue"}</Button>
        </Stack>
    )
}

const SitemapEditorRight = ({ stepIndex }) => {
    const step = steps?.[stepIndex]
    const isLastStep = stepIndex === steps?.length - 1
    return isLastStep ? (
        <Center bgColor="rarchy-bg-upgrade-modal" w="full">
            <Image w="50%" src={ConfirmedImg} />
        </Center>
    ) : (
        <Stack w="full" p={12} py={10} spacing={12} bgColor="rarchy-link">
            <Heading color="white" fontSize="xl" fontWeight="semibold">
                {steps?.[stepIndex]?.description}
            </Heading>
            {step?.video && (
                <Box
                    borderRadius="md"
                    as='video'
                    w="400px"
                    priority="true"
                    preload="true"
                    autoPlay
                    muted
                    loop
                    controls={false}
                    src={step?.video}
                />
            )}
        </Stack>
    )

}