import {
    Box,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { useCanFullyEditFile, useGetSitemap, useInUserFlow, useIsLinkedUserFlowPage } from '../../../../../hooks';

import { Cover } from './Cover';
import { RemainingScreenshots } from '../../covers/Capture/Components/Remaining';
import { Screenshot } from './Screenshot';
import { Upload } from './Upload';
import { Wireframes } from './Wireframes'
import { getCustomCover } from '../../canvas/utils/helpers';
import { useState } from 'react';

export const Covers = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const sitemap = useGetSitemap()
    const { PageDrawer } = sitemap?.ui
    const canFullyEdit = useCanFullyEditFile()
    const inUserFlow = useInUserFlow()
    const linkedPage = useIsLinkedUserFlowPage(PageDrawer.page)
    const tabs = getTabs({ inUserFlow, linkedPage })
    const customCover = getCustomCover(PageDrawer.page)
    return (
        <>
            {customCover && <Cover PageDrawer={PageDrawer} customCover={customCover} />}
            {!customCover && (
                <Tabs variant="enclosed" size="sm" defaultIndex={0} onChange={(index) => setTabIndex(index)} mt={2}>
                    <TabList>
                        {[...tabs.map(t => {
                            const isScreenshot = t?.id === 'screenshot'
                            const isUpload = t?.id === 'upload'
                            const isDisabled = !canFullyEdit && (isScreenshot || isUpload)
                            return (
                                <Tab
                                    key={t.id}
                                    isDisabled={isDisabled}
                                    fontWeight="semibold"
                                    _selected={{ borderColor: 'inherit', borderBottomColor: mode("white", "gray.700"), color: mode("blue.600", "blue.300") }}>
                                    <Tooltip isDisabled={!isDisabled} w="175px" textAlign="center" label={`Only team members can ${isScreenshot ? "capture screenshots" : "upload custom covers"}`} placement="top" variant="rounded-sm" showArrow openDelay={250}>{t.title}</Tooltip>
                                </Tab>
                            )
                        })]}
                    </TabList>
                    <TabPanels>
                        {[...tabs.map(t => {
                            return (
                                <TabPanel key={t.id} px={0}>
                                    {t.id === 'wireframes' && <Wireframes />}
                                    {t.id === 'screenshot' && <Screenshot />}
                                    {t.id === 'upload' && <Upload />}
                                </TabPanel>
                            )
                        })]}
                    </TabPanels>
                </Tabs>
            )
            }
        </>
    )
}

const getTabs = ({ inUserFlow, linkedPage }) => {
    const arr = [{
        id: "wireframes", title: "Wireframes"
    }];
    if (!inUserFlow || (inUserFlow && linkedPage)) {
        arr.push({ id: "screenshot", title: 'Screenshot' })
        arr.push({ id: "upload", title: 'Upload' })
    }
    return arr;
}