import { dragging } from "../../../app/canvas/utils/drag";

export const ARC_TO_RADIUS = 0 // 5;

export const getConnectorX = (node, section, pos) => {
    var x = node.leftOfNode;
    const inputOutputOffset = node.type === 'inputOutput' ? 9 : 0;
    //
    switch (pos) {
        case 'bottom':
        case 'top':
            x = x + ((node.rightOfNode - x) / 2)
            break;
        case 'left':
            x = (!section ? (x + inputOutputOffset) : section.x - 18) - 7.5
            break;
        case 'right':
            x = (!section ? (node.rightOfNode - inputOutputOffset) : section.x + 190 + 18) + 7.5
            break;
    }
    // account for multi-dragging
    if (dragging) {
        if (node._selected && node.id !== dragging?.id) x = accountForMultiDraggingOffset(x, 'x')
    }
    return x
}

export const getConnectorY = (node, section, pos) => {
    const isSymbol = !node?.type?.startsWith('page')
    const symbolOffset = isSymbol ? 5 : 0
    var y = node.y + 14
    //
    switch (pos) {
        case 'bottom':
            y = node.bottomOfNode + 7.5 + symbolOffset
            break;
        case 'top':
            y = node.topOfNode - 7.5 + symbolOffset
            break;
        case 'left':
        case 'right':
            y = (!section ? (y + (node.type === 'page' ? 117.5 : node.type === 'page-sections' ? node.nodeHeight / 2 : 10)) : section.y + (section.rectHeight / 2)) // need extra +10 to line up original svg y (all symbols apart from page)
            break;
    }
    // account for multi-dragging
    if (dragging) {
        if (node._selected && node.id !== dragging?.id) y = accountForMultiDraggingOffset(y, 'y')
    }
    return y
}

const accountForMultiDraggingOffset = (val, coord) => {
    const offset = dragging[coord] - dragging[`${coord}0`]
    return val + offset
}

export const getStartConnectorIsToTheLeftOfEndConnector = (startConnectorX, endConnectorX) => startConnectorX < endConnectorX

export const getStartConnectorIsBelowEndConnector = (startConnectorY, endConnectorY, minPadding) => startConnectorY - minPadding > endConnectorY - minPadding

export const getMidXBetweenNodes = (startNode, endNode) => {
    return startNode.rightOfNode + ((endNode.leftOfNode - startNode.rightOfNode) / 2);
}

export const getMidYBetweenNodes = (startNode, endNode, startConnectorIsBelowEndConnector) => {
    return startConnectorIsBelowEndConnector ? startNode.topOfNode + ((endNode.bottomOfNode - startNode.topOfNode) / 2) : startNode.bottomOfNode + ((endNode.topOfNode - startNode.bottomOfNode) / 2)
}