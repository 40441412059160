import { Stack, Button } from "@chakra-ui/react"
import { Owner } from "./Menus/Owner"
import { Modified } from "./Menus/Modified"
import { SortBy } from "./Menus/SortBy"
import { ProjectsView } from "../ProjectsView"
import { useGetProjectsPathFilter, useGetProjectsSortFilter } from "../../../../hooks"
import { changeProjectsModified, changeProjectsOwner } from "../../../../store/actions/projects-actions"
import { useDispatch } from "react-redux"

export const TopBar = () => {
    return (
        <Stack direction="row" w="full" justifyContent="space-between">
            <Stack direction="row" spacing={5}>
                <Stack direction="row" spacing={3}>
                    <Owner />
                    <Modified />
                </Stack>
                <ClearButton />
            </Stack>
            <Stack direction="row" spacing={3}>
                <SortBy />
                <ProjectsView />
            </Stack>
        </Stack>
    )
}

const ClearButton = () => {
    const dispatch = useDispatch()
    const owner = useGetProjectsSortFilter('owner')
    const modified = useGetProjectsSortFilter('modified')
    const hasPathFilter = useGetProjectsPathFilter()
    let count = null; if (owner) count++; if (modified?.key) count++;
    const showButton = !hasPathFilter && count;
    return (
        <>
            {showButton && (
                <Button
                    variant="link"
                    size="sm"
                    color="rarchy-link"
                    onClick={() => {
                        if (owner) dispatch(changeProjectsOwner(null))
                        if (modified?.key) dispatch(changeProjectsModified({ key: null }))
                    }}>
                    {`Clear all (${count})`}
                </Button>
            )}
        </>
    )
}