import React from 'react'
import {
    IconButton,
    useColorMode,
    Tooltip
} from "@chakra-ui/react"
import { BsMoonStarsFill, BsSun } from 'react-icons/bs'

export const ColorMode = () => {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <Tooltip variant="rounded-sm" openDelay={250} label={`${colorMode === "light" ? "Dark" : "Light"} mode`} hasArrow>
            <IconButton
                isRound
                size="md"
                fontSize="lg"
                variant="ghost"
                icon={colorMode === 'dark' ? <BsSun /> : <BsMoonStarsFill />}
                color="rarchy-color-sitemap-editor"
                onClick={toggleColorMode}
            />
        </Tooltip>
    )
}