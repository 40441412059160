import { Box, Grid, GridItem, HStack } from "@chakra-ui/react";
import { Comments, PageCommentsDrawer } from "../../../Editor/Navbar/Comments";
import { CoversCanvas, InteractionsCanvas, MainCanvas, PickerCanvas, resizeCanvas } from "./canvases";
import { SetTitle, useCanEditFile, useGetEditor, useGetFullscreenCoverDrawer, useGetSitemap, useInUserFlow, useIsPageCommentsDrawerShowing, useIsPageDrawerShowing } from "../../../../hooks";
import { center, initZoom } from "./utils/zoom";
import { useEffect, useRef } from "react";

import { CanvasButtons } from "./buttons";
import { CanvasTextEditorInput } from "./components/text/input";
import { ContextMenu } from "../context-menu";
import { CoversGallery } from "../covers/Gallery";
import { ExportPDFDrawer } from "../../../Editor/Toolbar/Export/PDF";
import { ExportProgressModal } from "../../../Editor/Toolbar/Export/ExportProgressModal";
import { LoadingSpinner } from "../../../Editor";
import { PageButtons } from "../page-buttons";
import { PageCommentsPopover } from "../../comments";
import { PageDrawer } from "../page-drawer";
import { PageSectionsOptionsPopover } from "./components/page-sections/options";
import { getCanvasHeight } from "./utils/helpers";
import { getCanvasTextEditor } from "../../../../helpers";
import { renderListeners } from "./utils/listeners";
import { update } from "../../utils/app";
import { update as updateUserFlow } from "../../user-flows/helpers";

const MIN_SIZE_IN_PIXELS = 512;

export const SharedCanvas = ({ children }) => {

    const editor = useGetEditor()

    SetTitle(editor?.name)

    const inUserFlow = useInUserFlow()

    const sitemap = useGetSitemap()

    const canEdit = useCanEditFile()

    const canvasHeight = getCanvasHeight()

    const isShowingPageDrawer = useIsPageDrawerShowing()
    const isShowingPageCommentsDrawer = useIsPageCommentsDrawerShowing()

    const containerRef = useRef(null)
    HandleCanvasContainerResize(containerRef)

    const shouldDisplayPanel = isShowingPageDrawer || isShowingPageCommentsDrawer

    const FullscreenCoverDrawer = useGetFullscreenCoverDrawer()
    const CanvasTextEditor = getCanvasTextEditor()

    return (
        <Box id="container" w="full">
            <HStack spacing={0}>
                <Box
                    id="canvases-container"
                    ref={containerRef}
                    w={`calc(100% - ${shouldDisplayPanel ? MIN_SIZE_IN_PIXELS : 0}px)`}
                    h={canvasHeight}
                >
                    {/*** ensure these are under "canvases-containers" so we can apply offsetY correctly */}
                    <LoadingSpinner h={canvasHeight} />
                    <PageCommentsPopover />
                    <PageButtons />
                    <ExportProgressModal />
                    <ExportPDFDrawer />
                    {FullscreenCoverDrawer.showing && <CoversGallery />}
                    {/* need to check for showing here, not in component */CanvasTextEditor?.showing && <CanvasTextEditorInput />}
                    {(inUserFlow || !sitemap?.showCovers) && <PageSectionsOptionsPopover />}
                    {(!inUserFlow || (inUserFlow && canEdit)) && <ContextMenu />}
                    {children}
                    {/*** ensure these are under "canvases-containers" so we can apply offsetY correctly */}

                    <Grid gridTemplateColumns="1fr" h={canvasHeight}>
                        <GridItem gridRowStart={1} gridColumnStart={1} w="fit-content">
                            <PickerCanvas />
                        </GridItem>
                        <GridItem gridRowStart={1} gridColumnStart={1} w="fit-content">
                            <CoversCanvas />
                        </GridItem>
                        <GridItem gridRowStart={1} gridColumnStart={1} w="fit-content">
                            <MainCanvas />
                        </GridItem>
                        <GridItem gridRowStart={1} gridColumnStart={1} w="fit-content">
                            <InteractionsCanvas />
                        </GridItem>
                    </Grid>

                    {/*** has to be below grid ***/}
                    <CanvasButtons />
                    {/*** has to be below grid ***/}

                </Box>
                {shouldDisplayPanel && (
                    <Box
                        w={`${MIN_SIZE_IN_PIXELS}px`}
                        h={canvasHeight}
                        zIndex={1}
                        overflowY="scroll"
                        borderLeft="1px solid"
                        borderColor="rarchy-border-sitemap-editor"
                        bgColor="rarchy-bg-sitemap-editor"
                    >
                        {isShowingPageDrawer && <PageDrawer h={canvasHeight} />}
                        {isShowingPageCommentsDrawer && <PageCommentsDrawer />}
                    </Box>
                )}
            </HStack>
        </Box>
    )
}

const HandleCanvasContainerResize = (containerRef) => {
    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            setTimeout(() => { resizeCanvas() }, 0);
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);
}

export const init = () => {
    // init zoom
    initZoom()
    // update and load!
    update();
    updateUserFlow();
    // center
    center();
    // render listeners
    renderListeners()
}