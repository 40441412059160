import { Fade, Text } from '@chakra-ui/react';
import { applyYWithCavasOffset, getX, getY, shouldShowCovers } from '../canvas/utils/helpers'
import { useGetRevisionHistoryDrawer, useGetSitemap, useGetUserFlow, useInUserFlow, useIsLinkedUserFlowPage } from '../../../../hooks';

import { CommentsButton } from './comments';
import { Delete } from './delete';
import { ExpandCollapse } from './expand-collapse';
import { Fullscreen } from './fullscreen';
import { NewPageButton } from './new-page';
import { NewPageSection } from './page-sections/new';
import { PageDrawerButton } from './page-drawer';
import { TopRow } from './top-row';
import { getIsTemplateDrawerOpen } from '../../../../helpers';
import { getURL } from '../../../../store/actions/sitemap-actions';
import { transform } from '../canvas/utils/zoom';

export const PageButtons = () => {

    const sitemap = useGetSitemap()
    const inUserFlow = useInUserFlow()
    const flow = useGetUserFlow()

    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer()

    const { PageButtons } = sitemap?.ui || {};

    const d = sitemap?.ui.PageButtons.node;
    const showCovers = shouldShowCovers(d);
    const { x, y } = getBoundingClientRect(d);
    const props = { d, x, y };
    const NotInRevHistory = !RevisionHistoryDrawer.showing;
    const { NewConnectorLine } = flow?.utils;
    const showInUserFlow = inUserFlow && !NewConnectorLine.showing;
    const linkedUserFlowPage = useIsLinkedUserFlowPage(d);
    const url = getURL(d?.id);

    if (!PageButtons.showing || PageButtons.zooming) return null;

    return (
        <>
            {NotInRevHistory && (
                <>
                    {(!inUserFlow || showInUserFlow) && <Delete {...props} />}
                    {!inUserFlow && ([...sides].map(side => <NewPageButton key={side} side={side} {...props} />))}
                    {(!inUserFlow || linkedUserFlowPage) && <CommentsButton {...props} />}
                    {(!inUserFlow || (showInUserFlow)) && <TopRow {...props} />}
                    {showCovers && (!inUserFlow || showInUserFlow) && <PageDrawerButton {...props} />}
                    {showCovers && (!inUserFlow || (showInUserFlow && linkedUserFlowPage)) && <Fullscreen {...props} />}
                    <NewPageSection inUserFlow={inUserFlow} {...props} />
                    <Fade in={url} transition={{ enter: { delay: 0.25, duration: 0.25 } }}>
                        <Text borderRadius="4px" pos="absolute" left="0" bottom="0" bgColor="rarchy-border" px={3} py={1} fontSize="xs">{getURL(d?.id)}</Text>
                    </Fade>
                </>
            )}
            <ExpandCollapse {...props} />
        </>
    )
}

const sides = ["left", "right", "below", "above"];

const getBoundingClientRect = (d) => {
    const x = transform.applyX(getX(d))
    const y = applyYWithCavasOffset(getY(d))
    return { x, y }
};