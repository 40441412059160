import {
    Box,
    Container,
    Stack
} from '@chakra-ui/react'
import { useGetAuth, useGetFirebaseFromState, useJustLoggedIn } from '../../../hooks';

import { Error } from './Components/Error';
import { Footer } from './Components/Footer';
import { HUBSPOT_SIGNUP_FORM_ID } from '../../../../shared/helpers';
import { Header } from './Components/Header';
import { InvitationMessaging } from './Components/InvitationMessaging';
import { Link } from 'react-router-dom';
import { Logo } from './Components/Logo'
import { Redirect } from 'react-router-dom'

export const AuthContainer = (props) => {

    const { children, type, ...rest } = props

    const auth = useGetAuth()

    const { email } = useGetFirebaseFromState()?.auth || {}

    const justLoggedIn = useJustLoggedIn()
    const isSignup = type === "signup"
    const isSignupPersonalize = type === "signup/2"
    const isConfirmingInvite = type === "confirm-invitation"
    const isLogin = type === "login"
    const isPasswordReset = type === "reset"
    const isSentPasswordResetEmail = type === 'sent-reset-email'
    const isPasswordResetVerification = type === 'reset-verify'
    const invited = [/confirm-/, /joinorg/].some(re => re.test(auth.redirectUser));

    if (justLoggedIn && !isSignupPersonalize && !isConfirmingInvite) {
        // get user to self-report attributes
        const hasSignedUp = auth?.signedUp
        if (isSignup && hasSignedUp) {
            // submit hubspot signup form 
            submitHubspotForm(email)
            // redirect to signup questions
            return <Redirect to="/signup/2" />
        }
        // user wasn't invited to create an account - continue as normal
        return <Redirect to={auth?.redirectUser || '/app'} />
    } else {
        const showFooter = !isConfirmingInvite && !isPasswordReset && !isSentPasswordResetEmail && !isPasswordResetVerification
        return (
            <Container maxW="lg" py={{ base: '12', md: '24' }}>
                <Stack spacing="9">
                    <Link to="/app"><Logo /></Link>
                    {invited && !isConfirmingInvite && <InvitationMessaging />}
                    {auth.isError && <Error auth={auth} />}
                    <Box
                        py={8}
                        px={10}
                        bg="rarchy-bg-white"
                        boxShadow="md"
                        borderRadius="xl"
                    >
                        <Header
                            isSignup={isSignup}
                            isSignUpPersonalize={isSignupPersonalize}
                            isLogin={isLogin}
                            isPasswordReset={isPasswordReset}
                            isSentPasswordResetEmail={isSentPasswordResetEmail}
                            isPasswordResetVerification={isPasswordResetVerification}
                        />
                        {children}
                    </Box>
                    {showFooter && <Footer isSignupPersonalize={isSignupPersonalize} isLogin={isLogin} />}
                </Stack>
            </Container>
        )
    }
}

const submitHubspotForm = (email) => {
    try {
        const hubspotFormDom = document.getElementById(`hsForm_${HUBSPOT_SIGNUP_FORM_ID}`)
        // bind email to hubspot input
        document.getElementById(`email-${HUBSPOT_SIGNUP_FORM_ID}`).value = email;
        // submit form
        hubspotFormDom.submit();
    } catch (e) {
        console.error(e);
    }
}
