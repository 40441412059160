import { AbsoluteCenter, Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { SearchIcon } from "@chakra-ui/icons"
import { useGetFoldersData } from "../../../../../hooks"
import { FolderItem } from "./Folders/Shared/FolderItem"
import { useMoveToFolderContext } from "../../../../Projects/Folders/hooks"
import { isEmpty } from 'lodash'

export const SearchFolders = () => {
    const { searchInputVal, setSearchInputVal } = useMoveToFolderContext()
    return (
        <Box px={3}>
            <InputGroup mb={2.5}>
                <InputLeftElement pointerEvents="none" children={<SearchIcon color="rarchy-border-400" />} />
                <Input key="search-folders-input" type="text" value={searchInputVal} fontSize="sm" placeholder="Search all folders" onChange={(e) => setSearchInputVal(e.target.value)} />
            </InputGroup>
        </Box>
    )
}

export const SearchFoldersList = () => {
    const { searchInputVal } = useMoveToFolderContext()
    const folders = useGetFoldersData()
    const filteredForSearch = [...folders]?.filter(f => f?.name?.toLowerCase().includes(searchInputVal?.toLowerCase()))
    return (
        <>
            <Box h="43vh" maxH="330px" overflowX="hidden" overflowY="auto">
                {isEmpty(filteredForSearch) && (
                    <AbsoluteCenter w="full" align="center" fontSize="xs" color="fg.muted" px={5}>
                        {`No results were found for "${searchInputVal}"`}
                    </AbsoluteCenter>
                )}
                {!isEmpty(filteredForSearch) && filteredForSearch.map(f => <FolderItem key={f?.id} folder={f} />)}
            </Box>
        </>
    )
}