import { Button, Checkbox, Divider, Input, InputGroup, Stack, Tab, TabList, Tabs, Text } from "@chakra-ui/react"
import { Close, Container } from "./Components";
import { useIsImportingSitemap, useIsSitemapImportPending } from "../../../../hooks";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Banner } from "../../Import/Banner";
import { Upload } from "./Upload";
import { getPathnameFromURL } from "../../../../store/actions/sitemaps-actions";
import { isFirebaseStorageURL } from "../../../../helpers";
import { useFormikContext } from "formik";

export const Header = ({ tabs, tabIndex, setTabIndex }) => {

    const { values, setFieldValue, handleChange, handleSubmit } = useFormikContext()

    const isImportPending = useIsSitemapImportPending()
    const isImporting = useIsImportingSitemap()

    const isCrawl = tabIndex === 0
    const isXML = tabIndex === 1
    const isCSV = tabIndex === 2
    const isHistory = tabIndex === 3

    const pathname = getPathnameFromURL(values?.url)
    const urlSubfolders = !isFirebaseStorageURL(values?.url) && pathname?.split("/")?.length

    return (
        <Container>
            <Close>
                {isImporting && <Banner inImport />}
                <Tabs isLazy index={tabIndex} tabIndex={tabIndex} w="full" colorScheme="blue" variant="solid-rounded" onChange={(index) => setTabIndex(index)} pb={isHistory ? 3 : 0}>
                    <TabList borderBottomWidth={(isCSV || isHistory) ? 2.5 : 0}>
                        {tabs.map(({ key, label }) => {
                            return (
                                <Stack key={key} direction="row" align="center" spacing={0}>
                                    {key === 'history' && (<Divider mx={6} orientation="vertical" h="36px" opacity={1} />)}
                                    <Tab px={5} py={4} borderRadius="md" borderBottomRadius={0}>{label}</Tab>
                                </Stack>
                            )
                        })}
                    </TabList>
                    {!isCSV && !isHistory && (
                        <>
                            <InputGroup size="lg" minW="full" h="75px">
                                <Input
                                    id="url"
                                    value={!isFirebaseStorageURL(values?.url) ? values?.url : null}
                                    isDisabled={(isImportPending || isImporting) || tabIndex === 2}
                                    bgColor="rarchy-bg-white"
                                    px={6}
                                    fontSize="md"
                                    fontWeight="medium"
                                    borderRadius="sm"
                                    placeholder="Type or paste any URL..." h="75px"
                                    mt={0}
                                    borderWidth={2.5}
                                    borderEndRadius={0}
                                    borderEnd={0}
                                    pr="180px"
                                    onChange={handleChange}
                                />
                                <Button
                                    borderStartRadius={0}
                                    h="full"
                                    boxShadow="xs"
                                    fontSize="md"
                                    ype="submit"
                                    paddingInline={8}
                                    colorScheme="blue"
                                    isDisabled={isImporting}
                                    isLoading={isImportPending}
                                    rightIcon={<ArrowForwardIcon />}
                                    onClick={handleSubmit}
                                >
                                    {isCrawl ? "Crawl" : "Import"}
                                </Button>
                            </InputGroup>
                            {isCrawl && urlSubfolders && (
                                <Stack direction="row" align="center" ml={1.5} mt={6} spacing={4}>
                                    <Checkbox
                                        size="lg"
                                        isChecked={values?.restrictToSubfolder}
                                        onChange={(e) => setFieldValue("restrictToSubfolder", e.target.checked)}
                                    >
                                        <Text ml={2} fontSize="sm" color="fg.muted">{`Only crawl pages containing "${pathname}"`}</Text>
                                    </Checkbox>
                                </Stack>
                            )}
                        </>
                    )}
                </Tabs>
            </Close>
            {!isCrawl && !isHistory && <Upload isXML={isXML} isCSV={isCSV} />}
        </Container>
    )
}