import React, { useEffect } from 'react'

import {
    Box,
    VStack,
    Button,
    Stack,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    FormHelperText,
    Flex,
    Heading,
    Select,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useBoolean
} from "@chakra-ui/react"

import { DropzoneComponent } from "./Components/Dropzone"
import { Formik } from 'formik'

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

import queryString from 'query-string';
import { useGetUser } from '../../hooks'
import { HUBSPOT_PORTAL_ID, wait } from '../../../shared/helpers';
import { isEmpty } from 'lodash'

const FORM_ID = "208e52ec-a9c4-4a1d-87f7-56647bb34b65"

export const Ticket = () => {
    const user = useGetUser()
    const [wasSubmitSuccess, setWasSubmitSuccess] = useBoolean()
    const { formCreated } = useHubspotForm({
        portalId: HUBSPOT_PORTAL_ID,
        formId: FORM_ID,
        target: '#hubspot-ticket-form'
    });
    const { tc: hs_ticket_category, s: subject, co: content } = queryString.parse(window.location.search);
    const prefill = ({ hs_ticket_category: TICKET_CATEGORY_OPTIONS.find(c => c.value === hs_ticket_category), subject, content });
    // unmount
    useEffect(() => { return () => setWasSubmitSuccess.off() }, [])
    return (
        <Stack py={6} spacing={6} ml={1.5}>
            <Heading fontSize="3xl" fontWeight="semibold">
                Submit a ticket
            </Heading>
            <VStack w="2xl" align="start" pb={20} spacing={6}>
                {wasSubmitSuccess && (
                    <Alert status="success" rounded="md" py={3}>
                        <AlertIcon />
                        <AlertTitle>We've received your ticket!</AlertTitle>
                        <AlertDescription>You'll receive a response from us within 48 hours.</AlertDescription>
                    </Alert>
                )}
                {formCreated && !wasSubmitSuccess && (
                    <Formik
                        initialValues={prefill}
                        enableReinitialize
                        onSubmit={async (values, { resetForm }) => {
                            try {
                                await wait(1000)
                                submitHubspotForm({ values, user });
                                setWasSubmitSuccess.on()
                                resetForm()
                            } catch (e) {
                                console.error(e)
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            if (!isEmpty(errors)) console.log(errors);
                            return (
                                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                                    <Stack spacing="5">
                                        <FormControl id="hs_ticket_category">
                                            <FormLabel fontSize="sm">Ticket Category</FormLabel>
                                            <Select
                                                size="md"
                                                placeholder='Select option'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values?.hs_ticket_category || ''}
                                                isRequired
                                            >
                                                {[...TICKET_CATEGORY_OPTIONS].map(option => {
                                                    return <option key={option.value} value={option.value}>{option.label}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl id="subject">
                                            <FormLabel fontSize="sm">Ticket Name</FormLabel>
                                            <Input size="md" rounded="md" type="text" onChange={handleChange}
                                                onBlur={handleBlur} value={values?.subject || ''} isRequired />
                                        </FormControl>
                                        <FormControl id="content">
                                            <FormLabel size="sm" fontSize="sm">Ticket Description</FormLabel>
                                            <Textarea size="md" rounded="md" overflow="scroll" onChange={handleChange} minH="200px"
                                                onBlur={handleBlur} value={values?.content || ''} isRequired />
                                        </FormControl>
                                        <FormControl id="fileUpload">
                                            <FormLabel fontSize="sm">Attach files (optional)</FormLabel>
                                            <DropzoneComponent attachFilesToForm={(files) => {
                                                const uploadFormElement = document.getElementById(`TICKET.${'hs_file_upload'}-${FORM_ID}`);
                                                uploadFormElement.files = files;
                                                document.getElementById(`TICKET.${'hs_file_upload'}-${FORM_ID}`).files = files
                                            }} />
                                            <FormHelperText mt={4} color="fg.subtle">Upload any screenshots/recordings of your issue</FormHelperText>
                                        </FormControl>
                                        <FormControl id="submit">
                                            <Flex direction="row-reverse">
                                                <Button isLoading={isSubmitting} size="lg" fontSize="md" type="submit" colorScheme="blue">Submit</Button>
                                            </Flex>
                                        </FormControl>
                                    </Stack>
                                </form>
                            )
                        }}
                    </Formik>
                )}
                <Box id="hubspot-ticket-form" className="hubspot-form" />
            </VStack>
        </Stack>
    )
}

const submitHubspotForm = ({ values, user }) => {

    try {

        const hubspotFormDom = document.getElementById(`hsForm_${FORM_ID}`)

        /*** bind inputs to hubspot inputs ***/
        // first name
        document.getElementById(`firstname-${FORM_ID}`).value = user.firstName;
        // last name
        document.getElementById(`lastname-${FORM_ID}`).value = user.lastName;
        // email
        document.getElementById(`email-${FORM_ID}`).value = user.email;
        // map user inputted values
        Object.keys(values).forEach(key => {
            if (values[key] !== '') {
                document.getElementById(`TICKET.${key}-${FORM_ID}`).value = values[key];
            }
        })
        /*** bind inputs to hubspot inputs ***/

        // submit form
        hubspotFormDom.submit();

    } catch (e) {
        throw new Error(e)
    }

}

const TICKET_CATEGORY_OPTIONS = [
    { label: 'Product issue', value: 'PRODUCT_ISSUE' },
    { label: 'Billing issue', value: 'BILLING_ISSUE' },
    { label: 'Feature request', value: 'FEATURE_REQUEST' },
    { label: 'General feedback', value: 'GENERAL_INQUIRY' },
];

const getHelpURL = (processing) => {
    let tc = "PRODUCT_ISSUE", s, co;
    if (processing?.type === 'xml') s = "Help with crawling sitemap";
    if (processing?.error?.code === 'not-found') co = `Hi Rarchy Team,%0A%0AI am trying to import pages from ${processing.url}, but I am unable to create a visual sitemap?.%0A%0ACan you please look into this for me?`;
    return `help?tc=${tc}&s=${s}&co=${co}`
}

// %0A for new line in query params