import { Box, Heading } from "@chakra-ui/react"

import { Filter } from "../../../../../components/SearchWithFilter";
import { PagesTree } from "../../../../../components/PagesTree"
import { isEmpty } from 'lodash'
import { useGetSitemap } from "../../../../../../../../hooks";
import { useState } from "react";

// ensure this is not wrapped in activeStep === 0, so we don't reload pages everytime we go to step :)

export const Pages = ({ activeStep, checked, setChecked }) => {
    const sitemap = useGetSitemap()
    const [filter, setFilter] = useState('all pages')
    return (
        <Box w="full" display={activeStep === 0 ? 'block' : 'none'} px={0} py={0}>
            <Heading
                fontSize="md"
                fontWeight="semibold"
                mb={3}
            >
                {`Pages`}
                <Box ml={2} display="inline" fontWeight="medium" fontSize="sm" color="fg.muted">{!isEmpty(checked) ? `(${checked.length} selected)` : ''}</Box>
            </Heading>
            <PagesTree
                checked={checked}
                setChecked={setChecked}
                opts={{
                    showCheckbox: true,
                    rightFilter: {
                        element: <Filter filter={filter} setFilter={setFilter} keys={['all pages', 'without covers']} />,
                        filter: (node) => {
                            const dontShowCovers = filter !== 'all pages' && hasCustomCover(sitemap, node.id)
                            return !dontShowCovers
                        }
                    }
                }}
            />
        </Box>
    )
}

const hasCustomCover = (sitemap, pageId) => {
    const covers = sitemap?.covers.pages[pageId]
    return !isEmpty(covers?.devices) && !isEmpty(covers.devices[sitemap?.covers.device]);
}