import { Select } from 'chakra-react-select'
import { useGetFilesData } from '../../../../../hooks';
import { Stack, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { changeLinkedSitemap } from '../../../../../store/actions/flow-actions';
import { compact } from 'lodash'

export const SelectSitemap = ({ flow, forNew, onChange, menuPortalTarget }) => {
    const files = useGetFilesData()
    const dispatch = useDispatch()
    const options = [...files].filter(f => f.collection === 'sitemaps' && f.member && f.id !== flow?.sitemap).map(f => { return { label: f?.name, value: f?.id } })
    return (
        <Stack mt={3} spacing={3}>
            {!forNew && <Text fontSize="xs" fontWeight="medium">Link to a sitemap</Text>}
            <Select
                menuPortalTarget={menuPortalTarget || null}
                components={{ DropdownIndicator: null }}
                size={forNew ? "md" : "sm"}
                styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 1 }) }}
                chakraStyles={chakraStyles}
                placeholder='Select a sitemap...'
                noOptionsMessage={() => "No sitemaps found..."}
                options={compact([!forNew ? { label: "None", value: null } : null, ...options])}
                onChange={(e) => {
                    if (forNew) {
                        if (onChange) onChange(e.value)
                    } else {
                        dispatch(changeLinkedSitemap(e.value))
                    }
                }}
            >
            </Select>
        </Stack>
    )
}

const chakraStyles = {
    control: (prev, { selectProps }) => ({
        ...prev,
        borderRadius: "3px",
    }),
    container: (prev, { selectProps }) => ({
        ...prev,
        cursor: "text",
        bgColor: "rarchy-bg-white",
    }),
    menuList: base => ({
        ...base,
        // maxHeight: "110px" // your desired height
    }),
    input: (prev, { selectProps }) => ({
        ...prev,
        minHeight: 25,
        cursor: "text",
        fontSize: "sm",
        mx: 1,
    }),
    placeholder: (prev, { selectProps }) => ({
        ...prev,
        pl: 1,
        fontSize: "sm"
    }),
    option: (prev, { selectProps }) => ({
        ...prev,
        fontSize: "sm",
        padding: 2.5,
        paddingInline: 4
    }),
    valueContainer: (prev, { selectProps }) => ({
        ...prev,
        pl: 2,
    }),
    noOptionsMessage: (prev, { selectProps }) => ({
        ...prev,
        px: 4,
        py: 2,
        textAlign: 'left',
        fontSize: "sm",
        color: "gray.500"
    })
};