import { store } from '../../../../../../store';
import { updatePageSectionsData } from '../../../../../../store/actions/sitemap-actions';
import { ascending } from 'd3';

var lastRearrangeY = 0;

export const reorderPageSections = (d, sections) => {
    var sectionData = sections ? sections[d.node.id] ? sections[d.node.id] : [] : [];
    sectionData.forEach((s, i) => {
        s.index = i; // ensure this is sorted before continuing so we don't run into any errors where the indexes are out of whack
        if (d.section.id !== s.id) {
            if (d.section.dy) {
                var direction = d.section.dy === lastRearrangeY ? undefined : d.section.dy < lastRearrangeY ? 'up' : 'down';
                if (direction) {
                    var inRange;
                    if (direction === 'down') {
                        const bottomOfSectionFromY = d.section.dy + (d.section.height / 2);
                        inRange = bottomOfSectionFromY.between(s.y + (s.height / 2), s.bottomOfSectionFromY - (s.height / 3));
                    } else if (direction === 'up') {
                        const topOfSectionFromY = d.section.dy - (d.section.height / 2);
                        inRange = topOfSectionFromY.between(s.y + (s.height / 3), s.bottomOfSectionFromY - (s.height / 2));
                    };
                    if (inRange) {
                        /*** render immediately ***/
                        sectionData.move(d.section.index, i);
                        sectionData.forEach((section, i) => { section.index = i; }); // set new indexes
                        sectionData.sort((a, b) => ascending(a.index, b.index)); // re-sort before update
                        // dispatch
                        store.dispatch(updatePageSectionsData(sections));
                        /*** render immediately ***/
                    }
                };
            }
        }
    });
    lastRearrangeY = d.section.dy;
}