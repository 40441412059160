import { Button, DarkMode, Icon, Image, Stack, Text } from "@chakra-ui/react";
import { useHasFolders, useInOrganization, useInProjectsGridView } from "../../../../../hooks"
import { useDispatch } from "react-redux";
import { toggleNewFolderModal } from "../../../../../store/actions/folders-actions";
import { AddIcon } from "@chakra-ui/icons";
import { BsFolderFill } from "react-icons/bs";
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper";

export const EmptyFolders = ({ inSideMenu }) => {
    const hasFolders = useHasFolders()
    const inOrganization = useInOrganization()
    const dispatch = useDispatch()
    const inGridView = useInProjectsGridView()
    if (!inGridView || !inOrganization || inOrganization && hasFolders) return null;
    const element = (
        <Stack w={inSideMenu ? "full" : "2xs"} mt={3} spacing={5} fontSize="sm" align="center">
            <SkeletonWrapper w="fit-content">
                <Stack direction="row" align="center" pl={3} spacing={4}>
                    <Icon as={BsFolderFill} color="rarchy-badge-icon" fontSize={`${inSideMenu ? 4 : 5}xl`} />
                    <Text textAlign={inSideMenu ? "start" : "start"} color="fg.default">Create folders to better organise your content</Text>
                </Stack>
            </SkeletonWrapper>
            <SkeletonWrapper>
                <Button
                    w="full"
                    py={2.5}
                    fontSize="sm"
                    iconSpacing={3}
                    leftIcon={<Icon as={AddIcon} fontSize="md" />}
                    onClick={() => { dispatch(toggleNewFolderModal()) }}
                >
                    Create folder
                </Button>
            </SkeletonWrapper>
        </Stack>
    )
    return inSideMenu ? <DarkMode>{element}</DarkMode> : element
}