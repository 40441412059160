import { AddIcon } from "@chakra-ui/icons"
import { Alert, Button, Input, Stack } from "@chakra-ui/react"
import { useState } from "react"
import { useGetFoldersByKeyOrPath, useGetUserId } from "../../../../../../hooks"
import { Formik } from "formik"
import { useShouldDisableNew } from "./hooks"
import { saveNewFolderInFirestore } from "../../../../../Projects/Folders/Components/New/helpers"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { showMoveToFolderToast } from "./helpers"
import { useMoveToFolderContext } from "../../../../../Projects/Folders/hooks"
import { isEmpty } from 'lodash'

export const CreateNewButton = () => {
    const { file, folder, showDetailScreen, setShowCreateNewScreen } = useMoveToFolderContext()
    const { inFolder } = showDetailScreen
    const item = file || folder
    const isDisabled = useShouldDisableNew({ item, folder, folderId: inFolder })
    return (
        <Button
            isDisabled={isDisabled}
            size="sm"
            variant="unstyled"
            iconSpacing={1.5}
            px={1.5}
            leftIcon={<AddIcon fontSize="10px" />}
            color="fg.muted"
            onClick={setShowCreateNewScreen.on}
        >
            Create new
        </Button>
    )
}

export const CreateNewScreen = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const userId = useGetUserId()
    const [isCreating, setIsCreating] = useState(false)
    const { file, folder, showDetailScreen, onClose } = useMoveToFolderContext()
    const { inFolder } = showDetailScreen
    const isYourProjects = inFolder === 'projects/your-projects'
    const noFolder = (!inFolder || isYourProjects ? true : false)
    const inFolderData = (useGetFoldersByKeyOrPath({ key: "id", folderId: inFolder })?.[0] || {})
    const item = file || folder
    return (
        <Formik
            initialValues={{ name: '' }}
            onSubmit={async (values, actions) => {
                try {
                    const { name } = values
                    if (!name) return
                    setIsCreating(true)
                    // save new folder
                    const parentFolderId = !noFolder ? inFolder : null
                    const { folderId } = await saveNewFolderInFirestore({ name, membersToAdd: folder?.members, parentFolderId, dispatch })
                    // save item to new folder
                    const oldFolderId = item?.team
                    // await moveToFolder({ file, folder, folderId, dispatch })
                    // post-save
                    onClose()
                    if (folderId) history.push(`folder/${folderId}`)
                    showMoveToFolderToast({ file, folder, oldFolderId, dispatch })
                } catch (e) {
                    console.error(e)
                } finally {
                    setIsCreating(false)
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => {
                const showAlert = !noFolder && inFolderData?.name
                return (
                    <form onSubmit={handleSubmit}>
                        <Stack borderTop={`1px solid`} borderColor="rarchy-border" p={4} spacing={4}>
                            <Input placeholder="Name new folder" fontSize="sm" name="name" onChange={handleChange} />
                            {showAlert && (
                                <Alert fontWeight="normal">
                                    {`You are creating a new folder in "${inFolderData?.name}"`}
                                </Alert>
                            )}
                            <Button colorScheme="blue" isDisabled={isEmpty(values?.name)} isLoading={isCreating} fontSize="sm" onClick={handleSubmit}>Add to new folder</Button>
                        </Stack>
                    </form>
                )
            }}
        </Formik>
    )
}