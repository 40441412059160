import {
    IconButton,
    useColorModeValue as mode
} from "@chakra-ui/react"
import React, { useState } from 'react';
import { useCanEditFile, useGetSitemap, useInUserFlow } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';

import { IoClose } from 'react-icons/io5'
import { handleDeleteUserFlowSymbol } from '../../user-flows/utils/Symbols/delete';
import { remove } from '../../utils/edit';
import { shouldShowCovers } from '../canvas/utils/helpers';
import { showEditorPermissionsToast } from '../../../Editor/App/Components/PermissionsToast';
import { togglePageButtons } from '../../../../store/actions/sitemap-actions';
import { transform } from '../canvas/utils/zoom';

export const Delete = ({ d, x, y }) => {

    const dispatch = useDispatch()

    const canEdit = useCanEditFile()
    const sitemap = useGetSitemap()
    const [buttonWidthMultiplier, setButtonWidthMultiplier] = useState(1)
    const inUserFlow = useInUserFlow()
    const showCovers = shouldShowCovers(d);
    const targetButtonWidth = Math.ceil((showCovers ? 28 : 24) * transform.k)
    const buttonWidth = ((targetButtonWidth % 2 === 0) ? targetButtonWidth : targetButtonWidth + 1) * buttonWidthMultiplier
    
    if (!canEdit) return null;

    return (d.parent || inUserFlow) ? (
        <IconButton
            color={mode("white", "gray.800")}
            icon={renderIcon(d, sitemap, buttonWidth, buttonWidthMultiplier)}
            h={`${buttonWidth}px`}
            minW={`${buttonWidth}px`}
            left={getLeft(x, buttonWidth)}
            top={getTop(y, buttonWidth)}
            bgColor="red.400"
            outline={`${2 * transform.k}px solid`}
            outlineColor={mode("white", "gray.800")}
            _hover={{ bgColor: "red.500" }}
            _active={{ bgColor: "red.500" }}
            rounded="full"
            pos="absolute"
            title={`Delete ${d.children || d._children ? 'Pages' : 'Page'}`}
            onClick={(e) => {
                e.stopPropagation()
                !inUserFlow ? remove(d) : handleDeleteUserFlowSymbol([d])
                dispatch(togglePageButtons({ showing: false }))
            }}
            onMouseEnter={() => setButtonWidthMultiplier(1.1)}
            onMouseLeave={() => setButtonWidthMultiplier(1)}
            paddingInlineStart={0}
            paddingInlineEnd={0}
        />
    ) : null
}

const getLeft = (x, buttonWidth) => {
    return Math.floor(x - (buttonWidth / 2) + (5 * transform.k))
}

const getTop = (y, buttonWidth) => {
    return Math.ceil(y - ((-2.5) * transform.k) - (buttonWidth / 2))
}

const renderIcon = (d, sitemap, buttonWidth, buttonWidthMultiplier) => {
    const targetFontSize = Math.floor(buttonWidth * 0.75)
    const fontSize = (targetFontSize % 2 === 0) ? targetFontSize : targetFontSize + 1;
    return <IoClose fontSize={fontSize * buttonWidthMultiplier} />
}