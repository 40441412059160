import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItemOption,
    MenuOptionGroup,
    Button,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useGetUser } from '../../../../../hooks'

export const UseCases = ({ onChange, forAccount }) => {
    const { primary_use_case } = useGetUser()?.selfReported || {}
    const [selectedOption, setSelectedOption] = useState(data.find(d => d.value === primary_use_case) || { label: 'Select your main reason for signing up...' })
    return (
        <Menu minW="full" matchWidth>
            <MenuButton as={Button} fontSize="sm" variant={forAccount && "outline"} rightIcon={<ChevronDownIcon />} minW="full" fontWeight="normal" textAlign="left">
                {selectedOption?.label}
            </MenuButton>
            <MenuList>
                <MenuOptionGroup type='radio' value={selectedOption?.value}>
                    {data.map(option => {
                        return <MenuItemOption fontSize="sm" key={option.value} value={option.value} onClick={() => {
                            setSelectedOption(option)
                            onChange(option.value)
                        }}>{option.label}</MenuItemOption>
                    })}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    )
}

const data = [
    {
        label: "Planning a new website", value: "plan-new-website"
    },
    {
        label: "Planning changes to an existing website", value: "plan-existing-website-changes"
    },
    {
        label: "Planning a website migration", value: "plan-website-migration"
    },
    {
        label: "Creating / exporting a sitemap", value: "create-export-sitemap"
    },
    {
        label: "Designing customer / user journeys", value: "design-customer-journeys"
    },
    {
        label: "Analyzing competitors' websites", value: "analyze-competitors"
    },
    {
        label: "Other", value: "other"
    }
]