import { CheckIcon } from "@chakra-ui/icons"
import { Box, HStack, ListItem } from "@chakra-ui/react"

export { UnlimitedFiles as UnlimitedFilesListItem } from "./UnlimitedFiles"
export { CollaborateWithOthers as CollaborateWithOthersListItem } from "./CollaborateWithOthers"
export { UnlimitedPagesPlusImport as UnlimitedPagesPlusImportListItem } from "./UnlimitedPagesPlusImport"
export { Screenshots as ScreenshotsListItem } from "./Screenshots"
export { ScreenshotsPageColorsAndMore as ScreenshotsPageColorsAndMoreListItem } from "./ScreenshotsPageColorsAndMore"
export { InviteTeam as InviteTeamListItem } from "./InviteTeam"
export { FolderPermissions as FolderPermissionsListItem } from "./FolderPermissions"
export { RestoreFiles as RestoreFilesListItem } from "./RestoreFiles"
export { PageSections as PageSectionsListItem } from "./PageSections"

export const ChecklistItem = ({ forConfirmed, children }) => {
    return (
        <ListItem
            marginInlineStart={forConfirmed ? -3 : "unset"}
            listStyleType={forConfirmed ? "none" : "unset"}
            fontSize={forConfirmed ? "sm" : "unset"}
        >
            <HStack spacing={2.5} align="start">
                {forConfirmed && <CheckIcon mt={1} color="rarchy-green" />}
                <Box>{children}</Box>
            </HStack>
        </ListItem>
    )
}