import React from 'react';
import {
    Box,
    Button,
    Spinner,
} from "@chakra-ui/react"
import { FiCamera } from 'react-icons/fi';
import { useInOrganization } from '../../../../../hooks';
import { toggleUpgradeModal } from '../../../../../store/actions/ui-actions';
import { useDispatch } from 'react-redux';


export const TriggerButton = ({ onOpen, isAutocapturing }) => {
    const dispatch = useDispatch()
    const inOrganization = useInOrganization()
    return (
        <Box cursor="pointer" bgColor="rarchy-bg-sitemap-editor" rounded="md">
            <Button
                variant="outline"
                size="sm"
                iconSpacing={3}
                lineHeight="inherit"
                leftIcon={!isAutocapturing ? <FiCamera /> : <Spinner size="xs" />}
                onClick={onOpen}//() => { inOrganization ? onOpen() : dispatch(toggleUpgradeModal({ showing: true })) }}
                color="rarchy-color-sitemap-editor"
                borderColor="rarchy-border-sitemap-editor"
                // borderWidth={0}
                bgColor="rarchy-bg-sitemap-editor"
            >
                {!isAutocapturing ? 'Screenshots' : 'Capturing'}
            </Button>
        </Box>
    )
}

//`Captur${!isAutocapturing ? 'e' : 'ing'}