import { Box, HStack, Link, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { getEditorDocIdFromPath } from '../../../../../helpers';
import { isEmpty } from 'lodash'
import { useGetFilesData } from '../../../../../hooks';

export const LinkedUserFlows = ({ sitemap }) => {
    const editorDocId = getEditorDocIdFromPath()
    const files = useGetFilesData()?.filter(f => f?.id !== editorDocId && f?.collection === 'user-flows' && f?.sitemap === sitemap?.id)
    return (
        <Stack mt={3} spacing={3}>
            <Text fontSize="xs" fontWeight="medium">Other user flows linked to this sitemap:</Text>
            {isEmpty(files) && <Text fontSize="13px" color="fg.muted">-</Text>}
            {!isEmpty(files) && (
                <VStack maxH="30vh" overflow="scroll">
                    {[...files]?.map(f => {
                        return (
                            <HStack key={f?.name} w="full">
                                <ExternalLinkIcon fontSize="13px" color="rarchy-link" />
                                <Tooltip label={f?.name} hasArrow openDelay={500} variant="rounded-sm">
                                    <Box noOfLines={1}>
                                        <Link fontSize="sm" fontWeight="medium" color="rarchy-link" href={f?.link} isExternal>
                                            {f?.name}
                                        </Link>
                                    </Box>
                                </Tooltip>
                            </HStack>
                        )
                    })}
                </VStack>
            )}
        </Stack>
    )
}