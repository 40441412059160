
import { store } from '../../../../store';

import { expandOnClick, collapse } from '../../utils/app';
import { mainCanvas, pickerCanvas, renderInteractionsCanvas } from '../canvas/canvases';
import { transform } from '../canvas/utils/zoom';
import { getPageColor, getRandomColor } from '../canvas/utils/helpers';

import { getPalletteColors } from '../../../../store/actions/editor-actions';
import { getSitemap } from '../../../../helpers';
import { forceCollide, forceLink, forceManyBody, forceSimulation, forceX, forceY, event } from 'd3';
import { isEmpty } from 'lodash'

var node;

var nodes = [];
var links = [];

export var simulation;

export var dragging;

const colors = {};
const colorByNodeIds = {};

var pageColors = {};
var palletteColors = {};

export const load = (root, opts) => {

  const { sitemap } = store.getState()

  const { noSimulation } = opts ? opts : {};

  //
  const { nodes: n, links: l } = flatten(root);

  if (n) nodes = n;
  if (l) links = l;

  /* const N = 300;
  var gData = {
    nodes: [...Array(N).keys()].map(i => ({ id: i })),
    links: [...Array(N).keys()]
      .filter(id => id)
      .map(id => ({
        source: id,
        target: Math.round(Math.random() * (id - 1))
      }))
  }; */

  // console.log(gData);

  // console.log({ nodes, links });

  // gData = { nodes, links };

  /* const Graph = ForceGraph()
    (document.getElementById('container'))
    .graphData(gData); */

  // graph config
  /* const graph = ForceGraph()
    .backgroundColor('#101020')
    .linkColor(() => 'rgba(255,0,0,0.1)')
    .dagMode('radialout')
    .dagLevelDistance(300)
    //.nodeId('package')
    /* .linkCurvature(d =>
      0.07 * // max curvature
      // curve outwards from source, using gradual straightening within a margin of a few px
      (['td', 'bu'].includes(graph.dagMode())
        ? Math.max(-1, Math.min(1, (d.source.x - d.target.x) / 25)) :
        ['lr', 'rl'].includes(graph.dagMode())
          ? Math.max(-1, Math.min(1, (d.target.y - d.source.y) / 25))
          : ['radialout', 'radialin'].includes(graph.dagMode()) ? 0 : 1
      )
    ) */
    //.linkDirectionalParticles(2)
    //.linkDirectionalParticleWidth(3)
    /* .nodeCanvasObject((node, ctx) => {
      const label = node.package;
      const fontSize = 15;
      ctx.font = `${fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(label).width;
      const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

      ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
      ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'lightsteelblue';
      ctx.fillText(label, node.x, node.y);

      node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
    }) */
    /* .nodePointerAreaPaint((node, color, ctx) => {
      ctx.fillStyle = color;
      const bckgDimensions = node.__bckgDimensions;
      bckgDimensions && ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
    })
    .d3Force('collide', d3.forceCollide(13))
    .d3AlphaDecay(0.02)
    .d3VelocityDecay(0.3);

  graph(document.getElementById('container')).graphData(gData);

  return; */

  pageColors = {};
  palletteColors = {};

  /* var render = renderQueue(renderData).clear(clear_canvas);

  var nodes = generateNodes(NUM_NODES);
  var links = generateLinks(nodes, NUM_EDGES);

  var data = FDL(nodes, links);

  render(data); */

  if (!noSimulation) {
    simulation = forceSimulation(nodes);
    simulation
      .force('charge', forceManyBody().strength(-250))
      .force('line', forceLink(links))//.distance(35).strength(-50))
      .force('x', forceX())
      .force('y', forceY())
      .force('collide', forceCollide().radius(0.1))
      .on('tick', function () {
        if (sitemap?.format === 'nodes' && simulation.alpha() > 0.015) {
          updateNodes();
        } else {
          simulation.stop();
        }
      });
  }

};

export const updateNodes = (pdf) => {
  const sitemap = getSitemap();
  if (sitemap?.format !== 'nodes') {
    simulation.stop();
  } else {
    if (!pdf) {
      draw(pickerCanvas, { hidden: true });
      draw(mainCanvas, {});
    } else {
      draw(pdf, { pdf: true });
    };
  }
};

function draw(context, opts) {

  if (!context) return;

  const { hidden, pdf } = opts;

  context.save();
  if (!pdf) context.clear();

  context.translate(transform.x, transform.y);
  context.scale(transform.k, transform.k);

  if (!hidden) {
    links.forEach(function (d) {
      if (d.overflow && isEmpty(d.overflow)) return;
      const pageColor = pageColors[d.target.id] ? pageColors[d.target.id] : getPageColor({ id: d.target.id })
      if (!pageColors[d.target.id]) pageColors[d.target.id] = pageColor;
      context.beginPath();
      context.moveTo(d.source.x, d.source.y);
      context.lineTo(d.target.x, d.target.y);
      context.strokeStyle = pageColor;
      context.stroke();
    });
  }

  // Draw the nodes
  nodes.forEach(function (d, i) {

    if (d.overflow && isEmpty(d.overflow)) return;

    const pageColor = pageColors[d.id] ? pageColors[d.id] : getPageColor(d)
    if (!pageColors[d.id]) pageColors[d.id] = pageColor;

    /*** assign event colors ***/
    var randomColor;
    if (hidden) {
      if (!colorByNodeIds[d.id]) {
        /*** assign new random color to node id ***/
        randomColor = getRandomColor();
        colorByNodeIds[d.id] = randomColor;
        colors[randomColor] = d;
        /*** assign new random color to node id ***/
      } else {
        randomColor = colorByNodeIds[d.id]; // use existing assigned color
      }
    };
    /*** assign event colors ***/

    context.beginPath();
    context.arc(d.x, d.y, getRadius(d), 0, 2 * Math.PI, true);
    context.strokeStyle = !hidden ? pageColor : randomColor;
    context.lineWidth = !pdf ? 2 : 1;
    context.stroke();
    const fillColor = palletteColors[d.id] ? palletteColors[d.id] : getPalletteColors(pageColor)[0]
    if (!palletteColors[d.id]) palletteColors[d.id] = fillColor;
    context.fillStyle = !hidden ? fillColor : randomColor;
    context.fill();

    /*** child nodes ***/
    if (!hidden && d.parent) {
      if (d._children) {
        context.beginPath();
        context.arc(d.x, d.y, 5.5, 0, 2 * Math.PI, true);
        context.globalAlpha = 0.66;
        context.strokeStyle = pageColor;
        context.lineWidth = 0.66;
        context.stroke();
        context.globalAlpha = 1;
        context.fillStyle = 'transparent';
        context.fill();
      }
    }
    /*** child nodes ***/


  });

  context.restore();

}

function getRadius(d) {
  if (d.overflow) return 8;
  return d._children || d.children ? 8 : 5;
}

export const mouseoverNodesNode = (colKey) => {
  var node = colors[colKey];
  if (node) return nodes.find(d => d.id === node.id);
}

export function clickNodesNode(d) {
  if (event.defaultPrevented || !d.parent) return;
  if (d.children) {
    collapse(d);
    simulation.restart();
  } else if (d._children) {
    expandOnClick(d);
    simulation.restart();
  }
}

export function dragsubject(event) {
  var i,
    x = transform.invertX(event.x),
    y = transform.invertY(event.y),
    dx,
    dy;
  for (i = nodes.length - 1; i >= 0; --i) {
    node = nodes[i];
    dx = x - node.x;
    dy = y - node.y;

    var radius = getRadius(node);

    if (dx * dx + dy * dy < radius * radius) {

      node.x = transform.applyX(node.x);
      node.y = transform.applyY(node.y);

      return node;
    }
  }
}

// Dragging Nodes
export function dragstarted(event) {
  dragging = true;
  if (!event.active) simulation.alphaTarget(0.25).restart();
  event.subject.fx = transform.invertX(event.x);
  event.subject.fy = transform.invertY(event.y);
  renderInteractionsCanvas();
}

export function dragged(event) {
  event.subject.fx = transform.invertX(event.x);
  event.subject.fy = transform.invertY(event.y);
}

export function dragended(event) {
  if (!event.active) simulation.alphaTarget(0);
  event.subject.fx = null;
  event.subject.fy = null;
  dragging = false;
};

const flatten = (root) => {
  const nodes = root.descendants();
  const links = root.links();
  return { nodes, links };
};
