import { store } from '../../../../../../store';
import { updatePageSectionsData, addPageSectionChange, togglePageSectionsOptionsPopover } from '../../../../../../store/actions/sitemap-actions';
import { getSitemap } from '../../../../../../helpers';
import { getPageId } from '../helpers';
import { cloneDeep } from 'lodash'

export const deletePageSection = ({ node, section }) => {

    /*** render immediately ***/
    const sitemap = getSitemap();
    
    var sections = cloneDeep(sitemap?.data.page_sections );
    // node id
    const pageId = getPageId(node);
    /*** remove section ***/
    sections[pageId] = sections[pageId].filter(s => s.id.toString() !== section.id.toString());
    /*** remove section ***/
    // dispatch
    store.dispatch(updatePageSectionsData(sections));
    // hide options popover
    store.dispatch(togglePageSectionsOptionsPopover({ showing: false }));
    /*** render immediately ***/

    const pageSectionChange = {
        id: new Date().getTime(),
        data: [{ action: 'page-section-remove', pageId, section }],
    };
    const history = {
        action: 'page-section-remove',
        data: { pageId, section },
    };

    // add change to autosave
    store.dispatch(addPageSectionChange({ change: pageSectionChange, history }));
}