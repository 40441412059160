import * as CryptoJS from 'crypto-js';
import { isNull } from 'lodash'

export const getSettingsFromStorage = (keys, settings, setSettings, sitemapId) => {
    const newSettings = { ...settings }
    keys.forEach((key) => {
        var localStorageValue = localStorage.getItem(`${sitemapId}#${key}`);
        if (key === 'fullpage' && !localStorageValue) localStorageValue = 'true'; // ensure fullpage is default if not set
        localStorageValue = localStorageValue === 'true' ? true : localStorageValue === 'false' ? false : localStorageValue;
        newSettings[key === 'username' ? 'httpAuth' : key] = key === 'username' ? { username: localStorageValue, password: "" } : !isNull(localStorageValue) ? localStorageValue : ['hidden', 'username', 'password'].includes(key) ? '' : false
    });
    setSettings(newSettings)
}

export const onSettingsChange = (key, e, settings, setSettings, sitemapId) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    e.persist();
    if (key !== "username" && key !== "password") {
        setSettings({ ...settings, [key]: value })
    } else {
        if (!settings.httpAuth) settings.httpAuth = {};
        setSettings({ ...settings, httpAuth: { ...settings.httpAuth, [key]: key === 'password' ? encryptPass(value) : !isNull(value) ? value : !['hidden', 'username', 'password'].includes(key) ? '' : false } })
    }
    if (key !== "password") localStorage.setItem(`${sitemapId}#${key}`, value);
}

export const encryptPass = (pass) => {
    var encrypted;
    /*** password encryption ***/
    if (pass !== "") {
        encrypted = CryptoJS.AES.encrypt(pass, '199019922095');
        encrypted = encrypted.toString();
    } else {
        encrypted = null;
    }
    /*** password encryption ***/
    return encrypted
}

export const getCapturing = ({ pages, page, device }) => {
    if (!pages[page]) return;
    if (!pages[page].devices) return;
    if (!pages[page].devices[device]) return;
    if (pages[page].devices[device].token === 'capturing') return pages[page].devices[device].startedAt;
};