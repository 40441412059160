import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
    Box,
    Divider,
    HStack,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { Component, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Add } from './Add';
import { AiOutlineEdit } from 'react-icons/ai'
import { Delete } from './Delete';
import { DownUp } from './DownUp';
import { Link } from 'react-router-dom';
import { Rename } from './Rename';
import { clickWebsiteSection } from '../index';

/*** popover ****/
export const WebsiteSectionOptionsPopover = () => {
    const { sitemap, user } = useSelector(state => state);
    /* componentDidUpdate(prevProps) {
        const { showUpgradeMessaging } = this.props?.sitemap?.ui.PageSectionsOptionsPopover;
        const { showUpgradeMessaging: prevShowUpgradeMessaging } = prevProps.sitemap?.ui.PageSectionsOptionsPopover;
        // just shown upgrade messaging
        if (showUpgradeMessaging && !prevShowUpgradeMessaging) sendDataLayerEventForPageSections('Upgrade');
    } */
    const { WebsiteSectionOptionsPopover } = sitemap?.ui;
    const { offset, renaming } = WebsiteSectionOptionsPopover;
    const { website_sections } = sitemap?.data;

    const props = { WebsiteSectionOptionsPopover, website_sections, sitemap, user };
    return (
        <>
            <Popover
                isOpen
                offset={offset}
                placement="top"
            >
                <PopoverTrigger><Box display="none" /></PopoverTrigger>
                <PopoverContent w="170px" borderColor={mode("gray.300", "whiteAlpha.400")} onClick={(e) => e.stopPropagation()}>
                    <PopoverBody p={1}>
                        <HStack spacing={0}>
                            {!renaming && (
                                <>
                                    <Add {...props} />
                                    <IconButton
                                        size="sm"
                                        variant="ghost"
                                        icon={<AiOutlineEdit fontSize="15px" />}
                                        onClick={() => {
                                            clickWebsiteSection(WebsiteSectionOptionsPopover.section, sitemap)
                                        }}
                                    />
                                    <DownUp {...props} />
                                    <Delete {...props} />
                                </>
                            )}
                            {renaming && <Rename {...props} />}
                        </HStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
};

/* export const sendDataLayerEventForPageSections = (action, label) => {
    const obj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Page Section: ${action}`, label } };
    window.dataLayer.push({
        event: 'generic_ga_event',
        ...obj
    });
}; */
/*** popover ****/