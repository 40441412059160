import { Box, IconButton, useColorModeValue as mode, useRadio } from "@chakra-ui/react"
import * as React from 'react'

export const ToggleButton = (props) => {
    const { radioProps, isRadio, draggable, onDragStart, px, ...rest } = props
    const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(radioProps)
    const inputProps = getInputProps()
    const checkboxProps = getCheckboxProps()
    const labelProps = getLabelProps()
    const bgColor = "rarchy-bg-sitemap-editor"
    return (
        <Box as="label" cursor="pointer" {...labelProps} rounded="md">
            <input {...inputProps} />
            <IconButton
                as="div"
                color="rarchy-color-sitemap-editor"
                borderColor="rarchy-border-sitemap-editor"
                borderWidth={0}
                bg={bgColor}
                px={px || "unset"}
                _active={{ bg: bgColor }}
                _checked={{
                    color: mode('gray.700', 'whiteAlpha.900'),
                    bg: isRadio ? "rarchy-border-sitemap-editor" : bgColor,
                }}
                {...checkboxProps}
                {...rest}
            />
        </Box>
    )
}