import {
    Box,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuOptionGroup,
    Portal,
    Stack
} from '@chakra-ui/react'

import { CopyLinkMenuItem } from '../../../../Components/Shared/CopyLinkMenuItem'
import { DeleteFolder } from './Handlers/Delete'
import { FolderFileIconButton } from '../../../../Components/Shared/FolderFileIconButton'
import { FolderName } from '../../Folder/Name'
import { MoveToFolderHOC } from '../../../../Components/Shared/MoveToFolder/Components/MoveToFolderHOC'
import { MoveToFolderMenuItem } from '../../../../Components/Shared/MoveToFolder/Components/MoveToFolderMenuItem'
import { MoveToFolderScreen } from '../../../../Components/Shared/MoveToFolder'
import { RemoveFromFolderMenuItem } from '../../../../Components/Shared/RemoveFromFolderMenuItem'
import { RiUserShared2Line } from 'react-icons/ri'
import { StarredFolder } from './Handlers/Starred'
import { toggleShareFolderModal } from '../../../../../store/actions/folders-actions'
import { useDispatch } from 'react-redux'

export const FONT_SIZE = "sm"
export const ICON_SIZE = "xl"
export const PY = 2.5

export const FolderMenu = ({ folder, onOpen, onClose, opts = {} }) => {
    const dispatch = useDispatch()
    const { forFolderTopbar } = opts
    return (
        <Menu
            placement="right"
            autoSelect={false}
            isLazy
            closeOnSelect={false}
            onOpen={onOpen}
            onClose={() => { if (onClose) onClose() }}
        >
            {({ onClose }) => (
                <>
                    <FolderFileIconButton as={MenuButton} opts={{ forFolderTopbar }} />
                    <MoveToFolderHOC folder={folder} onClose={onClose}>
                        {({ showMoveToFolderScreen }) => (
                            <Portal>
                                <MenuList w="300px" boxShadow="xl" pb={showMoveToFolderScreen ? 0 : 2} fontSize={FONT_SIZE} onClick={e => e.stopPropagation()}>
                                    {!showMoveToFolderScreen && (
                                        <>
                                            <Stack>
                                                <MenuOptionGroup
                                                    as={Box}
                                                    title={
                                                        <Stack my={0} spacing={1} fontWeight="normal">
                                                            <FolderName folder={folder} opts={{ fontSize: "md", w: "full" }} />
                                                        </Stack>
                                                    }
                                                    type='radio'
                                                >
                                                </MenuOptionGroup>
                                            </Stack>
                                            <MenuDivider />
                                            <StarredFolder folderId={folder?.id} />
                                            <MoveToFolderMenuItem folder={folder} onClose={onClose} />
                                            <MenuItem
                                                py={PY}
                                                icon={<Icon as={RiUserShared2Line} fontSize={ICON_SIZE} />}
                                                onClick={() => { onClose(); dispatch(toggleShareFolderModal(folder)) }}
                                            >
                                                Share
                                            </MenuItem>
                                            <CopyLinkMenuItem folder={folder} onClose={onClose} />
                                            <DeleteFolder folder={folder} />
                                            <RemoveFromFolderMenuItem folder={folder} />
                                        </>
                                    )}
                                    {showMoveToFolderScreen && <MoveToFolderScreen />}
                                </MenuList>
                            </Portal>
                        )}
                    </MoveToFolderHOC>
                </>
            )}
        </Menu>
    )
}