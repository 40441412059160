import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai'
import { Box, Center, HStack, Text } from '@chakra-ui/react'
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import { center, zoomIn, zoomOut } from './utils/zoom'
import { getEditor, getSitemap } from '../../../../helpers'
import { useGetEditor, useGetRevisionHistoryDrawer, useGetSitemap, useInUserFlow, useIsEditorDocAvailable, useIsPageDrawerShowing, usePrevious } from '../../../../hooks'

import { BiTargetLock } from 'react-icons/bi'
import { ToggleButton } from '../../../Editor/Toolbar/Components/ToggleButton/Button'
import { ToggleButtonGroup } from '../../../Editor/Toolbar/Components/ToggleButton/ButtonGroup'
import queryString from 'query-string';
import { update } from '../../utils/app'
import { useHotkeys } from 'react-hotkeys-hook'

export const CanvasButtons = () => {

    const isEditorDocAvailable = useIsEditorDocAvailable();

    const [expand, setExpand] = useState(true);
    const sitemap = useGetSitemap()
    const editor = useGetEditor()
    const prevSitemap = usePrevious(sitemap)

    const inUserFlow = useInUserFlow()
    // zoom in canvas
    useHotkeys('ctrl+=, command+=, ctrl++, ctrl+shift+=, command++, command+shift+=', e => { e.preventDefault(); zoomIn(); })
    // zoom out canvas
    useHotkeys('ctrl+-, ctrl+shift+-, command+-, command+shift+-', e => { e.preventDefault(); zoomOut(); })

    useEffect(() => {
        if (!prevSitemap) return;
        // at least one child is visible
        if (prevSitemap?.data?.nodes?.length !== sitemap?.data?.nodes?.length) setExpand(!hasOpenChildren());
        // expand all if requested (on load)
        if (!prevSitemap?.loaded && sitemap?.loaded) {
            const { ea: expand } = queryString.parse(window.location.search);
            if (expand === "1") expandAll();
        }

    }, [sitemap, prevSitemap, expand]);

    const handleExpandCollapseOnClick = () => {
        let openChildren = hasOpenChildren();
        openChildren ? collapseAll() : expandAll();
    };

    const orientation = sitemap?.format === 'tree' ? `rotate(${90}deg)` : 'initial';

    return (

        <Box bottom={7} mr={7} position="sticky" float="right" id="canvas-buttons">
            <HStack spacing={2}>
                <ToggleButtonGroup
                    size="sm"
                    isAttached
                    variant="outline"
                >
                    {!inUserFlow && <ToggleButton fontSize="lg" transform={orientation} transition="none" icon={expand ? <BsArrowsExpand /> : <BsArrowsCollapse />} onClick={handleExpandCollapseOnClick} />}
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    size="sm"
                    isAttached
                    variant="outline"
                >
                    <ToggleButton fontSize="lg" icon={<BiTargetLock />} onClick={center} />
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    size="sm"
                    isAttached
                    variant="outline"
                >
                    <ToggleButton fontSize="lg" icon={<AiOutlineZoomOut />} onClick={zoomOut} />
                    <ToggleButton icon={<Center minW={12}><Text fontSize="xs">{`${editor?.utils?.zoomLevel}%`}</Text></Center>} onClick={center} />
                    <ToggleButton fontSize="lg" icon={<AiOutlineZoomIn />} onClick={zoomIn} />
                </ToggleButtonGroup>
            </HStack>
        </Box>
    );
};

const hasOpenChildren = () => {

    const sitemap = getSitemap()

    const editor = getEditor()
    const RevisionHistoryDrawer = editor?.ui || {}

    let openChildren = false;
    const data = (!RevisionHistoryDrawer.showing ? (sitemap?.data.section ? sitemap?.data.section : sitemap?.data.root) : RevisionHistoryDrawer.data.root) || {};
    data?.children?.forEach(c => {
        if (c.children) {
            openChildren = true;
            return;
        }
    })
    return openChildren;
};

const collapseAll = () => {

    const sitemap = getSitemap()

    const editor = getEditor()
    const RevisionHistoryDrawer = editor?.ui || {}

    function recurse(d) {
        if (!d) return;
        if (d.children) {
            if (d.parent) {
                localStorage.removeItem(`${sitemap?.id}#${d.id}`);
                d._children = d.children;
                for (var i = 0, len = d._children.length; i < len; i++) {
                    localStorage.removeItem(`${sitemap?.id}#${d._children[i].id}`);
                    recurse(d._children[i]);
                }
                d.children = null;
            } else {
                for (var j = 0, len1 = d.children.length; j < len1; j++) {
                    localStorage.removeItem(`${sitemap?.id}#${d.children[j].id}`);
                    recurse(d.children[j]);
                }
            }
        }
        d.website_sections?.forEach(r => recurse(r)); // also expand website sections
    }
    // recurse
    const rootToUse = !RevisionHistoryDrawer.showing ? !sitemap?.data.section ? sitemap?.data.root : sitemap?.data.section : RevisionHistoryDrawer.data.root;
    recurse(rootToUse);
    // center();
    update({ noTransition: true });
};

export const expandAll = () => {

    const sitemap = getSitemap()

    const editor = getEditor()
    const RevisionHistoryDrawer = editor?.ui || {}

    const numberOfPages = Object.keys(!RevisionHistoryDrawer.showing ? sitemap?.docs.pages : RevisionHistoryDrawer.docs.pages).length;
    function recurse(d) {
        if (!d) return;
        if (!d.parent) {
            if (d.children) {
                for (var i = 0, len = d.children.length; i < len; i++) {
                    if (numberOfPages <= 5000) {
                        if (sitemap?.id) {
                            localStorage.setItem(`${sitemap?.id}#${d.children[i].id}`, true);
                        }
                    }
                    recurse(d.children[i]);
                }
            }
        } else {
            if (d._children) {
                d.children = d._children;
                for (var j = 0, len1 = d.children.length; j < len1; j++) {
                    if (numberOfPages <= 5000) {
                        if (sitemap?.id) {
                            localStorage.setItem(`${sitemap?.id}#${d.children[j].id}`, true);
                        }
                    }
                    recurse(d.children[j]);
                }
                d._children = null;
            }
        }
        d.website_sections?.forEach(r => recurse(r)); // also expand website sections
    }
    // recurse
    const rootToUse = !RevisionHistoryDrawer.showing ? (!sitemap?.data.section ? sitemap?.data.root : sitemap?.data.section) : RevisionHistoryDrawer.data.root;
    recurse(rootToUse);
    // center();
    update({ noTransition: true });
};