import * as React from 'react'
import { Box, HStack, Divider, Text, DarkMode } from "@chakra-ui/react"
import { BsCaretRightFill } from 'react-icons/bs'
import { useLocation, Link } from "react-router-dom"

export const NavItem = React.forwardRef(function NavItem(props, ref) {
    const {
        isButton, pathname, isDisabled, icon, iconFontSize, children, label, startElement, endElement, fontWeight, divider, onClick, onMouseEnter, onMouseLeave, color, spacing, ...rest
    } = props;
    const isSelected = useIsSelected(pathname)
    const item = (
        <Box w="full" ref={ref} {...rest}>
            {divider && <Divider mt={1} mb={2} />}
            <DarkMode>
                    <HStack
                        w="full"
                        px="3"
                        pl="4"
                        py="2"
                        h="40px"
                        bg={isSelected ? "rarchy-bg-subtle-hover-200" : isButton ? "rarchy-bg-subtle-hover" : "unset"}
                        spacing={spacing || 2.5}
                        fontWeight={fontWeight ? fontWeight : isSelected ? "medium" : 'normal'}
                        cursor="pointer"
                        userSelect="none"
                        rounded="md"
                        transition="all 0.2s"
                        color={!isDisabled ? "fg.default" : "fg.subtle"}
                        _hover={{
                            bg: 'rarchy-bg-subtle-hover',
                            color: color || "whiteAlpha.900",
                        }}
                        onClick={(props) => { if (onClick) onClick(props) }}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        {startElement && !children && <Box>{startElement}</Box>}
                        {icon && (<Box lineHeight="1px" fontSize={iconFontSize || "2xl"} mr="3px">{icon}</Box>)}
                        <Text flex="1" w="90%" isTruncated>{label}</Text>
                        {endElement && !children && <Box>{endElement}</Box>}
                        {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
                    </HStack>
            </DarkMode>
        </Box>
    )
    if (pathname) return <Box as={Link} w="full" to={pathname}>{item}</Box>
    return item;
})

const useIsSelected = (pathname) => {
    const location = useLocation()
    return location?.pathname === pathname;
}