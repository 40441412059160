import { useDispatch } from "react-redux"
import { Box, Button, FormControl, FormErrorMessage, HStack, Heading, Input, Stack, Text, useBoolean, usePrevious } from "@chakra-ui/react"
import { useGetTotalNumberOfSeats, useGetUpgradeModal, useGetUser } from "../../../hooks"
import { Formik } from "formik"
import { validate } from 'email-validator'
import { wait } from "../../../../shared/helpers"
import { getFirestore, doc, setDoc } from "firebase/firestore/lite"
import { getEncodedEmail, sendHubspotCustomEvent } from "../../../helpers"
import { toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { CheckCircleIcon } from "@chakra-ui/icons"
import { useEffect, useState } from "react"
import { mergeOrganizationChange } from "../../../store/actions/organization-actions"
import { addUsersToPeople } from "../../Components/Shared/People/helpers"
import { compact, uniq, isEmpty } from 'lodash'

export const ConfirmedInviteTeam = () => {

    const dispatch = useDispatch()

    const [hasAddedMembers, setHasAddedMembers] = useBoolean(false)
    const [timeToClose, setTimeToClose] = useState(3)

    const { planId, stripeRedirectAttrs } = useGetUpgradeModal()

    const total_number_of_seats_available = useGetTotalNumberOfSeats({ planId }) - 1

    const user = useGetUser()

    const { orgId } = stripeRedirectAttrs || {}

    const closeModal = () => dispatch(toggleUpgradeModal({ showing: false }))

    // close modal 3000ms after success (with countdown)
    const prevHasAddedMembers = usePrevious(hasAddedMembers)
    useEffect(() => {
        // close modal after 3 seconds after returned success
        if (hasAddedMembers && !prevHasAddedMembers) setTimeout(() => closeModal(), 3000);
        // countdown
        let intervalId = setInterval(() => { if (hasAddedMembers) setTimeToClose(timeToClose - 1) }, 1000)
        // unmount - clear countdown
        return (() => clearInterval(intervalId))
    }, [hasAddedMembers, timeToClose])

    return (

        <Formik
            initialValues={{ membersToAdd: new Array(total_number_of_seats_available) }}
            onSubmit={async (values, { setErrors }) => {
                const { membersToAdd } = values
                const emails = compact(uniq(membersToAdd));
                // not adding users
                if (isEmpty(emails)) {
                    await wait(500)
                    closeModal();
                    return;
                }
                // adding users
                try {
                    const users = {}; emails.forEach(email => { users[getEncodedEmail(email)] = { role: 'user', invitedBy: user?.id, invitedAt: new Date(), } });
                    // save to firestore
                    const orgRef = doc(getFirestore(), 'organizations', orgId);
                    // if (import.meta.env.PROD) {
                    await setDoc(orgRef, { users, updatedBy: user?.id, updatedAt: new Date() }, { merge: true });
                    // }
                    // success - merge users, show confirmation and close modal
                    dispatch(mergeOrganizationChange({ users }))
                    // update in people
                    addUsersToPeople(users)
                    // show confirmation
                    setHasAddedMembers.on();
                    // send event to Hubspot
                    sendHubspotCustomEvent('invited_users', { number_of_users_invited: (Object.keys(users)?.length || 0), collection: 'organizations' })
                } catch (e) {
                    console.error(e);
                    setErrors({ firebaseError: e })
                }
            }}
            validate={(values) => {
                const errors = {};
                values?.membersToAdd.forEach((email, i) => {
                    if (email && !validate(email)) errors[i] = "Please enter a valid email address."
                    if (email && user?.email === email) errors[i] = "You can't add yourself as a member."
                })
                return errors;
            }}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                isSubmitting,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={9}>
                            <Stack spacing={6}>
                                <Heading fontSize="2xl">Add people to your team</Heading>
                                <Text fontSize="sm">
                                    {`You have ${total_number_of_seats_available} seats available - let’s add some people to your team...`}
                                </Text>
                            </Stack>
                            <Stack spacing={10}>
                                <Box maxH="270px" overflowY="scroll">
                                    <Stack spacing={3}>
                                        {[...values?.membersToAdd]?.map((email, i) => {
                                            const key = `email-${i}`
                                            return (
                                                <FormControl key={key} name={key} isInvalid={email && !!errors?.[i] && touched[i]}>
                                                    <Input
                                                        autoComplete="no-password"
                                                        value={email}
                                                        fontSize="sm"
                                                        boxShadow="none !important"
                                                        placeholder="Enter email address..."
                                                        isDisabled={isSubmitting || hasAddedMembers}
                                                        onChange={(e) => {
                                                            setFieldValue(`membersToAdd.${i}`, e.target.value)
                                                        }}
                                                        onBlur={() => setFieldTouched(i, true)}
                                                    />
                                                    <FormErrorMessage>{errors?.[i]}</FormErrorMessage>
                                                </FormControl>

                                            )
                                        })}
                                    </Stack>
                                </Box>
                                <Button
                                    colorScheme={hasAddedMembers ? "green" : "blue"}
                                    fontSize="sm"
                                    isLoading={isSubmitting}
                                    leftIcon={hasAddedMembers ? <CheckCircleIcon fontSize="lg" color="rarchy-green" /> : null}
                                    iconSpacing={4}
                                    onClick={() => { if (!hasAddedMembers) handleSubmit() }}
                                >
                                    {hasAddedMembers ? `Invites sent! Closing in ${timeToClose}...` : "Invite people to my team"}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                )
            }}
        </Formik>
    )
}