import { store } from '../../../../../store'
import { getDeviceByPage, getPageColor, getPageId, getX, getY, shouldShowCovers } from '../utils/helpers'
import { mouseover } from '../utils/listeners';

import { getPalletteColors } from '../../../../../store/actions/editor-actions';
import { areCommentsMarkedAsResolved, getCommentsCount } from '../../../comments/helpers';
import { defaultPalletteColors, getCanvasTextEditor, getInUserFlow, getIsLinkedUserFlowPage, getSitemap } from '../../../../../helpers';
import { getCanCommentInEditor } from '../../../../Editor/Navbar/helpers';

export const SHOW_STATUS = false;

export const renderHeader = (context, d, hidden) => {
    const sitemap = getSitemap()
    const { editor, ui } = store.getState()
    const { RevisionHistoryDrawer } = editor?.ui || {}
    const CanvasTextEditor = getCanvasTextEditor()
    // draw buttons
    if (!SHOW_STATUS || (SHOW_STATUS && (!mouseover.node || mouseover.node && mouseover.node.id !== d.id))) {
        if (!hidden) drawButtons({ context, sitemap, ui, d })
    }
    if (RevisionHistoryDrawer.showing) return; // don't show comments count in Rev History Drawer
    // draw comments count
    if (!d.dragging && !d.overflow) {
        // if (!mouseover.node || mouseover.node && mouseover.node.id !== d.id) {
        if (!CanvasTextEditor.showing || (CanvasTextEditor.showing && CanvasTextEditor.node.id !== d.id)) {
            drawComments({ context, sitemap, ui, d, hidden });
        }
        // }
    }
}

function drawButtons({ context, ui, d }) {

    const showCovers = shouldShowCovers(d);
    const y = (getY(d) + (showCovers ? 9 : 8.5));
    const device = getDeviceByPage(d)
    const color = getPageColor(d)
    const pallette = getPalletteColors(color)
    context.fillStyle = SHOW_STATUS ? "#38a169" : pallette[ui.colorMode === "light" ? 1 : 7]

    if (device === 'desktop') {

        context.globalAlpha = 0.5;

        /* context.beginPath();
        roundRect(context, getX(d) + (showCovers ? 62.5 : 15), y - 2.5, showCovers ? 100 : 95, 6, 0.5 * Math.PI, true, true)
        context.fill(); */

        const yPadding = showCovers ? -2.5 : 2.5

        context.beginPath();
        roundRect(context, getX(d) + 12.5, y + yPadding, 9, 7, 1 * Math.PI, true, true)
        context.fill();

        context.beginPath();
        roundRect(context, getX(d) + 24, y + yPadding, 9, 7, 1 * Math.PI, true, true)
        context.fill();

        context.beginPath();
        roundRect(context, getX(d) + 40, y + yPadding, 65, 7, 0.5 * Math.PI, true, true)
        context.fill();

        context.globalAlpha = 1;

    }
    else if (device === 'tablet') {
        context.beginPath();
        context.arc(getX(d) + 112.5, y, 4, 0, 2 * Math.PI, false);
        context.fill();
    } else if (device === 'mobile') {
        context.fillStyle = color // sitemap?.pallette?.header
        roundRect(context, getX(d) + 67.5, getY(d), 90, 16.2, 20, false, false);
        context.fillStyle = ui.colorMode === 'light' ? SHOW_STATUS ? "#38a169" : 'white' : '#1A202C'
        roundRect(context, getX(d) + 85, getY(d) + 4, 40, 7.5, 5, false, true);
        context.beginPath();
        context.arc(getX(d) + 135, y - 1, 4, 0, 2 * Math.PI, false);
        context.fill();
    }
}

const drawComments = ({ context, sitemap, ui, d, hidden }) => {
    if (!context.canvas.id) return;
    // user flows
    const nodeId = getPageId(d)
    // 
    const comments = sitemap?.comments[nodeId] || {};
    const markedAsResolved = areCommentsMarkedAsResolved(comments);
    const commentsCount = getCommentsCount(comments);
    const canComment = getCanCommentInEditor();
    //
    if ((!canComment && !commentsCount) || markedAsResolved) return; // don't show comments if empty and not able to edit sitemap
    //
    const showCovers = shouldShowCovers(d);
    //
    var font = `700 ${getCommentFontSize(d, commentsCount)}px Inter,sans-serif`;
    context.font = font;
    if (!hidden) {
        if (commentsCount) {
            context.beginPath()
            context.arc(getX(d) + (showCovers ? 224 : 224), getY(d) + (showCovers ? (2.5) : 2.5), showCovers ? 16 : 14, 0, 2 * Math.PI)
            context.closePath()
            context.globalAlpha = 1 // sitemap?.showCovers ? 0.8 : 0.8
            context.lineWidth = 4
            context.strokeStyle = ui.colorMode === "light" ? "white" : "#1A202C"
            context.stroke();
            context.lineWidth = 1
            context.fillStyle = getPageColor(d)
            context.fill()
            context.globalAlpha = 1
        }
        /*** draw text ***/
        if (commentsCount) {
            context.fillStyle = sitemap?.pallette?.header === defaultPalletteColors[1] ? '#1A202C' : 'white'
            context.textAlign = 'center'
            context.fillText(commentsCount, ((getX(d) + (showCovers ? 224 : 224))), getCommentTextY(d, commentsCount))
        }
        /*** draw text ***/
    }
};

const getCommentFontSize = (d, commentsCount) => {
    const showCovers = shouldShowCovers(d);
    if (showCovers) {
        return commentsCount < 10 ? 16 : 12
    } else {
        return commentsCount < 10 ? 14 : 10
    }
}

const getCommentTextY = (d, commentsCount) => {
    const showCovers = shouldShowCovers(d);
    if (showCovers) {
        return getY(d) + ((commentsCount < 10) ? 7.5 : 22.5)
    } else {
        return getY(d) + ((commentsCount < 10) ? 7.5 : 22.5)
    }
}

function roundRect(ctx, x, y, width, height, radius, hidden, full) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, full ? radius : 0);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, full ? radius : 0);
    ctx.closePath();
    ctx.lineWidth = 2;
    ctx.fill();
    // if (!hidden) ctx.stroke();
};