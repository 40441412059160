import { DownloadIcon } from '@chakra-ui/icons'
import {
    DrawerFooter,
    DrawerHeader,
    DrawerCloseButton,
    GridItem,
    Button,
    IconButton,
    HStack,
    Text,
    Tooltip,
    FormControl,
    FormLabel,
    Box,
    Checkbox,
    Stack,
    VStack,
    RadioGroup,
    Radio,
} from '@chakra-ui/react'
import { useGetEditor, useInSitemap, useIsUserFlowLinkedToSitemap } from '../../../../../hooks'

export const Options = ({
    showName,
    setShowName,
    showWebsite,
    setShowWebsite,
    size,
    setSize,
    layout,
    setLayout
}) => {
    const inSitemap = useInSitemap()
    const isUserFlowLinkedToSitemap = useIsUserFlowLinkedToSitemap()
    const editor = useGetEditor()
    return (
        <Box borderLeft="1px solid" borderColor="rarchy-border">
            <DrawerHeader borderBottomWidth={1}>
                <HStack w="256px" justify="space-between" spacing={3}>
                    <Text>Export to PDF</Text>
                    <HStack spacing={3}>
                        <Tooltip variant="rounded" label="Download PDF">
                            <IconButton
                                size="sm"
                                variant="ghost"
                                icon={<DownloadIcon fontSize="md" />}
                                onClick={() => {
                                    var src = document.getElementById('export-pdf-preview').src;
                                    var a = document.createElement("a");
                                    document.body.appendChild(a);
                                    a.style = "display: none";
                                    a.href = src;
                                    a.download = `${editor?.name}.pdf`;
                                    a.click();
                                    window.URL.revokeObjectURL(src);
                                }}
                            />
                        </Tooltip>
                        <DrawerCloseButton pos="initial" />
                    </HStack>
                </HStack>
            </DrawerHeader>
            <VStack p={6} spacing={9}>
                <FormControl>
                    <FormLabel fontWeight="semibold">Display</FormLabel>
                    <Stack mt={5} spacing={5} direction='row'>
                        <Checkbox size="md" isChecked={showName} onChange={(e) => setShowName(e.target.checked)}><Text fontSize="sm">Name</Text></Checkbox>
                        {(inSitemap || isUserFlowLinkedToSitemap) && (
                            <Checkbox
                                size="md"
                                isChecked={showWebsite}
                                onChange={(e) => setShowWebsite(e.target.checked)}>
                                <Text fontSize="sm">
                                    {isUserFlowLinkedToSitemap ? "Sitemap Name" : "Website"}
                                </Text>
                            </Checkbox>
                        )}
                    </Stack>
                </FormControl>
                <FormControl>
                    <FormLabel fontWeight="semibold">Size</FormLabel>
                    <RadioGroup value={size}>
                        <Stack direction='row' mt={5} spacing={5}>
                            <Radio value='A1' onChange={(e) => setSize('A1')}><Text fontSize="sm">A1</Text></Radio>
                            <Radio value='A2' onChange={(e) => setSize('A2')}><Text fontSize="sm">A2</Text></Radio>
                            <Radio value='A3' onChange={(e) => setSize('A3')}><Text fontSize="sm">A3</Text></Radio>
                            <Radio value='A4' onChange={(e) => setSize('A4')}><Text fontSize="sm">A4</Text></Radio>
                        </Stack>
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel fontWeight="semibold">Orientation</FormLabel>
                    <RadioGroup value={layout}>
                        <Stack direction='row' mt={5} spacing={5}>
                            <Radio value='portrait' onChange={(e) => setLayout('portrait')}><Text fontSize="sm">Portrait</Text></Radio>
                            <Radio value='landscape' onChange={(e) => setLayout('landscape')}><Text fontSize="sm">Landscape</Text></Radio>
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </VStack>
        </Box>
    )
}