import { CheckIcon, ChevronDownIcon, TimeIcon } from "@chakra-ui/icons"
import { Box, Button, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup, Stack, Text, Tooltip } from "@chakra-ui/react"
import { HiArrowUp, HiArrowDown } from "react-icons/hi"

import { LuArrowUpDown } from 'react-icons/lu'
import { useGetProjectsSortFilter, useIsViewingFolder } from "../../../../../hooks"
import { useDispatch } from "react-redux"
import { changeProjectsSortBy } from "../../../../../store/actions/projects-actions"
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"

const options = [
    { key: 'newest', label: 'Newest edited', icon: TimeIcon },
    { key: 'oldest', label: 'Oldest edited', icon: TimeIcon },
    { key: 'a-z', label: 'Alphabetical (A-Z)', icon: HiArrowUp },
    { key: 'z-a', label: 'Alphabetical (Z-A)', icon: HiArrowDown }
]

export const SortBy = () => {
    const dispatch = useDispatch()
    const isViewingFolder = useIsViewingFolder()
    const sortBy = useGetProjectsSortFilter('sortBy')
    const selected = options.find(o => o.key === sortBy)
    return (
        <Menu placement={isViewingFolder ? "bottom-start" : "bottom"} autoSelect={false}>
            <SkeletonWrapper w="fit-content">
                <Tooltip variant="rounded-sm" label="Sort by" hasArrow openDelay={250}>
                    <MenuButton
                        as={Button}
                        onFocus={e => e.preventDefault()}
                        pl={3.5}
                        pr={2.5}
                        variant={isViewingFolder ? "ghost" : "outline"}
                        fontWeight={isViewingFolder ? "medium" : "normal"}
                        _hover={{ bgColor: isViewingFolder ? "unset" : "rarchy-bg-white" }}
                        _expanded={{ borderColor: 'rarchy-blue', bgColor: isViewingFolder ? "unset" : "rarchy-bg-white" }}
                    >
                            <Stack direction="row" align="center">
                                {!isViewingFolder && <Icon as={LuArrowUpDown} fontSize="xl" color="rarchy-color-sitemap-editor" />}
                                <Text fontSize="sm">{selected.label}</Text>
                                <ChevronDownIcon fontSize="xl" />
                            </Stack>
                    </MenuButton>
                </Tooltip>
            </SkeletonWrapper>
            <MenuList fontSize="sm" minW="325px">
                <MenuOptionGroup
                    as={Box}
                    pt={0}
                    mt={1}
                    pb={1}
                    title={<Text fontSize="lg" fontWeight="bold">Sort by</Text>}
                    type='radio'
                >
                </MenuOptionGroup>
                <MenuDivider />
                {options.map(o => {
                    return (
                        <MenuItem
                            key={o.key}
                            py={2}
                            icon={<Icon as={o.icon} fontSize="xl" />}
                            command={sortBy === o.key ? <CheckIcon mr={2} fontSize="lg" /> : null}
                            onClick={() => {
                                setTimeout(() => {
                                    dispatch(changeProjectsSortBy(o.key))
                                }, 100);
                            }}
                        >
                            {o.label}
                        </MenuItem>
                    )
                })}
            </MenuList>
        </Menu>
    )
}