import { Button, Center, DarkMode, Image, Spinner } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { setUpgradeModalScreen } from "../../../../store/actions/ui-actions"

const showButton = false

export const RightConfirming = () => {
    const dispatch = useDispatch()
    return (
        <Center key="confirmed-bg" bgColor="rarchy-bg-upgrade-modal" w="full">
            {showButton && (
                <Button onClick={() => dispatch(setUpgradeModalScreen({ screen: 'confirmed' }))}>
                    Set confirming
                </Button>
            )}
            <DarkMode>
                <Spinner mt={-10} speed="0.7s" size="xl" color="fg.default" />
            </DarkMode>
        </Center>
    )
}