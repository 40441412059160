import { Box, Flex, Stack, StackDivider } from "@chakra-ui/react"

import { Settings as CrawlOptions } from "../../Import/Crawl/Settings";
import { Exclude } from "../../Import/Shared/Exclude";
import { ExcludeSelectors } from "../../Import/Shared/ExcludeSelectors";
import { Include } from "../../Import/Shared/Include";
import { MaxDepth } from "../../Import/Shared/MaxDepth"
import { MaxPages } from "../../Import/Shared/MaxPages"
import { Renderer } from "../../Import/Crawl/Renderer";

export const Options = ({ isCrawl, isXML, isCSV, isHistory }) => {
    return (
        <Flex w="full" justify="center" mt={10}>
            <Box w="4xl">
                <Stack divider={<StackDivider />} spacing={7} pb={20}>
                    <MaxPages isCrawl={isCrawl} />
                    {isCrawl && <Renderer />}
                    <MaxDepth />
                    {(isCrawl || isXML) && <Include />}
                    {(isCrawl || isXML) && <Exclude />}
                    {isCrawl && <ExcludeSelectors />}
                    {isCrawl && <CrawlOptions values={{ extractSEOTags: true }} />}
                </Stack>
            </Box>
        </Flex>
    )
}