import { FormControl, Heading, Textarea, VStack } from "@chakra-ui/react"

import { useFormikContext } from "formik"

export const Exclude = ({ options = {}, setOptions }) => { // options/setOptions for imported detail view
    const { values, handleChange } = useFormikContext()
    return (
        <FormControl id="exclude">
            <VStack w="full" spacing={3} alignItems="start">
                <Heading fontSize="md" fontWeight="semibold">Exclude URLs</Heading>
                <FormControl id="exclude">
                    <Textarea
                        defaultValue={Array.isArray(values?.exclude) ? values?.exclude?.join("\n") : ''}
                        bgColor="rarchy-bg-white"
                        w="xl"
                        placeholder="List one regular expression per line"
                        size="sm"
                        type="text"
                        rounded="md"
                        onChange={handleChange}
                    />
                </FormControl>
            </VStack>
        </FormControl>
    )
}