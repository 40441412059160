import { Badge, HStack, Text } from '@chakra-ui/react';
import { compact, isEmpty, sortBy } from 'lodash';

import React from 'react';
import { getPageName } from '../../../Sitemap/utils/app';

export const flatten = (sitemap) => {
    const { root } = sitemap?.data;
    var topLevelChildren = [];
    if (!root) return [];
    if (!root.children || root.children.length === 0) return [];
    root.children.forEach(node => {
        if (node.children || node._children) {
            node.childrenCount = getChildrenCount(node);
            topLevelChildren.push(node); // top level only
        }
    });
    topLevelChildren = sortBy(topLevelChildren, 'name');
    return topLevelChildren;
}

export const getChildrenCount = (parent) => {
    var count = 0;

    var children = parent.children || parent._children;

    if (Array.isArray(children)) {
        count += children.length;
        children.forEach((child) => {
            var children = child.children || child._children;
            if (Array.isArray(children)) {
                count += getChildrenCount(child);
            }
        });
    }

    return count;
}

// <HStack p="1px 0px" mr={1} direction="row" justifyContent="space-between"><Text>{node.name}</Text><Badge>{count.toLocaleString()}</Badge></HStack>

export const renderPagesForTree = (sitemap) => {

    function recurse(node) {
        var children = node.children ? node.children : node._children;
        if (!children) return
        const count = getChildrenCount(node)
        const name = getPageName(node.name);
        const obj = { name, label: <HStack p="1px 0px" mr={1} direction="row" justifyContent="space-between"><Text>{name}</Text><Badge>{count.toLocaleString()}</Badge></HStack>, value: node.id };
        if (children) {
            // include parent page so it can be selected by itself (if not disabled)
            obj.children = [];
            obj.children = [...obj.children, ...compact(children.map(recurse))]
            if (isEmpty(obj.children)) delete obj.children;
        }
        return obj
    }
    // run recurse
    // const treeNodes = sitemap?.data.section ? sitemap?.data.section.children.map(node => recurse(node)) : sitemap?.data.root.children.map(node => recurse(node));
    const mainNodes = compact(sitemap?.data?.root?.children?.map(node => recurse(node)));
    const websiteSectionNodes = [];
    sitemap?.data?.root?.website_sections?.forEach((s) => {
        const nodes = compact(s.children?.map(node => recurse(node)));
        nodes.forEach((n) => websiteSectionNodes.push(n));
    })
    return { pages: compact([...mainNodes, ...websiteSectionNodes]) }
}

/*** filtered search ***/
export const mapFilteredSubfolders = (subfolders, str) => {
    var { children } = hierarchiesFilter(subfolders, str)
    children = filter(children)
    return [...children]
}

function hierarchiesFilter(hierarchies, search) {
    if (!hierarchies || !hierarchies.length) return { hierarchies: [], hasExpandedChildren: false };
    var oneIsExpanded = false;
    for (var i = 0; i < hierarchies.length; i++) {
        hierarchies[i].showChildren = false;
        hierarchies[i].matchesSearch = false;
        if (search.length) {
            var rx = new RegExp(search, 'i');
            if (hierarchies?.[i]?.name?.match(rx)) {
                hierarchies[i].matchesSearch = true;
                oneIsExpanded = true;
            }
        }
        // if the node has children which are expanded, we need to display it so its children that
        // should be highlighted are visible
        var hasExpandedChildren = hierarchiesFilter(hierarchies[i].children, search).hasExpandedChildren;
        if (hasExpandedChildren) {
            hierarchies[i].showChildren = true
            oneIsExpanded = true
        }
    }
    return { children: hierarchies, hasExpandedChildren: oneIsExpanded };
};

function filter(arr) {
    var matches = [];
    if (!Array.isArray(arr)) return matches;
    arr.forEach(function (o) {
        if (o.matchesSearch) {
            if (!o.showChildren) delete o.children
            matches.push(o);
        } else {
            if (o.children) {
                let childResults = filter(o.children, c => c.matchesSearch || (!c.matchesSearch && c.showChildren));
                if (childResults.length) {
                    o.showChildren = true
                    matches.push(Object.assign({}, o, { children: filter(childResults) }));
                }
            }
        }
    })
    return matches;
}
/*** filtered search ***/