import { getFirestore, query, collection, where, and, or, getDocs } from "firebase/firestore"
import { getUserId } from "../../helpers";
import { compact, uniq, unionBy, differenceBy } from 'lodash';

export const getOrganizations = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = getUserId();
    const firestore = getFirestore()
    dispatch({
      type: 'GET_ORGANIZATIONS',
      async payload() {

        const orgQuery = await (getDocs(query(collection(firestore, "organizations"),
          and(
            or(
              where(`users.${userId}.role`, '==', "user"),
              where(`users.${userId}.role`, '==', "admin")
            ))
        )))

        const organizations = compact(uniq(orgQuery?.docs?.map(doc => {
          return { id: doc.id, ...doc.data() }
        })) || [])

        // return
        return organizations;
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

export const addOrganization = (organization) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { organizations } = state;
    dispatch({
      type: 'ADD_ORGANIZATION',
      data: unionBy([organization], organizations.data, "id")
    });
  }
};

export const removeOrganization = (organization) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { organizations } = state;
    dispatch({
      type: 'REMOVE_ORGANIZATION',
      data: differenceBy([organization], organizations.data, "id")
    });
  }
};