import React from 'react';
import { useDispatch } from 'react-redux';
import { ToggleButtonGroup } from '../Components/ToggleButton/ButtonGroup';
import { ToggleButton } from '../Components/ToggleButton/Button';
import { ImUndo, ImRedo } from 'react-icons/im';
import { useHotkeys } from 'react-hotkeys-hook'
import { useFirestore } from 'react-redux-firebase';
import { useCanEditFile, useGetSitemap, useGetUserFlow, useInRevisionHistory, useInUserFlow } from '../../../../hooks';
import { handleRedoClick, handleUndoClick } from './helpers';
import { EditorSkeletonWrapper } from '..';
import { getInUserFlow } from '../../../../helpers';
import { isEmpty } from 'lodash'

export const UndoRedo = () => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    const firestore = useFirestore()
    const flow = useGetUserFlow()
    const canEdit = useCanEditFile()
    const inRevisionHistory = useInRevisionHistory()
    useHotkeys('ctrl+z, command+z', e => handleUndo(), [sitemap])
    useHotkeys('ctrl+shift+z, command+shift+z', e => handleRedo(), [sitemap])
    const handleUndo = () => handleUndoClick({ sitemap, firestore, dispatch })
    const handleRedo = () => handleRedoClick({ sitemap, firestore, dispatch })

    return (
        <EditorSkeletonWrapper>
            <ToggleButtonGroup
                size="sm"
                defaultValue="left"
                isAttached
                variant="outline"
                ml={1}
            >
                <ToggleButton isDisabled={isDisabled({ canEdit, inRevisionHistory, sitemap, flow, type: 'undo' })} value="undo" icon={<ImUndo />} onClick={handleUndo} px={3.5} />
                <ToggleButton isDisabled={isDisabled({ canEdit, inRevisionHistory, sitemap, flow, type: 'redo' })} value="redo" icon={<ImRedo />} onClick={handleRedo} px={3.5} />
            </ToggleButtonGroup>
        </EditorSkeletonWrapper>
    )
}

const isDisabled = ({ canEdit, inRevisionHistory, sitemap, flow, type }) => {
    if (!canEdit || inRevisionHistory) return true
    const history = getInUserFlow() ? flow?.history : sitemap?.history
    return isEmpty(history?.[type])
}