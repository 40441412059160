import { Badge, Box, Button, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { setUpgradeModalScreen } from "../../../store/actions/ui-actions"
import { useGetAnnualDiscount, useGetMonthlyAnnualPlanPrice, useGetMonthlyPlanPriceWithAddons, useGetNumberOfSeatsIncludedInPlan, useGetNumberOfSeatsOverLimit, useGetPriceWithCurrencySymbol } from "../../../../shared/hooks/pricing"
import { useGetAnnualPlanPrice } from "../../../../shared/hooks"

import { useGetUpgradeModal, useInOrganization } from "../../../hooks"

import { useFormikContext } from "formik"
import { PayToday } from "../Components/PayToday"
import { Checklist } from "../Components/Checklist"
import { AdditionalSeats } from "./AdditionalSeats"
import { UpgradeToEnterpriseInStripe } from "../Components/UpgradeToEnterprise"

export const PlansPricing = () => {

    const dispatch = useDispatch()

    const { values, setFieldValue } = useFormikContext()

    const { planId } = useGetUpgradeModal()

    const monthlyPrice = useGetMonthlyPlanPriceWithAddons({ planId, seats: values?.seats })
    const yearlyPrice = useGetAnnualPlanPrice(monthlyPrice)
    const yearlyPricePerMonth = useGetMonthlyAnnualPlanPrice(yearlyPrice)

    const inOrganization = useInOrganization()

    const seatsLimitByPlan = useGetNumberOfSeatsIncludedInPlan({ planId })
    const additionalSeatsRequired = useGetNumberOfSeatsOverLimit(seatsLimitByPlan, values?.seats)

    return (
        <Stack w="full" justify="space-between" spacing={6}>
            <Checklist />
            <RadioGroup onChange={val => setFieldValue("billingInterval", val)} value={values?.billingInterval}>
                <AdditionalSeats />
                <Text fontSize="sm" mt={5} mb={3}>{`For a team of ${values?.seats} ${values?.seats > 1 ? 'people' : 'person'}:`} </Text>
                <Stack align="start" spacing={4}>
                    <Radio size='md' value='month' alignItems="start">
                        <Stack mt={-1} ml={1} spacing={1}>
                            <Text fontWeight="semibold">Monthly</Text>
                            <Text fontSize="sm" fontWeight="medium" color="fg.muted">{useGetPriceWithCurrencySymbol(monthlyPrice)}</Text>
                        </Stack>
                    </Radio>
                    <Radio size='md' value='year' alignItems="start">
                        <Stack mt={-1} ml={1} spacing={1}>
                            <Stack direction="row" align="center" spacing={3}>
                                <Text fontWeight="semibold">Yearly</Text>
                                <Badge fontWeight="medium" colorScheme="green" rounded="full" px={2} py={.5}>{`BEST VALUE - SAVE ${useGetAnnualDiscount({ price: monthlyPrice })}`}</Badge>
                            </Stack>
                            <Box fontSize="sm" fontWeight="medium" color="fg.muted">{useGetPriceWithCurrencySymbol(yearlyPrice)}
                                <Box display="inline" fontWeight="normal" fontSize="sm" color="fg.subtle">{` (${useGetPriceWithCurrencySymbol(yearlyPricePerMonth)} per month)`}</Box>
                            </Box>
                        </Stack>
                    </Radio>
                </Stack>
            </RadioGroup>
            <PayToday />
            {inOrganization && (
                <UpgradeToEnterpriseInStripe
                    add_ons={{ seats: additionalSeatsRequired }}
                    buttonText="Continue"
                />
            )}
            {!inOrganization && (
                <Button
                    w="full"
                    colorScheme="blue"
                    onClick={() => dispatch(setUpgradeModalScreen({ screen: 'details' }))}>
                    Next
                </Button>
            )}
        </Stack>
    )
}