import { getFirestore, query, collection, doc, where, and, or, getDocs, setDoc } from "firebase/firestore"
import { getAccountId, getEditorDocIdFromPath, getEditorSubfolderFromDoc, getUserId, sendHubspotCustomEvent } from '../../helpers'
import { getPeople } from "./people-actions";
import deepmerge from 'deepmerge';
import { chunk, compact, map, keys, uniqWith, uniqBy, isEqual } from 'lodash'

const getCollaboratorsFilter = (uid) => {
  return and(
    or(
      where(`collaborators.${uid}.access`, '==', "comment"),
      where(`collaborators.${uid}.access`, "==", "edit")
    ));
}

export const getFiles = ({ folderIds, archived = false }) => {

  return (dispatch, getState) => {

    const { files } = getState()
    if (files?.loaded) return;

    const firestore = getFirestore()

    const userId = getUserId()
    const accountId = getAccountId()
    const chunkedTeamIds = chunk([accountId, ...folderIds], 10);

    dispatch({
      type: 'GET_FILES',
      async payload() {
        let docs = [];
        // THIS WORKS
        const collaboratorsFilter = getCollaboratorsFilter(userId);
        await Promise.all(chunkedTeamIds.map(async (teamIds) => {
          const docFilters = and(...compact([
            !archived && where('archived', '==', archived),
            archived && where('deleteAt', '>', new Date())
          ]),
            or(
              ...compact([
                or(where('account', '==', accountId)), // (used for archived file logic - when file is archived with/from folder, we won't be able to use folderIds to retrieve the archived files once the folder has been deleted. "account" is set when file is archived, so we can always retrieve the archived files, even if the folder has been deleted
                or(where('team', 'in', teamIds)), // in folders user has access to
                !archived && collaboratorsFilter // or shared with user (only if not archived, to stop collaborators being able to see archived files)
              ]))
          );

          const sitemaps = await getDocs(query(collection(firestore, "sitemaps"), docFilters))
          docs = [...docs, ...sitemaps.docs.map(doc => { return { doc, collection: 'sitemaps' } })];
          const flows = await getDocs(query(collection(firestore, "user-flows"), docFilters))
          docs = [...docs, ...flows.docs.map(doc => { return { doc, collection: 'user-flows' } })];
        }));

        // DATA INSTEAD OF BYID SO WE CAN HAVE MULTIPLE FILES WITH THE SAME ID (FOR FREE ACCOUNTS, WHERE SAME DOC ID BUT DIFFERENT DOC TYPES

        let data = await getDocsData({ docs, folderIds });

        return { data };
      },
    }).then(() => {
      dispatch(getPeople());
    }).catch(e => {
      console.error(e);
    });
  };
};

export const renameFile = ({ collectionId, docId, name }) => {
  return (dispatch, getState) => {

    const firestore = getFirestore();
    const state = getState();

    const { uid } = state.firebase.auth;

    dispatch({
      type: 'RENAME_FILE',
      async payload() {

        const collectionRef = collection(firestore, collectionId)
        const docRef = doc(collectionRef, docId)

        await setDoc(docRef, {
          name,
          updatedBy: uid,
          updatedAt: new Date()
        }, { merge: true })

        dispatch(mergeFileChange(docId, collectionId, { name })) // merge file change

      }
    }).catch(e => {
      console.error(e)
    })
  }
}

export const addFile = (id, collection, data) => {
  return (dispatch, getState) => {
    const { files } = getState()
    // add file
    let mergedData = [...files]?.unshift({ id, collection, ...data })
    // dispatch
    dispatch({
      type: 'ADD_FILE', data: mergedData
    })
  }
}

export const mergeFileChange = (id, collection, data) => {
  return (dispatch, getState) => {
    // have to do all of this as free files will have the same id
    const { files, folders } = getState()
    let mergedData = [];
    // delete file
    if (!data) {
      mergedData = [...files?.data].filter(f => {
        if (f.id === id) {
          if (f.collection === collection) return;
        }
        return f;
      })
    } else { // merge / add
      mergedData = compact(uniqBy([...files?.data]?.map(f => {
        if (f.id === id) {
          if (f.collection === collection) { f = data ? deepmerge(f, data) : undefined }
        }
        return f;
      }), 'id'))
      // add
      const docIds = map(mergedData.filter(d => d.collection === collection), 'id');
      if (!docIds.includes(id)) mergedData.push({ id, ...data })
    }
    // update attrs to match initial fetch of docs
    mergedData = map(mergedData, (d) => getDataFromDoc({ id: d.id, doc: d, collection: d.collection, folderIds: keys(folders?.byId) }))
    // dispatch
    dispatch({
      type: 'MERGE_FILE_CHANGE', data: mergedData
    })
  }
}

export const toggleShareFileModal = (file) => {
  return (dispatch, getState) => {
    const state = getState()
    const { ShareFileModal } = state?.files?.ui || {}
    const showing = !ShareFileModal?.showing
    if (showing) sendHubspotCustomEvent('opened_share_modal', { collection: file?.collection })
    dispatch({
      type: 'TOGGLE_SHARE_FILE_MODAL',
      showing,
      file
    });
  }
}

export const deleteUserFile = ({ collection, history }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const userId = getUserId()
    dispatch({
      type: 'DELETE_USER_FILE',
      async payload() {
        // Get a new write batch
        let batch = firestore.batch();
        // delete data docs
        const dataDocs = collection === 'sitemaps' ? ['pages', 'website_sections', 'sections', 'covers', 'comments'] : ['elements'];
        dataDocs.forEach(docId => {
          batch.delete(firestore.doc(`${collection}/${userId}/data/${docId}`));
        });
        // delete doc
        batch.delete(firestore.doc(`${collection}/${userId}`));
        // COMMIT
        try { await batch.commit(); } catch (e) { console.error(e) }
        // amplitude tracking
        // amplitude.getInstance().logEvent('DELETED FILE', { id: userId, collection });
      },
    }).then(async () => {
      dispatch(mergeFileChange(userId, collection, undefined))
      const inFile = userId === getEditorDocIdFromPath()
      if (inFile) history.push(`/app`)
    }).catch(e => {
      console.error(e);
    });
  };
};

export const deleteFile = ({ file }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const userId = getUserId()
    dispatch({
      type: 'DELETE_FILE',
      async payload() {
        // set file as deleted
        await firestore.doc(`${file?.collection}/${file?.id}`).set(
          {
            deleted: true,
            deleteAt: new Date(),
            updatedAt: new Date(),
            updatedBy: userId
          },
          { merge: true }
        );
        // amplitude tracking
        // amplitude.getInstance().logEvent('DELETED_SITEMAP', { id: file?.id });
        return { file };
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

const getDocsData = async ({ docs, folderIds }) => {
  let arr = compact(uniqWith(await Promise.all(docs.map(async ({ doc, collection }) => {
    const { id } = doc || {}
    if (!doc?.data()) return
    return getDataFromDoc({ id, doc: doc.data(), collection, folderIds })
  })), isEqual));
  return arr;
};

export const getDataFromDoc = ({ id, doc, collection, folderIds }) => {
  const userId = getUserId()
  const accountId = getAccountId()
  // people
  var { collaborators, team } = doc
  const member = ((id === accountId || team === accountId) || folderIds.includes(team))
  const collaborator = !member && (collaborators?.[userId]) ? true : false
  // dates
  const createdAt = getDate(doc.createdAt)
  const updatedAt = getDate(doc.updatedAt)
  const archivedAt = doc.archived ? getDate(doc.archivedAt) : null
  const deleteAt = doc.archived ? getDate(doc.deleteAt) : null
  // return
  const { flows, lastEdit, archived, link, ...rest } = doc
  return {
    id,
    ...rest,
    archived,
    createdAt,
    updatedAt,
    archivedAt,
    deleteAt,
    updatedBy: doc?.updatedBy || lastEdit,
    collection,
    member,
    collaborator,
    link: `/app/${getEditorSubfolderFromDoc({ id, collection })}/${id}`
  };
}

function getDate(date) {
  const hasToDateFunction = typeof date?.toDate === 'function'
  return hasToDateFunction ? date?.toDate() : new Date(date)
}