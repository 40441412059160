import { addPageChange, mergePagesEdits } from "../../../../../../store/actions/sitemap-actions";
import { convertObjToArrayWithKey, getSitemap, sendHubspotCustomEvent } from "../../../../../../helpers";
import { keyBy, pickBy } from "lodash"

import { getFirestore } from "redux-firestore";
import { saveColorLabelChange } from "../../../../../../store/actions/editor-actions";
import { store } from "../../../../../../store";

export class ColorLabel {
    constructor(obj) {
        this.color = obj.color || ''
        this.label = obj.label || ''
    }
    getFirestoreColorKey(color) {
        return (color || this.color)?.replace('#', '')
    }
    renameLabel(label) {
        if (label == this.label) return;
        this.label = label;
        this.saveLabelChange({ [this.getFirestoreColorKey()]: { label } });
        this.sendEventToHubspot('rename')
    }
    saveNewLabel() {
        this.saveLabelChange({ [this.getFirestoreColorKey()]: {} });
        this.sendEventToHubspot('new')
    }
    deleteLabel() {
        this.saveLabelChange({ [this.getFirestoreColorKey()]: undefined });
        this.sendEventToHubspot('delete')
    }
    changeLabelColor(color, opts) {

        if (color == this.color) return;

        const { overwritePages } = opts || {}

        /*** handle overwriting pages ***/
        if (overwritePages) {
            this.updatePagesWithNewLabelColorInFirestore(color)
        }
        /*** handle overwriting pages ***/

        /*** save label change ***/
        let oldColor = this.color // store old color
        this.color = color // sets new color
        this.saveLabelChange({
            [this.getFirestoreColorKey(oldColor)]: undefined, // delete old color
            [this.getFirestoreColorKey()]: { label: this.label }, // set new color
        });
        /*** save label change ***/

        this.sendEventToHubspot(`change ${overwritePages ? '(overwrite)' : ''}`)

    }
    updatePagesWithNewLabelColorInFirestore(color) {
        const { pages } = getSitemap()?.docs || {}
        // get all pages with matching existing color, replace with new color and return in array
        const arrayOfMatchingPallettes = convertObjToArrayWithKey(pickBy(pages, (p) => p?.pallette?.header === this.color), 'id')?.map(o => { return { id: o.id, pallette: { ...o.pallette, header: color } } })
        // add change so it get's fed through the sitemap autocorrect
        const change = { id: new Date().getTime(), data: [...arrayOfMatchingPallettes]?.map(p => { { return { action: 'color', ...p, children: p.pallette.children === true ? true : getFirestore().FieldValue.delete() } } }) };
        // merge changes for instant update
        store.dispatch(mergePagesEdits({ pages: keyBy(arrayOfMatchingPallettes, 'id') }))
        // push change to store
        store.dispatch(addPageChange({ change }));
    }
    saveLabelChange(change) {
        store.dispatch(saveColorLabelChange(change))
    }
    sendEventToHubspot(action) {
        sendHubspotCustomEvent('modified_color_labels', { action })
    }
    
}