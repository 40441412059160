import { Box, Icon, IconButton, Stack, StackDivider, Text, useColorMode, useColorModePreference } from "@chakra-ui/react"
import { MainHeader, SectionHeader } from "../../Components"
import { useEffect, useState } from "react"
import { BsMoonStarsFill, BsSun } from "react-icons/bs"
import { getIsSyncWithSystem } from "../../.."
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"

export const Theme = () => {
    // continue
    const { colorMode, toggleColorMode } = useColorMode()
    const preference = useColorModePreference()
    const isSyncWithSystem = getIsSyncWithSystem()
    const [colorModePreference, setColorModePreference] = useState(isSyncWithSystem ? 'system' : preference)
    const selectedAttrs = (key) => {
        if (colorModePreference === key) return { border: "2px solid", borderColor: "rarchy-link" }
    }
    useEffect(() => {
        if (colorModePreference !== 'system') {
            if (colorMode !== colorModePreference) setColorModePreference(colorMode)
        }
    })
    return (
        <Box mb={20}>
            <MainHeader title="Theme" />
            <Box mt={3} fontSize="sm" color="fg.muted">
                <SkeletonWrapper w="fit-content">
                    Choose how you’d like Rarchy to appear. Select a theme, or sync themes with your system preferences.
                </SkeletonWrapper>
            </Box>
            <Stack mt={4} direction="row" spacing={5}>
                <Stack>
                    <SkeletonWrapper w="fit-content">
                        <IconButton
                            {...selectedAttrs('system')}
                            icon={(
                                <Stack direction="row" spacing={5} divider={<StackDivider />}>
                                    <Icon as={BsSun} fontSize="2xl" color="fg.muted" />
                                    <Icon as={BsMoonStarsFill} fontSize="2xl" color="fg.muted" />
                                </Stack>
                            )}
                            px={8}
                            py={10}
                            variant="outline"
                            onClick={() => {
                                localStorage.setItem("chakra-ui-color-mode-system", true);
                                if (preference !== colorMode) toggleColorMode()
                                setColorModePreference('system')
                            }}
                        />
                    </SkeletonWrapper>
                    <SectionHeader title="Sync with system" />
                </Stack>
                {['Light', 'Dark'].map(mode => {
                    const lowercase = mode?.toLowerCase()
                    return (
                        <Stack key={lowercase}>
                            <SkeletonWrapper w="fit-content">
                                <IconButton
                                    {...selectedAttrs(lowercase)}
                                    icon={<Icon as={lowercase === 'light' ? BsSun : BsMoonStarsFill} fontSize="2xl" color="fg.muted" />}
                                    px={16}
                                    py={10}
                                    variant="outline"
                                    onClick={() => {
                                        localStorage.removeItem("chakra-ui-color-mode-system");
                                        if (colorMode !== lowercase) toggleColorMode()
                                        setColorModePreference(lowercase)
                                    }}
                                />
                            </SkeletonWrapper>
                            <SectionHeader title={mode} />
                        </Stack>
                    )
                })}
            </Stack>
        </Box>
    )
}