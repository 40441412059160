import { useState } from "react"
import { useDispatch } from "react-redux"
import { MenuItem } from "@chakra-ui/react"
import { CloseIcon } from "@chakra-ui/icons"
import { PY } from "../../Projects/Folders/Components/Options/Menu"
import { mergeFolderChange } from "../../../store/actions/folders-actions"
import { wait } from "../../../../shared/helpers"
import { MenuItemSpinner } from "./MenuItemSpinner"
import { useGetAccountId, useGetFolderIdFromPath, useGetUserId } from "../../../hooks"
import { mergeFileChange } from "../../../store/actions/files-actions"
import { getIsPublicFolderOrSharedWithUser } from "../../../helpers"

import { getFirestore, doc, setDoc } from "firebase/firestore/lite"

const ML = 0.5
const MR = 1

export const RemoveFromFolderMenuItem = ({ file, folder }) => {
    const dispatch = useDispatch()
    const userId = useGetUserId()
    const [isRemoving, setIsRemoving] = useState(false)
    const folderId = useGetFolderIdFromPath()
    // only show if file team is not equal to account id (no folder)
    const accountId = useGetAccountId()
    if (file && file?.team === accountId) return null
    // only show if folder has team (parent)
    if (folder && !folder?.team) return null
    // don't show if folder and not public/shared with user
    const isPublicOrSharedFolder = getIsPublicFolderOrSharedWithUser(folder)
    if (folder && !isPublicOrSharedFolder) return null
    // continue
    const item = file || folder
    // only show if in folder
    if (folderId !== item?.team) return null
    // continue
    return (
        <MenuItem
            py={PY}
            isDisabled={isRemoving}
            icon={isRemoving ? <MenuItemSpinner /> : <CloseIcon fontSize="sm" ml={ML} mr={MR} />}
            onClick={async () => {
                setIsRemoving(true)
                await wait(500)
                // file
                if (file) dispatch(mergeFileChange(file?.id, file?.collection, { team: accountId })) // has to be accountId
                // folder
                if (folder) dispatch(mergeFolderChange({ [folder?.id]: { team: null } })) // has to be null
                setIsRemoving(false)
                // update in firestore
                await setDoc(doc(getFirestore(), folder ? "teams" : file?.collection, item?.id), { team: folder ? null : accountId, updatedBy: userId, updatedAt: new Date() }, { merge: true });
            }}>
            Remove from folder
        </MenuItem>
    )
}