import { Box, Image, useColorModeValue as mode } from "@chakra-ui/react"
import DefaultBg from '../../Images/Default/Background.jpg'

const LightSitemapImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Light-1080.png"
const DarkSitemapImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Dark-1080.png"
const UserFlowsImage = "https://help.rarchy.com/hubfs/User%20Flows%20-%20Light-min%20-%201080.png"

const LightPagesImage = "https://help.rarchy.com/hubfs/%2B%205%2c000-min-1080.png"
const DarkPagesImage = "https://help.rarchy.com/hubfs/%2B%205%2c000%20-%20Dark-min-1080.png"

export const RightDefault = () => {
    return (
        <Box w="full" key="default-bg" bgImage={DefaultBg}>
            <Image
                pos="absolute"
                top="30px"
                right="50px"
                src={UserFlowsImage}
                w="300px"
            />
            <Image
                pos="absolute"
                top="150px"
                right="300px"
                src={mode(LightSitemapImage, DarkSitemapImage)}
                w="250px"
            />
            <Image
                pos="absolute"
                top="425px"
                right="50px"
                src={mode(LightPagesImage, DarkPagesImage)}
                w="300px"
            />
        </Box>
    )
}