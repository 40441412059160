import React from 'react';
import {
    Input,
    InputGroup,
    InputLeftElement
} from "@chakra-ui/react"
import { SearchIcon } from '@chakra-ui/icons';

export const Search = ({ initialFocusRef, searched, handleChange }) => {
    return (
        <InputGroup mt={3} mb={7}>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
            <Input fontSize="sm" ref={initialFocusRef} variant="flushed" value={searched} onChange={handleChange} placeholder="Search..." />
        </InputGroup>
    )
}