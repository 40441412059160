import React from 'react';
import { transform } from '../../../canvas/utils/zoom';

import { FiMoreHorizontal } from 'react-icons/fi';
import { showContextMenu } from '../../../context-menu/helpers';
import { PageButton } from '../../components/PageButton';
import { Icon, Tooltip } from '@chakra-ui/react';
import { useCanEditFile } from '../../../../../../hooks';

export const Menu = ({ d, showCovers }) => {
    const fontSize = `${(showCovers ? 45 : 35) * transform.k}`
    const canEditFile = useCanEditFile()
    if (!canEditFile) return null
    return (
        <Tooltip placement="bottom" label="Open Menu" openDelay={1000} variant="rounded-sm" hasArrow>
            <PageButton
                icon={<Icon as={FiMoreHorizontal} />}
                fontSize={fontSize}
                d={d}
                onClick={(e) => {
                    const { x, y } = e.target.getBoundingClientRect()
                    e.stopPropagation()
                    const left = x - 25
                    const top = y - 35
                    showContextMenu({ event: e, left, top, node: d, trigger: 'button' })
                }}
            />
        </Tooltip>
    )
}