import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

// just add here the lodash functions you want to support
const chainableFunctions = {
    map,
    mapValues,
    keyBy,
    groupBy,
};

export const chain = (input) => {
    let value = input;
    const wrapper = {
        ...mapValues(
            chainableFunctions,
            (f) => (...args) => {
                // lodash always puts input as the first argument
                value = f(value, ...args);
                return wrapper;
            },
        ),
        value: () => value,
    };
    return wrapper;
};