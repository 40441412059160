import { useGetFolders } from "../../../../../../hooks"
// can't move a folder to a subfolder of itself
export const useIsSubfolderOfFolder = ({ folder, inFolder }) => {
    let isSubfolder = false
    const topFolderId = folder?.id
    const folders = useGetFolders()?.byId
    function recurse(folderId) {
        const parentFolderId = folders[folderId]?.team
        if (parentFolderId) {
            if (parentFolderId === topFolderId) isSubfolder = true
            recurse(parentFolderId)
        }
    }
    recurse(inFolder)
    return isSubfolder
}

export const useShouldDisableNew = ({ item, folder, folderId }) => {
    const isSubfolderOfFolder = useIsSubfolderOfFolder({ folder, folderId })
    const isSharedWithYou = folderId === 'projects/shared-with-you'
    const isAlreadyFolder = item?.team === folderId
    const isMovingFolderToItself = item?.id === folderId
    return (isSharedWithYou || isAlreadyFolder || isMovingFolderToItself || isSubfolderOfFolder)
}

export const useShouldDisableMove = ({ item, folder, folderId }) => {
    const shouldDisableNew = useShouldDisableNew({ item, folder, folderId })
    return !folderId || shouldDisableNew
}