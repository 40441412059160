import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transform } from '../../../canvas/utils/zoom';

import { getURL, togglePageDrawer } from '../../../../../../store/actions/sitemap-actions';
import { PageButton } from '../../components/PageButton';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const GoToUrl = ({ d, showCovers }) => {
    /*
    const dispatch = useDispatch()
    const fontSize = `${(showCovers ? 24 : 24) * transform.k}px`
    return (
        <PageButton
            title="Edit Page URL"
            icon={<ExternalLinkIcon />}
            fontSize={fontSize}
            d={d}
            onClick={(e) => {
                e.stopPropagation()
                // window.open(getURL(d), '_blank');
                dispatch(togglePageDrawer({ node: d, editPageUrl: true }))
            }}
        />
    ) */
}