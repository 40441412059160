import { Button, Stack, Text, useBoolean } from "@chakra-ui/react"
import { BorderedBox, MainHeader } from "../../../Components"
import { useGetSubscription, useInAgencyPlan, useInProPlan, useIsOrganizationAdmin } from "../../../../../hooks"
import { handleCustomerPortalSubscriptionUpdate } from "."
import { useGetNumberOfSeatsIncludedInPlan, useGetPriceWithCurrencySymbol } from "../../../../../../shared/hooks/pricing"
import { SkeletonWrapper } from "../../../../Components/Shared/SkeletonWrapper"
import { UpgradeAnnual } from "../UpgradeAnnual"

export const Seats = () => {

    const [isLoading, setIsLoading] = useBoolean()

    const subscription = useGetSubscription()
    const inProPlan = useInProPlan()
    const inAgencyPlan = useInAgencyPlan()

    const isAdmin = useIsOrganizationAdmin()

    const numberOfSeatsIncludedInPlan = useGetNumberOfSeatsIncludedInPlan()
    const { customer_id, id: subscription_id, add_ons } = subscription || {};
    const { seats } = add_ons || {}
    const { quantity, amount } = seats || {}
    const interval = seats?.interval === 'year' ? 'year' : 'month'
    const price = (amount * quantity) / 100
    const hasAdditionalSeats = quantity;

    const priceWithCurrency = useGetPriceWithCurrencySymbol(price)

    return (
        <BorderedBox p={4}>
            <Stack spacing={5}>
                <Stack spacing={3}>
                    <MainHeader title={`Additional seats ${quantity ? `x${quantity}` : ''}`} />
                    <SkeletonWrapper w="fit-content">
                        <Text fontSize="sm">
                            {`${numberOfSeatsIncludedInPlan === Infinity ? 'Unlimited' : numberOfSeatsIncludedInPlan} seat${inProPlan ? '' : 's'} included in your plan`}
                        </Text>
                    </SkeletonWrapper>
                </Stack>
                {hasAdditionalSeats && (
                    <Stack w="full" direction="row" align="baseline" justify="space-between">
                        <SkeletonWrapper w="fit-content">
                            <Text fontSize="sm" fontWeight="medium">{`${priceWithCurrency} / ${interval}`}</Text>
                        </SkeletonWrapper>
                        {isAdmin && (
                            <SkeletonWrapper w="fit-content">
                                <UpgradeAnnual price={price} />
                            </SkeletonWrapper>
                        )}
                    </Stack>
                )}
                {isAdmin && !inAgencyPlan && (
                    <SkeletonWrapper w="fit-content">
                        <Button
                            fontSize="sm"
                            isLoading={isLoading}
                            onClick={async () => {
                                handleCustomerPortalSubscriptionUpdate({ setIsLoading, customer_id, subscription_id })
                            }}>
                            {`${hasAdditionalSeats ? "Manage" : "Add"} seats`}
                        </Button>
                    </SkeletonWrapper>
                )}
            </Stack>
        </BorderedBox>
    )
}